"use strict";
import React from "react";
import classNames from "classnames";
import {
  AlertMessage,
  Checkbox,
  Icon,
  Text,
  ToggleButton,
} from "@citifyd/style";
import BoxContent from "../../../../../../shared/react/components/BoxContent";
import Pricing from "./Pricing";
import { getDayNames } from "./utils";
import styles from "../../RateSettings.module.scss";
import {
  useService,
  useTranslator,
} from "../../../../../../shared/react/hooks";

const DailyParking = ({
  lot,
  pricingSettings,
  setPricingSettings,
  setIsEditingDailyPricing,
}) => {
  const t = useTranslator();
  const $state = useService("$state");
  const hasAdvancedRateDefinition = lot?.advancedRateDefinitionEnabled;

  const onPricingSettingsDayChange = (day) => {
    setPricingSettings((prevState) => ({
      ...prevState,
      days: {
        ...prevState.days,
        [day]: !prevState.days[day],
      },
    }));
  };

  const onPricingSettingsNightChange = (evening) => {
    setPricingSettings((prevState) => ({
      ...prevState,
      evenings: {
        ...prevState.evenings,
        [evening]: !prevState.evenings[evening],
      },
    }));
  };

  const onPricingSettingsChange = (range) => {
    setPricingSettings((prevState) => ({
      ...prevState,
      ...range,
    }));
  };

  const onRemovePricingSettings = (range) => {
    setPricingSettings((prevState) => ({
      ...prevState,
      [range]: { data: null, editData: null, editing: false },
    }));
  };

  return (
    pricingSettings && (
      <>
        {hasAdvancedRateDefinition && lot.setupIsComplete && (
          <AlertMessage className={styles.alertMessage}>
            {t("addProperty.youHaveAdvancedRates")}
          </AlertMessage>
        )}
        <BoxContent className={styles.box}>
          <BoxContent.Header
            className={styles.boxHeader}
            title={t("addProperty.dailyParking")}
          >
            <BoxContent.HeaderActions>
              <div className={styles.toggleButton}>
                <Text appearance="white" className={styles.text}>
                  {t("addProperty.switchToAdvancedRateSelection")}
                </Text>
                <ToggleButton
                  light
                  onChange={() =>
                    $state.go("edit-property-advanced-rates-lot-setup", {
                      lotId: lot.id,
                      advancedRateDefinitionId: "latest",
                    })
                  }
                />
              </div>
            </BoxContent.HeaderActions>
          </BoxContent.Header>
          <BoxContent.Content className={styles.boxContent}>
            <div className={styles.pricingWrapper}>
              <OpenOn
                period={pricingSettings.days}
                onPricingSettingsPeriodChange={onPricingSettingsDayChange}
              />

              <div className={styles.weekday}>
                <div className={classNames(styles.sun, styles.smallColumn)}>
                  <Icon
                    icon={["far", "sun"]}
                    pull="none"
                    size="3x"
                    appearance="white"
                  />
                </div>
                <div className={styles.largeColumn}>
                  <Pricing
                    lot={lot}
                    type="weekday"
                    period="day"
                    setIsEditingDailyPricing={setIsEditingDailyPricing}
                    selectedDays={pricingSettings?.days}
                    range="weekdays"
                    currentPricingObject={pricingSettings}
                    onRemovePricingSettings={() =>
                      onRemovePricingSettings("weekdays")
                    }
                    onUpdatePricingSettings={onPricingSettingsChange}
                  />
                </div>
                <div className={styles.mediumColumn}>
                  <Pricing
                    lot={lot}
                    type="weekend"
                    period="day"
                    setIsEditingDailyPricing={setIsEditingDailyPricing}
                    selectedDays={pricingSettings?.days}
                    range="weekends"
                    currentPricingObject={pricingSettings}
                    onRemovePricingSettings={() =>
                      onRemovePricingSettings("weekends")
                    }
                    onUpdatePricingSettings={onPricingSettingsChange}
                  />
                </div>
              </div>

              <OpenOn
                period={pricingSettings.evenings}
                onPricingSettingsPeriodChange={onPricingSettingsNightChange}
              />
              <div className={styles.weeknight}>
                <div className={classNames(styles.moon, styles.smallColumn)}>
                  <Icon
                    icon={["far", "moon"]}
                    pull="none"
                    size="3x"
                    appearance="white"
                  />
                </div>
                <div className={styles.largeColumn}>
                  <Pricing
                    lot={lot}
                    type="weekday"
                    period="night"
                    setIsEditingDailyPricing={setIsEditingDailyPricing}
                    selectedDays={pricingSettings?.evenings}
                    range="weeknights"
                    currentPricingObject={pricingSettings}
                    onRemovePricingSettings={() =>
                      onRemovePricingSettings("weeknights")
                    }
                    onUpdatePricingSettings={onPricingSettingsChange}
                  />
                </div>
                <div className={styles.mediumColumn}>
                  <Pricing
                    lot={lot}
                    type="weekend"
                    period="night"
                    setIsEditingDailyPricing={setIsEditingDailyPricing}
                    selectedDays={pricingSettings?.evenings}
                    range="weekendEvenings"
                    currentPricingObject={pricingSettings}
                    onRemovePricingSettings={() =>
                      onRemovePricingSettings("weekendEvenings")
                    }
                    onUpdatePricingSettings={onPricingSettingsChange}
                  />
                </div>
              </div>
            </div>
          </BoxContent.Content>
        </BoxContent>
      </>
    )
  );
};

const OpenOn = ({ period, onPricingSettingsPeriodChange }) => {
  const t = useTranslator();
  const smallDayNames = getDayNames("small"); // Mon, Tue, Wed, Fri, Sat, Sun (localized)
  const smallestDayNames = getDayNames("smallest"); // M, Tu, W, Th, F, Sa, Su (localized)

  const dayKeys = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  return (
    <>
      <div className={classNames(styles.days, styles.desktop)}>
        <div className={styles.smallColumn}>{t("addProperty.openOn")}</div>
        {period &&
          dayKeys.map((key, index) => (
            <div className={styles.smallColumn} key={index}>
              <Checkbox
                small
                id={key}
                onChange={() => onPricingSettingsPeriodChange(key)}
                defaultChecked={period[key]}
                label={smallDayNames?.[key]}
              />
            </div>
          ))}
      </div>

      <div className={classNames(styles.days, styles.mobile)}>
        {t("addProperty.openOn")}
        <ul>
          {period &&
            dayKeys.map((key, index) => (
              <li key={index}>
                <a
                  onClick={() => onPricingSettingsPeriodChange(key)}
                  className={classNames({
                    [styles.active]: period[key],
                  })}
                >
                  {smallestDayNames?.[key]}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default DailyParking;
