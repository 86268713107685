"use strict";

import React, { useMemo } from "react";
import { Modal } from "@citifyd/style";

import PhoneVerification from "../../react/components/PhoneVerification";
import { useAuthentication } from "../../react/contexts/authentication";

const VerifyPhoneNumberModal = ({
  user,
  title,
  open,
  onClose,
  logoutLink,
  closeOnEscape,
}) => {
  const { logout, reloadUser } = useAuthentication();
  const onVerify = async () => {
    await reloadUser();
    onClose();
  };

  const onLogout = useMemo(() => {
    if (logoutLink) {
      return () => {
        logout();
        window.location.reload();
      };
    } else {
      return null;
    }
  }, [logoutLink]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeOnEscape={closeOnEscape}
      closeIcon={!onLogout}
    >
      <PhoneVerification
        onLogout={onLogout}
        onVerify={onVerify}
        user={user}
        title={title}
        onCancel={onClose}
      />
    </Modal>
  );
};

export default VerifyPhoneNumberModal;
