"use strict";

import React from "react";
import { Button, Modal, Text } from "@citifyd/style";
import { useTranslator } from "../../../react/hooks";

const StepMigrationResult = ({ migrationResult, onClose }) => {
  const t = useTranslator();

  return (
    <>
      <Modal.Title>{t("modalUserMigration.result")}</Modal.Title>
      <Modal.Content>
        <Text variant="subtitle" textAlign="center">
          {migrationResult}
        </Text>
      </Modal.Content>
      <Modal.Actions display="block">
        <Button
          onMouseDown={onClose}
          type="button"
          justifyContent="center"
          extraPadding
          uppercase
          textColor="white"
          appearance="secondary"
        >
          {t("commonButtons.close")}
        </Button>
      </Modal.Actions>
    </>
  );
};

export default StepMigrationResult;
