import gql from "graphql-tag";

export default gql`
  query {
    admin_listPermitPortals {
      edges {
        node {
          id
          name
          namespace
        }
      }
    }
  }
`;
