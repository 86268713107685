"use strict";

import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "@citifyd/style";
import PageHeader from "../../shared/react/components/PageHeader";
import { useService, useTranslator } from "../../shared/react/hooks";

import { getCountries } from "../../shared/api";

import LoadingManager from "../../shared/react/components/LoadingManager";

import ApolloClient from "../../shared/services/apolloClient";
import { useLazyQuery } from "@apollo/client";

import ADMIN_GET_PERMIT_PORTAL_USER from "../../graphql/permitPortal/queries/AdminGetPermitPortalUser";
import ADMIN_LIST_PERMIT_PORTALS from "../../graphql/permitPortal/queries/AdminListPermitPortals";

import Body from "./components/Body.jsx";
import { portalToOption } from "./utils";

const apolloClient = ApolloClient();

const EditUser = () => {
  const t = useTranslator();
  const $state = useService("$state");
  const [hasError, setHasError] = useState(false);
  const [countries, setCountries] = useState(null);

  const isEditing = Boolean($state.params.id);

  const [getPermitPortalUser, { data: userData }] = useLazyQuery(
    ADMIN_GET_PERMIT_PORTAL_USER,
    {
      client: apolloClient,
      fetchPolicy: "network-only",
    }
  );

  const [getPermitPortals, { data: permitPortalsData }] = useLazyQuery(
    ADMIN_LIST_PERMIT_PORTALS,
    {
      client: apolloClient,
      fetchPolicy: "cache-first",
    }
  );

  const permitPortals = useMemo(() => {
    const edges = permitPortalsData?.admin_listPermitPortals?.edges;
    return edges?.map((edge) => portalToOption(edge.node));
  }, [permitPortalsData]);

  useEffect(() => {
    if (isEditing) loadUser();
    loadCountries();
    loadPermitPortals();
  }, []);

  const loadUser = async () => {
    try {
      await getPermitPortalUser({
        variables: { id: $state.params.id },
      });
    } catch {
      setHasError(true);
    }
  };

  const loadCountries = async () => {
    try {
      const countries = await getCountries();
      setCountries(countries);
    } catch (err) {
      setHasError(true);
    }
  };

  const loadPermitPortals = async () => {
    try {
      await getPermitPortals();
    } catch {
      setHasError(true);
    }
  };

  const isReady = permitPortals && countries && (isEditing ? userData : true);

  return (
    <>
      <Grid>
        <PageHeader>
          <PageHeader.Title
            title={
              isEditing
                ? t("permits.permitters.editUser")
                : t("permits.permitters.addUser")
            }
            defaultState={isEditing ? "user-permitters" : "permitters"}
            defaultParams={{ id: $state.params.id }}
            weight="300"
          />
        </PageHeader>
        {!isReady && (
          <LoadingManager isLoading={!hasError} hasError={hasError} />
        )}
        {isReady && (
          <Body
            apolloClient={apolloClient}
            countries={countries}
            isEditing={isEditing}
            userData={userData?.admin_getPermitPortalUser}
            permitPortals={permitPortals}
          />
        )}
      </Grid>
    </>
  );
};

export default EditUser;
