import { find, get, every, groupBy, map } from "lodash";
import { createAssistedPurchase } from "../../shared/api";
import { getService } from "../../shared/react/utils";
import { getTranslator } from "../../shared/services/languages";

import { showAlert } from "../../shared/services/helper";

export const parseEvents = (events) => {
  return events.map((event) => {
    event.lots.forEach((lot) => {
      const regular = find(lot.spaces, { name: "regular" });
      const max = get(regular, "max", 0);
      const occupied = get(regular, "occupied", 0);

      lot.soldOut = max - occupied === 0;
    });

    event.soldOut = every(event.lots, "soldOut");

    return event;
  });
};

export const mountBundleGroups = (bundles) => {
  bundles.forEach((bundle) => {
    if (!bundle.groupName) {
      bundle.groupName = Math.random();
      bundle.noGroup = true;
    }
  });

  const bundlesByGroup = groupBy(bundles, "groupName");

  return map(bundlesByGroup, (bundles, groupName) => {
    return {
      name: bundles[0].noGroup ? bundles[0].name : groupName,
      bundles: bundles,
      id: bundles[0].id,
    };
  });
};

export const hasSelectedItems = ({ selectedBundles, selectedEvents }) =>
  _.some(selectedBundles, "selected") || _.some(selectedEvents, "selected");

export const hasVehicleRequired = (currentSeletedEvent) => {
  const selected = currentSeletedEvent.filter(
    ({ selectedLot }) => selectedLot.vehicleRequired
  );

  return selected.length > 0;
};

export const getBundlesValues = (selectedBundles) =>
  _(selectedBundles)
    .filter("selected")
    .map(function (bundle) {
      return _.pick(bundle, "id");
    })
    .value();

export const getEventsValues = (selectedEvents) =>
  _(selectedEvents)
    .filter("selected")
    .map(function (event) {
      return {
        eventId: event.id,
        lotId: event.selectedLot.id,
        availabilityId: event.selectedLot.availability.id,
        vehicleRequired: event.selectedLot.vehicleRequired,
      };
    })
    .value();

export const onFormSubmit = async ({ values, vehicleId }) => {
  const $stateParams = getService("$stateParams");
  const { id: userId } = $stateParams;

  const {
    selectedCardId,
    reason,
    selectedBundles,
    selectedEvents,
    requestedByEmail,
    requestedByPhone,
  } = values;

  const bundles = getBundlesValues(selectedBundles);
  const events = getEventsValues(selectedEvents);

  let formatedEvents = events.map((event) =>
    event.vehicleRequired
      ? {
          ...event,
          vehicle: { vehicleId: vehicleId },
        }
      : event
  );

  var data = {
    bundles: bundles,
    events: formatedEvents,
    cardId: selectedCardId,
    reason: reason,

    extra: {
      requestedByEmail: requestedByEmail,
      requestedByPhone: requestedByPhone,
    },
  };

  return createAssistedPurchase(userId, data)
    .then((response) => ({
      data: response,
      events: events,
      bundles: bundles,
    }))
    .catch((err) => ({ error: err }));
};

export const validateForm = (values) => {
  const t = getTranslator();

  const {
    selectedCardId,
    reason,
    selectedBundles,
    selectedEvents,
    licensePlate,
  } = values;

  if (!hasSelectedItems({ selectedBundles, selectedEvents })) {
    showAlert(t("userPurchaseTicket.eventsRequired"));
    return false;
  }

  if (!selectedCardId) {
    showAlert(t("userPurchaseTicket.paymentMethodRequired"));
    return false;
  }
  if (!hasVehicleRequired && !licensePlate.plate) {
    showAlert(t("userPurchaseTicket.vehicleRequired"));
    return false;
  }
  if (reason.trim() === "") {
    showAlert(t("userPurchaseTicket.reasonRequired"));
    return false;
  }

  return true;
};
