import React from "react";
import { Radio } from "@citifyd/style";

import { getTranslator } from "../../../../shared/services/languages";
import styles from "./TicketExchangeConfirmationPayment.module.scss";

export const getData = (
  canSeeCardExpiration,
  cards,
  selectedCardId,
  handleSelectCard,
  complimentaryOption
) => {
  const t = getTranslator();

  let columns = [
    {
      key: "cardRadio",
      value: "",
      width: "1%",
    },
    {
      key: "card",
      value: t("ticketExchange.card"),
    },
    {
      key: "cardNumber",
      value: t("ticketExchange.cardNumber"),
      width: "55%",
    },
  ];

  if (canSeeCardExpiration) {
    columns.push({
      key: "expiration",
      value: t("ticketExchange.expiration"),
      width: "20%",
    });
  }

  const data = cards.map((card) => ({
    onClick: () => handleSelectCard(card.id),
    cardRadio: {
      value: <Radio small checked={card.id === selectedCardId} />,
      noVerticalPadding: true,
      verticalAlign: "middle",
    },
    card: {
      value: card.brand,
    },
    cardNumber: {
      value: `•••• •••• •••• ${card.last4}`,
    },
    ...(canSeeCardExpiration && {
      expiration: {
        value: `${card.expMonth}/${card.expYear}`,
      },
    }),
  }));

  const dataFormatted = [
    ...data,
    {
      className: styles.transparent,
      cardRadio: {
        value: <hr className={styles.divisor} />,
        colSpan: 4,
        noPadding: true,
      },
      card: {
        hide: true,
      },
      cardNumber: {
        hide: true,
      },
      expiration: {
        hide: true,
      },
    },
    {
      onClick: () => handleSelectCard("_complimentary"),
      cardRadio: {
        value: <Radio small checked={"_complimentary" === selectedCardId} />,
        noVerticalPadding: true,
        verticalAlign: "middle",
      },
      card: {
        value: t("ticketExchange.exchangeComplimentaryOf", {
          name: complimentaryOption.name,
        }),
        colSpan: 3,
      },
      cardNumber: {
        hide: true,
      },
      expiration: {
        hide: true,
      },
    },
  ];

  return {
    columns,
    data: dataFormatted,
  };
};
