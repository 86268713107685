"use strict";

import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { some } from "lodash";
import { Button } from "@citifyd/style";

import StickyFooter from "../../../../shared/react/components/StickyFooter";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import PageHeader from "../../../../shared/react/components/PageHeader";
import { useTranslator } from "../../../../shared/react/hooks";
import TicketExchangeConfirmationSummary from "../TicketExchangeConfirmationSummary";
import TicketExchangeConfirmationNoPayment from "../TicketExchangeConfirmationNoPayment";
import TicketExchangeConfirmationRefund from "../TicketExchangeConfirmationRefund";
import TicketExchangeConfirmationPayment from "../TicketExchangeConfirmationPayment";
import TicketExchangeConfirmationReason from "../TicketExchangeConfirmationReason";
import { getData, calculateRefundPreview } from "./utils";
import {
  calculateTicketRefundPreview,
  getAllCards,
} from "../../../../shared/api";

const TicketExchangeConfirmation = ({ ticket, setStep }) => {
  const t = useTranslator();
  const { values, handleSubmit } = useFormikContext();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState();
  const [cards, setCards] = useState([]);
  const [totalChargedOrRefunded, setTotalChargedOrRefunded] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState(
    t("ticketExchange.exchangingPass")
  );

  const [refundState, setRefundState] = useState(null);
  const [paymentState, setPaymentState] = useState(null);

  const { selectedEvent, selectedLot, selectedCardId } = values;
  const { title, reasonLabel, rateDifference, newRate, currentRate } = getData(
    ticket,
    selectedEvent,
    selectedLot
  );

  const shouldShowSubmitButton = rateDifference <= 0 || selectedCardId;

  const loadCards = async () => {
    setIsLoading(true);
    setLoadingMessage(t("ticketExchange.loadingPaymentMethods"));

    try {
      const response = await getAllCards(ticket.purchaser.id);
      const cards = response.cards;

      const canSeeCardExpiration = some(cards, (card) => card.expMonth);
      const complimentaryOption = response.complimentaryOption;

      setCards(cards);
      setTotalChargedOrRefunded(rateDifference);
      setPaymentState({
        canSeeCardExpiration,
        complimentaryOption,
      });
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
      setLoadingMessage("");
    }
  };

  const calculateRefund = async () => {
    setIsLoading(true);
    setLoadingMessage(t("ticketExchange.calculatingValues"));

    try {
      const response = await calculateTicketRefundPreview(ticket.id, {
        params: {
          refunder: "seller",
          feeRetentionMethod: "no_retention",
          refundAmount: Math.abs(rateDifference),
        },
      });

      const {
        transfersReversedAmount,
        refundDestinations,
        canSeeRefundCardExpiration,
      } = calculateRefundPreview(response);

      setRefundState({
        transfersReversedAmount,
        refundDestinations,
        canSeeRefundCardExpiration,
      });
      setTotalChargedOrRefunded(rateDifference + transfersReversedAmount);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
      setLoadingMessage("");
    }
  };

  const init = () => {
    setHasError(false);

    if (rateDifference > 0) {
      loadCards();
      return;
    } else if (rateDifference < 0) {
      calculateRefund();
      return;
    }

    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
        onClickTryAgain={init}
      />
    );
  }

  const renderPaymentDetails = () => {
    if (totalChargedOrRefunded === 0) {
      return (
        <TicketExchangeConfirmationNoPayment rateDifference={rateDifference} />
      );
    }

    return totalChargedOrRefunded > 0 ? (
      <TicketExchangeConfirmationPayment
        ticket={ticket}
        canSeeCardExpiration={paymentState?.canSeeCardExpiration}
        cards={cards}
        setCards={setCards}
        complimentaryOption={paymentState?.complimentaryOption}
      />
    ) : (
      <TicketExchangeConfirmationRefund
        canSeeRefundCardExpiration={refundState?.canSeeRefundCardExpiration}
        refundDestinations={refundState?.refundDestinations}
        ticket={ticket}
      />
    );
  };

  return (
    <>
      <PageHeader>
        <PageHeader.Title title={title} onClick={() => setStep(2)} />
      </PageHeader>

      <TicketExchangeConfirmationSummary
        ticket={ticket}
        currentRate={currentRate}
        newRate={newRate}
        transfersReversedAmount={refundState?.transfersReversedAmount}
        totalChargedOrRefunded={totalChargedOrRefunded}
        rateDifference={rateDifference}
      />

      {renderPaymentDetails()}

      <TicketExchangeConfirmationReason reasonLabel={reasonLabel} />

      {shouldShowSubmitButton && (
        <StickyFooter justifyContent="flex-end">
          <Button uppercase onMouseDown={handleSubmit} extraPadding>
            {t("ticketExchange.exchange")}
          </Button>
        </StickyFooter>
      )}
    </>
  );
};

export default TicketExchangeConfirmation;
