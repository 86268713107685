"use strict";
import React, { useEffect } from "react";
import { LoadingIndicator } from "@citifyd/style";

import {
  useAdminGetAccountPermissionInternalUserLazyQuery,
  useAdminGetAccountPermissionUserInvitationLazyQuery,
} from "../../graphql/generated/graphql";

import ApolloClient from "../../shared/services/apolloClient";
import { useService } from "../../shared/react/hooks";
import { useAuthentication } from "../../shared/react/contexts/authentication";

import { Form } from "./Form";

const apolloClient = ApolloClient();

const AddManagementPermission = () => {
  const { user } = useAuthentication();
  const $state = useService("$state");
  const isEditing = Boolean($state.params.id);
  const isPending = $state.params.pending;

  const [
    getUserData,
    {
      data: userManagementPermissionData,
      loading: loadingManagementPermission,
    },
  ] = useAdminGetAccountPermissionInternalUserLazyQuery({
    client: apolloClient,
    fetchPolicy: "network-only",
  });

  const [
    getPendingUserData,
    {
      data: userPendingManagementPermissionData,
      loading: loadingPendingManagementPermission,
    },
  ] = useAdminGetAccountPermissionUserInvitationLazyQuery({
    client: apolloClient,
    fetchPolicy: "network-only",
  });

  const userData =
    userManagementPermissionData?.getAccountPermissionInternalUser ||
    userPendingManagementPermissionData?.getAccountPermissionUserInvitation;

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    if (isEditing && !isPending) {
      await getUserData({
        variables: {
          userId: Number($state.params.id),
          organizationId: user.organization.id,
        },
      });
    } else if (isEditing && isPending) {
      await getPendingUserData({
        variables: {
          inviteId: Number($state.params.id),
          organizationId: user.organization.id,
        },
      });
    }
  };

  if (loadingManagementPermission || loadingPendingManagementPermission)
    return <LoadingIndicator />;
  if (isEditing && !userData) return null;

  return (
    <Form userData={userData} isEditing={isEditing} isPending={isPending} />
  );
};

export default AddManagementPermission;
