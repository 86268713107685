"use strict";

import { useFormik } from "formik";
import * as yup from "yup";
import { updateUser } from "../../api";
import { getTranslator } from "../../services/languages";
import { showErrorAlert } from "../../services/helper";

const useAdminEditUserPhoneNumber = ({
  user,
  originalPhoneNumber,
  originalPhoneCountryCode,
  onClose,
  trackLoading,
  untrackLoading,
}) => {
  return useFormik({
    initialValues: {
      phoneNumber: user.phoneNumber,
      phoneCountryCode: user.phoneCountryCode,
      clearPhoneNumber: false,
      skipTriggerMigration: true,
      forcePhoneNumberVerification: false,
    },
    validationSchema: yup.object().shape({
      phoneNumber: yup.string().required(),
    }),
    onSubmit: async (values) => {
      const t = getTranslator();
      if (
        !values.clearPhoneNumber &&
        (!values.phoneNumber || values.phoneNumber.trim() === "")
      ) {
        return alert(t("modalEditUserPhoneNumber.errors.phoneNumberRequired"));
      }

      if (
        !values.clearPhoneNumber &&
        !values.forcePhoneNumberVerification &&
        values.phoneNumber === originalPhoneNumber &&
        values.phoneCountryCode === originalPhoneCountryCode
      ) {
        return alert(
          t("modalEditUserPhoneNumber.errors.phoneNumberChangeRequired")
        );
      }

      trackLoading("users");

      const phoneNumber = values.clearPhoneNumber
        ? null
        : values.phoneNumber.replace(/[^0-9]/g, "");
      const phoneCountryCode = values.phoneCountryCode;
      const forceSkipMigrationTrigger =
        values.clearPhoneNumber || values.skipTriggerMigration;

      const body = {
        phoneNumber,
        phoneCountryCode,
        forceSkipMigrationTrigger,
      };

      if (!values.clearPhoneNumber && values.forcePhoneNumberVerification) {
        body.verifiedPhoneNumber = true;
      }
      try {
        await updateUser(user.id, body);

        untrackLoading("users");
        onClose({ userUpdated: true });
      } catch (response) {
        showErrorAlert(response.data);
      } finally {
        untrackLoading("users");
      }
    },
  });
};

export default useAdminEditUserPhoneNumber;
