"use strict";

import React from "react";
import { Icon, Text, Grid } from "@citifyd/style";
import moment from "moment-timezone";
import CurrencyFormatter from "@citifyd/currency-formatter";

import TransactionExtraInformation from "../../../../shared/react/components/TransactionExtraInformation";
import Table from "../../../../shared/react/components/Table";
import PaymentMethod from "../../../../shared/react/components/PaymentMethod";
import Address from "../../../../shared/react/components/Address";
import UserLink from "../../../../shared/react/components/UserLink";
import GenerateReceiptModal from "../../../../shared/modals/GenerateReceiptModal";
import Dictionary, {
  DictionaryItem,
} from "../../../../shared/react/components/Dictionary";
import TicketStatusBadge from "../../../../shared/react/components/TicketStatusBadge";
import BoxContent from "../../../../shared/react/components/BoxContent";
import { useTranslator } from "../../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../../shared/services/languages";
import styles from "./BundlePurchaseData.module.scss";
import { getLastName } from "../../../../shared/utils/utils";
import { getData } from "./utils";
import { useModal } from "../../../../shared/react/contexts/modal";
import Permissions from "../../../../shared/services/permissions";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const BundlePurchaseData = ({ bundlePurchase }) => {
  const t = useTranslator();
  const { openModal } = useModal();
  const { permissions } = useAuthentication();
  const language = getCurrentLanguage();

  const downloadTransactionReceipt = (e) => {
    openModal(GenerateReceiptModal, {
      bundlePurchaseId: bundlePurchase.id,
      forceLanguageSelection: e?.shiftKey,
    });
  };

  const lastNameOnCard = getLastName(
    bundlePurchase?.purchaseTransaction?.card?.name
  );

  const { columns, data } = getData(bundlePurchase);

  return (
    <>
      <BoxContent>
        <BoxContent.Header
          title={t("bundlePurchase.transactionNumber", {
            transactionNumber: bundlePurchase.transactionNumber,
          })}
        >
          {Permissions.userMayExportReceipts(permissions) && (
            <BoxContent.HeaderActions>
              <div
                className={styles.downloadPdfReceipt}
                title={t("transaction.downloadPdfReceipt")}
                onClick={(e) => downloadTransactionReceipt(e)}
              >
                <Icon icon={["far", "file"]} size="h2" appearance="white" />
              </div>
            </BoxContent.HeaderActions>
          )}
        </BoxContent.Header>
        <BoxContent.Content>
          <div className={styles.ticketHeader}>
            <Text component="span" variant="h3" weight="700">
              {bundlePurchase.bundle.name}
            </Text>
            <TicketStatusBadge statusTag="purchased" />
          </div>

          <Grid.Row>
            <Grid.Col xs={12} md={6}>
              <Dictionary withDivisor={false}>
                <DictionaryItem
                  textComponent="span"
                  label={t("bundlePurchase.purchasedBy")}
                >
                  <UserLink appearance="tertiary" user={bundlePurchase.user} />
                </DictionaryItem>

                <DictionaryItem
                  textComponent="span"
                  label={t("bundlePurchase.eventDate")}
                >
                  {t("bundlePurchase.multiple")}
                </DictionaryItem>

                <DictionaryItem
                  textComponent="span"
                  label={t("bundlePurchase.eventTime")}
                >
                  {t("bundlePurchase.multiple")}
                </DictionaryItem>

                <DictionaryItem
                  textComponent="span"
                  label={t("bundlePurchase.venue")}
                >
                  {bundlePurchase.tickets[0]?.event?.venueName}
                </DictionaryItem>

                <DictionaryItem
                  textComponent="span"
                  label={t("bundlePurchase.lot")}
                  multiline
                >
                  {bundlePurchase.bundle.lot.name} <br />
                  <Address entity={bundlePurchase.bundle.lot} format="full" />
                </DictionaryItem>

                <DictionaryItem
                  textComponent="span"
                  label={t("bundlePurchase.passDuration")}
                >
                  {t("bundlePurchase.multiple")}
                </DictionaryItem>
              </Dictionary>
            </Grid.Col>

            <Grid.Col xs={12} md={6}>
              <Dictionary withDivisor={false}>
                <DictionaryItem
                  textComponent="span"
                  label={t("bundlePurchase.price")}
                >
                  {CurrencyFormatter.format(
                    bundlePurchase.rate.value + bundlePurchase.rate.fee,
                    {
                      currency: bundlePurchase.currency,
                      language,
                    }
                  )}
                </DictionaryItem>

                <Item label={t("bundlePurchase.purchaseDate")}>
                  {bundlePurchase.purchaseTransaction &&
                    t("defaultFormats.date", {
                      date: moment.tz(
                        bundlePurchase.purchaseTransaction.createdAt,
                        bundlePurchase.lot?.timezoneName ||
                          bundlePurchase.tickets[0]?.event?.timezoneName
                      ),
                    })}
                </Item>

                <Item label={t("bundlePurchase.purchaseTime")}>
                  {bundlePurchase.purchaseTransaction &&
                    t("defaultFormats.time", {
                      date: moment.tz(
                        bundlePurchase.purchaseTransaction.createdAt,
                        bundlePurchase.lot?.timezoneName ||
                          bundlePurchase.tickets[0]?.event?.timezoneName
                      ),
                    })}
                </Item>

                <Item label={t("bundlePurchase.paymentMethod")}>
                  {bundlePurchase.purchaseTransaction.card && (
                    <span className={styles.payment}>
                      <PaymentMethod
                        card={bundlePurchase.purchaseTransaction.card}
                      />
                    </span>
                  )}
                </Item>

                <Item label={t("bundlePurchase.lastName")}>
                  {lastNameOnCard}
                </Item>

                {bundlePurchase?.purchaseTransaction?.card?.expMonth && (
                  <DictionaryItem
                    textComponent="span"
                    label={t("bundlePurchase.expirationDate")}
                  >
                    {bundlePurchase.purchaseTransaction.card.expMonth}/
                    {bundlePurchase.purchaseTransaction.card.expYear}
                    {!bundlePurchase.purchaseTransaction.card && "—"}
                  </DictionaryItem>
                )}
                <Item label={t("bundlePurchase.paymentStatus")}>
                  {bundlePurchase.purchaseTransaction &&
                    t("bundlePurchase.charged")}
                </Item>
              </Dictionary>
            </Grid.Col>
          </Grid.Row>
        </BoxContent.Content>
        {bundlePurchase.assistedPurchase && (
          <BoxContent.Footer>
            <Text weight="700">
              {t("transaction.fulfilledBy", {
                name: bundlePurchase.assistedPurchase.purchaser?.name,
              })}
            </Text>
            <Text>{bundlePurchase.assistedPurchase.reason}</Text>
            <Text italic>
              <TransactionExtraInformation
                data={bundlePurchase.assistedPurchase.extra}
              />
            </Text>
          </BoxContent.Footer>
        )}
      </BoxContent>

      <div className={styles.table}>
        <Text component="h5" variant="subtitle" gutterBottom>
          {t("bundlePurchase.bundleEvents", {
            bundleName: bundlePurchase.bundle.name,
          })}
        </Text>

        <Table columns={columns} data={data} responsive striped clickable />
      </div>
    </>
  );
};

const Item = ({ children, label }) => (
  <DictionaryItem textComponent="span" label={label}>
    {children || "—"}
  </DictionaryItem>
);

export default BundlePurchaseData;
