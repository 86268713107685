"use strict";

import React, { useMemo } from "react";
import className from "classnames";

import "./style.scss";

export default function VehicleColorIndicator({ vehicle }) {
  const colorClassName = useMemo(() => {
    if (vehicle && vehicle.color) {
      return vehicle.color.toLowerCase().replace("/", "_");
    }

    return null;
  }, [vehicle]);

  if (!colorClassName) {
    return null;
  }

  return (
    <span
      className={className("component-vehicle-color-indicator", colorClassName)}
    />
  );
}
