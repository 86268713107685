"use strict";

import React, { useEffect, useRef } from "react";
import { Icon } from "@citifyd/style";
import styles from "./HorizontalScrollable.module.scss";

const HorizontalScrollable = ({ children, step = 500 }) => {
  // We're using refs instead of React's declarative API because many DOM
  // changes that we need to do are result of scroll listeners, which may
  // be triggered many times per second. Refs require imperative code, but
  // allow for faster performance in this case.

  const wrapperRef = useRef();
  const contentRef = useRef();

  const leftButtonRef = useRef();
  const rightButtonRef = useRef();

  const leftButtonContainerRef = useRef();
  const rightButtonContainerRef = useRef();

  const scroll = (direction) => {
    let newScrollLeft = wrapperRef.current.scrollLeft;

    if (direction === "left") {
      newScrollLeft -= step;
    } else {
      newScrollLeft += step;
    }

    wrapperRef.current.scroll({
      left: newScrollLeft,
      behavior: "smooth",
    });

    updateArrowsVisibility(newScrollLeft);
  };

  const updateArrowsVisibility = (scrollLeft) => {
    if (!leftButtonRef.current || !rightButtonRef.current) return;

    if (scrollLeft === undefined) {
      scrollLeft = wrapperRef.current.scrollLeft;
    }

    if (scrollLeft <= 0) {
      leftButtonRef.current.classList.add(styles.disabled);
    } else {
      leftButtonRef.current.classList.remove(styles.disabled);
    }

    if (
      scrollLeft >=
      contentRef.current.scrollWidth - wrapperRef.current.offsetWidth
    ) {
      rightButtonRef.current.classList.add(styles.disabled);
    } else {
      rightButtonRef.current.classList.remove(styles.disabled);
    }
  };

  // Ensures that the arrows don't appear fixed incorrectly when the user
  // hasn't reached the table yet.
  useEffect(() => {
    const onScroll = () => {
      const rect = wrapperRef.current.getBoundingClientRect();
      const posY = rect.top + window.scrollY;

      if (window.scrollY > posY) {
        leftButtonRef.current.style.position = "fixed";
        rightButtonRef.current.style.position = "fixed";
      } else {
        leftButtonRef.current.style.position = "absolute";
        rightButtonRef.current.style.position = "absolute";
      }
    };

    window.addEventListener("scroll", onScroll);

    onScroll();
    updateArrowsVisibility();

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  // Ensures that the
  useEffect(() => {
    if (!contentRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      const { offsetHeight } = contentRef.current;

      let height;

      if (offsetHeight < window.innerHeight) {
        height = offsetHeight + "px";
      } else {
        height = "100vh";
      }

      leftButtonContainerRef.current.style.height = height;
      rightButtonContainerRef.current.style.height = height;
    });

    resizeObserver.observe(contentRef.current);

    return () => resizeObserver.disconnect();
  }, [contentRef.current]);

  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <div className={styles.scrollContainer}>
        <div style={{ position: "relative" }} ref={leftButtonContainerRef}>
          <button
            type="button"
            className={styles.arrow}
            onClick={() => scroll("left")}
            style={{
              position: "fixed",
              top: "50%",
              padding: "20px 20px 20px 0px",
              marginLeft: "-10px",
            }}
            ref={leftButtonRef}
          >
            <Icon appearance="gray_dark" icon="arrow-circle-left" size="3x" />
          </button>
        </div>
      </div>
      <div className={styles.wrapper} ref={wrapperRef}>
        <div ref={contentRef}>{children}</div>
      </div>
      <div className={styles.scrollContainer}>
        <div style={{ position: "relative" }} ref={rightButtonContainerRef}>
          <button
            type="button"
            className={styles.arrow}
            onClick={() => scroll("right")}
            ref={rightButtonRef}
            style={{
              position: "fixed",
              top: "50%",
              marginLeft: "-30px",
              padding: "20px 20px 20px 0px",
            }}
          >
            <Icon appearance="gray_dark" icon="arrow-circle-right" size="3x" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HorizontalScrollable;
