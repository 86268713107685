"use strict";

import React from "react";
import { List, Text } from "@citifyd/style";
import classNames from "classnames";

import { useTranslator } from "../../../../shared/react/hooks";
import styles from "../../Organization.module.scss";
import Address from "../../../../shared/react/components/Address";

const OrganizationVenues = ({ organization }) => {
  const t = useTranslator();

  if (organization.venues.length <= 0) {
    return null;
  }

  return (
    <div className={styles.section}>
      <Text variant="h2" component="h2" className={styles.subtitle}>
        {t("organization.venues")}
      </Text>
      <List className={classNames(styles.list, styles.gutterBottomItem)}>
        {organization.venues.map((venue) => (
          <List.Item key={venue.id}>
            <span>
              <Text weight="700">{venue.name}</Text>
              {venue.address && <Address entity={venue} format="full_inline" />}
            </span>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default OrganizationVenues;
