"use strict";

import _ from "lodash";
import { getCurrentLanguage } from "../services/languages";

export const getPrivacyPolicyUrl = () => {
  const language = getCurrentLanguage();
  return `https://policies.citifyd.com/privacy?lang=${language}`;
};

export const getTermsAndConditionsUrl = () => {
  const language = getCurrentLanguage();
  return `https://policies.citifyd.com/terms?lang=${language}`;
};

export const getMeasurementSystemFromCountryCode = (countryCode) => {
  countryCode = countryCode.toLowerCase();
  return countryCode === "us" ? "imperial" : "metric";
};
