"use strict";

import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useService } from "../../hooks";

export default function RouterLink({
  state = "",
  params = {},
  options = { replace: false },
  children,
  ...props
}) {
  const $state = useService("$state");
  const url = useMemo(
    () => $state.href(state, params, options),
    [state, params, options]
  );

  return (
    <Link {...props} to={url} replace={options.replace}>
      {children}
    </Link>
  );
}
