"use strict";
import React, { useState } from "react";
import { Grid } from "@citifyd/style";
import BoxContent from "../../../shared/react/components/BoxContent";
import Dictionary, {
  DictionaryItem,
} from "../../../shared/react/components/Dictionary";
import { useTranslator } from "../../../shared/react/hooks";
import { getTranslator } from "../../../shared/services/languages";
import { formatValue, displayDate } from "./functions/utils";
import RouterLink from "../../../shared/react/components/RouterLink";
import { useAuthentication } from "../../../shared/react/contexts/authentication";

const PayoutTable = ({ payout, absoluteAmount }) => {
  const t = useTranslator();
  const { user } = useAuthentication();

  const [isLoadingPayout, setIsLoadingPayout] = useState(false);

  return (
    <BoxContent>
      <BoxContent.Header title={pageTitle({ payout, isLoadingPayout })} />
      <BoxContent.Content>
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Dictionary withDivisor={false} border>
                <DictionaryItem
                  textComponent="span"
                  label={t("payout.organization.label")}
                >
                  <RouterLink
                    underline={true}
                    appearance="tertiary"
                    state="organization"
                    options={{ enableBackLink: true }}
                    params={{ id: payout?.organization.id }}
                  >
                    {payout?.organization.name}
                  </RouterLink>
                </DictionaryItem>
                {payout?.amount >= 0 && (
                  <DictionaryItem
                    textComponent="span"
                    label={t("payout.payoutAmount")}
                  >
                    {formatValue({
                      value: payout?.amount,
                      currency: payout?.currency,
                    })}
                  </DictionaryItem>
                )}
                {payout?.amount < 0 && (
                  <DictionaryItem
                    textComponent="span"
                    label={t("payout.debitAmount")}
                  >
                    {formatValue({
                      value: absoluteAmount,
                      currency: payout?.currency,
                    })}
                  </DictionaryItem>
                )}
                <DictionaryItem
                  textComponent="span"
                  label={t("payout.status.label")}
                >
                  {payout?.status &&
                    t(`payout.status.values.${payout?.status}`)}
                </DictionaryItem>
              </Dictionary>
            </Grid.Col>
            <Grid.Col>
              <Dictionary withDivisor={false} border>
                <DictionaryItem label={t("payout.depositTime.label")}>
                  {payout?.executedAt
                    ? displayDate({
                        date: payout?.executedAt,
                        format: "datetimeWithTimezone",
                        user,
                      })
                    : t("payout.depositTime.values.pending")}
                </DictionaryItem>

                <DictionaryItem
                  label={
                    payout?.amount < 0
                      ? t("payout.expectedArrivalDate.debitLabel")
                      : t("payout.expectedArrivalDate.label")
                  }
                >
                  {payout?.expectedArrivalDate
                    ? displayDate({
                        date: payout?.expectedArrivalDate,
                        format: "datetimeWithTimezone",
                        user,
                      })
                    : t("payout.depositTime.values.pending")}
                </DictionaryItem>
              </Dictionary>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </BoxContent.Content>
    </BoxContent>
  );
};

const pageTitle = ({ payout, isLoadingPayout }) => {
  const t = getTranslator();

  if (isLoadingPayout) {
    return t("payout.pageTitleLoading");
  } else if (payout?.amount < 0) {
    return t("payout.pageTitleDebit", {
      payoutDescription: payout?.description,
    });
  } else {
    return t("payout.pageTitlePayment", {
      payoutDescription: payout?.description,
    });
  }
};

export default PayoutTable;
