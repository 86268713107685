"use strict";

import React from "react";
import { Grid } from "@citifyd/style";
import PageHeader from "../../../shared/react/components/PageHeader";
import AddEventForm from "./AddEventForm/AddEventForm.jsx";
import { useTranslator, useService } from "../../../shared/react/hooks";
import Mode from "../mode";

const AddEvent = () => {
  const t = useTranslator();
  const { eventId } = useService("$stateParams");
  const mode = eventId ? Mode.EDIT : Mode.ADD;

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          weight="300"
          title={
            mode === Mode.ADD
              ? t("addEvent.createEvent")
              : t("addEvent.editEventPageTitle")
          }
        />
      </PageHeader>
      <AddEventForm mode={mode} eventId={eventId} />
    </Grid>
  );
};

export default AddEvent;
