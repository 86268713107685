"use strict";

import React, { useState, useEffect, Fragment } from "react";
import CurrencyFormatter from "@citifyd/currency-formatter";
import { Text, Button } from "@citifyd/style";

import BoxContent from "../BoxContent";
import Table from "../Table";
import LoadingManager from "../LoadingManager";
import { generateTimeRangeDescription } from "../../../utils/availability-allocation-utils";
import ApolloClient from "../../../../shared/services/apolloClient";

import { useTranslator, useService } from "../../hooks";
import { getCurrentLanguage } from "../../../services/languages";
import styles from "./ReservedParkingFullInformation.module.scss";

import RESERVED_PARKING_PASS_FEE_BREAKDOWN from "../../../../graphql/reservedParkingPasses/queries/ReservedParkingPassFeeBreakdown";
import ReservedParkingPassRatesModal from "../../../modals/ReservedParkingPassRatesModal";
import { useModal } from "../../contexts/modal";

const FeeBreakdownBox = () => {
  const t = useTranslator();
  const { id } = useService("$stateParams");
  const { openModal } = useModal();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [feeBreakdown, setFeeBreakdown] = useState(null);

  useEffect(() => {
    ApolloClient()
      .query({
        query: RESERVED_PARKING_PASS_FEE_BREAKDOWN,
        variables: {
          passId: parseInt(id),
        },
      })
      .then((response) => {
        if (response?.data?.reservedParkingPassFeeBreakdown) {
          setFeeBreakdown(response?.data?.reservedParkingPassFeeBreakdown);
        } else {
          setHasError(true);
        }
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const openReservedParkingPassRatesModal = () => {
    openModal(ReservedParkingPassRatesModal, {
      feeBreakdown: feeBreakdown,
    });
  };

  return (
    <BoxContent>
      <BoxContent.Header
        title={t("reservedParkingPage.feeBreakdownBox.feeBreakdown")}
      >
        <BoxContent.HeaderActions>
          <Button
            size="tiny"
            uppercase
            onClick={openReservedParkingPassRatesModal}
          >
            See rates
          </Button>
        </BoxContent.HeaderActions>
      </BoxContent.Header>
      <BoxContent.Content noHorizontalPadding>
        <FeeBreakdownContent
          feeBreakdown={feeBreakdown}
          isLoading={isLoading}
          hasError={hasError}
        />
      </BoxContent.Content>
    </BoxContent>
  );
};

const FeeBreakdownContent = ({ feeBreakdown, isLoading, hasError }) => {
  const t = useTranslator();

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  const content = [
    availabilityAllocations({ t, feeBreakdown }),
    citifydServiceFee({ t, feeBreakdown }),
    totalAmount({ t, feeBreakdown }),
  ];

  const data = content.filter((el) => el !== null && el !== undefined);

  return (
    <Fragment>
      <Table
        className={styles.breakDownTable}
        spacing
        showHeader={false}
        columns={[{ key: "title" }, { key: "amount" }]}
        data={data}
      />
    </Fragment>
  );
};

const availabilityAllocations = ({ t, feeBreakdown }) => {
  const { lot, currency, startTime, endTime } =
    feeBreakdown?.reservedParkingPass;
  const { timezoneName } = lot;

  const timeRange = generateTimeRangeDescription(
    startTime,
    endTime,
    timezoneName
  );

  return {
    title: (
      <div>
        <Text gutterBottom appearance="gray_dark" variant="small">
          {timeRange}
        </Text>
        {t("reservedParkingPage.feeBreakdownBox.reservedParkingRate")}
      </div>
    ),
    amount: (
      <div>
        <Price
          amount={feeBreakdown?.firstStep?.totalAmount}
          currency={currency}
        />
      </div>
    ),
  };
};

const citifydServiceFee = ({ t, feeBreakdown }) => {
  const { reservedParkingPass, firstStep } = feeBreakdown;
  const { currency } = reservedParkingPass;

  return {
    title: t("reservedParkingPage.feeBreakdownBox.citifydServiceFee"),
    amount: <Price amount={firstStep?.citifydFee} currency={currency} />,
  };
};

const totalAmount = ({ t, feeBreakdown }) => {
  const { reservedParkingPass, finalResult } = feeBreakdown;
  const { currency } = reservedParkingPass;

  return {
    title: <b>{t("reservedParkingPage.feeBreakdownBox.total")}</b>,
    amount: (
      <b>
        <Price amount={finalResult?.amountCharged} currency={currency} />
      </b>
    ),
  };
};

const Price = ({ amount, currency }) => {
  const language = getCurrentLanguage();
  return CurrencyFormatter.format(amount, { currency, language });
};

export default FeeBreakdownBox;
