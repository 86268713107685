"use strict";

import React, { useState, useMemo, useEffect, MutableRefObject } from "react";
import { Grid, Text } from "@citifyd/style";
import { syncPropertyConfiguration } from "../conversions/syncPropertyConfiguration";
import produce from "immer";

import { useTranslator } from "../../../shared/react/hooks";
import FormikField, {
  FIELD_TYPE,
} from "../../../shared/react/components/FormikField";

import BoxContent from "../../../shared/react/components/BoxContent";
import ImageUpload from "../../../shared/react/components/ImageUpload";
import FieldDescription from "./FieldDescription";

import styles from "./CommonStyles.module.scss";

import { permitIssuePeriodList } from "./PropertyConfiguration/permitIssuePeriodList";
import { usePermitPortalCtx } from "../hooks/usePermitPortalCtx";
import { useFormikContext } from "formik";
import { FormValues } from "../types";

const GeneralSettings = ({
  imageInputRef,
}: {
  imageInputRef: MutableRefObject<HTMLInputElement | undefined>;
}) => {
  const t = useTranslator();
  const { portalData, lots } = usePermitPortalCtx();

  const {
    values,
    errors,
    touched,
    setValues,
    setFieldValue,
    validateField,
    setFieldTouched,
  } = useFormikContext<FormValues>();

  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const errorImage = errors.hasImage;
  const touchedImage = touched.hasImage;

  useEffect(() => {
    setValues(
      produce((values) => {
        const { propertyConfiguration, properties } = values;
        values.propertyConfiguration = syncPropertyConfiguration({
          propertyConfiguration,
          properties,
        });
      })
    );
  }, [values?.properties]);

  const lotOptions = useMemo(
    () => lots.map((lot) => ({ value: lot.id, label: lot.name })),
    [lots]
  );

  useEffect(() => {
    if (portalData) {
      setCurrentImage(portalData.logo.fallbackUrl);
    }
  }, [portalData]);

  const handleImageChange = async ({ hasImage }) => {
    await setFieldValue("hasImage", hasImage);
    validateField("hasImage");
    setFieldTouched("hasImage");
  };

  return (
    <BoxContent>
      <BoxContent.Header
        title={t("permits.permitters.settings.generalSettings")}
      />
      <BoxContent.Content>
        <Grid.Row direction="row-reverse">
          <Grid.Col sm={12} lg={3}>
            <Grid.Row>
              <Grid.Col sm={12} lg={10}>
                <ImageUpload
                  onChange={handleImageChange}
                  currentImage={currentImage}
                  inputRef={imageInputRef}
                  errorImage={errorImage}
                  className={styles.image}
                  touchedImage={touchedImage}
                />
                <Thumbnail
                  errorImage={errorImage}
                  touchedImage={touchedImage}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col sm={12} lg={9}>
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <FormikField
                  as={FIELD_TYPE.INPUT}
                  name="property"
                  label={t("permits.permitters.settings.portalName")}
                />
                <FieldDescription>
                  {t("permits.permitters.settings.description.property")}
                </FieldDescription>
              </Grid.Col>

              <Grid.Col sm={12} lg={6}>
                <Grid.Row>
                  <Grid.Col xs={7} sm={9} lg={8}>
                    <FormikField
                      as={FIELD_TYPE.INPUT}
                      name="maximumLimitOfFuturePermits"
                      label={t("permits.permitters.settings.numberOfPermits")}
                      disabled={values.unlimitedMaximumLimitOfFuturePermits}
                      min="1"
                      type="number"
                    />
                    <FieldDescription>
                      {t(
                        "permits.permitters.settings.description.maximumLimitOfFuturePermits"
                      )}
                    </FieldDescription>
                  </Grid.Col>
                  <Grid.Col xs={5} sm={3} lg={4}>
                    <div className={styles.noLimit}>
                      <FormikField
                        name="unlimitedMaximumLimitOfFuturePermits"
                        label={t("permits.permitters.settings.noLimit")}
                        as={FIELD_TYPE.CHECKBOX}
                        fullWidth
                      />
                    </div>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <FormikField
                  as={FIELD_TYPE.INPUT}
                  name="urlPortal"
                  inlineLabel={t("permits.permitters.settings.url")}
                  label={t("permits.permitters.settings.urlPortal")}
                  textAlign="right"
                  fullWidth={false}
                />
                <FieldDescription>
                  {t("permits.permitters.settings.description.urlPortal")}
                </FieldDescription>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <Grid.Row>
                  <Grid.Col xs={7} sm={9} lg={8}>
                    <FormikField
                      as={FIELD_TYPE.INPUT}
                      name="maximumPermitLength"
                      type="number"
                      min="1"
                      disabled={values.unlimitedMaximumPermitLength}
                      label={t("permits.permitters.settings.permitDuration")}
                    />
                    <FieldDescription>
                      {t(
                        "permits.permitters.settings.description.maximumPermitLength"
                      )}
                    </FieldDescription>
                  </Grid.Col>
                  <Grid.Col xs={5} sm={3} lg={4}>
                    <div className={styles.noLimit}>
                      <FormikField
                        name="unlimitedMaximumPermitLength"
                        label={t("permits.permitters.settings.noLimit")}
                        as={FIELD_TYPE.CHECKBOX}
                        fullWidth
                      />
                    </div>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <FormikField
                  name="permitIssuePeriod"
                  as={FIELD_TYPE.SELECT}
                  options={permitIssuePeriodList}
                  label={t("permits.permitters.settings.permitIssuePeriod")}
                  gutterBottom
                />
                <FieldDescription>
                  {t(
                    "permits.permitters.settings.description.permitIssuePeriod"
                  )}
                </FieldDescription>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <FormikField
                  className={styles.select}
                  label={t("permits.permitters.settings.parkingProperties")}
                  as={FIELD_TYPE.MULTI_SELECT}
                  options={lotOptions}
                  gutterBottom
                  name="properties"
                />
                <FieldDescription>
                  {t("permits.permitters.settings.description.properties")}
                </FieldDescription>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row></Grid.Row>
      </BoxContent.Content>
    </BoxContent>
  );
};

const Thumbnail = ({
  errorImage,
  touchedImage,
}: {
  errorImage?: string;
  touchedImage?: boolean;
}) => {
  const t = useTranslator();

  return (
    <div className={styles.thumbnail}>
      <Text
        appearance={errorImage && touchedImage ? "error" : "gray_darker"}
        variant="body"
        weight="600"
      >
        {t("permits.permitters.settings.thumbnail")}
      </Text>
      <Text
        variant="small"
        appearance={errorImage && touchedImage ? "error" : "gray_darker"}
      >
        {t("permits.permitters.settings.thumbnailInfo", {
          width: 250,
          height: 250,
        })}
      </Text>
    </div>
  );
};

export default GeneralSettings;
