import gql from "graphql-tag";

export default gql`
  fragment BrAddress on BrAddress {
    line1
    line2
    city
    state
    postalCode
  }
`;
