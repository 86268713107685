import { pick, identity } from "lodash";
import { history } from "../../../routes";
import { convertQueryStringToObject, getFormattedQueryStrings } from "./utils";

export default {
  search: (param, value) => {
    if (param) {
      const queryStrings = {
        ...convertQueryStringToObject(history.location?.search?.substring(1)),
        ...(param && { [param]: value }),
      };

      history.replace(
        `${history.location?.pathname}?${getFormattedQueryStrings(
          pick(queryStrings, identity)
        )}`
      );
    } else {
      return (
        convertQueryStringToObject(history.location?.search?.substring(1)) || {}
      );
    }
  },
  path: () => history.location?.pathname,
};
