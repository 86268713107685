import React from "react";
import { Grid, Text, Icon } from "@citifyd/style";
import classNames from "classnames";
import BackLink from "../BackLink";
import styles from "./PageHeader.module.scss";

const PageHeader = (props) => {
  const { children, border = true, className, ...rest } = props;

  return (
    <Grid.Row
      className={classNames(styles.pageHeader, className, { bordered: border })}
      {...rest}
    >
      {children}
    </Grid.Row>
  );
};

PageHeader.Title = (props) => {
  const {
    defaultState,
    defaultParams,
    children,
    className,
    title,
    backState,
    onClick,
    ...rest
  } = props;

  return (
    <Grid.Col
      className={classNames(styles.pageHeaderTitle, className)}
      xs={12}
      sm={6}
    >
      {children}
      {defaultState || backState ? (
        <BackLink
          defaultState={defaultState}
          defaultParams={defaultParams}
          text={title}
        />
      ) : onClick ? (
        <div onClick={onClick} className={styles.button}>
          <Icon size="h1" className={styles.icon} icon="chevron-left" />
          <Text variant="h1" component="h1" weight="300">
            {title}
          </Text>
        </div>
      ) : (
        <Text variant="h1" component="h1" {...rest}>
          {title}
        </Text>
      )}
    </Grid.Col>
  );
};

PageHeader.Actions = ({ children, className, ...props }) => {
  return (
    <Grid.Col
      className={classNames(styles.pageHeaderActions, className)}
      xs={12}
      sm={6}
      {...props}
    >
      {children}
    </Grid.Col>
  );
};

PageHeader.Action = ({ children, className }) => {
  return (
    <div className={classNames(styles.pageHeaderAction, className)}>
      {children}
    </div>
  );
};

export default PageHeader;
