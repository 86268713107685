import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import ADMIN_CALCULATE_PERMIT_PASS_PRICE from "../../../../graphql/permitPass/queries/AdminCalculatePermitPassPrice";
import { formatDate } from "./utils";

const usePriceCalculator = ({ apolloClient, formikbag, properties }) => {
  const { values, errors } = formikbag;

  const { lot, startDate, startTime, endDate, endTime, typeOfPermit, price } =
    values;

  const [calculatePrice, { data, loading, error }] = useLazyQuery(
    ADMIN_CALCULATE_PERMIT_PASS_PRICE,
    {
      client: apolloClient,
      fetchPolicy: "cache-first",
    }
  );

  useEffect(() => {
    const shouldCalculate =
      lot &&
      typeOfPermit === "paid" &&
      price === "existingRates" &&
      !errors.endDate &&
      !errors.endTime &&
      !errors.startDate &&
      !errors.startTime;

    if (!shouldCalculate) {
      return;
    }
    const lotSelected = properties.find(
      (property) => property.id === parseInt(lot)
    );

    const start = formatDate(startDate, startTime, lotSelected?.timezoneName);
    const end = formatDate(endDate, endTime, lotSelected?.timezoneName);

    calculatePrice({
      variables: {
        lotId: values.lot,
        startTime: start,
        endTime: end,
      },
    });
  }, [
    lot,
    startDate,
    startTime,
    endDate,
    endTime,
    typeOfPermit,
    price,
    errors.endDate,
    errors.endTime,
    errors.startDate,
    errors.startTime,
  ]);

  return [data?.admin_calculatePermitPassPrice?.total, loading, error];
};

export default usePriceCalculator;
