import { getTranslator } from "../../../../shared/services/languages";

export const getGateEntryAuthentication = (gateEntry) => {
  const t = getTranslator();

  if (gateEntry.isPosEntry) {
    return t("transaction.ticketParkingInfoBox.gateEntryAuthentication.pos");
  }

  if (gateEntry.isManuallyCreated) {
    return t("transaction.ticketParkingInfoBox.gateEntryAuthentication.manual");
  }

  if (gateEntry.entryBeaconTrigger) {
    return t(
      "transaction.ticketParkingInfoBox.gateEntryAuthentication.beacon",
      {
        uid: gateEntry.entryBeaconTrigger.beaconUid,
      }
    );
  }

  if (gateEntry.eventGate.gate.isSystemGate) {
    return gateEntry.eventGate.name;
  }
};
