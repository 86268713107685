import gql from "graphql-tag";

export default gql`
  query AdminUserChangeLogs(
    $userId: Int!
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    admin_userChangelogs(
      userId: $userId
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          action
          metadata
          createdAt
          timezoneName
          changedByUser {
            id
            name
          }
          user {
            id
          }
        }
        cursor
      }
    }
  }
`;
