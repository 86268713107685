"use strict";

import React from "react";
import { Text } from "@citifyd/style";
import CurrencyFormatter from "@citifyd/currency-formatter";
import moment from "moment-timezone";

import { useTranslator, useService } from "../../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../../shared/services/languages";
import Table from "../../../../shared/react/components/Table";
import Permissions from "../../../../shared/services/permissions";
import styles from "./RevenueOfEventTickets.module.scss";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const RevenueOfEventTickets = ({ selectedLots, summary, currency }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const language = getCurrentLanguage();
  const { user: loggedUser, permissions } = useAuthentication();
  const canOpenTicket = Permissions.userMayOpenTicketPage();
  const canSeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  const columns = [
    {
      key: "date",
      value: t("revenue.date"),
    },
    {
      key: "time",
      value: t("revenue.time"),
    },
    {
      key: "name",
      value: t("revenue.name"),
    },
    {
      key: "barcode",
      value: t("revenue.barcode"),
    },
    {
      key: "ticketVerificationMethod",
      value: t("revenue.ticketVerificationMethod"),
      nowrap: "nowrap",
    },
    {
      key: "redeemedOnProperty",
      value: t("revenue.redeemedOnProperty"),
    },
    {
      key: "netPrice",
      value: t("revenue.netPrice"),
      textAlign: "right",
      nowrap: "nowrap",
    },
  ];

  const getData = (tickets, lotId) => {
    const data = tickets.map((ticket) => ({
      ...(canOpenTicket && {
        onClick: () =>
          $state.go("ticket", { id: ticket.id }, { enableBackLink: true }),
      }),
      date: {
        value: t("defaultFormats.date", {
          date: moment(ticket.createdAt).tz(loggedUser.timezoneName),
        }),
        nowrap: "nowrap",
        verticalAlign: "middle",
      },
      time: {
        value: t("defaultFormats.time", {
          date: moment.tz(ticket.createdAt, loggedUser.timezoneName),
        }),
        nowrap: "nowrap",
        verticalAlign: "middle",
      },
      ...(canSeeUserInformation && {
        name: {
          value: ticket.ownerName || " ",
          verticalAlign: "middle",
        },
      }),
      barcode: {
        value: ticket.barcode || "—",
        verticalAlign: "middle",
      },
      ticketVerificationMethod: {
        value: ticket.verificationAsText,
        verticalAlign: "middle",
      },
      redeemedOnProperty: {
        value: ticket.redeemedLot?.name || "—",
        verticalAlign: "middle",
      },
      netPrice: {
        value: (
          <Text textAlign="right">
            {ticket.payment.method === "bundle"
              ? t("revenue.bundle")
              : CurrencyFormatter.format(ticket.payment.netRevenue, {
                  currency: currency,
                  language,
                })}
            {ticket.payment.method === "cash" && "*"}
          </Text>
        ),
        textColor: ticket.payment.netRevenue < 0 ? "tertiary" : "default",
        verticalAlign: "middle",
      },
    }));

    const dataWithTotal = [
      ...data,
      {
        date: {
          value: <Text weight="700">{t("revenue.total")}</Text>,
          colSpan: canSeeUserInformation ? 6 : 5,
        },
        time: {
          hide: true,
        },
        ...(canSeeUserInformation && {
          name: {
            hide: true,
          },
        }),
        barcode: {
          hide: true,
        },
        ticketVerificationMethod: {
          hide: true,
        },
        redeemedOnProperty: {
          hide: true,
        },
        netPrice: {
          value: (
            <Text weight="700" textAlign="right">
              {CurrencyFormatter.format(summary.lots[lotId].netRevenue, {
                currency: currency,
                language,
              })}
            </Text>
          ),
          textColor:
            summary.lots[lotId].netRevenue < 0 ? "tertiary" : "default",
        },
      },
    ];

    return dataWithTotal;
  };

  return (
    <>
      {selectedLots.map((lot, index) => {
        if (!lot.tickets?.length || lot.tickets?.length === 0) {
          return null;
        }

        const data = getData(lot.tickets, lot.id);

        return (
          <React.Fragment key={index}>
            <Text className={styles.title} variant="h3" component="h2">
              {lot.name}
            </Text>
            <Table
              columns={columns}
              data={data}
              striped
              responsive
              borderBottom
              className={styles.table}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default RevenueOfEventTickets;
