"use strict";

import React, { useMemo } from "react";
import { sum, some } from "lodash";
import moment from "moment-timezone";
import { Text } from "@citifyd/style";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import Table from "../../../../shared/react/components/Table";
import Permissions from "../../../../shared/services/permissions";
import { formatCurrency } from "../../utils";
import { useTableColumns } from "./utils";
import styles from "./Tickets.module.scss";
import GuestBadge from "../../../../shared/react/components/GuestBadge";

const Tickets = ({ tickets }) => {
  const t = useTranslator();

  const columns = useTableColumns();
  const $state = useService("$state");

  const userMayOpenTicketPage = Permissions.userMayOpenTicketPage();
  const hasCashPayments = some(tickets, (t) => t.isCashPayment);

  const getUserName = (ticket) => {
    if (ticket.isPosPurchase) {
      return t("users.posUser");
    }

    if (ticket.purchaser?.isGuest) {
      return <GuestBadge />;
    }

    if (ticket.purchaser?.name) {
      return ticket.purchaser.name;
    }
  };

  const formattedData = useMemo(() => {
    let ticketsList = tickets.map((ticket) => {
      return {
        ...(userMayOpenTicketPage && {
          onClick: () =>
            $state.go("ticket", { id: ticket.id }, { enableBackLink: true }),
        }),
        name: getUserName(ticket),
        lotName: ticket.lot?.name,
        time: t("defaultFormats.shortDatetime", {
          date: moment.tz(ticket.createdAt, ticket.lot?.timezoneName),
        }),
        event: ticket.event?.name,
        revenueAmount: {
          value: `${formatCurrency(ticket.netRevenueAmount)} ${
            ticket.isCashPayment ? "*" : ""
          }`,
          textAlign: "right",
          textColor: ticket.netRevenueAmount < 0 ? "tertiary" : "default",
        },
      };
    });

    ticketsList = [
      ...ticketsList,
      {
        name: <b>{t("dailyRevenue.total")}</b>,
        lotName: null,
        time: null,
        event: null,
        revenueAmount: {
          value: <b>{formatCurrency(sum(tickets, "netRevenueAmount"))}</b>,
          textAlign: "right",
        },
      },
    ];

    return ticketsList;
  }, [tickets]);

  return (
    <>
      <div className={styles.revenueContent}>
        <Text gutterBottom variant="h3">
          {t("dailyRevenue.eventParkers")}
        </Text>

        <Table
          className={styles.table}
          data={formattedData}
          columns={columns}
          gutterBottom
          striped
          borderBottom
          clickable
        />

        {hasCashPayments && (
          <Text>* {t("dailyRevenue.ticketCashPaymentDisclaimer")}</Text>
        )}
      </div>
    </>
  );
};

export default Tickets;
