import {
  convertQueryStringToObject,
  getRouteByPathname,
  getRouteObject,
} from "./utils";
import { history } from "../../../routes";

export default {
  $on: (event, fn) => {
    let unlisten;
    if (event === "$stateChangeSuccess") {
      unlisten = history.listen(({ location }) => {
        fn({ location });
      });
    } else {
      let unblock;

      unblock = history.block(({ location, retry }) => {
        routeObject(location, retry);
      });

      const routeObject = (location, retry) => {
        const currentRoute = getRouteByPathname(location.pathname);

        const { params: currentParams } = getRouteObject();

        fn({
          success: () => {
            unblock && unblock();
            retry && retry();
          },
          toState: {
            auth: currentRoute.page?.auth,
            name: currentRoute.name,
            title: currentRoute.page?.title,
            url: currentRoute.url,
          },
          params: {
            ...currentParams,
            ...convertQueryStringToObject(location.search?.substring(1)),
          },
        });
      };

      unlisten = history.listen(({}) => {
        unblock();

        unblock = history.block(({ location, retry }) => {
          routeObject(location, retry);
        });
      });

      window.addEventListener("beforeunload", () => {
        unblock();
      });
    }

    return unlisten;
  },
};
