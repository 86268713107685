"use strict";

import React from "react";

import { useTranslator } from "../../hooks";
import exportPDFImage from "../../../../images/PDF icon@3x.png";
import styles from "./PDFDownload.module.scss";
import classNames from "classnames";

const PDFDownload = ({ onClick, disabled, className }) => {
  const t = useTranslator();

  return (
    <a
      onClick={() => (disabled ? {} : onClick())}
      disabled={disabled}
      className={classNames(styles.icon, className, {
        [styles.disabled]: disabled,
      })}
    >
      <img
        src={exportPDFImage}
        alt={t("commonButtons.exportAsPdf")}
        title={t("commonButtons.exportAsPdf")}
      />
    </a>
  );
};

export default PDFDownload;
