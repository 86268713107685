import React from "react";
import { Icon, Text } from "@citifyd/style";
import useInfiniteScroll from "react-infinite-scroll-hook";
import classNames from "classnames";

import styles from "./UsersList.module.scss";
import { useTranslator } from "../../../../shared/react/hooks";
import Table from "../../../../shared/react/components/Table";
import LoadingManager from "../../../../shared/react/components/LoadingManager";

const UsersList = ({
  sortBy,
  setSortBy,
  sortReverse,
  setSortReverse,
  setCurrentPage,
  data,
  ended,
  isLoading,
  hasError,
}) => {
  const t = useTranslator();

  const [tableRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !ended,
    onLoadMore: () => setCurrentPage((old) => old + 1),
    disabled: ended,
    rootMargin: "0px 0px 400px 0px",
  });

  if ((isLoading && !data.length) || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  if (!data.length) {
    return <Text>{t("users.noUsers")}</Text>;
  }

  const renderColumnTitle = (label, value, flexEnd) => (
    <span
      className={classNames(styles.columnTitle, {
        [styles.flexEnd]: flexEnd,
      })}
    >
      {label}
      {sortBy === value && (
        <Icon
          appearance="white"
          icon={sortReverse ? "caret-down" : "caret-up"}
          pull="right"
        ></Icon>
      )}
    </span>
  );

  const getColumnOnClick = (value) => () => {
    setSortBy(value);
    setSortReverse((old) => (sortBy === value ? !old : false));
  };

  const columns = [
    {
      value: renderColumnTitle(t("users.regDate"), "createdAt"),
      sortable: true,
      onClick: getColumnOnClick("createdAt"),
      width: "16%",
      key: "regDate",
    },
    {
      value: t("users.regTime"),
      width: "10%",
      key: "regTime",
    },
    {
      value: renderColumnTitle(t("users.name"), "namePronunciationOrName"),
      sortable: true,
      onClick: getColumnOnClick("namePronunciationOrName"),
      width: "30%",
      key: "name",
    },
    {
      value: renderColumnTitle(t("users.phone"), "phoneNumber"),
      sortable: true,
      onClick: getColumnOnClick("phoneNumber"),
      width: "16%",
      key: "phone",
    },
    {
      value: renderColumnTitle(
        t("users.tickets"),
        "ticketsPurchasedCount",
        true
      ),
      sortable: true,
      onClick: getColumnOnClick("ticketsPurchasedCount"),
      width: "14%",
      key: "tickets",
    },
    {
      value: renderColumnTitle(
        t("users.amountOfReservations"),
        "reservationsCount",
        true
      ),
      sortable: true,
      onClick: getColumnOnClick("reservationsCount"),
      width: "14%",
      key: "amountOfReservations",
    },
  ];

  return (
    <>
      <Table responsive columns={columns} data={data} striped clickable />
      {!ended && (
        <div className={styles.tableRef} ref={tableRef}>
          {isLoading && !!data.length && (
            <LoadingManager isLoading={isLoading} />
          )}
        </div>
      )}
    </>
  );
};

export default UsersList;
