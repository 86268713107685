"use strict";

import React from "react";
import { Text, Button } from "@citifyd/style";
import * as yup from "yup";
import classNames from "classnames";
import { useFormik } from "formik";

import { useTranslator, useService } from "../../../../shared/react/hooks";
import FormikProvider from "../../../../shared/react/components/FormikProvider";
import useLoadTracking from "../../../../shared/react/hooks/useLoadTracking";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import RouterLinkButton from "../../../../shared/react/components/RouterLinkButton";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";

import styles from "./Authentication.module.scss";
import { requestUserPasswordReset } from "../../../../shared/api";
import { showAlert, showErrorAlert } from "../../../../shared/services/helper";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const AuthenticationStep = ({
  onTwoFactorAuthenticationRequired,
  handleSuccessfulLogin,
}) => {
  const t = useTranslator();
  const { tryAuthenticate } = useAuthentication();
  const $location = useService("$location");

  const { isLoading, trackLoading, untrackLoading } = useLoadTracking();

  const onSubmit = (values) => {
    trackLoading("authenticating");

    tryAuthenticate(values)
      .then((resp) => {
        if (resp.twoFactorAuthentication) {
          onTwoFactorAuthenticationRequired(values);
        } else if (resp.user) {
          handleSuccessfulLogin();
        }
      })
      .catch((resp) => {
        untrackLoading("authenticating");
        showErrorAlert(resp.data);
      });
  };

  const onForgotPassword = () => {
    if (email === undefined || email.trim() === "") {
      window.alert(t("login.passwordReset.pleaseEnterEmail"));
      return;
    }

    if (!window.confirm(t("login.passwordReset.areYouSure"))) {
      return;
    }

    trackLoading("resetPassword");

    requestUserPasswordReset(email)
      .then((message) => {
        showAlert(message);
      })
      .catch(function (response) {
        showErrorAlert(response.data);
      })
      .finally(() => untrackLoading("resetPassword"));
  };

  const formikbag = useFormik({
    initialValues: {
      email: $location.search().email || "",
      password: "",
    },
    onSubmit,
    validationSchema: yup.object().shape({
      email: yup.string().required(),
      password: yup.string().required(),
    }),
  });

  const { handleSubmit, values } = formikbag;

  const { email } = values;

  return (
    <FormikProvider value={formikbag}>
      {isLoading && (
        <div className={styles.loadingManager}>
          <LoadingManager isLoading={true} />
        </div>
      )}

      <div
        className={classNames(styles.container, { [styles.hide]: isLoading })}
      >
        <Text
          className={styles.title}
          variant="h1"
          weight="300"
          textAlign="center"
        >
          {t("login.login")}
        </Text>
        <form className={styles.form} onSubmit={handleSubmit}>
          <FormikField
            as={FIELD_TYPE.INPUT}
            label={t("login.emailAddress")}
            labelSize="small"
            name="email"
            disabled={isLoading}
            gutterBottom
          />
          <FormikField
            as={FIELD_TYPE.INPUT}
            label={t("login.password")}
            name="password"
            type="password"
            labelSize="small"
            disabled={isLoading}
            gutterBottom
          />
          <div className={styles.submitBox}>
            <Button
              uppercase
              onMouseDown={() => handleSubmit()}
              disabled={isLoading}
              type="submit"
              extraPadding
            >
              {t("commonButtons.continue")}
            </Button>
          </div>
          <Text
            className={styles.forgotPasswordLink}
            variant="subtitle"
            textAlign="center"
            appearance="tertiary"
            underline
            onClick={() => !isLoading && onForgotPassword()}
          >
            {t("login.forgotPassword")}
          </Text>
          <hr className={styles.divisor} />
          <Text variant="h5" textAlign="center" weight="700">
            {t("login.newToCitifyd.title")}
          </Text>
          <Text variant="subtitle" textAlign="center">
            {t("login.newToCitifyd.description")}
          </Text>
        </form>
        <div className={styles.createAccountBox}>
          <RouterLinkButton
            state="register"
            uppercase
            extraPadding
            disabled={isLoading}
          >
            {t("login.newToCitifyd.createAccount")}
          </RouterLinkButton>
        </div>
      </div>
    </FormikProvider>
  );
};

export default AuthenticationStep;
