"use strict";

import React, { useEffect, useState } from "react";
import { Button, LoadingIndicator, Modal } from "@citifyd/style";

import PhoneField from "../../react/components/PhoneField";
import FormikProvider from "../../react/components/FormikProvider";
import FormikField, { FIELD_TYPE } from "../../react/components/FormikField";
import InformativeMessage from "./components/InformativeMessage.jsx";

import useAdminEditUserPhoneNumber from "./useAdminEditUserPhoneNumber";
import { useTranslator } from "../../react/hooks";
import { getCountries } from "../../api";
import useLoadTracking from "../../react/hooks/useLoadTracking";
import { showErrorAlert } from "../../services/helper";

const AdminEditUserPhoneNumberModal = ({ user, open, onClose }) => {
  const t = useTranslator();
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>{t("modalEditUserPhoneNumber.title")}</Modal.Title>
      <Form user={user} onClose={onClose} />
    </Modal>
  );
};

const Form = ({ user, onClose }) => {
  const t = useTranslator();
  const [countries, setCountries] = useState([]);
  const { isLoading, trackLoading, untrackLoading } = useLoadTracking({});

  const originalPhoneNumber = user.phoneNumber;
  const verifiedOriginalPhoneNumber = user.verifiedPhoneNumber;
  const originalPhoneCountryCode = user.phoneCountryCode;

  const formikbag = useAdminEditUserPhoneNumber({
    user,
    originalPhoneNumber,
    originalPhoneCountryCode,
    verifiedOriginalPhoneNumber,
    onClose,
    trackLoading,
    untrackLoading,
  });

  const clearPhoneNumber = formikbag.values.clearPhoneNumber;
  const hasPhoneNumberChanged =
    formikbag.values.phoneNumber !== originalPhoneNumber;

  useEffect(() => {
    loadCountries();
  }, []);

  const loadCountries = async () => {
    try {
      trackLoading("countries");
      const countries = await getCountries();

      setCountries(countries);
    } catch (err) {
      showErrorAlert(err);
    } finally {
      untrackLoading("countries");
    }
  };

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Modal.Content>
        {!clearPhoneNumber && (
          <PhoneField
            countries={countries}
            countryCodeDefault={user?.phoneCountryCode || "us"}
            countryCodeFieldName="phoneCountryCode"
            phoneNumberFieldName="phoneNumber"
            gutterBottom
          />
        )}

        <div>
          <FormikField
            name="clearPhoneNumber"
            label={t("modalEditUserPhoneNumber.clear")}
            as={FIELD_TYPE.CHECKBOX}
            fullWidth
            gutterBottom
          />
        </div>
        {!clearPhoneNumber && hasPhoneNumberChanged && (
          <div>
            <FormikField
              name="skipTriggerMigration"
              label={t("modalEditUserPhoneNumber.skipPassesMigration")}
              as={FIELD_TYPE.CHECKBOX}
              fullWidth
              gutterBottom
            />
          </div>
        )}
        {((!clearPhoneNumber && hasPhoneNumberChanged) ||
          !verifiedOriginalPhoneNumber) && (
          <div>
            <FormikField
              name="forcePhoneNumberVerification"
              label={t("modalEditUserPhoneNumber.markAsVerified")}
              as={FIELD_TYPE.CHECKBOX}
              fullWidth
              gutterBottom
            />
          </div>
        )}
        <InformativeMessage
          clearPhoneNumber={clearPhoneNumber}
          skipTriggerMigration={formikbag.values.skipTriggerMigration}
          forcePhoneNumberVerification={
            formikbag.values.forcePhoneNumberVerification
          }
        />
      </Modal.Content>
      <Footer onClose={onClose} handleSubmit={handleSubmit} />

      {isLoading && <LoadingIndicator isLoading={isLoading} withOverlay />}
    </FormikProvider>
  );
};

const Footer = ({ onClose, handleSubmit }) => {
  const t = useTranslator();
  return (
    <Modal.Actions>
      <Button
        type="button"
        textColor="white"
        appearance="secondary"
        fullWidth
        justifyContent="center"
        uppercase
        onMouseDown={onClose}
      >
        {t("commonButtons.cancel")}
      </Button>
      <Button
        type="button"
        fullWidth
        justifyContent="center"
        uppercase
        onMouseDown={handleSubmit}
      >
        {t("commonButtons.continue")}
      </Button>
    </Modal.Actions>
  );
};
export default AdminEditUserPhoneNumberModal;
