import React, { ChangeEvent } from "react";
import NumberFormat, { NumberFormatValues } from "react-number-format";

import CurrencyFormatter from "@citifyd/currency-formatter";
import { Input, Label } from "@citifyd/style";

interface CurrencyInputProps {
  value: number;
  label?: string;
  currency?: string;
  language?: string;
  fullWidth?: boolean;
  onChange: (value: number) => unknown;
  decimalScale?: number;
  allowNegative?: boolean;
  error?: string;
}

const CurrencyMaskedInput: React.FC<CurrencyInputProps> = ({
  value,
  currency = "USD",
  language = "en-US",
  onChange,
  decimalScale = 2,
  allowNegative = false,
  error,
  fullWidth,
  label,
}) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <NumberFormat
        value={
          CurrencyFormatter.isZeroDecimal({ currency }) ? value : value / 100
        }
        customInput={Input}
        fullWidth={fullWidth}
        prefix={CurrencyFormatter.getPrefix({ currency, language })}
        suffix={CurrencyFormatter.getSuffix({ currency, language })}
        thousandSeparator={false}
        decimalSeparator={CurrencyFormatter.getDecimalSeparator({ language })}
        onValueChange={(values) => {
          if (CurrencyFormatter.isZeroDecimal({ currency })) {
            onChange(values.floatValue!);
          } else {
            onChange(values.floatValue! * 100);
          }
        }}
        fixedDecimalScale
        decimalScale={
          CurrencyFormatter.isZeroDecimal({ currency }) ? 0 : decimalScale
        }
        allowNegative={allowNegative}
        isNumericString
      />
    </>
  );
};

export default CurrencyMaskedInput;
