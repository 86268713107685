"use strict";

import React from "react";
import { Textarea, Checkbox } from "@citifyd/style";

import { useReservationRefundContext } from "./ReservationRefundContext.jsx";
import { useTranslator } from "../../../shared/react/hooks";
import styles from "./ReservationRefund.module.scss";

export default function RefundReason() {
  const t = useTranslator();

  const { refundOptions, setRefundOptions } = useReservationRefundContext();

  const updateFlag = (fieldName) => (e) =>
    setRefundOptions({ ...refundOptions, [fieldName]: e.target.checked });

  return (
    <div className={styles.refundReason}>
      <Textarea
        rows="4"
        fullWidth
        id="refund-reason"
        value={refundOptions.reason}
        label={t("refundPages.reason")}
        onChange={(e) =>
          setRefundOptions({ ...refundOptions, reason: e.target.value })
        }
      />
      <div className={styles.checkboxes}>
        <Checkbox
          small
          gutterBottom
          className={styles.label}
          id="requested-by-phone-checkbox"
          checked={refundOptions.requestedByPhone}
          onChange={updateFlag("requestedByPhone")}
          label={t("refundPages.requestedPhone")}
        />

        <Checkbox
          small
          className={styles.label}
          id="requested-by-email-checkbox"
          checked={refundOptions.requestedByEmail}
          onChange={updateFlag("requestedByEmail")}
          label={t("refundPages.requestedEmail")}
        />
      </div>
    </div>
  );
}
