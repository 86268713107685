import React, { useState } from "react";
import BoxCollapse from "./components/BoxCollapse.jsx";
import { useParkingConfiguration } from "./useParkingConfiguration.js";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import { useTranslator } from "../../../../shared/react/hooks.js";
import FormikProvider from "../../../../shared/react/components/FormikProvider/FormikProvider.jsx";
import {
  Button,
  Icon,
  Label,
  Text,
  ToggleButton,
  Tooltip,
} from "@citifyd/style";

import styles from "./ParkingConfiguration.module.scss";
import BoxContent from "../../../../shared/react/components/BoxContent";
import StickyFooter from "../../../../shared/react/components/StickyFooter/StickyFooter.jsx";
import useLoadTracking from "../../../../shared/react/hooks/useLoadTracking.js";
import RouterLink from "../../../../shared/react/components/RouterLink/RouterLink.jsx";
import LoadingManager from "../../../../shared/react/components/LoadingManager/LoadingManager.jsx";
import { useFormikContext } from "formik";
import CheckboxWithTooltip from "../CheckboxWithTooltip";
import { useAuthentication } from "../../../../shared/react/contexts/authentication.js";
import Permissions from "../../../../shared/services/permissions.js";

const ParkingConfiguration = ({ lot, mode }) => {
  const t = useTranslator();
  const { trackLoading, untrackLoading, isLoading } = useLoadTracking();

  const formikbag = useParkingConfiguration({
    lot,
    mode,
    trackLoading,
    untrackLoading,
  });

  const { values, setFieldValue, handleSubmit, errors } = formikbag;

  const handleOpen = (name, value) => {
    setFieldValue(name, value);
  };

  return (
    <FormikProvider value={formikbag}>
      {isLoading && <LoadingManager isLoading={isLoading} withOverlay />}

      {!isLoading && (
        <>
          <General lot={lot} errors={errors} setFieldValue={setFieldValue} />
          <EventPaking handleOpen={handleOpen} />
          <HourlyParking handleOpen={handleOpen} />
          <ReservedParking handleOpen={handleOpen} />

          <BoxWithEnableSettings
            enabled={values.monthlyParking}
            title={t("addProperty.monthlyParking")}
            description={t("addProperty.monthlyParkingDescription")}
            handleChange={() =>
              handleOpen("monthlyParking", !values.monthlyParking)
            }
          />
        </>
      )}

      <StickyFooter isBlocked={false}>
        <RouterLink
          state="edit-property"
          params={{ lotId: lot.id }}
          className={styles.backLink}
        >
          <Icon size="subtitle" icon="chevron-left" appearance="white" />
          <Text variant="subtitle" appearance="white">
            {t("commonButtons.goBackWithoutSaving")}
          </Text>
        </RouterLink>

        <Button shadow extraPadding uppercase onMouseDown={handleSubmit}>
          {t("commonButtons.continue")}
        </Button>
      </StickyFooter>
    </FormikProvider>
  );
};

const General = ({ lot, errors, setFieldValue }) => {
  const t = useTranslator();
  const [isEditing, setIsEditing] = useState(false);
  const { permissions } = useAuthentication();

  return (
    <BoxContent>
      <BoxContent.Header title={t("addProperty.general.title")} />
      <BoxContent.Content>
        <Text gutterBottom>{t("addProperty.general.description")}</Text>
        <CheckboxWithTooltip
          tooltip={t("addProperty.general.propertyGatesTooltip")}
          id={"propertyGates"}
          label={t("addProperty.general.propertyGates")}
          name="general.hasPhysicalGates"
        />

        <CheckboxWithTooltip
          tooltip={t("addProperty.general.notifyWhenSomeoneParkingTooltip")}
          id={"notifyWhenSomeoneParking"}
          label={t("addProperty.general.notifyWhenSomeoneParking")}
          name="general.notifyWhenSomeoneParking"
        />
        {Permissions.userMayChangeLotUnavailableMessage(permissions) && (
          <div className={styles.customMessageContainer}>
            <div className={styles.container}>
              <Label>{t("addProperty.general.closedPropertyMessage")}</Label>

              <Tooltip
                id="custumMessage"
                place="right"
                label={
                  <Icon
                    icon="question-circle"
                    pull="right"
                    size="h3"
                    className={styles.tooltipIcon}
                  />
                }
              >
                <div className={styles.tooltip}>
                  <Text appearance="white">
                    {t("addProperty.general.closedPropertyMessageTooltip")}
                  </Text>
                </div>
              </Tooltip>
              <Button
                onClick={() => setIsEditing(!isEditing)}
                appearance="transparent"
                justifyContent="flex-end"
                className={styles.editButton}
              >
                <Tooltip
                  id="editMessage"
                  place="right"
                  label={<Icon icon="edit" size="h3" pull="none" />}
                >
                  <Text appearance="white">
                    {t("addProperty.general.editMessage")}
                  </Text>
                </Tooltip>
              </Button>
            </div>
            <FormikField
              as={FIELD_TYPE.TEXTAREA}
              rows="5"
              name="general.customLotUnavailableMessage"
              gutterBottom
              disabled={!isEditing}
            />
            {errors?.general?.customLotUnavailableMessage === "is required" && (
              <div className={styles.undoButton}>
                <Button
                  iconLeft="undo"
                  justifyContent="center"
                  gutterBottom
                  size="tiny"
                  uppercase
                  onClick={() =>
                    setFieldValue(
                      "general.customLotUnavailableMessage",
                      lot.unavailableMessage
                    )
                  }
                >
                  {t("addProperty.general.backToDefaultMessage")}
                </Button>
              </div>
            )}
          </div>
        )}
      </BoxContent.Content>
    </BoxContent>
  );
};

const EventPaking = ({ handleOpen }) => {
  const t = useTranslator();
  const { values } = useFormikContext();

  return (
    <BoxCollapse
      title={t("addProperty.eventParking.title")}
      isOpen={values.eventParking.enabled}
      description={t("addProperty.eventParking.description")}
      handleOpen={() =>
        handleOpen("eventParking.enabled", !values.eventParking.enabled)
      }
    >
      <FormikField
        as={FIELD_TYPE.CHECKBOX}
        name="eventParking.requiresVehicleSelection"
        gutterBottom
        label={t("addProperty.eventParking.requiresVehicleSelection")}
        labelSize="default"
      />

      <CheckboxWithTooltip
        tooltip={t(
          "addProperty.eventParking.notifyEventsNearThisPropertyTooltip"
        )}
        id={"requiredVehicleSelection"}
        label={t("addProperty.eventParking.notifyEventsNearThisProperty")}
        name="eventParking.notifyEventsNearThisProperty"
      />

      <CheckboxWithTooltip
        tooltip={t(
          "addProperty.eventParking.disableHourlyParkingDuringTheEventTimeTooltip"
        )}
        id={"disableHourlyParking"}
        label={t(
          "addProperty.eventParking.disableHourlyParkingDuringTheEventTime"
        )}
        name="eventParking.disableHourlyParking"
      />

      <CheckboxWithTooltip
        tooltip={t(
          "addProperty.eventParking.disableReservedParkingDuringEventTooltip"
        )}
        id={"disableReservedParkingDuringEvent"}
        label={t(
          "addProperty.eventParking.disableReservedParkingDuringTheEventTime"
        )}
        name="eventParking.disableReservedParkingDuringEvent"
      />

      <CheckboxWithTooltip
        tooltip={t(
          "addProperty.eventParking.additionalLotNotesForEventParkingTooltip"
        )}
        id={"additionalNotesAboutTheLotForEventParkingEnabled"}
        label={t("addProperty.eventParking.additionalLotNotesForEventParking")}
        name="eventParking.additionalNotesAboutTheLotForEventParkingEnabled"
      />
      <div className={styles.textarea}>
        <FormikField
          as={FIELD_TYPE.TEXTAREA}
          rows="5"
          name="eventParking.additionalNotesAboutTheLotForEventParking"
          gutterBottom
          disabled={
            !values.eventParking
              .additionalNotesAboutTheLotForEventParkingEnabled
          }
        />
      </div>
    </BoxCollapse>
  );
};

const HourlyParking = ({ handleOpen }) => {
  const t = useTranslator();
  const { values } = useFormikContext();
  const { permissions } = useAuthentication();

  return (
    <BoxCollapse
      title={t("addProperty.hourlyParking.title")}
      isOpen={values.hourlyParking.enabled}
      description={t("addProperty.hourlyParking.description")}
      handleOpen={() =>
        handleOpen("hourlyParking.enabled", !values.hourlyParking.enabled)
      }
    >
      <FormikField
        as={FIELD_TYPE.CHECKBOX}
        name="hourlyParking.spacesGuaranteed"
        gutterBottom
        label={t("addProperty.hourlyParking.spacesGuaranteed")}
        labelSize="default"
      />
      <div>
        <FormikField
          as={FIELD_TYPE.CHECKBOX}
          name="hourlyParking.expirationRemindersEnabled"
          gutterBottom
          label={t("addProperty.hourlyParking.expirationRemindersEnabled")}
          labelSize="default"
        />
      </div>
      <CheckboxWithTooltip
        tooltip={t("addProperty.hourlyParking.requiresTimeSelectionTooltip")}
        id={"requiresTimeSelection"}
        label={t("addProperty.hourlyParking.requiresTimeSelection")}
        name="hourlyParking.requiresTimeSelection"
      />
      <CheckboxWithTooltip
        tooltip={t("addProperty.hourlyParking.sessionExtensionsEnabledTooltip")}
        id={"sessionExtensionsEnabled"}
        label={t("addProperty.hourlyParking.sessionExtensionsEnabled")}
        name="hourlyParking.sessionExtensionsEnabled"
        disabled={!values.hourlyParking.requiresTimeSelection}
      />
      <CheckboxWithTooltip
        tooltip={t(
          "addProperty.hourlyParking.informationSentToParkerAfterPurchaseTooltip"
        )}
        id={"informationSentToParkerAfterPurchaseEnabled"}
        label={t(
          "addProperty.hourlyParking.informationSentToParkerAfterPurchase"
        )}
        name="hourlyParking.informationSentToParkerAfterPurchaseEnabled"
      />

      <div className={styles.textarea}>
        <FormikField
          as={FIELD_TYPE.TEXTAREA}
          rows="5"
          name="hourlyParking.informationSentToParkerAfterPurchase"
          gutterBottom
          disabled={
            !values.hourlyParking.informationSentToParkerAfterPurchaseEnabled
          }
        />
      </div>
      <div>
        <FormikField
          as={FIELD_TYPE.INPUT}
          required
          className={styles.shortField}
          name="hourlyParking.gracePeriodTimeAmount"
          label={t("addProperty.hourlyParking.gracePeriodTimeAmount")}
          gutterBottom
        />
      </div>

      {Permissions.userMayChangeSMSCode(permissions) && (
        <>
          <div className={styles.container}>
            <Label>{t("addProperty.hourlyParking.smsCode")}</Label>
            <Tooltip
              id="smsCode"
              place="right"
              label={<Icon icon="question-circle" pull="right" size="h3" />}
            >
              <div className={styles.tooltip}>
                <Text appearance="white">
                  {t("addProperty.hourlyParking.smsCodeTooltip")}
                </Text>
              </div>
            </Tooltip>
          </div>

          <FormikField
            as={FIELD_TYPE.INPUT}
            className={styles.shortField}
            name="hourlyParking.smsCode"
            gutterBottom
            maxLength="3"
          />
          <Text appearance="tertiary" italic gutterBottom>
            {t("addProperty.hourlyParking.theSuggestedSMSCode")}
          </Text>
        </>
      )}
    </BoxCollapse>
  );
};

const ReservedParking = ({ handleOpen }) => {
  const t = useTranslator();
  const { values } = useFormikContext();

  return (
    <BoxCollapse
      title={t("addProperty.reservedParking.title")}
      isOpen={values.reservedParking.enabled}
      description={t("addProperty.reservedParking.description")}
      handleOpen={() =>
        handleOpen("reservedParking.enabled", !values.reservedParking.enabled)
      }
    >
      <CheckboxWithTooltip
        id={"additionalNotesInformationEnabled"}
        tooltip={t(
          "addProperty.reservedParking.additionalNotesAboutTheLotForReservedParkingTooltip"
        )}
        label={t(
          "addProperty.reservedParking.additionalNotesAboutTheLotForReservedParking"
        )}
        name="reservedParking.additionalNotesInformationEnabled"
      />

      <div className={styles.textarea}>
        <FormikField
          as={FIELD_TYPE.TEXTAREA}
          rows="5"
          name="reservedParking.additionalNotesInformation"
          gutterBottom
          disabled={!values.reservedParking.additionalNotesInformationEnabled}
        />
      </div>
    </BoxCollapse>
  );
};

const BoxWithEnableSettings = ({
  enabled,
  description,
  handleChange,
  title,
}) => {
  return (
    <BoxContent>
      <BoxContent.Header title={title}>
        <BoxContent.HeaderActions>
          <div className={styles.container}>
            <Text
              variant="subtitle"
              className={styles.enabled}
              appearance="white"
            >
              {enabled ? "Enabled" : "Disabled"}
            </Text>
            <ToggleButton selected={enabled} light onChange={handleChange} />
          </div>
        </BoxContent.HeaderActions>
      </BoxContent.Header>

      <BoxContent.Content>
        <Text gutterBottom>{description}</Text>
      </BoxContent.Content>
    </BoxContent>
  );
};

export default ParkingConfiguration;
