import gql from "graphql-tag";

export default gql`
  query AdminGenerateSubscriptionRefundPreview(
    $passId: Int!
    $refundAmount: Int!
    $feeRetentionMethod: FeeRetentionMethod!
    $refunder: Refunder!
  ) {
    admin_generateSubscriptionRefundPreview(
      subscriptionId: $passId
      refundAmount: $refundAmount
      feeRetentionMethod: $feeRetentionMethod
      refunder: $refunder
    ) {
      entityType
      reversibleAmount
      amountToReverse
      remainingToReverse
      totalReversalAmount
      totals {
        citifydAmount
        externalAmount
        stripeAmount
        totalAmount
      }
      transaction {
        id
        type
        totalAmount
        externalAmount
        citifydAmount
        isCashPayment
        currency
        ParentTransactionId
        stripeAmount
        extra
        card {
          id
          last4
          brand
          tokenizationMethod
          expMonth
          expYear
          name
          funding
          countryCode
          address
        }
        createdAt
      }
    }
  }
`;
