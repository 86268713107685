import {
  getStripeConnectedAccount,
  getStripeConnectedAccounts,
  getCountry,
} from "../../../api";

export const preloadCountry = async ({
  stripeConnectedAccountRef,
  countryRef,
  user,
}) => {
  let countryCode;

  if (stripeConnectedAccountRef.current) {
    countryCode = stripeConnectedAccountRef.current.countryCode;
  } else {
    countryCode = user.organization.countryCode;
  }

  return getCountry(countryCode).then((country) => {
    countryRef.current = country;
  });
};

export const preloadStripeConnectedAccount = async ({
  stripeConnectedAccountRef,
}) => {
  const account = await getStripeConnectedAccounts();

  if (account) {
    const stripeConnectedAccountResponse = await getStripeConnectedAccount(
      account.stripeAccountId
    );

    stripeConnectedAccountRef.current = stripeConnectedAccountResponse;
  }
};
