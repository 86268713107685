import React, { useState, useEffect } from "react";
import { Grid, Label, Select } from "@citifyd/style";

import LoadingManager from "../../../../shared/react/components/LoadingManager";
import AppendableEventsEmpty from "../../../../shared/react/components/AppendableEventsEmpty";
import AppendableEventsList from "../../../../shared/react/components/AppendableEventsList";
import { getAppendableEvents } from "../../../../shared/api";
import { useTranslator, useService } from "../../../../shared/react/hooks";
import { generateMaxDistanceOptions } from "./utils";
import PageHeader from "../../../../shared/react/components/PageHeader";
import SearchInput from "../../../../shared/react/components/SearchInput";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const AppendableEvents = () => {
  const { user } = useAuthentication();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const t = useTranslator();
  const maxDistanceOptions = generateMaxDistanceOptions({ t, user });
  const $stateParams = useService("$stateParams");
  const [maxDistance, setMaxDistance] = useState(
    $stateParams.maxDistance || maxDistanceOptions[0].miles
  );
  const [searchQuery, setSearchQuery] = useState(
    $stateParams.searchQuery || ""
  );

  const loadEvents = async () => {
    setHasError(false);
    setIsLoading(true);

    try {
      const response = await getAppendableEvents({ params: { maxDistance } });
      setEvents(response);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadEvents();
  }, [maxDistance]);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  const handleMaxDistance = (e) => setMaxDistance(e.target.value);
  const handleSearchQuery = (e) => setSearchQuery(e.target.value);

  const maxDistanceSelect = maxDistanceOptions.map((option) => ({
    label: option.label,
    value: option.miles,
  }));

  if (!events.length) {
    return <AppendableEventsEmpty />;
  }

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          title={t("appendableEvents.nearbyEvents")}
          variant="h3"
          component="h2"
        />
        <PageHeader.Actions>
          <PageHeader.Action>
            <SearchInput
              value={searchQuery}
              id="search"
              onChange={handleSearchQuery}
              fullWidth
            />
          </PageHeader.Action>

          <PageHeader.Action>
            <Label variant="small" htmlFor="maxDistance">
              {t("appendableEvents.maxDistance")}
            </Label>
            <Select
              options={maxDistanceSelect}
              id="maxDistance"
              label={maxDistanceSelect.label}
              onChange={handleMaxDistance}
              value={maxDistance}
              fullWidth
            />
          </PageHeader.Action>
        </PageHeader.Actions>
      </PageHeader>
      <Grid.Row>
        <Grid.Col>
          <AppendableEventsList
            events={events}
            maxDistance={maxDistance}
            searchQuery={searchQuery}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default AppendableEvents;
