"use strict";

import axios from "axios";
import memoize from "promise-memoize";
import { getCurrentLanguage } from "./services/languages";
import { isPlainObject, isArray, pick } from "lodash";
import {
  getAccessToken,
  getClientToken,
  getImpersonatedUserId,
} from "../shared/react/contexts/authentication";

const { REACT_APP_API_URL: API_URL } = process.env;

const api = axios.create({
  baseURL: API_URL,

  transformRequest: [
    function (data, headers) {
      headers["Content-Type"] = "application/json";

      if (!headers["Content-Type"]) {
        headers["Content-Type"] = "application/json";
      }

      headers[
        "Citifyd-app-version"
      ] = `Dashboard ${process.env.REACT_APP_VERSION}`;
      headers["Citifyd-accept-language"] = getCurrentLanguage();

      const authHeaders = generateAuthenticationHeaders();
      for (const header in authHeaders) {
        if (!(header in headers)) headers[header] = authHeaders[header];
      }

      return isPlainObject(data) || isArray(data) ? JSON.stringify(data) : data;
    },
  ],
});

const mountAuthenticatedUrl = (url) => {
  const accessToken = getAccessToken();
  const impersonatedUserId = getImpersonatedUserId();
  if (!accessToken) {
    return url;
  }

  const glue = url.indexOf("?") !== -1 ? "&" : "?";
  let authenticatedUrl = `${url}${glue}access_token=${accessToken}`;

  if (impersonatedUserId) {
    authenticatedUrl += `&impersonate_user_id=${impersonatedUserId}`;
  }

  return authenticatedUrl;
};

export const generateAuthenticationHeaders = () => {
  const headers = {};
  const accessToken = getAccessToken();
  const impersonatedUserId = getImpersonatedUserId();

  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  if (impersonatedUserId) {
    headers["Impersonate-user-id"] = impersonatedUserId;
  }

  const clientToken = getClientToken();
  if (clientToken) {
    headers["Citifyd-client-token"] = clientToken;
  }
  return headers;
};

// Adjust errors so that we can access error.data instead of error.response.data
const injectDataToErrors = (func) => {
  return (...args) => {
    return func(...args).catch((err) => {
      err.data = err?.response?.data;
      throw err;
    });
  };
};

for (const verb of ["get", "post", "put", "delete"]) {
  api[verb] = injectDataToErrors(api[verb]);
}

const toQueryString = (data) => {
  data = pick(data, (value) => value !== null && value !== undefined);
  return new URLSearchParams(data).toString();
};

export const isRequestCancelled = (err) => axios.isCancel(err);

// Countries
export const getCountry = memoize((isoCode) =>
  api.get(`/countries/${isoCode}`).then((resp) => resp.data.country)
);

export const getCountries = memoize(() =>
  api.get(`/countries`).then((resp) => resp.data.continents)
);

// Properties
export const getAllProperties = (data) =>
  api.get("/admin/lots", data).then((resp) => resp.data.lots);

export const findProperty = (id) =>
  api.get(`/me/lots/${id}`).then((resp) => resp.data.lot);

export const createProperty = (data) =>
  api.post("/me/lots", data).then((resp) => resp.data.lot);

export const copyProperty = (lotId) =>
  api.post(`/me/lots/copy/${lotId}`).then((resp) => resp.data.lot);

export const updateProperty = (data) =>
  api.put(`/me/lots/${data.id}`, data).then((resp) => resp.data.lot);

export const getPropertyWeeklySchedule = (id) =>
  api
    .get(`/admin/lots/${id}/schedule/weekly`)
    .then((resp) => resp.data.availabilities);

export const getPropertySubscriptions = (data) =>
  api
    .get(`/admin/lots/${data}/subscriptions`)
    .then((resp) => resp.data.subscriptions);

export const updatePropertySchedule = (id, data) =>
  api.put(`/admin/lots/${id}/schedule`, { availabilities: data });

// Plans
export const getAllPlans = (options) =>
  api.get("/admin/plans", options).then((resp) => resp.data?.plans);

export const createPlan = (data) => api.post("/admin/plans", { plan: data });

export const updatePlan = (data) =>
  api.put(`/admin/plans/${data.id}`, { plan: data });

export const deletePlan = (planId) => api.delete(`/admin/plans/${planId}`);

// Revenue
export const getRevenueCurrencies = () =>
  api.get("/admin/revenue/currencies").then((resp) => resp?.data?.currencies);

export const getRevenueDates = (data) =>
  api.get("/admin/revenue", data).then((resp) => resp?.data?.revenue);

export const getRevenueDatesCsv = (data) =>
  api.get(`/admin/revenue.csv?${toQueryString(data)}`);

export const getRevenueEventsCsv = (data) =>
  api.get(`/admin/revenue/events.csv?${toQueryString(data)}`);

export const getRevenueEvents = (data) =>
  api.get("/admin/revenue/events", data).then((resp) => resp?.data?.events);

export const getRevenueForDate = (date, data) =>
  api.get(`/admin/revenue/${date}`, data).then((resp) => resp?.data);

export const getRevenueForDateCsv = (date, data) =>
  api.get(`/admin/revenue/${date}.csv?${toQueryString(data)}`);

export const getRevenueForEvent = (id, data) =>
  api.get(`/admin/revenue/events/${id}`, data).then((resp) => resp?.data);

export const getRevenueForEventCsv = (id, data) =>
  api.get(`/admin/revenue/events/${id}.csv?${toQueryString(data)}`);

export const getRevenueForEventPdfUrl = (id, data) =>
  `${API_URL}/events/${id}/report/html?${toQueryString({
    ...data,
    use_user_language: true,
  })}`;

export const getRevenueByPropertyCsv = (data) => {
  return api.get(
    `/admin/reports/revenue-by-property.csv?${toQueryString(data)}`
  );
};

export const getRevenueByPropertyPdf = (data) => {
  return api.get(
    `/admin/reports/revenue-by-property.pdf?${toQueryString(data)}`,
    { responseType: "arraybuffer" }
  );
};

export const getRevenuePayouts = (data) =>
  api.get("/admin/revenue/payouts", data).then((resp) => resp.data.payouts);

export const getRevenuePayoutsCsv = (data) =>
  api.get(`/admin/revenue/payouts.csv?${toQueryString(data)}`);

export const getRevenuePayout = (id) =>
  api.get(`/admin/revenue/payouts/${id}`).then((resp) => resp.data.payout);

export const getRevenuePayoutItems = (id, data) =>
  api
    .get(`/admin/revenue/payouts/${id}/items`, data)
    .then((resp) => resp.data.payoutItems);

export const getRevenuePayoutItemsCsv = (id, data) =>
  api.get(`/admin/revenue/payouts/${id}/items.csv?${toQueryString(data)}`);

// Referral code
export const getAllReferralCodes = () =>
  api.get("/admin/referral-codes").then((resp) => resp.data.referralCodes);

export const getReferralCode = (code) =>
  api
    .get(`/admin/referral-codes/${code}`)
    .then((resp) => resp.data.referralCode);

export const createReferralCode = (data) =>
  api.post("/admin/referral-codes", { referralCode: data });

export const updateReferralCode = (data) =>
  api.put(`/admin/referral-codes/${data.code}`, { referralCode: data });

export const toggleReferralCode = (code, active) =>
  api.put(`/admin/referral-codes/${code}/toggle`, {
    toggle: { active },
  });

// Snapshot
export const getPropertiesSnapshot = (data) =>
  api.get("/admin/snapshot/lots", data).then((resp) => resp.data.lots);

export const getParkersSnapshot = (data) =>
  api.get("/admin/snapshot/parkers", data).then((resp) => resp?.data);

export const getScheduleSnapshot = (data) =>
  api.get("/admin/snapshot/schedule", data).then((resp) => resp.data.lots);

// Subscriptions
export const getSubscription = (id) =>
  api.get(`/admin/subscriptions/${id}`).then((resp) => resp.data.subscription);

// Reservations
export const findReservation = (id) =>
  api.get(`/admin/reservations/${id}`).then((resp) => resp.data.reservation);

export const cancelReservation = (id, data) =>
  api.post(`/admin/reservations/${id}/cancel`, { refund: data || {} });

export const calculateReservationRefundPreview = (id, data) =>
  api
    .get(`/admin/reservations/${id}/refund-preview`, data)
    .then((resp) => resp.data);

export const calculateReservationFeeBreakdown = (id) =>
  api
    .get(`/admin/reservations/${id}/fee-breakdown`)
    .then((resp) => resp.data.feeBreakdown);

export const getReservationDefaultCancellationOptions = (id) =>
  api
    .get(`/admin/reservations/${id}/default-cancellation-options`)
    .then((resp) => resp.data.defaultCancellationOptions);

// Reports
export const getRatesReportsCsv = (data) =>
  api.get(`/admin/reports/rates?${toQueryString(data)}`);

export const getTransactionsReportsCsv = (data) =>
  api.get(`/admin/reports/transactions?${toQueryString(data)}`);

// Stripe connected accounts
export const getStripeConnectedAccounts = () =>
  api
    .get("/admin/stripe-connected-accounts")
    .then((resp) => resp.data.stripeConnectedAccounts[0]);

export const getStripeConnectedAccount = (id) =>
  api
    .get(`/admin/stripe-connected-accounts/${id}`)
    .then((resp) => resp.data.stripeConnectedAccount);

export const createStripeConnectedAccount = ({
  countryCode,
  bankAccountToken,
}) =>
  api.post("/admin/stripe-connected-accounts", {
    stripeConnectedAccount: { countryCode, bankAccountToken },
  });

export const updateStripeConnectedAccount = (id, bankAccountToken) =>
  api.put(`/admin/stripe-connected-accounts/${id}`, {
    stripeConnectedAccount: { bankAccountToken },
  });
export const checkStripeConnectedAccountsStatus = () =>
  api.get("/admin/stripe-connected-accounts/status-check");

export const updateStripeConnectedAccountRequirements = (id) =>
  api
    .post(`/admin/stripe-connected-accounts/${id}/requirement-updates`)
    .then((resp) => resp.data.stripeConnectedAccount);

export const generateStripeConnectedAccountVerificationLink = (id) =>
  api
    .post(`/admin/stripe-connected-accounts/${id}/account-links`)
    .then((resp) => resp.data.url);

// Organizations
export const getMyOrganization = () =>
  api.get("/me/organization").then((resp) => resp.data.organization);

export const updateMyOrganization = (data) =>
  api.put(`/me/organization`, { organization: data }).then((resp) => resp.data);

export const getOrganizationById = (id) =>
  api.get(`/organizations/${id}`).then((resp) => resp.data.organization);

// Venues
export const getAllVenues = () => api.get("/venues").then((resp) => resp.data);

export const getMyVenues = () =>
  api.get("/me/organization/venues").then((resp) => resp.data);

// Tickets
export const getTicket = (id) =>
  api.get(`/admin/tickets/${id}`).then((resp) => resp.data.ticket);

export const cancelTicket = (id, data) =>
  api.post(`/tickets/${id}/cancel`, { refund: data || {} });

export const exchangeTicket = (id, data) => api.put(`/tickets/${id}`, data);

export const transferTicket = (id, data) =>
  api.post(`/tickets/${id}/transfer`, data);

export const updateTicketTransfer = (id, data) =>
  api.put(`/tickets/${id}/transfer`, data);

export const calculateTicketRefundPreview = (id, data) =>
  api
    .get(`/admin/tickets/${id}/refund-preview`, data)
    .then((resp) => resp.data.operations);

export const exportTicketReceipt = (id, language) =>
  api
    .get(
      `/admin/tickets/${id}/purchase-receipt/export?request_language=${language}`,
      {
        responseType: "arraybuffer",
      }
    )
    .then((resp) => resp.data);

export const getTicketDefaultCancellationOptions = (id) =>
  api
    .get(`/admin/tickets/${id}/default-cancellation-options`)
    .then((resp) => resp.data.defaultCancellationOptions);

// Users
export const getAllUsers = (data) =>
  api.get("/admin/users", data).then((resp) => resp.data.users);

export const createUser = (data) =>
  api.post("/users", data).then((resp) => resp.data.user);

export const updateUser = (id, data) => api.put(`/users/${id}`, data);

export const blockUser = (id, isActive) =>
  api.put(`/admin/users/${id}/status`, {
    status: isActive ? "active" : "blocked",
  });

export const getMyOrganizationUsers = () =>
  api.get("/me/organization/users").then((resp) => resp.data);

export const getEventFilters = () =>
  api.get("/events/filters").then((resp) => resp.data);

export const getUserData = (id) =>
  api.get(`/admin/users/${id}/data`).then((resp) => resp.data);

export const getUserTickets = (id) =>
  api.get(`/admin/users/${id}/tickets`).then((resp) => resp.data.tickets);

export const getUserReservations = (id) =>
  api
    .get(`/admin/users/${id}/reservations`)
    .then((resp) => resp.data.reservations);

export const getUserSubscriptions = (id) =>
  api
    .get(`/admin/users/${id}/subscriptions`)
    .then((resp) => resp.data.subscriptions);

export const getNonCustomerUserData = (id, phoneCountryCode, phoneNumber) =>
  api
    .get(`/admin/users/${id}-${phoneCountryCode}-${phoneNumber}/data`)
    .then((resp) => resp.data);

export const getUsersStats = (data) =>
  api.get("/admin/users/stats", data).then((resp) => resp.data);

export const inviteUserToMyOrganization = (data) =>
  api.post("/me/organization/invitations", { invitation: data });

export const joinOrganizationInvitation = (id, data) =>
  api
    .put(`/me/organization/invitations/${id}/join`, data)
    .then((resp) => resp.data.accessToken);

export const getOrganizationUserInvitation = (id) =>
  api.get(`/me/organization/invitations/${id}`).then((resp) => resp.data);

export const updateMyOrganizationUserInvitation = (id, data) =>
  api.put(`/me/organization/invitations/${id}`, { invitation: data });

export const deleteMyOrganizationUserInvitation = (id) =>
  api.delete(`/me/organization/invitations/${id}`);

export const updateMyOrganizationUser = (id, data) =>
  api.put(`/me/organization/users/${id}`, { user: data });

export const deleteMyOrganizationUser = (id) =>
  api.delete(`/me/organization/users/${id}`);

export const requestUserPasswordReset = (email) =>
  api.post("/password", { email }).then((resp) => resp.data.message);

export const getUserTicketsByEvent = (id) =>
  api.get(`/admin/users/${id}/tickets-by-event`);

export const getUserPasswordRules = (data) => {
  const endpoint = getAccessToken() ? "me/password-rules" : "password-rules";

  return api.get(`/${endpoint}`, data).then((resp) => resp.data.passwordRules);
};

export const getMigrationTickets = (data) =>
  api.get("/admin/migration/tickets", data).then((resp) => resp.data.tickets);

export const migrateTickets = (id) =>
  api.post(`/admin/users/${id}/migration`).then((resp) => resp.data);

export const createParkerSignupInvitation = (data) =>
  api.post("/admin/parker-signup-invitations", data);

export const getUsersListCsv = (data) =>
  api.get(`/admin/users/csv?${toQueryString(data)}`);

// Events
export const getEventStats = (data) => api.get(`/admin/events/stats`, data);

export const getAllEvents = (data) =>
  api.get(`/admin/events`, data).then((resp) => resp.data?.events);

export const getEventsMonitoringData = (id, data) =>
  api.get(`/admin/events/${id}/monitoring`, data).then((resp) => resp.data);

export const getEvent = (id, data) =>
  api.get(`/admin/events/${id}`, data).then((resp) => resp.data?.event);

export const createEvent = (data) =>
  api.post(`/events`, data).then((resp) => resp.data);

export const updateEvent = (id, data) =>
  api.put(`/events/${id}`, data).then((resp) => resp.data);

export const getAppendableEvents = (data) =>
  api.get(`/admin/events/append`, data).then((resp) => resp.data?.events);

export const appendToEvent = (id, data) =>
  api.put(`/admin/events/${id}/append`, data).then((resp) => resp.data);

export const updateEventGates = (id, data) =>
  api.put(`/admin/events/${id}/gates`, data).then((resp) => resp.data);

export const getEventsAvailableForExchange = (id, data) =>
  api.get(`/tickets/${id}/exchange`, data).then((resp) => resp.data?.events);

export const getEventReportCsv = (id, data) =>
  api.get(`/admin/events/${id}/csv?${toQueryString(data)}`);

export const getEventsCsv = (data) =>
  api.get(`/admin/events/csv?${toQueryString(data)}`);

export const getEventsCsvUrl = (data) =>
  mountAuthenticatedUrl(`${API_URL}/admin/events/csv?${toQueryString(data)}`);

// Cards
export const createCard = (userId, data) =>
  api.post(`/users/${userId}/cards`, data).then((resp) => resp.data?.card);

export const getAllCards = (userId, data) =>
  api.get(`/users/${userId}/cards`, data).then((resp) => resp.data);

// Gate Entries
export const getGatesSummaryForEvent = (eventId) =>
  api
    .get(`/admin/events/${eventId}/gates-summary`)
    .then((resp) => resp?.data?.lots);

export const getEntriesForEventGate = (eventId, eventGateId, options) =>
  api
    .get(`/admin/events/${eventId}/gates/${eventGateId}/entries`, options)
    .then((resp) => resp.data);

export const getTicketsWithNoEntries = (eventId, lotId) =>
  api
    .get(`admin/events/${eventId}/lots/${lotId}/tickets-with-no-entries`)
    .then((resp) => resp.data.tickets);

export const updateParkerGateEntry = (eventId, userId, eventGateId) =>
  api
    .put(`/admin/events/${eventId}/parkers/${userId}/gate-entry`, {
      eventGateId: parseInt(eventGateId, 10),
    })
    .then((resp) => resp);

// Assisted Purchases
export const createAssistedPurchase = (id, data) =>
  api
    .post(`/admin/users/${id}/assisted-purchases`, { assistedPurchase: data })
    .then((resp) => resp.data);

export const getAvailableEventsForAssistedPurchase = (userId) => {
  let path;
  if (userId) {
    path = `/admin/users/${userId}/assisted-purchases/available-events`;
  } else {
    path = `/admin/assisted-purchases/available-events`;
  }

  return api.get(path).then((resp) => resp.data);
};

// Advanced Rate Definitions
export const getAllAdvancedRateDefinitions = (lotId) =>
  api
    .get(`/admin/lots/${lotId}/advanced-rate-definitions`)
    .then((resp) => resp.data.advancedRateDefinitions);

export const getAdvancedRateDefinition = ({ lotId, id = "latest" }) =>
  api
    .get(`/admin/lots/${lotId}/advanced-rate-definitions/${id}`)
    .then((resp) => resp.data.advancedRateDefinition);

export const saveAdvancedRateDefinition = ({ lotId, advancedRateDefinition }) =>
  api
    .put(`/admin/lots/${lotId}/advanced-rate-definitions/latest`, {
      advancedRateDefinition,
    })
    .then((resp) => resp.data.advancedRateDefinition);

export const publishLatestAdvancedRateDefinition = (lotId) =>
  api
    .post(`/admin/lots/${lotId}/advanced-rate-definitions/latest/publish`)
    .then((resp) => resp.data.advancedRateDefinition);

export const previewAdvancedRateDefinition = (lotId, rules) =>
  api
    .post(`/admin/lots/${lotId}/advanced-rate-definitions/preview`, {
      advancedRateDefinition: { rules },
    })
    .then((resp) => resp.data.preview);

// Phone Numbers
export const checkPhoneNumber = ({
  phoneCountryCode,
  phoneNumber,
  otherOptions,
}) =>
  api
    .get(`/phone-numbers/${phoneCountryCode}/${phoneNumber}`, otherOptions)
    .then((resp) => resp.data.phoneNumber);

export const sendPhoneNumberVerification = (data) =>
  api.post("/verify/send", data);

export const verifyPhoneNumber = ({ phoneCountryCode, phoneNumber, code }) =>
  api
    .post(`/phone-numbers/${phoneCountryCode}/${phoneNumber}/verify`, { code })
    .then((resp) => resp.data.success);

// Bundle Purchases
export const getBundlePurchase = (id) =>
  api
    .get(`/admin/bundle-purchases/${id}`)
    .then((resp) => resp.data.bundlePurchase);

export const exportBundlePurchaseReceipt = (id, language) =>
  api
    .get(
      `/admin/bundle-purchases/${id}/purchase-receipt/export?request_language=${language}`,
      {
        responseType: "arraybuffer",
      }
    )
    .then((resp) => resp.data);

// Sessions
export const getMySessions = () =>
  api.get("/me/sessions").then((resp) => resp.data.sessions);

export const updateMySession = (sessionId, data) =>
  api
    .put(`/me/sessions/${sessionId}`, { session: data })
    .then((resp) => resp.data.session);

export const deleteSession = (sessionId) =>
  api.delete(`/sessions/${sessionId}`);

export const renewCurrentSession = () =>
  api.put("/me/sessions/current").then((resp) => resp.data);

// Intercom
export const getIntercomUserHash = () =>
  api.get("/me/hash").then((resp) => resp.data.hash);

// Authentication
export const getMe = (data) => api.get("/me", data).then((resp) => resp.data);

export const login = ({ data, ...options }) =>
  api.post("/login", data, options).then((resp) => resp.data);
