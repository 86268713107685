"use strict";

import { getTranslator } from "../../../shared/services/languages";

export const sortFunctions = {
  name: (name) => name.toLowerCase(),

  price: (price) => {
    let priceAsNumber = Number(price);
    if (isNaN(priceAsNumber)) priceAsNumber = 0;
    return priceAsNumber;
  },

  end: (end) => {
    const t = getTranslator();
    const typeLabel = t(
      `advancedRateEditor.fields.end.options.${end.type}.label`
    );

    let value;
    if (end.type === "duration") {
      value = `${end.duration.hours}_${end.duration.minutes}`;
    } else if (end.type === "time") {
      value = `${end.time.days}_${end.time.time}`;
    }

    return `${typeLabel}_${value}`;
  },

  time: (time) => `${time.start}-${time.end}`,

  days: (days) => {
    return ["sun", "mon", "tue", "wed", "thu", "fri"]
      .map((day) => Number(!days[day]))
      .join("");
  },
};
