export const permitIssuePeriodList = [
  { value: "P1M", label: "1 month" },
  { value: "P2M", label: "2 months" },
  { value: "P3M", label: "3 months" },
  { value: "P4M", label: "4 months" },
  { value: "P5M", label: "5 months" },
  { value: "P6M", label: "6 months" },
  { value: "P7M", label: "7 months" },
  { value: "P8M", label: "8 months" },
  { value: "P9M", label: "9 months" },
  { value: "P10M", label: "10 months" },
  { value: "P11M", label: "11 months" },
  { value: "P12M", label: "12 months" },
  { value: "P13M", label: "13 months" },
  { value: "P14M", label: "14 months" },
  { value: "P15M", label: "15 months" },
  { value: "P16M", label: "16 months" },
  { value: "P17M", label: "17 months" },
  { value: "P18M", label: "18 months" },
  { value: "P19M", label: "19 months" },
  { value: "P20M", label: "20 months" },
  { value: "P21M", label: "21 months" },
  { value: "P22M", label: "22 months" },
  { value: "P23M", label: "23 months" },
  { value: "P24M", label: "24 months" },
  { value: "P25M", label: "25 months" },
  { value: "P26M", label: "26 months" },
  { value: "P27M", label: "27 months" },
  { value: "P28M", label: "28 months" },
  { value: "P29M", label: "29 months" },
  { value: "P30M", label: "30 months" },
  { value: "P31M", label: "31 months" },
  { value: "P32M", label: "32 months" },
  { value: "P33M", label: "33 months" },
  { value: "P34M", label: "34 months" },
  { value: "P35M", label: "35 months" },
  { value: "P36M", label: "36 months" },
];
