import moment from "moment-timezone";
import { getService } from "../../../../shared/react/utils";
import { getTranslator } from "../../../../shared/services/languages";

import { formatPhoneNumber } from "../../../../shared/utils/phone-number-formatting";
import { getEventsMonitoringData } from "../../../../shared/api";
import { setPageTitle } from "../../../../shared/services/helper";
import Permissions from "../../../../shared/services/permissions";

const calculateWaitlistEntryStatus = (waitlistEntry) => {
  const t = getTranslator();

  if (waitlistEntry.deletedAt) {
    if (waitlistEntry.TicketId) {
      return t("event.waitlistStatuses.purchased");
    }

    if (
      waitlistEntry.expiresAt !== null &&
      moment(waitlistEntry.deletedAt) >= moment(waitlistEntry.expiresAt)
    ) {
      return t("event.waitlistStatuses.expired");
    }

    return t("event.waitlistStatuses.cancelled");
  }

  if (waitlistEntry.activatedAt) {
    return t("event.waitlistStatuses.active");
  }

  return t("event.waitlistStatuses.waiting");
};

export const prepareWaitlistEntries = (waitlistEntries) =>
  waitlistEntries.map((waitlistEntry) => ({
    id: waitlistEntry.id,
    userName: waitlistEntry.User.name,
    email: waitlistEntry.User.email,
    phoneNumber: waitlistEntry.User.phoneNumber,
    phoneCountryCode: waitlistEntry.User.phoneCountryCode,
    createdAt: waitlistEntry.createdAt,
    deletedAt: waitlistEntry.deletedAt,
    expiresAt: waitlistEntry.expiresAt,
    activatedAt: waitlistEntry.activatedAt,
    status: calculateWaitlistEntryStatus(waitlistEntry),
  }));

export const loadWaitlistData = ({ lastHash }) => {
  const $stateParams = getService("$stateParams");
  const eventId = $stateParams?.id;

  if (!eventId) return;

  return getEventsMonitoringData(eventId, {
    params: {
      include_waitlist_entries: true,
      include_tickets: false,
    },
  })
    .then((response) => {
      setPageTitle("Event: " + response.name);

      if (response.hash === lastHash) {
        return;
      }

      return {
        waitlistEntries: prepareWaitlistEntries(response.waitlistEntries),
        lastHash: response.hash,
      };
    })
    .catch((err) => ({ error: err }));
};

export const getData = ({ waitlistEntries, user, permissions }) => {
  const t = getTranslator();

  const canSeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  const columns = [
    canSeeUserInformation && {
      key: "userName",
      value: t("event.username"),
      width: "16%",
      nowrap: "nowrap",
    },
    canSeeUserInformation && {
      key: "email",
      value: t("event.email"),
      width: "23%",
      nowrap: "nowrap",
    },
    canSeeUserInformation && {
      key: "phoneNumber",
      value: t("event.phone"),
      width: "15%",
      nowrap: "nowrap",
    },
    {
      key: "createdAt",
      value: t("event.joinedAt"),
      width: "18%",
    },
    {
      key: "activatedAt",
      value: t("event.activatedAt"),
      width: "18%",
    },
    {
      key: "status",
      value: t("event.status"),
      width: "10%",
      nowrap: "nowrap",
    },
  ];

  const data = waitlistEntries?.map((waitlistEntry) => ({
    ...(canSeeUserInformation && {
      userName: {
        value: waitlistEntry.userName || "—",
        nowrap: "nowrap",
      },
    }),
    ...(canSeeUserInformation && {
      email: {
        value: waitlistEntry.email || "—",
        nowrap: "nowrap",
      },
    }),
    ...(canSeeUserInformation && {
      phoneNumber: {
        value: formatPhoneNumber(
          waitlistEntry.phoneNumber,
          waitlistEntry.phoneCountryCode
        ),
        nowrap: "nowrap",
      },
    }),
    createdAt: {
      value: t("defaultFormats.shortDatetime", {
        date: moment(waitlistEntry.createdAt).tz(user.timezoneName),
      }),
      nowrap: "nowrap",
    },
    activatedAt: {
      value: waitlistEntry.activatedAt
        ? t("defaultFormats.shortDatetime", {
            date: moment(waitlistEntry.activatedAt).tz(user.timezoneName),
          })
        : "—",
      nowrap: "nowrap",
    },
    status: {
      value: waitlistEntry.status,
      nowrap: "nowrap",
    },
  }));

  return {
    columns,
    data,
  };
};
