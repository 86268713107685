import React from "react";
import { find, get, isUndefined, sum } from "lodash";
import moment from "moment-timezone";
import { getService } from "../../../../shared/react/utils";
import { getTranslator } from "../../../../shared/services/languages";

import { formatPhoneNumber } from "../../../../shared/utils/phone-number-formatting";
import HighlightText from "../../../../shared/react/components/HighlightText";
import GuestBadge from "../../../../shared/react/components/GuestBadge";
import TicketStatusBadge from "../../../../shared/react/components/TicketStatusBadge";
import { getEventsMonitoringData } from "../../../../shared/api";
import { setPageTitle } from "../../../../shared/services/helper";
import Permissions from "../../../../shared/services/permissions";

const eventStatus = ["SOLD", "PARKED", "TRANSFERRED", "CANCELLED"];

const ticketsBySearch = ({ tickets, search }) => {
  const filters = (ticket) => {
    return [ticket.userName, ticket.phoneNumber, ticket.lotName].map((item) =>
      item?.toLowerCase()
    );
  };

  return tickets.filter((ticket) =>
    !search
      ? ticket
      : filters(ticket)?.join(",")?.indexOf(search.toLowerCase()) > -1
  );
};

const formatTickets = ({ lots, tickets }) => {
  const t = getTranslator();

  return tickets.map((ticket) => {
    const owner = ticket.User || ticket.Purchaser;
    const lot = find(lots, (lot) => lot.id === ticket.LotId);

    return {
      id: ticket.id,
      createdAt: ticket.createdAt,
      time: moment(ticket.createdAt).locale("en").format("HH:mm"),
      attendance: ticket.attendance,
      purchaser: ticket.Purchaser,
      cancelledAt: ticket.cancelledAt,
      rate: ticket.rate,
      lotId: ticket.LotId,
      lotName: get(lot, "name"),
      lotTimezoneName: get(lot, "timezoneName"),
      userName: ticket.isPosPurchase ? t("users.posUser") : get(owner, "name"),
      isGuest: get(ticket.User, "isGuest"),
      phoneNumber: get(owner, "phoneNumber"),
      phoneCountryCode: get(owner, "phoneCountryCode"),
      email: get(owner, "email"),
      purchaserId: get(ticket.Purchaser, "id", null),
      userId: get(ticket.User, "id", null),
      ownerId: get(owner, "id", null),
      statusTags: ticket.statusTags,
      statusTag: ticket.statusTags[0],
    };
  });
};

export const getStats = ({ tickets, lots, propertyId }) => {
  const filteredTickets = tickets.filter((item) =>
    propertyId ? item.lotId === propertyId : item
  );
  const filteredLots = lots.filter((item) =>
    propertyId ? item.id === propertyId : item
  );

  const $stateParams = getService("$stateParams");
  const lotId = $stateParams?.lot;
  const lot = find(filteredLots, (lot) => lot.id === lotId);
  const lotsAvailable = lot ? lot.available : sum(filteredLots, "available");

  const ticketsByStatusTags = ({ status, except }) => {
    if (except) {
      return filteredTickets
        ?.filter((ticket) => !ticket?.statusTags.includes(except))
        ?.length?.toString();
    } else {
      return filteredTickets
        ?.filter((ticket) => ticket?.statusTags.includes(status))
        ?.length?.toString();
    }
  };

  return {
    sold: `${ticketsByStatusTags({ except: "cancelled" })}/${lotsAvailable}`,
    parked: ticketsByStatusTags({ status: "parked" }),
    transferred: ticketsByStatusTags({ status: "transferred" }),
    cancelled: ticketsByStatusTags({ status: "cancelled" }),
  };
};

export const showStat = (value) => (!isUndefined(value) ? value : <>&mdash;</>);

export const getEventStatus = (status) =>
  eventStatus.find((item) => item === status?.toUpperCase())?.toLowerCase() ||
  "sold";

export const selectDefaultFilters = () => {
  const $state = getService("$state");
  let changed = false;
  let params = { ...$state.params, tab: "tickets" };

  if (!params?.status) {
    params = { ...params, status: "sold" };
  }

  if (params) {
    $state.transitionTo($state.current.name, params, {
      location: "replace",
      notify: false,
      inherit: true,
    });
  }
  return { changed };
};

export const loadEventData = async ({ lastHash }) => {
  const $stateParams = getService("$stateParams");
  const eventId = $stateParams?.id;

  if (!eventId) return;

  return getEventsMonitoringData(eventId, {
    params: {
      include_waitlist_entries: false,
      include_tickets: true,
    },
  })
    .then((response) => {
      setPageTitle("Event: " + response.name);

      if (response.hash === lastHash) {
        return;
      }

      return {
        lots: response.lots,
        rawTickets: formatTickets({
          lots: response.lots,
          tickets: response.tickets,
        }),
        lastHash: response.hash,
      };
    })
    .catch((err) => ({ error: err }));
};

export const getData = ({
  tickets,
  search,
  handleSort,
  orders,
  permissions,
}) => {
  const t = getTranslator();
  const $state = getService("$state");
  const canOpenTicket = Permissions.userMayOpenTicketPage();
  const canSeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  const columns = [
    {
      key: "createdAt",
      value: t("event.date"),
      width: "15%",
      sortableKey: "createdAt",
      onClick: (e) => handleSort(e, "createdAt"),
      sortable: true,
      orders,
      nowrap: "nowrap",
    },
    {
      key: "eventTime",
      value: t("event.time"),
      width: "10%",
    },
    canSeeUserInformation && {
      key: "userName",
      value: t("event.username"),
      width: "25%",
      sortableKey: "userName",
      onClick: (e) => handleSort(e, "userName"),
      sortable: true,
      orders,
      nowrap: "nowrap",
    },
    canSeeUserInformation && {
      key: "phoneNumber",
      value: t("event.phone"),
      width: "15%",
      sortableKey: "phoneNumber",
      onClick: (e) => handleSort(e, "phoneNumber"),
      sortable: true,
      orders,
      nowrap: "nowrap",
    },
    {
      key: "lotName",
      value: t("event.property"),
      width: "20%",
      sortableKey: "lotName",
      onClick: (e) => handleSort(e, "lotName"),
      sortable: true,
      orders,
      nowrap: "nowrap",
    },
    {
      key: "statusTag",
      value: t("event.status"),
      width: "15%",
      sortableKey: "statusTag",
      onClick: (e) => handleSort(e, "statusTag"),
      sortable: true,
      orders,
      nowrap: "nowrap",
    },
  ];

  const data = ticketsBySearch({ tickets, search })?.map((ticket) => ({
    ...(canOpenTicket && {
      onClick: () =>
        $state.go("ticket", { id: ticket.id }, { enableBackLink: true }),
    }),
    createdAt: {
      value: t("defaultFormats.date", {
        date: moment(ticket.createdAt).tz(ticket.lotTimezoneName),
      }),
      nowrap: "nowrap",
      verticalAlign: "middle",
    },
    eventTime: {
      value: t("defaultFormats.time", {
        date: moment(ticket.createdAt).tz(ticket.lotTimezoneName),
      }),
      nowrap: "nowrap",
      verticalAlign: "middle",
    },
    ...(canSeeUserInformation && {
      userName: {
        value: ticket?.isGuest ? (
          <GuestBadge />
        ) : (
          <HighlightText flags="-i" text={ticket.userName} highlight={search} />
        ),
        verticalAlign: "middle",
        nowrap: "nowrap",
      },
    }),
    ...(canSeeUserInformation && {
      phoneNumber: {
        value: ticket.phoneNumber ? (
          <HighlightText
            flags="-i"
            text={formatPhoneNumber(
              ticket.phoneNumber,
              ticket.phoneCountryCode
            )}
            highlight={search}
          />
        ) : (
          "—"
        ),
        verticalAlign: "middle",
        nowrap: "nowrap",
      },
    }),
    lotName: {
      value: (
        <HighlightText flags="-i" text={ticket.lotName} highlight={search} />
      ),
      verticalAlign: "middle",
      nowrap: "nowrap",
    },
    statusTag: {
      value: <TicketStatusBadge fullWidth statusTag={ticket.statusTag} />,
      verticalAlign: "middle",
      nowrap: "nowrap",
    },
  }));

  return {
    columns,
    data,
  };
};
