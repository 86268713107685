import React, { useState } from "react";

import PageHeader from "../../shared/react/components/PageHeader";
import { useService, useTranslator } from "../../shared/react/hooks";
import { Overview } from "./Overview/Overview";
import { Tab } from "@citifyd/style";
import { PosRoverProvider } from "./hooks/usePosRoverCtx";
import { Devices } from "./Devices/Devices";
import { Agents } from "./Agents/Agents";
import { Transactions } from "./Transactions/Transcations";

const PosRover = () => {
  return (
    <PosRoverProvider>
      <PageContent />
    </PosRoverProvider>
  );
};
const PageContent = () => {
  const t = useTranslator();
  const $state = useService("$state");

  const [selectedTab, setSelectedTab] = useState(
    $state.params.tab || "overview"
  );

  const onTabClick = ({ name }: { name: String }) => {
    setSelectedTab(name);

    $state.transitionTo(
      "rover",
      {
        ...$state.params,
        tab: name,
      },
      { notify: false, location: "replace", inherit: true }
    );
  };

  return (
    <>
      <PageHeader>
        <PageHeader.Title weight="300" title={t("posRover.pageTitle")} />
      </PageHeader>

      <Tab stateless>
        <Tab.Item
          onClick={() => onTabClick({ name: "overview" })}
          active={selectedTab === "overview"}
          name={t("posRover.devices.overview")}
        >
          <Overview />
        </Tab.Item>
        <Tab.Item
          onClick={() => onTabClick({ name: "devices" })}
          active={selectedTab === "devices"}
          name={t("posRover.devices.devices")}
        >
          <Devices />
        </Tab.Item>
        <Tab.Item
          onClick={() => onTabClick({ name: "agents" })}
          active={selectedTab === "agents"}
          name={t("posRover.agents.title")}
        >
          <Agents />
        </Tab.Item>
        <Tab.Item
          onClick={() => onTabClick({ name: "transactions" })}
          active={selectedTab === "transactions"}
          name={t("posRover.transactions.title")}
        >
          <Transactions />
        </Tab.Item>
      </Tab>
    </>
  );
};

export default PosRover;
