import React, { useMemo, useState } from "react";
import {
  Badge,
  Grid,
  Modal,
  Button,
  Text,
  LoadingIndicator,
} from "@citifyd/style";

import Table from "../../react/components/Table";
import * as yup from "yup";
import { useFormik } from "formik";
import FormikField, { FIELD_TYPE } from "../../react/components/FormikField";
import {
  DeviceQuery,
  useEndSessionMutation,
  useUpdateProvisionMutation,
} from "../../../graphql-pos/generated/graphql";
import { currencyFormatter } from "../../utils/currencyFormtter";
import { useTranslator } from "../../react/hooks";
import styles from "./RoverManageDeviceModal.module.scss";
import PosApolloClient from "../../services/posApolloClient";
import FormikProvider from "../../react/components/FormikProvider";
import { showErrorAlert } from "../../services/helper";

const posApolloClient = PosApolloClient();

interface FormProps {
  lot: "" | number;
  gate: "" | number;
}

export const RoverManageDeviceModal = ({
  device,
  open,
  onClose,
  setSuccessMessage,
}: {
  device: DeviceQuery["device"];
  open: boolean;
  onClose: () => void;
  setSuccessMessage: (device: string) => any;
}) => {
  const t = useTranslator();
  const [isUpdatingProvision, setIsUpdatingProvision] =
    useState<boolean>(false);
  const [isFinishingSession, setIsFinishingSession] = useState<boolean>(false);
  const { tally, currentProvision, shortCode, id, serial } = device;

  const [updateProvision] = useUpdateProvisionMutation({
    client: posApolloClient,
  });

  const [endSession] = useEndSessionMutation({
    client: posApolloClient,
  });

  currentProvision?.citifydEvent.id;

  const agentName = device?.currentSession?.agent.name;
  const currentSelectedLot = currentProvision?.citifydLot;
  const currentSelectedGate = currentProvision?.citifydGate;

  const onFinishButtonClick = async () => {
    if (
      window.confirm(
        t("posRover.devices.areYouSure", {
          device: shortCode,
          agent: agentName,
        })
      )
    ) {
      try {
        setIsFinishingSession(true);
        await endSession({
          variables: { id: String(device?.currentSession?.id) },
        });

        onClose();
      } catch (err) {
        console.log({ err });
        showErrorAlert(err.message);
      } finally {
        setIsFinishingSession(false);
      }
    }
  };

  const initialValues: FormProps = {
    lot: currentSelectedLot?.id ?? "",
    gate: currentSelectedGate?.id ?? "",
  };
  const formikbag = useFormik({
    initialValues,
    validationSchema: yup.object().shape({}),

    onSubmit: async (values) => {
      setIsUpdatingProvision(true);
      try {
        await updateProvision({
          variables: {
            deviceSerialId: String(serial),
            citifydGateId: String(values.gate),
            citifydEventId: String(currentProvision?.citifydEvent.id),
          },
        });
        setSuccessMessage(device.id);
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
        onClose();
      } catch (err) {
        console.log({ err });
        showErrorAlert(err.message);
      } finally {
        setIsUpdatingProvision(false);
      }
    },
  });

  const columns = [
    { value: "Card transactions", key: "cardTransactions" },
    { value: "Cash transactions", key: "cashTransactions" },
    { value: "Scans", key: "scans" },
    { value: "Exemptions", key: "exemptions" },
    { value: "Citifyd", key: "citifyd" },
  ];

  const dataTable = [
    {
      cardTransactions: tally.card && String(tally.card.quantity),
      cashTransactions: tally.cash && String(tally.cash.quantity),
      scans: tally.scans && String(tally.scans.quantity),
      exemptions: tally.exemptions && String(tally.exemptions.quantity),
      citifyd:
        tally.citifydIngresses && String(tally.citifydIngresses.quantity),
    },
  ];

  const lotOptions = useMemo(
    () =>
      currentProvision?.citifydEvent.lots.map((lot) => ({
        value: lot.id,
        label: lot.name,
      })),
    [currentProvision?.citifydEvent.lots]
  );

  const gateOptions = useMemo(() => {
    const selectedLot = currentProvision?.citifydEvent.lots.find(
      (lotItem) => lotItem.id === formikbag.values.lot
    );

    const gates = selectedLot?.gates.map((lot) => ({
      value: lot.id,
      label: lot.name,
    }));

    if (formikbag.values.lot !== currentProvision?.citifydLot.id) {
      formikbag.setFieldValue("gate", gates?.[0].value);
    }

    return gates;
  }, [formikbag.values.lot, currentProvision?.citifydEvent.lots]);

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Modal open={open} onClose={onClose} className={styles.modal}>
        <Modal.Title textAlign="left">Device #{shortCode} </Modal.Title>
        <Modal.Content>
          {(isUpdatingProvision || isFinishingSession) && (
            <LoadingIndicator withOverlay />
          )}
          <Grid.Row>
            <Grid.Col xs={6} lg={12}>
              <Text variant="h2" weight="300">
                {t("posRover.devices.agentName", { agentName })}
              </Text>
            </Grid.Col>
            <Grid.Col xs={6} lg={12}>
              <Badge appearance="gray_lighter" className={styles.badge}>
                {currentProvision && currentProvision.citifydEvent.name}
              </Badge>
            </Grid.Col>
          </Grid.Row>

          <div className={styles.divisor}></div>

          <Grid.Row className={styles.row}>
            <Grid.Col xs={6} lg={3} offset={{ lg: 3 }}>
              <Text textAlign="center" weight="300" variant="h1">
                {t("posRover.devices.card")}
                {currencyFormatter({
                  value: tally?.card.money,
                  currency:
                    currentSelectedLot?.paymentDestination.currency || "usd",
                })}
              </Text>
            </Grid.Col>
            <Grid.Col xs={6} lg={3} offset={{ lg: -3 }}>
              <Text textAlign="center" weight="300" variant="h1">
                {t("posRover.devices.cash")}
                {currencyFormatter({
                  value: tally?.cash.money,
                  currency:
                    currentSelectedLot?.paymentDestination.currency || "usd",
                })}
              </Text>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col>
              <Table
                headerAppearance="gray_light"
                columns={columns}
                data={dataTable}
                responsive
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row className={styles.gate}>
            <Grid.Col>
              <Text variant="h2" weight="300">
                {t("posRover.devices.gateAssignment")}
              </Text>
            </Grid.Col>
          </Grid.Row>

          <div className={styles.divisor}></div>

          <Grid.Row className={styles.row}>
            <Grid.Col xs={12} lg={4}>
              <FormikField
                as={FIELD_TYPE.SELECT}
                fullWidth
                gutterBottom
                name="lot"
                label={t("posRover.devices.selectLot")}
                options={lotOptions}
              />
            </Grid.Col>
            <Grid.Col xs={12} lg={4}>
              <FormikField
                as={FIELD_TYPE.SELECT}
                fullWidth
                gutterBottom
                name="gate"
                label={t("posRover.devices.selectGate")}
                options={gateOptions}
              />
            </Grid.Col>
            <Grid.Col xs={12} lg={4} className={styles.buttons}>
              <Button
                disabled={isFinishingSession || isUpdatingProvision}
                onClick={onFinishButtonClick}
                justifyContent="center"
                extraPadding
                fullWidth
                size="small"
              >
                {t("posRover.commons.finish")}
              </Button>
              <Button
                disabled={isFinishingSession || isUpdatingProvision}
                type="submit"
                onMouseDown={() => handleSubmit()}
                justifyContent="center"
                extraPadding
                fullWidth
                size="small"
              >
                {t("posRover.commons.save")}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Modal.Content>
      </Modal>
    </FormikProvider>
  );
};
