"use strict";
import React from "react";

import { Label, Text } from "@citifyd/style";
import { CardElement } from "@stripe/react-stripe-js";
import { useTranslator } from "../../../../react/hooks";
import styles from "./Card.module.scss";
import { COMPLETE_CARD_CLASS_NAME } from "../../functions/functions";

import classNames from "classnames";

const Card = React.forwardRef(({ stripeError, setStripeError }, ref) => {
  const t = useTranslator();
  const hasError = Boolean(stripeError);

  const handleChange = (e) => {
    setStripeError(e.error?.message ? { message: e.error?.message } : null);
  };

  return (
    <div className={styles.container} ref={ref}>
      <Label error={hasError}>{t("modalAddCard.creditOrDebitCard")}</Label>
      <CardElement
        onChange={handleChange}
        className={classNames(styles.cardElementContainer, {
          [styles.error]: hasError,
        })}
        options={{
          hidePostalCode: true,
          classes: {
            complete: COMPLETE_CARD_CLASS_NAME,
          },
        }}
      />
      {stripeError?.message && (
        <Text appearance="error">{stripeError.message}</Text>
      )}
    </div>
  );
});

export default Card;
