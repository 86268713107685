"use strict";

import $state from "./compatibilities/state";
import $location from "./compatibilities/location";
import $rootScope from "./compatibilities/rootScope";

export const getService = (() => {
  const cache = {};
  return function (serviceName) {
    if (serviceName === "$state") {
      return $state;
    }
    if (serviceName === "$location") {
      return $location;
    }
    if (serviceName === "$stateParams") {
      return $state.params;
    }
    if (serviceName === "$rootScope") {
      return $rootScope;
    }
  };
})();
