import React from "react";

import styles from "./UsersChart.module.scss";

const UsersChart = ({ mainChartValues, transferChartValues }) => {
  return (
    <div className={styles.chartCeption}>
      <svg viewBox="0 0 32 32">
        <circle
          className={styles.purchased}
          r="16"
          cx="16"
          cy="16"
          style={mainChartValues.purchased}
        />
        <circle
          className={styles.expired}
          r="16"
          cx="16"
          cy="16"
          style={mainChartValues.expired}
        />
        <circle
          className={styles.cancelled}
          r="16"
          cx="16"
          cy="16"
          style={mainChartValues.cancelled}
        />
        <circle
          className={styles.parked}
          r="16"
          cx="16"
          cy="16"
          style={mainChartValues.parked}
        />
      </svg>

      <div className={styles.smallChart}>
        <svg viewBox="0 0 32 32">
          <circle
            className={styles.transfers}
            r="16"
            cx="16"
            cy="16"
            style={transferChartValues.pendingTransfer}
          />
          <circle
            className={styles.redeemed}
            r="16"
            cx="16"
            cy="16"
            style={transferChartValues.redeemed}
          />
        </svg>
      </div>
    </div>
  );
};

export default UsersChart;
