"use strict";

import React from "react";
import { Select, Grid } from "@citifyd/style";

import { useReservedParkingPassRefundContext } from "./ReservedParkingPassRefundContext.jsx";
import { useTranslator } from "../../../shared/react/hooks";
import styles from "./ReservedParkingPassRefund.module.scss";
import { useAuthentication } from "../../../shared/react/contexts/authentication.js";

export default function RefundSource() {
  const t = useTranslator();
  const { refundOptions, setRefundOptions } =
    useReservedParkingPassRefundContext();
  const { user } = useAuthentication();

  if (!user.isAdmin) {
    return null;
  }

  const changeRefunder = (e) => {
    const newRefunder = e.target.value;

    setRefundOptions({
      ...refundOptions,
      refunder: newRefunder,
      // Makes sure that whenever the user changes the refunder, that refunder will be responsible
      // for paying the Stripe processing fees by default.
      stripeFeePayer: newRefunder,
    });
  };

  const changeStripeFeePayer = (e) =>
    setRefundOptions({
      ...refundOptions,
      stripeFeePayer: e.target.value,
    });

  return (
    <div className={styles.boxContent}>
      <Grid.Row>
        <Grid.Col sm={12} md={6}>
          <Select
            fullWidth
            label={t("refundPages.deductTicketFundsFrom.label")}
            onChange={changeRefunder}
            value={refundOptions.refunder}
            options={[
              {
                label: t("refundPages.deductTicketFundsFrom.values.seller"),
                value: "seller",
              },
              {
                label: t("refundPages.deductTicketFundsFrom.values.citifyd"),
                value: "citifyd",
              },
            ]}
          />
          <br />
        </Grid.Col>
        <Grid.Col sm={12} md={6}>
          <Select
            fullWidth
            label={t("refundPages.deductStripeProcessingFeeFundsFrom.label")}
            onChange={changeStripeFeePayer}
            value={refundOptions.stripeFeePayer}
            disabled={refundOptions.refunder === "citifyd"}
            options={[
              {
                label: t(
                  "refundPages.deductStripeProcessingFeeFundsFrom.values.seller"
                ),
                value: "seller",
              },
              {
                label: t(
                  "refundPages.deductStripeProcessingFeeFundsFrom.values.citifyd"
                ),
                value: "citifyd",
              },
            ]}
          />
        </Grid.Col>
      </Grid.Row>
    </div>
  );
}
