"use strict";

import React, { useRef, useState, useEffect, useMemo } from "react";
import { Text } from "@citifyd/style";
import { useTranslator, useService } from "../../../../../shared/react/hooks";
import LoadingManager from "../../../../../shared/react/components/LoadingManager";
import RouterLink from "../../../../../shared/react/components/RouterLink";
import Table from "../../../../../shared/react/components/Table";
import styles from "./Summary.module.scss";
import { loadSummaryInfo, getSummaryTableData, getLotTableData } from "./utils";

const Summary = ({ setSelectedEventGate, ...rest }) => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [summaryInfo, setSummaryInfo] = useState([]);
  const dataUpdateTimer = useRef();
  const eventId = $stateParams?.id;
  const summaryTableContent = useMemo(
    () => getSummaryTableData({ summaryInfo }),
    [summaryInfo]
  );
  const lotTableContent = (lot) => getLotTableData({ lot, openGateView });

  const openGateView = (eventGate) => {
    setSelectedEventGate({ ...eventGate, allLots: summaryInfo });
  };

  const viewTicketsWithNoEntries = ({ lot, lots }) => {
    setSelectedEventGate({
      id: -1,
      gate: { isSystemGate: true },
      name: t("event.unredeemedPasses"),
      lot: lot,
      allLots: lots,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await loadSummaryInfo(eventId);
        setSummaryInfo(data);
      } catch (err) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    dataUpdateTimer.current = setInterval(() => fetchData(), 5000);

    fetchData();

    return () => {
      clearInterval(dataUpdateTimer?.current);
    };
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  if (!isLoading && !summaryInfo) {
    return (
      <div className={styles.noGates}>
        <Text>{t("event.noGatesSetUp")}&nbsp;</Text>
        <RouterLink
          underline
          state="edit-event-properties"
          params={{ eventId: eventId }}
        >
          {t("event.setupGates")} &raquo;
        </RouterLink>
      </div>
    );
  }

  return (
    <div {...rest}>
      {summaryTableContent && (
        <>
          <Text variant="h4" gutterBottom>
            {t("event.summary")}
          </Text>
          <Table
            data={summaryTableContent?.data}
            columns={summaryTableContent?.columns}
            responsive
            striped
            borderBottom
          />
        </>
      )}
      {summaryInfo?.map((lot, index) => (
        <div key={index} className={styles.lot}>
          <Text variant="h4" gutterBottom>
            {lot.name}
          </Text>
          <Table
            data={lotTableContent(lot)?.data}
            columns={lotTableContent(lot)?.columns}
            responsive
            striped
          />
          {lot.amountOfUnredeemedTickets > 0 && (
            <Text
              appearance="tertiary"
              className={styles.unredeemedLink}
              underline
              onClick={() =>
                viewTicketsWithNoEntries({ lot, lots: summaryInfo })
              }
            >
              {t("event.viewUnredeemedPasses", {
                count: lot.amountOfUnredeemedTickets,
              })}
            </Text>
          )}
        </div>
      ))}
    </div>
  );
};

export default Summary;
