"use strict";

import React, { useState } from "react";
import {
  Icon,
  Text,
  Button,
  Grid,
  List,
  Tooltip,
  Link,
  ToggleButton,
} from "@citifyd/style";
import moment from "moment-timezone";

import SmallLoadingIndicator from "../../../../shared/react/components/SmallLoadingIndicator";
import Permissions from "../../../../shared/services/permissions";
import Dictionary, {
  DictionaryItem,
} from "../../../../shared/react/components/Dictionary";
import GuestBadge from "../../../../shared/react/components/GuestBadge";
import { formatPhoneNumber } from "../../../../shared/utils/phone-number-formatting";
import BoxContent from "../../../../shared/react/components/BoxContent";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import RouterLink from "../../../../shared/react/components/RouterLink";
import UserVehicleInformationModal from "../../../../shared/modals/UserVehicleInformationModal";
import UserSessionInformationModal from "../../../../shared/modals/UserSessionInformationModal";
import {
  openMigrationTicketsInformation,
  getAddress,
  openUserUpdateEmail,
  openUserUpdatePhoneNumber,
} from "./utils";
import styles from "./UserSummary.module.scss";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";
import { useModal } from "../../../../shared/react/contexts/modal";
import { blockUser } from "../../../../shared/api";

const UserSummary = ({
  user,
  nonCustomer,
  migrationTickets,
  migrationTicketsLoadError,
  loadUser,
}) => {
  const { permissions } = useAuthentication();

  return (
    <>
      <UserSummaryInfo
        user={user}
        nonCustomer={nonCustomer}
        loadUser={loadUser}
      />

      {Permissions.userMayManageMigrationTickets(permissions) && (
        <UserSummaryAdditionalInfo
          user={user}
          migrationTickets={migrationTickets}
          migrationTicketsLoadError={migrationTicketsLoadError}
          loadUser={loadUser}
        />
      )}
    </>
  );
};

const UserSummaryInfo = ({ user, nonCustomer, loadUser }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const {
    impersonateUser,
    loggedUserIsAdmin,
    permissions,
    user: loggedUser,
  } = useAuthentication();
  const { openModal } = useModal();
  const [isActiveStatus, setIsActiveStatus] = useState(
    user?.status === "active"
  );

  const canAuthenticate = Permissions.userMayAuthenticateAsOthers(permissions);
  const canSeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  const authenticateAsUser = () => {
    impersonateUser(user?.id).then(
      () => {
        $state.go("dashboard");
      },
      () => window.alert(t("user.errorAuthenticatingAsUser"))
    );
  };

  const openSessionInformation = () =>
    openModal(UserSessionInformationModal, {
      user,
      onClose: ({ changedSession } = {}) => {
        if (changedSession) {
          loadUser();
        }
      },
    });

  const openVehicleInformation = () =>
    openModal(UserVehicleInformationModal, {
      user,
    });

  const toggleUserStatus = async () => {
    if (!loggedUserIsAdmin) return;

    const confirmationMessage = isActiveStatus
      ? t("user.accountStatus.confirmation.block")
      : t("user.accountStatus.confirmation.unblock");

    if (!window.confirm(confirmationMessage)) return;

    try {
      await blockUser(user.id, !isActiveStatus);
      setIsActiveStatus((currentState) => !currentState);
    } catch (err) {
      console.error("Error toggling user status", err);
    }
  };

  return (
    <BoxContent>
      <BoxContent.Header
        title={t("user.userId", {
          id: user?.id,
        })}
      >
        <BoxContent.HeaderActions className={styles.actions}>
          {!nonCustomer && canSeeUserInformation && (
            <Button appearance="transparent" onClick={openSessionInformation}>
              <Icon
                icon="mobile-alt"
                size="h2"
                appearance="white"
                title={t("user.deviceInformation")}
              />
            </Button>
          )}

          {!nonCustomer && !user?.isGuest && (
            <Button appearance="transparent" onClick={openVehicleInformation}>
              <Icon
                icon="car-alt"
                size="h2"
                appearance="white"
                title={t("user.vehicleInformation")}
              />
            </Button>
          )}

          {canAuthenticate && !user?.isGuest && (
            <Button
              size="small"
              onClick={authenticateAsUser}
              appearance="gray"
              rounded
            >
              {t("user.login")}
            </Button>
          )}

          {loggedUserIsAdmin && (
            <div className={styles.status}>
              <Text appearance="white">
                {isActiveStatus
                  ? t("user.accountStatus.label.active")
                  : t("user.accountStatus.label.blocked")}
              </Text>
              <ToggleButton
                customClassName={styles.toggle}
                selected={isActiveStatus}
                light
                onChange={toggleUserStatus}
              />
            </div>
          )}
        </BoxContent.HeaderActions>
      </BoxContent.Header>

      <BoxContent.Content>
        <Text
          component="span"
          variant="h3"
          weight="700"
          className={styles.username}
        >
          {user?.name && <span>{user.name}</span>}
          {!user?.name && user?.phoneNumber && (
            <span>
              {formatPhoneNumber(user.phoneNumber, user.phoneCountryCode)}
            </span>
          )}
          {!user?.name && !user?.phoneNumber && (
            <span>{t("user.customer")}</span>
          )}
          {user?.isGuest && <GuestBadge className={styles.badge} />}
        </Text>

        <Grid.Row>
          <Grid.Col xs={12} md={6}>
            <List border className={styles.list}>
              <List.Divisor />
              {/**see-organization */}
              {loggedUser.isAdmin && user?.organization && (
                <List.Item>
                  {t("user.organization")}
                  <RouterLink
                    appearance="tertiary"
                    state="organization"
                    params={{ id: user.organization?.id }}
                    options={{ enableBackLink: true }}
                    className={styles.badge}
                  >
                    {user.organization.name}
                  </RouterLink>
                </List.Item>
              )}

              {canSeeUserInformation && user?.phoneNumber && (
                <List.Item>
                  {formatPhoneNumber(user.phoneNumber, user.phoneCountryCode)}
                  {!user.isGuest && (
                    <TooltipIcon
                      id="verifiedPhoneNumber"
                      value={user?.verifiedPhoneNumber}
                    />
                  )}
                </List.Item>
              )}

              {canSeeUserInformation && !user?.phoneNumber && (
                <List.Item>{t("user.noPhoneNumber")}</List.Item>
              )}

              {canSeeUserInformation && !user?.isGuest && (
                <List.Item>
                  {user?.email}

                  <TooltipIcon id="email" value={user?.verifiedEmail} />
                </List.Item>
              )}

              <List.Item>
                {t("user.registeredSince", {
                  date: user?.createdAt,
                  amTimezone: loggedUser.timezoneName,
                })}
              </List.Item>

              {user?.market && (
                <List.Item>
                  {t("user.userMarket", {
                    marketRegion: getAddress(user),
                  })}
                </List.Item>
              )}
            </List>
          </Grid.Col>

          <Grid.Col xs={12} md={6}>
            {!nonCustomer && (
              <Dictionary>
                <Item label={t("user.lastTransaction")}>
                  {user?.lastTransactionDate &&
                    t("defaultFormats.date", {
                      date: moment.tz(
                        user.lastTransactionDate,
                        loggedUser.timezoneName
                      ),
                    })}
                </Item>

                {user?.merchantValidationAmounts && (
                  <Item label={t("user.transactions")}>
                    {user?.formattedTotalBalance}
                  </Item>
                )}

                {!user?.isGuest && user?.merchantValidationAmounts && (
                  <>
                    <Item label={t("user.validations")}>
                      {user?.formattedTotalValidations}
                    </Item>

                    <Item label={t("user.balance")}>
                      {user?.formattedTotalAvailableForRefund}
                    </Item>
                  </>
                )}
              </Dictionary>
            )}
          </Grid.Col>
        </Grid.Row>
      </BoxContent.Content>
    </BoxContent>
  );
};

const UserSummaryAdditionalInfo = ({
  user,
  migrationTickets,
  migrationTicketsLoadError,
  loadUser,
}) => {
  const t = useTranslator();
  const { openModal } = useModal();

  const yesNo = (value) => (value ? t("yes") : t("no"));

  const getMigrationLabel = () => (
    <>
      {t("user.pendingMigration")}

      {migrationTickets && migrationTickets?.length > 0 && (
        <Link
          appearance="default"
          className={styles.link}
          onClick={() =>
            openMigrationTicketsInformation(migrationTickets, user, openModal)
          }
        >
          ({t("user.view")})
        </Link>
      )}
    </>
  );

  return (
    <BoxContent>
      <BoxContent.Header title={t("user.additionalInfo")} />
      <BoxContent.Content>
        <Grid.Row marginBottom>
          <Grid.Col xs={12} md={6}>
            <Dictionary withDivisor={false}>
              <Item label={t("user.role")}>
                {user?.role === "admin"
                  ? t("user.roleAdmin")
                  : t("user.roleUser")}
              </Item>

              {!user?.isGuest && (
                <Item label={t("user.fbLogin")}>
                  {yesNo(user?.facebookProfile !== null)}
                </Item>
              )}

              <Item label={t("user.stripeId")}>
                {user?.stripeCustomerId && (
                  <Link href={user.stripeCustomerUrl} target="_blank">
                    {user?.stripeCustomerId}
                  </Link>
                )}
              </Item>

              {!user?.isGuest && (
                <Item label={getMigrationLabel(user)}>
                  {migrationTickets && migrationTickets?.length}
                  {migrationTicketsLoadError && t("user.error")}
                  {!migrationTicketsLoadError && !migrationTickets && (
                    <SmallLoadingIndicator />
                  )}
                </Item>
              )}
            </Dictionary>
          </Grid.Col>

          <Grid.Col xs={12} md={6}>
            <Dictionary withDivisor={false}>
              <Item label={t("user.marketingNotifications")}>
                {yesNo(user?.receiveMarketingNotifications)}
              </Item>

              {!user?.isGuest && (
                <>
                  <Item label={t("user.emailNotifications")}>
                    {yesNo(user?.receiveEmailNotifications)}
                  </Item>

                  <Item label={t("user.pushNotificications")}>
                    {yesNo(user?.receivePushNotifications)}
                  </Item>
                </>
              )}

              <Item label={t("user.textNotifications")}>
                {yesNo(user?.receiveTextMessageNotifications)}
              </Item>
            </Dictionary>
          </Grid.Col>
        </Grid.Row>

        {!user?.isGuest && (
          <Button
            uppercase
            size="small"
            onClick={() => openUserUpdateEmail({ user, loadUser, openModal })}
          >
            {t("user.updateEmail")}
          </Button>
        )}

        <Button
          uppercase
          size="small"
          onClick={() =>
            openUserUpdatePhoneNumber({ user, loadUser, openModal })
          }
        >
          {t("user.updatePhoneNumber")}
        </Button>
      </BoxContent.Content>
    </BoxContent>
  );
};

const TooltipIcon = ({ id, value }) => {
  const t = useTranslator();

  return (
    <Tooltip
      id={id}
      icon={value ? "check" : "times"}
      iconProps={{ className: styles.icon }}
    >
      {value ? t("user.verified") : t("user.notVerified")}
    </Tooltip>
  );
};

const Item = ({ children, label }) => (
  <DictionaryItem textComponent="span" label={label} labelWeight="400">
    {children || "—"}
  </DictionaryItem>
);

export default UserSummary;
