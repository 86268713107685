import React from "react";
import { Checkbox, Radio } from "@citifyd/style";
import _ from "lodash";
import moment from "moment-timezone";
import CurrencyFormatter from "@citifyd/currency-formatter";
import {
  getTranslator,
  getCurrentLanguage,
} from "../../../../shared/services/languages";

import styles from "./UserPurchaseTicketPayment.module.scss";

export const getPaymentMethodsData = ({
  canSeeCardExpiration,
  cards,
  selectedCardId,
  handleSelectCard,
  selectedBundles,
  selectedEvents,
  complimentaryOption,
}) => {
  const t = getTranslator();

  const columns = [
    {
      key: "radio",
      value: "",
      width: "1%",
    },
    {
      key: "card",
      value: t("userPurchaseTicket.card"),
      width: "25%",
    },
    {
      key: "cardNumber",
      value: t("userPurchaseTicket.cardNumber"),
      width: "55%",
    },
    canSeeCardExpiration && {
      key: "expiration",
      value: t("userPurchaseTicket.expiration"),
      width: "19%",
    },
  ];

  const cardList = cards.map((card) => ({
    onClick: () => handleSelectCard({ cardId: card.id }),
    hover: false,
    radio: {
      value: <Radio small checked={card.id === selectedCardId} />,
      noVerticalPadding: true,
      verticalAlign: "middle",
    },
    card: card.brand,
    cardNumber: `•••• •••• •••• ${card.last4}`,
    ...(canSeeCardExpiration && {
      expiration: `${card.expMonth}/${card.expYear}`,
    }),
  }));

  const complimentaryCard = {
    onClick: () => handleSelectCard({ cardId: "_complimentary" }),
    hover: false,
    radio: {
      value: <Radio small checked={"_complimentary" === selectedCardId} />,
      noVerticalPadding: true,
      verticalAlign: "middle",
    },
    card: {
      value: hasMultiplePasses({ selectedBundles, selectedEvents })
        ? t("userPurchaseTicket.complimentaryMultiple", {
            name: complimentaryOption.name,
          })
        : t("userPurchaseTicket.complimentary", {
            name: complimentaryOption.name,
          }),
      nowrap: "nowrap",
    },
  };

  const hasComplimentaryOption = complimentaryOptionShouldAppear({
    complimentaryOption,
    bundles: selectedBundles,
  });

  const data = [
    ...cardList,
    {
      appearance: "transparent",
      radio: {
        value: <hr className={styles.divisor} />,
        colSpan: 4,
      },
      card: {
        hide: true,
      },
      cardNumber: {
        hide: true,
      },
      expiration: {
        hide: true,
      },
    },
    ,
    hasComplimentaryOption && complimentaryCard,
  ];

  return {
    columns,
    data,
  };
};

export const getData = ({
  handleSelectEvent,
  handleSelectBundle,
  selectedEvents,
  selectedBundles,
}) => {
  const t = getTranslator();
  const language = getCurrentLanguage();

  const handleCheckbox = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const columns = [
    {
      key: "checkbox",
      value: "",
      width: "1%",
    },
    {
      key: "eventDate",
      value: t("userPurchaseTicket.eventDate"),
      width: "20%",
    },
    {
      key: "event",
      value: t("userPurchaseTicket.event"),
      width: "45%",
    },
    {
      key: "lot",
      value: t("userPurchaseTicket.lot"),
      width: "25%",
    },
    {
      key: "amount",
      value: t("userPurchaseTicket.amount"),
      width: "9%",
    },
  ];

  const dataBundles = selectedBundles
    .filter((item) => item.selectedBundleId)
    .map((bundle) => {
      const bundleObject = bundle?.bundles.filter(
        (item) => item.id === bundle.selectedBundleId
      )[0];
      return {
        onClick: () => handleSelectBundle(bundle),
        hover: false,
        checkbox: {
          value: (
            <Checkbox checked={!!bundle.selected} onClick={handleCheckbox} />
          ),
          noVerticalPadding: true,
          verticalAlign: "middle",
        },
        eventDate: <>&mdash;</>,
        event: bundle.name,
        lot: bundleObject?.lot?.name,
        amount: CurrencyFormatter.format(calculateBundlePrice(bundleObject), {
          currency: bundleObject?.lot?.country?.currency,
          language,
        }),
      };
    });

  const dataEvents = selectedEvents
    .filter((item) => item.selectedLot)
    .map((event) => {
      return {
        ...(!event.soldOut && { onClick: () => handleSelectEvent(event) }),
        hover: false,
        checkbox: {
          value: (
            <Checkbox checked={!!event.selected} onClick={handleCheckbox} />
          ),
          noVerticalPadding: true,
          verticalAlign: "middle",
        },
        eventDate: {
          value: t("defaultFormats.date", {
            date: moment.tz(event.start, event.timezoneName),
          }),
          nowrap: "nowrap",
        },
        event: {
          value: (
            <>
              {event.name}
              {event.soldOut && <i>{t("userPurchaseTicket.soldOut")}</i>}
            </>
          ),
        },
        lot: {
          value: event.selectedLot?.name,
          nowrap: "nowrap",
        },
        amount: CurrencyFormatter.format(calculateEventPrice(event), {
          currency: event.selectedLot?.country?.currency,
          language,
        }),
      };
    });

  const data = [
    ...dataBundles,
    ...dataEvents,
    {
      appearance: "transparent",
      lot: {
        value: <b>{t("userPurchaseTicket.total")}</b>,
        appearance: "white",
      },
      amount: {
        appearance: "white",
        value: (
          <b>
            {CurrencyFormatter.format(
              getTotal({ selectedBundles, selectedEvents }),
              {
                currency: getPurchaseCurrency({
                  selectedBundles,
                  selectedEvents,
                }),
                language,
              }
            )}
          </b>
        ),
      },
    },
  ];

  return {
    columns,
    data,
  };
};

export const hasSelectedItems = ({ selectedBundles, selectedEvents }) =>
  _.some(selectedBundles, "selected") || _.some(selectedEvents, "selected");

export const complimentaryOptionShouldAppear = ({
  complimentaryOption,
  bundles,
}) => complimentaryOption && !_.some(bundles, "selected");

const hasMultiplePasses = ({ selectedBundles, selectedEvents }) => {
  if (_.some(selectedBundles, "selected")) {
    return true;
  }

  return _.filter(selectedEvents, "selected").length > 1;
};

const getPurchaseCurrency = ({ selectedBundles, selectedEvents }) => {
  const bundleCurrencies = selectedBundles
    ?.map((bundle) => {
      const filteredBundle = bundle?.bundles?.filter(
        (item) => item.id === bundle.selectedBundleId
      )[0];
      return filteredBundle?.lot?.country?.currency;
    })
    .filter((item) => item);
  const eventCurrencies = selectedEvents.map(
    (event) => event.selectedLot?.country?.currency
  );
  const currencies = _.uniq([...bundleCurrencies, ...eventCurrencies]);

  return currencies.filter((item) => item)[0];
};

const getTotal = ({ selectedBundles, selectedEvents }) => {
  const bundlesTotal = _(selectedBundles)
    .filter((item) => item.selected)
    .map(
      (bundle) =>
        bundle?.bundles.filter((item) => item.id === bundle.selectedBundleId)[0]
    )
    .sum(calculateBundlePrice);

  const eventsTotal = _(selectedEvents)
    .filter("selected")
    .sum(calculateEventPrice);

  return bundlesTotal + eventsTotal;
};

const calculateBundlePrice = (bundle) =>
  bundle?.price?.value + bundle?.price?.fee;

const calculateEventPrice = (event) =>
  event?.selectedLot?.rate?.value + event?.selectedLot?.rate?.fee;
