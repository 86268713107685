"use strict";

import React, { useEffect, useState } from "react";
import { Button, Text } from "@citifyd/style";

import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import PhoneField from "../../../../shared/react/components/PhoneField";
import PasswordRulesIndicator from "../../../../shared/react/components/PasswordRulesIndicator";

import { useTranslator } from "../../../../shared/react/hooks";

import styles from "./Form.module.scss";
import {
  getOrganizationUserInvitation,
  getUserPasswordRules,
  getCountries,
} from "../../../../shared/api";
import { showErrorAlert } from "../../../../shared/services/helper";

const Form = ({
  formikbag,
  setIsLinkExpired,
  setHasError,
  invitationCode,
  trackLoading,
  untrackLoading,
}) => {
  const t = useTranslator();

  const [userExists, setUserExists] = useState(false);
  const [invitation, setInvitation] = useState(null);
  const [countries, setCountries] = useState([]);
  const [listOfRules, setListOfRules] = useState();

  useEffect(() => {
    init();
    loadCountries();
  }, []);

  const loadCountries = async () => {
    trackLoading("countries");

    try {
      const countries = await getCountries();

      setCountries(countries);
    } catch (err) {
      setHasError(true);
      console.log("err", err);
    } finally {
      untrackLoading("countries");
    }
  };

  const getRules = async () => {
    trackLoading("getRules");
    try {
      const response = await getUserPasswordRules({
        params: {
          potentialOrganizationUser: true,
        },
      });

      setListOfRules(response);
    } finally {
      untrackLoading("getRules");
    }
  };

  const init = async () => {
    try {
      trackLoading("init");

      const response = await getOrganizationUserInvitation(invitationCode);

      setInvitation(response.invitation);
      setUserExists(response.userExists);

      formikbag.setFieldValue("userExists", response.userExists);

      if (!response.userExists) {
        getRules();
      }
    } catch (response) {
      if (response.status === 404) {
        setIsLinkExpired(true);
      } else {
        showErrorAlert(response.data);
        setHasError(true);
      }
    } finally {
      untrackLoading("init");
    }
  };

  const { handleSubmit } = formikbag;

  return (
    <>
      <div className={styles.header}>
        <Text gutterBottom textAlign="center" variant="h1" weight="300">
          {t("signupFromInvitation.welcome")}
        </Text>
        {userExists && (
          <Text textAlign="center" gutterBottom>
            {t("signupFromInvitation.getStartedPassword")}
          </Text>
        )}
        {!userExists && (
          <Text gutterBottom>{t("signupFromInvitation.getStartedNumber")}</Text>
        )}
      </div>

      {!userExists && (
        <PhoneField
          countries={countries}
          countryCodeDefault={formikbag.values.countryCode}
          gutterBottom
          label={t("signupFromInvitation.phoneNumber")}
        />
      )}

      {userExists && (
        <FormikField
          label={t("signupFromInvitation.email")}
          as={FIELD_TYPE.INPUT}
          value={invitation?.email}
          name="email"
          gutterBottom
          disabled
        />
      )}

      <FormikField
        label={t("signupFromInvitation.password")}
        name="password"
        as={FIELD_TYPE.INPUT}
        type="password"
        gutterBottom
      />

      {!userExists && (
        <>
          <PasswordRulesIndicator
            password={formikbag.values.password}
            rules={listOfRules}
          />
          <FormikField
            label={t("signupFromInvitation.confirmPassword")}
            name="confirmPassword"
            as={FIELD_TYPE.INPUT}
            type="password"
            gutterBottom
          />
        </>
      )}

      <div className={styles.footer}>
        <Button
          onMouseDown={() => (window.location = "/")}
          type="button"
          textColor="white"
          appearance="secondary"
          fullWidth
          justifyContent="center"
          uppercase
        >
          {t("commonButtons.cancel")}
        </Button>
        <Button
          onMouseDown={handleSubmit}
          type="button"
          fullWidth
          justifyContent="center"
          uppercase
        >
          {t("commonButtons.continue")}
        </Button>
      </div>
    </>
  );
};

export default Form;
