"use strict";

import React, { useEffect, useState } from "react";
import { Button, LoadingIndicator, Modal } from "@citifyd/style";
import { useTranslator } from "../../react/hooks";
import { useFormik } from "formik";
import * as yup from "yup";

import FormikProvider from "../../../shared/react/components/FormikProvider";
import FormikField, {
  FIELD_TYPE,
} from "../../../shared/react/components/FormikField";

import { updateIntercom } from "./utils";
import { getMyOrganization, updateMyOrganization } from "../../api";
import { showErrorAlert } from "../../services/helper";
import { useAuthentication } from "../../react/contexts/authentication";

const EditOrganizationModal = ({ open, onClose }) => {
  const t = useTranslator();
  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>{t("modalEditOrganization.edit")}</Modal.Title>
      <Form onClose={onClose} />
    </Modal>
  );
};

const Form = ({ onClose }) => {
  const t = useTranslator();

  const { user: loggedUser } = useAuthentication();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const formikbag = useFormik({
    initialValues: {
      name: "",
      administratorName: "",
      email: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      administratorName: yup.string().required(),
      email: yup.string().email().required(),
    }),
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        administratorName: values.administratorName,
        email: values.email,
      };
      try {
        setIsSubmitting(true);
        const response = await updateMyOrganization(data);

        updateIntercom({ loggedUser, organization: response });
        onClose({ saved: true });
      } catch (response) {
        showErrorAlert(response.data);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const loadOrganization = async () => {
    const organizationData = await getMyOrganization();

    formikbag.setFieldValue("name", organizationData.name);
    formikbag.setFieldValue(
      "administratorName",
      organizationData.administratorName
    );
    formikbag.setFieldValue("email", organizationData.email);
    setIsLoading(false);
  };

  const init = () => {
    loadOrganization();
  };

  const { handleSubmit } = formikbag;
  return (
    <FormikProvider value={formikbag}>
      <Modal.Content>
        <FormikField
          fullWidth
          as={FIELD_TYPE.INPUT}
          name="name"
          label={t("modalEditOrganization.accountName")}
          gutterBottom
        />
        <FormikField
          fullWidth
          as={FIELD_TYPE.INPUT}
          name="administratorName"
          label={t("modalEditOrganization.admin")}
          gutterBottom
        />
        <FormikField
          fullWidth
          as={FIELD_TYPE.INPUT}
          name="email"
          label={t("modalEditOrganization.email")}
          gutterBottom
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onMouseDown={onClose}
          type="button"
          textColor="white"
          appearance="secondary"
          fullWidth
          justifyContent="center"
          uppercase
        >
          {t("commonButtons.cancel")}
        </Button>
        <Button
          onMouseDown={handleSubmit}
          fullWidth
          type="button"
          justifyContent="center"
          uppercase
        >
          {t("commonButtons.save")}
        </Button>
      </Modal.Actions>
      {(isLoading || isSubmitting) && (
        <LoadingIndicator isLoading={isLoading} withOverlay />
      )}
    </FormikProvider>
  );
};

export default EditOrganizationModal;
