import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import CurrencyFormatter from "@citifyd/currency-formatter";
import {
  getTranslator,
  getCurrentLanguage,
} from "../../../../shared/services/languages";

export const tableColumns = [
  {
    key: "eventDate",
    value: "",
    width: "20%",
  },
  {
    key: "eventName",
    value: "",
    width: "45%",
  },
  {
    key: "lot",
    value: "",
    width: "25%",
  },
  {
    key: "amount",
    value: "",
    width: "10%",
  },
];

export const getRejectedTransactions = (rejectedTransactions) => {
  const t = getTranslator();
  const language = getCurrentLanguage();

  return rejectedTransactions?.map((item) => ({
    eventDate: item.event?.start ? (
      t("defaultFormats.datetimeWithTimezone", {
        date: moment.tz(item.event?.start, item.event?.timezoneName),
      })
    ) : (
      <>&mdash;</>
    ),
    eventName: item.event?.name,
    lot: item.event?.selectedLot?.name,
    amount: CurrencyFormatter.format(
      item.event?.selectedLot?.rate?.value + item.event?.selectedLot?.rate?.fee,
      {
        currency: item.event?.selectedLot?.country?.currency,
        language,
      }
    ),
  }));
};

export const getSuccessfulTransactions = (successfulTransactions) => {
  const t = getTranslator();
  const language = getCurrentLanguage();

  return successfulTransactions?.map((item) => ({
    eventDate: item.event?.start ? (
      t("defaultFormats.datetimeWithTimezone", {
        date: moment.tz(item.event?.start, item.event?.timezoneName),
      })
    ) : (
      <>&mdash;</>
    ),
    eventName: item.event?.name,
    lot: item.event?.selectedLot?.name,
    amount: CurrencyFormatter.format(
      item.event?.selectedLot?.rate?.value + item.event?.selectedLot?.rate?.fee,
      {
        currency: item.event?.selectedLot?.country?.currency,
        language,
      }
    ),
  }));
};

export const find = (events, bundles, purchaseInfo) => {
  if (purchaseInfo.type === "event") {
    return _.find(events, { id: purchaseInfo.options.eventId });
  }

  if (purchaseInfo.type === "bundle") {
    const bundle = _.find(bundles, { id: purchaseInfo.options.id });
    const bundleEvent = bundle?.bundles[0];

    bundle.start = null;
    bundle.selectedLot = {
      name: bundleEvent?.lot?.name,
      country: bundleEvent?.lot?.country,
      rate: bundleEvent?.price,
    };
    return bundle;
  }
};
