"use strict";

import React, { useEffect, useState } from "react";
import { Button, LoadingIndicator, Text } from "@citifyd/style";
import BoxContent from "../../shared/react/components/BoxContent";
import { useService, useTranslator } from "../../shared/react/hooks";
import Form from "./Form/Form.jsx";
import styles from "./Register.module.scss";
import PhoneVerification from "../../shared/react/components/PhoneVerification";
import { updateUser } from "../../shared/api";
import { showErrorAlert } from "../../shared/services/helper";
import { logoutIntercom } from "../../shared/services/intercom";
import { getTermsAndConditionsUrl } from "../../shared/services/settings";
import { useAuthentication } from "../../shared/react/contexts/authentication";

const Register = () => {
  const t = useTranslator();
  const $state = useService("$state");
  const { user: loggedUser, logout } = useAuthentication();
  const [step, setStep] = useState("welcome");
  const [user, setUser] = useState();

  const leave = () => {
    if (!window.confirm(t("register.leaveConfirmation"))) {
      return;
    }

    logout();
    logoutIntercom();
    window.location = "/";
  };

  useEffect(() => {
    if (loggedUser) {
      $state.go("dashboard", {}, { location: "replace" });
    }
  }, []);

  return (
    <>
      {step === "welcome" && <GetStarted setStep={setStep} />}
      {step === "form" && (
        <Form setStep={setStep} setUser={setUser} leave={leave} />
      )}
      {step === "terms" && (
        <AcceptedTerms user={user} setStep={setStep} leave={leave} />
      )}
      {step === "phoneVerification" && (
        <RegisterPhone user={user} leave={leave} />
      )}
    </>
  );
};

const GetStarted = ({ setStep }) => {
  const t = useTranslator();

  return (
    <BoxContent className={styles.container}>
      <BoxContent.Content>
        <Text textAlign="center" variant="h1" weight="300" gutterBottom>
          {t("register.welcome")}
        </Text>
        <Text textAlign="center" variant="h2" weight="300" gutterBottom>
          {t("register.getStarted")}
        </Text>
        <div className={styles.footer}>
          <Button
            extraPadding
            justifyContent="center"
            onClick={() => setStep("form")}
          >
            {t("register.letsGo")}
          </Button>
        </div>
      </BoxContent.Content>
    </BoxContent>
  );
};

const AcceptedTerms = ({ user, setStep, leave }) => {
  const t = useTranslator();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const termsAndConditionsUrl = getTermsAndConditionsUrl();

  const acceptTerms = async () => {
    try {
      setIsSubmitting(true);
      const response = await updateUser(user.id, {
        acceptedTerms: true,
      });

      if (response.data.user.acceptedTerms) {
        setStep("phoneVerification");
      }
    } catch (err) {
      showErrorAlert(err.data);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BoxContent className={styles.iframeContainer}>
      <BoxContent.Content className={styles.iframeContent}>
        <Text
          appearance="white"
          gutterBottom
          textAlign="center"
          variant="h1"
          weight="300"
        >
          {t("register.terms")}
        </Text>
        <iframe
          className={styles.iframe}
          width="100%"
          height="400"
          src={termsAndConditionsUrl}
        ></iframe>
        <div className={styles.footer}>
          <Button
            type="button"
            textColor="white"
            appearance="secondary"
            fullWidth
            justifyContent="center"
            uppercase
            onMouseDown={() => leave()}
          >
            {t("register.decline")}
          </Button>
          <Button
            type="button"
            fullWidth
            justifyContent="center"
            uppercase
            onMouseDown={() => acceptTerms()}
          >
            {t("register.accept")}
          </Button>
        </div>
        {isSubmitting && (
          <LoadingIndicator isLoading={isSubmitting} withOverlay />
        )}
      </BoxContent.Content>
    </BoxContent>
  );
};

const RegisterPhone = ({ user, leave }) => {
  const $state = useService("$state");

  const phoneNumberVerified = () => {
    $state.go("dashboard", {}, { location: "replace" });
  };

  return (
    <BoxContent className={styles.container}>
      <BoxContent.Content>
        <PhoneVerification
          user={user}
          onVerify={phoneNumberVerified}
          onCancel={leave}
        />
      </BoxContent.Content>
    </BoxContent>
  );
};

export default Register;
