"use strict";

import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Icon, Grid, Text } from "@citifyd/style";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";

import BoxContent from "../../shared/react/components/BoxContent";
import PageHeader from "../../shared/react/components/PageHeader";
import RouterLink from "../../shared/react/components/RouterLink";
import StickyFooter from "../../shared/react/components/StickyFooter";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import { useService, useTranslator } from "../../shared/react/hooks";
import useLoadTracking from "../../shared/react/hooks/useLoadTracking";
import useVenues from "../../shared/react/hooks/useVenues";
import ApolloClient from "../../shared/services/apolloClient";

import { useAddressSchema } from "./components/useAddressSchema";
import VenueForm from "./components/VenueForm";

import styles from "./AddVenue.module.scss";

import ADMIN_CREATE_VENUE from "../../graphql/venues/mutations/AdminCreateVenue";
import ADMIN_UPDATE_VENUE from "../../graphql/venues/mutations/AdminUpdateVenue";
import ADMIN_VENUE from "../../graphql/venues/queries/AdminVenue";

import { getAddressValidation } from "../add-property/components/Basics/utils"; // verify path
import { getInitialValues } from "./getInitialValues";

const apolloClient = ApolloClient();

const Page = () => {
  const t = useTranslator();
  const { user } = useAuthentication();
  const $state = useService("$state");
  const $stateParams = useService("$stateParams");
  const { canManageAllVenues } = useVenues();

  const [hasError, setHasError] = useState(false);
  const [countryCode, setCountryCode] = useState("us");

  const mode = $stateParams.venueId ? "edit" : "add";
  const isEditMode = mode === "edit";

  const [createVenue, { loading: isCreatingVenue }] = useMutation(
    ADMIN_CREATE_VENUE,
    {
      client: apolloClient,
      onCompleted: () => $state.go("venues"),
    }
  );

  const [updateVenue, { loading: isUpdatingVenue }] = useMutation(
    ADMIN_UPDATE_VENUE,
    {
      client: apolloClient,
      onCompleted: () => $state.go("venues"),
    }
  );

  const isSaving = isCreatingVenue || isUpdatingVenue;

  const onSubmit = async (values) => {
    if (isEditMode) {
      updateVenue({
        variables: {
          id: Number($stateParams.venueId),
          countryCode: values.countryCode,
          latitude: values.coordinates.latitude,
          longitude: values.coordinates.longitude,
          name: values.name,
          OrganizationId: user.organizationId,
          venueAddress: values.venueAddress,
        },
      });

      return;
    }

    createVenue({
      variables: {
        countryCode: values.countryCode,
        latitude: values.coordinates.latitude,
        longitude: values.coordinates.longitude,
        name: values.name,
        OrganizationId: user.organizationId,
        venueAddress: values.venueAddress,
      },
    });
  };

  const { trackLoading, untrackLoading } = useLoadTracking({
    countryList: {
      message: t("addProperty.loadingMessages.loadingSettings"),
    },
    addressSchema: {
      message: t("addProperty.loadingMessages.loadingSettings"),
    },
  });

  const { addressSchema, availableCountries, loadAddressSchema } =
    useAddressSchema({
      alwaysShowCountryDropdown: true,
      setHasError,
      trackLoading,
      untrackLoading,
    });

  const formikbag = useFormik({
    initialValues: getInitialValues({}, addressSchema),
    onSubmit,
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      ...(addressSchema
        ? {
            venueAddress: yup
              .object()
              .shape(getAddressValidation(addressSchema, yup)),
          }
        : null),
    }),
  });

  const { dirty, handleSubmit, resetForm, values: test } = formikbag;

  const [
    fetchVenueToEdit,
    { data: venueToEdit, loading: isLoadingVenueToEdit },
  ] = useLazyQuery(ADMIN_VENUE, {
    client: apolloClient,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      resetForm({
        values: {
          name: data.admin_venue.name,
          coordinates: {
            latitude: data.admin_venue.latitude,
            longitude: data.admin_venue.longitude,
          },
          countryCode: data.admin_venue.countryCode,
          venueAddress: data.admin_venue.venueAddress,
        },
      });

      setCountryCode(data.admin_venue.countryCode);
    },
  });

  useEffect(() => {
    if (isEditMode && canManageAllVenues) {
      fetchVenueToEdit({ variables: { id: Number($stateParams.venueId) } });
    }
  }, []);

  if (!canManageAllVenues) return null;

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          weight="300"
          title={t(isEditMode ? "editVenue.pageTitle" : "addVenue.pageTitle")}
        />
      </PageHeader>

      <FormikProvider value={formikbag}>
        <BoxContent>
          <BoxContent.Header title={t("addVenue.formTitle")} />

          <BoxContent.Content>
            <VenueForm
              addressSchema={addressSchema}
              availableCountries={availableCountries}
              countryCode={countryCode}
              loadAddressSchema={loadAddressSchema}
              setCountryCode={setCountryCode}
            />
          </BoxContent.Content>

          <StickyFooter isBlocked={false}>
            <RouterLink state="venues" className={styles.backLink}>
              <Icon size="subtitle" icon="chevron-left" appearance="white" />
              <Text variant="subtitle" appearance="white">
                {t("commonButtons.goBackWithoutSaving")}
              </Text>
            </RouterLink>
            <Button
              shadow
              extraPadding
              uppercase
              onMouseDown={handleSubmit}
              disabled={!dirty || hasError || isSaving}
            >
              {isSaving ? t("commonButtons.saving") : t("commonButtons.save")}
            </Button>
          </StickyFooter>
        </BoxContent>
      </FormikProvider>
    </Grid>
  );
};

export default Page;
