import { ApolloError } from "@apollo/client";
import { getTranslator } from "./languages";

export type GraphqlError =
  | { type: "validation"; message: string; param?: string }
  | { type: "unexpected"; message: string };

export function getGraphqlError(err: Error): GraphqlError {
  if (err instanceof ApolloError) {
    const graphqlError = err.graphQLErrors[0];

    if (graphqlError && graphqlError.extensions.type === "InvalidRequest") {
      return { type: "validation", message: graphqlError.message };
    }

    if (
      graphqlError &&
      graphqlError.extensions.code === "ModelValidationError"
    ) {
      const validationErrors = graphqlError.extensions.list as {
        message: string;
        param: string;
      }[];

      if (validationErrors[0]) {
        return {
          type: "validation",
          message: validationErrors[0].message,
          param: validationErrors[0].param,
        };
      }
    }
  }

  const t = getTranslator();

  return {
    type: "unexpected",
    message: t("commonErrors.connectionProblem"),
  };
}
