import { getTranslator } from "../../../../shared/services/languages";

const confirmLotSelections = ({ bundles, events }) => {
  const t = getTranslator();

  const bundlesSelected = _.filter(bundles, "selectedBundleId").length;
  const eventLotsSelected = _.filter(events, "selectedLot").length;

  if (
    bundles.length !== bundlesSelected ||
    events.length !== eventLotsSelected
  ) {
    alert(t("inviteParkers.lotSelection.errors.lotsMissing"));
    return false;
  }

  return true;
};

export default confirmLotSelections;
