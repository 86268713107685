"use strict";

import { getTranslator } from "../../../../shared/services/languages";

export const getTableColumns = () => {
  const t = getTranslator();

  return [
    {
      value: t("addProperty.labels.name"),
      uppercase: false,
      width: "17%",
      key: "name",
    },
    {
      value: t("addProperty.labels.beaconId"),
      uppercase: false,
      width: "20%",
      key: "beaconUids",
    },
    {
      value: t("addProperty.labels.type"),
      uppercase: false,
      width: "13%",
      key: "type",
    },
    {
      value: "",
      width: "2%",
      key: "empty",
    },
    {
      value: t("addProperty.labels.armedGate"),
      uppercase: false,
      width: "6%",
      key: "gate",
    },
    {
      value: t("addProperty.labels.reportsStatus"),
      uppercase: false,
      width: "7%",
      key: "reportsStatus",
    },
    {
      value: t("addProperty.labels.hasBarrier"),
      uppercase: false,
      width: "5%",
      key: "hasBarrier",
    },
    {
      value: t("addProperty.labels.autoActi"),
      uppercase: false,
      width: "7%",
      key: "automaticActivation",
    },
    {
      value: t("addProperty.labels.hasAttendant"),
      uppercase: false,
      width: "7%",
      key: "hasAttendant",
    },
    {
      value: "",
      uppercase: false,
      width: "1%",
      key: "remove",
    },
  ];
};
