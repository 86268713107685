import React from "react";
import { Button, Icon } from "@citifyd/style";
import styles from "./CursorPagination.module.scss";
import { useTranslator } from "../../../react/hooks";

interface PaginationProps {
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
  loading: boolean;
  data?: any[];
  nextPage: (e: React.MouseEvent) => any;
  previousPage: (e: React.MouseEvent) => any;
}

export const CursorPagination = ({
  hasPreviousPage,
  hasNextPage,
  loading,
  data,
  nextPage,
  previousPage,
}: PaginationProps) => {
  const t = useTranslator();

  return (
    <div className={styles.buttons}>
      <Button
        justifyContent="center"
        fullWidth
        size="small"
        uppercase
        iconLeft="chevron-left"
        disabled={!hasPreviousPage || loading || !data}
        onClick={previousPage}
      >
        {t("commonButtons.previous")}
      </Button>

      <Button
        justifyContent="center"
        fullWidth
        size="small"
        uppercase
        iconRight="chevron-right"
        disabled={!hasNextPage || loading || !data}
        onClick={nextPage}
      >
        {t("commonButtons.next")}
      </Button>
    </div>
  );
};
