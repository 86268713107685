/* global google:false */

"use strict";

import AddressFormatter from "@citifyd/address-formatter";

import { isNumber } from "lodash";

export const hasCoordinates = (obj) => {
  return isNumber(obj.latitude) && isNumber(obj.longitude);
};

// Gets the coordinates from an address
// It returns a promise that resolves with an object containing `latitude` and `longitude` keys
// The `address` argument can be a string or an object.
// If it's an object, you can specify the following keys: `line1`, `line2`, `postalCode`, `city`
// and `state`
export const getCoordinates = (address, countryCode = "us") => {
  const geocoder = new google.maps.Geocoder();

  if (typeof address === "object") {
    address = AddressFormatter.format(address, countryCode, "full_inline").join(
      "\n"
    );
    address += ", " + countryCode.toUpperCase();
  }
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        let loc = results[0].geometry.location;
        resolve({ latitude: loc.lat(), longitude: loc.lng() });
      } else {
        reject(status);
      }
    });
  });
};

// Calculates the distance between two lat/lng coordinates
// Arguments can be numbers or objects.
//
// Numbers form (lat1, lon1, lat2, lon2):
// getDistanceBetweenCoordinates(45.531860, 122.666819, 45.530627, -122.680121)
//
// Objects form (each one should contain `latitude` and `longitude` properties):
// getDistanceBetweenCoordinates(
//   { latitude: 45.531860, longitude: 122.666819 },
//   { latitude: 45.530627, longitude: -122.680121 }
// )
//
// Based on:
// http://stackoverflow.com/questions/639695/how-to-convert-latitude-or-longitude-to-meters
export const getDistanceBetweenCoordinates = (arg1, arg2, arg3, arg4) => {
  let lat1, lon1, lat2, lon2;

  if (hasCoordinates(arg1)) {
    lat1 = arg1.latitude;
    lon1 = arg1.longitude;
    lat2 = arg2.latitude;
    lon2 = arg2.longitude;
  } else {
    lat1 = arg1;
    lon2 = arg2;
    lat1 = arg3;
    lon2 = arg4;
  }

  let R = 6378.137;
  let dLat = ((lat2 - lat1) * Math.PI) / 180;
  let dLon = ((lon2 - lon1) * Math.PI) / 180;
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d * 1000;
};
