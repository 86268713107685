"use strict";

import React, { useMemo, useState } from "react";
import Table from "../../../../shared/react/components/Table";
import { useTranslator } from "../../../../shared/react/hooks";
import { Label } from "@citifyd/style";
import Header from "../Header.jsx";
import Footer from "../Footer/Footer.jsx";
import useBundlesAndEvents from "./tableData";
import FormikProvider from "../../../../shared/react/components/FormikProvider";
import useConfirmation from "./useConfirmation";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import { formatPhoneNumber } from "../../../../shared/utils/phone-number-formatting";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import styles from "./Confirmation.module.scss";

const Confirmation = ({ steps, setSteps, passGiver }) => {
  const t = useTranslator();
  const [isSubmitting, setIsSubmitting] = useState();

  const accountInformationData = useMemo(() => {
    return [
      ...steps.data.userInfo.users.map((item) => ({
        ...item,
        phoneNumber: formatPhoneNumber(item.phoneNumber, item.phoneCountryCode),
      })),
    ];
  }, [steps.data.userInfo]);

  const bundlesAndEvents = useBundlesAndEvents({ steps, passGiver });

  const accountInformationColumns = [
    { key: "name", value: t("inviteParkers.accountInfo.table.name") },
    { key: "email", value: t("inviteParkers.accountInfo.table.email") },
    {
      key: "phoneNumber",
      value: t("inviteParkers.accountInfo.table.phoneNumber"),
    },
  ];

  const parkingPassesColumns = [
    { key: "name", value: t("inviteParkers.confirmation.table.event") },
    { key: "lotName", value: t("inviteParkers.confirmation.table.lot") },
    {
      key: "quantity",
      value: t("inviteParkers.confirmation.table.quantity"),
    },
    {
      key: "price",
      value: t("inviteParkers.confirmation.table.price"),
    },
  ];

  const formikbag = useConfirmation({ steps, setSteps, setIsSubmitting });

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Header
        text={t("inviteParkers.confirmation.title")}
        onClick={() =>
          setSteps({
            ...steps,
            step: steps.data.lotSelection ? "lot_selection" : "user_info",
          })
        }
      />

      {!isSubmitting && (
        <>
          <Label gutterBottom>
            {t("inviteParkers.confirmation.accountInformation")}
          </Label>
          <Table
            data={accountInformationData}
            columns={accountInformationColumns}
            responsive
            striped
            gutterBottom
          />
          <br />

          {bundlesAndEvents.length > 0 && (
            <>
              <Label gutterBottom>
                {t("inviteParkers.confirmation.parkingPasses")}
              </Label>
              <Table
                data={bundlesAndEvents}
                columns={parkingPassesColumns}
                responsive
                striped
                gutterBottom
              />
              <br />
            </>
          )}

          <FormikField
            label={
              bundlesAndEvents.length > 0
                ? t("inviteParkers.confirmation.reason.passPurchase")
                : t("inviteParkers.confirmation.reason.accountCreation")
            }
            as={FIELD_TYPE.TEXTAREA}
            name="reason"
            rows={4}
            gutterBottom
          />
          <div className={styles.checkboxes}>
            <FormikField
              gutterBottom
              label={t("inviteParkers.confirmation.reason.requestedPhone")}
              as={FIELD_TYPE.CHECKBOX}
              name="requestedPhone"
            />
            <FormikField
              gutterBottom
              label={t("inviteParkers.confirmation.reason.requestedEmail")}
              as={FIELD_TYPE.CHECKBOX}
              name="requestedEmail"
            />
          </div>
          <Footer handleSubmit={handleSubmit} />
        </>
      )}
      {isSubmitting && (
        <LoadingManager
          isLoading={isSubmitting}
          loadingMessage={t("inviteParkers.confirmation.creatingAccounts")}
        />
      )}
    </FormikProvider>
  );
};
export default Confirmation;
