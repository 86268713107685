"use strict";

import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useRef } from "react";
import { Icon, Label, Grid } from "@citifyd/style";
import FormikProvider from "../../../../shared/react/components/FormikProvider";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import styles from "./MonthlyParking.module.scss";
import currencyFormatter from "@citifyd/currency-formatter";
import { getCurrentLanguage } from "../../../../shared/services/languages";
import { generateTimesArray } from "../../../../shared/services/helper";
import { useTranslator } from "../../../../shared/react/hooks";

const PlanForm = ({ currency, index, plan, onSave, onCancel, lot }) => {
  const t = useTranslator();
  const inputNameRef = useRef();
  const language = getCurrentLanguage();

  useEffect(() => {
    setTimeout(() => {
      inputNameRef.current?.focus();
    }, 100);
  }, []);

  const monthlyRatePlaceholder = useMemo(
    () =>
      currency &&
      language &&
      currencyFormatter.format(10000, {
        currency,
        language,
      }),
    [currency, language]
  );

  const timeOptions = useMemo(
    () =>
      generateTimesArray().map((time) => ({
        value: time.time,
        label: time.label,
      })),
    []
  );

  const billingCycleField = `billingCycle_${index}`;

  const cancellationNoticePeriodField = `cancellationNoticePeriod_${index}`;

  const formikbag = useFormik({
    initialValues: {
      name: plan.name,
      startTime: plan.startTime,
      endTime: plan.endTime,
      daysOfWeek: plan.daysOfWeek,
      price: plan.price,
      [billingCycleField]: plan.billingCycle?.toString() || "1",
      [cancellationNoticePeriodField]:
        plan.cancellationNoticePeriod?.toString() || "30",
      spaces: plan.spaces,
    },

    validationSchema: yup.object().shape({
      name: yup.string().required(),
      price: yup.string().required(),
      spaces: yup
        .number()
        .required()
        .test({
          test: (defaultNumber) => {
            defaultNumber;
            return defaultNumber <= lot.maxSpots;
          },
          message: t(
            "addProperty.monthly.errorMessages.amountOfSpacesAllocated",
            { amount: lot.maxSpots }
          ),
        }),
      [billingCycleField]: yup.string().required(),
      [cancellationNoticePeriodField]: yup.string().required(),
      startTime: yup.string().required(),
      endTime: yup
        .string()
        .required()
        .when(["startTime"], (startTime) => {
          return yup.string().test({
            test: (endTime) => {
              const start = moment(startTime, "HH:mm");
              const end = moment(endTime, "HH:mm");

              return start.isBefore(end) || endTime === "00:00";
            },
            message: t(
              "addProperty.monthly.errorMessages.endTimeMustBeAfterStartTime"
            ),
          });
        }),
    }),

    onSubmit: (values) => {
      values = { ...values };
      values.billingCycle = values[billingCycleField];
      delete values[billingCycleField];

      values.cancellationNoticePeriod = values[cancellationNoticePeriodField];
      delete values[cancellationNoticePeriodField];

      onSave({ values, index });
    },
  });

  const { handleSubmit, values } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Grid.Row>
        <Grid.Col sm={3}>
          <div>
            <FormikField
              as={FIELD_TYPE.INPUT}
              label={t("addProperty.monthlyName")}
              name="name"
              ref={inputNameRef}
            />
          </div>
        </Grid.Col>
        <Grid.Col sm={4} className={styles.rateAndBillingCycle}>
          <div className={styles.rate}>
            <FormikField
              name="price"
              as={FIELD_TYPE.CURRENCY}
              currency={currency}
              placeholder={monthlyRatePlaceholder}
              fullWidth={true}
              label={t("addProperty.rateBilling")}
            />
          </div>
          <div className={styles.billingCycle}>
            <div>
              <FormikField
                as={FIELD_TYPE.RADIO}
                name={billingCycleField}
                label={t("addProperty.billFirst")}
                value="1"
                checked={values[billingCycleField] === "1"}
                small
              />
              <FormikField
                as={FIELD_TYPE.RADIO}
                name={billingCycleField}
                label={t("addProperty.billFifteen")}
                checked={values[billingCycleField] === "15"}
                value="15"
                small
              />
            </div>
          </div>
        </Grid.Col>
        <Grid.Col sm={4}>
          <FormikField
            as={FIELD_TYPE.INPUT}
            fullWidth={false}
            type="number"
            placeholder={lot.maxSpots}
            label={t("addProperty.monthlyAvailability")}
            inlineLabel={t("addProperty.passes")}
            name="spaces"
          />
        </Grid.Col>
        <Grid.Col sm={1} className={styles.actionButtons}>
          <Icon
            icon="check"
            pull="right"
            size="h2"
            className={styles.actionButton}
            onClick={handleSubmit}
          />
          <Icon
            icon="times"
            pull="right"
            size="h2"
            className={styles.actionButton}
            onClick={() => onCancel({ index })}
          />
        </Grid.Col>
      </Grid.Row>
      <br />
      <Grid.Row>
        <Grid.Col sm={3}>
          <FormikField
            label={t("addProperty.daysOfWeek")}
            as={FIELD_TYPE.DAYS_OF_WEEK}
            name="daysOfWeek"
            fullWidth
          />
        </Grid.Col>

        <Grid.Col sm={2}>
          <div>
            <FormikField
              as={FIELD_TYPE.SELECT}
              options={timeOptions}
              label={t("addProperty.startTime")}
              name="startTime"
            />
          </div>
        </Grid.Col>
        <Grid.Col sm={2}>
          <div>
            <FormikField
              as={FIELD_TYPE.SELECT}
              options={timeOptions}
              requireTouchForError={false}
              label={t("addProperty.endTime")}
              name="endTime"
            />
          </div>
        </Grid.Col>

        <Grid.Col sm={3}>
          <Label>{t("addProperty.cancellationNotice", { cancel: 30 })}</Label>
          <div>
            <FormikField
              as={FIELD_TYPE.RADIO}
              name={cancellationNoticePeriodField}
              label={t("addProperty.requireNotice")}
              value="30"
              checked={values[cancellationNoticePeriodField] === "30"}
              small
            />
            <FormikField
              as={FIELD_TYPE.RADIO}
              name={cancellationNoticePeriodField}
              label={t("addProperty.noNotice")}
              checked={values[cancellationNoticePeriodField] === "0"}
              value="0"
              small
            />
          </div>
        </Grid.Col>
      </Grid.Row>
    </FormikProvider>
  );
};

export default PlanForm;
