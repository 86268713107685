import gql from "graphql-tag";

export default gql`
  query AdminGetUserPermitsPasses($userId: Int) {
    admin_listPermitPasses(userId: $userId)
      @connection(key: "adminListPermitPasses") {
      total
      edges {
        node {
          __typename
          id
          user {
            id
            name
          }
          lot {
            id
            name
            timezoneName
            country {
              currency
            }
          }
          startTime
          endTime
          calculatedPrice {
            total
          }
        }
      }
    }
  }
`;
