import * as yup from "yup";

export const makeValidationSchema = () => {
  return yup.object().shape({
    countryCode: yup.string(),
    phoneNumber: yup.string(),
    email: yup.string().email(),
    reason: yup.string().trim().required(),
    requestedByEmail: yup.boolean(),
    requestedByPhone: yup.boolean(),
  });
};

export const getTransfer = (values) => {
  const {
    email,
    phoneNumber,
    countryCode,
    reason,
    requestedByEmail,
    requestedByPhone,
  } = values;

  const transfer = {
    email: email?.trim(),
    phoneCountryCode: countryCode,
    phoneNumber: phoneNumber?.replace(/\s/g, ""),
    extra: {
      reason: reason.trim(),
      requestedByPhone,
      requestedByEmail,
    },
  };

  return transfer;
};
