"use strict";
import React, { useState } from "react";
import { Grid, Select, Text } from "@citifyd/style";

import generateCsvClicked from "../functions/generateCsvClicked";
import CSVDownload from "../../../../shared/react/components/CSVDownload";
import loadPayoutItems from "../../functions/loadPayoutItems";

import styles from "./HeaderActions.module.scss";
import { useTranslator } from "../../../../shared/react/hooks";
import { getService } from "../../../../shared/react/utils";
import { useModal } from "../../../../shared/react/contexts/modal";

const HeaderActions = ({
  lot,
  lotId,
  setLotId,
  setPayoutItems,
  payoutId,
  payout,
  setIsLoading,
}) => {
  const t = useTranslator();
  const { openModal } = useModal();

  const lotChanged = async (lotId) => {
    updateURL(lotId);
    setLotId(lotId);
    setIsLoading(true);
    setPayoutItems(
      await loadPayoutItems({
        payoutId,
        lotId,
      })
    );
    setIsLoading(false);
  };

  const updateURL = (lotId) => {
    const $state = getService("$state");
    const $stateParams = getService("$stateParams");
    $state.go(
      $state.current.name,
      { ...$stateParams, lot: lotId },
      {
        inherit: false,
        location: "replace",
        notify: false,
      }
    );
  };

  return (
    <Grid className={styles.grid}>
      <Grid.Row>
        <Grid.Col xs={12} sm={4}>
          <Text variant="h1" weight="300" gutterBottom>
            {t("payout.payoutItems")}
          </Text>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={4}>
          <FilterByProperty
            lotId={lotId}
            lots={payout.lots}
            onChange={lotChanged}
          />
        </Grid.Col>
        <Grid.Col xs={12} sm={8} align="end" className={styles.csvCol}>
          <CSVDownload
            className={styles.csv}
            onClick={() =>
              generateCsvClicked({
                lotId,
                payoutId,
                payout,
                lot,
                openModal,
              })
            }
          />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

const FilterByProperty = ({ lots, onChange, lotId }) => {
  const t = useTranslator();

  const options = [
    {
      label: t("payout.lotFilter.anyLot"),
      value: "",
    },
    ...lots.map((lot) => ({ label: lot.name, value: lot.id })),
  ];
  return (
    <Select
      label={t("payout.lotFilter.label")}
      labelSize="small"
      gutterBottom
      fullWidth
      options={options}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={lotId}
    />
  );
};

export default HeaderActions;
