"use strict";

import React, { useEffect, useState } from "react";
import { Select } from "@citifyd/style";
import { omit } from "lodash";
import { useService, useTranslator } from "../../../../../shared/react/hooks";

const PropertySelector = ({ lots, propertyId, setPropertyId, ...rest }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const [options, setOptions] = useState([]);

  const onChange = (value) => {
    setPropertyId(value);
    $state.transitionTo(
      $state.current.name,
      {
        ...omit($state.params, "lot"),
        ...(value && { lot: value }),
      },
      { location: "replace", notify: false, inherit: false }
    );
  };

  useEffect(() => {
    if (options?.length === 0 && lots?.length > 0) {
      const list = lots?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      list.unshift({
        value: "",
        label: t("event.filterProperty.allProperties"),
      });

      setOptions(list);
    }
  }, [lots]);

  return (
    <Select
      id="propertySelector"
      onChange={(e) => onChange(e.target?.value)}
      label={t("event.filterProperty.label")}
      fullWidth
      labelSize="small"
      value={propertyId}
      options={options}
      {...rest}
    />
  );
};

export default PropertySelector;
