"use strict";

import React from "react";
import { Text, Button } from "@citifyd/style";
import * as yup from "yup";
import classNames from "classnames";
import { useFormik } from "formik";

import { useTranslator } from "../../../../shared/react/hooks";
import FormikProvider from "../../../../shared/react/components/FormikProvider";
import useLoadTracking from "../../../../shared/react/hooks/useLoadTracking";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";

import styles from "./TwoFactorAuthentication.module.scss";
import { showAlert, showErrorAlert } from "../../../../shared/services/helper";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const TwoFactorAuthentication = ({ credentials, handleSuccessfulLogin }) => {
  const t = useTranslator();
  const { tryAuthenticate } = useAuthentication();

  const { isLoading, trackLoading, untrackLoading } = useLoadTracking();

  const onSubmit = (values) => {
    trackLoading("authenticating");

    const data = {
      ...credentials,
      twoFactorAuthenticationCode: values.code,
      twoFactorAuthenticationTrustPermanently: values.remember,
    };

    tryAuthenticate(data)
      .then((resp) => {
        if (resp.user) {
          handleSuccessfulLogin();
        }
      })
      .catch((resp) => {
        untrackLoading("authenticating");
        showErrorAlert(resp.data);
      });
  };

  const onResend = (method) => {
    trackLoading("resending");

    const data = {
      ...credentials,
      twoFactorAuthenticationMethod: method,
    };

    tryAuthenticate(data)
      .then((resp) => {
        if (resp.twoFactorAuthentication) {
          showMessageSentAlert(method);
          untrackLoading("resending");
        } else if (resp.user) {
          handleSuccessfulLogin();
        }
      })
      .catch((resp) => {
        untrackLoading("resending");
        showErrorAlert(resp.data);
      });
  };

  const showMessageSentAlert = (method) => {
    let message;

    switch (method) {
      case "sms":
        message = t("login.twoFactor.resent.sms");
        break;

      case "call":
        message = t("login.twoFactor.resent.call");
        break;
    }

    if (message) {
      showAlert(message);
    }
  };

  const onCancel = () => {
    if (!window.confirm(t("login.twoFactor.cancelConfirmation"))) {
      return;
    }

    window.location = "/";
  };

  const formikbag = useFormik({
    initialValues: {
      code: "",
      remember: false,
    },
    onSubmit,
    validationSchema: yup.object().shape({
      code: yup.string().required(),
      remember: yup.boolean(),
    }),
  });

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      {isLoading && (
        <div className={styles.loadingManager}>
          <LoadingManager isLoading={true} />
        </div>
      )}

      <div
        className={classNames(styles.container, { [styles.hide]: isLoading })}
      >
        <Text
          variant="h1"
          weight="300"
          textAlign="center"
          gutterBottom
          dangerouslySetInnerHTML={{
            __html: t("login.twoFactor.title", {
              nonBreakingSpace: "&nbsp;",
              interpolation: { escapeValue: true },
            }),
          }}
        />
        <Text className={styles.subtitle} textAlign="center">
          {t("login.twoFactor.smsSent")}
        </Text>
        <form className={styles.form} onSubmit={handleSubmit}>
          <FormikField
            as={FIELD_TYPE.INPUT}
            label={t("login.autheticationCode")}
            name="code"
            disabled={isLoading}
            gutterBottom
          />
          <FormikField
            as={FIELD_TYPE.CHECKBOX}
            label={t("login.remember")}
            name="remember"
            disabled={isLoading}
            gutterBottom
          />
          <Text appearance="gray_dark" variant="small">
            {t("login.checking")}
          </Text>
          <div className={styles.submitBox}>
            <Button
              uppercase
              fullWidth
              textColor="white"
              appearance="secondary"
              disabled={isLoading}
              justifyContent="center"
              onMouseDown={() => onCancel()}
            >
              {t("commonButtons.cancel")}
            </Button>
            <Button
              uppercase
              fullWidth
              disabled={isLoading}
              justifyContent="center"
              type="submit"
              onMouseDown={() => handleSubmit()}
            >
              {t("commonButtons.continue")}
            </Button>
          </div>

          <Text variant="h5" textAlign="center" gutterBottom weight="700">
            {t("login.notReceiving")}
          </Text>
          <Text
            className={styles.forgotPasswordLink}
            variant="subtitle"
            textAlign="center"
            appearance="tertiary"
            underline
            onClick={() => !isLoading && onResend("sms")}
          >
            {t("login.sendAgain")}
          </Text>
          <Text
            className={styles.forgotPasswordLink}
            variant="subtitle"
            textAlign="center"
            appearance="tertiary"
            underline
            onClick={() => !isLoading && onResend("call")}
          >
            {t("login.call")}
          </Text>
        </form>
      </div>
    </FormikProvider>
  );
};

export default TwoFactorAuthentication;
