import ca from "./ca";
import fi from "./fi";
import jp from "./jp";
import us from "./us";

export default {
  ca,
  fi,
  jp,
  us,
};
