"use strict";

import React, { useRef } from "react";

import { Button, Icon, LoadingIndicator, Grid, Text } from "@citifyd/style";

import { useTranslator } from "../../shared/react/hooks";
import useSettings from "./hooks/useSettings";

import PageHeader from "../../shared/react/components/PageHeader";
import RouterLink from "../../shared/react/components/RouterLink";
import FormikProvider from "../../shared/react/components/FormikProvider";

import GeneralSettings from "./components/GeneralSettings";
import PropertyConfiguration from "./components/PropertyConfiguration/PropertyConfiguration";
import StickyFooter from "../../shared/react/components/StickyFooter";
import {
  PermitPortalProvider,
  usePermitPortalCtx,
} from "./hooks/usePermitPortalCtx";

const AddSettings = () => {
  return (
    <PermitPortalProvider>
      <PageContent />
    </PermitPortalProvider>
  );
};

const PageContent = () => {
  const t = useTranslator();
  const { portalData, isEditing, isLoading } = usePermitPortalCtx();

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          title={
            isEditing
              ? t("permits.permitters.settings.editPortal")
              : t("permits.permitters.settings.addPortal")
          }
          defaultState={
            portalData ? "permitters-portal-settings" : "permitters"
          }
          weight="300"
        />
      </PageHeader>

      {isLoading && <LoadingIndicator />}

      {!isLoading && (!isEditing || portalData) && <Body />}
    </Grid>
  );
};

const Body = () => {
  const t = useTranslator();
  const imageInputRef = useRef<HTMLInputElement>();
  const formikbag = useSettings({ imageInputRef });

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Grid>
        <GeneralSettings imageInputRef={imageInputRef} />
        {formikbag.values.propertyConfiguration?.map((propertyConfig, i) => (
          <PropertyConfiguration
            index={i}
            key={i}
            propertyConfig={propertyConfig}
          />
        ))}
      </Grid>

      <StickyFooter isBlocked={false} justifyContent="space-between">
        <RouterLink state={"permitters"}>
          <Icon size="subtitle" icon="chevron-left" appearance="white" />
          <Text variant="subtitle" appearance="white">
            {t("commonButtons.goBackWithoutSaving")}
          </Text>
        </RouterLink>
        <Button uppercase onClick={() => handleSubmit()} extraPadding>
          {t("permits.form.save")}
        </Button>
      </StickyFooter>
    </FormikProvider>
  );
};

export default AddSettings;
