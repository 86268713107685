"use strict";

import React, { useEffect, useState, useRef } from "react";
import { Select, Text, Grid } from "@citifyd/style";
import BoxContent from "../../../../shared/react/components/BoxContent";
import { useTranslator } from "../../../../shared/react/hooks";
import Table from "../../../../shared/react/components/Table";
import SearchInput from "../../../../shared/react/components/SearchInput";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import Pagination from "../../../../shared/react/components/Pagination";
import { isRequestCancelled } from "../../../../shared/api";
import { loadParkers, getData } from "./utils";
import styles from "./Parkers.module.scss";

const Parkers = ({ properties }) => {
  const t = useTranslator();
  const searchTimer = useRef(null);
  const timer = useRef(null);
  const abortRequest = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReservations, setIsLoadingReservations] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pages, setPages] = useState();
  const [amountOfPages, setAmountOfPages] = useState(1);
  const [reservations, setReservations] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const refreshRate = 3;

  const { columns, data } = getData({ reservations, search });

  const resetState = (currentPage = 1) => {
    setReservations([]);
    setIsLoadingReservations(true);
    setCurrentPage(currentPage);
  };

  const fetchParkers = async ({
    currentPage,
    selectedProperty,
    search,
    shouldResetAll = true,
  }) => {
    if (shouldResetAll) {
      resetState(currentPage);
    }

    if (abortRequest.current) {
      abortRequest.current.abort();
      abortRequest.current = null;
    }

    abortRequest.current = new AbortController();

    const parkers = await loadParkers({
      currentPage,
      selectedProperty,
      search,
      signal: abortRequest.current.signal,
    });

    abortRequest.current = null;

    if (!isRequestCancelled(parkers?.error)) {
      setHasError(parkers?.error);
      setIsLoadingReservations(false);
    }

    setReservations(parkers?.reservations);
    setPages(parkers?.pages);
    setAmountOfPages(parkers?.amountOfPages);
    setIsLoading(false);
    refreshTimeout({ currentPage, selectedProperty, search });

    if (currentPage > parkers?.amountOfPages) {
      setCurrentPage(parkers?.amountOfPages);
      fetchParkers({ currentPage, selectedProperty, search });
    }
  };

  const goToPage = (currentPage) => {
    clearTimeout(timer?.current);
    setCurrentPage(currentPage);
    setReservations([]);
    setIsLoadingReservations(true);
    fetchParkers({ currentPage, selectedProperty, search });
  };

  const onSelectPropertyChange = (e) => {
    const selectedProperty = e.target?.value;
    setSelectedProperty(selectedProperty);
    setReservations([]);
    setCurrentPage(1);
    setIsLoadingReservations(true);
    fetchParkers({ currentPage: 1, selectedProperty, search });
  };

  const onSearchInputChange = (e) => {
    const search = e.target?.value;
    clearTimeout(timer?.current);

    if (searchTimer) {
      clearTimeout(searchTimer.current);
    }

    searchTimer.current = setTimeout(() => {
      setSearch(search);
      fetchParkers({ currentPage, selectedProperty, search });
    }, 500);
  };

  const refreshTimeout = ({ currentPage, selectedProperty, search }) => {
    clearTimeout(timer?.current);
    timer.current = setTimeout(() => {
      fetchParkers({
        currentPage,
        selectedProperty,
        search,
        shouldResetAll: false,
      });
    }, refreshRate * 1000);
  };

  useEffect(() => {
    if (options?.length === 0) {
      const list = properties?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      list.unshift({
        value: "",
        label: t("dashboard.dropdownOptionAllProperties"),
      });
      setOptions(list);
    }
  }, [properties]);

  useEffect(() => {
    fetchParkers({ currentPage, selectedProperty, search });
  }, [selectedProperty]);

  useEffect(() => {
    refreshTimeout({
      currentPage,
      selectedProperty,
      search,
    });

    return () => {
      if (timer) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  return (
    <BoxContent>
      <BoxContent.Header title={t("dashboard.currentParkers")} />
      <BoxContent.Content>
        <Grid.Row justify="space-between" className={styles.filtersRow}>
          <Grid.Col sm={12} md={6} lg={4}>
            <Select
              fullWidth
              name="properties"
              options={options}
              defaultValue=""
              disabled={isLoadingReservations}
              value={selectedProperty || ""}
              onChange={(e) => onSelectPropertyChange(e)}
            />
          </Grid.Col>
          {(reservations?.length > 0 || search) && (
            <Grid.Col sm={12} md={4} lg={3} className={styles.searchInput}>
              <SearchInput
                id="search"
                onChange={(e) => onSearchInputChange(e)}
                fullWidth
              />
            </Grid.Col>
          )}
        </Grid.Row>
        {isLoadingReservations && (
          <LoadingManager isLoading={isLoadingReservations} />
        )}
        {!isLoadingReservations && !search && reservations?.length === 0 && (
          <Text textAlign="center">{t("search.noParkers")}</Text>
        )}
        {!isLoadingReservations && search && reservations?.length === 0 && (
          <Text textAlign="center">{t("search.noResults")}</Text>
        )}

        {data && columns && reservations?.length > 0 && (
          <Table
            spacing
            columns={columns}
            data={data}
            responsive
            headerAppearance="transparent"
          />
        )}
        {!isLoadingReservations && amountOfPages > 1 && (
          <Pagination
            currentPage={currentPage}
            goToPage={goToPage}
            pages={pages}
            amountOfPages={amountOfPages}
          />
        )}
      </BoxContent.Content>
    </BoxContent>
  );
};

export default Parkers;
