import { useMemo } from "react";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";
import { useTranslator } from "../../../../shared/react/hooks";
import Permissions from "../../../../shared/services/permissions";

export const useTableColumns = () => {
  const t = useTranslator();
  const { permissions } = useAuthentication();
  const userMaySeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  return useMemo(() => {
    const columns = [
      userMaySeeUserInformation
        ? {
            value: t("dailyRevenue.username"),
            key: "name",
          }
        : null,
      {
        value: t("dailyRevenue.plate"),
        key: "license",
      },
      {
        value: t("dailyRevenue.property"),
        key: "lotName",
      },
      {
        value: t("dailyRevenue.start"),
        key: "startTime",
      },
      {
        value: t("dailyRevenue.end"),
        key: "endTime",
      },
      {
        value: t("dailyRevenue.duration"),
        key: "duration",
      },
      {
        value: t("dailyRevenue.status"),
        key: "status",
      },
      {
        value: t("dailyRevenue.revenue"),
        textAlign: "right",
        key: "revenueAmount",
      },
    ];

    return columns.filter((column) => column !== null);
  }, [userMaySeeUserInformation]);
};
