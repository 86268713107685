"use strict";

import React, { useEffect, useState, useMemo, useRef } from "react";
import { Text } from "@citifyd/style";
import Table from "../../../../shared/react/components/Table";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import { useTranslator } from "../../../../shared/react/hooks";
import { loadWaitlistData, getData } from "./utils";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const Waitlist = ({ event, ...rest }) => {
  const t = useTranslator();
  const timer = useRef();
  const { user, permissions } = useAuthentication();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [lastHash, setLastHash] = useState();
  const [waitlistEntries, setWaitlistEntries] = useState([]);

  const { columns, data } = useMemo(
    () =>
      getData({
        waitlistEntries,
        user,
        permissions,
      }),
    [waitlistEntries]
  );

  const fetchWaitlist = async ({ lastHash }) => {
    const waitlistData = await loadWaitlistData({ lastHash });
    setWaitlistEntries(waitlistData?.waitlistEntries);
    setHasError(waitlistData?.error);
    setLastHash(waitlistData?.lastHash);
    refreshTimeout();
    setIsLoading(false);
  };

  const refreshTimeout = () => {
    clearTimeout(timer?.current);
    timer.current = setTimeout(() => {
      fetchWaitlist({ lastHash });
    }, 5000);
  };

  useEffect(() => {
    fetchWaitlist({ lastHash });

    return clearTimeout(timer?.current);
  }, []);

  if (isLoading || hasError) {
    return (
      <div {...rest}>
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      </div>
    );
  }

  return (
    <div {...rest}>
      {data && data?.length === 0 && (
        <Text variant="subtitle">{t(`event.noUsers`)}</Text>
      )}

      {data?.length > 0 && (
        <Table
          columns={columns}
          data={data}
          striped
          responsive
          sortable
          clickable
        />
      )}
    </div>
  );
};

export default Waitlist;
