"use strict";

import React, { useMemo } from "react";

import CurrencyFormatter from "@citifyd/currency-formatter";
import Table from "../../../../../shared/react/components/Table";
import { getCurrentLanguage } from "../../../../../shared/services/languages";
import { useTranslator, useService } from "../../../../../shared/react/hooks";

const SummaryTable = ({ summaryData }) => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const language = getCurrentLanguage();

  const formatCurrency = (value) =>
    CurrencyFormatter.format(value, {
      currency: $stateParams.currency,
      language,
    });

  const columns = [{ key: "title" }, { key: "amount" }];

  const data = useMemo(
    () => [
      {
        title: t("reports.summaryTable.numberOfTransactions"),
        amount: {
          value: summaryData?.totalTransactions?.toString(),
          textAlign: "right",
        },
      },
      {
        title: t("reports.summaryTable.customersReceipts"),
        amount: {
          value: formatCurrency(summaryData?.revenue),
          textAlign: "right",
        },
      },
      {
        title: t("reports.summaryTable.processingFees"),
        amount: {
          value: formatCurrency(summaryData?.processingFees),
          textAlign: "right",
        },
      },
      {
        title: t("reports.summaryTable.citifydFees"),
        amount: {
          value: formatCurrency(summaryData?.citifydServiceFeeApplied),
          textAlign: "right",
        },
      },
      {
        title: t("reports.summaryTable.subsidiesPaid"),
        amount: {
          value: formatCurrency(summaryData?.subsidiesPaid),
          textAlign: "right",
        },
      },
      {
        title: t("reports.summaryTable.net"),
        amount: { value: formatCurrency(summaryData?.net), textAlign: "right" },
      },
      {
        title: t("reports.summaryTable.refundsAmount"),
        amount: {
          value: formatCurrency(summaryData?.refundsAmount),
          textAlign: "right",
        },
      },
      {
        title: t("reports.summaryTable.refundsIssued"),
        amount: {
          value: summaryData?.refundsIssued?.toString(),
          textAlign: "right",
        },
      },
    ],
    [summaryData]
  );

  return (
    <Table
      showHeader={false}
      striped
      fixed
      columns={columns}
      data={data}
      responsive
    />
  );
};

export default SummaryTable;
