"use strict";

import React, { useMemo } from "react";
import CurrencyFormatter from "@citifyd/currency-formatter";
import { Text } from "@citifyd/style";

import PaymentMethod from "../../../shared/react/components/PaymentMethod";
import Table from "../../../shared/react/components/Table";

import { useReservationRefundContext } from "./ReservationRefundContext.jsx";
import { useTranslator } from "../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../shared/services/languages";
import styles from "./ReservationRefund.module.scss";

import { some } from "lodash";

export default function RefundDestination() {
  const t = useTranslator();
  const language = getCurrentLanguage();
  const { reservation, refundPreview } = useReservationRefundContext();

  const canSeeExpiration = useMemo(() =>
    some(
      refundPreview.refundDestinations,
      (destination) => destination.card.expMonth
    )
  );

  if (!refundPreview) {
    return null;
  }

  const dataTable = refundPreview?.refundDestinations?.map(
    (refundDestination) => ({
      card: <PaymentMethod card={refundDestination.card} />,
      expiration: {
        value:
          refundDestination.card.expMonth &&
          `${refundDestination.card.expMonth}/${refundDestination.card.expYear}`,
        verticalAlign: "middle",
      },
      amount: {
        value: CurrencyFormatter.format(refundDestination.amount, {
          currency: reservation.currency,
          language,
        }),
        verticalAlign: "middle",
      },
    })
  );

  return (
    <div className={styles.boxContent}>
      <Text variant="h5" gutterBottom>
        {t("refundPages.refundDestination")}
      </Text>
      <Table
        headerGap
        columns={[
          { key: "card", value: t("refundPages.card") },
          canSeeExpiration && {
            key: "expiration",
            value: t("refundPages.expiration"),
          },
          { key: "amount", value: t("refundPages.amount") },
        ]}
        data={dataTable}
      />
    </div>
  );
}
