"use strict";

import React, { useEffect, useState } from "react";
import { Icon, Text, Button } from "@citifyd/style";

import { useService, useTranslator } from "../../../../shared/react/hooks";
import useLoadTracking from "../../../../shared/react/hooks/useLoadTracking";

import {
  saveDailyPricing,
  getPricingSettings,
} from "./components/DailyParking/utils";

import LoadingManager from "../../../../shared/react/components/LoadingManager";
import RouterLink from "../../../../shared/react/components/RouterLink";
import StickyFooter from "../../../../shared/react/components/StickyFooter";
import DailyParking from "./components/DailyParking/DailyParking.jsx";

import styles from "./RateSettings.module.scss";
import { getPropertyWeeklySchedule } from "../../../../shared/api";
import { goToNextStep } from "../goToNextStep";

const RateSettings = ({ lot }) => {
  const t = useTranslator();
  const [pricingSettings, setPricingSettings] = useState();

  const [hasError, setHasError] = useState();
  const [isEditingDailyPricing, setIsEditingDailyPricing] = useState(false);

  const $state = useService("$state");

  const { isLoading, loadingMessage, trackLoading, untrackLoading } =
    useLoadTracking({
      loadPlan: { message: t("addProperty.loadingMessages.setupDaily") },
      setupDaily: { message: t("addProperty.loadingMessages.setupDaily") },
      savePlan: { message: t("addProperty.loadingMessages.savingMonthly") },
    });

  const getWeeklySchedule = async () => {
    const weeklySchedule = await getPropertyWeeklySchedule(lot.id);
    setPricingSettings(getPricingSettings({ lot, weeklySchedule }));
  };
  const showEditingDailyAlert = () => {
    window.alert(t("addProperty.pricing.errorMessages.isEditingDailyPricing"));
    return false;
  };

  const onSubmit = () => {
    if (isEditingDailyPricing) {
      return showEditingDailyAlert();
    }
    saveDailyPricing({ lot, pricingSettings, trackLoading, untrackLoading })
      .then((success) => {
        if (success) {
          goToNextStep({ lotId: lot.id, step: 4 });
        }
      })
      .catch(() => setHasError(true));
  };

  useEffect(() => {
    getWeeklySchedule();
  }, []);

  useEffect(() => {
    if (
      lot.advancedRateDefinitionEnabled &&
      !$state.params.switchingFromAdvanced
    ) {
      $state.go("edit-property-advanced-rates-lot-setup", {
        lotId: lot.id,
        advancedRateDefinitionId: "latest",
      });
      return;
    }
  }, []);

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    );
  }

  return (
    <div className={styles.step2}>
      {pricingSettings && (
        <DailyParking
          lot={lot}
          pricingSettings={pricingSettings}
          setPricingSettings={setPricingSettings}
          setIsEditingDailyPricing={setIsEditingDailyPricing}
        />
      )}

      {pricingSettings && (
        <StickyFooter isBlocked={false}>
          <RouterLink
            state="edit-property"
            params={{ lotId: lot.id }}
            className={styles.backLink}
          >
            <Icon size="subtitle" icon="chevron-left" appearance="white" />
            <Text variant="subtitle" appearance="white">
              {t("commonButtons.goBackWithoutSaving")}
            </Text>
          </RouterLink>

          <Button shadow extraPadding uppercase onMouseDown={() => onSubmit()}>
            {t("commonButtons.continue")}
          </Button>
        </StickyFooter>
      )}
    </div>
  );
};

export default RateSettings;
