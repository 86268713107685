"use strict";

import React, { createContext, useContext, useEffect, useState } from "react";
import { map, startsWith } from "lodash";
import {
  unstable_HistoryRouter as HistoryRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { confirmPageUnload, setPageTitle } from "../../../services/helper";
import { loadIntercom, updateIntercom } from "../../../services/intercom";
import { routes, history } from "../../../../routes";
import { getService } from "../../utils";
import { useTranslator } from "../../hooks";
import Header from "../Header";
import Footer from "../Footer";
import OriginalUserNotificationBar from "../OriginalUserNotificationBar";
import PaymentInformationNotificationBar from "../PaymentInformationNotificationBar";
import PasswordChangeNotificationBar from "../PasswordChangeNotificationBar";
import { useAuthentication } from "../../contexts/authentication";

export const ReactRouterContext = createContext();

const ReactRouter = () => {
  const $rootScope = getService("$rootScope");
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    loadIntercom();

    $rootScope.$on("$change", ({ success }) => {
      confirmPageUnload(
        () => success(),
        () => {
          // Route blocked because we didn't call success
        }
      );
    });
  }, []);

  return (
    <ReactRouterContext.Provider
      value={{
        showMenu,
        setShowMenu,
      }}
    >
      <HistoryRouter history={history}>
        <OriginalUserNotificationBar />
        <PasswordChangeNotificationBar />
        <PaymentInformationNotificationBar />

        <nav id="site-navigation" className="main-navigation" role="navigation">
          <Header />
        </nav>

        <div className="site-content grid">
          <Routes>
            {map(routes, (route) => {
              return (
                <Route
                  key={route.url}
                  path={route.url}
                  element={
                    <>
                      {route.redirect ? (
                        <Navigate key={route.url} to={route.redirect} replace />
                      ) : (
                        <Page key={route.url} page={route.page} />
                      )}
                    </>
                  }
                />
              );
            })}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Footer />
      </HistoryRouter>
    </ReactRouterContext.Provider>
  );
};

const Page = ({ page }) => {
  const t = useTranslator();
  const { user } = useAuthentication();
  const { setShowMenu } = useContext(ReactRouterContext);

  useEffect(() => {
    setShowMenu(user && page.auth);
  }, [user, page]);

  useEffect(() => {
    const canAccess = !page.auth || user;
    if (!canAccess) return;

    let title = page.title;

    if (startsWith(title, "i18n:")) {
      title = t(title.replace("i18n:", ""));
    }

    setPageTitle(title);
    updateIntercom();
  }, [page]);

  if (page.auth && !user) {
    const location = window.location;
    const page = location.pathname + location.search + location.hash;
    const source = encodeURIComponent(page);
    return <Navigate to={`/login?source=${source}`} replace />;
  }

  return <page.Component />;
};

export default ReactRouter;
