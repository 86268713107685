"use strict";

import React, { useEffect, useState } from "react";
import { Grid } from "@citifyd/style";

import { useService, useTranslator } from "../../shared/react/hooks";
import PageHeader from "../../shared/react/components/PageHeader";
import LoadingManager from "../../shared/react/components/LoadingManager";
import styles from "./Organization.module.scss";
import OrganizationInfo from "./components/OrganizationInfo";
import OrganizationLots from "./components/OrganizationLots";
import OrganizationVenues from "./components/OrganizationVenues";
import OrganizationUsers from "./components/OrganizationUsers";
import { getOrganizationById } from "../../shared/api";
import { setPageTitle } from "../../shared/services/helper";

const Organization = () => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const [organization, setOrganization] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const fetchOrganization = async () => {
    setIsLoading(true);

    try {
      const organization = await getOrganizationById($stateParams.id);
      setOrganization(organization);
      setPageTitle("Organization: " + organization?.name);
    } catch (err) {
      console.log("err", err);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganization();
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  return (
    <div className={styles.organization}>
      <Grid>
        <PageHeader>
          <PageHeader.Title
            backState
            title={`${t("organization.organization")} ${organization.name}`}
          />
        </PageHeader>
        <OrganizationInfo organization={organization} />
        <OrganizationLots organization={organization} />
        <OrganizationVenues organization={organization} />
        <OrganizationUsers organization={organization} />
      </Grid>
    </div>
  );
};

export default Organization;
