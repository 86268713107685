"use strict";

import React from "react";
import produce from "immer";

import BoxHeader from "./BoxHeader";
import styles from "./DynamicPricing.module.scss";
import { Collapse, Icon } from "@citifyd/style";
import BoxContent from "../../../../../shared/react/components/BoxContent";
import Table from "../../../../../shared/react/components/Table";
import { useTranslator } from "../../../../../shared/react/hooks";
import FormikField, {
  FIELD_TYPE,
} from "../../../../../shared/react/components/FormikField";

const AdvanceSales = ({ lot, formikbag, formikIndex }) => {
  const t = useTranslator();
  const { setValues, values } = formikbag;
  const { selectedLots } = values;

  const columns = [
    {
      value: t("eventProperties.dynamicPricing.advanceSales.cutoffType"),
      key: "cutoffType",
      width: "50%",
      uppercase: false,
    },
    {
      value: t("eventProperties.dynamicPricing.advanceSales.cutoffValue"),
      key: "cutoffValue",
      width: "25%",
      uppercase: false,
    },
    {
      value: "",
      key: "padding",
      width: "25%",
    },
    {
      value: "",
      key: "action",
      textAlign: "right",
    },
  ];

  const timeOptions = [
    {
      value: 360,
      label: t("eventProperties.dynamicPricing.hoursBefore", {
        hours: "6",
      }),
    },
    {
      value: 720,
      label: t("eventProperties.dynamicPricing.hoursBefore", {
        hours: "12",
      }),
    },
    {
      value: 1440,
      label: t("eventProperties.dynamicPricing.hoursBefore", {
        hours: "24",
      }),
    },
    {
      value: 2880,
      label: t("eventProperties.dynamicPricing.hoursBefore", {
        hours: "48",
      }),
    },
    {
      value: 4320,
      label: t("eventProperties.dynamicPricing.hoursBefore", {
        hours: "72",
      }),
    },
  ];

  const data = [
    {
      cutoffType: {
        value: (
          <FormikField
            className={styles.checkbox}
            as={FIELD_TYPE.CHECKBOX}
            label={t(
              "eventProperties.dynamicPricing.advanceSales.cutoffByRemainingTime"
            )}
            name={`selectedLots[${formikIndex}].availability.saleCutoffByTimeEnabled`}
          />
        ),
        nowrap: "nowrap",
        verticalAlign: "middle",
      },
      cutoffValue: {
        value: (
          <FormikField
            as={FIELD_TYPE.SELECT}
            name={`selectedLots[${formikIndex}].availability.saleCutoffByTime`}
            options={timeOptions}
            disabled={
              !values.selectedLots[formikIndex].availability
                .saleCutoffByTimeEnabled
            }
          />
        ),
        nowrap: "nowrap",
      },
      padding: {
        value: <></>,
      },
      action: {
        value: <></>,
        verticalAlign: "middle",
      },
    },
    {
      cutoffType: {
        value: (
          <FormikField
            className={styles.checkbox}
            as={FIELD_TYPE.CHECKBOX}
            label={t(
              "eventProperties.dynamicPricing.advanceSales.cutoffByPassesSold"
            )}
            name={`selectedLots[${formikIndex}].availability.saleCutoffByTransactionEnabled`}
          />
        ),
        nowrap: "nowrap",
        verticalAlign: "middle",
      },
      cutoffValue: {
        value: (
          <FormikField
            as={FIELD_TYPE.INPUT}
            type="number"
            name={`selectedLots[${formikIndex}].availability.saleCutoffByTransaction`}
            disabled={
              !values.selectedLots[formikIndex].availability
                .saleCutoffByTransactionEnabled
            }
          />
        ),
        nowrap: "nowrap",
      },
      padding: {
        value: <></>,
      },
      action: {
        value: <></>,
        verticalAlign: "middle",
      },
    },
  ];

  const handleChange = () => {
    setValues(
      produce((values) => {
        const availability = values.selectedLots[formikIndex].availability;

        if (!availability.advancedSalesEnabled)
          availability.advancedSalesEnabled = false;
        availability.advancedSalesEnabled = !availability.advancedSalesEnabled;
        if (!availability.cutoffByRemainingTime)
          availability.saleCutoffByTimeEnabled = false;
        availability.saleCutoffByTime = 360;
      })
    );
  };

  return (
    <BoxContent noShadow>
      <BoxHeader
        title={t("eventProperties.dynamicPricing.advanceSales.title")}
        tooltip={t("eventProperties.dynamicPricing.advanceSales.tooltip")}
        selected={selectedLots[formikIndex].availability.advancedSalesEnabled}
        handleChange={handleChange}
        isOpened={selectedLots[formikIndex].availability.advancedSalesEnabled}
      />
      <Collapse
        isOpened={selectedLots[formikIndex].availability.advancedSalesEnabled}
      >
        <BoxContent.Content>
          <Table
            columns={columns}
            data={data}
            spacing
            headerAppearance="transparent"
          />
        </BoxContent.Content>
      </Collapse>
    </BoxContent>
  );
};

export default AdvanceSales;
