"use strict";

import React from "react";
import { Button, LoadingIndicator, Modal, Text } from "@citifyd/style";
import { useTranslator } from "../../../react/hooks";
import Dictionary, {
  DictionaryItem,
} from "../../../react/components/Dictionary";
import Items from "./Items.jsx";
import useLoadTracking from "../../../react/hooks/useLoadTracking";
import { migrateTickets } from "../../../api";
import styles from "./StepShow.module.scss";
import { showErrorAlert } from "../../../services/helper";

const StepShow = ({
  migrationTickets,
  onClose,
  setMigrationResult,
  setStep,
  migrationAvailable,
  user,
}) => {
  const t = useTranslator();

  const { isLoading, trackLoading, untrackLoading } = useLoadTracking();

  const migrate = async () => {
    if (!window.confirm(t("modalUserMigration.confirmMigration"))) {
      return;
    }
    try {
      trackLoading("migrate");
      const response = await migrateTickets(user.id);

      setStep("migration-result");
      if (response.migrated) {
        const count = response.redeemedTickets.length;
        setMigrationResult(t("modalUserMigration.passesRedeemed", { count }));
      } else if (response.verificationEmailSent) {
        setMigrationResult(t("modalUserMigration.verificationEmailSent"));
      } else {
        setMigrationResult(t("modalUserMigration.noPassesRedeemed"));
      }
    } catch (response) {
      showErrorAlert(response.data);
    } finally {
      untrackLoading("migrate");
    }
  };

  const getStatusDescription = (status) => {
    switch (status) {
      case "MISSING_EVENT_CONFIG":
        return t("modalUserMigration.statuses.missingEventConfig");

      case "UNVERIFIED_PHONE_NUMBER":
        return t("modalUserMigration.statuses.unverifiedPhoneNumber");

      case "UNVERIFIED_EMAIL":
        return t("modalUserMigration.statuses.unverifiedEmail");

      case "READY":
        return t("modalUserMigration.statuses.ready");

      default:
        return "-";
    }
  };

  return (
    <>
      <Modal.Title>{t("modalUserMigration.pending")}</Modal.Title>
      <Modal.Content>
        {migrationTickets.map((ticket) => (
          <>
            <Dictionary key={ticket.id}>
              {ticket.event && (
                <DictionaryItem
                  label={
                    <Text variant="subtitle">
                      {ticket.event.name
                        ? ticket.event.name
                        : t("modalUserMigration.eventId", {
                            eventId: ticket.eventId,
                          })}
                    </Text>
                  }
                ></DictionaryItem>
              )}
              <DictionaryItem label={t("modalUserMigration.property")}>
                {ticket.lot.name ? ticket.lot.name : `'#' ${ticket.lotId}`}
              </DictionaryItem>
              <DictionaryItem label={t("modalUserMigration.barcode")}>
                {ticket.barcode}
              </DictionaryItem>
              <Items
                label={t("modalUserMigration.migration")}
                tooltipLabel={t("modalUserMigration.registered")}
                items={ticket.user.emails}
                verifyIfMatch={(email) => email === user.email}
              />
              <Items
                label={t("modalUserMigration.phone")}
                tooltipLabel={t("modalUserMigration.registeredPhone")}
                items={ticket.user.phoneNumbers}
                verifyIfMatch={(phoneNumber) =>
                  phoneNumber === user.phoneNumber
                }
              />

              <DictionaryItem label={t("modalUserMigration.status")}>
                <Text className={styles.status} textAlign="right">
                  {getStatusDescription(ticket.status)}
                </Text>
              </DictionaryItem>
            </Dictionary>
          </>
        ))}
      </Modal.Content>
      {migrationAvailable && <MigrationUnavailableActions onClose={onClose} />}
      {!migrationAvailable && (
        <MigrationAvailableActions onClose={onClose} migrate={migrate} />
      )}
      {isLoading && <LoadingIndicator isLoading={isLoading} withOverlay />}
    </>
  );
};

const MigrationUnavailableActions = ({ onClose }) => {
  const t = useTranslator();
  return (
    <Modal.Actions display="block">
      <Button
        onMouseDown={onClose}
        type="button"
        extraPadding
        justifyContent="center"
        uppercase
        textColor="white"
        appearance="secondary"
      >
        {t("commonButtons.close")}
      </Button>
    </Modal.Actions>
  );
};
const MigrationAvailableActions = ({ onClose, migrate }) => {
  const t = useTranslator();
  return (
    <Modal.Actions>
      <Button
        onMouseDown={onClose}
        type="button"
        fullWidth
        justifyContent="center"
        uppercase
        textColor="white"
        appearance="secondary"
      >
        {t("commonButtons.close")}
      </Button>

      <Button
        onMouseDown={() => migrate()}
        type="button"
        fullWidth
        justifyContent="center"
        uppercase
      >
        {t("modalUserMigration.triggerMigration")}
      </Button>
    </Modal.Actions>
  );
};
export default StepShow;
