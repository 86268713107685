"use strict";

import React from "react";
import { Grid } from "@citifyd/style";
import AccountLots from "./components/AccountLots";

const Properties = () => {
  return (
    <Grid>
      <AccountLots />
    </Grid>
  );
};

export default Properties;
