import { useState } from "react";
import { getUserTickets } from "../../../../shared/api";
import { useService } from "../../../../shared/react/hooks";

export const useTickets = ({ nonCustomer, setUser }) => {
  const [ticketsLoaded, setTicketsLoaded] = useState(false);
  const [ticketsError, setTicketsError] = useState();
  const $stateParams = useService("$stateParams");
  const { id: userId } = $stateParams;

  const fetchTickets = async () => {
    if (nonCustomer) {
      setUser((old) => ({
        ...old,
        tickets: [],
      }));
      return;
    }

    try {
      const tickets = await getUserTickets(userId);

      tickets.forEach((ticket) => {
        ticket.statusTag = ticket.statusTags[0];

        if (ticket.bundlePurchaseId) {
          ticket.price = 0;
        } else {
          ticket.price = ticket.rate.fee + ticket.rate.value;
        }
      });

      setUser((old) => ({
        ...old,
        tickets,
      }));
    } catch (err) {
      setTicketsError(true);
    } finally {
      setTicketsLoaded(true);
    }
  };

  return {
    fetchTickets,
    ticketsLoaded,
    ticketsError,
  };
};

export default useTickets;
