"use strict";

import React from "react";
import { Button } from "@citifyd/style";
import styles from "./ButtonTab.module.scss";

const ButtonTab = ({ isActive, label, onClick, ...rest }) => (
  <Button
    className={styles.buttonTab}
    appearance={isActive ? "primary" : "gray"}
    onClick={onClick}
    rounded
    size="small"
    uppercase
    {...rest}
  >
    {label}
  </Button>
);

export default ButtonTab;
