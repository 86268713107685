import { ApolloError } from "@apollo/client";
import { LoadingIndicator } from "@citifyd/style";
import React, { createContext, ReactNode, useContext, useState } from "react";
import {
  DevicesQuery,
  useDevicesQuery,
} from "../../../graphql-pos/generated/graphql";
import { useAuthentication } from "../../../shared/react/contexts/authentication";

import PosApolloClient from "../../../shared/services/posApolloClient";

const posApolloClient = PosApolloClient();

export const DEFAULT_VALUE = {
  devicesQuery: {
    devices: null,
    loadingDevices: false,
  },
};

type ContextValues = {
  devicesQuery: {
    devices?: DevicesQuery["devices"] | null;
    loadingDevices: boolean;
    errorDevices?: ApolloError;
    devicesRefetch: (variables?: any) => void;
    processingDevices: ProcessingDevicesProps;
    startPollingDevices: (variables?: any) => void;
    stopPollingDevices: (variables?: any) => void;
  };
};

const PosRoverCtx = createContext<ContextValues>(DEFAULT_VALUE);

export const PosRoverProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuthentication();
  const processingDevices = useProcessingDevices();

  const {
    data,
    loading: loadingDevices,
    error: errorDevices,
    refetch: devicesRefetch,
    startPolling: startPollingDevices,
    stopPolling: stopPollingDevices,
  } = useDevicesQuery({
    client: posApolloClient,
    variables: {
      citifydOrganizationId: String(user.organizationId),
    },
  });

  const devicesQuery = {
    devices: data?.devices ?? [],

    errorDevices,
    processingDevices,
    devicesRefetch,
    loadingDevices,
    startPollingDevices,
    stopPollingDevices,
  };

  if (loadingDevices) {
    return <LoadingIndicator />;
  }
  return (
    <PosRoverCtx.Provider value={{ devicesQuery }}>
      {children}
    </PosRoverCtx.Provider>
  );
};

export const usePosRoverCtx = () => useContext(PosRoverCtx);

export const useProcessingDevices = () => {
  const [ids, setIds] = useState<string[]>([]);

  const add = (id) => setIds((ids) => [...ids, id]);
  const remove = (id) => setIds((ids) => ids.filter((item) => item !== id));
  const includes = (id) => ids.includes(id);

  return { ids, add, remove, includes };
};

export interface ProcessingDevicesProps {
  ids: string[];
  add: (id: string) => void;
  remove: (id: string) => void;
  includes: (id: string) => boolean;
}
