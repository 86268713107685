"use strict";

import { sum, some } from "lodash";
import { getPropertiesSnapshot } from "../../../../shared/api";

const adjustPercentage = (n) => (n > 0 && n < 1 ? 1 : Math.ceil(n));

const generateGraphValue = (previousValue, portion, total) => {
  const percentage = adjustPercentage((portion / total) * 100);
  const value = previousValue + percentage;
  return value;
};

const generateGraphStyleObject = (value) => ({
  strokeDasharray: value + " 100",
});

export const generateGraphStylesForProperty = (property) => {
  let parked = generateGraphValue(
    0,
    property.ticketsParkedCount,
    property.maxSpots
  );
  let purchased = generateGraphValue(
    parked,
    property.pendingTicketsPurchasedCount,
    property.maxSpots
  );
  let monthly = generateGraphValue(
    purchased,
    property.monthlyParkersCount,
    property.maxSpots
  );
  let ondemand = generateGraphValue(
    monthly,
    property.onDemandParkersCount,
    property.maxSpots
  );
  let permit = generateGraphValue(
    ondemand,
    property.permitPassCount,
    property.maxSpots
  );
  let reservedparking = generateGraphValue(
    permit,
    property.reservedParkingCount,
    property.maxSpots
  );

  // 100 doesn't fill the entire circle, so we have to add 1 in this case :|
  if (ondemand === 100) {
    ondemand++;
  }

  if (reservedparking === 100) {
    reservedparking++;
  }

  if (permit === 100) {
    permit++;
  }

  return {
    ondemand: generateGraphStyleObject(ondemand),
    reservedparking: generateGraphStyleObject(reservedparking),
    permit: generateGraphStyleObject(permit),
    monthly: generateGraphStyleObject(monthly),
    purchased: generateGraphStyleObject(purchased),
    parked: generateGraphStyleObject(parked),
  };
};

export const loadSnapshots = async () => {
  return getPropertiesSnapshot()
    .then((properties) => {
      properties.forEach((property) => {
        property.occupied =
          property.onDemandParkersCount +
          property.reservedParkingCount +
          property.permitPassCount +
          property.monthlyParkersCount +
          property.ticketsPurchasedCount;
        property.percentageOccupied = adjustPercentage(
          (property.occupied / property.maxSpots) * 100
        );

        property.available = property.maxSpots - property.occupied;
        property.pendingTicketsPurchasedCount =
          property.ticketsPurchasedCount - property.ticketsParkedCount;
      });

      const total = {
        spaces: sum(properties, "maxSpots"),
        occupied: sum(properties, "occupied"),
        available: sum(properties, "available"),
        ondemand: sum(properties, "onDemandParkersCount"),
        reservedparking: sum(properties, "reservedParkingCount"),
        permit: sum(properties, "permitPassCount"),
        monthly: sum(properties, "monthlyParkersCount"),
        purchased: sum(properties, "pendingTicketsPurchasedCount"),
        parked: sum(properties, "ticketsParkedCount"),
      };

      return {
        total,
        properties,
        propertiesWithOccupation: properties,
        anyLotHasLongTermParkingPlan: some(
          properties,
          (property) => property.hasLongTermParkingPlan
        ),
      };
    })
    .catch((err) => ({ error: err }));
};
