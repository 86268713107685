"use strict";

import React, { useState, useEffect } from "react";
import { Text, Grid, Icon } from "@citifyd/style";

import { formatPhoneNumber } from "../../../../shared/utils/phone-number-formatting";
import { useService, useTranslator } from "../../../../shared/react/hooks";

import RouterLink from "../../../../shared/react/components/RouterLink";
import LoadingManager from "../../../../shared/react/components/LoadingManager";

import EditOrganizationModal from "../../../../shared/modals/EditOrganizationModal";
import NotificationSettingsModal from "../../../../shared/modals/NotificationSettingsModal";
import Permissions from "../../../../shared/services/permissions";
import styles from "./AccountInformation.module.scss";
import { getMyOrganization } from "../../../../shared/api";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";
import PageHeader from "../../../../shared/react/components/PageHeader";
import { useModal } from "../../../../shared/react/contexts/modal";
import ReceiveDailyPropertyReportPDFViaEmailModal from "../../../../shared/modals/ReceiveDailyPropertyReportPDFViaEmailModal/ReceiveDailyPropertyReportPDFViaEmailModal";
import BoxContent from "../../../../shared/react/components/BoxContent";

const AccountInformation = ({ openEditPaymentModal }) => {
  const t = useTranslator();
  const { user: loggedUser, permissions } = useAuthentication();
  const $stateParams = useService("$stateParams");
  const { openModal } = useModal();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [organizationInfo, setOrganizationInfo] = useState(null);

  const userMayChangePaymentInformation = () =>
    Permissions.userMayChangePaymentInformation(permissions);

  const mayEditOrganizationInformation =
    Permissions.userMayEditOrganizationInformation(permissions);

  const openEditOrganizationModal = () => {
    openModal(EditOrganizationModal, {
      onClose: ({ saved } = {}) => {
        if (saved) {
          loadOrganizationInformation();
        }
      },
    });
  };

  const openReceivePdfModal = () => {
    openModal(ReceiveDailyPropertyReportPDFViaEmailModal);
  };

  const loadOrganizationInformation = async () => {
    setIsLoading(true);
    if (!loggedUser.organization) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await getMyOrganization();

      setOrganizationInfo(response);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadOrganizationInformation();
    if ($stateParams.receivePDF) {
      openReceivePdfModal();
    }
  }, []);

  const renderContent = () => (
    <>
      <Grid.Row>
        <Grid.Col sm={6}>
          {organizationInfo && (
            <Box
              colLeft={
                <>
                  <Text gutterBottom variant="subtitle" bold>
                    {t("accountInfo.account")}
                  </Text>
                  <Text variant="subtitle">{organizationInfo.name}</Text>
                  <Text variant="subtitle">
                    {formatPhoneNumber(
                      organizationInfo.phoneNumber,
                      organizationInfo.phoneCountryCode
                    )}
                  </Text>
                  <Text variant="subtitle">{organizationInfo.email}</Text>
                </>
              }
              colRight={
                mayEditOrganizationInformation && (
                  <span
                    className={styles.edit}
                    onClick={openEditOrganizationModal}
                  >
                    <Icon icon="edit" />
                  </span>
                )
              }
            />
          )}

          {Permissions.userMayChangeAccountInformation(permissions) && (
            <Box
              colLeft={
                <Text bold variant="subtitle">
                  {t("accountInfo.accountSettings")}
                </Text>
              }
              colRight={
                <RouterLink className={styles.edit} state="my-account">
                  <Icon icon="edit" />
                </RouterLink>
              }
            />
          )}

          {Permissions.userMayChangeAccountPermissions(permissions) && (
            <Box
              colLeft={
                <Text bold variant="subtitle">
                  {t("accountInfo.accountPermissions")}
                </Text>
              }
              colRight={
                <RouterLink className={styles.edit} state="account-permissions">
                  <Icon icon="edit" />
                </RouterLink>
              }
            />
          )}

          {organizationInfo &&
            organizationInfo.hasStripeConnectedAccount &&
            userMayChangePaymentInformation(permissions) && (
              <Box
                colLeft={
                  <Text bold variant="subtitle">
                    {t("accountInfo.verificationInformation")}
                  </Text>
                }
                colRight={
                  <RouterLink
                    className={styles.edit}
                    state="stripe-account-setup"
                  >
                    <Icon icon="edit" />
                  </RouterLink>
                }
              />
            )}
        </Grid.Col>

        <Grid.Col sm={6}>
          {organizationInfo && (
            <Box
              colLeft={
                <Text bold variant="subtitle">
                  {t("accountInfo.notificationPreferences")}
                </Text>
              }
              colRight={
                <span
                  className={styles.edit}
                  onClick={() => openModal(NotificationSettingsModal)}
                >
                  <Icon icon="edit" />
                </span>
              }
            />
          )}

          {Permissions.userMayAccessReports(permissions) && (
            <Box
              colLeft={
                <Text bold variant="subtitle">
                  {t("accountInfo.receiveReportDailyPdf")}
                </Text>
              }
              colRight={
                <span
                  className={styles.edit}
                  onClick={() =>
                    openModal(ReceiveDailyPropertyReportPDFViaEmailModal)
                  }
                >
                  <Icon icon="edit" />
                </span>
              }
            />
          )}

          {organizationInfo && userMayChangePaymentInformation(permissions) && (
            <Box
              colLeft={
                <Text bold variant="subtitle">
                  {t("accountInfo.paymentInfo")}
                </Text>
              }
              colRight={
                <span className={styles.edit} onClick={openEditPaymentModal}>
                  <Icon icon="edit" />
                </span>
              }
            />
          )}
        </Grid.Col>
      </Grid.Row>
    </>
  );

  return (
    <>
      <PageHeader>
        <PageHeader.Title weight="300" title={t("accountInfo.accountInfo")} />
      </PageHeader>

      {isLoading ? (
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      ) : (
        renderContent()
      )}
    </>
  );
};

const Box = ({ colLeft, colRight }) => {
  return (
    <BoxContent
      appearance="secondary"
      noShadow
      smallRadius
      className={styles.box}
    >
      <BoxContent.Content>
        <Grid.Row>
          <Grid.Col>{colLeft}</Grid.Col>
          <Grid.Col xs="content">{colRight}</Grid.Col>
        </Grid.Row>
      </BoxContent.Content>
    </BoxContent>
  );
};

export default AccountInformation;
