"use strict";

import React, { ReactNode } from "react";
import { Text } from "@citifyd/style";
import styles from "./CommonStyles.module.scss";

const FieldDescription = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      appearance="gray_dark"
      variant="small"
      gutterBottom
      className={styles.text}
    >
      {children}
    </Text>
  );
};

export default FieldDescription;
