import React, { useMemo, useState } from "react";
import { Button, LoadingIndicator, Modal } from "@citifyd/style";
import { useTranslator } from "../../react/hooks";
import {
  DeviceQuery,
  useCreateProvisionMutation,
  useFutureCitifydEventsQuery,
} from "../../../graphql-pos/generated/graphql";
import PosApolloClient from "../../services/posApolloClient";
import * as yup from "yup";
import { useFormik } from "formik";
import FormikField, { FIELD_TYPE } from "../../react/components/FormikField";
import FormikProvider from "../../react/components/FormikProvider";
import { showAlert } from "../../services/helper";
import { getGraphqlError } from "../../services/graphql-helper";

const posApolloClient = PosApolloClient();

interface FormProps {
  event: "" | number;
  lot: "" | number;
  gate: "" | number;
}

export const RoverAssignDeviceModal = ({
  device,
  open,
  onClose,
}: {
  device: DeviceQuery["device"];
  open: boolean;
  onClose: () => void;
}) => {
  const t = useTranslator();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { data, loading: loadingFutureCitifydEvents } =
    useFutureCitifydEventsQuery({
      client: posApolloClient,
    });

  const [createProvision] = useCreateProvisionMutation({
    client: posApolloClient,
  });

  const initialValues: FormProps = {
    event: "",
    lot: "",
    gate: "",
  };

  const formikbag = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      event: yup.number().required(),
      lot: yup.number().when("event", {
        is: (event) => event !== undefined && event !== null,
        then: yup.number().required(),
        otherwise: yup.number(),
      }),
    }),

    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        await createProvision({
          variables: {
            deviceId: device.id,
            deviceShortCode: device.shortCode,
            deviceSerialId: device.serial,
            citifydEventId: String(values.event),
            citifydLotId: String(values.lot),
            citifydGateId: String(values.gate),
          },
        });

        onClose();
      } catch (err) {
        console.log("Error occurred", err);
        const error = getGraphqlError(err);
        showAlert(error.message);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const { event, lot } = formikbag.values;
  const futureCitifydEvents = data?.futureCitifydEvents;

  const eventOptions = useMemo(
    () =>
      futureCitifydEvents
        ? futureCitifydEvents.map((event) => ({
            value: event.id,
            label: event.name,
          }))
        : [],
    [futureCitifydEvents]
  );

  const lotOptions = useMemo(() => {
    const selectedEvent = futureCitifydEvents?.find(
      (eventItem) => eventItem.id === event
    );

    const lots = selectedEvent?.lots.map((lot) => ({
      value: lot.id,
      label: lot.name,
    }));

    return lots;
  }, [event, futureCitifydEvents]);

  const gateOptions = useMemo(() => {
    const selectedEvent = futureCitifydEvents?.find(
      (eventItem) => eventItem.id === event
    );

    const selectedLot = selectedEvent?.lots.find(
      (lotItem) => lotItem.id === lot
    );

    const gates = selectedLot?.gates.map((lot) => ({
      value: lot.id,
      label: lot.name,
    }));

    return gates;
  }, [lot, event, futureCitifydEvents]);

  const { handleSubmit } = formikbag;
  return (
    <FormikProvider value={formikbag}>
      <Modal open={open} onClose={onClose}>
        {(isSubmitting || loadingFutureCitifydEvents) && (
          <LoadingIndicator withOverlay />
        )}

        <Modal.Title>
          {t("posRover.devices.assignDevice", { shortCode: device.shortCode })}
        </Modal.Title>
        <Modal.Content>
          <FormikField
            as={FIELD_TYPE.SELECT}
            fullWidth
            gutterBottom
            name="event"
            label={t("posRover.devices.selectEvent")}
            options={eventOptions}
          />
          <FormikField
            as={FIELD_TYPE.SELECT}
            fullWidth
            gutterBottom
            name="lot"
            disabled={!event}
            label={t("posRover.devices.selectLot")}
            options={lotOptions}
          />

          <FormikField
            as={FIELD_TYPE.SELECT}
            fullWidth
            gutterBottom
            name="gate"
            disabled={!event || !lot}
            label={t("posRover.devices.selectGate")}
            options={gateOptions}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={onClose}
            textColor="white"
            appearance="secondary"
            fullWidth
            justifyContent="center"
            uppercase
            disabled={isSubmitting}
          >
            {t("posRover.commons.cancel")}
          </Button>
          <Button
            type="submit"
            fullWidth
            justifyContent="center"
            uppercase
            onMouseDown={() => handleSubmit()}
          >
            {t("posRover.commons.assign")}
          </Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  );
};
