import moment from "moment";
import {
  dateAndTimeToMomentObject,
  generateTimesArray,
} from "../services/helper";

export const getFormattedDate = (date) => moment(date).toDate();

export const getFormattedTime = (date) => moment(date).format("HH:mm");

export const getTimes = () => {
  const times = generateTimesArray();

  return times.map((time) => ({
    value: time.time,
    label: time.label,
  }));
};

export const getSelectionObject = ({ name, value, selection }) => ({
  ...selection,
  [name]: value,
});

export const onTimeChange = ({ e, selection }) => {
  const name = e.target.name;
  const time = e.target.value;
  let fieldName, date;

  if (name === "startTime") {
    date = selection.startDate;
    fieldName = "startDate";
  } else if (name === "endTime") {
    date = selection.endDate;
    fieldName = "endDate";
  }

  return getSelectionObject({
    name: fieldName,
    value: dateAndTimeToMomentObject(date, time).toDate(),
    selection,
  });
};

export const getTimezoneValue = (timezoneName) =>
  moment.tz(decodeURIComponent(timezoneName)).format("Z");
