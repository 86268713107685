"use strict";

import React from "react";
import { Icon, Text, Tooltip } from "@citifyd/style";

import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import styles from "./CheckboxWithTooltip.module.scss";

const CheckboxWithTooltip = ({
  tooltip,
  id,
  label,
  name,
  disabled = false,
  ...rest
}) => {
  return (
    <div className={styles.container}>
      <FormikField
        {...rest}
        disabled={disabled}
        as={FIELD_TYPE.CHECKBOX}
        name={name}
        gutterBottom
        label={label}
        labelSize="default"
      />
      <Tooltip
        id={id}
        place="right"
        label={
          <Icon
            icon="question-circle"
            className={styles.icon}
            pull="right"
            size="h3"
          />
        }
      >
        <div className={styles.tooltip}>
          <Text appearance="white">{tooltip}</Text>
        </div>
      </Tooltip>
    </div>
  );
};

export default CheckboxWithTooltip;
