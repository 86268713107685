"use strict";

import React from "react";
import { Icon } from "@citifyd/style";

import Address from "../../../shared/react/components/Address";
import BoxContent from "../../../shared/react/components/BoxContent";
import RouterLink from "../../../shared/react/components/RouterLink";

import styles from "./VenueItem.module.scss";

function VenueItem({ venue }) {
  const { name } = venue;

  return (
    <BoxContent className={styles.venueItem}>
      <BoxContent.Header title={name}>
        <BoxContent.HeaderActions>
          <RouterLink
            state="edit-venue"
            params={{ venueId: venue.id }}
            className={styles.editLink}
          >
            <Icon icon="pencil-alt" appearance="white" />
          </RouterLink>
        </BoxContent.HeaderActions>
      </BoxContent.Header>
      <BoxContent.Content>
        {venue.venueAddress && (
          <Address
            className={styles.address}
            entity={venue}
            format="full"
            addressFieldName={"venueAddress"}
          />
        )}
      </BoxContent.Content>
    </BoxContent>
  );
}

export default VenueItem;
