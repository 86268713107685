import React from "react";
import { Text } from "@citifyd/style";
import { countBy, keys, zipObject, map, forEach, find } from "lodash";
import classNames from "classnames";

import { calculateChartStyles } from "../../../../shared/utils/calculate-chart-styles";
import { getTranslator } from "../../../../shared/services/languages";
import styles from "../../../users/components/UsersStats/UsersStats.module.scss";

const statuses = [
  "parked",
  "transferred",
  "redeemed",
  "cancelled",
  "expired",
  "purchased",
];

export const getPassesByEvent = (values) =>
  values
    ?.map((value) => value.name)
    .sort((a, b) => {
      const x = a.toUpperCase();
      const y = b.toUpperCase();
      return x == y ? 0 : x > y ? -1 : 1;
    });

const calculatePassesByStatus = (tickets) => {
  const total = tickets.length;
  const ticketsByStatusTag = countBy(tickets, "statusTag");

  let values = {};

  statuses.forEach((status) => {
    values[status] = ticketsByStatusTag[status] || 0;
  });

  return {
    total,
    values,
    chartStyles: calculateChartStyles(values, statuses, total),
  };
};

const calculateRedeemedPasses = (user) => {
  const tickets = user.tickets.filter((ticket) =>
    ticket.statusTags.includes("redeemed")
  );
  const total = tickets.length;

  const isParked = (ticket) => ticket.statusTags.includes("parked");
  const isExpired = (ticket) => ticket.statusTags.includes("expired");

  const values = {
    future: tickets.filter((ticket) => !isParked(ticket) && !isExpired(ticket))
      .length,
    parked: tickets.filter(isParked).length,
    expired: tickets.filter(isExpired).length,
  };

  return {
    total,
    values,
    chartStyles: calculateChartStyles(values, keys(values), total),
  };
};

export const getData = ({ user }) => {
  const t = getTranslator();
  const { tickets } = user;

  const passesByStatus = calculatePassesByStatus(tickets);
  const redeemedPasses = calculateRedeemedPasses(user);

  const getLegend = (label) => (
    <span className={styles.boxLegend}>
      <span className={classNames(styles.circle, styles[label])}></span>
      {t(`user.${label}`)}
    </span>
  );

  const passesByStatusColumns = [
    {
      value: (
        <Text variant="subtitle" weight="600">
          {t("user.numberPasses")}
        </Text>
      ),
      uppercase: false,
      key: "numberPasses",
    },
    {
      value: (
        <Text variant="subtitle" weight="600" textAlign="right">
          {passesByStatus.total}
        </Text>
      ),
      key: "total",
    },
  ];

  const passesByStatusData = [
    {
      numberPasses: getLegend("purchased"),
      total: {
        value: passesByStatus.values.purchased || "0",
        textAlign: "right",
      },
    },
    {
      numberPasses: getLegend("parked"),
      total: {
        value: passesByStatus.values.parked || "0",
        textAlign: "right",
      },
    },
    {
      numberPasses: getLegend("transferred"),
      total: {
        value: passesByStatus.values.transferred || "0",
        textAlign: "right",
      },
    },
    {
      numberPasses: getLegend("redeemed"),
      total: {
        value: passesByStatus.values.redeemed || "0",
        textAlign: "right",
      },
    },
    {
      numberPasses: getLegend("cancelled"),
      total: {
        value: passesByStatus.values.cancelled || "0",
        textAlign: "right",
      },
    },
    {
      numberPasses: getLegend("expired"),
      total: {
        value: passesByStatus.values.expired || "0",
        textAlign: "right",
      },
    },
  ];

  const redeemedPassesColumns = [
    {
      value: (
        <Text variant="subtitle" weight="600">
          {t("user.numberPasses")}
        </Text>
      ),
      uppercase: false,
      key: "numberPasses",
    },
    {
      value: (
        <Text variant="subtitle" weight="600" textAlign="right">
          {redeemedPasses.total}
        </Text>
      ),
      key: "total",
    },
  ];

  const redeemedPassesData = [
    {
      numberPasses: getLegend("transfersRedeemed"),
      total: {
        value: redeemedPasses.values.future || "0",
        textAlign: "right",
      },
    },
    {
      numberPasses: getLegend("redeemedParked"),
      total: {
        value: redeemedPasses.values.parked || "0",
        textAlign: "right",
      },
    },
    {
      numberPasses: getLegend("redeemedExpired"),
      total: {
        value: redeemedPasses.values.expired || "0",
        textAlign: "right",
      },
    },
  ];

  return {
    passesByStatus,
    passesByStatusColumns,
    passesByStatusData: passesByStatusData.map((cell) => ({
      ...cell,
      borderBottom: true,
    })),
    redeemedPasses,
    redeemedPassesColumns,
    redeemedPassesData: redeemedPassesData.map((cell) => ({
      ...cell,
      borderBottom: true,
    })),
  };
};

export const getEventData = ({ user, response }) => {
  const t = getTranslator();
  const total = user.tickets.length;
  const values = response.data.counts;
  let chartStyles = calculateChartStyles(
    zipObject(map(values, "name"), map(values, "count")),
    map(values, "name"),
    total
  );

  forEach(chartStyles, (style, key) => {
    style.stroke = find(response.data.counts, { name: key }).color;
  });

  const passesByEvent = {
    total,
    values,
    chartStyles,
  };

  const columns = [
    {
      value: (
        <Text variant="subtitle" weight="600">
          {t("user.numberPasses")}
        </Text>
      ),
      uppercase: false,
      key: "numberPasses",
    },
    {
      value: (
        <Text variant="subtitle" weight="600" textAlign="right">
          {passesByEvent.total}
        </Text>
      ),
      key: "total",
    },
  ];

  const getLegend = (label) => (
    <span className={styles.boxLegend}>
      <span
        className={styles.circle}
        style={{ backgroundColor: label.color }}
      ></span>
      {label.name}
    </span>
  );

  const data = passesByEvent.values.map((value) => ({
    numberPasses: getLegend(value),
    total: {
      value: value.count || "0",
      textAlign: "right",
    },
  }));

  return {
    passesByEvent,
    columns,
    data: data.map((cell) => ({
      ...cell,
      borderBottom: true,
    })),
  };
};
