"use strict";

import React, { useState, useEffect } from "react";
import { Grid } from "@citifyd/style";

import LoadingManager from "../../../shared/react/components/LoadingManager";
import PageHeader from "../../../shared/react/components/PageHeader";

import SubscriptionOverviewBox from "./SubscriptionOverviewBox.jsx";
import SubscriptionPaymentHistoryBox from "./SubscriptionPaymentHistoryBox.jsx";

import { getService } from "../../../shared/react/utils";
import { useTranslator } from "../../../shared/react/hooks";

import styles from "../Subscription.module.scss";
import { getSubscription } from "../../../shared/api";

function Page() {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [subscription, setSubscription] = useState(null);

  async function fetchData() {
    const { id } = getService("$stateParams");

    setIsLoading(true);
    try {
      const subscription = await getSubscription(id);

      setSubscription(subscription);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  return (
    <Grid className={styles.pageSubscription}>
      <SubscriptionPageTitle subscription={subscription} />

      <SubscriptionOverviewBox subscription={subscription} />
      <SubscriptionPaymentHistoryBox
        subscription={subscription}
        reload={fetchData}
      />
    </Grid>
  );
}

function SubscriptionPageTitle({ subscription }) {
  const t = useTranslator();

  if (!subscription || !subscription.user) {
    return (
      <PageHeader>
        <PageHeader.Title title={t("subscriptionPage.pageTitle")} />
      </PageHeader>
    );
  }

  return (
    <PageHeader>
      <PageHeader.Title
        defaultState="user"
        defaultParams={{ id: subscription.user.id }}
      />
    </PageHeader>
  );
}

export default Page;
