"use strict";

import React from "react";
import { Select, Text } from "@citifyd/style";
import { useTranslator } from "../../../../shared/react/hooks";
import styles from "./SelectReport.module.scss";

const SelectReport = ({ selectedReport, onChange }) => {
  const t = useTranslator();
  const reports = [
    {
      label: t("reports.selectOneOption"),
      value: "",
    },
    {
      label: t("revenue.payoutHistory"),
      value: "payouts",
    },
    {
      label: t("reports.ratesReport"),
      value: "rates",
    },
    {
      label: t("revenue.revenuePerDay"),
      value: "daily",
    },
    {
      label: t("revenue.revenuePerEvent"),
      value: "events",
    },
    {
      label: t("reports.revenueByProperty"),
      value: "revenueByProperty",
    },
    {
      label: t("reports.transactionsReport"),
      value: "transactions",
    },
    {
      label: t("reports.summaryReport"),
      value: "summary",
    },
  ];
  return (
    <>
      <div className={styles.selectReport}>
        <Text variant="subtitle" className={styles.text}>
          {t("reports.reportsType")}
        </Text>
        <Select
          fullWidth
          options={reports}
          value={selectedReport ? selectedReport : ""}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </div>
      <div className={styles.divisor}></div>
    </>
  );
};

export default SelectReport;
