import gql from "graphql-tag";

export default gql`
  query AdminVenue($id: Int!) {
    admin_venue(id: $id) {
      __typename
      countryCode
      id
      latitude
      longitude
      name
      venueAddress
    }
  }
`;
