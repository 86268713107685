import React from "react";
import _ from "lodash";
import { Radio } from "@citifyd/style";
import CurrencyFormatter from "@citifyd/currency-formatter";

import {
  getTranslator,
  getCurrentLanguage,
} from "../../../../shared/services/languages";
import { showAlert } from "../../../services/helper";

const getTableColumns = () => {
  const t = getTranslator();
  return [
    {
      key: "radio",
      value: "",
      width: "1%",
    },
    {
      key: "lot",
      value: t("userPurchaseTicket.lot"),
      width: "80%",
    },
    {
      key: "price",
      value: t("userPurchaseTicket.price"),
      width: "20%",
    },
  ];
};

const getTableData = ({ group, lot, bundle, handleSelectLot }) => {
  const language = getCurrentLanguage();
  const price = bundle?.price || lot.rate;
  const item = bundle?.lot || lot;
  return {
    ...(!item.soldOut && {
      onClick: () => handleSelectLot({ group, lot, bundle }),
    }),
    hover: false,
    radio: {
      value: !item.soldOut ? (
        <Radio
          small
          disabled={item.soldOut}
          checked={
            bundle
              ? bundle?.id === group?.selectedBundleId
              : item?.id === group?.selectedLotId
          }
        />
      ) : (
        <div></div>
      ),
      noVerticalPadding: true,
      verticalAlign: "middle",
    },
    lot: {
      value: (
        <>
          {item.name} {item.soldOut && <i>{t("ticketExchange.soldOut")}</i>}
        </>
      ),
      verticalAlign: "middle",
    },
    price: {
      value: CurrencyFormatter.format(price.value + price.fee, {
        currency: item.country.currency,
        language,
      }),
      verticalAlign: "middle",
    },
  };
};

export const addSelectedBundleId = (bundleGroups) => {
  return bundleGroups.map((bundleGroup) => {
    bundleGroup.selectedBundleId = bundleGroup.selectedBundleId || null;
    return bundleGroup;
  });
};

export const groupEventsByRate = (events) => {
  const groupedEvents = _.groupBy(events, (event) => {
    const rates = _(event.lots)
      .sortBy("id")
      .map((lot) => {
        const lotRates = JSON.stringify(
          _(lot.rate).pairs().sortBy("0").value()
        );

        return lot.id + ":" + lotRates + ":" + lot.soldOut;
      })
      .value()
      .join("|");

    return rates;
  });

  return _.values(groupedEvents).map((eventGroup) => {
    return {
      events: eventGroup,
      lots: _.cloneDeep(eventGroup[0].lots),
      selectedLotId: eventGroup[0].selectedLot?.id,
    };
  });
};

export const getEventsData = ({ group, handleSelectEventLot }) => {
  const columns = getTableColumns();
  const data = group?.lots.map((lot) =>
    getTableData({ group, lot, handleSelectLot: handleSelectEventLot })
  );

  return {
    columns,
    data,
  };
};

export const getBundlesData = ({ group, handleSelectBundleLot }) => {
  const columns = getTableColumns();
  const data = group?.bundles.map((bundle) =>
    getTableData({ group, bundle, handleSelectLot: handleSelectBundleLot })
  );

  return {
    columns,
    data,
  };
};

export const getEventsWithSelectedLotIds = (events) =>
  events.map((item) =>
    item?.events.map((event) => ({
      ...event,
      selectedLot: event?.lots.filter(
        (lot) => lot.id === item.selectedLotId
      )[0],
    }))
  );

export const hasMultipleCurrencies = ({ bundles, events }) => {
  const t = getTranslator();

  let bundleCurrencies = bundles
    .filter((bundleGroup) => bundleGroup.selectedBundleId)
    .map((bundleGroup) =>
      _.find(bundleGroup.bundles, {
        id: bundleGroup.selectedBundleId,
      })
    )
    .map((bundle) => bundle.lot?.country?.currency);

  let eventCurrencies = events
    .filter((event) => event.selectedLot)
    .map((event) => event.selectedLot.country?.currency);

  let currencies = _.uniq([...bundleCurrencies, ...eventCurrencies]);

  if (currencies.length > 1) {
    showAlert(t("userPurchaseTicket.multipleCurrenciesError"));

    return true;
  } else {
    return false;
  }
};

export const confirmLotSelections = ({ bundles, events }) => {
  const t = getTranslator();

  const bundlesSelected = _.filter(bundles, "selectedBundleId").length;
  const eventLotsSelected = _.filter(events, "selectedLot").length;

  if (
    (!bundles.length || bundlesSelected > 0) &&
    (!events.length || eventLotsSelected > 0)
  ) {
    return true;
  }

  if (bundlesSelected === 0 && eventLotsSelected === 0) {
    showAlert(t("userPurchaseTicket.lotsRequired"));
    return false;
  }

  return window.confirm(t("userPurchaseTicket.confirmUnselectedLots"));
};
