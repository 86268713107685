"use strict";

import React from "react";
import { Grid, Text } from "@citifyd/style";
import { useTranslator } from "../../hooks";
import LanguageSelector from "../LanguageSelector";

import styles from "./Footer.module.scss";

export default function Footer() {
  const t = useTranslator();
  const currentYear = new Date().getFullYear();

  // the dropdown directive uses the footer position to calculate overflow logic, this is the reason we added an ID here.
  return (
    <div id="footer" className={styles.siteFooterWrap}>
      <Grid>
        <Grid.Row nogutter justify="between">
          <Grid.Col>
            <Text appearance="white" variant="subtitle">
              {t("footer.copyright", { currentYear: currentYear })}
            </Text>
          </Grid.Col>
          <Grid.Col>
            <LanguageSelector />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
}
