"use strict";

import moment from "moment-timezone";
import { includes, find } from "lodash";
import { getService } from "../../shared/react/utils";
import { getTranslator } from "../../shared/services/languages";

import { useMemo } from "react";
import { getSelectionObject } from "../../shared/utils/date-time-formatting";

const MAX_RANGE_ALLOWED_IN_MONTHS = 6;
const DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_CURRENCY = "usd";

const getValidDate = (date) => {
  if (!date) return null;

  return moment(date).isValid() ? moment(date).format(DATE_FORMAT) : null;
};

const getDefaultRatesDate = () => {
  const ratesDate = moment();
  return ratesDate.format(DATE_FORMAT);
};

const getDefaultTimezone = ({ lots, user }) =>
  getTimezoneList({ lots, user })[0].value;

export const getLotsOptions = ({
  lots,
  t,
  showAllPropertiesOption = true,
  organizationId,
}) => {
  if (organizationId && organizationId !== "*") {
    lots = lots.filter((lot) => lot.organizationId === organizationId);
  }

  return [
    showAllPropertiesOption && {
      label: t("reports.commonFilter.fields.allProperties"),
      value: "",
    },
    ...lots.map((lot) => ({
      label: lot.name,
      value: lot.id,
      currency: lot?.country?.currency,
      organizationId: lot.organizationId,
    })),
  ].filter((n) => n);
};

export const selectionToParams = ({ selection, lots, user }) => {
  const { startDate, endDate, ratesDate, timezoneName, ...rest } = selection;

  const startDateFormatted = moment(startDate).format(DATE_FORMAT);
  const endDateFormatted = moment(endDate).format(DATE_FORMAT);
  const dateRange = `${startDateFormatted},${endDateFormatted}`;
  const ratesDateFormatted = moment(ratesDate).format(DATE_FORMAT);

  return {
    ...rest,
    dateRange,
    ratesDate: ratesDateFormatted,
    timezoneName: timezoneName || getDefaultTimezone({ lots, user }),
  };
};

export const getTimezoneList = ({ lots, user }) => {
  const timezoneNames = [
    user.timezoneName,
    "UTC",
    ...(lots ?? []).map((lot) => lot.timezoneName),
  ];
  const uniqueTimezoneNames = [...new Set(timezoneNames)];

  return uniqueTimezoneNames.map((timezoneName) => ({
    value: timezoneName,
    label: timezoneName,
  }));
};

export const useTimezoneList = ({ lots, user }) => {
  return useMemo(() => getTimezoneList({ lots, user }), [lots]);
};

export const getLotsByParams = ({ lots, params }) => {
  let response;

  if (params.lots) {
    const lotIds = getLotsIdsObjectByParams(params.lots);
    response = lots.filter((item) => find(lotIds, (lot) => lot === item.value));
  } else if (params.organizationId) {
    const organizationId = Number(params.organizationId);
    response = lots.filter((lot) => lot.organizationId === organizationId);
  } else {
    response = [...lots];
  }

  return response;
};

export const getParkingMethodList = () => {
  const t = getTranslator();
  return [
    {
      value: "",
      label: t("reports.commonFilter.fields.allReservationType"),
    },
    {
      value: "SUBSCRIPTION",
      label: t("reports.transactionsTable.reservationTypeText.monthlyParking"),
    },
    {
      value: "RESERVED_PARKING",
      label: t("reports.transactionsTable.reservationTypeText.reservedParking"),
    },
    {
      value: "TICKET",
      label: t("reports.transactionsTable.reservationTypeText.eventParking"),
    },
    {
      value: "BUNDLE",
      label: t(
        "reports.transactionsTable.reservationTypeText.eventParkingBundle"
      ),
    },
    {
      value: "RESERVATION",
      label: t("reports.transactionsTable.reservationTypeText.onDemandParking"),
    },
  ];
};
export const getLotsIdsObjectByParams = (lots) => JSON.parse("[" + lots + "]");

export const getFilterSelectionFromState = ({ user }) => {
  const $state = getService("$state");

  const defaultDateRange = getDefaultDateRange();

  let startDate, endDate;

  if ($state.params.dateRange) {
    const parts = $state.params.dateRange.split(",");

    startDate = getValidDate(parts[0]);
    endDate = getValidDate(parts[1]);
  }

  if (!startDate) startDate = defaultDateRange.startDate;
  if (!endDate) endDate = defaultDateRange.endDate;

  const ratesDate =
    getValidDate($state.params.ratesDate) || getDefaultRatesDate();
  const lots = $state.params.lots || null;
  const lastFourDigits = $state.params.lastFourDigits || null;
  const similarSearch = $state.params.similarSearch === "true" || null;
  const license = $state.params.license || null;
  const phoneNumber = $state.params.phoneNumber || null;
  const transactionId = $state.params.transactionId
    ? Number($state.params.transactionId)
    : null;
  const currency = $state.params.currency || DEFAULT_CURRENCY;
  const parkingMethod = $state.params.parkingMethod || null;
  const organizationId = $state.params.organizationId
    ? Number($state.params.organizationId)
    : null;
  const timezoneName =
    $state.params.timezoneName || getDefaultTimezone({ lots, user });
  return {
    lots,
    startDate,
    ratesDate,
    endDate,
    timezoneName,
    currency,
    lastFourDigits,
    similarSearch,
    phoneNumber,
    transactionId,
    license,
    parkingMethod,
    organizationId,
  };
};

export const updateFilters = ({ selection, lots, user }) => {
  const $state = getService("$state");

  $state.transitionTo(
    $state.current.name,
    { ...$state.params, ...selectionToParams({ selection, lots, user }) },
    {
      location: "replace",
      notify: false,
      inherit: true,
    }
  );
};

export const onBasicSelectChange = ({ e, selection }) => {
  const value = e.target.value;
  const name = e.target.name;

  return getSelectionObject({
    name,
    value: encodeURIComponent(value),
    selection,
  });
};

export const onDateChange = ({ field, date, selection }) =>
  getSelectionObject({
    name: field,
    value: date,
    selection,
  });

export const onPropertiesChange = ({ items, selection }) => {
  const allProperties = items?.filter((item) => item.value === "*")[0];
  const propertiesIds = items?.map((item) => item.value);

  return getSelectionObject({
    name: "lots",
    value:
      !allProperties && items?.length > 0 ? propertiesIds.toString() : null,
    selection,
  });
};

export const validateStartAndEndDate = ({ selection }) => {
  const t = getTranslator();
  if (selection?.startDate && selection?.endDate) {
    let errors;
    const { startDate, endDate } = selection;
    const startDateFormatted = moment(startDate);
    const endDateFormatted = moment(endDate);

    if (startDateFormatted.isAfter(endDateFormatted)) {
      errors = {
        endDate: t(
          "reports.commonFilter.errors.endDateAndTime.mustBeAfterStartDate"
        ),
      };
    }

    if (
      endDateFormatted.diff(startDateFormatted, "months") >
      MAX_RANGE_ALLOWED_IN_MONTHS
    ) {
      errors = {
        endDate: t(
          "reports.commonFilter.errors.endDateAndTime.rangeLimitReached",
          {
            maxMonths: MAX_RANGE_ALLOWED_IN_MONTHS,
          }
        ),
      };
    }

    return { errors: errors, isValid: !errors };
  } else {
    return { errors: null, isValid: true };
  }
};

const getDefaultDateRange = () => {
  const startDate = moment()
    .subtract(1, "month")
    .set({ hour: 0, minute: 0, seconds: 0 });

  const endDate = moment().set({ hour: 0, minute: 0, seconds: 0 });

  return { startDate, endDate };
};

export const getLotsByCurrency = (lots) =>
  lots.filter(
    (item) =>
      !item.currency || item.currency === getCurrencyOfSelectedLots(lots)
  );

export const getCurrencyOfSelectedLots = (lots) =>
  lots?.filter((item) => item.currency)[0]?.currency || false;
