"use strict";
import React from "react";
import { AlertMessage, Button, Grid, Icon, Text } from "@citifyd/style";

import FormikProvider from "../../shared/react/components/FormikProvider";
import FormikField, {
  FIELD_TYPE,
} from "../../shared/react/components/FormikField";

import { useTranslator } from "../../shared/react/hooks";
import { useManagementPermission, UserData } from "./useManagementPermission";
import {
  usePermissionOptionColumns,
  usePermissionOptions,
} from "./usePermissionOptions";

import BoxContent from "../../shared/react/components/BoxContent";
import PageHeader from "../../shared/react/components/PageHeader";
import StickyFooter from "../../shared/react/components/StickyFooter";
import CheckboxWithTooltip from "../../shared/react/components/CheckboxWithTooltip/CheckboxWithTooltip";
import styles from "./AddManagementPermission.module.scss";
interface FormProps {
  userData: UserData;
  isEditing: boolean;
  isPending: boolean;
}

export const Form = ({ userData, isEditing, isPending }: FormProps) => {
  const t = useTranslator();

  const permissionOptions = usePermissionOptions();
  const permissionOptionsColumns = usePermissionOptionColumns();

  const handleChangeSelectAllOptions = (e) => {
    const selectAllValue = e.target.checked;

    setFieldValue("selectAll", selectAllValue);
    const updatedCheckboxes = {};

    permissionOptions.forEach((option) => {
      updatedCheckboxes[option.name] = selectAllValue;
    });
    setFieldValue("checkboxes", updatedCheckboxes);
  };

  const handleChangeSelectOptions = (e) => {
    const checked = e.target.checked;

    if (values.selectAll && !checked) {
      setFieldValue("selectAll", false);
      return;
    }

    const name = e.target.name.split(".").pop();

    const allOthersChecked = Object.entries(values.checkboxes)
      .filter(([key, _value]) => key !== name)
      .every(([_key, checked]) => checked);

    if (checked && allOthersChecked) {
      setFieldValue("selectAll", true);
    }
  };

  const formikbag = useManagementPermission({ permissionOptions, userData });

  const { handleSubmit, values, setFieldValue } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Grid>
        <PageHeader>
          <PageHeader.Title
            weight="300"
            defaultState="account-permissions"
            title={
              isEditing
                ? t(
                    "accountPermissions.permissionInternalUser.editManagementPermission"
                  )
                : t(
                    "accountPermissions.permissionInternalUser.addManagementPermission"
                  )
            }
          />
        </PageHeader>
        <BoxContent>
          <BoxContent.Content>
            <Grid.Row>
              <Grid.Col xs={12} sm={6}>
                <FormikField
                  as={FIELD_TYPE.INPUT}
                  gutterBottom
                  name="employeesName"
                  disabled={isEditing && !isPending}
                  label={t(
                    "accountPermissions.permissionInternalUser.employeesName"
                  )}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                <FormikField
                  as={FIELD_TYPE.INPUT}
                  gutterBottom
                  name="employeesEmail"
                  disabled={isEditing && !isPending}
                  label={t(
                    "accountPermissions.permissionInternalUser.employeesEmail"
                  )}
                />
              </Grid.Col>
            </Grid.Row>
            <br />
            <Text gutterBottom variant="h4" weight="600">
              {t(
                "accountPermissions.permissionInternalUser.assignPermissions.title"
              )}
            </Text>
            <Text gutterBottom>
              {t(
                "accountPermissions.permissionInternalUser.assignPermissions.description.row1"
              )}
            </Text>
            <AlertMessage className={styles.alertMessage}>
              <Text>
                <Icon pull="none" icon="exclamation-triangle" />{" "}
                {t(
                  "accountPermissions.permissionInternalUser.assignPermissions.description.row2"
                )}
              </Text>
            </AlertMessage>

            <FormikField
              gutterBottom
              as={FIELD_TYPE.CHECKBOX}
              label={t(
                "accountPermissions.permissionInternalUser.assignPermissions.selectAllAdminAccount"
              )}
              name="selectAll"
              checked={values.selectAll}
              onChange={(e) => handleChangeSelectAllOptions(e)}
            />
            <Grid.Row>
              <Grid.Col xs={12} sm={6}>
                {permissionOptionsColumns[0].map((option) => (
                  <div key={option.name}>
                    <FormikField
                      gutterBottom
                      as={FIELD_TYPE.CHECKBOX}
                      label={option.label}
                      handleChange={(e) => handleChangeSelectOptions(e)}
                      name={`checkboxes.${option.name}`}
                    />
                  </div>
                ))}
              </Grid.Col>
              <Grid.Col>
                {permissionOptionsColumns[1].map((option) => (
                  <div key={option.name}>
                    {option.name === "createManagementPermissions" ? (
                      <CheckboxWithTooltip
                        key={option.name}
                        tooltip={
                          <Text appearance="white">
                            <Icon
                              pull="none"
                              appearance="white"
                              icon="exclamation-triangle"
                            />{" "}
                            {t(
                              "accountPermissions.permissionInternalUser.assignPermissions.description.row2"
                            )}
                          </Text>
                        }
                        id={"createManagementPermissions"}
                        label={option.label}
                        handleChange={(e) => handleChangeSelectOptions(e)}
                        name={`checkboxes.${option.name}`}
                      />
                    ) : (
                      <FormikField
                        key={option.name}
                        gutterBottom
                        as={FIELD_TYPE.CHECKBOX}
                        label={option.label}
                        handleChange={(e) => handleChangeSelectOptions(e)}
                        name={`checkboxes.${option.name}`}
                      />
                    )}
                  </div>
                ))}
              </Grid.Col>
            </Grid.Row>
          </BoxContent.Content>
        </BoxContent>
      </Grid>
      <StickyFooter isBlocked={false} justifyContent="flex-end">
        <Button uppercase onMouseDown={handleSubmit} extraPadding>
          {t(
            "accountPermissions.permissionInternalUser.assignPermissions.addPermission"
          )}
        </Button>
      </StickyFooter>
    </FormikProvider>
  );
};
