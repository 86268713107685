import React from "react";
import moment from "moment-timezone";
import { Badge } from "@citifyd/style";
import { trim, sum, flatten, map, isUndefined } from "lodash";
import { getService } from "../../../../shared/react/utils";
import { getTranslator } from "../../../../shared/services/languages";

import { getEventStats, getAllEvents } from "../../../../shared/api";

import { getLocalTimezoneName } from "../../../../shared/services/helper";
import HighlightText from "../../../../shared/react/components/HighlightText";
import Permissions from "../../../../shared/services/permissions";

const PER_PAGE = 15;
const eventStatus = ["FUTURE", "PAST", "WEEK", "MONTH", "TODAY"];

import styles from "./Events.module.scss";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

export const getEventStatus = (status) =>
  eventStatus.find((item) => item === status?.toUpperCase())?.toLowerCase() ||
  "future";

export const getTableData = (eventPasses) => {
  if (!eventPasses) return null;
  const { permissions } = useAuthentication();
  const userMayEditEvent = Permissions.userMayEditEvent(permissions);

  const $state = getService("$state");
  const t = getTranslator();
  const columns = [
    {
      key: "date",
      value: t("schedule.date"),
      width: "20%",
    },
    {
      key: "event",
      value: t("schedule.event"),
      width: "30%",
    },
    {
      key: "venue",
      value: t("schedule.venue"),
      width: "30%",
    },
    {
      key: "time",
      value: t("schedule.time"),
      width: "10%",
    },
    {
      key: "sold",
      value: t("schedule.sold"),
      width: "10%",
    },
  ];

  const data = eventPasses?.map((event) => ({
    onClick: () =>
      userMayEditEvent &&
      $state.go(
        "event",
        { id: event.id },
        { enableBackLink: true, inherit: false }
      ),

    date: t("defaultFormats.date", {
      date: moment(event.start).tz(event.timezoneName),
    }),
    event: (
      <>
        <HighlightText
          flags="-i"
          text={event.name}
          highlight={$state?.params?.search}
        />
        {event.extra?.source === "connect" && (
          <Badge appearance="white" className={styles.badge}>
            {t("schedule.events.connect")}
          </Badge>
        )}
      </>
    ),
    venue: event.venue?.name ? (
      <HighlightText
        flags="-i"
        text={event.venue?.name}
        highlight={$state?.params?.search}
      />
    ) : (
      <>&mdash;</>
    ),
    time: t("defaultFormats.time", {
      date: moment(event.start).tz(event.timezoneName),
    }),
    sold: `${event.ticketCount}/${totalSpaces(event)}`,
  }));

  return {
    columns,
    data,
  };
};

export const getCsvParams = () => {
  const params = getService("$state")?.params;
  const { status, property } = params;

  const data = {
    timezoneName: getLocalTimezoneName(),
    ...(property && { lotId: property }),
    ...(status && { filter: status }),
  };

  return data;
};

export const generateEmptyListMessage = (selectedProperty) => {
  const t = getTranslator();
  const params = getService("$state")?.params;
  const { search, status } = params;
  const localeOptions = {};

  let localeKey = `schedule.events.empty.${getEventStatus(status)}`;

  if (selectedProperty) {
    localeKey += "Lot";
    localeOptions.lotName = selectedProperty.name;
  }

  if (search) {
    localeKey += "Search";
    localeOptions.searchQuery = search;
  }

  return t(localeKey, localeOptions);
};

const totalSpaces = (event) => {
  return sum(flatten(map(event.lots, (lot) => lot.spaces)), "max");
};

export const showStat = (value) => (!isUndefined(value) ? value : <>&mdash;</>);

export const loadStats = async () => {
  const params = getService("$state")?.params;
  const { property } = params;

  const options = {};

  if (property) {
    options.lotId = property;
  }

  return getEventStats({ params: options })
    .then((response) => response.data)
    .catch((err) => err);
};

export const getFilterDescription = () => {
  const t = getTranslator();
  const params = getService("$state")?.params;
  const { status } = params;

  switch (status) {
    case "today":
      return t("schedule.events.filterDescription.today");
    case "week":
      return t("schedule.events.filterDescription.week");
    case "month":
      return t("schedule.events.filterDescription.month");
    case "past":
      return t("schedule.events.filterDescription.past");
    default:
      return t("schedule.events.filterDescription.none");
  }
};

export const loadEvents = async (offset) => {
  const params = getService("$state")?.params;
  const { search, status, property } = params;

  var options = {
    filter: getEventStatus(status),
    offset: offset * PER_PAGE,
    limit: PER_PAGE,
  };

  if (trim(search) !== "") {
    options.search = trim(search);
  }

  if (property) {
    options.lotId = property;
  }

  return getAllEvents({ params: options })
    .then((response) => ({
      events: response,
      hasEnded: response?.length < PER_PAGE,
    }))
    .catch((err) => err);
};
