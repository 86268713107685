import { Button, Grid } from "@citifyd/style";
import LoadingManager from "../../../shared/react/components/LoadingManager";
import PageHeader from "../../../shared/react/components/PageHeader";
import { useService, useTranslator } from "../../../shared/react/hooks";
import SubscriptionRefundContextProvider, {
  useSubscriptionRefundContext,
} from "./SubscriptionRefundContext";

import RefundDestination from "./SubscriptionRefundDestination.jsx";
import RefundSource from "./SubscriptionRefundSource";
import RefundReason from "./SubscriptionRefundReason";
import StickyFooter from "../../../shared/react/components/StickyFooter";

const SubscriptionRefundPage = () => {
  const { id } = useService("$stateParams");

  return (
    <SubscriptionRefundContextProvider subscriptionId={id}>
      <PageContent />
    </SubscriptionRefundContextProvider>
  );
};

function PageContent() {
  const { subscription, refundOptions, isLoading, loadingMessage, hasError } =
    useSubscriptionRefundContext();

  if (isLoading || hasError) {
    return (
      <LoadingManager
        loadingMessage={loadingMessage}
        isLoading={isLoading}
        hasError={hasError}
      />
    );
  }

  return (
    <Grid>
      <PageTitle />

      {subscription && refundOptions && (
        <>
          <RefundDestination />
          <RefundSource />
          <RefundReason />
          <Footer />
        </>
      )}
    </Grid>
  );
}

function PageTitle() {
  const { subscriptionId } = useSubscriptionRefundContext();
  const t = useTranslator();

  return (
    <PageHeader>
      <PageHeader.Title
        defaultState="subscription"
        defaultParams={{ id: subscriptionId }}
        title={t("subscriptionPage.pageTitle")}
      />
    </PageHeader>
  );
}

function Footer() {
  const t = useTranslator();
  const { isLoading, requestRefund } = useSubscriptionRefundContext();

  return (
    <StickyFooter isBlocked={isLoading} justifyContent="flex-end">
      <Button uppercase onMouseDown={requestRefund} extraPadding>
        {t("ticketRefund.refundPass")}
      </Button>
    </StickyFooter>
  );
}

export default SubscriptionRefundPage;
