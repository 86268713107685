"use strict";

import React, { useMemo } from "react";
import { kebabCase } from "lodash";

import "./style.scss";

export default function CardIcon({ brand }) {
  const classModifier = useMemo(() => {
    return `component-card-icon--${kebabCase(brand || "unknown")}`;
  }, [brand]);

  return <span className={`component-card-icon ${classModifier}`} />;
}
