"use strict";

import React from "react";
import PageHeader from "../../../shared/react/components/PageHeader";

const Header = ({ text, onClick }) => {
  return (
    <PageHeader>
      <PageHeader.Title onClick={onClick} weight="300" title={text} />
    </PageHeader>
  );
};

export default Header;
