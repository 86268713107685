import React, { useState, useEffect, useRef } from "react";
import { Tab, Grid, LoadingIndicator } from "@citifyd/style";

import Table from "../../../../shared/react/components/Table";
import { useTranslator } from "../../../../shared/react/hooks";
import { calculateChartStyles, generateData } from "./utils";
import TabName from "../../../../shared/react/components/TabName";
import UsersChart from "../UsersChart";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import styles from "./UsersStats.module.scss";
import { getUsersStats } from "../../../../shared/api";

const UsersStats = () => {
  const t = useTranslator();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [loading, setLoading] = useState({});
  const [cache, setCache] = useState({});
  const currentFilter = useRef(null);
  const [values, setValues] = useState({});
  const [mainChartValues, setMainChartValues] = useState(null);
  const [transferChartValues, setTransferChartValues] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [counts, setCounts] = useState(null);

  const isFilterSelected = (filterName) => currentFilter.current === filterName;

  const updateValues = (values) => {
    setValues(values);

    setMainChartValues(
      calculateChartStyles(
        values,
        ["parked", "cancelled", "expired", "purchased"],
        values.total,
        true
      )
    );

    setTransferChartValues(
      calculateChartStyles(
        values,
        ["redeemed", "pendingTransfer"],
        values.total,
        false
      )
    );
  };

  const selectFilter = async (filterName) => {
    setSelectedFilter(filterName);
    setHasError(false);
    currentFilter.current = filterName;

    if (cache[filterName]) {
      if (isFilterSelected(filterName)) {
        setIsLoadingFilter(false);
      }

      setTimeout(() => {
        updateValues(cache[filterName]);
      }, 100);
      return false;
    }

    setIsLoadingFilter(true);

    if (loading[filterName]) {
      return false;
    }

    setLoading({ [filterName]: true });

    try {
      const response = await getUsersStats({ params: { filter: filterName } });
      const values = response.values;

      setCache((old) => ({ ...old, [filterName]: values }));

      if (isFilterSelected(filterName)) {
        updateValues(values);
      }
    } catch (err) {
      setHasError(true);
    } finally {
      setLoading({ [filterName]: false });
      if (isFilterSelected(filterName)) {
        setIsLoadingFilter(false);
      }
    }
  };

  const fetchStats = async () => {
    try {
      const response = await getUsersStats({
        params: { includeCounts: true },
      });

      const values = response.values;

      setCounts(response.counts);
      updateValues(values);
      setSelectedFilter(response.counts[0]?.name);
      currentFilter.current = response.counts[0]?.name;
      setCache({ [null]: values });
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  const { columnsTotal, columnsTransferred, dataTotal, dataTransferred } =
    generateData(values, t);

  return (
    <Tab className={styles.tab} stateless>
      {counts.map((count, index) => {
        const isActive = selectedFilter === count.name;

        const tabName = {
          big: count.count,
          small: count.label,
        };

        return (
          <Tab.Item
            key={index}
            active={isActive}
            name={<TabName item={tabName} active={isActive} />}
            onClick={() => selectFilter(count.name)}
          >
            {isLoadingFilter && (
              <div className={styles.boxLoading}>
                <LoadingIndicator noMargin />
              </div>
            )}
            {!isLoadingFilter && (
              <Grid.Row align="center" onClick={(e) => e.stopPropagation()}>
                <Grid.Col className={styles.boxTables}>
                  <Table
                    borderBottom
                    cleanMode
                    data={dataTotal}
                    columns={columnsTotal}
                  />
                  <Table
                    borderBottom
                    cleanMode
                    data={dataTransferred}
                    columns={columnsTransferred}
                  />
                </Grid.Col>
                <Grid.Col>
                  <UsersChart
                    mainChartValues={mainChartValues}
                    transferChartValues={transferChartValues}
                  />
                </Grid.Col>
              </Grid.Row>
            )}
          </Tab.Item>
        );
      })}
    </Tab>
  );
};

export default UsersStats;
