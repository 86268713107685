"use strict";

import React from "react";
import Table from "../Table";
import { Text } from "@citifyd/style";
import { getData } from "./utils";
import { useTranslator } from "../../hooks";

const EventsSelector = ({
  events,
  bundleGroups,
  handleSelectEvent,
  handleSelectBundle,
  selectedEvents,
  selectedBundles,
}) => {
  const t = useTranslator();
  const { columns, data } = getData({
    events,
    bundleGroups,
    handleSelectEvent,
    handleSelectBundle,
    selectedEvents,
    selectedBundles,
  });

  return (
    <>
      {!bundleGroups.length && !events.length && (
        <Text>{t("userPurchaseTicket.noEvents")}</Text>
      )}
      <Table columns={columns} responsive striped data={data} />
    </>
  );
};

export default EventsSelector;
