"use strict";

import React from "react";
import { Text } from "@citifyd/style";
import classNames from "classnames";
import { useTranslator } from "../../../../shared/react/hooks";
import styles from "./Properties.module.scss";

const Breakdown = ({ property, anyLotHasLongTermParkingPlan, ...rest }) => {
  const t = useTranslator();
  return (
    <div {...rest}>
      <Text
        className={classNames(styles.breakdownTitle, styles.spaces)}
        variant="subtitle"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t("propertySnapshot.totalSpaces", {
            occupied: property?.occupied,
            count: property?.maxSpots,
            startBold: "<strong>",
            endBold: "</strong>",
          }),
        }}
      />
      <Text className={classNames(styles.legendColor, styles.space)}>
        {t("propertySnapshot.availableSpaces", {
          count: property?.available,
        })}
      </Text>
      <Text className={classNames(styles.legendColor, styles.ondemand)}>
        {t("propertySnapshot.onDemandParkers", {
          count: property?.onDemandParkersCount,
        })}
      </Text>
      <Text className={classNames(styles.legendColor, styles.permit)}>
        {t("propertySnapshot.permitParkers", {
          count: property?.permitPassCount,
        })}
      </Text>
      <Text className={classNames(styles.legendColor, styles.reservedparking)}>
        {t("propertySnapshot.reservedParkingParkers", {
          count: property?.reservedParkingCount,
        })}
      </Text>
      {anyLotHasLongTermParkingPlan && (
        <Text className={classNames(styles.legendColor, styles.monthly)}>
          {t("propertySnapshot.monthlyParkers", {
            count: property?.monthlyParkersCount,
          })}
        </Text>
      )}
      <Text className={classNames(styles.legendColor, styles.purchased)}>
        {t("propertySnapshot.eventPassesPurchased", {
          count: property?.pendingTicketsPurchasedCount,
        })}
      </Text>
      <Text className={classNames(styles.legendColor, styles.parked)}>
        {t("propertySnapshot.eventPassesParked", {
          count: property?.ticketsParkedCount,
        })}
      </Text>
    </div>
  );
};

export default Breakdown;
