import React from "react";
import { Collapse, Text, ToggleButton } from "@citifyd/style";
import BoxContent from "../../../../../shared/react/components/BoxContent";
import styles from "./BoxCollapse.module.scss";

const BoxCollapse = ({ title, isOpen, description, children, handleOpen }) => {
  if (isOpen === undefined) {
    isOpen = true;
  }
  return (
    <BoxContent>
      <BoxContent.Header title={title}>
        {handleOpen && (
          <BoxContent.HeaderActions>
            <div className={styles.container}>
              <Text
                variant="subtitle"
                className={styles.enabled}
                appearance="white"
              >
                {isOpen ? "Enabled" : "Disabled"}
              </Text>
              <ToggleButton selected={isOpen} light onChange={handleOpen} />
            </div>
          </BoxContent.HeaderActions>
        )}
      </BoxContent.Header>

      <BoxContent.Content>
        <Text gutterBottom>{description}</Text>
        {children && (
          <Collapse isOpened={isOpen}>
            <Text>{children}</Text>
          </Collapse>
        )}
      </BoxContent.Content>
    </BoxContent>
  );
};

export default BoxCollapse;
