import { useMemo } from "react";
import { useTranslator } from "../../../../shared/react/hooks";
import Permissions from "../../../../shared/services/permissions";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

export const useTableColumns = () => {
  const t = useTranslator();
  const { permissions } = useAuthentication();
  const userMaySeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  return useMemo(() => {
    const columns = [
      userMaySeeUserInformation
        ? {
            value: t("dailyRevenue.username"),
            key: "name",
          }
        : null,
      {
        value: t("dailyRevenue.property"),
        key: "lotName",
      },
      {
        value: t("dailyRevenue.time"),
        key: "time",
      },
      {
        value: t("dailyRevenue.event"),
        key: "event",
      },
      {
        value: t("dailyRevenue.revenue"),
        textAlign: "right",
        key: "revenueAmount",
      },
    ];

    return columns.filter((column) => column !== null);
  }, [userMaySeeUserInformation]);
};
