import React from "react";
import { useField } from "formik";
import { InputGroup, Grid, Select, Label } from "@citifyd/style";
import { every } from "lodash";

import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import layouts from "./layouts";
import styles from "./AddressForm.module.scss";
import { showAlert } from "../../../services/helper";
import {
  getCoordinates,
  getDistanceBetweenCoordinates,
} from "../../../services/geo";

const AddressForm = ({
  addressFieldName = "address",
  addressSchema,
  availableCountries,
  countryCode,
  label,
  loadAddressSchema,
  setCountryCode,
  shouldShowCountryDropdown,
}) => {
  if (!addressSchema) return null;

  const $stateParams = useService("$stateParams");
  const mode = $stateParams.venueId ? "edit" : "add";
  const isEditMode = mode === "edit";

  const layout = layouts[countryCode];
  const t = useTranslator();
  const [fieldCoordinates, metaCoordinates, helperCoordinates] =
    useField("coordinates");
  const [fieldAddress, metaAddress] = useField(addressFieldName);

  const hasError = metaAddress?.error && metaAddress?.touched;

  const countries = availableCountries.map((country) => {
    return {
      label: country.name,
      value: country.isoCode,
    };
  });

  const handleChangeCountry = (e) => {
    setCountryCode(e.target.value);
    loadAddressSchema(e.target.value);
  };

  const updateCoordinatesFromAddress = async () => {
    // Wait until the address schema is loaded
    if (!addressSchema) {
      return;
    }

    const required = Object.entries(addressSchema.properties)
      .filter(([_key, config]) => config.required)
      .map(([key, _config]) => key);

    const allFilled = every(required, (prop) => fieldAddress.value[prop]);

    if (!allFilled) {
      return;
    }

    try {
      const coordinates = await getCoordinates(fieldAddress.value, countryCode);

      let distance;

      const hasCoordinates =
        typeof fieldCoordinates?.value?.latitude === "number";

      if (hasCoordinates) {
        distance = getDistanceBetweenCoordinates(
          fieldCoordinates.value,
          coordinates
        );
      }

      // Doesn't update if new coordinates are up to 200-meters close to the previous one
      if (hasCoordinates && distance <= 200) {
        console.log("distance", distance);
      } else {
        helperCoordinates.setValue(coordinates);
      }
    } catch (err) {
      showAlert(t("commonErrors.addressNotFound"));
    }
  };

  const renderFields = () =>
    layout?.rows?.map((row, index) => {
      return (
        <InputGroup.Row key={index}>
          {row.map((item, index) => {
            const field = addressSchema.properties[item.field];
            const options = addressSchema.properties[item.field]?.enum;

            if (!field) return null;

            const renderField = () => {
              if (options) {
                return (
                  <FormikField
                    as={FIELD_TYPE.SELECT}
                    handleBlur={updateCoordinatesFromAddress}
                    options={options}
                    className={styles.select}
                    name={`${addressFieldName}.${item.field}`}
                    placeholder={field.name}
                  />
                );
              }

              const extraProps = {
                ...(field.minLength && { minLength: field.minLength }),
                ...(field.maxLength && { maxLength: field.maxLength }),
              };

              return (
                <FormikField
                  handleBlur={updateCoordinatesFromAddress}
                  {...extraProps}
                  showErrorMessage={false}
                  name={`${addressFieldName}.${item.field}`}
                  placeholder={field.name}
                />
              );
            };

            return (
              <InputGroup.Column key={index} width={item.width}>
                {renderField()}
              </InputGroup.Column>
            );
          })}
        </InputGroup.Row>
      );
    });

  return (
    <div>
      <Label required>{label}</Label>
      {shouldShowCountryDropdown && (
        <Grid.Row marginBottom>
          <Grid.Col>
            <Select
              fullWidth
              onChange={handleChangeCountry}
              options={countries}
              value={countryCode}
              disabled={isEditMode}
            />
          </Grid.Col>
        </Grid.Row>
      )}
      <InputGroup error={hasError}>{renderFields()}</InputGroup>
    </div>
  );
};

export default AddressForm;
