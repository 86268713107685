"use strict";

import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Grid } from "@citifyd/style";

import ApolloClient from "../../shared/services/apolloClient";
import LoadingManager from "../../shared/react/components/LoadingManager";
import PageHeader from "../../shared/react/components/PageHeader";
import { useService, useTranslator } from "../../shared/react/hooks";
import AddPermitForm from "./components/AddPermitForm";
import ADMIN_GET_PERMIT_PASS_COMMON_FIELDS from "../../graphql/permitPass/queries/AdminGetPermitPassCommonFields";
import { getAllProperties, getCountries } from "../../shared/api";
import Permissions from "../../shared/services/permissions";
import { useAuthentication } from "../../shared/react/contexts/authentication";

const apolloClient = ApolloClient();

const AddPermit = () => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const $state = useService("$state");
  const { permissions } = useAuthentication();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [countries, setCountries] = useState([]);
  const [properties, setProperties] = useState([]);
  const { permitId } = $stateParams;
  const loadingMessage = t("permits.form.loading");

  const [getPermit, { data, loading }] = useLazyQuery(
    ADMIN_GET_PERMIT_PASS_COMMON_FIELDS,
    {
      variables: {
        id: parseInt(permitId),
      },
      client: apolloClient,
      fetchPolicy: "network-only",
    }
  );

  const loadProperties = async () => {
    try {
      let params = {};

      if (Permissions.userMaySeeAllProperties(permissions)) {
        params = {
          all: true,
          includeOwner: true,
          order: "id",
          reverse: true,
        };
      }

      const response = await getAllProperties({ params });

      setProperties(response);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCountries = async () => {
    setIsLoading(true);

    try {
      const countries = await getCountries();

      setCountries(countries);
    } catch (err) {
      setHasError(true);
      console.log("err", err);
    } finally {
      loadProperties();
    }
  };

  const loadPermit = async () => {
    try {
      getPermit();
    } catch (err) {
      console.log("err", err);
      setHasError(true);
    } finally {
      loadCountries();
    }
  };

  useEffect(() => {
    if (permitId) {
      loadPermit();
    } else {
      loadCountries();
    }
  }, []);

  useEffect(() => {
    if (data) {
      const { admin_getPermitPass: permit } = data;

      if (
        permit?.status === "EXPIRED" ||
        permit?.status === "CANCELLED" ||
        permit.status !== "PENDING"
      ) {
        return $state.go("permits", { location: "replace" });
      }

      if (permit.isActive) {
        return $state.go("permit", { permitId }, { location: "replace" });
      }
    }
  }, [data]);

  if (isLoading || hasError || loading) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    );
  }

  const { admin_getPermitPass: permit } = data || {};

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          defaultState={permitId ? "permit" : "permits"}
          defaultParams={permitId ? { permitId } : null}
          weight="300"
          title={
            permitId ? t("permits.permitInformation") : t("permits.permits")
          }
        />
      </PageHeader>

      <AddPermitForm
        permit={permit}
        countries={countries}
        properties={properties}
      />
    </Grid>
  );
};

export default AddPermit;
