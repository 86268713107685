"use strict";

import { setLocale } from "yup";
import i18next from "i18next";

setLocale({
  mixed: {
    required: () => i18next.t("commonErrors.validation.required"),
  },
  string: {
    required: () => i18next.t("commonErrors.validation.required"),
    email: () => i18next.t("commonErrors.validation.email"),
    length: " ",
    trim: " ",
  },
  number: {
    min: ({ min }) => i18next.t("commonErrors.validation.number.min", { min }),
    max: ({ max }) => i18next.t("commonErrors.validation.number.max", { max }),
    positive: " ",
    negative: " ",
  },
});
