import moment from "moment-timezone";
import CurrencyFormatter from "@citifyd/currency-formatter";

import { formatPhoneNumber } from "../../../../shared/utils/phone-number-formatting";
import {
  getCurrentLanguage,
  getTranslator,
} from "../../../../shared/services/languages";

const columnsTransferSummary = (t) => [
  {
    key: "eventDate",
    value: t("ticketTransfer.eventDate"),
    width: "15%",
  },
  {
    key: "event",
    value: t("ticketTransfer.event"),
    width: "45%",
  },
  {
    key: "lot",
    value: t("ticketTransfer.lot"),
    width: "25%",
  },
  {
    key: "amount",
    value: t("ticketTransfer.amount"),
    width: "15%",
  },
];

const calculateTicketPrice = (ticket) => ticket.rate.value + ticket.rate.fee;

const dataTransferSummary = (t, ticket) => {
  const language = getCurrentLanguage();

  return [
    {
      eventDate: {
        value: t("defaultFormats.date", {
          date: moment.tz(ticket.event.start, ticket.event.timezoneName),
        }),
        nowrap: "nowrap",
      },
      event: {
        value: ticket.event.name,
      },
      lot: {
        value: ticket.lot.name,
        nowrap: "nowrap",
      },
      amount: {
        value: CurrencyFormatter.format(calculateTicketPrice(ticket), {
          currency: ticket.lot.country.currency,
          language,
        }),
      },
    },
  ];
};

const columnsRecipient = (t, values) => {
  const { phoneNumber, email } = values;

  let columns = [];

  if (phoneNumber) {
    columns.push({
      key: "phoneNumber",
      value: t("ticketTransfer.phoneNumber"),
      width: "15%",
      nowrap: "nowrap",
    });
  }

  if (email) {
    columns.push({
      key: "email",
      value: t("ticketTransfer.email"),
    });
  }

  return columns;
};

const dataRecipient = (values) => {
  const { phoneNumber, email, countryCode } = values;

  return [
    {
      ...(!!phoneNumber && {
        phoneNumber: {
          value: formatPhoneNumber(phoneNumber, countryCode || "us"),
          nowrap: "nowrap",
        },
      }),
      ...(!!email && {
        email: {
          value: email,
        },
      }),
    },
  ];
};

export const getData = ({ ticket, values }) => {
  const t = getTranslator();

  return {
    columnsTransferSummary: columnsTransferSummary(t),
    dataTransferSummary: dataTransferSummary(t, ticket),
    columnsRecipient: columnsRecipient(t, values),
    dataRecipient: dataRecipient(values),
  };
};
