"use strict";

import React, { useMemo, useEffect } from "react";
import { Button, Icon, List, Grid, Text, Tooltip } from "@citifyd/style";
import PageHeader from "../../shared/react/components/PageHeader";
import Table from "../../shared/react/components/Table";
import { useService, useTranslator } from "../../shared/react/hooks";
import BoxContent from "../../shared/react/components/BoxContent";
import RouterLinkButton from "../../shared/react/components/RouterLinkButton";
import { formatPhoneNumber } from "../../shared/utils/phone-number-formatting";
import { formatDuration, showErrorAlert } from "../../shared/services/helper";
import ApolloClient from "../../shared/services/apolloClient";
import { useLazyQuery, useMutation } from "@apollo/client";
import ADMIN_GET_PERMIT_PORTAL_USER from "../../graphql/permitPortal/queries/AdminGetPermitPortalUser";
import ADMIN_LIST_PERMIT_PASSES from "../../graphql/permitPortal/queries/AdminListPermitPasses";

import ADMIN_DELETE_PERMIT_PORTAL_USER from "../../graphql/permitPortal/mutations/AdminDeletePermitPortalUser";
import LoadingManager from "../../shared/react/components/LoadingManager";
import moment from "moment-timezone";

const apolloClient = ApolloClient();

const calculateDuration = (startTime, endTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const duration = moment.duration(end.diff(start));
  return formatDuration(duration);
};

const User = () => {
  const t = useTranslator();
  const $state = useService("$state");
  const { id: userId } = $state.params;

  const [getPermitPortalsUser, { data, loading, error }] = useLazyQuery(
    ADMIN_GET_PERMIT_PORTAL_USER,
    {
      client: apolloClient,
      fetchPolicy: "network-only",
    }
  );

  const [getPermitPasses, { data: permitsPassesData }] = useLazyQuery(
    ADMIN_LIST_PERMIT_PASSES,

    {
      client: apolloClient,
      fetchPolicy: "network-only",
    }
  );

  const [deletePermitPortalsUser, { loading: isDeleting }] = useMutation(
    ADMIN_DELETE_PERMIT_PORTAL_USER,
    {
      client: apolloClient,
    }
  );

  useEffect(() => {
    getPermitPortalsUser({
      variables: { id: userId },
    });
    getPermitPasses({
      variables: { portalUserId: userId },
    });
  }, []);

  const userFromBackend = data ? data.admin_getPermitPortalUser : {};
  const permitsIssued = permitsPassesData
    ? permitsPassesData.admin_listPermitPasses.edges
    : [];

  const columns = [
    { key: "userName", value: t("permits.permitters.name") },
    { key: "startDateTime", value: t("permits.permitters.startTime") },
    { key: "endDateTime", value: t("permits.permitters.endTime") },
    { key: "duration", value: t("permits.permitters.duration") },
  ];

  const permits = useMemo(
    () =>
      permitsIssued?.map((permit) => ({
        ...permit,
        onClick: () =>
          $state.go("permit", {
            permitId: permit.node.id,
            permitterId: userId,
          }),
        permitName: permit.node.name,
        startDateTime: t("defaultFormats.datetime", {
          date: permit.node.startTime,
        }),
        endDateTime: t("defaultFormats.datetime", {
          date: permit.node.endTime,
        }),
        duration: calculateDuration(permit.node.startTime, permit.node.endTime),
      })),
    [permitsIssued]
  );

  const deletePermission = () => {
    if (
      !window.confirm(
        t("permits.permitters.messages.deleteRuleConfirmation", {
          userName: userFromBackend.name,
        })
      )
    ) {
      return;
    }
    try {
      deletePermitPortalsUser({
        variables: { id: userId },
      });

      $state.transitionTo("permitters", {
        deleted: userId,
        deletedUser: userFromBackend.name,
      });
    } catch (err) {
      console.log(err);
      showErrorAlert(err);
    }
  };

  if (error || loading || isDeleting) {
    return (
      <LoadingManager
        isLoading={loading || isDeleting}
        hasError={Boolean(error)}
      />
    );
  }

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          defaultState="permitters"
          weight="300"
          title={`${t("permits.permitters.user")}: ${userFromBackend.name}`}
        />
      </PageHeader>
      <BoxContent>
        <BoxContent.Header
          title={`${t("permits.permitters.user")}: ${userFromBackend.name}`}
        >
          <BoxContent.HeaderActions>
            <RouterLinkButton
              appearance="transparent"
              state="edit-permitters"
              params={{ id: userFromBackend.id }}
            >
              <Tooltip
                id={t("permits.permitters.tooltips.edit")}
                backgroundColor="white"
                label={<Icon size="h1" appearance="white" icon="edit" />}
              >
                <Text>{t("permits.permitters.tooltips.edit")}</Text>
              </Tooltip>
            </RouterLinkButton>

            <Button appearance="transparent" onClick={() => deletePermission()}>
              <Tooltip
                id={t("permits.permitters.tooltips.delete")}
                backgroundColor="white"
                label={<Icon size="h1" appearance="white" icon="trash" />}
              >
                <Text>{t("permits.permitters.tooltips.delete")}</Text>
              </Tooltip>
            </Button>
          </BoxContent.HeaderActions>
        </BoxContent.Header>
        <BoxContent.Content>
          <List border>
            <Grid.Row>
              <Grid.Col>
                <List.Item justifyContent="space-between">
                  <Text>{t("permits.permitters.phone")}</Text>
                  {formatPhoneNumber(
                    userFromBackend?.phoneNumber,
                    userFromBackend?.phoneCountryCode || "us"
                  )}
                </List.Item>
                <List.Item justifyContent="space-between">
                  <Text>{t("permits.permitters.accessEndDate")}</Text>
                  {!userFromBackend.accessExpiresAt
                    ? "Never"
                    : t("defaultFormats.datetime", {
                        date: userFromBackend.accessExpiresAt,
                      })}
                </List.Item>
              </Grid.Col>
              <Grid.Col>
                <List.Item justifyContent="space-between">
                  <Text>{t("permits.permitters.identifier")}</Text>
                  <Text>{userFromBackend.identifier}</Text>
                </List.Item>
                <List.Item justifyContent="space-between"></List.Item>
              </Grid.Col>
            </Grid.Row>
          </List>
        </BoxContent.Content>
      </BoxContent>
      {permitsIssued.length > 0 && (
        <>
          <Text variant="h2" weight="300" compoent="h2" gutterBottom>
            {t("permits.permitters.permitsIssued")}
          </Text>
          <Table striped columns={columns} data={permits} />
        </>
      )}
    </Grid>
  );
};

export default User;
