"use strict";

import { useFormik } from "formik";

import onSubmit from "./onSubmit";
import initialValues from "./initialValues";
import validationSchema from "./validationSchema";

const useEventFormik = ({
  venues,
  mode,
  eventId,
  imageInputRef,
  trackLoading,
  untrackLoading,
}) => {
  return useFormik({
    initialValues: initialValues({ mode }),
    validationSchema: validationSchema(),
    onSubmit: onSubmit({
      venues,
      mode,
      eventId,
      imageInputRef,
      trackLoading,
      untrackLoading,
    }),
  });
};

export default useEventFormik;
