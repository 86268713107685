"use strict";
import React from "react";
import moment from "moment-timezone";
import CurrencyFormatter from "@citifyd/currency-formatter";
import {
  getCurrentLanguage,
  getTranslator,
} from "../../../../shared/services/languages";

export const formatValue = ({ value, currency }) => {
  const language = getCurrentLanguage();

  return CurrencyFormatter.format(value, {
    currency,
    language,
  });
};

export const displayDate = ({ date, format, user }) => {
  const t = getTranslator();

  const formats = {
    datetimeWithTimezone: "defaultFormats.datetimeWithTimezone",
    shortDatetime: "defaultFormats.shortDatetime",
    time: "defaultFormats.time",
    dateTime: "defaultFormats.datetime",
  };

  return t(formats[format], {
    date: moment.tz(date, user.timezoneName),
  });
};
