"use strict";

import React from "react";
import moment from "moment-timezone";

import CurrencyFormatter from "@citifyd/currency-formatter";
import HighlightText from "../../../../../shared/react/components/HighlightText";
import { formatPhoneNumber } from "../../../../../shared/utils/phone-number-formatting";
import Table from "../../../../../shared/react/components/Table";
import {
  getCurrentLanguage,
  getTranslator,
} from "../../../../../shared/services/languages";
import GuestBadge from "../../../../../shared/react/components/GuestBadge";
import { useTranslator, useService } from "../../../../../shared/react/hooks";
import { getTableColumns } from "./utils";
import styles from "../TransactionsReport.module.scss";
import HorizontalScrollable from "../../../../../shared/react/components/HorizontalScrollable";

const TransactionsTable = ({ transactionsData }) => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const $state = useService("$state");
  const language = getCurrentLanguage();
  const columns = getTableColumns();

  const onClickRedirection = (item) => {
    switch (item.parkingMethod) {
      case "RESERVATION":
        $state.go(
          "reservation",
          { id: item.parkingSessionId },
          { enableBackLink: true }
        );
        break;
      case "TICKET":
        $state.go(
          "ticket",
          { id: item.parkingSessionId },
          { enableBackLink: true }
        );
        break;
      case "RESERVED_PARKING":
        $state.go(
          "reserved-parking-pass",
          { id: item.parkingSessionId },
          { enableBackLink: true }
        );
        break;
      case "SUBSCRIPTION":
        $state.go(
          "subscription",
          { id: item.parkingSessionId },
          { enableBackLink: true }
        );
        break;

      case "BUNDLE":
        $state.go(
          "bundle-purchases",
          { id: item.parkingSessionId },
          { enableBackLink: true }
        );
        break;
      default:
    }
  };

  const data = transactionsData?.map(({ node: item }) => {
    return {
      ...{ onClick: () => onClickRedirection(item) },
      clickable: true,
      id: {
        value: item.parkingSessionId,
        verticalAlign: "middle",
      },
      transactionCreatedAt: {
        value: t("defaultFormats.numberDatetime", {
          date: moment(item.createdAt).tz(item.timezoneName),
        }),
        verticalAlign: "middle",
      },
      parkingMethod: {
        value: generatereservationTypeText(item.parkingMethod),
        verticalAlign: "middle",
      },
      startDateAndTime:
        item.parkingMethod === "BUNDLE"
          ? "—"
          : {
              value: t("defaultFormats.numberDatetime", {
                date: moment(item.startTime).tz(item.timezoneName),
              }),
              verticalAlign: "middle",
            },
      endDateAndTime:
        item.parkingMethod === "BUNDLE"
          ? "—"
          : {
              value: t("defaultFormats.numberDatetime", {
                date: moment(item.endTime).tz(item.timezoneName),
              }),
              verticalAlign: "middle",
            },
      timezone: {
        value: moment.tz(item.timezoneName).zoneName(),
        verticalAlign: "middle",
      },
      initialRate: {
        value: item.initialRate || "—",
        verticalAlign: "middle",
      },
      rate: {
        value: item.rate || "—",
        verticalAlign: "middle",
      },
      citifydFees: {
        value: CurrencyFormatter.format(item.citifydServiceFeeApplied, {
          currency: item.currency || "usd",
          language,
        }),
        verticalAlign: "middle",
      },
      amount: {
        value: CurrencyFormatter.format(item.amount, {
          currency: item.currency || "usd",
          language,
        }),
        verticalAlign: "middle",
      },

      procFees: {
        value: CurrencyFormatter.format(item.stripeAmount, {
          currency: item.currency || "usd",
          language,
        }),
        verticalAlign: "middle",
      },
      feeSub: {
        value: CurrencyFormatter.format(item.subsidiesPaid, {
          currency: item.currency || "usd",
          language,
        }),
        verticalAlign: "middle",
      },
      netRevenue: {
        value: CurrencyFormatter.format(item.externalAmount, {
          currency: item.currency || "usd",
          language,
        }),
        verticalAlign: "middle",
      },
      license: {
        value: item.license ? (
          <HighlightText
            flags="-i"
            text={item.license}
            highlight={$stateParams?.license}
          />
        ) : (
          "—"
        ),
        verticalAlign: "middle",
      },
      property: {
        value: item.lotName,
        verticalAlign: "middle",
      },
      state: {
        value: item.state,
        verticalAlign: "middle",
      },
      parker: {
        value: item.parkerName || <GuestBadge />,
        verticalAlign: "middle",
      },
      phoneNumber: {
        value:
          item.phoneNumber && item.phoneCountryCode
            ? formatPhoneNumber(item.phoneNumber, item.phoneCountryCode)
            : "—",
        verticalAlign: "middle",
      },
    };
  });

  return (
    <>
      <HorizontalScrollable>
        <Table
          striped
          columns={columns}
          data={data}
          responsive={false}
          className={styles.table}
        />
      </HorizontalScrollable>
    </>
  );
};

const generatereservationTypeText = (parkingMethod) => {
  const t = getTranslator();

  if (parkingMethod === "RESERVATION")
    return t("reports.transactionsTable.reservationTypeText.onDemandParking");

  if (parkingMethod === "TICKET")
    return t("reports.transactionsTable.reservationTypeText.eventParking");

  if (parkingMethod === "BUNDLE")
    return t(
      "reports.transactionsTable.reservationTypeText.eventParkingBundle"
    );

  if (parkingMethod === "RESERVED_PARKING")
    return t("reports.transactionsTable.reservationTypeText.reservedParking");

  if (parkingMethod === "SUBSCRIPTION")
    return t("reports.transactionsTable.reservationTypeText.monthlyParking");
};

export default TransactionsTable;
