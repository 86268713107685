"use strict";

import { useFormik } from "formik";
import * as yup from "yup";
import { createUser } from "../../shared/api";
import { useTranslator } from "../../shared/react/hooks";
import { getCurrentLanguage } from "../../shared/services/languages";

import { showErrorAlert } from "../../shared/services/helper";
import { useAuthentication } from "../../shared/react/contexts/authentication";

const useRegister = ({ setStep, setUser, setIsSubmitting }) => {
  const t = useTranslator();
  const { setToken } = useAuthentication();

  return useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
    },
    validateOnBlur: false,
    validationSchema: yup.object().shape({
      name: yup.string().required().min(3).max(100),
      email: yup.string().email().required(),
      password: yup.string().required(t("register.passwordIsRequired")),
      passwordConfirm: yup
        .string()
        .test(
          "passwords-match",
          t("register.passwordsMustMatch"),
          function (value) {
            return this.parent.password === value;
          }
        )
        .required(),
    }),

    onSubmit: async (values, actions) => {
      const data = {
        name: values.name,
        email: values.email,
        password: values.password,
        sessionType: "management",
        language: getCurrentLanguage(),
      };

      try {
        setIsSubmitting(true);
        const response = await createUser(data);

        setUser(response);
        await setToken(response.accessToken);

        setStep("terms");
      } catch (err) {
        const { param, message } = err?.data?.error ?? {};

        if (param === "email") {
          actions.setFieldError("email", message);
        } else {
          showErrorAlert(err.data);
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });
};

export default useRegister;
