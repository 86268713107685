import _ from "lodash";

import { calculateTicketRefundPreview } from "../../shared/api";

export const calculateRefund = async (ticketId, feeRetentionMethod) => {
  const operations = await calculateTicketRefundPreview(ticketId, {
    params: {
      feeRetentionMethod,
      refunder: "seller", // we must inform 'seller' independent of the real checkbox value in order to always show complimentary reversals
      refundAmount: "REVERSE_ALL",
    },
  });

  const transfersReversedAmount = _(operations)
    .filter((operation) =>
      _.includes(["transfer", "complimentary"], operation.transaction.type)
    )
    .map((operation) => operation.amountToReverse);

  const stripeProcessingFee = _(operations)
    .filter((operation) =>
      _.includes(["purchase", "payment"], operation.transaction.type)
    )
    .sum((operation) => operation.totals.stripeAmount);

  const isCashPayment = _.some(
    operations,
    (operation) => operation.transaction.isCashPayment
  );

  let refundDestinations = [];

  if (!isCashPayment) {
    refundDestinations = _(operations)
      .filter(function (operation) {
        return _.includes(["purchase", "payment"], operation.transaction.type);
      })
      .groupBy(function (operation) {
        return _.get(operation, "transaction.card.id");
      })
      .map(function (operationsPerCard) {
        return {
          card: operationsPerCard[0].transaction.card,
          amount: _.sum(operationsPerCard, "amountToReverse"),
        };
      })
      .value();
  }

  return {
    transfersReversedAmount,
    stripeProcessingFee,
    refundDestinations,
  };
};

export const calculateAmountToRefund = (refundDestinations) =>
  _.sum(refundDestinations, "amount");

export const getCanSeeCardExpiration = (refundDestinations) =>
  _.some(
    refundDestinations,
    (refundDestination) => refundDestination.card.expMonth
  );

export const calculateCanToggleStripeProcessingFeeRetention = (
  user,
  stripeProcessingFee
) => {
  return user.isAdmin && stripeProcessingFee > 0;
};

export const calculateOriginalAmount = (ticket) => {
  if (!ticket) {
    return 0;
  }

  return ticket.originalRate.fee + ticket.originalRate.value;
};
