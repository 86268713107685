"use strict";

import React, { useState } from "react";
import { Text } from "@citifyd/style";
import FormikProvider from "../../shared/react/components/FormikProvider";
import useSignupFromInvitation from "./useSignupFromInvitation.js";
import Form from "./components/Form/Form.jsx";
import styles from "./SignupFromInvitation.module.scss";
import { useService, useTranslator } from "../../shared/react/hooks";
import LoadingManager from "../../shared/react/components/LoadingManager";
import useLoadTracking from "../../shared/react/hooks/useLoadTracking";
import Hide from "../../shared/react/components/Hide";
import BoxContent from "../../shared/react/components/BoxContent";

const SignupFromInvitation = () => {
  const $stateParams = useService("$stateParams");
  const { isLoading, trackLoading, untrackLoading } = useLoadTracking({});
  const [hasError, setHasError] = useState(false);
  const invitationCode = $stateParams.code;

  const [isLinkExpired, setIsLinkExpired] = useState(false);

  const formikbag = useSignupFromInvitation({
    invitationCode,
    trackLoading,
    untrackLoading,
  });

  return (
    <>
      <FormikProvider value={formikbag}>
        {!isLinkExpired && (
          <Hide if={isLoading || hasError}>
            <BoxContent className={styles.container}>
              <BoxContent.Content>
                <Form
                  trackLoading={trackLoading}
                  untrackLoading={untrackLoading}
                  formikbag={formikbag}
                  invitationCode={invitationCode}
                  setIsLinkExpired={setIsLinkExpired}
                  setHasError={setHasError}
                />
              </BoxContent.Content>
            </BoxContent>
          </Hide>
        )}
        {isLinkExpired && <ErrorMessage />}
      </FormikProvider>

      {(isLoading || hasError) && (
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      )}
    </>
  );
};

const ErrorMessage = () => {
  const t = useTranslator();

  return (
    <div className={styles.container}>
      <Text gutterBottom textAlign="center" variant="h1" weight="300">
        {t("signupFromInvitation.expired")}
      </Text>
      <Text gutterBottom textAlign="center">
        {t("signupFromInvitation.expiredLink")}
      </Text>
      <Text gutterBottom textAlign="center">
        {t("signupFromInvitation.pleaseContact")}
      </Text>
    </div>
  );
};
export default SignupFromInvitation;
