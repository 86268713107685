import React, { useMemo } from "react";
import moment from "moment";

import { Button, Icon, Text } from "@citifyd/style";

import {
  AdminGetAccountPermissionUserInvitationsQuery,
  AdminGetAccountPermissionUserInvitationsQueryVariables,
  useAdminDeleteAccountPermissionUserInvitationMutation,
} from "../../../graphql/generated/graphql";

import Table from "../../../shared/react/components/Table";
import { Pagination } from "../Pagination/Pagination";

import ApolloClient from "../../../shared/services/apolloClient";
import { useTranslator } from "../../../shared/react/hooks";
import { usePagination } from "../Pagination/usePagination";
import { getService } from "../../../shared/react/utils";
import { useAuthentication } from "../../../shared/react/contexts/authentication";

import styles from "../AccountPermissions.module.scss";
import { transformPermissionsToFrontend } from "./transformPermissionsToFrontend";
import { usePermissionOptions } from "../../add-management-permission/usePermissionOptions";
import { ListToSeeMorePermissions } from "./ListToSeeMorePermissions";

const apolloClient = ApolloClient();

interface PendingPermissionInvitationsProps {
  loading: boolean;
  pendingPermissions?: AdminGetAccountPermissionUserInvitationsQuery["getAccountPermissionUserInvitations"];
  pageSize: number;
  refetch: (
    variables: AdminGetAccountPermissionUserInvitationsQueryVariables
  ) => any;
}

export const PendingInternalUserInvitations = ({
  pendingPermissions,
  refetch,
  loading,
  pageSize,
}: PendingPermissionInvitationsProps) => {
  const t = useTranslator();
  const { user } = useAuthentication();
  const permissionOptions = usePermissionOptions();

  const { hasNextPage, hasPreviousPage, nextPage, previousPage } =
    usePagination({
      data: pendingPermissions,
      pageSize,
      refetch,
    });

  const editPermission = (id) => {
    const $state = getService("$state");

    $state.go(
      "management-permission-edit",
      { id, pending: true },
      { enableBackLink: true }
    );
  };

  const [deletePermissionInternalUser] =
    useAdminDeleteAccountPermissionUserInvitationMutation({
      client: apolloClient,
    });

  const deleteInternalUser = async ({ inviteId }: { inviteId: number }) => {
    if (window.confirm(t("commonConfirmations.areYouSure"))) {
      await deletePermissionInternalUser({
        variables: {
          organizationId: user.organization.id,
          inviteId,
        },
      });
      refetch({
        first: pageSize,
        last: null,
        after: null,
        before: null,
      });
    }
  };

  const columns = [
    {
      value: t("accountPermissions.permissionInternalUser.name"),
      uppercase: false,
      key: "name",
      width: "20%",
    },
    {
      value: t("accountPermissions.permissionInternalUser.email"),
      uppercase: false,
      key: "email",
      width: "25%",
    },

    {
      value: t("accountPermissions.permissionInternalUser.permissions"),
      uppercase: false,
      key: "permissions",
      width: "25%",
    },

    {
      value: t("accountPermissions.permissionInternalUser.invitedAt"),
      uppercase: false,
      key: "invitedAt",
      width: "20%",
    },

    {
      value: t("accountPermissions.actions"),
      uppercase: false,
      key: "actions",
      width: "10%",
      textAlign: "right",
    },
  ];

  const data = useMemo(
    () =>
      pendingPermissions?.edges.map((edge) => {
        const permissions = transformPermissionsToFrontend({
          permissions: edge.node.permissions,
          permissionOptions,
        });

        return {
          ...edge.node,
          name: {
            value: edge.node.name,
          },
          email: {
            value: edge.node.email,
          },
          permissions: {
            value: <ListToSeeMorePermissions permissions={permissions} />,
          },
          invitedAt: {
            value: moment(edge.node.invitedAt).format("lll"),
          },
          actions: {
            textAlign: "right",
            value: (
              <>
                <Button
                  justifyContent="center"
                  appearance="transparent"
                  onClick={() => editPermission(edge.node.id)}
                >
                  <Icon icon="pencil-alt" />
                </Button>

                <Button
                  justifyContent="center"
                  appearance="transparent"
                  onClick={() => {
                    deleteInternalUser({ inviteId: edge.node.id });
                  }}
                >
                  <Icon icon="trash" />
                </Button>
              </>
            ),
          },
        };
      }),
    [pendingPermissions?.edges]
  );

  return (
    <div>
      <div className={styles.divisor}></div>
      <Text weight="300" variant="h5" gutterBottom>
        {t("accountPermissions.acceptanceInvitations")}
      </Text>

      <Table
        headerAppearance="transparent"
        spacing
        columns={columns}
        data={data}
        responsive
      />

      {(hasNextPage || hasPreviousPage) && (
        <Pagination
          loading={loading}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          nextPage={nextPage}
          previousPage={previousPage}
          data={pendingPermissions?.edges}
        />
      )}
    </div>
  );
};
