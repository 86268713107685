"use strict";
import { getTranslator } from "../services/languages";

export const localizeSystemGateName = (gate) => {
  const t = getTranslator();
  if (!gate.isSystemGate) {
    return gate.name;
  }

  const cleanName = gate.name.toLowerCase().replace(/ /g, "_");
  return t(`systemGates.${cleanName}`);
};
