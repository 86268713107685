import React from "react";
import moment from "moment-timezone";
import { trim, startCase } from "lodash";
import { getService } from "../../../../shared/react/utils";
import { getTranslator } from "../../../../shared/services/languages";

import GuestBadge from "../../../../shared/react/components/GuestBadge";
import HighlightText from "../../../../shared/react/components/HighlightText";
import Permissions from "../../../../shared/services/permissions";

const STATUS = ["ACTIVE", "PAST", "FUTURE"];
const PER_PAGE = 10;

export const getTableData = ({ reservedParkingPasses, permissions }) => {
  if (!reservedParkingPasses) return null;

  const t = getTranslator();
  const $state = getService("$state");
  const userMaySeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  const columns = [
    {
      key: "user",
      value: t("schedule.user"),
      width: "23%",
    },
    {
      key: "property",
      value: t("schedule.property"),
      width: "25%",
    },
    {
      key: "license",
      value: t("schedule.license"),
      width: "13%",
    },
    {
      key: "start",
      value: t("schedule.start"),
      width: "13%",
    },
    {
      key: "end",
      value: t("schedule.end"),
      width: "13%",
    },
    {
      key: "status",
      value: t("schedule.status"),
      width: "10%",
    },
  ];

  const data = reservedParkingPasses?.map(({ node: reservedParkingPass }) => ({
    onClick: () =>
      $state.go(
        "reserved-parking-pass",
        { id: reservedParkingPass.id },
        { enableBackLink: true }
      ),
    ...(userMaySeeUserInformation && {
      user: {
        value: !reservedParkingPass.user.isGuest ? (
          <HighlightText
            flags="-i"
            text={reservedParkingPass.user.name}
            highlight={$state?.params?.search}
          />
        ) : (
          <GuestBadge />
        ),
        verticalAlign: "middle",
      },
    }),
    property: reservedParkingPass.lot.name,
    license: (
      <HighlightText
        flags="-i"
        text={reservedParkingPass.vehicle.license}
        highlight={$state?.params?.search}
      />
    ),
    start: t("defaultFormats.datetime", {
      date: reservedParkingPass.startTime,
    }),
    end: t("defaultFormats.datetime", { date: reservedParkingPass.endTime }),
    status: startCase(reservedParkingPass.status?.toLowerCase()),
  }));

  return {
    columns,
    data,
  };
};

const getValidStatus = (status) =>
  STATUS.includes(status?.toUpperCase()) ? status?.toUpperCase() : null;

const getDefaultDate = (month) =>
  moment(new Date())[month]("month").format("YYYY-MM-DD");

const getValidDate = (date, month) =>
  moment(date).isValid()
    ? moment(date).format("YYYY-MM-DD")
    : getDefaultDate(month);

export const getFilterSelectionFromState = () => {
  const params = getService("$state")?.params;
  const parts = params.dateRange?.split(":");
  let start = parts && getValidDate(parts[0], "startOf");
  let end = parts && getValidDate(parts[1], "endOf");

  if (start && end && moment(start).isAfter(end)) {
    start = getDefaultDate("startOf");
    end = getDefaultDate("endOf");
  }

  const dateRange = { start, end };

  const status = getValidStatus(params.status);
  const search = params.search || null;

  return { status, search, dateRange };
};

export const selectDefaultFilters = ($state) => {
  const params = getService("$state")?.params;
  let changed = false;

  if (!params.dateRange) {
    const start = moment().startOf("month").format("YYYY-MM-DD");
    const end = moment().endOf("month").format("YYYY-MM-DD");
    params.dateRange = `${start}:${end}`;
    changed = true;
  }

  if (changed) {
    $state.transitionTo($state.current.name, params, {
      location: "replace",
      notify: false,
      inherit: true,
    });
  }
  return { changed };
};

export const generateEmptyListMessage = (selectedProperty) => {
  const t = getTranslator();
  const params = getService("$state")?.params;
  const { status, search } = params;
  const localeOptions = {};

  const validStatus = getValidStatus(status);

  let localeKey = `schedule.reservedParking.empty.${
    validStatus?.toLowerCase() || "default"
  }`;

  if (selectedProperty) {
    localeKey += "Lot";
    localeOptions.lotName = selectedProperty.name;
  }

  if (search) {
    localeKey += "Search";
    localeOptions.searchQuery = search;
  }

  return t(localeKey, localeOptions);
};

export const getFormattedVariables = ({ selectedProperty, currentFilter }) => ({
  lotId: selectedProperty?.id,
  startDate: moment(currentFilter.startDate).format("YYYY-MM-DD"),
  endDate: moment(currentFilter.endDate).format("YYYY-MM-DD"),
  first: PER_PAGE,
  searchTerm: trim(currentFilter.search),
  status: currentFilter.status?.toUpperCase(),
});
