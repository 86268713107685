import { AdminGetPermitPortalQuery } from "../../../graphql/generated/graphql";
import { DaysOfWeek, Hours, PermitType, FormValues, FormLot } from "../types";
import {
  END_TIME,
  IS_MIDNIGHT,
  START_TIME,
  END_TIME_WHEN_OPEN_ALL_DAY,
} from "../constants";

const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"] as const;

export const transformToFrontend = (
  portalData: AdminGetPermitPortalQuery["admin_getPermitPortal"]
): FormValues => {
  if (portalData) {
    const selectedLots: FormLot[] = [];

    selectedLots.push(
      ...portalData.lots!.map(({ lot }) => ({
        value: lot.id,
        label: lot.name,
      }))
    );
    const { name, namespace, issuanceSettings, lots } = portalData;

    return {
      property: name,
      urlPortal: namespace,
      hasImage: true,
      maximumLimitOfFuturePermits: issuanceSettings.maxFuturePermits ?? "",
      unlimitedMaximumLimitOfFuturePermits:
        issuanceSettings.maxFuturePermits === null,
      maximumPermitLength: issuanceSettings.maxHours ?? "",
      unlimitedMaximumPermitLength: issuanceSettings.maxHours === null,
      properties: selectedLots,
      permitIssuePeriod: issuanceSettings.maxPeriodDuration,

      propertyConfiguration: lots!.map(({ lot, issuanceSettings }) => ({
        propertyId: lot.id,
        propertyName: lot.name,

        daysOfWeek: Object.fromEntries(
          daysOfWeek.map((day) => [
            day,
            Boolean(issuanceSettings.weeklyAvailability?.[day]),
          ])
        ) as DaysOfWeek,

        hours: formattedHours({ issuanceSettings }),

        permitType:
          issuanceSettings.permitChargeType.toLowerCase() as PermitType,
        concurrentPermitsLimit: {
          quantity: issuanceSettings.maxConcurrentPermits ?? "",
          noLimit: !issuanceSettings.maxConcurrentPermits,
        },
        permitsMonthlyLimit: {
          quantity: issuanceSettings.monthlyMax ?? "",
          noLimit: !issuanceSettings.monthlyMax,
        },
        permitsDailyLimit: {
          quantity: issuanceSettings.dailyMax ?? "",
          noLimit: !issuanceSettings.dailyMax,
        },
        amountOfFreePermits: issuanceSettings.mixedPermitsFreeLimit ?? "",
        blockedDates: issuanceSettings.blockedDates ?? [],
      })),
    };
  } else {
    return {
      property: "",
      urlPortal: "",
      hasImage: false,
      permitIssuePeriod: "",
      maximumLimitOfFuturePermits: "",
      unlimitedMaximumLimitOfFuturePermits: false,
      maximumPermitLength: "",
      unlimitedMaximumPermitLength: false,
      properties: [],
      propertyConfiguration: [],
    };
  }
};

const formattedHours = ({
  issuanceSettings,
}: {
  issuanceSettings: NonNullable<
    AdminGetPermitPortalQuery["admin_getPermitPortal"]["lots"]
  >[number]["issuanceSettings"];
}) => {
  return Object.fromEntries(
    daysOfWeek.map((day) => {
      const hours = issuanceSettings?.weeklyAvailability[day];

      if (hours) {
        const isOpenAllDay =
          hours.startTime.substr(0, 5) === IS_MIDNIGHT &&
          hours.endTime.substr(0, 12) === END_TIME_WHEN_OPEN_ALL_DAY;

        const start = isOpenAllDay ? IS_MIDNIGHT : hours.startTime.substr(0, 5);
        const end = isOpenAllDay ? IS_MIDNIGHT : hours.endTime.substr(0, 5);

        return [
          day,
          {
            start,
            end,
            openAllDay: isOpenAllDay,
          },
        ];
      } else {
        return [day, { start: START_TIME, end: END_TIME, openAllDay: false }];
      }
    })
  ) as Hours;
};
