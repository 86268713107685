import { getCurrentLanguage } from "../services/languages";

export const currencyFormatter = ({
  value,
  currency = "usd",
}: {
  value: number;
  currency?: string;
}) => {
  const language = getCurrentLanguage();

  const formattedValue = new Intl.NumberFormat(language, {
    style: "currency",
    currency,
  }).format(value / 100);

  return formattedValue;
};
