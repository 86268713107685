"use strict";

import { localDateAndTimeToIsoString } from "../../../../shared/react/dateUtils";
import { getService } from "../../../../shared/react/utils";
import { createEvent, updateEvent } from "../../../../shared/api";
import { uploadImage as upload } from "../../../../shared/services/helper";

import Mode from "../../mode";

const onSubmit = ({
  venues,
  mode,
  eventId,
  imageInputRef,
  trackLoading,
  untrackLoading,
}) => {
  return async (values) => {
    const $state = getService("$state");

    const venue = venues.find((venue) => venue.id === Number(values.venueId));

    const event = {
      name: values.name,
      venueId: Number(values.venueId),
      start: localDateAndTimeToIsoString(
        values.startDate,
        values.startTime,
        venue.timezoneName
      ),
      timezoneName: venue.timezoneName,
      waitlistEnabled: values.waitlist === "true",
      eventTypeCode: values.eventType,
    };

    if (mode === Mode.ADD) {
      event.availabilityDefaultStartTime = localDateAndTimeToIsoString(
        values.defaultAvailabilityStartDate,
        values.defaultAvailabilityStartTime,
        venue.timezoneName
      );
      event.availabilityDefaultEndTime = localDateAndTimeToIsoString(
        values.defaultAvailabilityEndDate,
        values.defaultAvailabilityEndTime,
        venue.timezoneName
      );
      trackLoading("createEvent");
      const response = await createEvent({ event });
      eventId = response.id;
      untrackLoading("createEvent");
    } else {
      trackLoading("updateEvent");
      await updateEvent(eventId, { event });
      untrackLoading("updateEvent");
    }

    await uploadImage({
      eventId,
      imageInput: imageInputRef.current,
      trackLoading,
      untrackLoading,
    });

    $state.go(
      "edit-event-properties",
      { eventId },
      { location: mode === Mode.ADD ? "replace" : true }
    );
  };
};

const uploadImage = async ({
  eventId,
  imageInput,
  trackLoading,
  untrackLoading,
}) => {
  if (imageInput.files.length === 0) {
    return;
  }

  trackLoading("uploadImage");

  const { cloudName, publicId, format } = await upload(
    imageInput.form,
    "events"
  );

  await updateEvent(eventId, {
    event: {
      image: {
        mode: "cloudinary",
        cloudinary: {
          cloudName,
          publicId,
          format,
          defaultTransformation: "t_event_image",
        },
      },
    },
  });

  untrackLoading("uploadImage");
};

export default onSubmit;
