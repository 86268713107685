"use strict";
import React, { useState, useEffect, useMemo } from "react";
import { Grid, Text } from "@citifyd/style";
import { useTranslator } from "../../hooks";
import { getService } from "../../utils";
import RouterLink from "../RouterLink";
import Permissions from "../../../services/permissions";
import { getAllProperties } from "../../../api";

import styles from "./NavMenu.module.scss";
import classNames from "classnames";
import { useAuthentication } from "../../contexts/authentication";

export default function NavMenu({ openMenu, loggedUser }) {
  const t = useTranslator();
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);
  const [properties, setProperties] = useState();
  const $rootScope = getService("$rootScope");
  const { permissions } = useAuthentication();

  useEffect(() => {
    getAllProperties().then((response) => setProperties(response));
  }, [loggedUser]);

  $rootScope.$on("$stateChangeSuccess", ({ location }) => {
    setCurrentRoute(location.pathname);
  });

  const Link = ({ children, state }) => (
    <RouterLink state={state}>
      <Text>{children}</Text>
    </RouterLink>
  );

  const MenuItem = useMemo(
    () =>
      ({ children, item }) => {
        const regex = new RegExp("^/" + (item || "") + "$");

        return (
          <li
            className={classNames({
              [styles.active]: regex.test(currentRoute),
            })}
          >
            {children}
          </li>
        );
      },
    [currentRoute]
  );

  if (loggedUser?.role === "localManager") {
    return (
      <div
        className={classNames(styles.navWrap, { [styles.openMenu]: openMenu })}
      >
        <Grid>
          <Grid.Row nogutter>
            <Grid.Col xs={12}>
              <div className={styles.nav}>
                <ul className={styles.links}>
                  <MenuItem item="">
                    <Link state="dashboard">
                      {t("header.siteNav.snapshot")}
                    </Link>
                  </MenuItem>
                  <MenuItem item="(all-propertie(s.*|properties.*)">
                    <Link state="all-properties">
                      {t("header.siteNav.properties")}
                    </Link>
                  </MenuItem>
                </ul>
              </div>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  if (loggedUser?.role !== "localManager") {
    return (
      <div
        className={classNames(styles.navWrap, { [styles.openMenu]: openMenu })}
      >
        <Grid>
          <Grid.Row nogutter>
            <Grid.Col>
              <div className={styles.nav}>
                <ul className={styles.links}>
                  {Permissions.userMayAccessSnapshots(permissions) && (
                    <MenuItem item="">
                      <Link state="dashboard">
                        {t("header.siteNav.snapshot")}
                      </Link>
                    </MenuItem>
                  )}
                  {Permissions.userMayAccessSchedule(permissions) && (
                    <MenuItem item="(schedule.*|events|events\/[0-9]+.*)">
                      <Link state="schedule">
                        {t("header.siteNav.schedule")}
                      </Link>
                    </MenuItem>
                  )}
                  {Permissions.userMayAppendLotToEvent({
                    permissions,
                    hasEventCreationAccess:
                      loggedUser?.organization?.hasEventCreationAccess,
                    user: loggedUser,
                  }) && (
                    <MenuItem item="append-to-event">
                      <Link state="appendable-events">
                        {t("header.siteNav.addEvent")}
                      </Link>
                    </MenuItem>
                  )}
                  {Permissions.userMayCreateEvent({
                    permissions,
                    hasEventCreationAccess:
                      loggedUser?.organization?.hasEventCreationAccess,
                    user: loggedUser,
                  }) && (
                    <MenuItem item="events\/new">
                      <Link state="add-event">
                        {t("header.siteNav.addEvent")}
                      </Link>
                    </MenuItem>
                  )}
                  {Permissions.userMayManageAllVenues({
                    permissions,
                  }) && (
                    <MenuItem item="venues.*">
                      <Link state="venues">Venues</Link>
                    </MenuItem>
                  )}
                  {properties?.length > 0 &&
                    Permissions.userMayAccessReports(permissions) && (
                      <MenuItem item="reports.*">
                        <Link state="reports">
                          {t("header.siteNav.reports")}
                        </Link>
                      </MenuItem>
                    )}
                  {Permissions.userMaySeeUserInformation(permissions) && (
                    <MenuItem item="users.*">
                      <Link state="users">{t("header.siteNav.parkers")}</Link>
                    </MenuItem>
                  )}
                  {Permissions.userMaySeePermits(permissions) && (
                    <MenuItem item="(permits.*|permit.*)">
                      <Link state="permits">{t("header.siteNav.permits")}</Link>
                    </MenuItem>
                  )}

                  {Permissions.userMayAccessRover(permissions, loggedUser) && (
                    <MenuItem item="rover.*">
                      <Link state="rover"> {t("header.siteNav.rover")}</Link>
                    </MenuItem>
                  )}

                  {Permissions.userMaySeeAllProperties(permissions) && (
                    <MenuItem item="all-properties.*">
                      <Link state="all-properties">
                        {t("header.siteNav.propertiesAdmin")}
                      </Link>
                    </MenuItem>
                  )}

                  {Permissions.userMayManageReferralCodes(permissions) && (
                    <MenuItem item="referral-codes">
                      <Link state="referral-codes">
                        {t("header.siteNav.referralCodesAdmin")}
                      </Link>
                    </MenuItem>
                  )}
                </ul>

                <ul className={styles.account}>
                  <MenuItem item="account.*">
                    <Link state="account"> {t("header.siteNav.account")}</Link>
                  </MenuItem>

                  {Permissions.userMaySeeLots(permissions) && (
                    <MenuItem item="properties.*">
                      <Link state="properties">
                        {t("header.siteNav.properties")}
                      </Link>
                    </MenuItem>
                  )}
                </ul>
              </div>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
