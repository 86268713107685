"use strict";

import React from "react";

import { useTranslator } from "../../hooks";
import CardIcon from "../CardIcon";

export default function PaymentMethod({ card, isCashPayment = false }) {
  const t = useTranslator();

  if (isCashPayment) {
    return t("transaction.cash");
  }

  if (card) {
    return (
      <>
        <CardIcon brand={card.brand} />{" "}
        {card.tokenizationMethod &&
          t(
            `transaction.tokenizationMethods.${card.tokenizationMethod.toLowerCase()}.using`
          )}
        {!card.tokenizationMethod && `•••• ${card.last4}`}
      </>
    );
  }

  return "—";
}
