"use strict";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Text } from "@citifyd/style";

import AdvancedRateDefinitionStatusBadge from "../../../shared/react/components/AdvancedRateDefinitionStatusBadge";
import RouterLink from "../../../shared/react/components/RouterLink";
import StickyFooter from "../../../shared/react/components/StickyFooter";

import { patchState } from "../redux/actions";
import { saveRateDefinition } from "../redux/thunk-actions";

import { useTranslator } from "../../../shared/react/hooks";

import styles from "../styles/Page.module.scss";
import { useAuthentication } from "../../../shared/react/contexts/authentication";
import { goToNextStep } from "../../add-property/components/goToNextStep";
import { lotSetup } from "../utils/lotSetup";

export default function Footer() {
  const t = useTranslator();

  const dispatch = useDispatch();
  const { user } = useAuthentication();
  const isLoading = useSelector((state) => state.isLoading);
  const lotId = useSelector((state) => state.lot.id);
  const advancedRateDefinitionEnabled = useSelector(
    (state) => state.lot.advancedRateDefinitionEnabled
  );
  const rateDefinition = useSelector((state) => state.rateDefinition);
  const editEnabled = useSelector((state) => state.editEnabled);
  const hasUnsavedRules = useSelector((state) => state.hasUnsavedRules);
  const isLotSetup = lotSetup();

  const switchingFromSimpleRates = !advancedRateDefinitionEnabled;

  const canSave = hasUnsavedRules || switchingFromSimpleRates;
  const canPublish =
    hasUnsavedRules ||
    rateDefinition.status !== "published" ||
    switchingFromSimpleRates;

  const disableIfLoading =
    (fn) =>
    (...args) =>
      !isLoading && fn(...args);

  const save = disableIfLoading(() => {
    if (
      rateDefinition.isLatestVersion ||
      window.confirm(
        t("advancedRateEditor.messages.saveFromPastVersionConfirmation")
      )
    ) {
      dispatch(saveRateDefinition({ publish: false, user }));
    }
  });

  const publish = disableIfLoading(() => {
    if (window.confirm(t("advancedRateEditor.messages.publishConfirmation"))) {
      dispatch(saveRateDefinition({ publish: true, user }));
    }
  });

  const enableEditingOfPastVersion = disableIfLoading(() => {
    dispatch(
      patchState({
        editEnabled: true,
        hasUnsavedRules: true,
      })
    );
  });

  if (!rateDefinition) {
    return null;
  }

  return (
    <StickyFooter
      isBlocked={isLoading}
      justifyContent={rateDefinition.updatedAt ? "space-between" : "flex-end"}
    >
      <VersionInfo />

      {editEnabled && (
        <div>
          <Button extraPadding uppercase onMouseDown={save} disabled={!canSave}>
            {t("commonButtons.saveAsDraft")}
          </Button>
          <Button
            extraPadding
            uppercase
            onMouseDown={publish}
            disabled={!canPublish}
          >
            {t("commonButtons.saveAndPublish")}
          </Button>

          {isLotSetup && !switchingFromSimpleRates && (
            <Button
              extraPadding
              uppercase
              onMouseDown={() => goToNextStep({ lotId, step: 4 })}
            >
              {t("commonButtons.continue")}
            </Button>
          )}
        </div>
      )}

      {!editEnabled && !rateDefinition.isLatestVersion && (
        <Button extraPadding uppercase onMouseDown={enableEditingOfPastVersion}>
          {t("advancedRateEditor.buttons.createCopyOfVersion")}
        </Button>
      )}
    </StickyFooter>
  );
}

function VersionInfo() {
  const t = useTranslator();

  const lotId = useSelector((state) => state.lot.id);
  const rateDefinition = useSelector((state) => state.rateDefinition);
  const hasUnsavedRules = useSelector((state) => state.hasUnsavedRules);
  const editEnabled = useSelector((state) => state.editEnabled);

  const versionNumber =
    rateDefinition.status === "published" && hasUnsavedRules
      ? rateDefinition.versionNumber + 1
      : rateDefinition.versionNumber;

  const versionLabel =
    rateDefinition.status === "past_version" && editEnabled
      ? t("advancedRateEditor.versionInfo.copyOfVersionNumber", {
          number: versionNumber,
        })
      : t("advancedRateEditor.versionInfo.versionNumber", {
          number: versionNumber,
        });

  // If no rate definition has been saved so far, the user is creating the first version.
  // In this case, do not show version history information.
  if (!rateDefinition.updatedAt) {
    return null;
  }

  return (
    <div>
      <div className={styles.versionInfo}>
        <Text component="span" appearance="white">
          {versionLabel}
        </Text>
        <span className={styles.versionInfoBagde}>
          <AdvancedRateDefinitionStatusBadge
            status={hasUnsavedRules ? "unsaved" : rateDefinition.status}
          />
        </span>
      </div>
      <div>
        <Text component="span" appearance="white">
          {t("advancedRateEditor.versionInfo.lastSaved", {
            date: rateDefinition.updatedAt,
          })}{" "}
        </Text>
        <RouterLink
          state="list-property-advanced-rates"
          params={{ lotId }}
          options={{ enableBackLink: true }}
          appearance="white"
        >
          {t("advancedRateEditor.versionInfo.viewOtherVersions")}
        </RouterLink>
      </div>
    </div>
  );
}
