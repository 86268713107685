"use strict";

import React, { useState } from "react";
import { useFormikContext } from "formik";
import { Text, Button } from "@citifyd/style";
import { find } from "lodash";

import StickyFooter from "../../../../shared/react/components/StickyFooter";
import Table from "../../../../shared/react/components/Table";
import PageHeader from "../../../../shared/react/components/PageHeader";
import { useTranslator } from "../../../../shared/react/hooks";
import LotsSelector from "../../../../shared/react/components/LotsSelector";
import {
  groupEventsByRate,
  addSelectedBundleId,
  hasMultipleCurrencies,
  getEventsWithSelectedLotIds,
  confirmLotSelections,
} from "../../../../shared/react/components/LotsSelector/utils.js";
import styles from "./UserPurchaseTicketLot.module.scss";

const UserPurchaseTicketLot = ({ setStep }) => {
  const t = useTranslator();
  const { setFieldValue, values } = useFormikContext();
  const { selectedEvents, selectedBundles } = values;
  const [eventsByRate, setEventsByRate] = useState(
    groupEventsByRate(selectedEvents)
  );
  const [bundles, setBundles] = useState(addSelectedBundleId(selectedBundles));

  const handleSelectEventLot = ({ group, lot }) => {
    const currentLot = find(group?.events[0].lots, { id: lot?.id });
    if (currentLot.soldOut) return;

    const eventsByRateUpdated = eventsByRate.map((item) => {
      if (group === item) {
        item.selectedLotId = lot?.id;
      }

      return item;
    });

    const eventsWithSelectedLotIds =
      getEventsWithSelectedLotIds(eventsByRateUpdated);

    setFieldValue("selectedEvents", _.union(...eventsWithSelectedLotIds));
    setEventsByRate(eventsByRateUpdated);
  };

  const handleSelectBundleLot = ({ group, bundle }) => {
    const bundlesUpdated = bundles.map((item) => {
      if (group === item) {
        item.selectedBundleId = bundle?.id;
      }

      return item;
    });

    setFieldValue("selectedBundles", selectedBundles);
    setBundles(bundlesUpdated);
  };

  const onNextStepClick = () => {
    const data = {
      bundles: selectedBundles,
      events: selectedEvents,
    };
    if (!confirmLotSelections(data)) {
      return false;
    }

    if (!hasMultipleCurrencies(data)) {
      setStep(3);
    }
  };

  return (
    <>
      <PageHeader>
        <PageHeader.Title
          onClick={() => setStep(1)}
          weight="300"
          title={t("userPurchaseTicket.assign")}
        />
      </PageHeader>
      <LotsSelector
        bundles={bundles}
        handleSelectBundleLot={handleSelectBundleLot}
        eventsByRate={eventsByRate}
        handleSelectEventLot={handleSelectEventLot}
      />
      <StickyFooter justifyContent="space-between">
        <Text appearance="white" className={styles.allPrices}>
          {t("userPurchaseTicket.allPrices")}
        </Text>
        <Button uppercase onMouseDown={() => onNextStepClick()} extraPadding>
          {t("commonButtons.continue")}
        </Button>
      </StickyFooter>
    </>
  );
};

export default UserPurchaseTicketLot;
