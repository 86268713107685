import { useFormik } from "formik";
import * as yup from "yup";
import { isEmpty } from "lodash";
import { showErrorAlert } from "../../../../shared/services/helper";
import { createProperty, updateProperty } from "../../../../shared/api";
import { useTranslator } from "../../../../shared/react/hooks";
import { goToNextStep } from "../goToNextStep";

export const useParkingConfiguration = ({
  lot,
  mode,
  trackLoading,
  untrackLoading,
}) => {
  const t = useTranslator();

  const initialValues = getInitialValues(lot);

  return useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      general: yup.object().shape({
        customLotUnavailableMessage: yup.string().required(),
      }),
      eventParking: yup.object().shape({
        additionalNotesAboutTheLotForEventParking: yup
          .string()
          .when(["additionalNotesAboutTheLotForEventParkingEnabled"], {
            is: (additionalNotesAboutTheLotForEventParkingEnabled) =>
              additionalNotesAboutTheLotForEventParkingEnabled,
            then: yup.string().required(),
          }),
      }),
      hourlyParking: yup.object().shape({
        informationSentToParkerAfterPurchase: yup
          .string()
          .nullable()
          .when(["informationSentToParkerAfterPurchaseEnabled"], {
            is: (informationSentToParkerAfterPurchaseEnabled) =>
              informationSentToParkerAfterPurchaseEnabled,
            then: yup.string().required(),
          }),

        gracePeriodTimeAmount: yup.string().when(["enabled"], {
          is: (enabled) => enabled,
          then: yup.string().required(),
        }),
        smsCode: yup.string(),
      }),
      reservedParking: yup.object().shape({
        additionalNotesInformation: yup
          .string()
          .when(["additionalNotesInformationEnabled"], {
            is: (additionalNotesInformationEnabled) =>
              additionalNotesInformationEnabled,
            then: yup.string().required(),
          }),
      }),
    }),
    onSubmit: async (values, actions) => {
      const { general, hourlyParking, reservedParking, eventParking } = values;

      const input = {
        ...lot,
        extraInfo: { hasPhysicalGates: general.hasPhysicalGates },
        notifyParkingSessions: general.notifyWhenSomeoneParking,
        unavailableMessage: general.customLotUnavailableMessage,
        eventParkingEnabled: eventParking.enabled,
        requiresVehicleSelection: eventParking.requiresVehicleSelection,
        notifyAboutEvents: eventParking.notifyEventsNearThisProperty,
        disableReservedDuringEvents:
          eventParking.disableReservedParkingDuringEvent,
        disableOnDemandDuringEvents: eventParking.disableHourlyParking,
        eventParkingAdditionalNotes:
          eventParking.additionalNotesAboutTheLotForEventParking,
        onDemandParkingEnabled: hourlyParking.enabled,
        onDemandSpacesGuaranteed: hourlyParking.spacesGuaranteed,
        onDemandExtensionsEnabled: hourlyParking.sessionExtensionsEnabled,
        onDemandExpirationReminderEnabled:
          hourlyParking.expirationRemindersEnabled,
        onDemandRequiresTimeSelection: hourlyParking.requiresTimeSelection,
        onDemandGracePeriod: Number(hourlyParking.gracePeriodTimeAmount),
        privateInfo: hourlyParking.informationSentToParkerAfterPurchase,
        smsCode: hourlyParking.smsCode,
        reservedParkingEnabled: reservedParking.enabled,
        reservedParkingAdditionalNotes:
          reservedParking.additionalNotesInformation,
        monthlyParkingEnabled: values.monthlyParking,
      };

      try {
        if (mode === "add") {
          trackLoading("createLot");
          await createProperty(input);
        } else {
          trackLoading("updateLot");
          await updateProperty(input);
        }

        goToNextStep({ lotId: lot.id, step: 3 });
      } catch (response) {
        untrackLoading("createLot");
        untrackLoading("updateLot");

        if (response.data.error.param === "smsCode") {
          actions.setFieldError(
            "hourlyParking.smsCode",
            t("addProperty.hourlyParking.invalidSmsCode")
          );
        } else {
          showErrorAlert(response.data);
        }
      }
    },
  });
};

const getInitialValues = (lot) => {
  if (!isEmpty(lot)) {
    return {
      general: {
        hasPhysicalGates: lot.extraInfo.hasPhysicalGates,
        notifyWhenSomeoneParking: lot.notifyParkingSessions,
        customLotUnavailableMessage: lot.unavailableMessage,
      },
      eventParking: {
        enabled: lot.eventParkingEnabled,
        requiresVehicleSelection: lot.requiresVehicleSelection,
        notifyEventsNearThisProperty: lot.notifyAboutEvents,
        disableReservedParkingDuringEvent: lot.disableReservedDuringEvents,
        disableHourlyParking: lot.disableOnDemandDuringEvents,
        additionalNotesAboutTheLotForEventParkingEnabled: Boolean(
          lot.eventParkingAdditionalNotes
        ),
        additionalNotesAboutTheLotForEventParking:
          lot.eventParkingAdditionalNotes,
      },
      hourlyParking: {
        enabled: lot.onDemandParkingEnabled,
        spacesGuaranteed: lot.onDemandSpacesGuaranteed,
        sessionExtensionsEnabled: lot.onDemandExtensionsEnabled,
        expirationRemindersEnabled: lot.onDemandExpirationReminderEnabled,
        requiresTimeSelection: lot.onDemandRequiresTimeSelection,
        informationSentToParkerAfterPurchase: lot.privateInfo,
        informationSentToParkerAfterPurchaseEnabled: Boolean(lot.privateInfo),
        gracePeriodTimeAmount: lot.onDemandGracePeriod,
        smsCode: lot.lotPhoneNumbers[0]?.code ?? "",
      },

      reservedParking: {
        enabled: lot.reservedParkingEnabled,
        additionalNotesInformationEnabled: Boolean(
          lot.reservedParkingAdditionalNotes
        ),
        additionalNotesInformation: lot.reservedParkingAdditionalNotes,
      },
      monthlyParking: lot.monthlyParkingEnabled,
    };
  }

  return {
    general: {
      hasPhysicalGates: false,
      notifyWhenSomeoneParking: false,
      unavailableMessage: "",
    },
    eventParking: {
      enabled: false,
      requiresVehicleSelection: false,
      notifyEventsNearThisProperty: false,
      disableHourlyParking: false,
      disableReservedParkingDuringEvent: false,
      additionalNotesAboutTheLotForEventParkingEnabled: false,
      additionalNotesAboutTheLotForEventParking: "",
    },
    hourlyParking: {
      enabled: false,
      spacesGuaranteed: false,
      sessionExtensionsEnabled: false,
      expirationRemindersEnabled: false,
      requiresTimeSelection: false,
      informationSentToParkerAfterPurchaseEnabled: false,
      informationSentToParkerAfterPurchase: "",
      gracePeriodTimeAmount: "",
      smsCode: "",
    },
    reservedParking: {
      enabled: false,
      additionalNotesInformationEnabled: false,
      additionalNotesInformation: "",
    },
    monthlyParking: false,
  };
};
