import { mapValues } from "lodash";
import moment from "moment-timezone";
import { Lot } from "../types";
import { IS_MIDNIGHT, END_TIME_WHEN_OPEN_ALL_DAY } from "../constants";
import { FormValues } from "../types";
import {
  CreatePermitPortalInput,
  UpdatePermitPortalInput,
  PermitPortalIssuanceSettingsPermitChargeType,
} from "../../../graphql/generated/graphql";

export const transformToBackend = ({
  logo,
  lots,
  values,
}: {
  logo: any;
  lots: Lot[];
  values: FormValues;
}): CreatePermitPortalInput | UpdatePermitPortalInput => {
  const lotsData = values.properties.map((property) => {
    const lotId = property.value;

    const currentLot = lots.find((l) => l.id === lotId);
    const timezoneName = currentLot?.timezoneName || "America/Los_Angeles";
    const configuration = values?.propertyConfiguration?.find(
      (config) => config?.propertyId === lotId
    );

    const {
      blockedDates,
      permitType,
      permitsMonthlyLimit,
      permitsDailyLimit,
      concurrentPermitsLimit,
      amountOfFreePermits,
      daysOfWeek,
      hours,
    } = configuration!;

    return {
      lotId,
      issuanceSettings: {
        blockedDates,
        permitChargeType:
          permitType.toUpperCase() as PermitPortalIssuanceSettingsPermitChargeType,
        monthlyMax: formatQuantity({ value: permitsMonthlyLimit }),
        dailyMax: formatQuantity({ value: permitsDailyLimit }),
        maxConcurrentPermits: formatQuantity({ value: concurrentPermitsLimit }),

        mixedPermitsFreeLimit:
          permitType === "mixed" && amountOfFreePermits !== ""
            ? amountOfFreePermits
            : null,
        weeklyAvailability: mapValues(daysOfWeek, (selected, day) => {
          const currentDay = hours[day];
          const { openAllDay, start, end } = currentDay;

          let endTime = "";

          if (openAllDay) {
            endTime = moment
              .tz(END_TIME_WHEN_OPEN_ALL_DAY, "HH:mm:ss.SSS", timezoneName)
              .format("HH:mm:ss.SSSZ");
          } else {
            endTime = moment.tz(end, "HH:mm", timezoneName).format("HH:mm:00Z");
          }

          return selected
            ? {
                startTime: moment
                  .tz(openAllDay ? IS_MIDNIGHT : start, "HH:mm", timezoneName)
                  .format("HH:mm:00Z"),

                endTime,
              }
            : null;
        }),
      },
    };
  });

  const data = {
    name: values.property,
    namespace: values.urlPortal,
    issuanceSettings: {
      maxHours: formatPermit({
        unlimited: values.unlimitedMaximumPermitLength,
        maximum: values.maximumPermitLength,
      }),

      maxFuturePermits: formatPermit({
        unlimited: values.unlimitedMaximumLimitOfFuturePermits,
        maximum: values.maximumLimitOfFuturePermits,
      }),

      maxPeriodDuration: values.permitIssuePeriod,
    },
    lots: lotsData,
    logo,
  };

  return data;
};

const formatQuantity = ({
  value,
}: {
  value: {
    noLimit: boolean;
    quantity: number | "";
  };
}) => {
  return value.noLimit || value.quantity === "" ? null : value.quantity;
};

const formatPermit = ({
  unlimited,
  maximum,
}: {
  unlimited: boolean;
  maximum: number | "";
}) => {
  return unlimited || maximum === "" ? null : maximum;
};
