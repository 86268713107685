"use strict";

import acceptLanguageParser from "accept-language-parser";

export const DEFAULT_LANGUAGE = "en-US";

export const DEFAULT_LANGUAGE_REGIONS = {
  en: "US",
};

export const getAvailableLanguages = (() => {
  let cachedAvailableLanguages = null;

  return function getAvailableLanguages() {
    if (!cachedAvailableLanguages) {
      const localeFiles = ["en-US", "ja-JP", "pt-BR"];

      cachedAvailableLanguages = localeFiles.map((language) => {
        const [code, region] = language.split("-");
        return { code: code.toLowerCase(), region: region.toUpperCase() };
      });
    }

    return cachedAvailableLanguages;
  };
})();

export function getAcceptedLanguage(input) {
  const languages = getAvailableLanguages();
  const list = acceptLanguageParser.parse(input);

  let bestMatch = null;

  for (const item of list) {
    const code = item.code.toLowerCase();
    const region = item.region && item.region.toUpperCase();

    // First, try to find the language by specific region.
    // e.g. if input = en-US, then only en-US of available languages will match.
    let match = languages.find(
      (availableLanguage) =>
        code === availableLanguage.code && region === availableLanguage.region
    );

    // If it's not found, check if a language exists with this code (e.g. `en`), but with a different region.
    // e.g. if input = en or input = en-CA, then it will match en-US.
    // First, it tries to find the default region for the given language from the DEFAULT_REGIONS constant.
    // If there's no default region defined for a given language, take the first one on the list of available
    // languages.
    if (!match) {
      if (DEFAULT_LANGUAGE_REGIONS[code]) {
        match = { code, region: DEFAULT_LANGUAGE_REGIONS[code].toUpperCase() };
      } else {
        match =
          match ||
          languages.find(
            (availableLanguage) => code === availableLanguage.code
          );
      }
    }

    if (match) {
      bestMatch = `${match.code}-${match.region}`;
      break;
    }
  }

  return bestMatch || DEFAULT_LANGUAGE;
}
