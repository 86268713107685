import { getTranslator } from "../../../shared/services/languages";

const getPendingRequiredInformationContent = (stripeConnectedAccount) => {
  const t = getTranslator();

  const title = t("stripeAccountSetupResult.pendingRequiredInformation.title");

  let firstParagraph;
  if (
    !stripeConnectedAccount.chargesEnabled &&
    !stripeConnectedAccount.payoutsEnabled
  ) {
    firstParagraph = t(
      "stripeAccountSetupResult.pendingRequiredInformation.chargesAndPayoutsDisabled"
    );
  } else if (!stripeConnectedAccount.chargesEnabled) {
    firstParagraph = t(
      "stripeAccountSetupResult.pendingRequiredInformation.chargesDisabled"
    );
  } else if (!stripeConnectedAccount.payoutsEnabled) {
    firstParagraph = t(
      "stripeAccountSetupResult.pendingRequiredInformation.payoutsDisabled"
    );
  } else {
    throw new Error(
      "getPendingRequiredInformationContent should not be called if both charges and payouts are enabled"
    );
  }

  const body = [
    {
      type: "paragraph",
      content: firstParagraph,
    },
    {
      type: "title",
      content: t("stripeAccountSetupResult.whyDoWeNeedYourInfo.title"),
    },
    {
      type: "paragraph",
      content: t("stripeAccountSetupResult.whyDoWeNeedYourInfo.paragraph"),
    },
    {
      type: "button",
      content: t(
        "stripeAccountSetupResult.buttons.updateVerificationInformation"
      ),
      state: "stripe-account-setup",
    },
  ];

  return { title, body };
};

export default getPendingRequiredInformationContent;
