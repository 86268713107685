"use strict";
import _ from "lodash";
import { isSameDate } from "../../../shared/services/helper";

export const filterItems = (items, property) => {
  return _(items)
    .filter((item) => {
      return item[property];
    })
    .map((item) => {
      item[property].amount = item.amount;
      return item[property];
    })
    .value();
};

export const sumAmount = (items) => {
  return _.sum(items, "amount");
};

export const adjustForSingleDaySession = (list) => {
  list.forEach((item) => {
    item.singleDay = isSameDate(
      item.startTime,
      item.endTime,
      item.lot.timezoneName
    );
  });

  return list;
};
