"use strict";

import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { sum, find, isEqual, isEmpty } from "lodash";
import { Text } from "@citifyd/style";

import usePrevious from "../../../../hooks/usePrevious";
import LoadingManager from "../../../LoadingManager";
import { useService, useTranslator } from "../../../../hooks";
import RevenueFilter from "../RevenueFilter";
import RevenueEventsTable from "../RevenueEventsTable";
import { getFilterSelectionFromState } from "../../utils";
import GenerateReportModal from "../../../../../modals/GenerateReportModal";

import {
  getRevenueEventsCsv,
  getRevenueEvents,
  isRequestCancelled,
} from "../../../../../api";
import { useModal } from "../../../../contexts/modal";

const RevenueEvents = ({ lots, currencies, organizationsOptions }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const { openModal } = useModal();
  const abortRequest = useRef(null);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totals, setTotals] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const prevParams = usePrevious($state.params);

  const getQueryOptionsFromFilterSelection = (filterSelection) => {
    return {
      rangeStart: moment(filterSelection.dateRange.startDate).format(
        "YYYY-MM-DD"
      ),
      rangeEnd: moment(filterSelection.dateRange.endDate).format("YYYY-MM-DD"),
      currency: filterSelection.currency,
      organizationId: filterSelection.organizationId
        ? Number(filterSelection.organizationId)
        : null,
      lotId: filterSelection.lotId,
    };
  };

  const calculateTotals = (events) => {
    const fields = ["netRevenueAmount", "totalTickets", "totalSpaces"];

    let totals = {};

    for (const field of fields) {
      totals[field] = sum(events, (event) => {
        return event[field];
      });
    }

    setTotals(totals);
  };

  const load = async () => {
    setIsLoading(true);

    if (abortRequest.current) {
      abortRequest.current.abort();
      abortRequest.current = null;
    }

    const filterSelection = getFilterSelectionFromState($state);
    const options = getQueryOptionsFromFilterSelection(filterSelection);

    setCurrentFilter(filterSelection);

    try {
      abortRequest.current = new AbortController();

      const events = await getRevenueEvents({
        params: options,
        signal: abortRequest.current.signal,
      });

      setEvents(events);
      calculateTotals(events);
      setIsLoading(false);
    } catch (err) {
      if (!isRequestCancelled(err)) {
        setIsLoading(false);
      }
      console.log("err", err);
    } finally {
      abortRequest.current = null;
    }
  };

  useEffect(() => {
    if (
      !isEqual(prevParams, $state.params) &&
      !isEmpty($state.params?.dateRange)
    ) {
      load();
    }
  }, [$state.params]);

  const generateCsvReportDescription = (filterSelection) => {
    const options = {
      startDate: moment.tz(filterSelection.dateRange.startDate, "UTC"),
      endDate: moment.tz(filterSelection.dateRange.endDate, "UTC"),
    };

    if (filterSelection.lotId) {
      const lot = find(lots, (lot) => lot.id === Number(filterSelection.lotId));
      return t("revenue.csvReportName.events.lot", {
        ...options,
        lotName: lot.name,
      });
    } else {
      return t("revenue.csvReportName.events.currency", {
        ...options,
        currency: filterSelection.currency.toUpperCase(),
      });
    }
  };

  const generateCsvClicked = () => {
    const filterSelection = getFilterSelectionFromState($state);
    const options = getQueryOptionsFromFilterSelection(filterSelection);

    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription: generateCsvReportDescription(filterSelection),
      reportCsvRequest: getRevenueEventsCsv(options),
    });
  };

  return (
    <div>
      <RevenueFilter
        onClickGenerateCsv={generateCsvClicked}
        enableLotSelection
        lots={lots}
        organizationsOptions={organizationsOptions}
        currencies={currencies}
        data={events}
        isEvent={true}
      />
      {isLoading && <LoadingManager isLoading={isLoading} />}

      {events.length > 0 && !isLoading && (
        <RevenueEventsTable
          events={events}
          totals={totals}
          currentFilter={currentFilter}
          params={$state.params}
        />
      )}
      {events.length === 0 && !isLoading && (
        <Text textAlign="center">{t("search.noResults")}</Text>
      )}
    </div>
  );
};

export default RevenueEvents;
