"use strict";

import React, { useEffect, useState } from "react";
import { Grid, Tab } from "@citifyd/style";
import { sortBy, find, isEmpty } from "lodash";

import { useService, useTranslator } from "../../shared/react/hooks";
import LoadingManager from "../../shared/react/components/LoadingManager";
import NoProperties from "../../shared/react/components/NoProperties";
import { fetchProperties } from "./utils";
import ScheduleTab from "./components/Tab";
import PropertySelector from "./components/PropertySelector";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import Permissions from "../../shared/services/permissions";

const Schedule = () => {
  const t = useTranslator();
  const { user, permissions } = useAuthentication();

  const $state = useService("$state");
  const [selectedTab, setSelectedTab] = useState(
    $state.params.tab || "onDemand"
  );
  const userMayEditEvent = Permissions.userMayEditEvent(permissions);
  const [loadingProperties, setLoadingProperties] = useState(true);
  const [properties, setProperties] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const onSelectedPropertyChange = (item) => {
    const property = properties.find((property) => property.id === item.value);

    $state.transitionTo(
      "schedule",
      {
        ...$state.params,
        property: property?.id || null,
        tab: selectedTab,
      },
      { notify: false, location: "replace", inherit: false }
    );
  };

  useEffect(() => {
    if (!isEmpty($state.params?.property)) {
      const propertyId = $state.params?.property;
      const id = propertyId ? parseInt(propertyId, 10) : null;

      setSelectedProperty(find(properties, (p) => p.id === id));
    } else {
      if (!user.isAdmin && properties.length === 1) {
        setSelectedProperty(properties[0]);
      } else {
        setSelectedProperty(null);
      }
    }
  }, [$state.params, properties, user]);

  const onTabClick = (name) => {
    setSelectedTab(name);

    $state.transitionTo(
      "schedule",
      {
        ...$state.params,
        property: selectedProperty?.id || null,
        tab: name,
      },
      { notify: false, location: "replace", inherit: true }
    );
  };

  useEffect(() => {
    fetchProperties(user)
      .then((properties) => {
        setLoadingProperties(false);
        setProperties(sortBy(properties, "name"));
      })
      .catch((err) => setHasError(err));
  }, []);

  if (loadingProperties) {
    return <LoadingManager isLoading={loadingProperties} hasError={hasError} />;
  }

  if (properties?.length === 0 && !loadingProperties) {
    return <NoProperties />;
  }

  return (
    <Grid>
      <PropertySelector
        onChange={(item) => onSelectedPropertyChange(item)}
        disabled={!properties || loadingProperties}
        selectedProperty={selectedProperty}
        properties={properties}
      />
      <Tab stateless>
        <ScheduleTab
          active={selectedTab === "onDemand"}
          name={t("schedule.tabs.onDemand")}
          tab="onDemand"
          selectedProperty={selectedProperty}
          onClick={() => onTabClick("onDemand")}
        />
        <ScheduleTab
          active={selectedTab === "reservedParking"}
          name={t("schedule.tabs.reservedParking")}
          tab="reservedParking"
          selectedProperty={selectedProperty}
          onClick={() => onTabClick("reservedParking")}
        />

        <ScheduleTab
          active={selectedTab === "events"}
          name={t("schedule.tabs.events")}
          tab="events"
          selectedProperty={selectedProperty}
          onClick={() => onTabClick("events")}
        />

        <ScheduleTab
          active={selectedTab === "monthly"}
          name={t("schedule.tabs.monthly")}
          tab="monthly"
          selectedProperty={selectedProperty}
          onClick={() => onTabClick("monthly")}
        />
      </Tab>
    </Grid>
  );
};

export default Schedule;
