"use strict";

import React from "react";
import moment from "moment";
import { Button, LoadingIndicator, Link } from "@citifyd/style";
import styles from "./ChangeLogs.module.scss";

import ApolloClient, {
  NetworkStatus,
} from "../../../../shared/services/apolloClient";

import Table from "../../../../shared/react/components/Table";

import { transformChangelogToText } from "./transformChangelogToText";

import { useQuery } from "@apollo/client";
import ADMIN_USER_CHANGE_LOGS from "../../../../graphql/changeLogs/queries/AdminUserChangelogs";
import { useTranslator } from "../../../../shared/react/hooks";

const apolloClient = ApolloClient();

const PAGE_SIZE = 5;

const ChangeLogs = ({ userId }) => {
  const {
    data: queryData,
    loading: initialLoading,
    networkStatus,
    refetch,
  } = useQuery(
    ADMIN_USER_CHANGE_LOGS,

    {
      variables: {
        userId: Number(userId),
        first: PAGE_SIZE,
      },
      client: apolloClient,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  const loading = initialLoading || networkStatus === NetworkStatus.refetch;

  const data = queryData?.admin_userChangelogs ?? {};
  const { hasNextPage, hasPreviousPage, startCursor, endCursor } =
    data.pageInfo ?? {};

  const nextPage = () => {
    if (hasNextPage) {
      refetch({ first: PAGE_SIZE, last: null, after: endCursor, before: null });
    }
  };

  const previousPage = () => {
    if (hasPreviousPage) {
      refetch({
        first: null,
        last: PAGE_SIZE,
        after: null,
        before: startCursor,
      });
    }
  };

  if (loading || !data.edges) {
    return <LoadingIndicator />;
  }

  const tableData = data.edges.map((changeLog) => ({
    actions: transformChangelogToText(changeLog.node),
    dateAndTime: moment(changeLog.node.createdAt)
      .tz(changeLog.node.timezoneName)
      .format("MMMM Do YYYY, h:mm a"),
    changedBy: { value: <ChangedBy changeLog={changeLog.node} /> },
  }));

  const columns = [
    { key: "actions", value: "Actions" },
    { key: "dateAndTime", value: "Date and time" },
    { key: "changedBy", value: "Changed by" },
  ];

  return (
    <div>
      <Table striped columns={columns} data={tableData} />
      {data.edges.length > 0 && (
        <div className={styles.buttons}>
          <Button
            justifyContent="center"
            fullWidth
            size="small"
            uppercase
            iconLeft="chevron-left"
            disabled={!hasPreviousPage || loading || !data?.edges}
            onClick={previousPage}
          >
            Previous
          </Button>

          <Button
            justifyContent="center"
            fullWidth
            size="small"
            uppercase
            iconRight="chevron-right"
            disabled={!hasNextPage || loading || !data?.edges}
            onClick={nextPage}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

const ChangedBy = ({ changeLog }) => {
  const t = useTranslator();
  if (!changeLog.changedByUser) {
    return t("user.changelogs.system");
  }

  if (changeLog.changedByUser?.id === changeLog.user.id) {
    return t("user.changelogs.ownUser");
  }

  return (
    <Link href={`users/${changeLog.changedByUser.id}`} target="_blank">
      {changeLog.changedByUser.name}
    </Link>
  );
};

export default ChangeLogs;
