import React from "react";
import { Icon } from "@citifyd/style";
import moment from "moment-timezone";
import { getService } from "../../../../shared/react/utils";
import Permissions from "../../../../shared/services/permissions";

import { getTranslator } from "../../../../shared/services/languages";
import { findWhere } from "lodash";

import { getAllPlans } from "../../../../shared/api";

import styles from "./Monthly.module.scss";
import { getPropertySubscriptions } from "../../../../shared/api";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

export const getMonthlyTableData = ({ selectedProperty, monthlyPlan }) => {
  if (!selectedProperty) return null;
  const { permissions } = useAuthentication();
  const t = getTranslator();
  const $state = getService("$state");

  const onEditIconClick = () => {
    $state.transitionTo("edit-property-step", {
      lotId: selectedProperty?.id,
      step: 3,
      editPlan: true,
    });
  };

  const columns = [
    {
      key: "type",
      value: t("schedule.type"),
      width: "48%",
    },
    {
      key: "passes",
      value: t("schedule.passes"),
      width: "48%",
    },
    {
      key: "edit",
      value: "",
      width: "4%",
    },
  ];

  const data = [
    {
      type: t("schedule.monthlyParking"),
      passes: `${monthlyPlan?.amountOfSubscribers || 0}/${
        monthlyPlan?.spaces || 0
      }`,
      edit: Permissions.userMaySeeLots(permissions) && (
        <Icon
          icon="pencil-alt"
          className={styles.editIcon}
          size="lg"
          onClick={() => onEditIconClick()}
        />
      ),
    },
  ];

  return {
    columns,
    data,
  };
};

export const getSubscriptionsTableData = ({
  selectedProperty,
  subscriptions,
  permissions,
}) => {
  if (!selectedProperty || subscriptions?.length === 0) return null;

  const t = getTranslator();
  const canSeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  const columns = [
    canSeeUserInformation && {
      key: "username",
      value: t("schedule.username"),
    },
    {
      key: "purchased",
      value: t("schedule.purchased"),
    },
    {
      key: "time",
      value: t("schedule.time"),
    },
    {
      key: "lastUse",
      value: t("schedule.lastUse"),
    },
    {
      key: "expires",
      value: t("schedule.expires"),
    },
  ];

  const data = subscriptions?.map((subscription) => ({
    username: subscription.user.name,
    purchased: {
      value: t("defaultFormats.date", {
        date: moment.tz(
          (subscription.initialSubscription || subscription).subscribedAt,
          selectedProperty.timezoneName
        ),
      }),
      nowrap: "nowrap",
    },
    time: t("defaultFormats.timeWithTimezone", {
      date: moment.tz(
        (subscription.initialSubscription || subscription).subscribedAt,
        selectedProperty.timezoneName
      ),
    }),
    lastUse: {
      value: subscription.lastReservation ? (
        subscription.lastReservation?.endTime ? (
          t("defaultFormats.date", {
            date: moment.tz(
              lastReservation.endTime,
              selectedProperty.timezoneName
            ),
          })
        ) : (
          t("schedule.active")
        )
      ) : (
        <>&mdash;</>
      ),
      nowrap: "nowrap",
    },
    expires: subscription.effectiveUntil ? (
      t("defaultFormats.timeWithTimezone", {
        date: moment.tz(
          subscription.effectiveUntil,
          selectedProperty.timezoneName
        ),
      })
    ) : (
      <>&mdash;</>
    ),
  }));

  return {
    columns,
    data,
  };
};

export const loadMonthlyData = async (selectedProperty) => {
  const propertyId = selectedProperty?.id;

  const subscriptions = await loadSubscriptions(propertyId);
  const monthlyPlan = await loadMonthlyPlan(propertyId);

  return {
    subscriptions,
    monthlyPlan,
  };
};

export const loadSubscriptions = async (propertyId) => {
  return getPropertySubscriptions(propertyId);
};

export const loadMonthlyPlan = async (propertyId) =>
  getAllPlans({ params: { lotId: propertyId } }).then((plans) =>
    findWhere(plans, { period: "month" })
  );
