"use strict";

import React from "react";
import { Button, Modal, Text } from "@citifyd/style";
import { useTranslator } from "../../shared/react/hooks";

const PermitAlertModal = ({ onClose, open, children }) => {
  const t = useTranslator();

  return (
    <Modal open={open} onClose={onClose} closeIcon={false} textAlign="center">
      <Modal.Content>
        <Text textAlign="center" variant="subtitle">
          {children}
        </Text>
      </Modal.Content>
      <Modal.Actions display="block">
        <Button
          onClick={onClose}
          extraPadding
          justifyContent="center"
          uppercase
        >
          {t("commonButtons.ok")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PermitAlertModal;
