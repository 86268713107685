"use strict";

import React, { useEffect, useState, useRef } from "react";
import { Select, Grid, DatePicker } from "@citifyd/style";
import moment from "moment-timezone";

import { useService, useTranslator } from "../../../../shared/react/hooks";
import styles from "./PermitsFilters.module.scss";
import { getCurrentLanguage } from "../../../../shared/services/languages";
import { validate, selectionToParams } from "./utils";
import SearchInput from "../../../../shared/react/components/SearchInput";

const PermitsFilters = ({ currentFilter }) => {
  const t = useTranslator();
  const [errors, setErrors] = useState({});
  const $state = useService("$state");
  const [selection, setSelection] = useState({});
  const timer = useRef(null);
  const language = getCurrentLanguage();
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSelection(currentFilter);
    setSearch(currentFilter.search || "");
  }, [currentFilter]);

  const statusOptions = [
    {
      label: t("permits.status.all"),
      value: "",
    },
    {
      value: "PENDING",
      label: t("permits.status.pending"),
    },
    {
      value: "ACTIVATED",
      label: t("permits.status.activated"),
    },
    {
      value: "EXPIRED",
      label: t("permits.status.expired"),
    },
    {
      value: "CANCELLED",
      label: t("permits.status.cancelled"),
    },
  ];

  const updateFilters = (newSelection) => {
    if (!validate(newSelection, setErrors, t)) {
      return false;
    }

    $state.transitionTo($state.current.name, selectionToParams(newSelection), {
      location: "replace",
      notify: false,
      inherit: true,
    });
  };

  const fieldChanged = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    const newSelection = {
      ...selection,
      [name]: value,
    };

    setSelection(newSelection);
    updateFilters(newSelection);
  };

  const setDate = (field, date) => {
    const newSelection = {
      ...selection,
      [field]: date,
    };

    setSelection(newSelection);
    updateFilters(newSelection);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (timer) {
      clearTimeout(timer.current);
    }

    const newSelection = {
      ...selection,
      search: e.target.value,
    };

    timer.current = setTimeout(() => {
      updateFilters(newSelection);
    }, 500);
  };

  return (
    <Grid.Row className={styles.filters}>
      <Grid.Col className={styles.filter} xs={12} md={3}>
        <Select
          fullWidth
          name="status"
          labelSize="small"
          label={t("permits.permitsFilters.status")}
          options={statusOptions}
          value={selection.status || ""}
          onChange={fieldChanged}
        />
      </Grid.Col>
      <Grid.Col className={styles.filter} xs={12} md={3}>
        <DatePicker
          locale={language}
          onChange={(date) => setDate("startDate", date)}
          selectedDate={moment(selection.startDate).toDate()}
          label={t("permits.permitsFilters.startDate")}
          labelSize="small"
        />
      </Grid.Col>
      <Grid.Col className={styles.filter} xs={12} md={3}>
        <DatePicker
          locale={language}
          onChange={(date) => setDate("endDate", date)}
          selectedDate={moment(selection.endDate).toDate()}
          label={t("permits.permitsFilters.endDate")}
          labelSize="small"
          error={errors["dateRange.end"]}
          errorMessage={t(errors["dateRange.end"])}
        />
      </Grid.Col>
      <Grid.Col className={styles.filter} xs={12} md={3}>
        <SearchInput
          value={search}
          id="search"
          label={t("permits.permitsFilters.search")}
          labelSize="small"
          onChange={handleSearch}
          fullWidth
        />
      </Grid.Col>
    </Grid.Row>
  );
};

export default PermitsFilters;
