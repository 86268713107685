"use strict";
import React, { useMemo } from "react";
import { Text } from "@citifyd/style";
import Table from "../../../shared/react/components/Table";
import { useService, useTranslator } from "../../../shared/react/hooks";
import { displayDate, formatValue } from "./functions/utils";
import GuestBadge from "../../../shared/react/components/GuestBadge";
import Permissions from "../../../shared/services/permissions";
import Total from "./Total.jsx";
import { useAuthentication } from "../../../shared/react/contexts/authentication";

const ReconciliationTicketCancellations = React.forwardRef(
  ({ items, total, currency }, ref) => {
    const t = useTranslator();
    const $state = useService("$state");
    const { user, permissions } = useAuthentication();

    const columns = [
      ...(Permissions.userMaySeeUserInformation(permissions)
        ? [{ key: "name", value: t("payout.username") }]
        : []),
      { key: "property", value: t("payout.property") },
      { key: "cancelDate", value: t("payout.cancelDate") },
      { key: "event", value: t("payout.event") },
      {
        key: "total",
        value: <Text textAlign="right">{t("payout.revenue")}</Text>,
      },
    ];

    const data = useMemo(() => {
      return [
        ...items.map((item) => ({
          ...item,
          name: (
            <>
              {item.ticket.purchaser.name}{" "}
              {item.ticket.purchaser.isGuest && <GuestBadge />}
            </>
          ),
          property: item.ticket.lot.name,
          cancelDate: displayDate({
            date: item.createdAt,
            format: "shortDatetime",
            user,
          }),
          event: item.ticket.event.name,
          total: <Total value={item.amount} currency={currency} />,
          onClick: Permissions.userMayOpenTicketPage()
            ? () => {
                $state.go(
                  "ticket",
                  { id: item.ticket.id },
                  { enableBackLink: true }
                );
              }
            : null,
        })),
        {
          name: <Text bold>{t("payout.total")}</Text>,
          total: (
            <Text textAlign="right" bold>
              {formatValue({
                value: total,
                currency,
              })}
            </Text>
          ),
        },
      ];
    }, [items, currency, total]);

    return (
      <div ref={ref}>
        <Text variant="h3" gutterBottom>
          {t("payout.reconciliation")}
        </Text>
        <Text gutterBottom>{t("payout.ticketCancellationsDescription")}</Text>

        <Table
          columns={columns}
          data={data}
          gutterBottom
          responsive
          striped
          clickable
        />
      </div>
    );
  }
);

export default ReconciliationTicketCancellations;
