"use strict";

import React from "react";
import { useFormikContext } from "formik";
import { Button, Icon } from "@citifyd/style";
import classNames from "classnames";
import { last, isEmpty } from "lodash";
import { useTranslator } from "../../../../../shared/react/hooks";
import FormikField, {
  FIELD_TYPE,
} from "../../../../../shared/react/components/FormikField";
import Table from "../../../../../shared/react/components/Table";
import styles from "./PosRates.module.scss";
import produce from "immer";

const PosRates = ({ rates, lot }) => {
  const t = useTranslator();
  const { setValues } = useFormikContext();

  const canAddPosRate = () => {
    const lastRate = last(rates[lot.id]);
    return !lastRate || (lastRate.name !== "" && lastRate.label !== "");
  };

  const onAddNewRateClick = () => {
    setValues(
      produce((values) => {
        if (!values.posRates[lot.id]) {
          values.posRates[lot.id] = [];
        }

        const lotPosRates = values.posRates[lot.id];
        const lastPosRate = last(lotPosRates);
        const nextId = lastPosRate ? lastPosRate.id + 1 : 0;

        lotPosRates.push({
          id: nextId,
          name: "",
          label: "",
          allowReentry: false,
          rate: { type: "fixed", value: "", fee: "" },
        });
      })
    );
  };

  const onRemoveRateClick = (rateId) => {
    setValues(
      produce((values) => {
        values.posRates[lot.id].splice(rateId, 1);
      })
    );
  };

  const tableColumns = [
    {
      value: t("eventProperties.posRates.label"),
      uppercase: false,
      width: "20%",
      key: "label",
    },
    {
      value: t("eventProperties.posRates.allowReentry"),
      uppercase: false,
      width: "20%",
      key: "allowReentry",
      textAlign: "center",
    },
    {
      value: t("eventProperties.posRates.price"),
      uppercase: false,
      width: "15%",
      key: "price",
    },
    {
      value: t("eventProperties.posRates.citifydServiceFee"),
      uppercase: false,
      width: "15%",
      key: "citifydServiceFee",
    },
    {
      value: "",
      width: "10%",
      key: "remove",
    },
  ];

  const tableData = rates[lot.id]?.map((rate, index) => ({
    borderBottom: index !== rates[lot.id].length - 1,
    borderBottomColor: "gray",
    label: (
      <FormikField
        name={`posRates[${lot.id}][${index}].label`}
        as={FIELD_TYPE.INPUT}
      />
    ),
    allowReentry: {
      value: (
        <div className={styles.checkbox}>
          <FormikField
            name={`posRates[${lot.id}][${index}].allowReentry`}
            as={FIELD_TYPE.CHECKBOX}
          />
        </div>
      ),
      textAlign: "center",
    },
    price: (
      <FormikField
        as={FIELD_TYPE.CURRENCY}
        currency={lot.country?.currency}
        disabled={lot.availability.rateEditingDisabled}
        name={`posRates[${lot.id}][${index}].rate.value`}
      />
    ),
    citifydServiceFee: (
      <FormikField
        as={FIELD_TYPE.CURRENCY}
        currency={lot.country?.currency}
        disabled={lot.availability.rateEditingDisabled}
        name={`posRates[${lot.id}][${index}].rate.fee`}
      />
    ),
    remove: {
      value: (
        <Button
          onMouseDown={() => onRemoveRateClick(rate.id)}
          appearance="tertiary"
          size="small"
          justifyContent="center"
          className={styles.button}
          rounded
        >
          <Icon icon="times" pull={null} size="h3" appearance="white" />
        </Button>
      ),
      textAlign: "center",
      verticalAlign: "middle",
    },
  }));

  return (
    <>
      <Table
        condensed
        borderBottom={rates[lot.id]?.length > 0}
        className={styles.table}
        borderBottomColor="gray"
        headerAppearance="gray"
        columns={tableColumns}
        data={tableData}
      />
      <Button
        onMouseDown={() => onAddNewRateClick()}
        disabled={!canAddPosRate()}
        appearance="primary"
        size="small"
        justifyContent="center"
        className={classNames(styles.button, styles.addRateButton)}
        rounded
      >
        <Icon icon="plus" pull={null} size="h3" appearance="white" />
      </Button>
    </>
  );
};

export default PosRates;
