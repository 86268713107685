"use strict";

import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { Grid } from "@citifyd/style";

import { useService, useTranslator } from "../../shared/react/hooks";
import PageHeader from "../../shared/react/components/PageHeader";
import NoProperties from "../../shared/react/components/NoProperties";
import LoadingManager from "../../shared/react/components/LoadingManager";
import PropertiesComp from "./components/Properties";
import Parkers from "./components/Parkers";
import Schedule from "./components/Schedule";
import { getAllProperties } from "../../shared/api";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import Permissions from "../../shared/services/permissions";

const Dashboard = () => {
  const t = useTranslator();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(null);
  const [shouldLoadSubComponents, setShouldLoadSubComponents] = useState(false);
  const [properties, setProperties] = useState([]);
  const $state = useService("$state");
  const { permissions, user, logout } = useAuthentication();
  const hasProperties = useMemo(() => properties?.length > 0, [properties]);

  useEffect(() => {
    if (
      Permissions.userMayAccessEnforcement(permissions) &&
      user.verifiedPhoneNumber
    ) {
      logout();
      window.location.href = "https://enforcement.citifyd.com/";
    }
  }, [user, permissions]);

  useEffect(() => {
    if (
      Permissions.userMaySeePermits(permissions) &&
      !Permissions.userMayAccessSnapshots(permissions)
    ) {
      $state.go("permits", { location: "replace" });
    } else {
      getAllProperties()
        .then(function (response) {
          setIsLoading(false);
          setProperties(response);
        })
        .catch((err) => setHasError(err));
    }
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  return (
    <Grid>
      {Permissions.userMayAccessSnapshots(permissions) &&
        !Permissions.userMayAccessEnforcement(permissions) && (
          <>
            <PageHeader>
              <PageHeader.Title
                weight="300"
                title={
                  hasProperties &&
                  t("propertySnapshot.dateTitle", {
                    today: moment(),
                  })
                }
              />
            </PageHeader>

            {!hasProperties && <NoProperties />}
            {hasProperties && (
              <>
                <PropertiesComp
                  onSnapshotsLoaded={() => setShouldLoadSubComponents(true)}
                />
                {shouldLoadSubComponents && <Parkers properties={properties} />}
                {shouldLoadSubComponents && (
                  <Schedule properties={properties} />
                )}
              </>
            )}
          </>
        )}
    </Grid>
  );
};

export default Dashboard;
