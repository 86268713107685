"use strict";

import React, { useEffect, useState } from "react";
import { Button, Grid } from "@citifyd/style";
import CurrencyFormatter from "@citifyd/currency-formatter";

import Table from "../../shared/react/components/Table";
import { getCurrentLanguage } from "../../shared/services/languages";
import { useTranslator } from "../../shared/react/hooks";
import LoadingManager from "../../shared/react/components/LoadingManager";
import PageHeader from "../../shared/react/components/PageHeader";
import ManageReferralCodeModal from "../../shared/modals/ManageReferralCodeModal";
import styles from "./ReferralCodes.module.scss";
import Mode from "../../shared/utils/mode";
import { getAllReferralCodes, toggleReferralCode } from "../../shared/api";
import { showErrorAlert } from "../../shared/services/helper";
import { useModal } from "../../shared/react/contexts/modal";

const ReferralCodes = () => {
  const t = useTranslator();
  const { openModal } = useModal();
  const language = getCurrentLanguage();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(true);
  const [referralCodes, setReferralCodes] = useState([]);

  const load = async () => {
    setHasError(false);
    setIsLoading(true);

    try {
      const response = await getAllReferralCodes();

      setReferralCodes(response);
    } catch (err) {
      setHasError(true);
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, []);

  const openReferralCode = (options) =>
    openModal(ManageReferralCodeModal, {
      ...options,
      onClose: ({ refreshUsers } = {}) => {
        if (refreshUsers) {
          load();
        }
      },
    });

  const openAddReferralCode = () => openReferralCode({ mode: Mode.ADD });

  const editReferralCode = (referralCode) =>
    openReferralCode({ mode: Mode.EDIT, referralCodeId: referralCode.code });

  const toggle = async (referralCode) => {
    let toggleValue = Boolean(referralCode.deletedAt);
    let message;

    if (toggleValue) {
      message = t("referral.reactivateConfirmation");
    } else {
      message = t("referral.deactivateConfirmation");
    }

    if (!window.confirm(message)) {
      return;
    }

    setIsLoading(true);

    try {
      await toggleReferralCode(referralCode.code, toggleValue);

      load();
    } catch (err) {
      console.log("err", err);
      showErrorAlert(err.data);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      key: "code",
      value: t("referral.code"),
      width: "20%",
    },
    {
      key: "magicLink",
      value: t("referral.magicLink"),
      width: "30%",
    },
    {
      key: "incentive",
      value: t("referral.incentive"),
      width: "10%",
    },
    {
      key: "maxReferrals",
      value: t("referral.maxReferrals"),
      width: "15%",
    },
    {
      key: "numReferrals",
      value: t("referral.numReferrals"),
      width: "15%",
    },
    {
      key: "active",
      value: t("referral.active"),
      width: "10%",
    },
  ];

  const columnsFormatted = columns.map((item) => ({
    ...item,
    verticalAlign: "middle",
  }));

  const data = referralCodes.map((referralCode) => ({
    code: {
      value: referralCode.code,
    },
    magicLink: {
      value: referralCode.url,
    },
    incentive: {
      value: (
        <span
          className={styles.link}
          onClick={() => editReferralCode(referralCode)}
        >
          {CurrencyFormatter.format(referralCode.incentiveAmount, {
            currency: "usd",
            language,
          })}
        </span>
      ),
    },
    maxReferrals: {
      value: (
        <span
          className={styles.link}
          onClick={() => editReferralCode(referralCode)}
        >
          {referralCode.maxReferrals || t("no")}
        </span>
      ),
    },
    numReferrals: {
      value: (
        <span>
          {referralCode.totalReferralCount}{" "}
          {referralCode.successReferralCount > 0 &&
            `(${referralCode.successReferralCount} purchased)`}
        </span>
      ),
      nowrap: "nowrap",
    },
    active: {
      value: (
        <span className={styles.link} onClick={() => toggle(referralCode)}>
          {referralCode.deletedAt ? t("no") : t("yes")}
        </span>
      ),
    },
  }));

  return (
    <Grid className={styles.referralCodes}>
      <PageHeader className={styles.pageHeader}>
        <PageHeader.Title weight="300" title={t("referral.referralCodes")} />
        <PageHeader.Actions>
          <PageHeader.Action>
            <Button
              onClick={openAddReferralCode}
              size="small"
              letterSpacing
              uppercase
              iconRight="plus-circle"
            >
              {t("referral.add")}
            </Button>
          </PageHeader.Action>
        </PageHeader.Actions>
      </PageHeader>
      {isLoading || hasError ? (
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      ) : (
        <Table
          className={styles.table}
          columns={columnsFormatted}
          responsive
          striped
          data={data}
        />
      )}
    </Grid>
  );
};

export default ReferralCodes;
