"use strict";

import React from "react";
import { Input, InputProps } from "@citifyd/style";

const SearchInput = (props: InputProps, rest) => {
  return (
    <Input
      type="search"
      iconLeft="search"
      iconAppearance="secondary"
      {...props}
      {...rest}
    />
  );
};

export default SearchInput;
