"use strict";

import React, { useEffect, useState } from "react";
import { Grid, Tab } from "@citifyd/style";

import { useService, useTranslator } from "../../shared/react/hooks";
import LoadingManager from "../../shared/react/components/LoadingManager";
import PageHeader from "../../shared/react/components/PageHeader";
import EventHeader from "../../shared/react/components/EventHeader";
import { loadEvent } from "./utils";
import EventTab from "./components/Tab";

const Event = () => {
  const t = useTranslator();
  const $state = useService("$state");
  const $stateParams = useService("$stateParams");
  const [selectedTab, setSelectedTab] = useState($stateParams.tab || "gates");
  const [hasError, setHasError] = useState(false);
  const [event, setEvent] = useState([]);
  const [isLoadingEvent, setIsLoadingEvent] = useState(false);

  const onTabClick = (name) => {
    setSelectedTab(name);

    $state.transitionTo(
      "event",
      {
        ...$state.params,
        tab: name,
      },
      { location: "replace", notify: false, inherit: false }
    );
  };

  useEffect(() => {
    const fetchEvent = async () => {
      setIsLoadingEvent(true);
      try {
        const event = await loadEvent($stateParams.id);
        setEvent(event);
      } catch (err) {
        setHasError(true);
      } finally {
        setIsLoadingEvent(false);
      }
    };

    fetchEvent();
  }, []);

  if (isLoadingEvent || hasError) {
    return <LoadingManager isLoading={isLoadingEvent} hasError={hasError} />;
  }

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title weight="300" backState title={t("event.schedule")} />
      </PageHeader>
      <EventHeader event={event} showActions={true} />
      <Tab stateless>
        <EventTab
          active={selectedTab === "gates"}
          name={t("event.properties")}
          event={event}
          tab="gates"
          onClick={() => onTabClick("gates")}
        />
        <EventTab
          active={selectedTab === "tickets"}
          name={t("event.purchases")}
          event={event}
          tab="tickets"
          onClick={() => onTabClick("tickets")}
        />
        <EventTab
          active={selectedTab === "waitlist"}
          name={t("event.eventWaitlist")}
          event={event}
          tab="waitlist"
          onClick={() => onTabClick("waitlist")}
        />
      </Tab>
    </Grid>
  );
};

export default Event;
