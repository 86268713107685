"use strict";

import { getTranslator } from "../services/languages";
import { camelCase } from "lodash";
import moment from "moment-timezone";

function generateRateDescription({
  type,
  ruleName,
  maxValue,
  value,
  currency,
}) {
  const t = getTranslator();

  type = camelCase(type);

  if (type === "variable") {
    return ruleName;
  } else if (maxValue) {
    return t(
      `reservations.availabilityAllocations.rateDescriptions.${type}WithMaxValue`,
      {
        value: value,
        maxValue: maxValue,
        currency: currency,
      }
    );
  } else {
    return t(`reservations.availabilityAllocations.rateDescriptions.${type}`, {
      value: value,
      currency: currency,
    });
  }
}

export function generateTimeRangeDescription(startTime, endTime, timezoneName) {
  const t = getTranslator();

  const start = moment.tz(startTime, timezoneName);
  const end = moment.tz(endTime, timezoneName);

  const description = end.isSame(start, "day")
    ? t("reservations.availabilityAllocations.timeRange.singleDay", {
        start,
        end,
      })
    : t("reservations.availabilityAllocations.timeRange.multiDay", {
        start,
        end,
      });

  return description;
}

export function formatAvailabilityAllocation({
  availabilityAllocation,
  timezoneName,
  currency,
}) {
  return {
    rate: generateRateDescription({ ...availabilityAllocation.rate, currency }),
    timeRange: generateTimeRangeDescription(
      availabilityAllocation.start || availabilityAllocation.startTime,
      availabilityAllocation.end || availabilityAllocation.endTime,
      timezoneName
    ),
  };
}
