"use strict";

import React from "react";
import { Tab } from "@citifyd/style";
import OnDemand from "../OnDemand";
import ReservedParking from "../ReservedParking";
import Monthly from "../Monthly";
import Events from "../Events";

import styles from "./Tab.module.scss";

const ScheduleTab = ({ selectedProperty, active, name, tab }) => {
  const renderContent = () => {
    switch (tab) {
      case "onDemand":
        return (
          <OnDemand
            className={styles.content}
            selectedProperty={selectedProperty}
          />
        );
      case "reservedParking":
        return (
          <ReservedParking
            className={styles.content}
            selectedProperty={selectedProperty}
          />
        );
      case "events":
        return (
          <Events
            className={styles.content}
            selectedProperty={selectedProperty}
          />
        );
      case "monthly":
        return (
          <Monthly
            className={styles.content}
            selectedProperty={selectedProperty}
          />
        );
    }
  };

  return (
    <Tab.Item active={active} name={name}>
      {renderContent()}
    </Tab.Item>
  );
};

export default ScheduleTab;
