import React, { useEffect, useState, useMemo } from "react";
import { Text } from "@citifyd/style";
import { get } from "lodash";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import RouterLinkButton from "../../../../shared/react/components/RouterLinkButton";
import Table from "../../../../shared/react/components/Table";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import { loadAvailabilities, getTableData } from "./utils";
import Permissions from "../../../../shared/services/permissions";

import styles from "./OnDemand.module.scss";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const OnDemand = ({ selectedProperty, ...rest }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const { permissions } = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasRatesDefiniton, setHasRatesDefiniton] = useState(false);
  const [availabilityGroups, setAvailabilityGroups] = useState(null);

  const tableContent = useMemo(
    () =>
      getTableData({
        selectedProperty,
        availabilityGroups,
      }),
    [selectedProperty, availabilityGroups]
  );

  const loadData = async () => {
    if (
      !selectedProperty ||
      get(selectedProperty, "advancedRateDefinitionEnabled")
    ) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await loadAvailabilities(selectedProperty);

      setHasRatesDefiniton(response.hasRatesDefiniton);
      setAvailabilityGroups(response.availabilityGroups);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [selectedProperty]);

  useEffect(() => {
    loadData();
  }, [$state.params.property]);

  if (isLoading) {
    return (
      <div {...rest}>
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      </div>
    );
  }

  return (
    <div {...rest}>
      {!selectedProperty && (
        <Text variant="subtitle">
          {t("schedule.onDemandPropertyInstructions")}
        </Text>
      )}
      {selectedProperty?.advancedRateDefinitionEnabled &&
        Permissions.userMaySeeLots(permissions) && (
          <>
            <RouterLinkButton
              state="edit-property-advanced-rates"
              params={{
                lotId: selectedProperty.id,
                advancedRateDefinitionId: "latest",
              }}
              options={{ location: "replace", inherit: false }}
              uppercase
              size="small"
            >
              {t("schedule.editRates")}
            </RouterLinkButton>
          </>
        )}
      {selectedProperty &&
        !selectedProperty?.advancedRateDefinitionEnabled &&
        Permissions.userMaySeeLots(permissions) && (
          <>
            {!hasRatesDefiniton && (
              <Text gutterBottom={tableContent} variant="subtitle">
                {t("schedule.onDemandRateDefinition")}
              </Text>
            )}
            {tableContent && (
              <Table
                data={tableContent?.data}
                columns={tableContent?.columns}
                responsive
                striped
              />
            )}

            <RouterLinkButton
              state="edit-property-step"
              className={styles.buttonRates}
              options={{ enableBackLink: true }}
              params={{
                lotId: selectedProperty.id,
                step: 2,
              }}
              uppercase
              size="small"
            >
              {t("schedule.editRates")}
            </RouterLinkButton>
          </>
        )}
    </div>
  );
};

export default OnDemand;
