import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  CitifydID: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type Agent = {
  __typename?: 'Agent';
  citifydOrganizationId: Scalars['CitifydID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AgentMutationResponse = MutationResponse & {
  __typename?: 'AgentMutationResponse';
  agent?: Maybe<Agent>;
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type AssignProvisionGateInput = {
  gateCode: Scalars['String']['input'];
};

export type CancelPaymentInput = {
  id: Scalars['ID']['input'];
};

export type CancelProvisionInput = {
  /** The target device vendor ID to cancel the provision. */
  deviceSerialId: Scalars['String']['input'];
};

export type CitifydAvailability = {
  __typename?: 'CitifydAvailability';
  end: Scalars['DateTime']['output'];
  id: Scalars['CitifydID']['output'];
  start: Scalars['DateTime']['output'];
  timezoneName: Scalars['String']['output'];
};

export type CitifydEvent = {
  __typename?: 'CitifydEvent';
  id: Scalars['CitifydID']['output'];
  image?: Maybe<Scalars['JSON']['output']>;
  lots: Array<CitifydEventLot>;
  name: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
  timezoneName: Scalars['String']['output'];
  venue: CitifydVenue;
};

export type CitifydEventLot = {
  __typename?: 'CitifydEventLot';
  address: Scalars['JSON']['output'];
  availability?: Maybe<CitifydAvailability>;
  countryCode: Scalars['String']['output'];
  gates: Array<CitifydGate>;
  id: Scalars['CitifydID']['output'];
  lotAvailability?: Maybe<CitifydLotAvailability>;
  name: Scalars['String']['output'];
  paymentDestination: CitifydPaymentDestination;
  photo?: Maybe<Scalars['JSON']['output']>;
  timezoneName: Scalars['String']['output'];
};

export type CitifydGate = {
  __typename?: 'CitifydGate';
  id: Scalars['CitifydID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type CitifydIngress = {
  __typename?: 'CitifydIngress';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
};

export type CitifydIngressMutationResponse = {
  __typename?: 'CitifydIngressMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CitifydLot = {
  __typename?: 'CitifydLot';
  address: Scalars['JSON']['output'];
  countryCode: Scalars['String']['output'];
  id: Scalars['CitifydID']['output'];
  name: Scalars['String']['output'];
  paymentDestination: CitifydPaymentDestination;
  photo?: Maybe<Scalars['JSON']['output']>;
  timezoneName: Scalars['String']['output'];
};

export type CitifydLotAvailability = {
  __typename?: 'CitifydLotAvailability';
  posRates?: Maybe<Array<CitifydPosRate>>;
  spaces: Array<CitifydSpace>;
};

export type CitifydPosRate = {
  __typename?: 'CitifydPOSRate';
  allowReentry: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rate: CitifydRate;
};

export type CitifydPosRateInput = {
  allowReentry: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  rate: CitifydRateInput;
};

export type CitifydPaymentDestination = {
  __typename?: 'CitifydPaymentDestination';
  currency: Scalars['String']['output'];
  stripeAccountId: Scalars['String']['output'];
};

export type CitifydRate = {
  __typename?: 'CitifydRate';
  fee: Scalars['Int']['output'];
  type: CitifydRateType;
  value: Scalars['Int']['output'];
};

export type CitifydRateInput = {
  fee: Scalars['Int']['input'];
  type: CitifydRateType;
  value: Scalars['Int']['input'];
};

export enum CitifydRateType {
  Fixed = 'FIXED'
}

export type CitifydSpace = {
  __typename?: 'CitifydSpace';
  max: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CitifydVenue = {
  __typename?: 'CitifydVenue';
  countryCode: Scalars['String']['output'];
  id: Scalars['CitifydID']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  venueAddress?: Maybe<Scalars['JSON']['output']>;
};

export type ConnectionTokenMutationResponse = {
  __typename?: 'ConnectionTokenMutationResponse';
  code: Scalars['Int']['output'];
  connectionToken: Scalars['String']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type CreateAgentInput = {
  /**
   * Citifyd Organization ID
   * It's optional if the request is being executed with a Citifyd session.
   */
  citifydOrganizationId?: InputMaybe<Scalars['CitifydID']['input']>;
  name: Scalars['String']['input'];
  pin: Scalars['String']['input'];
};

export type CreateCitifydIngressInput = {
  idempotencyKey: Scalars['UUID']['input'];
};

export type CreateDeviceInput = {
  /**
   * Citifyd Organization ID
   * It's optional if the request is being executed with a Citifyd session.
   */
  citifydOrganizationId?: InputMaybe<Scalars['CitifydID']['input']>;
  enabled: Scalars['Boolean']['input'];
  imei?: InputMaybe<Scalars['String']['input']>;
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  networkCarrierName?: InputMaybe<Scalars['String']['input']>;
  nextMaintenanceScheduledAt?: InputMaybe<Scalars['DateTime']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  shortCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateExemptionIngressInput = {
  exemptionGroupId: Scalars['UUID']['input'];
  idempotencyKey: Scalars['UUID']['input'];
};

export type CreatePaymentInput = {
  /**
   * An unique idempotency key should be generated for each payment request.
   * The client should only use the same idempotency key more than once when you want to retry a failed attempt.
   */
  idempotencyKey: Scalars['UUID']['input'];
  /**
   * If you need to generate a paymentIntent without having a card reader in test mode, you can use this tool:
   * https://github.com/Citifyd/stripe-terminal-web-simulator
   */
  paymentIntentToken?: InputMaybe<Scalars['String']['input']>;
  posRate: CitifydPosRateInput;
  type: PaymentType;
};

export type CreateProvisionInput = {
  citifydEventId: Scalars['CitifydID']['input'];
  citifydGateId?: InputMaybe<Scalars['CitifydID']['input']>;
  citifydLotId: Scalars['CitifydID']['input'];
  citifydManagerUserId?: InputMaybe<Scalars['CitifydID']['input']>;
  /**
   * The target device ID to be provisioned.
   * This is required unless a short code or device serial ID is provided.
   */
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * The target device serial ID to be provisioned.
   * This is required unless a device ID or short code is provided.
   */
  deviceSerialId?: InputMaybe<Scalars['String']['input']>;
  /**
   * The target device short code to be provisioned.
   * This is required unless a device ID or serial ID is provided.
   */
  deviceShortCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateScanInput = {
  barcodeValue: Scalars['String']['input'];
  /**
   * An unique idempotency key should be generated for each scan request.
   * The client should only use the same idempotency key more than once when you want to retry a failed attempt.
   */
  idempotencyKey: Scalars['UUID']['input'];
};

export type CreateTransactionProcessingTimeInput = {
  endDateTime: Scalars['DateTime']['input'];
  startDateTime: Scalars['DateTime']['input'];
  transactionId: Scalars['UUID']['input'];
  type: CreateTransactionProcessingTimeType;
};

export type CreateTransactionProcessingTimeMutationResponse = {
  __typename?: 'CreateTransactionProcessingTimeMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  transactionProcessingTime?: Maybe<TransactionProcessingTime>;
};

export enum CreateTransactionProcessingTimeType {
  CompletedBarcodeTransaction = 'COMPLETED_BARCODE_TRANSACTION',
  CompletedCardProcessing = 'COMPLETED_CARD_PROCESSING',
  CompletedCardTransaction = 'COMPLETED_CARD_TRANSACTION',
  CompletedCashTransaction = 'COMPLETED_CASH_TRANSACTION'
}

export type CreateVipMemberIngressInput = {
  idempotencyKey: Scalars['UUID']['input'];
  vipMemberId: Scalars['UUID']['input'];
};

export type Device = {
  __typename?: 'Device';
  /** This is the Citifyd Organization ID representing where this device belongs to. */
  citifydOrganizationId: Scalars['CitifydID']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The current device provision. This could be null if no provision has been created. */
  currentProvision?: Maybe<Provision>;
  /** If an agent is currently connected, their session will be shown here. */
  currentSession?: Maybe<Session>;
  /** This enables or disables a device. A disabled device will not be able to be provisioned. */
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The device IMEI value (if any). */
  imei?: Maybe<Scalars['String']['output']>;
  /** The last online represents the last time we've been able to receive a successful heartbeat from the device. */
  lastOnline: Scalars['DateTime']['output'];
  /** The manufacturer of the device. */
  make: Scalars['String']['output'];
  /** The model identifier of the device. */
  model: Scalars['String']['output'];
  /** The device name for easier identification. */
  name?: Maybe<Scalars['String']['output']>;
  /** The name of the network carrier, such as T-Mobile or Verizon. */
  networkCarrierName?: Maybe<Scalars['String']['output']>;
  /** An optional date for the next maintenance date for this device. */
  nextMaintenanceScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The device serial number (if any). */
  serial?: Maybe<Scalars['String']['output']>;
  /**
   * A device short code is used for easy provision of devices.
   *
   * It will be automatically generated if no value is provided.
   */
  shortCode: Scalars['String']['output'];
  /** The state of the device is made of different values representing it's current state, such as offline or active. */
  state: DeviceState;
  /** The status of the device is made of different properties representing the overall health. */
  status?: Maybe<DeviceStatus>;
  /** Tally for currently active session, or most recent session. */
  tally: Tally;
  updatedAt: Scalars['DateTime']['output'];
  /** The vendor ID is provided by our MDM. */
  vendorId?: Maybe<Scalars['String']['output']>;
};

export type DeviceMutationResponse = MutationResponse & {
  __typename?: 'DeviceMutationResponse';
  code: Scalars['Int']['output'];
  device?: Maybe<Device>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** A set of values that represent the state of a device. */
export enum DeviceState {
  /** This device is active and being used to process transactions. */
  Active = 'ACTIVE',
  /** This device is offline an unreachable from Citifyd. */
  Offline = 'OFFLINE',
  /** This device is provisioned and waiting authentication. */
  Provisioned = 'PROVISIONED',
  /** This device is ready to be provisioned. */
  Ready = 'READY'
}

/** A common set of values that represent the status of a device. */
export type DeviceStatus = {
  __typename?: 'DeviceStatus';
  batteryHealth?: Maybe<DeviceStatusValue>;
  batteryPercentage?: Maybe<DeviceStatusValue>;
  /** The type of connection the device is currently using. Wifi, 3G, etc */
  connectionMethod?: Maybe<DeviceStatusValue>;
  /** Good, poor, etc */
  connectionStrength?: Maybe<DeviceStatusValue>;
  /** The device's last known latitude. */
  latitude?: Maybe<DeviceStatusValue>;
  /** The device's last known longitude. */
  longitude?: Maybe<DeviceStatusValue>;
};

/**
 * A DeviceStatusValue type allows each value of the DeviceStatus type to have a last known date when
 * the value was updated.
 */
export type DeviceStatusValue = {
  __typename?: 'DeviceStatusValue';
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EndSessionInput = {
  id: Scalars['ID']['input'];
};

export type ExemptionGroup = {
  __typename?: 'ExemptionGroup';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type ExemptionIngress = {
  __typename?: 'ExemptionIngress';
  createdAt: Scalars['DateTime']['output'];
  exemptionGroup: ExemptionGroup;
  id: Scalars['UUID']['output'];
};

export type ExemptionIngressMutationResponse = {
  __typename?: 'ExemptionIngressMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type GenerateConnectionTokenInput = {
  /** The target device vendor serial number to generate connection token. */
  deviceSerialNumber: Scalars['String']['input'];
};

export type MonetaryTallyCategory = {
  __typename?: 'MonetaryTallyCategory';
  money: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
};

export type MonitoringAppSessionMutationResponse = {
  __typename?: 'MonitoringAppSessionMutationResponse';
  accessToken?: Maybe<Scalars['String']['output']>;
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * This mutation updates the Citifyd gate of an ongoing provision.
   *
   * **App Authentication Required**
   */
  assignProvisionGate?: Maybe<ProvisionMutationResponse>;
  cancelPayment?: Maybe<PaymentMutationResponse>;
  /**
   * This mutation allows a provision to be canceled. Cancelling a provision will require a new provision
   * to authenticate a client.
   */
  cancelProvision?: Maybe<ProvisionMutationResponse>;
  /**
   * Creates a new POS agent. You must define a PIN for an agent.
   *
   * **App Authentication Required**
   */
  createAgent?: Maybe<AgentMutationResponse>;
  createCitifydIngress?: Maybe<CitifydIngressMutationResponse>;
  /**
   * Creates a new device.
   *
   * **App or Citifyd Authentication Required**
   */
  createDevice?: Maybe<DeviceMutationResponse>;
  createExemptionIngress?: Maybe<ExemptionIngressMutationResponse>;
  createMonitoringAppSession?: Maybe<MonitoringAppSessionMutationResponse>;
  createPayment?: Maybe<PaymentMutationResponse>;
  /**
   * This mutation allows a device to be provision for use. You can use startSession after the device
   * has been provisioned.
   *
   * **App Authentication Required**
   */
  createProvision?: Maybe<ProvisionMutationResponse>;
  createScan?: Maybe<ScanMutationResponse>;
  createTransactionProcessingTime?: Maybe<CreateTransactionProcessingTimeMutationResponse>;
  createVipMemberIngress?: Maybe<VipMemberIngressMutationResponse>;
  /**
   * This mutation ends the current agent session.
   *
   * **Bearer Authentication Required**
   */
  endCurrentSession?: Maybe<SessionMutationResponse>;
  /**
   * This mutation ends a specific session.
   *
   * **App Authentication Required**
   */
  endSession?: Maybe<SessionMutationResponse>;
  generateConnectionToken?: Maybe<ConnectionTokenMutationResponse>;
  /**
   * This mutation starts a session. You can use the session ID as the bearer token
   * for additional queries.
   */
  startSession?: Maybe<SessionMutationResponse>;
  /**
   * Updates a POS agent. A new PIN can be set using this mutation.
   *
   * **App Authentication Required**
   */
  updateAgent?: Maybe<AgentMutationResponse>;
  /**
   * Updates the device status. Use this mutation to send status updates.
   * All inputs are optional. Using this mutation without any fields will update its lastOnline value.
   */
  updateDeviceStatus?: Maybe<UpdateDeviceStatusMutationResponse>;
  /**
   * This mutation allows a device provision to be updated.
   *
   * **App Authentication Required**
   */
  updateProvision?: Maybe<ProvisionMutationResponse>;
};


export type MutationAssignProvisionGateArgs = {
  input: AssignProvisionGateInput;
};


export type MutationCancelPaymentArgs = {
  payment: CancelPaymentInput;
};


export type MutationCancelProvisionArgs = {
  provision: CancelProvisionInput;
};


export type MutationCreateAgentArgs = {
  agent: CreateAgentInput;
};


export type MutationCreateCitifydIngressArgs = {
  citifydIngress: CreateCitifydIngressInput;
};


export type MutationCreateDeviceArgs = {
  device: CreateDeviceInput;
};


export type MutationCreateExemptionIngressArgs = {
  exemptionIngress: CreateExemptionIngressInput;
};


export type MutationCreatePaymentArgs = {
  payment: CreatePaymentInput;
};


export type MutationCreateProvisionArgs = {
  provision: CreateProvisionInput;
};


export type MutationCreateScanArgs = {
  scan: CreateScanInput;
};


export type MutationCreateTransactionProcessingTimeArgs = {
  input: CreateTransactionProcessingTimeInput;
};


export type MutationCreateVipMemberIngressArgs = {
  vipMemberIngress: CreateVipMemberIngressInput;
};


export type MutationEndSessionArgs = {
  session: EndSessionInput;
};


export type MutationGenerateConnectionTokenArgs = {
  input: GenerateConnectionTokenInput;
};


export type MutationStartSessionArgs = {
  session: StartSessionInput;
};


export type MutationUpdateAgentArgs = {
  agent: UpdateAgentInput;
};


export type MutationUpdateDeviceStatusArgs = {
  device: UpdateDeviceStatusInput;
};


export type MutationUpdateProvisionArgs = {
  provision: UpdateProvisionInput;
};

export type MutationResponse = {
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type NonMonetaryTallyCategory = {
  __typename?: 'NonMonetaryTallyCategory';
  quantity: Scalars['Int']['output'];
};

/** Learn more on [Relay's Cursor Connection Spec](https://facebook.github.io/relay/graphql/connections.htm). */
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Payment = {
  __typename?: 'Payment';
  card?: Maybe<PaymentCard>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastPaymentError?: Maybe<PaymentError>;
  paymentErrors: Array<PaymentError>;
  rate: CitifydPosRate;
  session: Session;
  status: PaymentStatus;
  transaction?: Maybe<Transaction>;
  type: PaymentType;
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  brand: Scalars['String']['output'];
  funding: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  last4: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PaymentError = {
  __typename?: 'PaymentError';
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  type: PaymentErrorType;
};

export enum PaymentErrorType {
  Generic = 'GENERIC',
  RateConfigurationMismatch = 'RATE_CONFIGURATION_MISMATCH'
}

export type PaymentMutationResponse = {
  __typename?: 'PaymentMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  payment?: Maybe<Payment>;
  success: Scalars['Boolean']['output'];
};

export enum PaymentStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export enum PaymentType {
  Card = 'CARD',
  Cash = 'CASH'
}

/**
 * Provisions are created when a parking manager assigns an event and lot to a POS device.
 *
 * Provisions are short-lived for security purposes. They can be canceled from the target device or by a manager.
 * A device can only have one provision at a time.
 */
export type Provision = {
  __typename?: 'Provision';
  /** The Citifyd Event for which this provision belongs to. */
  citifydEvent: CitifydEvent;
  /** The Citifyd Event for which this provision belongs to. */
  citifydEventId: Scalars['CitifydID']['output'];
  /** The Citifyd Gate for which this provision was attached to. */
  citifydGate?: Maybe<CitifydGate>;
  /** The Citifyd Gate for which this provision was attached to. No gate will be provided if agent must scan a gate versatile code. */
  citifydGateId?: Maybe<Scalars['CitifydID']['output']>;
  /** The Citifyd Lot for which this provision belongs to. */
  citifydLot: CitifydEventLot;
  /** The Citifyd Lot for which this provision belongs to. */
  citifydLotId: Scalars['CitifydID']['output'];
  /** The Citifyd Manager User that created this provision. */
  citifydManagerUser: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The device for which this provision belongs to. */
  device: Device;
  /** The date when the provision was expired. */
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date and time when the provision is set to expire. */
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProvisionMutationResponse = MutationResponse & {
  __typename?: 'ProvisionMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  provision: Provision;
  success: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  agents: Array<Agent>;
  /** Retrieves Citifyd lots */
  citifydLots: Array<CitifydLot>;
  /**
   * This query returns the current session information.
   *
   * **Bearer Authentication Required**
   */
  currentSession: Session;
  /**
   * Retrieves a specific device.
   *
   * TODO: add authentication back. We removed the authentication as the POS device
   * expects this endpoint to not have authentication, and use the ID as a secret.
   * We should change this to add an additional column as a secret that only the device knows
   * and authenticate using it.
   */
  device: Device;
  /**
   * Retrieves a list of devices.
   *
   * **App Authentication Required**
   */
  devices?: Maybe<Array<Maybe<Device>>>;
  exemptionGroups: Array<ExemptionGroup>;
  /** Retrieves Citifyd events */
  futureCitifydEvents: Array<CitifydEvent>;
  tally: Tally;
  transactions: TransactionConnection;
  vipMembers: Array<VipMember>;
};


export type QueryCitifydLotsArgs = {
  citifydOrganizationId?: InputMaybe<Scalars['CitifydID']['input']>;
};


export type QueryDeviceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDevicesArgs = {
  citifydOrganizationId?: InputMaybe<Scalars['CitifydID']['input']>;
};


export type QueryFutureCitifydEventsArgs = {
  citifydOrganizationId?: InputMaybe<Scalars['CitifydID']['input']>;
};


export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order: TransactionOrderBy;
  type?: InputMaybe<TransactionType>;
};

export type Scan = {
  __typename?: 'Scan';
  barcodeValue: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** In case of error, this field contains more information about what happened. */
  errorType?: Maybe<ScanErrorType>;
  id: Scalars['ID']['output'];
  /**
   * The outcome of the scan.
   * It can be used by the client to determine what to do next.
   */
  outcome: ScanOutcome;
  transaction?: Maybe<Transaction>;
  updatedAt: Scalars['DateTime']['output'];
};

export enum ScanErrorType {
  /**
   * The barcode scanned belongs to a single-entry pass that has already been scanned previously, so
   * it can't be scanned again.
   */
  BarcodeAlreadyScanned = 'BARCODE_ALREADY_SCANNED',
  /** The barcode scanned couldn't be found. */
  BarcodeNotFound = 'BARCODE_NOT_FOUND',
  /**
   * The error type couldn't be identified.
   * The client can retry the operation passing the same "idempotencyKey".
   */
  Generic = 'GENERIC'
}

export type ScanMutationResponse = {
  __typename?: 'ScanMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  scan?: Maybe<Scan>;
  success: Scalars['Boolean']['output'];
};

export enum ScanOutcome {
  /**
   * The registration of a barcode coming from a Citifyd Receipt Card failed.
   * When this is the outcome, "errorType" will contain more details about the error.
   */
  BarcodeRegistrationFailed = 'BARCODE_REGISTRATION_FAILED',
  /**
   * The scan of an existing barcode failed.
   * When this is the outcome, "errorType" will contain more details about the error.
   */
  BarcodeScanFailed = 'BARCODE_SCAN_FAILED',
  /**
   * An existing barcode has been scanned successfuly.
   * When this is the outcome, "errorType" will always be null.
   */
  ExistingBarcodeScanned = 'EXISTING_BARCODE_SCANNED',
  /**
   * When a Citifyd Receipt Card is scanned for the first time, the
   * barcode scanned is associated with the latest purchase.
   * When this is the outcome, "errorType" will always be null.
   */
  NewBarcodeRegistered = 'NEW_BARCODE_REGISTERED'
}

export type Session = {
  __typename?: 'Session';
  /** The agent assigned to this session. */
  agent: Agent;
  createdAt: Scalars['DateTime']['output'];
  /** The device assigned to this session. */
  device: Device;
  /**
   * The date and time when this session was ended. This could be the time when an agent
   * or manager finalized a session, or when the session has expired.
   */
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The expiration date of the session. It is automatically set when a session is created. */
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** A session always starts from a device provision. */
  provision: Provision;
  updatedAt: Scalars['DateTime']['output'];
};

export type SessionMutationResponse = MutationResponse & {
  __typename?: 'SessionMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  session: Session;
  success: Scalars['Boolean']['output'];
};

export type StartSessionInput = {
  deviceSerialId: Scalars['String']['input'];
  /** This is the agent's defined personal identification number. */
  pin: Scalars['String']['input'];
};

export enum Strategy {
  CitifydSession = 'CITIFYD_SESSION',
  InternalIntegration = 'INTERNAL_INTEGRATION',
  Session = 'SESSION'
}

export type Subscription = {
  __typename?: 'Subscription';
  /**
   * Use this subscription to listen for incoming provisions for the specified device.
   *
   * After receiving a provision, use the mutation startSession to authenticate an agent.
   */
  provisionCreated: Provision;
  /** Use this subscription to listen for updates to the provision. */
  provisionUpdated: Provision;
  /**
   * Use this subscription to listen for session extensions or terminations. A session
   * ID is required.
   */
  sessionUpdated: Session;
  /**
   * Use this subscription to listen for incoming provisions for the specified device.
   *
   * After receiving a provision, use the mutation startSession to authenticate an agent.
   */
  transactionCreated: Transaction;
  /** Use this subscription to listen for updates to the provision. */
  transactionUpdated: Transaction;
};


export type SubscriptionProvisionCreatedArgs = {
  deviceSerialId: Scalars['String']['input'];
};


export type SubscriptionProvisionUpdatedArgs = {
  deviceSerialId: Scalars['String']['input'];
};


export type SubscriptionSessionUpdatedArgs = {
  id: Scalars['UUID']['input'];
};


export type SubscriptionTransactionCreatedArgs = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
};


export type SubscriptionTransactionUpdatedArgs = {
  deviceId?: InputMaybe<Scalars['ID']['input']>;
};

export type Tally = {
  __typename?: 'Tally';
  card: MonetaryTallyCategory;
  cash: MonetaryTallyCategory;
  citifydIngresses: NonMonetaryTallyCategory;
  exemptions: NonMonetaryTallyCategory;
  scans: NonMonetaryTallyCategory;
  vip: NonMonetaryTallyCategory;
};

export type Transaction = {
  __typename?: 'Transaction';
  agent?: Maybe<Agent>;
  citifydEvent?: Maybe<CitifydEvent>;
  citifydEventId?: Maybe<Scalars['CitifydID']['output']>;
  citifydGate?: Maybe<CitifydGate>;
  citifydGateId?: Maybe<Scalars['CitifydID']['output']>;
  citifydIngress?: Maybe<CitifydIngress>;
  citifydLot?: Maybe<CitifydLot>;
  citifydLotId?: Maybe<Scalars['CitifydID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  device?: Maybe<Device>;
  exemptionIngress?: Maybe<ExemptionIngress>;
  id: Scalars['ID']['output'];
  payment?: Maybe<Payment>;
  scan?: Maybe<Scan>;
  ticketCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  ticketId?: Maybe<Scalars['CitifydID']['output']>;
  type: TransactionType;
  updatedAt: Scalars['DateTime']['output'];
  vipMemberIngress?: Maybe<VipMemberIngress>;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  edges?: Maybe<Array<Maybe<TransactionEdge>>>;
  pageInfo: PageInfo;
  total: Scalars['Int']['output'];
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Transaction>;
};

export enum TransactionOrderBy {
  CreatedAt = 'CREATED_AT'
}

export type TransactionProcessingTime = {
  __typename?: 'TransactionProcessingTime';
  createdAt: Scalars['DateTime']['output'];
  endDateTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  startDateTime: Scalars['DateTime']['output'];
  transaction: Transaction;
  type: CreateTransactionProcessingTimeType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum TransactionType {
  CitifydIngress = 'CITIFYD_INGRESS',
  ExemptionIngress = 'EXEMPTION_INGRESS',
  Payment = 'PAYMENT',
  Scan = 'SCAN',
  VipMemberIngress = 'VIP_MEMBER_INGRESS'
}

export type UpdateAgentInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  pin?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDeviceStatusInput = {
  serial: Scalars['String']['input'];
  status?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateDeviceStatusMutationResponse = MutationResponse & {
  __typename?: 'UpdateDeviceStatusMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type UpdateProvisionInput = {
  citifydEventId?: InputMaybe<Scalars['CitifydID']['input']>;
  /** Citifyd Gate ID to update for the provision. A gate can be updated using the citifydGateVersatileCode input as well. */
  citifydGateId?: InputMaybe<Scalars['CitifydID']['input']>;
  /** Versatile code content to update the gate provision. A gate can be updated by specifying a gate ID as well. */
  citifydGateVersatileCode?: InputMaybe<Scalars['String']['input']>;
  /** The target device vendor ID to update the provision. */
  deviceSerialId: Scalars['String']['input'];
  /**
   * By default, this mutation will only fetch updated event information from the Citifyd API
   * if you update the CitifydEventId.
   * If you want to force it to re-fetch the data, set this to true.
   */
  forceRefreshOfCitifydData?: Scalars['Boolean']['input'];
};

export type VipMember = {
  __typename?: 'VipMember';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type VipMemberIngress = {
  __typename?: 'VipMemberIngress';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  vipMember: VipMember;
};

export type VipMemberIngressMutationResponse = {
  __typename?: 'VipMemberIngressMutationResponse';
  code: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type DeviceFragment = { __typename?: 'Device', id: string, name?: string | null, shortCode: string, state: DeviceState, make: string, model: string, enabled: boolean, lastOnline: any, serial?: string | null, imei?: string | null, networkCarrierName?: string | null, citifydOrganizationId: any, currentSession?: { __typename?: 'Session', id: string, agent: { __typename?: 'Agent', name: string } } | null, currentProvision?: { __typename?: 'Provision', citifydLot: { __typename?: 'CitifydEventLot', name: string, id: any, timezoneName: string, paymentDestination: { __typename?: 'CitifydPaymentDestination', currency: string }, gates: Array<{ __typename?: 'CitifydGate', id: any, name: string }> }, citifydGate?: { __typename?: 'CitifydGate', id: any, name: string } | null, citifydEvent: { __typename?: 'CitifydEvent', name: string, id: any, timezoneName: string, lots: Array<{ __typename?: 'CitifydEventLot', id: any, name: string, gates: Array<{ __typename?: 'CitifydGate', id: any, name: string }> }> } } | null, tally: { __typename?: 'Tally', scans: { __typename?: 'NonMonetaryTallyCategory', quantity: number }, citifydIngresses: { __typename?: 'NonMonetaryTallyCategory', quantity: number }, exemptions: { __typename?: 'NonMonetaryTallyCategory', quantity: number }, card: { __typename?: 'MonetaryTallyCategory', quantity: number, money: number }, cash: { __typename?: 'MonetaryTallyCategory', quantity: number, money: number } } };

export type CancelProvisionMutationVariables = Exact<{
  deviceSerialId: Scalars['String']['input'];
}>;


export type CancelProvisionMutation = { __typename?: 'Mutation', cancelProvision?: { __typename?: 'ProvisionMutationResponse', code: number, success: boolean, message: string, provision: { __typename?: 'Provision', id: string, device: { __typename?: 'Device', id: string, name?: string | null, serial?: string | null } } } | null };

export type CreateAgentMutationVariables = Exact<{
  name: Scalars['String']['input'];
  pin: Scalars['String']['input'];
}>;


export type CreateAgentMutation = { __typename?: 'Mutation', createAgent?: { __typename?: 'AgentMutationResponse', code: number, success: boolean, message: string, agent?: { __typename?: 'Agent', id: string, name: string, citifydOrganizationId: any } | null } | null };

export type CreateDeviceMutationVariables = Exact<{
  shortCode: Scalars['String']['input'];
  make: Scalars['String']['input'];
  model: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  networkCarrierName?: InputMaybe<Scalars['String']['input']>;
  imei?: InputMaybe<Scalars['String']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateDeviceMutation = { __typename?: 'Mutation', createDevice?: { __typename?: 'DeviceMutationResponse', code: number, success: boolean, message: string } | null };

export type CreateProvisionMutationVariables = Exact<{
  deviceId?: InputMaybe<Scalars['ID']['input']>;
  deviceShortCode?: InputMaybe<Scalars['String']['input']>;
  deviceSerialId?: InputMaybe<Scalars['String']['input']>;
  citifydGateId?: InputMaybe<Scalars['CitifydID']['input']>;
  citifydEventId: Scalars['CitifydID']['input'];
  citifydLotId: Scalars['CitifydID']['input'];
}>;


export type CreateProvisionMutation = { __typename?: 'Mutation', createProvision?: { __typename?: 'ProvisionMutationResponse', code: number, success: boolean, message: string, provision: { __typename?: 'Provision', id: string, device: { __typename?: 'Device', id: string, name?: string | null, serial?: string | null } } } | null };

export type EndSessionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type EndSessionMutation = { __typename?: 'Mutation', endSession?: { __typename?: 'SessionMutationResponse', code: number, success: boolean, message: string } | null };

export type UpdateAgentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  pin: Scalars['String']['input'];
}>;


export type UpdateAgentMutation = { __typename?: 'Mutation', updateAgent?: { __typename?: 'AgentMutationResponse', code: number, success: boolean, message: string, agent?: { __typename?: 'Agent', id: string, name: string, citifydOrganizationId: any } | null } | null };

export type UpdateProvisionMutationVariables = Exact<{
  deviceSerialId: Scalars['String']['input'];
  citifydGateId?: InputMaybe<Scalars['CitifydID']['input']>;
  citifydEventId?: InputMaybe<Scalars['CitifydID']['input']>;
}>;


export type UpdateProvisionMutation = { __typename?: 'Mutation', updateProvision?: { __typename?: 'ProvisionMutationResponse', code: number, success: boolean, message: string, provision: { __typename?: 'Provision', id: string, device: { __typename?: 'Device', id: string, name?: string | null, serial?: string | null } } } | null };

export type AgentsQueryVariables = Exact<{ [key: string]: never; }>;


export type AgentsQuery = { __typename?: 'Query', agents: Array<{ __typename?: 'Agent', id: string, name: string, citifydOrganizationId: any }> };

export type DeviceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeviceQuery = { __typename?: 'Query', device: { __typename?: 'Device', id: string, name?: string | null, shortCode: string, state: DeviceState, make: string, model: string, enabled: boolean, lastOnline: any, serial?: string | null, imei?: string | null, networkCarrierName?: string | null, citifydOrganizationId: any, currentSession?: { __typename?: 'Session', id: string, agent: { __typename?: 'Agent', name: string } } | null, currentProvision?: { __typename?: 'Provision', citifydLot: { __typename?: 'CitifydEventLot', name: string, id: any, timezoneName: string, paymentDestination: { __typename?: 'CitifydPaymentDestination', currency: string }, gates: Array<{ __typename?: 'CitifydGate', id: any, name: string }> }, citifydGate?: { __typename?: 'CitifydGate', id: any, name: string } | null, citifydEvent: { __typename?: 'CitifydEvent', name: string, id: any, timezoneName: string, lots: Array<{ __typename?: 'CitifydEventLot', id: any, name: string, gates: Array<{ __typename?: 'CitifydGate', id: any, name: string }> }> } } | null, tally: { __typename?: 'Tally', scans: { __typename?: 'NonMonetaryTallyCategory', quantity: number }, citifydIngresses: { __typename?: 'NonMonetaryTallyCategory', quantity: number }, exemptions: { __typename?: 'NonMonetaryTallyCategory', quantity: number }, card: { __typename?: 'MonetaryTallyCategory', quantity: number, money: number }, cash: { __typename?: 'MonetaryTallyCategory', quantity: number, money: number } } } };

export type DevicesQueryVariables = Exact<{
  citifydOrganizationId?: InputMaybe<Scalars['CitifydID']['input']>;
}>;


export type DevicesQuery = { __typename?: 'Query', devices?: Array<{ __typename?: 'Device', id: string, name?: string | null, shortCode: string, state: DeviceState, make: string, model: string, enabled: boolean, lastOnline: any, serial?: string | null, imei?: string | null, networkCarrierName?: string | null, citifydOrganizationId: any, currentSession?: { __typename?: 'Session', id: string, agent: { __typename?: 'Agent', name: string } } | null, currentProvision?: { __typename?: 'Provision', citifydLot: { __typename?: 'CitifydEventLot', name: string, id: any, timezoneName: string, paymentDestination: { __typename?: 'CitifydPaymentDestination', currency: string }, gates: Array<{ __typename?: 'CitifydGate', id: any, name: string }> }, citifydGate?: { __typename?: 'CitifydGate', id: any, name: string } | null, citifydEvent: { __typename?: 'CitifydEvent', name: string, id: any, timezoneName: string, lots: Array<{ __typename?: 'CitifydEventLot', id: any, name: string, gates: Array<{ __typename?: 'CitifydGate', id: any, name: string }> }> } } | null, tally: { __typename?: 'Tally', scans: { __typename?: 'NonMonetaryTallyCategory', quantity: number }, citifydIngresses: { __typename?: 'NonMonetaryTallyCategory', quantity: number }, exemptions: { __typename?: 'NonMonetaryTallyCategory', quantity: number }, card: { __typename?: 'MonetaryTallyCategory', quantity: number, money: number }, cash: { __typename?: 'MonetaryTallyCategory', quantity: number, money: number } } } | null> | null };

export type FutureCitifydEventsQueryVariables = Exact<{
  citifydOrganizationId?: InputMaybe<Scalars['CitifydID']['input']>;
}>;


export type FutureCitifydEventsQuery = { __typename?: 'Query', futureCitifydEvents: Array<{ __typename?: 'CitifydEvent', id: any, name: string, start: any, timezoneName: string, lots: Array<{ __typename?: 'CitifydEventLot', id: any, name: string, timezoneName: string, gates: Array<{ __typename?: 'CitifydGate', id: any, name: string, type: string }>, paymentDestination: { __typename?: 'CitifydPaymentDestination', currency: string, stripeAccountId: string } }>, venue: { __typename?: 'CitifydVenue', id: any, name: string, countryCode: string } }> };

export type TransactionsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  order: TransactionOrderBy;
  type?: InputMaybe<TransactionType>;
}>;


export type TransactionsQuery = { __typename?: 'Query', transactions: { __typename?: 'TransactionConnection', total: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges?: Array<{ __typename?: 'TransactionEdge', node?: { __typename?: 'Transaction', id: string, createdAt: any, type: TransactionType, payment?: { __typename?: 'Payment', type: PaymentType, rate: { __typename?: 'CitifydPOSRate', rate: { __typename?: 'CitifydRate', type: CitifydRateType, value: number, fee: number } } } | null, citifydLot?: { __typename?: 'CitifydLot', paymentDestination: { __typename?: 'CitifydPaymentDestination', currency: string } } | null, device?: { __typename?: 'Device', shortCode: string } | null, agent?: { __typename?: 'Agent', name: string } | null, citifydGate?: { __typename?: 'CitifydGate', name: string } | null, citifydEvent?: { __typename?: 'CitifydEvent', name: string } | null } | null } | null> | null } };

export const DeviceFragmentDoc = gql`
    fragment Device on Device {
  id
  name
  shortCode
  state
  make
  model
  enabled
  lastOnline
  serial
  imei
  networkCarrierName
  citifydOrganizationId
  currentSession {
    id
    agent {
      name
    }
  }
  currentProvision {
    citifydLot {
      name
      id
      timezoneName
      paymentDestination {
        currency
      }
      gates {
        id
        name
      }
    }
    citifydGate {
      id
      name
    }
    citifydEvent {
      name
      id
      timezoneName
      lots {
        id
        name
        gates {
          id
          name
        }
      }
    }
  }
  tally {
    scans {
      quantity
    }
    citifydIngresses {
      quantity
    }
    exemptions {
      quantity
    }
    card {
      quantity
      money
    }
    cash {
      quantity
      money
    }
  }
}
    `;
export const CancelProvisionDocument = gql`
    mutation CancelProvision($deviceSerialId: String!) {
  cancelProvision(provision: {deviceSerialId: $deviceSerialId}) {
    code
    success
    message
    provision {
      id
      device {
        id
        name
        serial
      }
    }
  }
}
    `;
export type CancelProvisionMutationFn = Apollo.MutationFunction<CancelProvisionMutation, CancelProvisionMutationVariables>;

/**
 * __useCancelProvisionMutation__
 *
 * To run a mutation, you first call `useCancelProvisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelProvisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelProvisionMutation, { data, loading, error }] = useCancelProvisionMutation({
 *   variables: {
 *      deviceSerialId: // value for 'deviceSerialId'
 *   },
 * });
 */
export function useCancelProvisionMutation(baseOptions?: Apollo.MutationHookOptions<CancelProvisionMutation, CancelProvisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelProvisionMutation, CancelProvisionMutationVariables>(CancelProvisionDocument, options);
      }
export type CancelProvisionMutationHookResult = ReturnType<typeof useCancelProvisionMutation>;
export type CancelProvisionMutationResult = Apollo.MutationResult<CancelProvisionMutation>;
export type CancelProvisionMutationOptions = Apollo.BaseMutationOptions<CancelProvisionMutation, CancelProvisionMutationVariables>;
export const CreateAgentDocument = gql`
    mutation CreateAgent($name: String!, $pin: String!) {
  createAgent(agent: {name: $name, pin: $pin}) {
    agent {
      id
      name
      citifydOrganizationId
    }
    code
    success
    message
  }
}
    `;
export type CreateAgentMutationFn = Apollo.MutationFunction<CreateAgentMutation, CreateAgentMutationVariables>;

/**
 * __useCreateAgentMutation__
 *
 * To run a mutation, you first call `useCreateAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAgentMutation, { data, loading, error }] = useCreateAgentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      pin: // value for 'pin'
 *   },
 * });
 */
export function useCreateAgentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAgentMutation, CreateAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAgentMutation, CreateAgentMutationVariables>(CreateAgentDocument, options);
      }
export type CreateAgentMutationHookResult = ReturnType<typeof useCreateAgentMutation>;
export type CreateAgentMutationResult = Apollo.MutationResult<CreateAgentMutation>;
export type CreateAgentMutationOptions = Apollo.BaseMutationOptions<CreateAgentMutation, CreateAgentMutationVariables>;
export const CreateDeviceDocument = gql`
    mutation CreateDevice($shortCode: String!, $make: String!, $model: String!, $name: String, $enabled: Boolean!, $networkCarrierName: String, $imei: String, $serial: String) {
  createDevice(
    device: {shortCode: $shortCode, make: $make, model: $model, name: $name, enabled: $enabled, networkCarrierName: $networkCarrierName, imei: $imei, serial: $serial}
  ) {
    code
    success
    message
  }
}
    `;
export type CreateDeviceMutationFn = Apollo.MutationFunction<CreateDeviceMutation, CreateDeviceMutationVariables>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      shortCode: // value for 'shortCode'
 *      make: // value for 'make'
 *      model: // value for 'model'
 *      name: // value for 'name'
 *      enabled: // value for 'enabled'
 *      networkCarrierName: // value for 'networkCarrierName'
 *      imei: // value for 'imei'
 *      serial: // value for 'serial'
 *   },
 * });
 */
export function useCreateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeviceMutation, CreateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument, options);
      }
export type CreateDeviceMutationHookResult = ReturnType<typeof useCreateDeviceMutation>;
export type CreateDeviceMutationResult = Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<CreateDeviceMutation, CreateDeviceMutationVariables>;
export const CreateProvisionDocument = gql`
    mutation CreateProvision($deviceId: ID, $deviceShortCode: String, $deviceSerialId: String, $citifydGateId: CitifydID, $citifydEventId: CitifydID!, $citifydLotId: CitifydID!) {
  createProvision(
    provision: {deviceId: $deviceId, deviceShortCode: $deviceShortCode, deviceSerialId: $deviceSerialId, citifydGateId: $citifydGateId, citifydEventId: $citifydEventId, citifydLotId: $citifydLotId}
  ) {
    code
    success
    message
    provision {
      id
      device {
        id
        name
        serial
      }
    }
  }
}
    `;
export type CreateProvisionMutationFn = Apollo.MutationFunction<CreateProvisionMutation, CreateProvisionMutationVariables>;

/**
 * __useCreateProvisionMutation__
 *
 * To run a mutation, you first call `useCreateProvisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProvisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProvisionMutation, { data, loading, error }] = useCreateProvisionMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceShortCode: // value for 'deviceShortCode'
 *      deviceSerialId: // value for 'deviceSerialId'
 *      citifydGateId: // value for 'citifydGateId'
 *      citifydEventId: // value for 'citifydEventId'
 *      citifydLotId: // value for 'citifydLotId'
 *   },
 * });
 */
export function useCreateProvisionMutation(baseOptions?: Apollo.MutationHookOptions<CreateProvisionMutation, CreateProvisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProvisionMutation, CreateProvisionMutationVariables>(CreateProvisionDocument, options);
      }
export type CreateProvisionMutationHookResult = ReturnType<typeof useCreateProvisionMutation>;
export type CreateProvisionMutationResult = Apollo.MutationResult<CreateProvisionMutation>;
export type CreateProvisionMutationOptions = Apollo.BaseMutationOptions<CreateProvisionMutation, CreateProvisionMutationVariables>;
export const EndSessionDocument = gql`
    mutation EndSession($id: ID!) {
  endSession(session: {id: $id}) {
    code
    success
    message
  }
}
    `;
export type EndSessionMutationFn = Apollo.MutationFunction<EndSessionMutation, EndSessionMutationVariables>;

/**
 * __useEndSessionMutation__
 *
 * To run a mutation, you first call `useEndSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endSessionMutation, { data, loading, error }] = useEndSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEndSessionMutation(baseOptions?: Apollo.MutationHookOptions<EndSessionMutation, EndSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndSessionMutation, EndSessionMutationVariables>(EndSessionDocument, options);
      }
export type EndSessionMutationHookResult = ReturnType<typeof useEndSessionMutation>;
export type EndSessionMutationResult = Apollo.MutationResult<EndSessionMutation>;
export type EndSessionMutationOptions = Apollo.BaseMutationOptions<EndSessionMutation, EndSessionMutationVariables>;
export const UpdateAgentDocument = gql`
    mutation UpdateAgent($id: ID!, $name: String!, $pin: String!) {
  updateAgent(agent: {id: $id, name: $name, pin: $pin}) {
    agent {
      id
      name
      citifydOrganizationId
    }
    code
    success
    message
  }
}
    `;
export type UpdateAgentMutationFn = Apollo.MutationFunction<UpdateAgentMutation, UpdateAgentMutationVariables>;

/**
 * __useUpdateAgentMutation__
 *
 * To run a mutation, you first call `useUpdateAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAgentMutation, { data, loading, error }] = useUpdateAgentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      pin: // value for 'pin'
 *   },
 * });
 */
export function useUpdateAgentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAgentMutation, UpdateAgentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAgentMutation, UpdateAgentMutationVariables>(UpdateAgentDocument, options);
      }
export type UpdateAgentMutationHookResult = ReturnType<typeof useUpdateAgentMutation>;
export type UpdateAgentMutationResult = Apollo.MutationResult<UpdateAgentMutation>;
export type UpdateAgentMutationOptions = Apollo.BaseMutationOptions<UpdateAgentMutation, UpdateAgentMutationVariables>;
export const UpdateProvisionDocument = gql`
    mutation UpdateProvision($deviceSerialId: String!, $citifydGateId: CitifydID, $citifydEventId: CitifydID) {
  updateProvision(
    provision: {deviceSerialId: $deviceSerialId, citifydGateId: $citifydGateId, citifydEventId: $citifydEventId}
  ) {
    code
    success
    message
    provision {
      id
      device {
        id
        name
        serial
      }
    }
  }
}
    `;
export type UpdateProvisionMutationFn = Apollo.MutationFunction<UpdateProvisionMutation, UpdateProvisionMutationVariables>;

/**
 * __useUpdateProvisionMutation__
 *
 * To run a mutation, you first call `useUpdateProvisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProvisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProvisionMutation, { data, loading, error }] = useUpdateProvisionMutation({
 *   variables: {
 *      deviceSerialId: // value for 'deviceSerialId'
 *      citifydGateId: // value for 'citifydGateId'
 *      citifydEventId: // value for 'citifydEventId'
 *   },
 * });
 */
export function useUpdateProvisionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProvisionMutation, UpdateProvisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProvisionMutation, UpdateProvisionMutationVariables>(UpdateProvisionDocument, options);
      }
export type UpdateProvisionMutationHookResult = ReturnType<typeof useUpdateProvisionMutation>;
export type UpdateProvisionMutationResult = Apollo.MutationResult<UpdateProvisionMutation>;
export type UpdateProvisionMutationOptions = Apollo.BaseMutationOptions<UpdateProvisionMutation, UpdateProvisionMutationVariables>;
export const AgentsDocument = gql`
    query Agents {
  agents {
    id
    name
    citifydOrganizationId
  }
}
    `;

/**
 * __useAgentsQuery__
 *
 * To run a query within a React component, call `useAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAgentsQuery(baseOptions?: Apollo.QueryHookOptions<AgentsQuery, AgentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgentsQuery, AgentsQueryVariables>(AgentsDocument, options);
      }
export function useAgentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgentsQuery, AgentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgentsQuery, AgentsQueryVariables>(AgentsDocument, options);
        }
export type AgentsQueryHookResult = ReturnType<typeof useAgentsQuery>;
export type AgentsLazyQueryHookResult = ReturnType<typeof useAgentsLazyQuery>;
export type AgentsQueryResult = Apollo.QueryResult<AgentsQuery, AgentsQueryVariables>;
export const DeviceDocument = gql`
    query Device($id: ID!) {
  device(id: $id) {
    ...Device
  }
}
    ${DeviceFragmentDoc}`;

/**
 * __useDeviceQuery__
 *
 * To run a query within a React component, call `useDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeviceQuery(baseOptions: Apollo.QueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, options);
      }
export function useDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, options);
        }
export type DeviceQueryHookResult = ReturnType<typeof useDeviceQuery>;
export type DeviceLazyQueryHookResult = ReturnType<typeof useDeviceLazyQuery>;
export type DeviceQueryResult = Apollo.QueryResult<DeviceQuery, DeviceQueryVariables>;
export const DevicesDocument = gql`
    query Devices($citifydOrganizationId: CitifydID) {
  devices(citifydOrganizationId: $citifydOrganizationId) {
    ...Device
  }
}
    ${DeviceFragmentDoc}`;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *      citifydOrganizationId: // value for 'citifydOrganizationId'
 *   },
 * });
 */
export function useDevicesQuery(baseOptions?: Apollo.QueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
      }
export function useDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
        }
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = Apollo.QueryResult<DevicesQuery, DevicesQueryVariables>;
export const FutureCitifydEventsDocument = gql`
    query FutureCitifydEvents($citifydOrganizationId: CitifydID) {
  futureCitifydEvents(citifydOrganizationId: $citifydOrganizationId) {
    id
    name
    start
    timezoneName
    lots {
      id
      name
      timezoneName
      gates {
        id
        name
        type
      }
      paymentDestination {
        currency
        stripeAccountId
      }
    }
    venue {
      id
      name
      countryCode
    }
  }
}
    `;

/**
 * __useFutureCitifydEventsQuery__
 *
 * To run a query within a React component, call `useFutureCitifydEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFutureCitifydEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFutureCitifydEventsQuery({
 *   variables: {
 *      citifydOrganizationId: // value for 'citifydOrganizationId'
 *   },
 * });
 */
export function useFutureCitifydEventsQuery(baseOptions?: Apollo.QueryHookOptions<FutureCitifydEventsQuery, FutureCitifydEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FutureCitifydEventsQuery, FutureCitifydEventsQueryVariables>(FutureCitifydEventsDocument, options);
      }
export function useFutureCitifydEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FutureCitifydEventsQuery, FutureCitifydEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FutureCitifydEventsQuery, FutureCitifydEventsQueryVariables>(FutureCitifydEventsDocument, options);
        }
export type FutureCitifydEventsQueryHookResult = ReturnType<typeof useFutureCitifydEventsQuery>;
export type FutureCitifydEventsLazyQueryHookResult = ReturnType<typeof useFutureCitifydEventsLazyQuery>;
export type FutureCitifydEventsQueryResult = Apollo.QueryResult<FutureCitifydEventsQuery, FutureCitifydEventsQueryVariables>;
export const TransactionsDocument = gql`
    query Transactions($first: Int, $last: Int, $after: String, $before: String, $order: TransactionOrderBy!, $type: TransactionType) {
  transactions(
    first: $first
    last: $last
    after: $after
    before: $before
    order: $order
    type: $type
  ) {
    total
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        createdAt
        type
        payment {
          type
          rate {
            rate {
              type
              value
              fee
            }
          }
        }
        citifydLot {
          paymentDestination {
            currency
          }
        }
        device {
          shortCode
        }
        agent {
          name
        }
        citifydGate {
          name
        }
        citifydEvent {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useTransactionsQuery__
 *
 * To run a query within a React component, call `useTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      order: // value for 'order'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useTransactionsQuery(baseOptions: Apollo.QueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
      }
export function useTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsQuery, TransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsQuery, TransactionsQueryVariables>(TransactionsDocument, options);
        }
export type TransactionsQueryHookResult = ReturnType<typeof useTransactionsQuery>;
export type TransactionsLazyQueryHookResult = ReturnType<typeof useTransactionsLazyQuery>;
export type TransactionsQueryResult = Apollo.QueryResult<TransactionsQuery, TransactionsQueryVariables>;