import React from "react";
import Table from "../Table";
import moment from "moment-timezone";

import { Text } from "@citifyd/style";

import HighlightText from "../HighlightText";
import { useTranslator, useService } from "../../hooks";

export default function AppendableEventsList({
  events = [],
  maxDistance,
  searchQuery,
}) {
  const t = useTranslator();
  const $state = useService("$state");

  if (!events.length) {
    return null;
  }

  const handleClick = (id) => {
    $state.go("edit-event-properties", { eventId: id, maxDistance });
  };

  const columns = [
    {
      value: t("appendableEvents.date"),
      width: "20%",
      key: "date",
    },
    {
      value: t("appendableEvents.event"),
      width: "50%",
      key: "event",
    },
    {
      value: t("appendableEvents.nearbyProp"),
      width: "20%",
      key: "nearby",
    },
  ];

  const eventsFiltered = events.filter((event) =>
    event.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
  );

  const data = eventsFiltered.map((event) => {
    const date = t("defaultFormats.date", {
      date: moment(event.start).tz(event.timezoneName),
    });

    const isScheduled = event.lots.length > 0;

    return {
      onClick: () => handleClick(event.id),
      appearance: isScheduled && "gray",
      date,
      event: <HighlightText text={event.name} highlight={searchQuery} />,
      nearby: isScheduled
        ? t("appendableEvents.scheduled")
        : t("appendableEvents.amountOfProperties", {
            count: event.nearbyLots.length,
          }),
    };
  });

  if (!eventsFiltered.length) {
    return <Text>{t("appendableEvents.noEventsFound")}</Text>;
  }

  return (
    <Table
      data={data}
      columns={columns}
      striped
      clickable
      showIconRight
      fixed
    />
  );
}
