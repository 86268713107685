import gql from "graphql-tag";

export default gql`
  query AdminRevenueByPropertyReport(
    $organizationId: Int
    $lotIds: [Int!]!
    $startTime: DateTime!
    $endTime: DateTime!
  ) {
    admin_revenueByPropertyReport(
      organizationId: $organizationId
      lotIds: $lotIds
      startTime: $startTime
      endTime: $endTime
    ) {
      lotId
      lotName
      totalTransactions
      revenue
      processingFees
      refundsIssued
      refundsAmount
      net
      citifydFees
      citifydServiceFeeApplied
      subsidiesPaid
      rates {
        rateName
        totalTransactions
        revenue
        processingFees
        net
        citifydFees
        citifydServiceFeeApplied
        subsidiesPaid
      }
    }
  }
`;
