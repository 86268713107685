"use strict";

import React, { useEffect, useRef, useMemo, useState } from "react";
import { uniqueId } from "lodash";
import produce from "immer";
import { AlertMessage, Button, Icon, Text } from "@citifyd/style";

import BoxContent from "../../../../shared/react/components/BoxContent";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import {
  saveMonthlyPlans,
  loadMonthlyPlans,
  isEditingMonthly,
  showEditingMonthlyAlert,
} from "./utils";

import ViewPlan from "./ViewPlan.jsx";
import PlanForm from "./PlanForm.jsx";
import styles from "./MonthlyParking.module.scss";
import { scrollTo } from "../../../../shared/services/helper";
import { getTranslator } from "../../../../shared/services/languages";
import StickyFooter from "../../../../shared/react/components/StickyFooter";
import RouterLink from "../../../../shared/react/components/RouterLink";
import useLoadTracking from "../../../../shared/react/hooks/useLoadTracking";
import { useStripeStatus } from "../../../../shared/react/contexts/stripeStatus";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import { goToNextStep } from "../goToNextStep";

const MonthlyParking = ({ lot, canConfigureGates }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const { reloadStripeStatus } = useStripeStatus();
  const { isLoading, loadingMessage, trackLoading, untrackLoading } =
    useLoadTracking({
      loadPlan: { message: t("addProperty.loadingMessages.setupDaily") },
      savePlan: { message: t("addProperty.loadingMessages.savingMonthly") },
    });
  const [monthlyPlans, setMonthlyPlans] = useState([]);
  const [hasError, setHasError] = useState();
  const monthlyPlanElementsRef = useRef({});
  const currency = lot.country?.currency;

  const onRemove = ({ index }) => {
    if (!monthlyPlans[index].removed) {
      if (!window.confirm(t("commonConfirmations.areYouSure"))) {
        return;
      }
    }

    if (monthlyPlans[index].id) {
      setMonthlyPlans(
        produce((monthlyPlans) => {
          monthlyPlans[index].removed = !monthlyPlans[index].removed;
        })
      );
    } else {
      setMonthlyPlans(
        produce((monthlyPlans) => {
          monthlyPlans.splice(index, 1);
        })
      );
    }
  };

  const onCancel = ({ index }) => {
    if (monthlyPlans[index].saved || monthlyPlans[index].id) {
      setMonthlyPlans(
        produce((monthlyPlans) => {
          monthlyPlans[index].showForm = false;
        })
      );
    } else {
      setMonthlyPlans(
        produce((monthlyPlans) => {
          monthlyPlans.splice(index, 1);
        })
      );
    }
  };

  const onEdit = ({ index }) => {
    setMonthlyPlans(
      produce((monthlyPlans) => {
        monthlyPlans[index].showForm = true;
      })
    );
  };

  const addPlan = () => {
    setMonthlyPlans((monthlyPlans) => [
      ...monthlyPlans,
      {
        name: "",
        daysOfWeek: {
          mon: true,
          tue: true,
          wed: true,
          thu: true,
          fri: true,
          sat: true,
          sun: true,
        },
        startTime: "00:00",
        endTime: "00:00",
        billingCycle: "1",
        cancellationNoticePeriod: "30",
        showForm: true,
        saved: false,
        removed: false,
        clientId: uniqueId(),
      },
    ]);

    setTimeout(() => {
      const lastElement = monthlyPlanElementsRef.current[monthlyPlans.length];
      if (lastElement) {
        scrollTo(lastElement);
      }
    }, 100);
  };

  const onSave = ({ values, index }) => {
    setMonthlyPlans(
      produce((monthlyPlans) => {
        monthlyPlans[index] = {
          ...monthlyPlans[index],
          ...values,
          showForm: false,
          saved: true,
        };
      })
    );
  };

  const isAnyPlanRemoved = useMemo(
    () => monthlyPlans.filter((monthlyPlan) => monthlyPlan.removed).length > 0,
    [monthlyPlans]
  );

  useEffect(() => {
    const load = async () => {
      try {
        trackLoading("loadPlan");
        setMonthlyPlans(await loadMonthlyPlans(lot));
      } catch (err) {
        setHasError(true);
      } finally {
        untrackLoading("loadPlan");
      }
    };
    load();
  }, []);

  const onSubmit = async () => {
    if (isEditingMonthly(monthlyPlans)) {
      return showEditingMonthlyAlert();
    }

    const success = await saveMonthlyPlans({
      lot,
      monthlyPlans,
      setMonthlyPlans,
      trackLoading,
      untrackLoading,
    });

    if (success) {
      if (canConfigureGates) {
        goToNextStep({ lotId: lot.id, step: 5 });
      } else {
        $state.transitionTo("dashboard");
        reloadStripeStatus();
      }
    }
  };

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    );
  }

  return (
    <div>
      <BoxContent>
        <BoxContent.Header
          title={t("addProperty.monthlyParking")}
        ></BoxContent.Header>

        <BoxContent.Content>
          {isAnyPlanRemoved && (
            <AlertMessage>{t("addProperty.removedPlansMessage")}</AlertMessage>
          )}
          {monthlyPlans.map((plan, index) => (
            <div
              key={index}
              className={styles.border}
              ref={(el) => (monthlyPlanElementsRef.current[index] = el)}
            >
              {plan.showForm ? (
                <PlanForm
                  plan={plan}
                  currency={currency}
                  index={index}
                  lot={lot}
                  onCancel={onCancel}
                  onSave={onSave}
                />
              ) : (
                <ViewPlan
                  currency={currency}
                  plan={plan}
                  onRemove={onRemove}
                  index={index}
                  onEdit={onEdit}
                />
              )}
            </div>
          ))}

          {monthlyPlans.length === 0 && <AddPlanButton addPlan={addPlan} />}
        </BoxContent.Content>

        {monthlyPlans.length > 0 && (
          <BoxContent.Footer appearance="white">
            <AddPlanButton addPlan={addPlan} />
          </BoxContent.Footer>
        )}
      </BoxContent>

      <StickyFooter isBlocked={false}>
        <RouterLink
          state="edit-property"
          params={{ lotId: lot.id }}
          className={styles.backLink}
        >
          <Icon size="subtitle" icon="chevron-left" appearance="white" />
          <Text variant="subtitle" appearance="white">
            {t("commonButtons.goBackWithoutSaving")}
          </Text>
        </RouterLink>

        <Button shadow extraPadding uppercase onMouseDown={() => onSubmit()}>
          {t("commonButtons.continue")}
        </Button>
      </StickyFooter>
    </div>
  );
};

const AddPlanButton = ({ addPlan }) => {
  const t = getTranslator();
  return (
    <div className={styles.button}>
      <Button
        justifyContent="center"
        shadow
        size="small"
        uppercase
        onClick={() => addPlan()}
      >
        {t("addProperty.addMonthlyPlan")}
      </Button>
    </div>
  );
};

export default MonthlyParking;
