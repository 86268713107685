import {
  DeviceQuery,
  useCancelProvisionMutation,
  useEndSessionMutation,
} from "../../../graphql-pos/generated/graphql";

import { useModal } from "../../../shared/react/contexts/modal";
import { usePosRoverCtx } from "./usePosRoverCtx";
import { useTranslator } from "../../../shared/react/hooks";

import { RoverAssignDeviceModal } from "../../../shared/modals/RoverAssignDeviceModal/RoverAssignDeviceModal";
import { RoverManageDeviceModal } from "../../../shared/modals/RoverManageDeviceModal/RoverManageDeviceModal";
import PosApolloClient from "../../../shared/services/posApolloClient";
import { showAlert } from "../../../shared/services/helper";
import { getGraphqlError } from "../../../shared/services/graphql-helper";

const posApolloClient = PosApolloClient();

export const ROVER_POLLING = 10000;

export const useDevicesActions = () => {
  const t = useTranslator();
  const { openModal } = useModal();
  const {
    devicesQuery: {
      processingDevices,
      devicesRefetch,
      startPollingDevices,
      stopPollingDevices,
    },
  } = usePosRoverCtx();

  const [cancelProvision] = useCancelProvisionMutation({
    client: posApolloClient,
  });

  const [endSession] = useEndSessionMutation({
    client: posApolloClient,
  });

  const onAssign = (device: DeviceQuery["device"]) => {
    stopPollingDevices();

    return openModal(RoverAssignDeviceModal, {
      device,
      onClose: async () => {
        processingDevices.add(device.id);
        try {
          await devicesRefetch();
        } catch (err) {
          console.log("Error occurred", err);
          const error = getGraphqlError(err);
          showAlert(error.message);
        } finally {
          processingDevices.remove(device.id);
          startPollingDevices(ROVER_POLLING);
        }
      },
    });
  };

  const onCancel = async (device: DeviceQuery["device"]) => {
    processingDevices.add(device.id);
    startPollingDevices();
    try {
      await cancelProvision({
        variables: { deviceSerialId: String(device.serial) },
      });
      await devicesRefetch();
    } catch (err) {
      console.log("Error occurred", err);
      const error = getGraphqlError(err);
      showAlert(error.message);
    } finally {
      processingDevices.remove(device.id);
      startPollingDevices(ROVER_POLLING);
    }
  };

  const onFinishButtonClick = async (device: DeviceQuery["device"]) => {
    if (
      window.confirm(
        t("posRover.devices.areYouSure", {
          device: device.shortCode,
          agent: device.currentSession?.agent.name,
        })
      )
    ) {
      try {
        processingDevices.add(device.id);
        await endSession({
          variables: { id: String(device.currentSession?.id) },
        });
        await devicesRefetch();
      } catch (err) {
        console.log("Error occurred", err);
        const error = getGraphqlError(err);
        showAlert(error.message);
      } finally {
        processingDevices.remove(device.id);
        startPollingDevices(ROVER_POLLING);
      }
    }
  };

  const onManageButtonClick = ({
    device,
    setSuccessMessage,
  }: {
    device: DeviceQuery["device"];
    setSuccessMessage?: (device: string) => any;
  }) => {
    openModal(RoverManageDeviceModal, {
      device,
      setSuccessMessage,
      onClose: async () => {
        processingDevices.add(device.id);
        try {
          await devicesRefetch();
        } catch (err) {
          console.log("Error occurred", err);
          const error = getGraphqlError(err);
          showAlert(error.message);
        } finally {
          processingDevices.remove(device.id);
          startPollingDevices(ROVER_POLLING);
        }
      },
    });
  };

  return { onAssign, onCancel, onFinishButtonClick, onManageButtonClick };
};
