import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  CountryCode: { input: any; output: any; }
  Date: { input: string; output: string; }
  DateTime: { input: any; output: any; }
  Duration: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Time: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export enum AccountPermissionType {
  Company = 'COMPANY',
  InternalUser = 'INTERNAL_USER'
}

export type Address = BrAddress | CaAddress | FiAddress | JpAddress | OtherAddress | UsAddress;

export type AddressInput = {
  _other?: InputMaybe<OtherAddressInput>;
  br?: InputMaybe<BrAddressInput>;
  ca?: InputMaybe<CaAddressInput>;
  fi?: InputMaybe<FiAddressInput>;
  jp?: InputMaybe<JpAddressInput>;
  us?: InputMaybe<UsAddressInput>;
};

export type AdminListPermitPassesConnection = {
  __typename?: 'AdminListPermitPassesConnection';
  edges: Array<PermitPassEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AdminListReservedParkingPassesConnection = {
  __typename?: 'AdminListReservedParkingPassesConnection';
  edges: Array<ReservedParkingPassEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AdminListTransactionsConnection = {
  __typename?: 'AdminListTransactionsConnection';
  edges: Array<TransactionsReportEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Amenity = {
  __typename?: 'Amenity';
  /** Unique code for this amenity. It can be used by clients to identify it and apply custom styling. */
  code: Scalars['String']['output'];
  /** Translated description */
  description?: Maybe<Scalars['String']['output']>;
  /** When filtering by this amenity, the amenities in this list -- identified by the code -- should be disabled */
  disables: Array<Scalars['String']['output']>;
  /** Translated name */
  label: Scalars['String']['output'];
};

export enum AuthStrategy {
  Anonymous = 'ANONYMOUS',
  Bearer = 'BEARER',
  InternalIntegrationKey = 'INTERNAL_INTEGRATION_KEY',
  Portal = 'PORTAL'
}

export type AutocompleteColor = {
  __typename?: 'AutocompleteColor';
  color: Scalars['String']['output'];
};

export type AutocompleteLicense = {
  __typename?: 'AutocompleteLicense';
  license: Scalars['String']['output'];
};

export type AutocompleteMake = {
  __typename?: 'AutocompleteMake';
  make: Scalars['String']['output'];
};

export type Availability = {
  __typename?: 'Availability';
  /** When this availability ended, or will end */
  endTime: Scalars['DateTime']['output'];
  /** ID of the availability */
  id: Scalars['Int']['output'];
  /** When this availability started, or will start */
  startTime: Scalars['DateTime']['output'];
};

/** Represents the allocation of an availability to a reservation or a reserved parking pass */
export type AvailabilityAllocation = {
  __typename?: 'AvailabilityAllocation';
  /** When the availability of this allocation ends */
  availabilityEndTime: Scalars['DateTime']['output'];
  /** When the availability of this allocation starts */
  availabilityStartTime: Scalars['DateTime']['output'];
  /**
   * When this allocation ended, or will end.
   * It might be null if the allocation hasn't ended yet.
   */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  /** Availability allocation rate */
  rate: Scalars['JSON']['output'];
  /** When this allocation started, or will start */
  startTime: Scalars['DateTime']['output'];
};

export type AvailabilityAllocationPriceCalculation = {
  __typename?: 'AvailabilityAllocationPriceCalculation';
  /**
   * End of the allocation applied to the calculation. Unlike the 'endTime' field of AvailabilityAllocation type,
   * this field is not nullable, as an end time must be considered for the price calculation.
   */
  endTime: Scalars['DateTime']['output'];
  /** Rate details */
  rate: AvailabilityAllocationPriceCalculationRateDetails;
  /** Start of the allocation applied to the calculation */
  startTime: Scalars['DateTime']['output'];
  /** Price calculated for this allocation */
  total: Scalars['Int']['output'];
};

/** Rate details for the availability allocation price calculation */
export type AvailabilityAllocationPriceCalculationRateDetails = {
  __typename?: 'AvailabilityAllocationPriceCalculationRateDetails';
  /** Maximum value applied, only available for hourly and fixed rate types */
  maxValue?: Maybe<Scalars['Int']['output']>;
  /** Rule name, only available for variable rate types */
  ruleName?: Maybe<Scalars['String']['output']>;
  /** Type of the rate */
  type: AvailabilityAllocationPriceCalculationRateType;
  /** Value is only available for hourly and fixed rate types */
  value?: Maybe<Scalars['Int']['output']>;
};

/** Type of the rate */
export enum AvailabilityAllocationPriceCalculationRateType {
  /** Fixed rate */
  Fixed = 'FIXED',
  /** Hourly rate */
  Hourly = 'HOURLY',
  /** Minimum charge */
  MinimumCharge = 'MINIMUM_CHARGE',
  /** Variable rate */
  Variable = 'VARIABLE'
}

/** This type contains an availability allocation and the price calculation details for it */
export type AvailabilityAllocationWithPriceCalculation = {
  __typename?: 'AvailabilityAllocationWithPriceCalculation';
  /** The availability allocation */
  availabilityAllocation: AvailabilityAllocation;
  /** Price calculation details */
  priceCalculation?: Maybe<AvailabilityAllocationPriceCalculation>;
};

export type Barcode = {
  __typename?: 'Barcode';
  /** Format of the code */
  type: BarcodeType;
  /** Data stored in the barcode */
  value: Scalars['String']['output'];
};

export enum BarcodeType {
  /** QR code format */
  Qrcode = 'QRCODE'
}

export type BrAddress = {
  __typename?: 'BrAddress';
  city: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type BrAddressInput = {
  city: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type BrVehicleLicenseInfoInput = {
  plate: Scalars['String']['input'];
};

export type BrVehicleLicenseInfoWithOptionalFields = {
  __typename?: 'BrVehicleLicenseInfoWithOptionalFields';
  plate: Scalars['String']['output'];
};

export type BrVehicleLicenseInfoWithOptionalFieldsInput = {
  plate: Scalars['String']['input'];
};

export type CaAddress = {
  __typename?: 'CaAddress';
  city: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type CaAddressInput = {
  city: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type CaVehicleLicenseInfoInput = {
  plate: Scalars['String']['input'];
  province: Scalars['String']['input'];
};

export type CaVehicleLicenseInfoWithOptionalFields = {
  __typename?: 'CaVehicleLicenseInfoWithOptionalFields';
  plate: Scalars['String']['output'];
  province?: Maybe<Scalars['String']['output']>;
};

export type CaVehicleLicenseInfoWithOptionalFieldsInput = {
  plate: Scalars['String']['input'];
  province?: InputMaybe<Scalars['String']['input']>;
};

/** Cancellation ( refund ) for ticket, reservation or reservedParkingPass */
export type Cancellation = {
  __typename?: 'Cancellation';
  /** Created date */
  createdAt: Scalars['DateTime']['output'];
  /** Entity types */
  entityType: CancellationEntityType;
  /** Fee retention method */
  feeRetentionMethod: FeeRetentionMethod;
  /** Cancellation ID */
  id: Scalars['Int']['output'];
  /** Is POS Cancellation? */
  isPosCancellation: Scalars['Boolean']['output'];
  /** Last refund attempt end date */
  lastRefundAttemptFinishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Last refund attempt start date */
  lastRefundAttemptStartedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Lot */
  lot: Lot;
  /** Cancel reason description */
  reason?: Maybe<Scalars['String']['output']>;
  /** Refund attempts */
  refundAttempts: Scalars['Int']['output'];
  /** Refund status */
  refunded: Scalars['Boolean']['output'];
  /** Refunder */
  refunder: Refunder;
  /** Was requested by email? */
  requestedByEmail?: Maybe<Scalars['Boolean']['output']>;
  /** Was requested by phone? */
  requestedByPhone?: Maybe<Scalars['Boolean']['output']>;
  /** Cancelled reservation details */
  reservation?: Maybe<Reservation>;
  /** Cancelled reserved parking pass details */
  reservedParkingPass?: Maybe<ReservedParkingPass>;
  /** Stripe fee payer */
  stripeFeePayer: StripeFeePayer;
  /** Cancelled ticket details */
  ticket?: Maybe<Ticket>;
  /** Transaction details */
  transactions: Array<Maybe<Transaction>>;
  /** Cancellation requester */
  user?: Maybe<User>;
};

/** Cancellation Entity Type */
export enum CancellationEntityType {
  /** reservation */
  Reservation = 'RESERVATION',
  /** reservedParkingPass */
  ReservedParkingPass = 'RESERVED_PARKING_PASS',
  /** ticket */
  Ticket = 'TICKET'
}

export type Card = {
  __typename?: 'Card';
  /** Address of the card */
  address?: Maybe<Scalars['JSON']['output']>;
  /** Card brand */
  brand: CardBrand;
  /** Country of the card */
  countryCode: Scalars['String']['output'];
  /** Expiration month */
  expMonth?: Maybe<Scalars['Int']['output']>;
  /** Expiration year */
  expYear?: Maybe<Scalars['Int']['output']>;
  /** Funding of the card */
  funding: CardFunding;
  /** ID of the card */
  id: Scalars['Int']['output'];
  /** Last 4 digits of the card */
  last4: Scalars['String']['output'];
  /** Billing name */
  name?: Maybe<Scalars['String']['output']>;
  /** Tokenization method */
  tokenizationMethod?: Maybe<CardTokenizationMethod>;
};

export enum CardBrand {
  AmericanExpress = 'AMERICAN_EXPRESS',
  DinersClub = 'DINERS_CLUB',
  Discover = 'DISCOVER',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  UnionPay = 'UNION_PAY',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export enum CardFunding {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Prepaid = 'PREPAID',
  Unknown = 'UNKNOWN'
}

/** Details about the card payment */
export type CardPaymentMethodDetails = {
  __typename?: 'CardPaymentMethodDetails';
  /** Card used for the payment */
  card: Card;
};

export enum CardTokenizationMethod {
  AndroidPay = 'ANDROID_PAY',
  ApplePay = 'APPLE_PAY'
}

/** Cloudinary  */
export type CloudinaryInput = {
  /** The name of the folder */
  cloudName: Scalars['String']['input'];
  /** The type of the transformation in the image, ex: t_on_demand_map_lot_image_2 */
  defaultTransformation: Scalars['String']['input'];
  /** The format of the image */
  format: Scalars['String']['input'];
  /** The id of the image, ex: citifyd-dev/lots/jokkb1sxqru8axk1nbdq */
  publicId: Scalars['String']['input'];
};

export type CoordinatesInput = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  /** Radius for search in kilometers */
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type Country = {
  __typename?: 'Country';
  /** Phone calling code for this country */
  callingCode: Scalars['String']['output'];
  /** Which continent this country belongs to */
  continent: Scalars['String']['output'];
  /** Default currency used on this country */
  currency: Scalars['String']['output'];
  /** Defines if this country has phone number pronunciation enabled */
  hasUserNamePronunciation: Scalars['Boolean']['output'];
  /** Country code */
  isoCode: Scalars['String']['output'];
  /** Default language of this country */
  language: Scalars['String']['output'];
  /** Defines if lot can be added to this country */
  lotAllowed: Scalars['Boolean']['output'];
  /** Name of the country, translated */
  name: Scalars['String']['output'];
  /** Defines if phone numbers from this country are supported */
  phoneNumberAllowed: Scalars['Boolean']['output'];
  /** Schemas for this country */
  schemas: CountrySchemas;
  /** Defines if vehicles can be added to this country */
  vehiclesAllowed: Scalars['Boolean']['output'];
};

export type CountrySchemas = {
  __typename?: 'CountrySchemas';
  /** Address schema */
  address: Scalars['JSON']['output'];
  /** Phone number schema */
  phoneNumber: Scalars['JSON']['output'];
  /** Vehicle license information schema */
  vehicleLicenseInfo: Scalars['JSON']['output'];
};

/** Create permit portal input. See PermitPortal for documentation. */
export type CreatePermitPortalInput = {
  /** Required. Non-nullable. */
  issuanceSettings: CreatePermitPortalIssuanceSettingsInput;
  /** Required. */
  logo?: InputMaybe<UploadedImageInput>;
  /** Optional. Nullable. */
  lots?: InputMaybe<Array<CreatePermitPortalLotInput>>;
  /** Required. Non-nullable. */
  name: Scalars['String']['input'];
  /** Required. Non-nullable. */
  namespace: Scalars['String']['input'];
};

/** See PermitPortalIssuanceSettings for documentation. */
export type CreatePermitPortalIssuanceSettingsInput = {
  /** Optional. Nullable. */
  maxFuturePermits?: InputMaybe<Scalars['Int']['input']>;
  /** Optional. Nullable. */
  maxHours?: InputMaybe<Scalars['Int']['input']>;
  /** Required. Non-nullable. */
  maxPeriodDuration: Scalars['Duration']['input'];
};

/** See PermitPortalIssuanceSettingsWeeklyAvailability for documentation. */
export type CreatePermitPortalIssuanceSettingsWeeklyAvailabilityInput = {
  /** Optional. Nullable. */
  fri?: InputMaybe<CreateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  mon?: InputMaybe<CreateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  sat?: InputMaybe<CreateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  sun?: InputMaybe<CreateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  thu?: InputMaybe<CreateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  tue?: InputMaybe<CreateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  wed?: InputMaybe<CreateWeeklyAvailabilityStartEndTimesInput>;
};

/** Create PermitPortalLot relationship input. */
export type CreatePermitPortalLotInput = {
  /** Required. Non-nullable. */
  issuanceSettings: CreatePermitPortalLotIssuanceSettingsInput;
  /** Required. Non-nullable. */
  lotId: Scalars['Int']['input'];
};

/** See PermitPortalLotIssuanceSettings for documentation. */
export type CreatePermitPortalLotIssuanceSettingsInput = {
  /** Optional. Nullable. */
  blockedDates?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** Optional. Nullable. */
  dailyMax?: InputMaybe<Scalars['Int']['input']>;
  /** Optional. Nullable. */
  maxConcurrentPermits?: InputMaybe<Scalars['Int']['input']>;
  /** Optional. Nullable. */
  mixedPermitsFreeLimit?: InputMaybe<Scalars['Int']['input']>;
  /** Optional. Nullable. */
  monthlyMax?: InputMaybe<Scalars['Int']['input']>;
  /** Required. Non-nullable. */
  permitChargeType?: PermitPortalIssuanceSettingsPermitChargeType;
  /** Required. Non-nullable. */
  weeklyAvailability: CreatePermitPortalIssuanceSettingsWeeklyAvailabilityInput;
};

export type CreatePermitPortalUserAccessInput = {
  id: Scalars['UUID']['input'];
};

export type CreatePermitPortalUserInput = {
  /** Date and time the access for this permit portal user expires. */
  accessExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The identifier of the user, such as its apartment number. */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** The name of the user. */
  name: Scalars['String']['input'];
  /** Array of permit portals that the user will manage */
  permitPortals: Array<CreatePermitPortalUserAccessInput>;
  /** The country code of the user's phone number. */
  phoneCountryCode: Scalars['String']['input'];
  /** The phone number of the user. */
  phoneNumber: Scalars['String']['input'];
};

/** See WeeklyAvailabilityStartEndTimes for documentation. */
export type CreateWeeklyAvailabilityStartEndTimesInput = {
  /** Required. Non-nullable. */
  endTime: Scalars['String']['input'];
  /** Required. Non-nullable. */
  startTime: Scalars['String']['input'];
};

/**
 * When there is no additional payment details.
 * We are required to have this type because the GraphQL always expects an union
 * (in this case, 'PaymentMethodDetails') to be resolved with a type.
 */
export type EmptyPaymentDetails = {
  __typename?: 'EmptyPaymentDetails';
  _?: Maybe<Scalars['Boolean']['output']>;
};

/** Direction to order a parking sessions list */
export enum EnforcementLotParkingSessionsDirection {
  /** Ascending order */
  Asc = 'ASC',
  /** descending order */
  Desc = 'DESC'
}

export enum EnforcementLotParkingSessionsOrderBy {
  /** Order by the color of the vehicle. */
  Color = 'COLOR',
  /** Order by the make of the vehicle. */
  Make = 'MAKE',
  /** Order by the license plate. */
  Plate = 'PLATE',
  /** Order by the amount of time left in the session. */
  TimeLeft = 'TIME_LEFT'
}

/** Statistics of active/parked sessions in a lot. */
export type EnforcementLotStats = {
  __typename?: 'EnforcementLotStats';
  expiredSessions: Scalars['Int']['output'];
  parkedSessions: Scalars['Int']['output'];
};

/** An autocomplete result can be a color, license plate, or make. */
export type EnforcementSessionAutocompleteResult = AutocompleteColor | AutocompleteLicense | AutocompleteMake;

/** An anonymous phone number that can be used to call or text the user. */
export type EnforcementTemporaryUserPhoneNumber = {
  __typename?: 'EnforcementTemporaryUserPhoneNumber';
  phoneCountryCode: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

/**
 * An event, such as a concert or a sports game.
 * Lots can be associated with an event so that people can park for it. Users can purchase passes for event parking,
 * these passes are called "tickets" on the Citifyd system.
 */
export type Event = {
  __typename?: 'Event';
  /** Event's default availability */
  defaultAvailability?: Maybe<Availability>;
  /** Event type code, e.g. concert, game */
  eventTypeCode?: Maybe<Scalars['String']['output']>;
  /** ID of the event */
  id: Scalars['Int']['output'];
  /** In waitlist check */
  inWaitlist: Scalars['Boolean']['output'];
  /** Event location latitude */
  latitude: Scalars['Float']['output'];
  /** Event location longitude */
  longitude: Scalars['Float']['output'];
  /** Event main photo */
  mainPhoto?: Maybe<UploadedImage>;
  /** Name of the event */
  name: Scalars['String']['output'];
  /** Postponed flag */
  postponed: Scalars['Boolean']['output'];
  /** Event location address in JSON scalar format */
  rawAddress?: Maybe<Scalars['JSON']['output']>;
  /** Sold out flag */
  soldOut: Scalars['Boolean']['output'];
  /** The event start date and time. For a basketball game, for example, this would be the tip off. */
  start: Scalars['DateTime']['output'];
  /** Event timezone name */
  timezoneName: Scalars['String']['output'];
  /** Event's venue name */
  venueName: Scalars['String']['output'];
  /** Waitlist flag */
  waitlistEnabled: Scalars['Boolean']['output'];
  /** Waitlist status */
  waitlistOpen: Scalars['Boolean']['output'];
};

export type ExportReservedParkingPassMutationResponse = {
  __typename?: 'ExportReservedParkingPassMutationResponse';
  passExport: PassExport;
};

/** Fee retention method options */
export enum FeeRetentionMethod {
  /** Option for Citifyd service fee */
  CitifydServiceFee = 'CITIFYD_SERVICE_FEE',
  /** Option for no retention */
  NoRetention = 'NO_RETENTION',
  /** Option for Stripe processing fee */
  StripeProcessingFee = 'STRIPE_PROCESSING_FEE'
}

export type FiAddress = {
  __typename?: 'FiAddress';
  city: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
};

export type FiAddressInput = {
  city: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
};

export type Gate = {
  __typename?: 'Gate';
  armedGate: Scalars['Boolean']['output'];
  automaticActivation: Scalars['Boolean']['output'];
  beaconUids: Array<Scalars['String']['output']>;
  hasAttendant: Scalars['Boolean']['output'];
  hasBarrier: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isSystemGate: Scalars['Boolean']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  reportsStatus: Scalars['Boolean']['output'];
  rssiCalibration: Scalars['Int']['output'];
  type: GateType;
};

export enum GateType {
  Egress = 'EGRESS',
  Ingress = 'INGRESS'
}

/**
 * Lot information.
 *
 * This type is meant to be used by other APIs of Citifyd for integration between the back-ends. It is not accessible
 * for customer-facing apps (such as the mobile app, web version or the dashboard).
 */
export type IntegrationLot = {
  __typename?: 'IntegrationLot';
  address: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  organization: IntegrationOrganization;
  timezoneName: Scalars['String']['output'];
};

/**
 * Current user information.
 *
 * This type is meant to be used by other APIs of Citifyd for integration between the back-ends. It is not accessible
 * for customer-facing apps (such as the mobile app, web version or the dashboard).
 */
export type IntegrationMe = {
  __typename?: 'IntegrationMe';
  permissions: Array<IntegrationPermission>;
  session: IntegrationSession;
  user: IntegrationUser;
};

/**
 * Organization information.
 *
 * This type is meant to be used by other APIs of Citifyd for integration between the back-ends. It is not accessible
 * for customer-facing apps (such as the mobile app, web version or the dashboard).
 */
export type IntegrationOrganization = {
  __typename?: 'IntegrationOrganization';
  hasLimeAccess: Scalars['Boolean']['output'];
  hasPosAccess: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/**
 * Permission information.
 *
 * This type is meant to be used by other APIs of Citifyd for integration between the back-ends. It is not accessible
 * for customer-facing apps (such as the mobile app, web version or the dashboard).
 */
export type IntegrationPermission = {
  __typename?: 'IntegrationPermission';
  filter?: Maybe<Scalars['JSON']['output']>;
  key: Scalars['String']['output'];
};

/**
 * Session information.
 *
 * This type is meant to be used by other APIs of Citifyd for integration between the back-ends. It is not accessible
 * for customer-facing apps (such as the mobile app, web version or the dashboard).
 */
export type IntegrationSession = {
  __typename?: 'IntegrationSession';
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  lastRenewalAt: Scalars['DateTime']['output'];
  lastRenewalCheckAt?: Maybe<Scalars['DateTime']['output']>;
  nextRenewalAt: Scalars['DateTime']['output'];
};

/** User information */
export type IntegrationUser = {
  __typename?: 'IntegrationUser';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isAdmin: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<IntegrationOrganization>;
  phoneCountryCode?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type InternalUser = {
  __typename?: 'InternalUser';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  isMyself?: Maybe<Scalars['Boolean']['output']>;
  isOwner?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  permissions: Array<Scalars['String']['output']>;
  phoneCountryCode?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type InternalUserConnection = {
  __typename?: 'InternalUserConnection';
  edges: Array<InternalUserEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type InternalUserEdge = {
  __typename?: 'InternalUserEdge';
  cursor: Scalars['String']['output'];
  node: InternalUser;
};

export type JpAddress = {
  __typename?: 'JpAddress';
  city: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
  town: Scalars['String']['output'];
};

export type JpAddressInput = {
  city: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
  town: Scalars['String']['input'];
};

export type JpVehicleLicenseInfoInput = {
  area: Scalars['String']['input'];
  categoryNumber: Scalars['String']['input'];
  hiragana: Scalars['String']['input'];
  numberFirstDigits: Scalars['String']['input'];
  numberLastDigits: Scalars['String']['input'];
};

export type JpVehicleLicenseInfoWithOptionalFields = {
  __typename?: 'JpVehicleLicenseInfoWithOptionalFields';
  area?: Maybe<Scalars['String']['output']>;
  categoryNumber?: Maybe<Scalars['String']['output']>;
  hiragana?: Maybe<Scalars['String']['output']>;
  numberFirstDigits?: Maybe<Scalars['String']['output']>;
  numberLastDigits?: Maybe<Scalars['String']['output']>;
};

export type JpVehicleLicenseInfoWithOptionalFieldsInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  categoryNumber?: InputMaybe<Scalars['String']['input']>;
  hiragana?: InputMaybe<Scalars['String']['input']>;
  numberFirstDigits?: InputMaybe<Scalars['String']['input']>;
  numberLastDigits?: InputMaybe<Scalars['String']['input']>;
};

export type ListMyPermitPassesConnection = {
  __typename?: 'ListMyPermitPassesConnection';
  edges: Array<PermitPassEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Lot = {
  __typename?: 'Lot';
  /** Information about the parking lot */
  about?: Maybe<Scalars['String']['output']>;
  /** List of additional photos of the lot */
  additionalPhotos: Array<LotPhoto>;
  /** Lot address */
  address: Address;
  /** List of amenities of the lot */
  amenities: Array<Amenity>;
  /** Color used for identifying this lot */
  color?: Maybe<Scalars['String']['output']>;
  /** Lot country */
  country: Country;
  /** Lot country code */
  countryCode: Scalars['CountryCode']['output'];
  /** Availability that is happening at the moment. It will be used for the user that starts a reservation now. */
  currentAvailability?: Maybe<LotAvailability>;
  /** Directions notes for the parker */
  directionsNotes?: Maybe<Scalars['String']['output']>;
  /** List of gates of the lot */
  gates: Array<Gate>;
  id: Scalars['Int']['output'];
  isVisible: Scalars['Boolean']['output'];
  /** Lot latitude */
  latitude: Scalars['Float']['output'];
  /** Lot longitude */
  longitude: Scalars['Float']['output'];
  /** Main photo of the lot */
  mainPhoto?: Maybe<LotPhoto>;
  /** Amount of spaces */
  maxSpots: Scalars['Int']['output'];
  /** Lot name */
  name: Scalars['String']['output'];
  /** Notes for the parker */
  notes?: Maybe<Scalars['String']['output']>;
  /** Flag for lots with FastPass */
  onDemandUsesFastPass: Scalars['Boolean']['output'];
  /** Opening hours */
  openingHours: LotOpeningHours;
  organizationId?: Maybe<Scalars['Int']['output']>;
  /** Parking instruction category */
  parkingInstructionsCategory: ParkingInstructionsCategory;
  /** List of plans of the lot */
  plans: Array<Plan>;
  /** Private info for the parker */
  privateInfo?: Maybe<Scalars['String']['output']>;
  /** Same as the address field, but retorned as a JSON scalar */
  rawAddress: Scalars['JSON']['output'];
  /** Indicates if reserved parking is enabled for the lot */
  reservedParkingEnabled: Scalars['Boolean']['output'];
  /**
   * Indicates which method is using for validating the reserved parking passes.
   * It will be null if reserved parking is not enabled.
   */
  reservedParkingValidationMethod?: Maybe<ReservedParkingValidationMethod>;
  /** If the lot has sold out of spaces available for purchase. */
  soldOut: Scalars['Boolean']['output'];
  /** Timezone where the lot is in */
  timezoneName: Scalars['String']['output'];
  /** Current and upcoming availabilities for the lot */
  upcomingAvailabilities: Array<LotAvailability>;
};

export type LotAvailability = {
  __typename?: 'LotAvailability';
  barcodeActivation: Scalars['Boolean']['output'];
  end: Scalars['DateTime']['output'];
  gpsVerification: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  latestReservationStartAllowed?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['JSON']['output']>;
  spaces: Array<LotAvailabilitySpaces>;
  start: Scalars['DateTime']['output'];
  timezoneName: Scalars['String']['output'];
};

export type LotAvailabilitySpaces = {
  __typename?: 'LotAvailabilitySpaces';
  max: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  occupied?: Maybe<Scalars['Int']['output']>;
};

export type LotConnection = {
  __typename?: 'LotConnection';
  edges: Array<LotEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Kinds of distances between lot and event. ex: walking, driving */
export type LotDistance = {
  __typename?: 'LotDistance';
  lotId: Scalars['Int']['output'];
  walking: LotDistanceValues;
};

/** Distance between event and lot walking */
export type LotDistanceValues = {
  __typename?: 'LotDistanceValues';
  distance: Scalars['Int']['output'];
  duration: Scalars['Int']['output'];
};

export type LotEdge = {
  __typename?: 'LotEdge';
  cursor: Scalars['String']['output'];
  node: Lot;
};

export type LotLocationInput = {
  coordinates: CoordinatesInput;
};

export type LotOpeningHours = {
  __typename?: 'LotOpeningHours';
  fri?: Maybe<Array<LotOpeningHoursPeriod>>;
  mon?: Maybe<Array<LotOpeningHoursPeriod>>;
  sat?: Maybe<Array<LotOpeningHoursPeriod>>;
  sun?: Maybe<Array<LotOpeningHoursPeriod>>;
  thu?: Maybe<Array<LotOpeningHoursPeriod>>;
  tue?: Maybe<Array<LotOpeningHoursPeriod>>;
  wed?: Maybe<Array<LotOpeningHoursPeriod>>;
};

export type LotOpeningHoursPeriod = {
  __typename?: 'LotOpeningHoursPeriod';
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export enum LotOrderBy {
  /** Order by distance to location provided on the query arguments */
  DistanceToLocation = 'DISTANCE_TO_LOCATION',
  /** Order by name */
  Name = 'NAME'
}

/** Parking methods of a lot */
export type LotParkingMethods = {
  __typename?: 'LotParkingMethods';
  /** Details of the event method */
  event?: Maybe<ParkingMethodDetails>;
  /** Details of the onDemand method */
  onDemand?: Maybe<ParkingMethodDetails>;
  /** Details of the subscription method */
  subscription?: Maybe<ParkingMethodDetails>;
};

export type LotPhoto = {
  __typename?: 'LotPhoto';
  image: UploadedImage;
};

export type Me = {
  __typename?: 'Me';
  permissions: Array<Permission>;
  session: Session;
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Add a permit pass to user passes */
  activatePermitPass: PermitPass;
  /** Cancel a reserved parking pass and refund */
  admin_cancelAndRefundReservedParkingPass: Scalars['Boolean']['output'];
  admin_cancelSubscription: Subscription;
  /** Create a permit */
  admin_createPermitPass: PermitPass;
  /** Create a permit booking portal. */
  admin_createPermitPortal: PermitPortal;
  /** Create a user who will manage a portal for one or more lots */
  admin_createPermitPortalUser: PermitPortalUser;
  /** Admin Create a vehicle */
  admin_createVehicle: Vehicle;
  /** Delete a permit */
  admin_deletePermitPass: PermitPass;
  admin_deletePermitPortalUser: PermitPortalUser;
  /** Admin Delete a vehicle */
  admin_deleteVehicle?: Maybe<Vehicle>;
  /** Refund a subscription */
  admin_refundSubscription: Scalars['Boolean']['output'];
  /** Update a permit */
  admin_updatePermitPass: PermitPass;
  /** Update a permit booking portal. */
  admin_updatePermitPortal: PermitPortal;
  admin_updatePermitPortalUser: PermitPortalUser;
  /** Admin Update a vehicle */
  admin_updateVehicle: Vehicle;
  /** Cancel a subscription */
  cancelSubscription: Subscription;
  createAccountPermissionCompany: Scalars['String']['output'];
  createAccountPermissionInternalUser: Scalars['String']['output'];
  /** Create a card */
  createCard: Card;
  /** Create a vehicle */
  createVehicle: Vehicle;
  deleteAccountPermissionCompany: Scalars['Boolean']['output'];
  deleteAccountPermissionInternalUser: Scalars['Boolean']['output'];
  deleteAccountPermissionUserInvitation: Scalars['Boolean']['output'];
  /** Delete a card */
  deleteCard?: Maybe<Scalars['Boolean']['output']>;
  /** Delete event wait list entry */
  deleteEventWaitListEntry?: Maybe<Scalars['Boolean']['output']>;
  /** Delete a vehicle */
  deleteVehicle?: Maybe<Vehicle>;
  /** Generates an anonymous phone number that can be used to call or text the user. */
  enforcement_generateTemporaryUserPhoneNumber: EnforcementTemporaryUserPhoneNumber;
  /** Export a reserved parking pass */
  exportReservedParkingPass: ExportReservedParkingPassMutationResponse;
  /** Join event wait list */
  joinEventWaitList: TicketWaitListEntry;
  portal_createPermitPass: PermitPass;
  portal_deletePermitPass: PermitPass;
  /**
   * Login as a permit portal user by using its phone number. A verification code will be sent to the user's phone number.
   *
   * Use the mutation `portal_verifyLogin` afterwards with the code to create a session. You can use this mutation
   * to re-send a code or give the user a call if the user requests it.
   */
  portal_login: PermitPortalUserLoginLogoutResponse;
  /** Logout as a permit portal user. Use this to force expire your current token. */
  portal_logout: PermitPortalUserLoginLogoutResponse;
  portal_updatePermitPass: PermitPass;
  /** Verify a login by a permit portal user. The code is sent to the user's phone number. */
  portal_verifyLogin: PermitPortalUser;
  /** Purchase a reserved parking pass */
  purchaseReservedParkingPass: PurchaseReservedParkingPassMutationResponse;
  /** Reinstate cancelled monthly subscription */
  reinstateSubscription: Subscription;
  /** Subscribe the user to a new long-term parking plan */
  subscribeToPlan: Subscription;
  subscribeToPlanV2: SubscribeToPlanResponse;
  updateAccountPermissionCompany: PermissionCompany;
  updateAccountPermissionInternalUser: InternalUser;
  updateAccountPermissionUserInvitationCompany: UserInvitation;
  updateAccountPermissionUserInvitationUser: UserInvitation;
  /** Update a card */
  updateCard: Card;
  /** Update a permit */
  updatePermitPassVehicle: PermitPass;
  /** Update the vehicle of a reserved parking pass */
  updateReservedParkingPassVehicle: ReservedParkingPass;
  /** Update subscription payment method or vehicle */
  updateSubscription: Subscription;
  /** Update a vehicle */
  updateVehicle: Vehicle;
};


export type MutationActivatePermitPassArgs = {
  id: Scalars['Int']['input'];
  paymentMethod?: InputMaybe<PermitPassPaymentMethodInput>;
  vehicle: PermitPassVehicleInput;
};


export type MutationAdmin_CancelAndRefundReservedParkingPassArgs = {
  feeRetentionMethod: FeeRetentionMethod;
  passId: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  refunder: Refunder;
  requestedByEmail: Scalars['Boolean']['input'];
  requestedByPhone: Scalars['Boolean']['input'];
  stripeFeePayer: StripeFeePayer;
};


export type MutationAdmin_CancelSubscriptionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationAdmin_CreatePermitPassArgs = {
  chargeType: PermitPassChargeType;
  customPrice?: InputMaybe<Scalars['Int']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  lotId: Scalars['Int']['input'];
  phoneCountryCode: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  startTime: Scalars['DateTime']['input'];
};


export type MutationAdmin_CreatePermitPortalArgs = {
  input: CreatePermitPortalInput;
};


export type MutationAdmin_CreatePermitPortalUserArgs = {
  input: CreatePermitPortalUserInput;
};


export type MutationAdmin_CreateVehicleArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  licenseInfo: Scalars['JSON']['input'];
  makeCode?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
};


export type MutationAdmin_DeletePermitPassArgs = {
  id: Scalars['Int']['input'];
};


export type MutationAdmin_DeletePermitPortalUserArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationAdmin_DeleteVehicleArgs = {
  userId: Scalars['Int']['input'];
  vehicleId: Scalars['Int']['input'];
};


export type MutationAdmin_RefundSubscriptionArgs = {
  feeRetentionMethod: FeeRetentionMethod;
  id: Scalars['Int']['input'];
  partialAmount?: InputMaybe<Scalars['Int']['input']>;
  reason: Scalars['String']['input'];
  refunder: Refunder;
  requestedByEmail: Scalars['Boolean']['input'];
  requestedByPhone: Scalars['Boolean']['input'];
  stripeFeePayer: StripeFeePayer;
};


export type MutationAdmin_UpdatePermitPassArgs = {
  chargeType?: InputMaybe<PermitPassChargeType>;
  customPrice?: InputMaybe<Scalars['Int']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['Int']['input'];
  lotId?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationAdmin_UpdatePermitPortalArgs = {
  id: Scalars['UUID']['input'];
  input: UpdatePermitPortalInput;
};


export type MutationAdmin_UpdatePermitPortalUserArgs = {
  id: Scalars['UUID']['input'];
  input: UpdatePermitPortalUserInput;
};


export type MutationAdmin_UpdateVehicleArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  licenseInfo?: InputMaybe<Scalars['JSON']['input']>;
  makeCode?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
};


export type MutationCancelSubscriptionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationCreateAccountPermissionCompanyArgs = {
  canContactParkers: Scalars['Boolean']['input'];
  email: Scalars['String']['input'];
  lots?: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  organizationId: Scalars['Int']['input'];
};


export type MutationCreateAccountPermissionInternalUserArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['Int']['input'];
  permissions: Array<Scalars['String']['input']>;
};


export type MutationCreateCardArgs = {
  cardToken: Scalars['String']['input'];
};


export type MutationCreateVehicleArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  countryCode: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  licenseInfo: Scalars['JSON']['input'];
  makeCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteAccountPermissionCompanyArgs = {
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type MutationDeleteAccountPermissionInternalUserArgs = {
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type MutationDeleteAccountPermissionUserInvitationArgs = {
  inviteId: Scalars['Int']['input'];
  organizationId: Scalars['Int']['input'];
};


export type MutationDeleteCardArgs = {
  cardId: Scalars['Int']['input'];
};


export type MutationDeleteEventWaitListEntryArgs = {
  eventId: Scalars['Int']['input'];
};


export type MutationDeleteVehicleArgs = {
  vehicleId: Scalars['Int']['input'];
};


export type MutationEnforcement_GenerateTemporaryUserPhoneNumberArgs = {
  userId: Scalars['Int']['input'];
};


export type MutationExportReservedParkingPassArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  rendering: PassExportRendering;
  reservedParkingPassId: Scalars['Int']['input'];
  type: PassExportType;
};


export type MutationJoinEventWaitListArgs = {
  eventId: Scalars['Int']['input'];
  lotId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationPortal_CreatePermitPassArgs = {
  chargeType: PermitPassChargeType;
  endTime: Scalars['DateTime']['input'];
  lotId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  paymentMethod?: InputMaybe<PermitPassPaymentMethodInput>;
  permitPortalId: Scalars['UUID']['input'];
  phoneCountryCode: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  startTime: Scalars['DateTime']['input'];
};


export type MutationPortal_DeletePermitPassArgs = {
  id: Scalars['Int']['input'];
};


export type MutationPortal_LoginArgs = {
  input: PermitPortalUserLoginInput;
};


export type MutationPortal_UpdatePermitPassArgs = {
  chargeType?: InputMaybe<PermitPassChargeType>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['Int']['input'];
  lotId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  permitPortalId: Scalars['UUID']['input'];
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationPortal_VerifyLoginArgs = {
  input: PermitPortalUserVerifyLoginInput;
};


export type MutationPurchaseReservedParkingPassArgs = {
  endTime: Scalars['DateTime']['input'];
  idempotencyKey: Scalars['UUID']['input'];
  lotId: Scalars['Int']['input'];
  paymentMethod?: InputMaybe<ReservedParkingPaymentMethodInput>;
  startTime: Scalars['DateTime']['input'];
  totalPriceCalculated: Scalars['Int']['input'];
  vehicle?: InputMaybe<ReservedParkingVehicleInput>;
};


export type MutationReinstateSubscriptionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationSubscribeToPlanArgs = {
  paymentMethod: SubscriptionPaymentMethodInput;
  planId: Scalars['Int']['input'];
  vehicle: SubscriptionVehicleInput;
};


export type MutationSubscribeToPlanV2Args = {
  paymentMethodId: Scalars['String']['input'];
  planId: Scalars['Int']['input'];
  vehicle: SubscriptionVehicleInput;
};


export type MutationUpdateAccountPermissionCompanyArgs = {
  canContactParkers: Scalars['Boolean']['input'];
  lots?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type MutationUpdateAccountPermissionInternalUserArgs = {
  organizationId: Scalars['Int']['input'];
  permissions: Array<Scalars['String']['input']>;
  userId: Scalars['Int']['input'];
};


export type MutationUpdateAccountPermissionUserInvitationCompanyArgs = {
  canContactParkers: Scalars['Boolean']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  inviteId: Scalars['Int']['input'];
  lots?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['Int']['input'];
};


export type MutationUpdateAccountPermissionUserInvitationUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  inviteId: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['Int']['input'];
  permissions: Array<Scalars['String']['input']>;
};


export type MutationUpdateCardArgs = {
  address?: InputMaybe<Scalars['JSON']['input']>;
  cardId: Scalars['Int']['input'];
  countryCode?: InputMaybe<Scalars['String']['input']>;
  expMonth?: InputMaybe<Scalars['Int']['input']>;
  expYear?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdatePermitPassVehicleArgs = {
  id: Scalars['Int']['input'];
  vehicle: PermitPassVehicleInput;
};


export type MutationUpdateReservedParkingPassVehicleArgs = {
  id: Scalars['Int']['input'];
  vehicle: ReservedParkingVehicleInput;
};


export type MutationUpdateSubscriptionArgs = {
  id: Scalars['Int']['input'];
  paymentMethod?: InputMaybe<SubscriptionPaymentMethodInput>;
  vehicle?: InputMaybe<SubscriptionVehicleInput>;
};


export type MutationUpdateVehicleArgs = {
  color?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  licenseInfo?: InputMaybe<Scalars['JSON']['input']>;
  makeCode?: InputMaybe<Scalars['String']['input']>;
};

export type MyActiveReservationsResponse = {
  __typename?: 'MyActiveReservationsResponse';
  reservations: Array<Reservation>;
};

export type MyReservedParkingPassesConnection = {
  __typename?: 'MyReservedParkingPassesConnection';
  edges: Array<ReservedParkingPassEdge>;
  /**
   * If the query is made by a guest with a session created without phone verification, they will only be able to see
   * passes purchased using the current session. This flag indicates if the guest has more passes purchased in other
   * sessions.
   */
  moreAvailableUponPhoneVerification: Scalars['Boolean']['output'];
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum MyReservedParkingPassesOrderBy {
  StartTime = 'START_TIME'
}

/** Transaction details */
export type MyTransaction = {
  __typename?: 'MyTransaction';
  /** Cancelled time */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Card details */
  card?: Maybe<MyTransactionCard>;
  /** Citifyd amount */
  citifydAmount: Scalars['Int']['output'];
  /** Transaction creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Currency name */
  currency: Scalars['String']['output'];
  /** End time */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  /** External amount */
  externalAmount: Scalars['Int']['output'];
  /** Transaction ID */
  id: Scalars['Int']['output'];
  /** License */
  license?: Maybe<Scalars['String']['output']>;
  /** Lot details */
  lot: MyTransactionLot;
  /** Parking method */
  parkingMethod?: Maybe<Scalars['String']['output']>;
  /** Start time */
  startTime?: Maybe<Scalars['DateTime']['output']>;
  /** Stripe amount */
  stripeAmount: Scalars['Int']['output'];
  /** Transaction timezone name */
  timezoneName: Scalars['String']['output'];
  /** Total amount */
  totalAmount: Scalars['Int']['output'];
  /** Transaction type */
  type: TransactionType;
};

/** Transaction card details */
export type MyTransactionCard = {
  __typename?: 'MyTransactionCard';
  /** Card brand */
  brand: CardBrand;
  /** Card expiration month */
  expMonth?: Maybe<Scalars['Int']['output']>;
  /** Card expiration year */
  expYear?: Maybe<Scalars['Int']['output']>;
  /** Card ID */
  id: Scalars['Int']['output'];
  /** Card last 4 digits */
  last4: Scalars['String']['output'];
};

export type MyTransactionConnection = {
  __typename?: 'MyTransactionConnection';
  edges: Array<MyTransactionEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type MyTransactionEdge = {
  __typename?: 'MyTransactionEdge';
  cursor: Scalars['String']['output'];
  node: MyTransaction;
};

/** Transaction lot details */
export type MyTransactionLot = {
  __typename?: 'MyTransactionLot';
  /** Lot address */
  address: Scalars['JSON']['output'];
  /** Lot country code */
  countryCode: Scalars['String']['output'];
  /** Lot ID */
  id: Scalars['Int']['output'];
  /** Lot name */
  name: Scalars['String']['output'];
};

/** Order by direction */
export enum OrderDirection {
  /** Ascending order */
  Asc = 'ASC',
  /** descending order */
  Desc = 'DESC'
}

export type Organization = {
  __typename?: 'Organization';
  hasLimeAccess: Scalars['Boolean']['output'];
  hasPosAccess: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type OtherAddress = {
  __typename?: 'OtherAddress';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type OtherAddressInput = {
  _?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OtherVehicleLicenseInfoInput = {
  plate: Scalars['String']['input'];
};

export type OtherVehicleLicenseInfoWithOptionalFields = {
  __typename?: 'OtherVehicleLicenseInfoWithOptionalFields';
  plate: Scalars['String']['output'];
};

export type OtherVehicleLicenseInfoWithOptionalFieldsInput = {
  plate: Scalars['String']['input'];
};

/** Relay Connection PageInfo: https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum ParkingInstructionsCategory {
  /** Category for QRCode instructions */
  QrcodeOnEntry = 'QRCODE_ON_ENTRY',
  /** Category for vehicle registration instructions */
  RegisterVehicle = 'REGISTER_VEHICLE'
}

/** Parking method details */
export type ParkingMethodDetails = {
  __typename?: 'ParkingMethodDetails';
  /** Method state */
  active: Scalars['Boolean']['output'];
  /** Link to download the app and readirect in the selected parking method */
  downloadLink?: Maybe<Scalars['String']['output']>;
};

/** A parking session represents a current or expired parking session. */
export type ParkingSession = {
  __typename?: 'ParkingSession';
  /** The time the session will expire or when it has expired. */
  expiresAt: Scalars['DateTime']['output'];
  /** The object id can differentiate based on the type. */
  id: Scalars['UUID']['output'];
  /** The lot this session belongs to. */
  lot: Lot;
  /** The type of session this is. */
  passType: ParkingSessionType;
  /** The reservation reference for this session. Use this to reference TICKET, RESERVED_PARKING, PERMIT_PASS, FASTPASS and ONDEMAND session types. */
  reservation?: Maybe<Reservation>;
  /** The time the session started. */
  startedAt: Scalars['DateTime']['output'];
  /** The subscription reference for this session. Use this to reference SUBSCRIPTION session types. */
  subscription?: Maybe<Subscription>;
  /** The user of this session. Can be null if this is a fastpass session. */
  user?: Maybe<User>;
  /** The vehicle used to park for this session. Can be null if this is a fastpass session. */
  vehicle?: Maybe<Vehicle>;
};

export type ParkingSessionConnection = {
  __typename?: 'ParkingSessionConnection';
  edges: Array<ParkingSessionEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ParkingSessionEdge = {
  __typename?: 'ParkingSessionEdge';
  cursor: Scalars['String']['output'];
  node: ParkingSession;
};

/** The type of a parking session. */
export enum ParkingSessionType {
  /** Fastpass session for a gated lot. */
  Fastpass = 'FASTPASS',
  /** Regular hourly pass. */
  Ondemand = 'ONDEMAND',
  /** Permit pass. */
  PermitPass = 'PERMIT_PASS',
  /** Reserved parking pass. */
  ReservedParking = 'RESERVED_PARKING',
  /** Monthly parking pass. */
  Subscription = 'SUBSCRIPTION',
  /** Event pass. */
  Ticket = 'TICKET'
}

/** Represents the export of a pass to an external place, such as printing, Apple Wallet and Google Wallet */
export type PassExport = {
  __typename?: 'PassExport';
  /** Content of the pass, it varies based on the pass export type */
  content: PassExportContent;
  /** Rendering mode of the pass export */
  rendering: PassExportRendering;
  /** Type of the pass export */
  type: PassExportType;
};

/** Content of a pass exported to the Apple Wallet */
export type PassExportAppleWalletContent = {
  __typename?: 'PassExportAppleWalletContent';
  /** The URL for downloading the pkpass file */
  downloadUrl: Scalars['String']['output'];
};

/** The content of the pass export varies based on the type */
export type PassExportContent = PassExportAppleWalletContent | PassExportGoogleWalletContent | PassExportPrintContent;

/** Content of a pass exported to the Google Wallet */
export type PassExportGoogleWalletContent = {
  __typename?: 'PassExportGoogleWalletContent';
  /** JWT of the pass for using with the Google Pay for Passes SDK, or for redirecting to the Google website */
  jwt: Scalars['String']['output'];
};

/** Content of the printed version of a pass */
export type PassExportPrintContent = {
  __typename?: 'PassExportPrintContent';
  /** HTML to be rendered for printing */
  html: Scalars['String']['output'];
};

/** Rendering mode of the pass export */
export enum PassExportRendering {
  /** Pass sent to the user by email */
  Email = 'EMAIL',
  /** Pass rendered on the web browser, or on the app itself */
  Web = 'WEB'
}

/** Type of the pass export */
export enum PassExportType {
  /** Pass exported to the Apple Wallet */
  AppleWallet = 'APPLE_WALLET',
  /** Pass exported to the Google Wallet */
  GoogleWallet = 'GOOGLE_WALLET',
  /** Printed version of the pass */
  Print = 'PRINT'
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  /** More details about this payment method */
  details?: Maybe<PaymentMethodDetails>;
  /** Type of this payment method */
  type: PaymentMethodType;
};

/** The payment method details varies based on the payment method used */
export type PaymentMethodDetails = CardPaymentMethodDetails | EmptyPaymentDetails;

/** Type of payment method */
export enum PaymentMethodType {
  /** Paid with card */
  Card = 'CARD',
  /** Paid with cash */
  Cash = 'CASH'
}

/** Payout details */
export type Payout = {
  __typename?: 'Payout';
  /** Amount */
  amount: Scalars['Int']['output'];
  /** Creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Currency */
  currency: Scalars['String']['output'];
  /** Description of the payout */
  description: Scalars['String']['output'];
  /** Execution date */
  executedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Expected arrival date */
  expectedArrivalDate?: Maybe<Scalars['DateTime']['output']>;
  /** Payout ID */
  id: Scalars['Int']['output'];
  /** Organization */
  organization?: Maybe<Organization>;
  /** Payout status */
  status: PayoutStatus;
  /** Stripe account ID */
  stripeAccountId?: Maybe<Scalars['String']['output']>;
  /** Stripe failure code */
  stripeFailureCode?: Maybe<Scalars['String']['output']>;
  /** Stripe failure message */
  stripeFailureMessage?: Maybe<Scalars['String']['output']>;
  /** Stripe payout ID */
  stripePayoutId?: Maybe<Scalars['String']['output']>;
};

/** Payout status */
export enum PayoutStatus {
  /** Failed */
  Failed = 'FAILED',
  /** In transit */
  InTransit = 'IN_TRANSIT',
  /** Paid */
  Paid = 'PAID',
  /** Pending */
  Pending = 'PENDING',
  /** Waiting for request */
  WaitingForRequest = 'WAITING_FOR_REQUEST'
}

export type Permission = {
  __typename?: 'Permission';
  filter?: Maybe<Scalars['JSON']['output']>;
  key: Scalars['String']['output'];
};

export type PermissionCompany = {
  __typename?: 'PermissionCompany';
  canContactParkers: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permissions: Array<Scalars['String']['output']>;
  phoneCountryCode?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  properties: Array<Lot>;
};

export type PermissionCompanyConnection = {
  __typename?: 'PermissionCompanyConnection';
  edges: Array<PermissionCompanyEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PermissionCompanyEdge = {
  __typename?: 'PermissionCompanyEdge';
  cursor: Scalars['String']['output'];
  node: PermissionCompany;
};

/** Permit pass info */
export type PermitPass = {
  __typename?: 'PermitPass';
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  calculatedPrice?: Maybe<PermitPassCalculatedPrice>;
  card?: Maybe<Card>;
  chargeType: PermitPassChargeType;
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  endTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  lot: Lot;
  name?: Maybe<Scalars['String']['output']>;
  phoneCountryCode: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  requester?: Maybe<User>;
  startTime: Scalars['DateTime']['output'];
  status: PermitPassStatus;
  user?: Maybe<User>;
  vehicle?: Maybe<Vehicle>;
};

export type PermitPassCalculatedPrice = {
  __typename?: 'PermitPassCalculatedPrice';
  /** Citifyd service fee */
  fee: Scalars['Int']['output'];
  /** Parking price + Citifyd service fee */
  total: Scalars['Int']['output'];
  /** Parking price */
  value: Scalars['Int']['output'];
};

/** Charge type for Permit Passes */
export enum PermitPassChargeType {
  /** Free Permit Pass */
  Free = 'FREE',
  /** Paid Permit Pass by admin with existing rate */
  PaidAdminExistingRate = 'PAID_ADMIN_EXISTING_RATE',
  /** Paid Permit Pass by user with custom price */
  PaidUserCustomPrice = 'PAID_USER_CUSTOM_PRICE',
  /** Paid Permit Pass by user with existing rate */
  PaidUserExistingRate = 'PAID_USER_EXISTING_RATE'
}

export type PermitPassEdge = {
  __typename?: 'PermitPassEdge';
  cursor: Scalars['String']['output'];
  node: PermitPass;
};

/** Payment method: only one of the fields should be informed */
export type PermitPassPaymentMethodInput = {
  /** ID of the existing multiple-usage card */
  cardId?: InputMaybe<Scalars['Int']['input']>;
  /** Payment token, for single usage of the card */
  paymentToken?: InputMaybe<Scalars['String']['input']>;
};

/** Status options for a permit pass */
export enum PermitPassStatus {
  /** Activated */
  Activated = 'ACTIVATED',
  /** Canceled */
  Cancelled = 'CANCELLED',
  /** Expired */
  Expired = 'EXPIRED',
  /** Activation pending */
  Pending = 'PENDING'
}

/** Vehicle: only one of the fields should be informed */
export type PermitPassVehicleInput = {
  /** If the vehicle you want to use doesn't exist yet, or you don't have its ID, you can pass its information in this field. */
  vehicleData?: InputMaybe<QuickVehicleCreationInput>;
  /** ID of the existing vehicle */
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

/** Options to order a permit pass list */
export enum PermitPassesOrder {
  /** End time */
  EndTime = 'END_TIME',
  /** Lot name */
  Lot = 'LOT',
  /** Phone number */
  PhoneNumber = 'PHONE_NUMBER',
  /** Requester name */
  Requester = 'REQUESTER',
  /** Start time */
  StartTime = 'START_TIME',
  /** Status */
  Status = 'STATUS',
  /** User name */
  User = 'USER',
  /** Vehicle license */
  Vehicle = 'VEHICLE'
}

/** The permit booking portal type */
export type PermitPortal = {
  __typename?: 'PermitPortal';
  /** How many permits this Portal still has to issue */
  futurePermitsRemaining?: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  /** The settings defined for this portal on permit issuance. */
  issuanceSettings: PermitPortalIssuanceSettings;
  /** The logo for this portal. This is a Cloudinary object. */
  logo: UploadedImage;
  /** The list of properties linked to this booking portal. */
  lots?: Maybe<Array<PermitPortalLot>>;
  /** The name of this portal. Example: NV Apartments. */
  name: Scalars['String']['output'];
  /** The URL slug for this portal. Example: `nvapartaments`. This is so we can create a nvapartaments.citifyd.com subdomain. */
  namespace: Scalars['String']['output'];
};

export type PermitPortalConnection = {
  __typename?: 'PermitPortalConnection';
  edges: Array<PermitPortalEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PermitPortalEdge = {
  __typename?: 'PermitPortalEdge';
  cursor: Scalars['String']['output'];
  node: PermitPortal;
};

/** This type represents all permit-issuance configurations for the booking portal. */
export type PermitPortalIssuanceSettings = {
  __typename?: 'PermitPortalIssuanceSettings';
  /** The maximum amount of monthly future permits an issuer can have. `null` if there's no limit. */
  maxFuturePermits?: Maybe<Scalars['Int']['output']>;
  /** The maximum amount of hours a permit can span. `null` if there's no limit. */
  maxHours?: Maybe<Scalars['Int']['output']>;
  /**
   * How farther out a permit can be issued.
   *
   * This is represented as a Duration scalar: https://en.wikipedia.org/wiki/ISO_8601#Durations
   *
   * Example: if `P6M` it will mean a new permit can be issued up to 6 months from now. So if someone tries to issue
   * a permit that starts 7 months from now, the system will throw an error.
   *
   * Important: this is not the maximum amount of time a permit can have! Use `maxHours` instead.
   */
  maxPeriodDuration: Scalars['Duration']['output'];
};

/** This enum is used on the PermitPortalIssuanceSettings type. */
export enum PermitPortalIssuanceSettingsPermitChargeType {
  /** Permits issued are free. */
  Free = 'FREE',
  /** Permits issued have a mix of free and paid. If this is selected, `mixedPermitsFreeLimit` must be provided. */
  Mixed = 'MIXED',
  /** Permits issued must be paid. */
  Paid = 'PAID'
}

/** Configurations for the weekly availability per each weekday. If a day is `null`, it is not available for booking. */
export type PermitPortalIssuanceSettingsWeeklyAvailability = {
  __typename?: 'PermitPortalIssuanceSettingsWeeklyAvailability';
  fri?: Maybe<WeeklyAvailabilityStartEndTimes>;
  mon?: Maybe<WeeklyAvailabilityStartEndTimes>;
  sat?: Maybe<WeeklyAvailabilityStartEndTimes>;
  sun?: Maybe<WeeklyAvailabilityStartEndTimes>;
  thu?: Maybe<WeeklyAvailabilityStartEndTimes>;
  tue?: Maybe<WeeklyAvailabilityStartEndTimes>;
  wed?: Maybe<WeeklyAvailabilityStartEndTimes>;
};

/** A `PermitPortalLot` many-to-many relationship instance. */
export type PermitPortalLot = {
  __typename?: 'PermitPortalLot';
  /** The settings defined for this lot on permit issuance. */
  issuanceSettings: PermitPortalLotIssuanceSettings;
  /** The lot associated to this booking portal. */
  lot: Lot;
  /** The portal associated to this property. */
  portal: PermitPortal;
};

/** This type represents all permit-issuance configuration for the lot linked to this booking portal. */
export type PermitPortalLotIssuanceSettings = {
  __typename?: 'PermitPortalLotIssuanceSettings';
  /** Which dates are blocked for issuance. For example: holidays, special events, etc. `null` if there are no blocked dates. */
  blockedDates?: Maybe<Array<Scalars['Date']['output']>>;
  /** The maximum amount of permits available to be issued in a day. `null` if there is no limit. */
  dailyMax?: Maybe<Scalars['Int']['output']>;
  /** The maximum amount of permits allowed to happen at the same time. `null` if there is no limit. */
  maxConcurrentPermits?: Maybe<Scalars['Int']['output']>;
  /** The amount of free spaces if MIXED is provided on the permitChargeType. `null` if FREE or PAID. */
  mixedPermitsFreeLimit?: Maybe<Scalars['Int']['output']>;
  /** The maximum amount of permits available to be issued in a month. `null` if there is no limit. */
  monthlyMax?: Maybe<Scalars['Int']['output']>;
  /** The type of permits to be issued in this portal, if they are paid or free. */
  permitChargeType: PermitPortalIssuanceSettingsPermitChargeType;
  /** Set of configurations for the week. */
  weeklyAvailability: PermitPortalIssuanceSettingsWeeklyAvailability;
};

export type PermitPortalRemainingPermits = {
  __typename?: 'PermitPortalRemainingPermits';
  lotId: Scalars['Int']['output'];
  remainingDaily?: Maybe<Scalars['Int']['output']>;
  remainingMonthly?: Maybe<Scalars['Int']['output']>;
};

/** The permit portal user type. */
export type PermitPortalUser = {
  __typename?: 'PermitPortalUser';
  /** Date and time the access for this permit portal user expires. */
  accessExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** The permit portal user's current active session authorized by the bearer token. */
  activeSession: Session;
  /** Date and time this permit portal user was created. */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  /** A nullable field to store any identifying information of this permit portal user, such as its apartment number. */
  identifier?: Maybe<Scalars['String']['output']>;
  /** The permit portal user name. */
  name: Scalars['String']['output'];
  /** Alpha-2 ISO 3166 code for the user's phone number country. */
  phoneCountryCode: Scalars['String']['output'];
  /** User's phone number. Use in conjunction with `phoneCountryCode`. */
  phoneNumber: Scalars['String']['output'];
  /** The list of permit portal that this user has access to */
  portals: Array<PermitPortal>;
};

export type PermitPortalUserConnection = {
  __typename?: 'PermitPortalUserConnection';
  edges: Array<PermitPortalUserEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PermitPortalUserEdge = {
  __typename?: 'PermitPortalUserEdge';
  cursor: Scalars['String']['output'];
  node: PermitPortalUser;
};

/** Input for logging in with the phone number. */
export type PermitPortalUserLoginInput = {
  /** ID of the permit portal that this user is logging in */
  permitPortalId: Scalars['UUID']['input'];
  /** Alpha-2 ISO 3166 code for the user's phone number country. */
  phoneCountryCode: Scalars['String']['input'];
  /** User's phone number. Use in conjunction with `phoneCountryCode`. */
  phoneNumber: Scalars['String']['input'];
  /** Method use to provide the code to the user. */
  verificationMethod?: InputMaybe<PermitPortalUserLoginInputVerificationMethod>;
};

export enum PermitPortalUserLoginInputVerificationMethod {
  /** Call the user with the token. */
  Call = 'CALL',
  /** Send verification token as a text. */
  Text = 'TEXT'
}

/** Response for a successful login or logout request. */
export type PermitPortalUserLoginLogoutResponse = {
  __typename?: 'PermitPortalUserLoginLogoutResponse';
  success: Scalars['Boolean']['output'];
};

/** List of dates when the user has permits and for days permit booking isn't available. */
export type PermitPortalUserMyPermitsCalendar = {
  __typename?: 'PermitPortalUserMyPermitsCalendar';
  bookedDates: Array<Scalars['Date']['output']>;
  unavailableDates: Array<Scalars['Date']['output']>;
};

/** Query filter for providing a range that will filter permits that are active. */
export type PermitPortalUserPeriodInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Input to verify the login with a code. */
export type PermitPortalUserVerifyLoginInput = {
  /** The 4-digit code received by the user to verify its login. */
  code: Scalars['String']['input'];
  /** ID of the permit portal that this user is logging in */
  permitPortalId: Scalars['UUID']['input'];
  /** Alpha-2 ISO 3166 code for the user's phone number country. */
  phoneCountryCode: Scalars['String']['input'];
  /** User's phone number. Use in conjunction with `phoneCountryCode`. */
  phoneNumber: Scalars['String']['input'];
};

export type Plan = {
  __typename?: 'Plan';
  /** @deprecated No longer supported */
  LotId: Scalars['Int']['output'];
  billingCycle: Scalars['Int']['output'];
  cancellationNoticePeriod: Scalars['Int']['output'];
  /** Specifies the days of the week the users will be able to park their vehicles. Where 1 being Monday and 7 being Sunday. */
  daysOfTheWeek: Array<Maybe<Scalars['Int']['output']>>;
  /** Plan end time following the format 'HH:mm:ss' */
  endTime: Scalars['String']['output'];
  fee: PlanFee;
  id: Scalars['Int']['output'];
  lot: Lot;
  /** @deprecated No longer supported */
  mySubscription?: Maybe<PlanSubscription>;
  /** Plan name */
  name: Scalars['String']['output'];
  period: PlanPeriod;
  price: Scalars['Int']['output'];
  soldOut: Scalars['Boolean']['output'];
  /** Plan start time following the format 'HH:mm:ss' */
  startTime: Scalars['String']['output'];
  timezoneName: Scalars['String']['output'];
};

export type PlanFee = {
  __typename?: 'PlanFee';
  type: PlanFeeType;
  value: Scalars['Int']['output'];
};

export enum PlanFeeType {
  Percentage = 'PERCENTAGE'
}

export enum PlanPeriod {
  Month = 'MONTH'
}

export type PlanSubscription = {
  __typename?: 'PlanSubscription';
  /** @deprecated No longer supported */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated No longer supported */
  currency: Scalars['String']['output'];
  /** @deprecated No longer supported */
  effectiveUntil?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** @deprecated No longer supported */
  financial?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated No longer supported */
  id: Scalars['Int']['output'];
  /** @deprecated No longer supported */
  sequence: Scalars['Int']['output'];
  /** @deprecated No longer supported */
  subscribedAt: Scalars['DateTime']['output'];
  /** @deprecated No longer supported */
  suspendedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PortalListMyPermitsConnection = {
  __typename?: 'PortalListMyPermitsConnection';
  edges: Array<PermitPassEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProratedPlanResponse = {
  __typename?: 'ProratedPlanResponse';
  fee: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type PurchaseReservedParkingPassMutationResponse = {
  __typename?: 'PurchaseReservedParkingPassMutationResponse';
  reservedParkingPass: ReservedParkingPass;
};

export type Query = {
  __typename?: 'Query';
  /** Calculate permit pass price */
  admin_calculatePermitPassPrice: PermitPassCalculatedPrice;
  /** Generates default refund options for a reserved parking pass */
  admin_generateReservedParkingDefaultRefundOptions: RefundOptions;
  /** Generate a refund preview for a reserved parking pass */
  admin_generateReservedParkingRefundPreview: Array<Maybe<ReversalTransaction>>;
  /** Generates default refund options for a subscription */
  admin_generateSubscriptionDefaultRefundOptions: RefundOptions;
  /** Generate a refund preview for a reserved parking pass */
  admin_generateSubscriptionRefundPreview: Array<Maybe<ReversalTransaction>>;
  /** Details of a permit */
  admin_getPermitPass: PermitPass;
  admin_getPermitPortal: PermitPortal;
  admin_getPermitPortalUser: PermitPortalUser;
  /** Retrieves details of a reserved parking pass for property admins */
  admin_getReservedParkingPass: ReservedParkingPass;
  /** List of filtered current parkers */
  admin_listParkers: ParkingSessionConnection;
  /** List of filtered permits */
  admin_listPermitPasses: AdminListPermitPassesConnection;
  admin_listPermitPortalUser: PermitPortalUserConnection;
  /** List all permit portals. */
  admin_listPermitPortals: PermitPortalConnection;
  /** Retrieves a filtered list of reserved parking passes */
  admin_listReservedParkingPasses: AdminListReservedParkingPassesConnection;
  /** Retrieves user's reserved parking passes list */
  admin_listUserReservedParkingPasses: AdminListReservedParkingPassesConnection;
  /** Admin list user vehicles */
  admin_listVehicles: VehicleConnection;
  admin_lots: LotConnection;
  /** Generate rates report */
  admin_ratesReport: RatesReport;
  /** Generate revenue by property report */
  admin_revenueByPropertyReport: Array<Maybe<RevenueByPropertyReport>>;
  /** Generate summary report */
  admin_summaryReport: SummaryReport;
  /** Generate transactions report */
  admin_transactionsReport: AdminListTransactionsConnection;
  admin_userChangelogs: UserChangelogConnection;
  amenities: Array<Amenity>;
  countries: Array<Country>;
  country: Country;
  /** Fetch lot information the enforcer has access to. */
  enforcement_lot: Lot;
  /** Fetch lot sessions for enforcement. */
  enforcement_lotParkingSessions: ParkingSessionConnection;
  /** Fetch lot enforcement statistics information. */
  enforcement_lotStats: EnforcementLotStats;
  /** List of lots the enforcer has access to. */
  enforcement_lots: LotConnection;
  enforcement_sessionAutocompleteResults: Array<EnforcementSessionAutocompleteResult>;
  /** Fetch list of vehicles that belongs to the specified user. */
  enforcement_userVehicles: Array<Vehicle>;
  getAccountPermissionCompanies: PermissionCompanyConnection;
  getAccountPermissionCompany?: Maybe<PermissionCompany>;
  getAccountPermissionInternalUser?: Maybe<InternalUser>;
  getAccountPermissionInternalUsers: InternalUserConnection;
  getAccountPermissionUserInvitation?: Maybe<UserInvitation>;
  getAccountPermissionUserInvitations: UserInvitationConnection;
  /** Details of a permit */
  getPermitPass: PermitPass;
  /** Retrieves details of a reserved parking pass for users */
  getReservedParkingPass: ReservedParkingPass;
  /**
   *   Retrieves the lots specified.
   *
   * This query is meant to be used by other APIs of Citifyd for integration between the back-ends. It is not accessible
   * for customer-facing apps (such as the mobile app, web version or the dashboard).
   */
  integrationLots: Array<IntegrationLot>;
  /** Retrieves an user based on the session access token. */
  integrationMe: IntegrationMe;
  /**
   *   Retrieves the organizations specified.
   *
   * This query is meant to be used by other APIs of Citifyd for integration between the back-ends. It is not accessible
   * for customer-facing apps (such as the mobile app, web version or the dashboard).
   */
  integrationOrganizations: Array<IntegrationOrganization>;
  /**
   *   Retrieves the users specified.
   *
   * This query is meant to be used by other APIs of Citifyd for integration between the back-ends. It is not accessible
   * for customer-facing apps (such as the mobile app, web version or the dashboard).
   */
  integrationUsers: Array<IntegrationUser>;
  /** Retrieves permit passes for the authenticated user */
  listMyPermitPasses: ListMyPermitPassesConnection;
  lot: Lot;
  /** List events of a lot */
  lotEvents: Array<Event>;
  /** Get parking methods of a lot */
  lotParkingMethods: LotParkingMethods;
  lots: LotConnection;
  /** Get the distances between event and lot */
  lotsDistances: Array<LotDistance>;
  me: Me;
  /** Get active parking sessions of the current user */
  myActiveReservations: MyActiveReservationsResponse;
  /** Retrieves reserved parking passes for the authenticated user */
  myReservedParkingPasses: MyReservedParkingPassesConnection;
  /** Retrieves subscriptions for the authenticated user */
  mySubscriptions: Array<Subscription>;
  /** Retrieves transactions for the authenticated user */
  myTransactions: MyTransactionConnection;
  organizations: Array<Organization>;
  /** Gets the prorated price that the user will pay for this plan if they subscribe today or at specified 'start' date. */
  planProrated: ProratedPlanResponse;
  /** Gets the plans that belongs to the specified Lot. */
  plans: Array<Plan>;
  /** Calculate permit pass price */
  portal_calculatePermitPassPrice: PermitPassCalculatedPrice;
  /** Get a list of dates the user has at least one permit and unavailable dates between the specified period. */
  portal_getMyPermitsCalendar: PermitPortalUserMyPermitsCalendar;
  portal_getPermitPass: PermitPass;
  /** Public query to get permit portal details by its `namespace`. */
  portal_getPermitPortal: PermitPortal;
  portal_getRemainingPermits: Array<PermitPortalRemainingPermits>;
  /** List all permits that belong to this session's permit portal user. */
  portal_listMyPermits: PortalListMyPermitsConnection;
  /** Get the current session's permit portal user. */
  portal_me: PermitPortalUser;
  /** Retrieves fee breakdown of a reserved parking pass from the user perspective */
  reservedParkingPassFeeBreakdown: ReservedParkingPassFeeBreakdown;
  reservedParkingSearch: ReservedParkingSearchResultConnection;
  /** Send permit pass receipt email */
  sendPermitPassReceiptEmail: Scalars['Boolean']['output'];
  /** Retrieves a single subscription that the authenticated user has access to */
  subscription: Subscription;
  /** Retrieves a single transactions for the authenticated user */
  transaction: MyTransaction;
};


export type QueryAdmin_CalculatePermitPassPriceArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  lotId: Scalars['Int']['input'];
  startTime: Scalars['DateTime']['input'];
};


export type QueryAdmin_GenerateReservedParkingDefaultRefundOptionsArgs = {
  reservedParkingPassId: Scalars['Int']['input'];
};


export type QueryAdmin_GenerateReservedParkingRefundPreviewArgs = {
  feeRetentionMethod: FeeRetentionMethod;
  refundAmount: Scalars['Int']['input'];
  refunder: Refunder;
  reservedParkingPassId: Scalars['Int']['input'];
};


export type QueryAdmin_GenerateSubscriptionDefaultRefundOptionsArgs = {
  subscriptionId: Scalars['Int']['input'];
};


export type QueryAdmin_GenerateSubscriptionRefundPreviewArgs = {
  feeRetentionMethod: FeeRetentionMethod;
  refundAmount: Scalars['Int']['input'];
  refunder: Refunder;
  subscriptionId: Scalars['Int']['input'];
};


export type QueryAdmin_GetPermitPassArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdmin_GetPermitPortalArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryAdmin_GetPermitPortalUserArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryAdmin_GetReservedParkingPassArgs = {
  passId: Scalars['Int']['input'];
};


export type QueryAdmin_ListParkersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRecentlyExpired: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  licesePlate?: InputMaybe<Scalars['String']['input']>;
  lotId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdmin_ListPermitPassesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lotId?: InputMaybe<Scalars['Int']['input']>;
  portalUserId?: InputMaybe<Scalars['UUID']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sortBy?: PermitPassesOrder;
  sortDirection?: OrderDirection;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<PermitPassStatus>;
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdmin_ListPermitPortalUserArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdmin_ListPermitPortalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdmin_ListReservedParkingPassesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endDate: Scalars['Date']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lotId?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['Date']['input'];
  status?: InputMaybe<ReservedParkingPassStatus>;
};


export type QueryAdmin_ListUserReservedParkingPassesArgs = {
  userId: Scalars['Int']['input'];
};


export type QueryAdmin_ListVehiclesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};


export type QueryAdmin_LotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<LotOrderBy>;
};


export type QueryAdmin_RatesReportArgs = {
  date: Scalars['Date']['input'];
  lotId?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdmin_RevenueByPropertyReportArgs = {
  endTime: Scalars['DateTime']['input'];
  lotIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['DateTime']['input'];
};


export type QueryAdmin_SummaryReportArgs = {
  currency: Scalars['String']['input'];
  endTime: Scalars['DateTime']['input'];
  lotIds: Array<Scalars['Int']['input']>;
  lotName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  startTime: Scalars['DateTime']['input'];
};


export type QueryAdmin_TransactionsReportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  endTime: Scalars['DateTime']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  last4?: InputMaybe<Scalars['String']['input']>;
  license?: InputMaybe<Scalars['String']['input']>;
  lotIds: Array<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  parkingMethod?: InputMaybe<TransactionsReportParkingMethods>;
  parkingSessionId?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  similarSearch?: InputMaybe<Scalars['Boolean']['input']>;
  startTime: Scalars['DateTime']['input'];
};


export type QueryAdmin_UserChangelogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};


export type QueryCountryArgs = {
  isoCode: Scalars['String']['input'];
};


export type QueryEnforcement_LotArgs = {
  id: Scalars['Int']['input'];
};


export type QueryEnforcement_LotParkingSessionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lotId: Scalars['Int']['input'];
  orderBy?: InputMaybe<EnforcementLotParkingSessionsOrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEnforcement_LotStatsArgs = {
  lotId: Scalars['Int']['input'];
};


export type QueryEnforcement_LotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<LotLocationInput>;
  orderBy?: InputMaybe<LotOrderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryEnforcement_SessionAutocompleteResultsArgs = {
  lotId: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};


export type QueryEnforcement_UserVehiclesArgs = {
  userId: Scalars['Int']['input'];
};


export type QueryGetAccountPermissionCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  lotIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAccountPermissionCompanyArgs = {
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryGetAccountPermissionInternalUserArgs = {
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
};


export type QueryGetAccountPermissionInternalUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAccountPermissionUserInvitationArgs = {
  inviteId: Scalars['Int']['input'];
  organizationId: Scalars['Int']['input'];
};


export type QueryGetAccountPermissionUserInvitationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<AccountPermissionType>;
};


export type QueryGetPermitPassArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetReservedParkingPassArgs = {
  passId: Scalars['Int']['input'];
};


export type QueryIntegrationLotsArgs = {
  ids: Array<Scalars['Int']['input']>;
};


export type QueryIntegrationOrganizationsArgs = {
  ids: Array<Scalars['Int']['input']>;
};


export type QueryIntegrationUsersArgs = {
  ids: Array<Scalars['Int']['input']>;
};


export type QueryListMyPermitPassesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  ongoingAndFutureOnly?: Scalars['Boolean']['input'];
};


export type QueryLotArgs = {
  id: Scalars['Int']['input'];
};


export type QueryLotEventsArgs = {
  lotId: Scalars['Int']['input'];
};


export type QueryLotParkingMethodsArgs = {
  lotId: Scalars['Int']['input'];
};


export type QueryLotsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  amenities?: InputMaybe<Array<Scalars['String']['input']>>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  includeFastPass?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<LotLocationInput>;
  monthlyParkingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<LotOrderBy>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLotsDistancesArgs = {
  eventId: Scalars['Int']['input'];
};


export type QueryMyReservedParkingPassesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  futureOnly?: Scalars['Boolean']['input'];
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<MyReservedParkingPassesOrderBy>;
};


export type QueryMySubscriptionsArgs = {
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryMyTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPlanProratedArgs = {
  planId: Scalars['Int']['input'];
  start?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryPlansArgs = {
  lotId: Scalars['Int']['input'];
};


export type QueryPortal_CalculatePermitPassPriceArgs = {
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  lotId: Scalars['Int']['input'];
  startTime: Scalars['DateTime']['input'];
};


export type QueryPortal_GetMyPermitsCalendarArgs = {
  activeBetween: PermitPortalUserPeriodInput;
};


export type QueryPortal_GetPermitPassArgs = {
  id: Scalars['Int']['input'];
};


export type QueryPortal_GetPermitPortalArgs = {
  namespace: Scalars['String']['input'];
};


export type QueryPortal_GetRemainingPermitsArgs = {
  day: Scalars['Date']['input'];
  permitPortalId: Scalars['UUID']['input'];
};


export type QueryPortal_ListMyPermitsArgs = {
  activeBetween?: InputMaybe<PermitPortalUserPeriodInput>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  futureOnly?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReservedParkingPassFeeBreakdownArgs = {
  passId: Scalars['Int']['input'];
};


export type QueryReservedParkingSearchArgs = {
  amenities?: InputMaybe<Array<Scalars['String']['input']>>;
  endTime: Scalars['DateTime']['input'];
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  location?: InputMaybe<LotLocationInput>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['DateTime']['input'];
};


export type QuerySendPermitPassReceiptEmailArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySubscriptionArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTransactionArgs = {
  id: Scalars['Int']['input'];
};

/** This input is used for mutations that allow the user to create a vehicle directly by passing minimal information. */
export type QuickVehicleCreationInput = {
  countryCode: Scalars['String']['input'];
  licenseInfo: VehicleLicenseInfoWithOptionalFieldsInput;
};

/** Rates report info */
export type RatesReport = {
  __typename?: 'RatesReport';
  /** Amounts */
  amounts: RatesReportTransactionDetail;
  /** Transaction currency */
  currency: Scalars['String']['output'];
  /** Rates */
  rates: Array<RatesReportRateDetail>;
  /** Sessions */
  sessions: RatesReportTransactionDetail;
};

/** Rate detail */
export type RatesReportRateDetail = {
  __typename?: 'RatesReportRateDetail';
  /** Rate name */
  name: Scalars['String']['output'];
  /** Values */
  values: Array<Scalars['Int']['output']>;
};

/** Transactions calcs */
export type RatesReportTransactionDetail = {
  __typename?: 'RatesReportTransactionDetail';
  /** Percentage */
  percentages: Array<Scalars['Int']['output']>;
  /** Total */
  totals: Array<Scalars['Int']['output']>;
};

/** Refund options */
export type RefundOptions = {
  __typename?: 'RefundOptions';
  feeRetentionMethod: FeeRetentionMethod;
  refunder: Refunder;
  stripeFeePayer: StripeFeePayer;
};

/** Refunder options */
export enum Refunder {
  /** Option to select Citifyd as refunder */
  Citifyd = 'CITIFYD',
  /** Option to select seller as refunder */
  Seller = 'SELLER'
}

/** A reservation represents a parking session */
export type Reservation = {
  __typename?: 'Reservation';
  /** List of availability allocations of this reservation */
  availabilityAllocations: Array<AvailabilityAllocation>;
  /** A parking session might have an associated barcode */
  barcode?: Maybe<Barcode>;
  /** The card used to pay for this parking session */
  card?: Maybe<Card>;
  /**
   * Currency used to pay for this parking session.
   * It might be null if this parking session is not on-demand.
   */
  currency?: Maybe<Scalars['String']['output']>;
  /** Current availability of this reservation */
  currentAvailability: LotAvailability;
  /** End time of the currently allocated availability */
  currentAvailabilityEndTime: Scalars['DateTime']['output'];
  /**
   * An email might be filled if the reservation is started anonymously, using a session with 'reservation'
   * entity type. In that case, the receipt will be sent to this email.
   */
  email?: Maybe<Scalars['String']['output']>;
  /** Parking session end time. Might be null if it's ongoing. */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  /** When this parking session is planned to end */
  expirationTime: Scalars['DateTime']['output'];
  /**
   * Reservation financial information for the end user (parker).
   * It might be null if the reservation is not complete, or if this isn't an on-demand reservation.
   */
  financial?: Maybe<UserFacingReservationFinancial>;
  /** Whether or not this parking session has pre-selection of time */
  hasTimeSelection: Scalars['Boolean']['output'];
  /** ID of the reservation */
  id: Scalars['Int']['output'];
  /** Tells if this is a fastpass reservation */
  isFastPassReservation: Scalars['Boolean']['output'];
  /** Which lot is being used to park */
  lot: Lot;
  /** If this is true, we send a notification to the user whenever their reservation is close to expiring */
  notifyCloseToExpiration: Scalars['Boolean']['output'];
  /** In lots that support it, this field will store a parker identification code */
  parkerIdentification?: Maybe<Scalars['String']['output']>;
  /** A parking session might be associated with a reserved parking pass (a pre-booked parking session) */
  reservedParkingPass?: Maybe<ReservedParkingPass>;
  /** Parking session start time */
  startTime: Scalars['DateTime']['output'];
  /** A parking session might be associated with a ticket (an event pass) */
  ticket?: Maybe<Ticket>;
  /** Type of the reservation */
  type: ReservationType;
  /** Which vehicle is being used to park */
  vehicle?: Maybe<Vehicle>;
};

/** A reservation might be of one of these types */
export enum ReservationType {
  /** FastPass parking session */
  Fastpass = 'FASTPASS',
  /** On-demand parking session */
  OnDemand = 'ON_DEMAND',
  /** Permit pass session */
  PermitPass = 'PERMIT_PASS',
  /** Reserved parking session */
  ReservedParking = 'RESERVED_PARKING',
  /** Parking session associated with an event ticket */
  Ticket = 'TICKET'
}

export type ReservedParkingCalculatedPrice = {
  __typename?: 'ReservedParkingCalculatedPrice';
  /** Citifyd service fee */
  fee: Scalars['Int']['output'];
  /** Parking price + Citifyd service fee */
  total: Scalars['Int']['output'];
  /** Parking price */
  value: Scalars['Int']['output'];
};

export type ReservedParkingPass = {
  __typename?: 'ReservedParkingPass';
  /** Availability allocations related to this reserved parking pass */
  availabilityAllocations: Array<AvailabilityAllocation>;
  /** The pass barcode. Might not be available depending on the reserved parking validation method of the lot. */
  barcode?: Maybe<Barcode>;
  /** Price calculated */
  calculatedPrice: ReservedParkingCalculatedPrice;
  /** Pass cancellation details */
  cancellation?: Maybe<Cancellation>;
  /** Reserved parking pass cancel date */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** Card */
  card?: Maybe<Card>;
  /** Reserved parking pass creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Currency used to pay for this reserved parking pass */
  currency: Scalars['String']['output'];
  /** Reserved parking pass end time */
  endTime: Scalars['DateTime']['output'];
  /** Pass id */
  id: Scalars['Int']['output'];
  /** Selected lot */
  lot: Lot;
  /** Reserved parking pass start time */
  startTime: Scalars['DateTime']['output'];
  /** Reserved parking pass status */
  status: ReservedParkingPassStatus;
  /** Reserved parking pass transaction number */
  transactionNumber: Scalars['String']['output'];
  /** User */
  user: User;
  /** Vehicle selected for the pass */
  vehicle?: Maybe<Vehicle>;
};

export type ReservedParkingPassEdge = {
  __typename?: 'ReservedParkingPassEdge';
  cursor: Scalars['String']['output'];
  node: ReservedParkingPass;
};

/** User-facing fee breakdown for a reserved parking pass */
export type ReservedParkingPassFeeBreakdown = {
  __typename?: 'ReservedParkingPassFeeBreakdown';
  /** Final calculation result */
  finalResult: ReservedParkingPassFeeBreakdownFinalResult;
  /** First round of calculations */
  firstStep: ReservedParkingPassFeeBreakdownFirstStep;
  reservedParkingPass: ReservedParkingPass;
};

/** Final calculation result for a reserved parking pass transaction */
export type ReservedParkingPassFeeBreakdownFinalResult = {
  __typename?: 'ReservedParkingPassFeeBreakdownFinalResult';
  /** How much was charged at the end */
  amountCharged: Scalars['Int']['output'];
};

/** First round of calculations for a reserved parking pass transaction */
export type ReservedParkingPassFeeBreakdownFirstStep = {
  __typename?: 'ReservedParkingPassFeeBreakdownFirstStep';
  /** List of availability allocations of the pass with the calculated prices */
  availabilityAllocationsWithPriceCalculation: Array<AvailabilityAllocationWithPriceCalculation>;
  /** Citifyd service fee amount */
  citifydFee: Scalars['Int']['output'];
  /** Total amount */
  totalAmount: Scalars['Int']['output'];
};

export enum ReservedParkingPassStatus {
  /** Ongoing reserved parking passes */
  Active = 'ACTIVE',
  /** Future reserved parking passes (Unredeemed) */
  Future = 'FUTURE',
  /** Past reserved parking passes */
  Past = 'PAST'
}

/** Payment method: only one of the fields should be informed */
export type ReservedParkingPaymentMethodInput = {
  /** ID of the existing multiple-usage card */
  cardId?: InputMaybe<Scalars['Int']['input']>;
  /** Payment token, for single usage of the card */
  paymentToken?: InputMaybe<Scalars['String']['input']>;
};

export type ReservedParkingSearchResult = {
  __typename?: 'ReservedParkingSearchResult';
  /** Price calculated for the period selected */
  calculatedPrice: ReservedParkingCalculatedPrice;
  /** Parking lot */
  lot: Lot;
};

export type ReservedParkingSearchResultConnection = {
  __typename?: 'ReservedParkingSearchResultConnection';
  edges: Array<ReservedParkingSearchResultEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ReservedParkingSearchResultEdge = {
  __typename?: 'ReservedParkingSearchResultEdge';
  cursor: Scalars['String']['output'];
  node: ReservedParkingSearchResult;
};

export enum ReservedParkingValidationMethod {
  /** No validation method */
  None = 'NONE',
  /** QR code will be scanned on entry */
  QrcodeOnEntry = 'QRCODE_ON_ENTRY'
}

/** Vehicle: only one of the fields should be informed */
export type ReservedParkingVehicleInput = {
  /** If the vehicle you want to use doesn't exist yet, or you don't have its ID, you can pass its information in this field. */
  vehicleData?: InputMaybe<QuickVehicleCreationInput>;
  /** ID of the existing vehicle */
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

/** Revenue By Property Report info */
export type RevenueByPropertyReport = {
  __typename?: 'RevenueByPropertyReport';
  /** Citifyd Fees */
  citifydFees?: Maybe<Scalars['Int']['output']>;
  /** Citifyd Service Fee Applied */
  citifydServiceFeeApplied?: Maybe<Scalars['Int']['output']>;
  /** Transactions currency */
  currency?: Maybe<Scalars['String']['output']>;
  /** Property ID */
  lotId?: Maybe<Scalars['Int']['output']>;
  /** Property name */
  lotName?: Maybe<Scalars['String']['output']>;
  /** Net */
  net: Scalars['Int']['output'];
  /** Processing fees */
  processingFees: Scalars['Int']['output'];
  /** Rates */
  rates?: Maybe<Array<Maybe<RevenueByRateReport>>>;
  /** Refunds amount */
  refundsAmount: Scalars['Int']['output'];
  /** Refunds issued */
  refundsIssued: Scalars['Int']['output'];
  /** Revenue */
  revenue: Scalars['Int']['output'];
  /** Subsidies Paid */
  subsidiesPaid?: Maybe<Scalars['Int']['output']>;
  /** Total transactions */
  totalTransactions: Scalars['Int']['output'];
};

/** Revenue By Rates Report info */
export type RevenueByRateReport = {
  __typename?: 'RevenueByRateReport';
  /** Citifyd Fees */
  citifydFees?: Maybe<Scalars['Int']['output']>;
  /** Citifyd Service Fee Applied */
  citifydServiceFeeApplied?: Maybe<Scalars['Int']['output']>;
  /** Transactions currency */
  currency?: Maybe<Scalars['String']['output']>;
  /** Net */
  net: Scalars['Int']['output'];
  /** Processing fees */
  processingFees: Scalars['Int']['output'];
  /** Rate name */
  rateName?: Maybe<Scalars['String']['output']>;
  /** Revenue */
  revenue: Scalars['Int']['output'];
  /** Subsidies Paid */
  subsidiesPaid?: Maybe<Scalars['Int']['output']>;
  /** Total transactions */
  totalTransactions: Scalars['Int']['output'];
};

/** Reversal transaction details */
export type ReversalTransaction = {
  __typename?: 'ReversalTransaction';
  /** Amount to reverse */
  amountToReverse: Scalars['Int']['output'];
  /** Type of the entity */
  entityType: Scalars['String']['output'];
  /** Remaining value to reverse */
  remainingToReverse: Scalars['Int']['output'];
  /** Reversible amount */
  reversibleAmount: Scalars['Int']['output'];
  /** Total reversal amount */
  totalReversalAmount: Scalars['Int']['output'];
  /** Transaction totals */
  totals: TransactionTotals;
  /** Transaction details */
  transaction: Transaction;
};

export type Session = {
  __typename?: 'Session';
  accessToken: Scalars['UUID']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  lastRenewalAt: Scalars['DateTime']['output'];
  lastRenewalCheckAt?: Maybe<Scalars['DateTime']['output']>;
  nextRenewalAt: Scalars['DateTime']['output'];
};

export enum SessionType {
  Management = 'MANAGEMENT',
  Monitoring = 'MONITORING',
  Parking = 'PARKING',
  Portal = 'PORTAL'
}

/** Stripe fee payer options */
export enum StripeFeePayer {
  /** Option to select Citifyd as Stripe fee payer */
  Citifyd = 'CITIFYD',
  /** Option to select customer as Stripe fee payer */
  Customer = 'CUSTOMER',
  /** Option to select seller as Stripe fee payer */
  Seller = 'SELLER'
}

export type SubscribeToPlanResponse = {
  __typename?: 'SubscribeToPlanResponse';
  clientSecret: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  subscription: Subscription;
};

/** A subscription is a monthly parking pass. */
export type Subscription = {
  __typename?: 'Subscription';
  /** How many days before the end of the month we'll automatically try to renew */
  antecipateDaysToAttemptRenewal: Scalars['Int']['output'];
  /**
   * The date when this subscription was cancelled. If it is cancelled, it might still be valid until its effective until
   * time. It will be null if not cancelled.
   */
  cancelledAt?: Maybe<Scalars['DateTime']['output']>;
  /** The card used to pay for this subscription */
  card: Card;
  /** When this subscription was created */
  createdAt: Scalars['DateTime']['output'];
  /** Curreny used to pay for this subscription */
  currency: Scalars['String']['output'];
  /** The date until this plan will be in effect if it was cancelled. */
  effectiveUntil?: Maybe<Scalars['DateTime']['output']>;
  /**
   * The date when this subscription is no longer available for parking. This date is only valid for the current
   * subscription period. The subscription could still be valid after the expiresAt date if it is renewed.
   * Use the effective until date if you'd like to know until when the subscription is valid after a cancellation.
   */
  expiresAt: Scalars['DateTime']['output'];
  /** Financial information of this subscription */
  financial?: Maybe<SubscriptionFinancial>;
  /** ID of the subscription */
  id: Scalars['Int']['output'];
  /**
   * The first recurrence of this subscription. Assuming that this is a monthly subscription, if this is the first month,
   * the value of this field will be null since there's no previous renewal. The next months will point to the
   * subscription representing the first month.
   */
  initialSubscription?: Maybe<Subscription>;
  /** The next amount the parker will be charged. */
  nextBillingAmount: Scalars['Int']['output'];
  /** The next date the parker will be billed. */
  nextBillingDate: Scalars['DateTime']['output'];
  /** Plan of this subscription */
  plan: Plan;
  /**
   * The recurrence number of this subscription. It's 1 for the first payment, 2 for the first renewal, 3 for the
   * next renewal, and so on...
   */
  sequence: Scalars['Int']['output'];
  /** Date for start of the subscription */
  subscribedAt: Scalars['DateTime']['output'];
  /** Date when this subscription was suspended due to lack of payment */
  suspendedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The vehicle use for this subscription */
  vehicle?: Maybe<Vehicle>;
};

export type SubscriptionFinancial = {
  __typename?: 'SubscriptionFinancial';
  /** Total amount we charged the customer */
  amountCharged: Scalars['Int']['output'];
  /** Amount paid in Citifyd fees for this subscription */
  fee: Scalars['Int']['output'];
  /** Amount of a minimum charged applied to the subscription payment */
  minimumChargeApplied: Scalars['Int']['output'];
  /** Amount of credits used against the subscription payment */
  userCreditsUsed: Scalars['Int']['output'];
  /** Amount paid for the parking space for this subscription */
  value: Scalars['Int']['output'];
};

/** Payment method: only one of the fields should be informed */
export type SubscriptionPaymentMethodInput = {
  /** ID of the existing multiple-usage card */
  cardId?: InputMaybe<Scalars['Int']['input']>;
  /** Payment token, for single usage of the card */
  paymentToken?: InputMaybe<Scalars['String']['input']>;
};

/** Vehicle: only one of the fields should be informed */
export type SubscriptionVehicleInput = {
  /** If the vehicle you want to use doesn't exist yet, or you don't have its ID, you can pass its information in this field. */
  vehicleData?: InputMaybe<QuickVehicleCreationInput>;
  /** ID of the existing vehicle */
  vehicleId?: InputMaybe<Scalars['Int']['input']>;
};

/** Sumamry Report info */
export type SummaryReport = {
  __typename?: 'SummaryReport';
  /** Citifyd Fees */
  citifydFees?: Maybe<Scalars['Int']['output']>;
  /** Citifyd service fee applied */
  citifydServiceFeeApplied?: Maybe<Scalars['Int']['output']>;
  /** Customer registered */
  customersRegistered?: Maybe<Scalars['Int']['output']>;
  /** Net */
  net: Scalars['Int']['output'];
  /** New customers */
  newCustomers: Scalars['Int']['output'];
  /** Processing fees */
  processingFees: Scalars['Int']['output'];
  /** Refunds amount */
  refundsAmount: Scalars['Int']['output'];
  /** Refunds issued */
  refundsIssued: Scalars['Int']['output'];
  /** Returning customers */
  returningCustomers: Scalars['Int']['output'];
  /** Revenue */
  revenue: Scalars['Int']['output'];
  /** Subsidies paid */
  subsidiesPaid?: Maybe<Scalars['Int']['output']>;
  /** Total transactions */
  totalTransactions: Scalars['Int']['output'];
};

/** A ticket is a parking pass for an event */
export type Ticket = {
  __typename?: 'Ticket';
  /** Barcode of the ticket */
  barcode: Barcode;
  /** The beacon UIDs relevant to this event ticket for validating the pass */
  beaconUids: Array<Scalars['String']['output']>;
  /** Tells if the ticket can be cancelled at the present moment. */
  cancellationAllowed: Scalars['Boolean']['output'];
  /** Ticket can no longer be cancelled after this cut date */
  cancellationCutDate?: Maybe<Scalars['DateTime']['output']>;
  /** When the ticket was created */
  createdAt: Scalars['DateTime']['output'];
  /** Currency used for purchasing the ticket */
  currency: Scalars['String']['output'];
  /** Event of the ticket */
  event: Event;
  /** Tells if the ticket can be exchanged to another event or lot at the present moment. */
  exchangeAllowed: Scalars['Boolean']['output'];
  /** Ticket can no longer be exchanged after this cut date */
  exchangeCutDate?: Maybe<Scalars['DateTime']['output']>;
  /** Tells if the ticket can be exported to an external wallet or printed at the present moment. */
  exportAllowed: Scalars['Boolean']['output'];
  /** ID of the ticket */
  id: Scalars['Int']['output'];
  /**
   * Tells if this ticket is cancellable. It doesn't mean that the ticket can be cancelled at the present moment.
   * If you need to know if the ticket can be cancelled at the present moment, check 'cancellationAllowed'.
   */
  isCancellable: Scalars['Boolean']['output'];
  /**
   * Tells if this ticket can be exchanged to another event or lot. It doesn't mean that the ticket can be exchanged
   * at the present moment. If you need to know if the ticket can be exchanged at the present moment, check
   * 'exchangeAllowed'.
   */
  isExchangeable: Scalars['Boolean']['output'];
  /**
   * Tells if this ticket can be exported to an external wallet or printed. It doesn't mean that the ticket can be
   * exported at the present moment. If you need to know if the ticket can be exported at the present moment, check
   * 'exportAllowed'.
   */
  isExportable: Scalars['Boolean']['output'];
  /** Tells if the ticket was purchased using the POS */
  isPosPurchase: Scalars['Boolean']['output'];
  /**
   * Tells if this ticket can be transferred to someone else. It doesn't mean that the ticket can be transferred
   * at the present moment. If you need to know if the ticket can be transferred at the present moment, check
   * 'transferAllowed'.
   */
  isTransferable: Scalars['Boolean']['output'];
  /** Lot of the ticket */
  lot: Lot;
  /** Payment method information. Might be null if ticket is free. */
  paymentMethod?: Maybe<PaymentMethod>;
  /**
   * An user may have multiple tickets for the same event, but only one of them is primary.
   * The primary ticket is the one used to park.
   */
  primary: Scalars['Boolean']['output'];
  /** Tells if the ticket can be transferred to someone else at the present moment. */
  transferAllowed: Scalars['Boolean']['output'];
  /** Ticket can no longer be transferred after this cut date */
  transferCutDate?: Maybe<Scalars['DateTime']['output']>;
  /** Vehicle of the ticket */
  vehicle?: Maybe<Vehicle>;
  /** Date when the vehicle selection was confirmed for the ticket. It'll be null if it's not yet confirmed. */
  vehicleSelectionConfirmedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TicketWaitListEntry = {
  __typename?: 'TicketWaitListEntry';
  EventId: Scalars['Int']['output'];
  LotId?: Maybe<Scalars['Int']['output']>;
  TicketId?: Maybe<Scalars['Int']['output']>;
  UserId: Scalars['Int']['output'];
  activatedAt?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Transaction details */
export type Transaction = {
  __typename?: 'Transaction';
  /** Parent transaction ID */
  ParentTransactionId?: Maybe<Scalars['Int']['output']>;
  /** Card used in the transaction */
  card?: Maybe<Card>;
  /** Citifyd amount */
  citifydAmount: Scalars['Int']['output'];
  /** Transaction creation date */
  createdAt: Scalars['DateTime']['output'];
  /** Currency name */
  currency: Scalars['String']['output'];
  /** External amount */
  externalAmount: Scalars['Int']['output'];
  /** Extra infos */
  extra?: Maybe<Scalars['JSON']['output']>;
  /** Transaction ID */
  id: Scalars['Int']['output'];
  /** Flag for cash payment */
  isCashPayment: Scalars['Boolean']['output'];
  /** Stripe amount */
  stripeAmount: Scalars['Int']['output'];
  /** Total amount */
  totalAmount: Scalars['Int']['output'];
  /** Transaction type */
  type: TransactionType;
};

export type TransactionTotals = {
  __typename?: 'TransactionTotals';
  /** Citifyd amount */
  citifydAmount: Scalars['Int']['output'];
  /** External amount */
  externalAmount: Scalars['Int']['output'];
  /** Stripe amount */
  stripeAmount: Scalars['Int']['output'];
  /** Total amount */
  totalAmount: Scalars['Int']['output'];
};

/** Type of the transaction */
export enum TransactionType {
  Authorization = 'AUTHORIZATION',
  Complimentary = 'COMPLIMENTARY',
  ComplimentaryReversal = 'COMPLIMENTARY_REVERSAL',
  MerchantsInternalTransfer = 'MERCHANTS_INTERNAL_TRANSFER',
  Payment = 'PAYMENT',
  Purchase = 'PURCHASE',
  Refund = 'REFUND',
  Transfer = 'TRANSFER',
  TransferReversal = 'TRANSFER_REVERSAL',
  Withdrawal = 'WITHDRAWAL'
}

/** Transactions Report info */
export type TransactionsReport = {
  __typename?: 'TransactionsReport';
  /** Amount */
  amount: Scalars['Int']['output'];
  /** Charges */
  charges: Scalars['Int']['output'];
  /** Citifyd amount */
  citifydAmount: Scalars['Int']['output'];
  /** Citifyd service fee applied */
  citifydServiceFeeApplied: Scalars['Int']['output'];
  /** Create time */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Transaction currency */
  currency?: Maybe<Scalars['String']['output']>;
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** End time */
  endTime?: Maybe<Scalars['DateTime']['output']>;
  /** External amount */
  externalAmount: Scalars['Int']['output'];
  /** Transaction ID */
  id: Scalars['Int']['output'];
  /** License */
  license?: Maybe<Scalars['String']['output']>;
  /** Lot name ( Property ) */
  lotName?: Maybe<Scalars['String']['output']>;
  /** Parker ID ( User ) */
  parkerId?: Maybe<Scalars['String']['output']>;
  /** Parker name ( User ) */
  parkerName?: Maybe<Scalars['String']['output']>;
  /** Parking method name */
  parkingMethod: TransactionsReportParkingMethods;
  /** Parking method ID */
  parkingSessionId: Scalars['Int']['output'];
  /** Phone country code */
  phoneCountryCode?: Maybe<Scalars['String']['output']>;
  /** Phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Rate */
  rate?: Maybe<Scalars['String']['output']>;
  /** Start time */
  startTime?: Maybe<Scalars['DateTime']['output']>;
  /** State */
  state: Scalars['String']['output'];
  /** Stripe amount */
  stripeAmount: Scalars['Int']['output'];
  /** Subsidies paid by Citifyd */
  subsidiesPaid: Scalars['Int']['output'];
  /** Timezone Name */
  timezoneName: Scalars['String']['output'];
};

export type TransactionsReportEdge = {
  __typename?: 'TransactionsReportEdge';
  cursor: Scalars['String']['output'];
  node: TransactionsReport;
};

/** Transactions report parking methods */
export enum TransactionsReportParkingMethods {
  /** Bundle */
  Bundle = 'BUNDLE',
  /** Reservation */
  Reservation = 'RESERVATION',
  /** Reserved Parking */
  ReservedParking = 'RESERVED_PARKING',
  /** Subscription */
  Subscription = 'SUBSCRIPTION',
  /** Ticket */
  Ticket = 'TICKET'
}

/** Update permit portal input. See PermitPortal for documentation. */
export type UpdatePermitPortalInput = {
  /** Optional. Non-nullable. */
  issuanceSettings?: InputMaybe<UpdatePermitPortalIssuanceSettingsInput>;
  /** Required. */
  logo?: InputMaybe<UploadedImageInput>;
  /** Optional. Non-nullable. */
  lots?: InputMaybe<Array<UpdatePermitPortalLotInput>>;
  /** Optional. Non-nullable. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Optional. Non-nullable. */
  namespace?: InputMaybe<Scalars['String']['input']>;
};

/** See PermitPortalIssuanceSettings for documentation. */
export type UpdatePermitPortalIssuanceSettingsInput = {
  /** Optional. Nullable. It is maximum amount of permits in the future an issuer can have */
  maxFuturePermits?: InputMaybe<Scalars['Int']['input']>;
  /** Optional. Nullable. */
  maxHours?: InputMaybe<Scalars['Int']['input']>;
  /** Optional. Non-nullable. */
  maxPeriodDuration?: InputMaybe<Scalars['Duration']['input']>;
};

/** See PermitPortalIssuanceSettingsWeeklyAvailability for documentation. */
export type UpdatePermitPortalIssuanceSettingsWeeklyAvailabilityInput = {
  /** Optional. Nullable. */
  fri?: InputMaybe<UpdateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  mon?: InputMaybe<UpdateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  sat?: InputMaybe<UpdateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  sun?: InputMaybe<UpdateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  thu?: InputMaybe<UpdateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  tue?: InputMaybe<UpdateWeeklyAvailabilityStartEndTimesInput>;
  /** Optional. Nullable. */
  wed?: InputMaybe<UpdateWeeklyAvailabilityStartEndTimesInput>;
};

/** Update PermitPortalLot relationship input. */
export type UpdatePermitPortalLotInput = {
  /** Optional. Non-nullable. Set to true to delete permit portal lot relationship. */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Optional. Non-nullable. */
  issuanceSettings?: InputMaybe<UpdatePermitPortalLotIssuanceSettingsInput>;
  /** Optional. Non-nullable. */
  lotId?: InputMaybe<Scalars['Int']['input']>;
};

/** See PermitPortalLotIssuanceSettings for documentation. */
export type UpdatePermitPortalLotIssuanceSettingsInput = {
  /** Optional. Nullable. */
  blockedDates?: InputMaybe<Array<Scalars['Date']['input']>>;
  /** Optional. Nullable. */
  dailyMax?: InputMaybe<Scalars['Int']['input']>;
  /** Optional. Nullable. */
  maxConcurrentPermits?: InputMaybe<Scalars['Int']['input']>;
  /** Optional. Nullable. */
  mixedPermitsFreeLimit?: InputMaybe<Scalars['Int']['input']>;
  /** Optional. Nullable. */
  monthlyMax?: InputMaybe<Scalars['Int']['input']>;
  /** Optional. Non-nullable. */
  permitChargeType?: InputMaybe<PermitPortalIssuanceSettingsPermitChargeType>;
  /** Optional. Non-nullable. */
  weeklyAvailability?: InputMaybe<UpdatePermitPortalIssuanceSettingsWeeklyAvailabilityInput>;
};

export type UpdatePermitPortalUserAccessInput = {
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['UUID']['input'];
};

export type UpdatePermitPortalUserInput = {
  /** Date and time the access for this permit portal user expires. */
  accessExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The identifier of the user, such as its apartment number. */
  identifier?: InputMaybe<Scalars['String']['input']>;
  /** The name of the user. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Array of permit portals that the user will manage */
  permitPortals?: InputMaybe<Array<UpdatePermitPortalUserAccessInput>>;
  /** The country code of the user's phone number. */
  phoneCountryCode?: InputMaybe<Scalars['String']['input']>;
  /** The phone number of the user. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** See WeeklyAvailabilityStartEndTimes for documentation. */
export type UpdateWeeklyAvailabilityStartEndTimesInput = {
  /** Optional. Non-nullable. */
  endTime: Scalars['String']['input'];
  /** Optional. Non-nullable. */
  startTime: Scalars['String']['input'];
};

/**
 * Represents an image uploaded to Citifyd.
 * Ideally, the client should generate the image URL based on the information stored on 'info' or 'rawInfo', to get
 * the most optimized version of that image for the scenario the client needs.
 * If the client is not able to render the image based on 'info' because it doesn't support its 'mode', it can use the
 * 'fallbackUrl' instead.
 */
export type UploadedImage = {
  __typename?: 'UploadedImage';
  /** Fallback URL to be used, in case the client doesn't support the 'mode' of the uploaded image */
  fallbackUrl: Scalars['String']['output'];
  /** Information that can be used to generate the image URL */
  info: UploadedImageInfo;
  /** Same as 'info', but returned as a JSON scalar */
  rawInfo: Scalars['JSON']['output'];
};

export type UploadedImageInfo = {
  __typename?: 'UploadedImageInfo';
  cloudinary?: Maybe<UploadedImageInfoCloudinary>;
  mode: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type UploadedImageInfoCloudinary = {
  __typename?: 'UploadedImageInfoCloudinary';
  cloudName: Scalars['String']['output'];
  defaultTransformation: Scalars['String']['output'];
  format: Scalars['String']['output'];
  publicId: Scalars['String']['output'];
};

export type UploadedImageInput = {
  /** The Cloudinary object */
  cloudinary?: InputMaybe<CloudinaryInput>;
  /** The type of the upload */
  mode: UploadedImageMode;
  /** Url of the image */
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Enum that defines the type of the upload */
export enum UploadedImageMode {
  /** The upload is a cloudinary object */
  Cloudinary = 'CLOUDINARY',
  /** The upload is an url */
  Url = 'URL'
}

export type UsAddress = {
  __typename?: 'UsAddress';
  city: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type UsAddressInput = {
  city: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type UsVehicleLicenseInfoInput = {
  plate: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type UsVehicleLicenseInfoWithOptionalFields = {
  __typename?: 'UsVehicleLicenseInfoWithOptionalFields';
  plate: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
};

export type UsVehicleLicenseInfoWithOptionalFieldsInput = {
  plate: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  isGuest?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  phoneCountryCode?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

/** A user changelog is a record of changes made to a user */
export type UserChangelog = {
  __typename?: 'UserChangelog';
  /** Type of the user changelog */
  action: Scalars['String']['output'];
  /** Session of the user that made the change */
  changedBySession?: Maybe<Session>;
  /** User that made the change */
  changedByUser?: Maybe<User>;
  /** User changelog created at */
  createdAt: Scalars['DateTime']['output'];
  /** ID of the user changelog */
  id: Scalars['String']['output'];
  /** Metadata of the user changelog */
  metadata: Scalars['JSON']['output'];
  /** Timezone name where the change was made */
  timezoneName: Scalars['String']['output'];
  /** User changelog updated at */
  updatedAt: Scalars['DateTime']['output'];
  /** User of the user changelog */
  user: User;
};

export type UserChangelogConnection = {
  __typename?: 'UserChangelogConnection';
  edges: Array<UserChangelogEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type UserChangelogEdge = {
  __typename?: 'UserChangelogEdge';
  cursor: Scalars['String']['output'];
  node: UserChangelog;
};

/** Reservation financial information for the end user */
export type UserFacingReservationFinancial = {
  __typename?: 'UserFacingReservationFinancial';
  /** The amount charged (after discounts), taking minimum charge into account */
  amountCharged: Scalars['Int']['output'];
  /** The original amount that would be charged (without merchant and credits discounts) */
  amountTotal: Scalars['Int']['output'];
  /** The citifyd service fee */
  citifydAmount: Scalars['Int']['output'];
  /** The discount amount coming from user credits */
  incentiveAmount: Scalars['Int']['output'];
  /** The discount amount coming from business rewards validations */
  merchantDiscountAmount: Scalars['Int']['output'];
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  canContactParkers: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  invitedAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
  properties: Array<Lot>;
  type: AccountPermissionType;
};

export type UserInvitationConnection = {
  __typename?: 'UserInvitationConnection';
  edges: Array<UserInvitationEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type UserInvitationEdge = {
  __typename?: 'UserInvitationEdge';
  cursor: Scalars['String']['output'];
  node: UserInvitation;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  /** Color code of the vehicle, not translated. For the translated color, use 'translatedColor' */
  color?: Maybe<Scalars['String']['output']>;
  /** Country of the vehicle */
  country: Country;
  /** Country code of the vehicle */
  countryCode: Scalars['String']['output'];
  /** Vehicle ID */
  id: Scalars['Int']['output'];
  /** Vehicle nickname */
  label?: Maybe<Scalars['String']['output']>;
  /** A plate string generated for the vehicle, extracted from the licenseInfo */
  license: Scalars['String']['output'];
  /** Vehicle license info, such as the plate and state. The values vary based on the country of the vehicle. */
  licenseInfo: VehicleLicenseInfoWithOptionalFields;
  /** Vehicle make, translated */
  make?: Maybe<Scalars['String']['output']>;
  /** Vehicle make code */
  makeCode?: Maybe<Scalars['String']['output']>;
  /** Vehicle model */
  model?: Maybe<Scalars['String']['output']>;
  /** Same as the licenseInfo field, but returned as a JSON scalar */
  rawLicenseInfo: Scalars['JSON']['output'];
  /** Vehicle color, translated */
  translatedColor?: Maybe<Scalars['String']['output']>;
};

export type VehicleConnection = {
  __typename?: 'VehicleConnection';
  edges: Array<VehicleEdge>;
  pageInfo: PageInfo;
  total?: Maybe<Scalars['Int']['output']>;
};

export type VehicleEdge = {
  __typename?: 'VehicleEdge';
  cursor: Scalars['String']['output'];
  node: Vehicle;
};

export type VehicleLicenseInfoInput = {
  _other?: InputMaybe<OtherVehicleLicenseInfoInput>;
  br?: InputMaybe<BrVehicleLicenseInfoInput>;
  ca?: InputMaybe<CaVehicleLicenseInfoInput>;
  jp?: InputMaybe<JpVehicleLicenseInfoInput>;
  us?: InputMaybe<UsVehicleLicenseInfoInput>;
};

export type VehicleLicenseInfoWithOptionalFields = BrVehicleLicenseInfoWithOptionalFields | CaVehicleLicenseInfoWithOptionalFields | JpVehicleLicenseInfoWithOptionalFields | OtherVehicleLicenseInfoWithOptionalFields | UsVehicleLicenseInfoWithOptionalFields;

export type VehicleLicenseInfoWithOptionalFieldsInput = {
  _other?: InputMaybe<OtherVehicleLicenseInfoWithOptionalFieldsInput>;
  br?: InputMaybe<BrVehicleLicenseInfoWithOptionalFieldsInput>;
  ca?: InputMaybe<CaVehicleLicenseInfoWithOptionalFieldsInput>;
  jp?: InputMaybe<JpVehicleLicenseInfoWithOptionalFieldsInput>;
  us?: InputMaybe<UsVehicleLicenseInfoWithOptionalFieldsInput>;
};

/** Configuration of the start and end time for each weekday. */
export type WeeklyAvailabilityStartEndTimes = {
  __typename?: 'WeeklyAvailabilityStartEndTimes';
  endTime: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
};

export type AdminLotsQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminLotsQuery = { __typename?: 'Query', admin_lots: { __typename?: 'LotConnection', edges: Array<{ __typename?: 'LotEdge', node: { __typename?: 'Lot', id: number, name: string, organizationId?: number | null, timezoneName: string, country: { __typename?: 'Country', currency: string } } }> } };

export type AdminCreateAccountPermissionCompanyMutationVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  lots?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  canContactParkers: Scalars['Boolean']['input'];
}>;


export type AdminCreateAccountPermissionCompanyMutation = { __typename?: 'Mutation', createAccountPermissionCompany: string };

export type AdminCreateAccountPermissionInternalUserMutationVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  permissions: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AdminCreateAccountPermissionInternalUserMutation = { __typename?: 'Mutation', createAccountPermissionInternalUser: string };

export type AdminDeleteAccountPermissionCompanyMutationVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}>;


export type AdminDeleteAccountPermissionCompanyMutation = { __typename?: 'Mutation', deleteAccountPermissionCompany: boolean };

export type AdminDeleteAccountPermissionInternalUserMutationVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}>;


export type AdminDeleteAccountPermissionInternalUserMutation = { __typename?: 'Mutation', deleteAccountPermissionInternalUser: boolean };

export type AdminDeleteAccountPermissionUserInvitationMutationVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  inviteId: Scalars['Int']['input'];
}>;


export type AdminDeleteAccountPermissionUserInvitationMutation = { __typename?: 'Mutation', deleteAccountPermissionUserInvitation: boolean };

export type AdminUpdateAccountPermissionCompanyMutationVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
  lots: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  canContactParkers: Scalars['Boolean']['input'];
}>;


export type AdminUpdateAccountPermissionCompanyMutation = { __typename?: 'Mutation', updateAccountPermissionCompany: { __typename?: 'PermissionCompany', name?: string | null, lastLoginAt?: any | null, permissions: Array<string>, id: number, phoneCountryCode?: string | null, phoneNumber?: string | null, properties: Array<{ __typename?: 'Lot', name: string, id: number }> } };

export type AdminUpdateAccountPermissionInternalUserMutationVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
  permissions: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AdminUpdateAccountPermissionInternalUserMutation = { __typename?: 'Mutation', updateAccountPermissionInternalUser: { __typename?: 'InternalUser', name?: string | null, isAdmin?: boolean | null, lastLoginAt?: any | null, isOwner?: boolean | null, id: number, phoneCountryCode?: string | null, phoneNumber?: string | null } };

export type AdminUpdateAccountPermissionUserInvitationCompanyMutationVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  inviteId: Scalars['Int']['input'];
  lots: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  canContactParkers: Scalars['Boolean']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type AdminUpdateAccountPermissionUserInvitationCompanyMutation = { __typename?: 'Mutation', updateAccountPermissionUserInvitationCompany: { __typename?: 'UserInvitation', id: number, name: string, email: string, invitedAt: any, type: AccountPermissionType, canContactParkers: boolean, properties: Array<{ __typename?: 'Lot', name: string, id: number }> } };

export type AdminUpdateAccountPermissionUserInvitationUserMutationVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  inviteId: Scalars['Int']['input'];
  permissions: Array<Scalars['String']['input']> | Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type AdminUpdateAccountPermissionUserInvitationUserMutation = { __typename?: 'Mutation', updateAccountPermissionUserInvitationUser: { __typename?: 'UserInvitation', id: number, name: string, email: string, invitedAt: any, type: AccountPermissionType, canContactParkers: boolean, permissions: Array<string> } };

export type AdminGetAccountPermissionCompaniesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  lotIds?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;


export type AdminGetAccountPermissionCompaniesQuery = { __typename?: 'Query', getAccountPermissionCompanies: { __typename?: 'PermissionCompanyConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'PermissionCompanyEdge', node: { __typename?: 'PermissionCompany', name?: string | null, lastLoginAt?: any | null, id: number, phoneCountryCode?: string | null, phoneNumber?: string | null, properties: Array<{ __typename?: 'Lot', name: string, id: number }> } }> } };

export type AdminGetAccountPermissionCompanyInvitationQueryVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  inviteId: Scalars['Int']['input'];
}>;


export type AdminGetAccountPermissionCompanyInvitationQuery = { __typename?: 'Query', getAccountPermissionUserInvitation?: { __typename?: 'UserInvitation', id: number, name: string, email: string, invitedAt: any, type: AccountPermissionType, canContactParkers: boolean, properties: Array<{ __typename?: 'Lot', id: number, name: string }> } | null };

export type AdminGetAccountPermissionInternalUsersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
}>;


export type AdminGetAccountPermissionInternalUsersQuery = { __typename?: 'Query', getAccountPermissionInternalUsers: { __typename?: 'InternalUserConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'InternalUserEdge', node: { __typename?: 'InternalUser', name?: string | null, isAdmin?: boolean | null, permissions: Array<string>, lastLoginAt?: any | null, isMyself?: boolean | null, isOwner?: boolean | null, id: number, email?: string | null, phoneCountryCode?: string | null, phoneNumber?: string | null } }> } };

export type AdminGetAccountPermissionUserInvitationQueryVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  inviteId: Scalars['Int']['input'];
}>;


export type AdminGetAccountPermissionUserInvitationQuery = { __typename?: 'Query', getAccountPermissionUserInvitation?: { __typename?: 'UserInvitation', id: number, name: string, email: string, invitedAt: any, type: AccountPermissionType, permissions: Array<string>, canContactParkers: boolean, properties: Array<{ __typename?: 'Lot', id: number, name: string }> } | null };

export type AdminGetAccountPermissionUserInvitationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AccountPermissionType>;
}>;


export type AdminGetAccountPermissionUserInvitationsQuery = { __typename?: 'Query', getAccountPermissionUserInvitations: { __typename?: 'UserInvitationConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'UserInvitationEdge', node: { __typename?: 'UserInvitation', id: number, name: string, email: string, invitedAt: any, type: AccountPermissionType, permissions: Array<string>, canContactParkers: boolean, properties: Array<{ __typename?: 'Lot', id: number, name: string }> } }> } };

export type AdminGetAccountPermissionCompanyQueryVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}>;


export type AdminGetAccountPermissionCompanyQuery = { __typename?: 'Query', getAccountPermissionCompany?: { __typename?: 'PermissionCompany', name?: string | null, lastLoginAt?: any | null, id: number, canContactParkers: boolean, phoneCountryCode?: string | null, phoneNumber?: string | null, permissions: Array<string>, properties: Array<{ __typename?: 'Lot', name: string, id: number }> } | null };

export type AdminGetAccountPermissionInternalUserQueryVariables = Exact<{
  organizationId: Scalars['Int']['input'];
  userId: Scalars['Int']['input'];
}>;


export type AdminGetAccountPermissionInternalUserQuery = { __typename?: 'Query', getAccountPermissionInternalUser?: { __typename?: 'InternalUser', name?: string | null, isAdmin?: boolean | null, lastLoginAt?: any | null, isOwner?: boolean | null, id: number, email?: string | null, permissions: Array<string>, phoneCountryCode?: string | null, phoneNumber?: string | null } | null };

export type AdminCreatePermitPortalMutationVariables = Exact<{
  input: CreatePermitPortalInput;
}>;


export type AdminCreatePermitPortalMutation = { __typename?: 'Mutation', admin_createPermitPortal: { __typename?: 'PermitPortal', id: any, name: string, namespace: string, issuanceSettings: { __typename?: 'PermitPortalIssuanceSettings', maxHours?: number | null, maxPeriodDuration: any, maxFuturePermits?: number | null }, logo: { __typename?: 'UploadedImage', fallbackUrl: string, rawInfo: any, info: { __typename?: 'UploadedImageInfo', mode: string, url?: string | null, cloudinary?: { __typename?: 'UploadedImageInfoCloudinary', cloudName: string, defaultTransformation: string, format: string, publicId: string } | null } }, lots?: Array<{ __typename?: 'PermitPortalLot', issuanceSettings: { __typename?: 'PermitPortalLotIssuanceSettings', blockedDates?: Array<string> | null, dailyMax?: number | null, maxConcurrentPermits?: number | null, mixedPermitsFreeLimit?: number | null, monthlyMax?: number | null, permitChargeType: PermitPortalIssuanceSettingsPermitChargeType, weeklyAvailability: { __typename?: 'PermitPortalIssuanceSettingsWeeklyAvailability', fri?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, mon?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, sat?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, sun?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, thu?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, tue?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, wed?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null } } }> | null } };

export type AdminUpdatePermitPortalMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  input: UpdatePermitPortalInput;
}>;


export type AdminUpdatePermitPortalMutation = { __typename?: 'Mutation', admin_updatePermitPortal: { __typename?: 'PermitPortal', id: any } };

export type AdminGetPermitPortalQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type AdminGetPermitPortalQuery = { __typename?: 'Query', admin_getPermitPortal: { __typename?: 'PermitPortal', id: any, name: string, namespace: string, issuanceSettings: { __typename?: 'PermitPortalIssuanceSettings', maxHours?: number | null, maxPeriodDuration: any, maxFuturePermits?: number | null }, logo: { __typename?: 'UploadedImage', fallbackUrl: string, rawInfo: any, info: { __typename?: 'UploadedImageInfo', mode: string, url?: string | null, cloudinary?: { __typename?: 'UploadedImageInfoCloudinary', cloudName: string, defaultTransformation: string, format: string, publicId: string } | null } }, lots?: Array<{ __typename?: 'PermitPortalLot', lot: { __typename?: 'Lot', id: number, name: string }, issuanceSettings: { __typename?: 'PermitPortalLotIssuanceSettings', blockedDates?: Array<string> | null, dailyMax?: number | null, maxConcurrentPermits?: number | null, mixedPermitsFreeLimit?: number | null, monthlyMax?: number | null, permitChargeType: PermitPortalIssuanceSettingsPermitChargeType, weeklyAvailability: { __typename?: 'PermitPortalIssuanceSettingsWeeklyAvailability', fri?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, mon?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, sat?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, sun?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, thu?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, tue?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null, wed?: { __typename?: 'WeeklyAvailabilityStartEndTimes', endTime: string, startTime: string } | null } } }> | null } };

export type AdminCreateVehicleMutationVariables = Exact<{
  userId: Scalars['Int']['input'];
  countryCode: Scalars['String']['input'];
  licenseInfo: Scalars['JSON']['input'];
}>;


export type AdminCreateVehicleMutation = { __typename?: 'Mutation', admin_createVehicle: { __typename?: 'Vehicle', id: number, make?: string | null, makeCode?: string | null, model?: string | null, label?: string | null, countryCode: string, color?: string | null, translatedColor?: string | null, license: string, country: { __typename?: 'Country', isoCode: string, name: string, currency: string, continent: string, callingCode: string, phoneNumberAllowed: boolean, hasUserNamePronunciation: boolean, vehiclesAllowed: boolean, lotAllowed: boolean, language: string } } };


export const AdminLotsDocument = gql`
    query AdminLots {
  admin_lots {
    edges {
      node {
        id
        name
        organizationId
        country {
          currency
        }
        timezoneName
      }
    }
  }
}
    `;

/**
 * __useAdminLotsQuery__
 *
 * To run a query within a React component, call `useAdminLotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLotsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminLotsQuery(baseOptions?: Apollo.QueryHookOptions<AdminLotsQuery, AdminLotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminLotsQuery, AdminLotsQueryVariables>(AdminLotsDocument, options);
      }
export function useAdminLotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminLotsQuery, AdminLotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminLotsQuery, AdminLotsQueryVariables>(AdminLotsDocument, options);
        }
export type AdminLotsQueryHookResult = ReturnType<typeof useAdminLotsQuery>;
export type AdminLotsLazyQueryHookResult = ReturnType<typeof useAdminLotsLazyQuery>;
export type AdminLotsQueryResult = Apollo.QueryResult<AdminLotsQuery, AdminLotsQueryVariables>;
export const AdminCreateAccountPermissionCompanyDocument = gql`
    mutation AdminCreateAccountPermissionCompany($organizationId: Int!, $name: String!, $email: String!, $lots: [Int!], $canContactParkers: Boolean!) {
  createAccountPermissionCompany(
    organizationId: $organizationId
    name: $name
    email: $email
    lots: $lots
    canContactParkers: $canContactParkers
  )
}
    `;
export type AdminCreateAccountPermissionCompanyMutationFn = Apollo.MutationFunction<AdminCreateAccountPermissionCompanyMutation, AdminCreateAccountPermissionCompanyMutationVariables>;

/**
 * __useAdminCreateAccountPermissionCompanyMutation__
 *
 * To run a mutation, you first call `useAdminCreateAccountPermissionCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateAccountPermissionCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateAccountPermissionCompanyMutation, { data, loading, error }] = useAdminCreateAccountPermissionCompanyMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      lots: // value for 'lots'
 *      canContactParkers: // value for 'canContactParkers'
 *   },
 * });
 */
export function useAdminCreateAccountPermissionCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateAccountPermissionCompanyMutation, AdminCreateAccountPermissionCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateAccountPermissionCompanyMutation, AdminCreateAccountPermissionCompanyMutationVariables>(AdminCreateAccountPermissionCompanyDocument, options);
      }
export type AdminCreateAccountPermissionCompanyMutationHookResult = ReturnType<typeof useAdminCreateAccountPermissionCompanyMutation>;
export type AdminCreateAccountPermissionCompanyMutationResult = Apollo.MutationResult<AdminCreateAccountPermissionCompanyMutation>;
export type AdminCreateAccountPermissionCompanyMutationOptions = Apollo.BaseMutationOptions<AdminCreateAccountPermissionCompanyMutation, AdminCreateAccountPermissionCompanyMutationVariables>;
export const AdminCreateAccountPermissionInternalUserDocument = gql`
    mutation AdminCreateAccountPermissionInternalUser($organizationId: Int!, $name: String!, $email: String!, $permissions: [String!]!) {
  createAccountPermissionInternalUser(
    organizationId: $organizationId
    name: $name
    email: $email
    permissions: $permissions
  )
}
    `;
export type AdminCreateAccountPermissionInternalUserMutationFn = Apollo.MutationFunction<AdminCreateAccountPermissionInternalUserMutation, AdminCreateAccountPermissionInternalUserMutationVariables>;

/**
 * __useAdminCreateAccountPermissionInternalUserMutation__
 *
 * To run a mutation, you first call `useAdminCreateAccountPermissionInternalUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateAccountPermissionInternalUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateAccountPermissionInternalUserMutation, { data, loading, error }] = useAdminCreateAccountPermissionInternalUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useAdminCreateAccountPermissionInternalUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateAccountPermissionInternalUserMutation, AdminCreateAccountPermissionInternalUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateAccountPermissionInternalUserMutation, AdminCreateAccountPermissionInternalUserMutationVariables>(AdminCreateAccountPermissionInternalUserDocument, options);
      }
export type AdminCreateAccountPermissionInternalUserMutationHookResult = ReturnType<typeof useAdminCreateAccountPermissionInternalUserMutation>;
export type AdminCreateAccountPermissionInternalUserMutationResult = Apollo.MutationResult<AdminCreateAccountPermissionInternalUserMutation>;
export type AdminCreateAccountPermissionInternalUserMutationOptions = Apollo.BaseMutationOptions<AdminCreateAccountPermissionInternalUserMutation, AdminCreateAccountPermissionInternalUserMutationVariables>;
export const AdminDeleteAccountPermissionCompanyDocument = gql`
    mutation AdminDeleteAccountPermissionCompany($organizationId: Int!, $userId: Int!) {
  deleteAccountPermissionCompany(organizationId: $organizationId, userId: $userId)
}
    `;
export type AdminDeleteAccountPermissionCompanyMutationFn = Apollo.MutationFunction<AdminDeleteAccountPermissionCompanyMutation, AdminDeleteAccountPermissionCompanyMutationVariables>;

/**
 * __useAdminDeleteAccountPermissionCompanyMutation__
 *
 * To run a mutation, you first call `useAdminDeleteAccountPermissionCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteAccountPermissionCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteAccountPermissionCompanyMutation, { data, loading, error }] = useAdminDeleteAccountPermissionCompanyMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminDeleteAccountPermissionCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteAccountPermissionCompanyMutation, AdminDeleteAccountPermissionCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteAccountPermissionCompanyMutation, AdminDeleteAccountPermissionCompanyMutationVariables>(AdminDeleteAccountPermissionCompanyDocument, options);
      }
export type AdminDeleteAccountPermissionCompanyMutationHookResult = ReturnType<typeof useAdminDeleteAccountPermissionCompanyMutation>;
export type AdminDeleteAccountPermissionCompanyMutationResult = Apollo.MutationResult<AdminDeleteAccountPermissionCompanyMutation>;
export type AdminDeleteAccountPermissionCompanyMutationOptions = Apollo.BaseMutationOptions<AdminDeleteAccountPermissionCompanyMutation, AdminDeleteAccountPermissionCompanyMutationVariables>;
export const AdminDeleteAccountPermissionInternalUserDocument = gql`
    mutation AdminDeleteAccountPermissionInternalUser($organizationId: Int!, $userId: Int!) {
  deleteAccountPermissionInternalUser(
    organizationId: $organizationId
    userId: $userId
  )
}
    `;
export type AdminDeleteAccountPermissionInternalUserMutationFn = Apollo.MutationFunction<AdminDeleteAccountPermissionInternalUserMutation, AdminDeleteAccountPermissionInternalUserMutationVariables>;

/**
 * __useAdminDeleteAccountPermissionInternalUserMutation__
 *
 * To run a mutation, you first call `useAdminDeleteAccountPermissionInternalUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteAccountPermissionInternalUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteAccountPermissionInternalUserMutation, { data, loading, error }] = useAdminDeleteAccountPermissionInternalUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminDeleteAccountPermissionInternalUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteAccountPermissionInternalUserMutation, AdminDeleteAccountPermissionInternalUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteAccountPermissionInternalUserMutation, AdminDeleteAccountPermissionInternalUserMutationVariables>(AdminDeleteAccountPermissionInternalUserDocument, options);
      }
export type AdminDeleteAccountPermissionInternalUserMutationHookResult = ReturnType<typeof useAdminDeleteAccountPermissionInternalUserMutation>;
export type AdminDeleteAccountPermissionInternalUserMutationResult = Apollo.MutationResult<AdminDeleteAccountPermissionInternalUserMutation>;
export type AdminDeleteAccountPermissionInternalUserMutationOptions = Apollo.BaseMutationOptions<AdminDeleteAccountPermissionInternalUserMutation, AdminDeleteAccountPermissionInternalUserMutationVariables>;
export const AdminDeleteAccountPermissionUserInvitationDocument = gql`
    mutation AdminDeleteAccountPermissionUserInvitation($organizationId: Int!, $inviteId: Int!) {
  deleteAccountPermissionUserInvitation(
    organizationId: $organizationId
    inviteId: $inviteId
  )
}
    `;
export type AdminDeleteAccountPermissionUserInvitationMutationFn = Apollo.MutationFunction<AdminDeleteAccountPermissionUserInvitationMutation, AdminDeleteAccountPermissionUserInvitationMutationVariables>;

/**
 * __useAdminDeleteAccountPermissionUserInvitationMutation__
 *
 * To run a mutation, you first call `useAdminDeleteAccountPermissionUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteAccountPermissionUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteAccountPermissionUserInvitationMutation, { data, loading, error }] = useAdminDeleteAccountPermissionUserInvitationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useAdminDeleteAccountPermissionUserInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteAccountPermissionUserInvitationMutation, AdminDeleteAccountPermissionUserInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteAccountPermissionUserInvitationMutation, AdminDeleteAccountPermissionUserInvitationMutationVariables>(AdminDeleteAccountPermissionUserInvitationDocument, options);
      }
export type AdminDeleteAccountPermissionUserInvitationMutationHookResult = ReturnType<typeof useAdminDeleteAccountPermissionUserInvitationMutation>;
export type AdminDeleteAccountPermissionUserInvitationMutationResult = Apollo.MutationResult<AdminDeleteAccountPermissionUserInvitationMutation>;
export type AdminDeleteAccountPermissionUserInvitationMutationOptions = Apollo.BaseMutationOptions<AdminDeleteAccountPermissionUserInvitationMutation, AdminDeleteAccountPermissionUserInvitationMutationVariables>;
export const AdminUpdateAccountPermissionCompanyDocument = gql`
    mutation AdminUpdateAccountPermissionCompany($organizationId: Int!, $userId: Int!, $lots: [Int!]!, $canContactParkers: Boolean!) {
  updateAccountPermissionCompany(
    userId: $userId
    organizationId: $organizationId
    lots: $lots
    canContactParkers: $canContactParkers
  ) {
    name
    lastLoginAt
    permissions
    id
    properties {
      name
      id
    }
    phoneCountryCode
    phoneNumber
  }
}
    `;
export type AdminUpdateAccountPermissionCompanyMutationFn = Apollo.MutationFunction<AdminUpdateAccountPermissionCompanyMutation, AdminUpdateAccountPermissionCompanyMutationVariables>;

/**
 * __useAdminUpdateAccountPermissionCompanyMutation__
 *
 * To run a mutation, you first call `useAdminUpdateAccountPermissionCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateAccountPermissionCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateAccountPermissionCompanyMutation, { data, loading, error }] = useAdminUpdateAccountPermissionCompanyMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      lots: // value for 'lots'
 *      canContactParkers: // value for 'canContactParkers'
 *   },
 * });
 */
export function useAdminUpdateAccountPermissionCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateAccountPermissionCompanyMutation, AdminUpdateAccountPermissionCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateAccountPermissionCompanyMutation, AdminUpdateAccountPermissionCompanyMutationVariables>(AdminUpdateAccountPermissionCompanyDocument, options);
      }
export type AdminUpdateAccountPermissionCompanyMutationHookResult = ReturnType<typeof useAdminUpdateAccountPermissionCompanyMutation>;
export type AdminUpdateAccountPermissionCompanyMutationResult = Apollo.MutationResult<AdminUpdateAccountPermissionCompanyMutation>;
export type AdminUpdateAccountPermissionCompanyMutationOptions = Apollo.BaseMutationOptions<AdminUpdateAccountPermissionCompanyMutation, AdminUpdateAccountPermissionCompanyMutationVariables>;
export const AdminUpdateAccountPermissionInternalUserDocument = gql`
    mutation AdminUpdateAccountPermissionInternalUser($organizationId: Int!, $userId: Int!, $permissions: [String!]!) {
  updateAccountPermissionInternalUser(
    userId: $userId
    organizationId: $organizationId
    permissions: $permissions
  ) {
    name
    isAdmin
    lastLoginAt
    isOwner
    id
    phoneCountryCode
    phoneNumber
  }
}
    `;
export type AdminUpdateAccountPermissionInternalUserMutationFn = Apollo.MutationFunction<AdminUpdateAccountPermissionInternalUserMutation, AdminUpdateAccountPermissionInternalUserMutationVariables>;

/**
 * __useAdminUpdateAccountPermissionInternalUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateAccountPermissionInternalUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateAccountPermissionInternalUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateAccountPermissionInternalUserMutation, { data, loading, error }] = useAdminUpdateAccountPermissionInternalUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useAdminUpdateAccountPermissionInternalUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateAccountPermissionInternalUserMutation, AdminUpdateAccountPermissionInternalUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateAccountPermissionInternalUserMutation, AdminUpdateAccountPermissionInternalUserMutationVariables>(AdminUpdateAccountPermissionInternalUserDocument, options);
      }
export type AdminUpdateAccountPermissionInternalUserMutationHookResult = ReturnType<typeof useAdminUpdateAccountPermissionInternalUserMutation>;
export type AdminUpdateAccountPermissionInternalUserMutationResult = Apollo.MutationResult<AdminUpdateAccountPermissionInternalUserMutation>;
export type AdminUpdateAccountPermissionInternalUserMutationOptions = Apollo.BaseMutationOptions<AdminUpdateAccountPermissionInternalUserMutation, AdminUpdateAccountPermissionInternalUserMutationVariables>;
export const AdminUpdateAccountPermissionUserInvitationCompanyDocument = gql`
    mutation AdminUpdateAccountPermissionUserInvitationCompany($organizationId: Int!, $inviteId: Int!, $lots: [Int!]!, $canContactParkers: Boolean!, $name: String, $email: String) {
  updateAccountPermissionUserInvitationCompany(
    organizationId: $organizationId
    inviteId: $inviteId
    lots: $lots
    canContactParkers: $canContactParkers
    name: $name
    email: $email
  ) {
    id
    name
    email
    invitedAt
    type
    canContactParkers
    properties {
      name
      id
    }
  }
}
    `;
export type AdminUpdateAccountPermissionUserInvitationCompanyMutationFn = Apollo.MutationFunction<AdminUpdateAccountPermissionUserInvitationCompanyMutation, AdminUpdateAccountPermissionUserInvitationCompanyMutationVariables>;

/**
 * __useAdminUpdateAccountPermissionUserInvitationCompanyMutation__
 *
 * To run a mutation, you first call `useAdminUpdateAccountPermissionUserInvitationCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateAccountPermissionUserInvitationCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateAccountPermissionUserInvitationCompanyMutation, { data, loading, error }] = useAdminUpdateAccountPermissionUserInvitationCompanyMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      inviteId: // value for 'inviteId'
 *      lots: // value for 'lots'
 *      canContactParkers: // value for 'canContactParkers'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAdminUpdateAccountPermissionUserInvitationCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateAccountPermissionUserInvitationCompanyMutation, AdminUpdateAccountPermissionUserInvitationCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateAccountPermissionUserInvitationCompanyMutation, AdminUpdateAccountPermissionUserInvitationCompanyMutationVariables>(AdminUpdateAccountPermissionUserInvitationCompanyDocument, options);
      }
export type AdminUpdateAccountPermissionUserInvitationCompanyMutationHookResult = ReturnType<typeof useAdminUpdateAccountPermissionUserInvitationCompanyMutation>;
export type AdminUpdateAccountPermissionUserInvitationCompanyMutationResult = Apollo.MutationResult<AdminUpdateAccountPermissionUserInvitationCompanyMutation>;
export type AdminUpdateAccountPermissionUserInvitationCompanyMutationOptions = Apollo.BaseMutationOptions<AdminUpdateAccountPermissionUserInvitationCompanyMutation, AdminUpdateAccountPermissionUserInvitationCompanyMutationVariables>;
export const AdminUpdateAccountPermissionUserInvitationUserDocument = gql`
    mutation AdminUpdateAccountPermissionUserInvitationUser($organizationId: Int!, $inviteId: Int!, $permissions: [String!]!, $name: String, $email: String) {
  updateAccountPermissionUserInvitationUser(
    organizationId: $organizationId
    inviteId: $inviteId
    permissions: $permissions
    name: $name
    email: $email
  ) {
    id
    name
    email
    invitedAt
    type
    canContactParkers
    permissions
  }
}
    `;
export type AdminUpdateAccountPermissionUserInvitationUserMutationFn = Apollo.MutationFunction<AdminUpdateAccountPermissionUserInvitationUserMutation, AdminUpdateAccountPermissionUserInvitationUserMutationVariables>;

/**
 * __useAdminUpdateAccountPermissionUserInvitationUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateAccountPermissionUserInvitationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateAccountPermissionUserInvitationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateAccountPermissionUserInvitationUserMutation, { data, loading, error }] = useAdminUpdateAccountPermissionUserInvitationUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      inviteId: // value for 'inviteId'
 *      permissions: // value for 'permissions'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAdminUpdateAccountPermissionUserInvitationUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateAccountPermissionUserInvitationUserMutation, AdminUpdateAccountPermissionUserInvitationUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateAccountPermissionUserInvitationUserMutation, AdminUpdateAccountPermissionUserInvitationUserMutationVariables>(AdminUpdateAccountPermissionUserInvitationUserDocument, options);
      }
export type AdminUpdateAccountPermissionUserInvitationUserMutationHookResult = ReturnType<typeof useAdminUpdateAccountPermissionUserInvitationUserMutation>;
export type AdminUpdateAccountPermissionUserInvitationUserMutationResult = Apollo.MutationResult<AdminUpdateAccountPermissionUserInvitationUserMutation>;
export type AdminUpdateAccountPermissionUserInvitationUserMutationOptions = Apollo.BaseMutationOptions<AdminUpdateAccountPermissionUserInvitationUserMutation, AdminUpdateAccountPermissionUserInvitationUserMutationVariables>;
export const AdminGetAccountPermissionCompaniesDocument = gql`
    query AdminGetAccountPermissionCompanies($first: Int, $last: Int, $after: String, $before: String, $search: String, $lotIds: [Int!]) {
  getAccountPermissionCompanies(
    first: $first
    last: $last
    after: $after
    before: $before
    search: $search
    lotIds: $lotIds
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        name
        lastLoginAt
        id
        properties {
          name
          id
        }
        phoneCountryCode
        phoneNumber
      }
    }
  }
}
    `;

/**
 * __useAdminGetAccountPermissionCompaniesQuery__
 *
 * To run a query within a React component, call `useAdminGetAccountPermissionCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAccountPermissionCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAccountPermissionCompaniesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      lotIds: // value for 'lotIds'
 *   },
 * });
 */
export function useAdminGetAccountPermissionCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetAccountPermissionCompaniesQuery, AdminGetAccountPermissionCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAccountPermissionCompaniesQuery, AdminGetAccountPermissionCompaniesQueryVariables>(AdminGetAccountPermissionCompaniesDocument, options);
      }
export function useAdminGetAccountPermissionCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAccountPermissionCompaniesQuery, AdminGetAccountPermissionCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAccountPermissionCompaniesQuery, AdminGetAccountPermissionCompaniesQueryVariables>(AdminGetAccountPermissionCompaniesDocument, options);
        }
export type AdminGetAccountPermissionCompaniesQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionCompaniesQuery>;
export type AdminGetAccountPermissionCompaniesLazyQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionCompaniesLazyQuery>;
export type AdminGetAccountPermissionCompaniesQueryResult = Apollo.QueryResult<AdminGetAccountPermissionCompaniesQuery, AdminGetAccountPermissionCompaniesQueryVariables>;
export const AdminGetAccountPermissionCompanyInvitationDocument = gql`
    query AdminGetAccountPermissionCompanyInvitation($organizationId: Int!, $inviteId: Int!) {
  getAccountPermissionUserInvitation(
    organizationId: $organizationId
    inviteId: $inviteId
  ) {
    id
    name
    email
    invitedAt
    type
    canContactParkers
    properties {
      id
      name
    }
  }
}
    `;

/**
 * __useAdminGetAccountPermissionCompanyInvitationQuery__
 *
 * To run a query within a React component, call `useAdminGetAccountPermissionCompanyInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAccountPermissionCompanyInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAccountPermissionCompanyInvitationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useAdminGetAccountPermissionCompanyInvitationQuery(baseOptions: Apollo.QueryHookOptions<AdminGetAccountPermissionCompanyInvitationQuery, AdminGetAccountPermissionCompanyInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAccountPermissionCompanyInvitationQuery, AdminGetAccountPermissionCompanyInvitationQueryVariables>(AdminGetAccountPermissionCompanyInvitationDocument, options);
      }
export function useAdminGetAccountPermissionCompanyInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAccountPermissionCompanyInvitationQuery, AdminGetAccountPermissionCompanyInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAccountPermissionCompanyInvitationQuery, AdminGetAccountPermissionCompanyInvitationQueryVariables>(AdminGetAccountPermissionCompanyInvitationDocument, options);
        }
export type AdminGetAccountPermissionCompanyInvitationQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionCompanyInvitationQuery>;
export type AdminGetAccountPermissionCompanyInvitationLazyQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionCompanyInvitationLazyQuery>;
export type AdminGetAccountPermissionCompanyInvitationQueryResult = Apollo.QueryResult<AdminGetAccountPermissionCompanyInvitationQuery, AdminGetAccountPermissionCompanyInvitationQueryVariables>;
export const AdminGetAccountPermissionInternalUsersDocument = gql`
    query AdminGetAccountPermissionInternalUsers($first: Int, $last: Int, $after: String, $before: String) {
  getAccountPermissionInternalUsers(
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        name
        isAdmin
        permissions
        lastLoginAt
        isMyself
        isOwner
        id
        email
        phoneCountryCode
        phoneNumber
      }
    }
  }
}
    `;

/**
 * __useAdminGetAccountPermissionInternalUsersQuery__
 *
 * To run a query within a React component, call `useAdminGetAccountPermissionInternalUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAccountPermissionInternalUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAccountPermissionInternalUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useAdminGetAccountPermissionInternalUsersQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetAccountPermissionInternalUsersQuery, AdminGetAccountPermissionInternalUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAccountPermissionInternalUsersQuery, AdminGetAccountPermissionInternalUsersQueryVariables>(AdminGetAccountPermissionInternalUsersDocument, options);
      }
export function useAdminGetAccountPermissionInternalUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAccountPermissionInternalUsersQuery, AdminGetAccountPermissionInternalUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAccountPermissionInternalUsersQuery, AdminGetAccountPermissionInternalUsersQueryVariables>(AdminGetAccountPermissionInternalUsersDocument, options);
        }
export type AdminGetAccountPermissionInternalUsersQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionInternalUsersQuery>;
export type AdminGetAccountPermissionInternalUsersLazyQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionInternalUsersLazyQuery>;
export type AdminGetAccountPermissionInternalUsersQueryResult = Apollo.QueryResult<AdminGetAccountPermissionInternalUsersQuery, AdminGetAccountPermissionInternalUsersQueryVariables>;
export const AdminGetAccountPermissionUserInvitationDocument = gql`
    query AdminGetAccountPermissionUserInvitation($organizationId: Int!, $inviteId: Int!) {
  getAccountPermissionUserInvitation(
    organizationId: $organizationId
    inviteId: $inviteId
  ) {
    id
    name
    email
    invitedAt
    type
    permissions
    canContactParkers
    properties {
      id
      name
    }
  }
}
    `;

/**
 * __useAdminGetAccountPermissionUserInvitationQuery__
 *
 * To run a query within a React component, call `useAdminGetAccountPermissionUserInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAccountPermissionUserInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAccountPermissionUserInvitationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      inviteId: // value for 'inviteId'
 *   },
 * });
 */
export function useAdminGetAccountPermissionUserInvitationQuery(baseOptions: Apollo.QueryHookOptions<AdminGetAccountPermissionUserInvitationQuery, AdminGetAccountPermissionUserInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAccountPermissionUserInvitationQuery, AdminGetAccountPermissionUserInvitationQueryVariables>(AdminGetAccountPermissionUserInvitationDocument, options);
      }
export function useAdminGetAccountPermissionUserInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAccountPermissionUserInvitationQuery, AdminGetAccountPermissionUserInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAccountPermissionUserInvitationQuery, AdminGetAccountPermissionUserInvitationQueryVariables>(AdminGetAccountPermissionUserInvitationDocument, options);
        }
export type AdminGetAccountPermissionUserInvitationQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionUserInvitationQuery>;
export type AdminGetAccountPermissionUserInvitationLazyQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionUserInvitationLazyQuery>;
export type AdminGetAccountPermissionUserInvitationQueryResult = Apollo.QueryResult<AdminGetAccountPermissionUserInvitationQuery, AdminGetAccountPermissionUserInvitationQueryVariables>;
export const AdminGetAccountPermissionUserInvitationsDocument = gql`
    query AdminGetAccountPermissionUserInvitations($first: Int, $last: Int, $after: String, $before: String, $type: AccountPermissionType) {
  getAccountPermissionUserInvitations(
    first: $first
    last: $last
    after: $after
    before: $before
    type: $type
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        name
        email
        invitedAt
        type
        permissions
        properties {
          id
          name
        }
        canContactParkers
      }
    }
  }
}
    `;

/**
 * __useAdminGetAccountPermissionUserInvitationsQuery__
 *
 * To run a query within a React component, call `useAdminGetAccountPermissionUserInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAccountPermissionUserInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAccountPermissionUserInvitationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAdminGetAccountPermissionUserInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetAccountPermissionUserInvitationsQuery, AdminGetAccountPermissionUserInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAccountPermissionUserInvitationsQuery, AdminGetAccountPermissionUserInvitationsQueryVariables>(AdminGetAccountPermissionUserInvitationsDocument, options);
      }
export function useAdminGetAccountPermissionUserInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAccountPermissionUserInvitationsQuery, AdminGetAccountPermissionUserInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAccountPermissionUserInvitationsQuery, AdminGetAccountPermissionUserInvitationsQueryVariables>(AdminGetAccountPermissionUserInvitationsDocument, options);
        }
export type AdminGetAccountPermissionUserInvitationsQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionUserInvitationsQuery>;
export type AdminGetAccountPermissionUserInvitationsLazyQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionUserInvitationsLazyQuery>;
export type AdminGetAccountPermissionUserInvitationsQueryResult = Apollo.QueryResult<AdminGetAccountPermissionUserInvitationsQuery, AdminGetAccountPermissionUserInvitationsQueryVariables>;
export const AdminGetAccountPermissionCompanyDocument = gql`
    query AdminGetAccountPermissionCompany($organizationId: Int!, $userId: Int!) {
  getAccountPermissionCompany(organizationId: $organizationId, userId: $userId) {
    name
    lastLoginAt
    id
    canContactParkers
    properties {
      name
      id
    }
    phoneCountryCode
    phoneNumber
    permissions
  }
}
    `;

/**
 * __useAdminGetAccountPermissionCompanyQuery__
 *
 * To run a query within a React component, call `useAdminGetAccountPermissionCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAccountPermissionCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAccountPermissionCompanyQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminGetAccountPermissionCompanyQuery(baseOptions: Apollo.QueryHookOptions<AdminGetAccountPermissionCompanyQuery, AdminGetAccountPermissionCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAccountPermissionCompanyQuery, AdminGetAccountPermissionCompanyQueryVariables>(AdminGetAccountPermissionCompanyDocument, options);
      }
export function useAdminGetAccountPermissionCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAccountPermissionCompanyQuery, AdminGetAccountPermissionCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAccountPermissionCompanyQuery, AdminGetAccountPermissionCompanyQueryVariables>(AdminGetAccountPermissionCompanyDocument, options);
        }
export type AdminGetAccountPermissionCompanyQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionCompanyQuery>;
export type AdminGetAccountPermissionCompanyLazyQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionCompanyLazyQuery>;
export type AdminGetAccountPermissionCompanyQueryResult = Apollo.QueryResult<AdminGetAccountPermissionCompanyQuery, AdminGetAccountPermissionCompanyQueryVariables>;
export const AdminGetAccountPermissionInternalUserDocument = gql`
    query AdminGetAccountPermissionInternalUser($organizationId: Int!, $userId: Int!) {
  getAccountPermissionInternalUser(
    organizationId: $organizationId
    userId: $userId
  ) {
    name
    isAdmin
    lastLoginAt
    isOwner
    id
    email
    permissions
    phoneCountryCode
    phoneNumber
  }
}
    `;

/**
 * __useAdminGetAccountPermissionInternalUserQuery__
 *
 * To run a query within a React component, call `useAdminGetAccountPermissionInternalUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetAccountPermissionInternalUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetAccountPermissionInternalUserQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminGetAccountPermissionInternalUserQuery(baseOptions: Apollo.QueryHookOptions<AdminGetAccountPermissionInternalUserQuery, AdminGetAccountPermissionInternalUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAccountPermissionInternalUserQuery, AdminGetAccountPermissionInternalUserQueryVariables>(AdminGetAccountPermissionInternalUserDocument, options);
      }
export function useAdminGetAccountPermissionInternalUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAccountPermissionInternalUserQuery, AdminGetAccountPermissionInternalUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAccountPermissionInternalUserQuery, AdminGetAccountPermissionInternalUserQueryVariables>(AdminGetAccountPermissionInternalUserDocument, options);
        }
export type AdminGetAccountPermissionInternalUserQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionInternalUserQuery>;
export type AdminGetAccountPermissionInternalUserLazyQueryHookResult = ReturnType<typeof useAdminGetAccountPermissionInternalUserLazyQuery>;
export type AdminGetAccountPermissionInternalUserQueryResult = Apollo.QueryResult<AdminGetAccountPermissionInternalUserQuery, AdminGetAccountPermissionInternalUserQueryVariables>;
export const AdminCreatePermitPortalDocument = gql`
    mutation AdminCreatePermitPortal($input: CreatePermitPortalInput!) {
  admin_createPermitPortal(input: $input) {
    id
    name
    namespace
    issuanceSettings {
      maxHours
      maxPeriodDuration
      maxFuturePermits
    }
    logo {
      fallbackUrl
      info {
        cloudinary {
          cloudName
          defaultTransformation
          format
          publicId
        }
        mode
        url
      }
      rawInfo
    }
    lots {
      issuanceSettings {
        blockedDates
        dailyMax
        maxConcurrentPermits
        mixedPermitsFreeLimit
        monthlyMax
        permitChargeType
        weeklyAvailability {
          fri {
            endTime
            startTime
          }
          mon {
            endTime
            startTime
          }
          sat {
            endTime
            startTime
          }
          sun {
            endTime
            startTime
          }
          thu {
            endTime
            startTime
          }
          tue {
            endTime
            startTime
          }
          wed {
            endTime
            startTime
          }
        }
      }
    }
  }
}
    `;
export type AdminCreatePermitPortalMutationFn = Apollo.MutationFunction<AdminCreatePermitPortalMutation, AdminCreatePermitPortalMutationVariables>;

/**
 * __useAdminCreatePermitPortalMutation__
 *
 * To run a mutation, you first call `useAdminCreatePermitPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreatePermitPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreatePermitPortalMutation, { data, loading, error }] = useAdminCreatePermitPortalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreatePermitPortalMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreatePermitPortalMutation, AdminCreatePermitPortalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreatePermitPortalMutation, AdminCreatePermitPortalMutationVariables>(AdminCreatePermitPortalDocument, options);
      }
export type AdminCreatePermitPortalMutationHookResult = ReturnType<typeof useAdminCreatePermitPortalMutation>;
export type AdminCreatePermitPortalMutationResult = Apollo.MutationResult<AdminCreatePermitPortalMutation>;
export type AdminCreatePermitPortalMutationOptions = Apollo.BaseMutationOptions<AdminCreatePermitPortalMutation, AdminCreatePermitPortalMutationVariables>;
export const AdminUpdatePermitPortalDocument = gql`
    mutation AdminUpdatePermitPortal($id: UUID!, $input: UpdatePermitPortalInput!) {
  admin_updatePermitPortal(id: $id, input: $input) {
    id
  }
}
    `;
export type AdminUpdatePermitPortalMutationFn = Apollo.MutationFunction<AdminUpdatePermitPortalMutation, AdminUpdatePermitPortalMutationVariables>;

/**
 * __useAdminUpdatePermitPortalMutation__
 *
 * To run a mutation, you first call `useAdminUpdatePermitPortalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdatePermitPortalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdatePermitPortalMutation, { data, loading, error }] = useAdminUpdatePermitPortalMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdatePermitPortalMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdatePermitPortalMutation, AdminUpdatePermitPortalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdatePermitPortalMutation, AdminUpdatePermitPortalMutationVariables>(AdminUpdatePermitPortalDocument, options);
      }
export type AdminUpdatePermitPortalMutationHookResult = ReturnType<typeof useAdminUpdatePermitPortalMutation>;
export type AdminUpdatePermitPortalMutationResult = Apollo.MutationResult<AdminUpdatePermitPortalMutation>;
export type AdminUpdatePermitPortalMutationOptions = Apollo.BaseMutationOptions<AdminUpdatePermitPortalMutation, AdminUpdatePermitPortalMutationVariables>;
export const AdminGetPermitPortalDocument = gql`
    query AdminGetPermitPortal($id: UUID!) {
  admin_getPermitPortal(id: $id) {
    id
    name
    namespace
    issuanceSettings {
      maxHours
      maxPeriodDuration
      maxFuturePermits
    }
    logo {
      fallbackUrl
      info {
        cloudinary {
          cloudName
          defaultTransformation
          format
          publicId
        }
        mode
        url
      }
      rawInfo
    }
    lots {
      lot {
        id
        name
      }
      issuanceSettings {
        blockedDates
        dailyMax
        maxConcurrentPermits
        mixedPermitsFreeLimit
        monthlyMax
        permitChargeType
        weeklyAvailability {
          fri {
            endTime
            startTime
          }
          mon {
            endTime
            startTime
          }
          sat {
            endTime
            startTime
          }
          sun {
            endTime
            startTime
          }
          thu {
            endTime
            startTime
          }
          tue {
            endTime
            startTime
          }
          wed {
            endTime
            startTime
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAdminGetPermitPortalQuery__
 *
 * To run a query within a React component, call `useAdminGetPermitPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetPermitPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetPermitPortalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetPermitPortalQuery(baseOptions: Apollo.QueryHookOptions<AdminGetPermitPortalQuery, AdminGetPermitPortalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetPermitPortalQuery, AdminGetPermitPortalQueryVariables>(AdminGetPermitPortalDocument, options);
      }
export function useAdminGetPermitPortalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetPermitPortalQuery, AdminGetPermitPortalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetPermitPortalQuery, AdminGetPermitPortalQueryVariables>(AdminGetPermitPortalDocument, options);
        }
export type AdminGetPermitPortalQueryHookResult = ReturnType<typeof useAdminGetPermitPortalQuery>;
export type AdminGetPermitPortalLazyQueryHookResult = ReturnType<typeof useAdminGetPermitPortalLazyQuery>;
export type AdminGetPermitPortalQueryResult = Apollo.QueryResult<AdminGetPermitPortalQuery, AdminGetPermitPortalQueryVariables>;
export const AdminCreateVehicleDocument = gql`
    mutation AdminCreateVehicle($userId: Int!, $countryCode: String!, $licenseInfo: JSON!) {
  admin_createVehicle(
    userId: $userId
    countryCode: $countryCode
    licenseInfo: $licenseInfo
  ) {
    id
    make
    makeCode
    model
    label
    countryCode
    color
    country {
      isoCode
      name
      currency
      continent
      callingCode
      phoneNumberAllowed
      hasUserNamePronunciation
      vehiclesAllowed
      lotAllowed
      language
    }
    translatedColor
    license
  }
}
    `;
export type AdminCreateVehicleMutationFn = Apollo.MutationFunction<AdminCreateVehicleMutation, AdminCreateVehicleMutationVariables>;

/**
 * __useAdminCreateVehicleMutation__
 *
 * To run a mutation, you first call `useAdminCreateVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateVehicleMutation, { data, loading, error }] = useAdminCreateVehicleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      countryCode: // value for 'countryCode'
 *      licenseInfo: // value for 'licenseInfo'
 *   },
 * });
 */
export function useAdminCreateVehicleMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateVehicleMutation, AdminCreateVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateVehicleMutation, AdminCreateVehicleMutationVariables>(AdminCreateVehicleDocument, options);
      }
export type AdminCreateVehicleMutationHookResult = ReturnType<typeof useAdminCreateVehicleMutation>;
export type AdminCreateVehicleMutationResult = Apollo.MutationResult<AdminCreateVehicleMutation>;
export type AdminCreateVehicleMutationOptions = Apollo.BaseMutationOptions<AdminCreateVehicleMutation, AdminCreateVehicleMutationVariables>;