import { useEffect } from "react";
import { Grid } from "@citifyd/style";
import { useFormikContext } from "formik";

import AddressForm from "../../../shared/react/components/AddressForm";
import FormikField from "../../../shared/react/components/FormikField";
import { useTranslator } from "../../../shared/react/hooks";

import { VenueMap } from "./VenueMap";

const VenueForm = ({
  addressSchema,
  availableCountries,
  countryCode,
  loadAddressSchema,
  setCountryCode,
}) => {
  const t = useTranslator();

  const { setFieldValue, setValues, values } = useFormikContext();

  useEffect(() => {
    loadAddressSchema(countryCode);
  }, [countryCode]);

  useEffect(() => {
    setValues({
      ...values,
      countryCode: countryCode,
    });
  }, [countryCode]);

  const isValidCoordinates = () => {
    const { coordinates } = values;

    return !!(coordinates && coordinates.latitude && coordinates.longitude);
  };

  const validCoordinates = isValidCoordinates();

  return (
    <Grid.Row>
      <Grid.Col xs={12} sm={7}>
        <FieldContainer marginBottom>
          <FormikField
            required
            name="name"
            label={t("addVenue.form.labels.name")}
            placeholder={t("addVenue.form.placeholders.name")}
          />
        </FieldContainer>
        <FieldContainer marginBottom>
          <AddressForm
            addressFieldName="venueAddress"
            addressSchema={addressSchema}
            availableCountries={availableCountries}
            countryCode={countryCode}
            label={t("addVenue.form.labels.address")}
            loadAddressSchema={loadAddressSchema}
            setCountryCode={setCountryCode}
            shouldShowCountryDropdown={true}
          />
        </FieldContainer>
      </Grid.Col>
      <Grid.Col xs={12} sm={5}>
        {validCoordinates && (
          <FieldContainer marginBottom>
            <VenueMap coordinates={values.coordinates} />
          </FieldContainer>
        )}
      </Grid.Col>
    </Grid.Row>
  );
};

const FieldContainer = ({ children, marginBottom }) => {
  return (
    <Grid.Row marginBottom={marginBottom}>
      <Grid.Col>{children}</Grid.Col>
    </Grid.Row>
  );
};

export default VenueForm;
