import { getTranslator } from "../../../services/languages";

export const changedBankInfo = ({ previousBankAccount, bankAccount }) => {
  if (!previousBankAccount) {
    return true;
  }

  return _.some(
    bankAccount,
    (value, key) => _.trim(value) !== _.trim(previousBankAccount[key]) // use _.trim instead of String#trim to handle null values
  );
};

export const validate = ({ previousBankAccount, bankAccount, actions }) => {
  const t = getTranslator();

  if (changedBankInfo({ previousBankAccount, bankAccount })) {
    if (bankAccount.accountNumber.indexOf("*") !== -1) {
      actions.setFieldError(
        "accountNumber",
        t("modalEditPayment.errors.reenterAccountNumber")
      );
      return false;
    }
  }

  return true;
};
