import { isEmpty, find, mapValues, pick } from "lodash";
import { AVAILABLE_COLORS } from "../../../../shared/react/components/ColorSelector";

import { planifyCountries } from "../../../../shared/services/helper";
import {
  createProperty,
  updateProperty,
  getCountries,
} from "../../../../shared/api";
import { getDistanceBetweenCoordinates } from "../../../../shared/services/geo";

const getFormData = (lot) => {
  return pick(lot, [
    "id",
    "name",
    "address",
    "notes",
    "about",
    "directionsNotes",
    "openingHours",
    "maxSpots",
    "notifyAboutEvents",
    "reservedParkingEnabled",
    "color",
    "extraInfo",
    "latitude",
    "longitude",
    "countryCode",
    "photo",
    "additionalPhotos",
    "amenityCodes",
  ]);
};

export const getAvailableColors = (lot) => {
  if (!isEmpty(lot) && !AVAILABLE_COLORS.includes(lot.color)) {
    return [...AVAILABLE_COLORS, lot.color];
  }

  return AVAILABLE_COLORS;
};

export const getAddressValidation = (addressSchema, yup) => {
  return mapValues(addressSchema.properties, (config, key) => {
    if (config.required) {
      return yup.string().required();
    } else {
      return yup.string();
    }
  });
};

export const getLatitudeAngLongitude = ({ lat, lng, lot }) => {
  const LIMIT = 200;

  const isBeyondLimit = () => {
    const diff = getDistanceBetweenCoordinates(lot, {
      latitude: lat,
      longitude: lng,
    });

    return diff > LIMIT;
  };

  if (isBeyondLimit()) {
    return { latitude: null, longitude: null };
  }

  return { latitude: lat, longitude: lng };
};

export const onCreateLot = async (values) => {
  const { hasPhysicalGates, file, coordinates, ...rest } = values;

  const data = {
    extraInfo: {
      gates: { entryOnly: "", exitOnly: "", mixedUse: "" },
      scan: { entering: false, exiting: false, no: true },
      hasPhysicalGates,
    },
    latitude: coordinates.latitude,
    longitude: coordinates.longitude,
    reservedParkingValidationMethod: "none",
    ...rest,
  };

  return createProperty(data);
};

export const onUpdateLot = async ({ lot, newData }) => {
  let data = getFormData({
    ...lot,
    ...newData,
  });

  data = {
    ...data,
    reservedParkingValidationMethod: "none",
  };

  return updateProperty(data);
};

export const getDefaultContryCodeForNewLot = async (user) => {
  let userCountryCode = null;

  if (user.organization) {
    userCountryCode = user.organization.countryCode;
  } else {
    userCountryCode = user.phoneCountryCode;
  }

  try {
    const countries = await getCountries();

    const countryList = planifyCountries(countries);
    const country = find(countryList, (c) => c.isoCode === userCountryCode);

    if (!country) {
      userCountryCode = null;
    } else if (!country.lotAllowed) {
      // If lot creation is not allowed for this country, look for another country in the same continent
      const sameContinentCountry = find(
        countryList,
        (c) => c.lotAllowed && c.continent === country.continent
      );
      userCountryCode = sameContinentCountry
        ? sameContinentCountry.isoCode
        : null;
    }

    return userCountryCode || "us";
  } catch (error) {
    throw error;
  }
};

export const formatOpeningHoursToBackend = (daysOfWeek, hours) => {
  let formattedOpeningHours = {};

  for (let day in daysOfWeek) {
    if (daysOfWeek[day]) {
      formattedOpeningHours[day] = [hours[day]];
    } else {
      formattedOpeningHours[day] = [];
    }
  }

  return formattedOpeningHours;
};

export const transformAdditionalPhotosToBackend = (images) => {
  let additionalPhotos = [];

  for (let image in images) {
    if (image !== "main" && images[image].image) {
      additionalPhotos.push(images[image].image);
    }
  }

  return additionalPhotos;
};

export const transformAmenitiesToBackend = (amenities) => {
  let selectedAmenitiesCode = Object.entries(amenities).filter(
    ([_code, amenityStatus]) => amenityStatus === true
  );

  return selectedAmenitiesCode.map(([code, _amenityStatus]) => code);
};
