"use strict";

import React from "react";
import { Button, Text } from "@citifyd/style";

import { useTranslator } from "../../hooks";
import RouterLinkButton from "../RouterLinkButton";
import NavBar from "../NavBar";
import styles from "../NavBar/NavBar.module.scss";
import EditPaymentModal from "../../../modals/EditPaymentModal";
import { useModal } from "../../contexts/modal";
import { useStripeStatus } from "../../contexts/stripeStatus";

const PaymentInformationNotificationBar = () => {
  const t = useTranslator();
  const { openModal } = useModal();
  const { stripeStatus } = useStripeStatus();

  const inStripeAccountSetupPage =
    window.location.pathname.indexOf("/stripe-account-setup") === 0;

  if (
    !stripeStatus ||
    stripeStatus.status === "ok" ||
    inStripeAccountSetupPage
  ) {
    return null;
  }

  const openEditPaymentModal = () => {
    openModal(EditPaymentModal);
  };

  const renderMissingVerificationInformationLabel = () => {
    if (stripeStatus.chargesEnabled) {
      return stripeStatus.payoutsEnabled
        ? t(
            "paymentInformationNotificationBar.messages.missingVerificationInformation.chargesAndPayoutsEnabled",
            {
              deadline: stripeStatus.deadline,
            }
          )
        : t(
            "paymentInformationNotificationBar.messages.missingVerificationInformation.chargesEnabledAndPayoutsDisabled",
            {
              deadline: stripeStatus.deadline,
            }
          );
    }

    return stripeStatus.payoutsEnabled
      ? t(
          "paymentInformationNotificationBar.messages.missingVerificationInformation.chargesDisabledAndPayoutsEnabled",
          {
            deadline: stripeStatus.deadline,
          }
        )
      : t(
          "paymentInformationNotificationBar.messages.missingVerificationInformation.chargesAndPayoutsDisabled",
          {
            deadline: stripeStatus.deadline,
          }
        );
  };

  const NavBarItem = ({ title, onClick, label, link, params }) => {
    return (
      <div className={styles.textWithButtons}>
        <div className={styles.text}>
          <span>
            <Text>{title}</Text>
          </span>
        </div>
        <div className={styles.buttons}>
          {link ? (
            <RouterLinkButton
              state={link}
              appearance="white"
              size="small"
              extraPadding
              params={params}
            >
              {label}
            </RouterLinkButton>
          ) : (
            <Button
              onClick={onClick}
              appearance="white"
              size="small"
              extraPadding
            >
              {label}
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <NavBar>
      {stripeStatus.status === "pending_bank_account" && (
        <NavBarItem
          title={t(
            "paymentInformationNotificationBar.messages.enterBankAccount"
          )}
          onClick={openEditPaymentModal}
          label={t(
            "paymentInformationNotificationBar.buttons.setUpPaymentInformation"
          )}
        />
      )}

      {stripeStatus.status === "missing_verification_information" && (
        <NavBarItem
          title={renderMissingVerificationInformationLabel()}
          link="stripe-account-setup"
          label={t(
            "paymentInformationNotificationBar.buttons.updateVerificationInformation"
          )}
        />
      )}

      {stripeStatus.status === "information_being_verified" && (
        <NavBarItem
          title={t(
            "paymentInformationNotificationBar.messages.informationBeingVerified"
          )}
          link="stripe-account-setup-result"
          label={t("paymentInformationNotificationBar.buttons.viewDetails")}
          params={{ status: "view-details" }}
        />
      )}
    </NavBar>
  );
};

export default PaymentInformationNotificationBar;
