"use strict";
import React from "react";
import { Modal, Text } from "@citifyd/style";
import { useTranslator } from "../../react/hooks";
import Table from "../../react/components/Table";
import { formatAvailabilityAllocation } from "../../utils/availability-allocation-utils";
import { getCurrentLanguage } from "../../services/languages";
import currencyFormatter from "@citifyd/currency-formatter";
import styles from "./ReservedParkingPassRatesModal.module.scss";

const ReservedParkingPassRatesModal = ({ open, onClose, feeBreakdown }) => {
  if (!feeBreakdown) return null;

  const t = useTranslator();
  const content = availabilityAllocations({ feeBreakdown });
  const data = content.filter((el) => el !== null && el !== undefined);

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>{t("reservedParkingPassRates.title")}</Modal.Title>
      <Modal.Content>
        <Table
          className={styles.breakDownTable}
          spacing
          showHeader={false}
          columns={[{ key: "title" }, { key: "amount" }]}
          data={data}
        />
      </Modal.Content>
    </Modal>
  );
};

const availabilityAllocations = ({ feeBreakdown }) => {
  const { availabilityAllocationsWithPriceCalculation } =
    feeBreakdown.firstStep;
  const { currency, lot } = feeBreakdown.reservedParkingPass;
  const { timezoneName } = lot;

  return availabilityAllocationsWithPriceCalculation.map((allocation) =>
    availabilityAllocationRow({
      availabilityAllocation: allocation.priceCalculation,
      timezoneName: timezoneName,
      currency: currency,
    })
  );
};

const availabilityAllocationRow = ({
  availabilityAllocation,
  timezoneName,
  currency,
}) => {
  const formatted = formatAvailabilityAllocation({
    availabilityAllocation,
    timezoneName,
    currency,
  });

  return {
    title: (
      <div>
        <Text gutterBottom appearance="gray_dark" variant="small">
          {formatted.timeRange}
        </Text>
        {formatted.rate}
      </div>
    ),
    amount: (
      <div>
        <Price amount={availabilityAllocation?.total} currency={currency} />
      </div>
    ),
  };
};

const Price = ({ amount, currency }) => {
  const language = getCurrentLanguage();
  return currencyFormatter.format(amount, { currency, language });
};

export default ReservedParkingPassRatesModal;
