import React, { useMemo, useState } from "react";
import moment from "moment";

import {
  InputMaybe,
  TransactionOrderBy,
  TransactionType,
  useTransactionsQuery,
} from "../../../graphql-pos/generated/graphql";
import Table from "../../../shared/react/components/Table";

import PosApolloClient from "../../../shared/services/posApolloClient";
import { currencyFormatter } from "../../../shared/utils/currencyFormtter";
import { usePagination } from "../../../shared/react/components/CursorPagination/usePagination";
import { CursorPagination } from "../../../shared/react/components/CursorPagination/CursorPagination";
import { Grid, LoadingIndicator, Select } from "@citifyd/style";
import styles from "./Transactions.module.scss";
import { useTranslator } from "../../../shared/react/hooks";
import { useTransactionFilter } from "../hooks/useTransactionFilter";

const posApolloClient = PosApolloClient();

const pageSize = 10;
export const Transactions = () => {
  const t = useTranslator();
  const { options, typeKeys } = useTransactionFilter();
  const [filterBy, setFilterBy] = useState<InputMaybe<TransactionType>>(null);

  const {
    refetch,
    data: transactions,
    loading,
  } = useTransactionsQuery({
    client: posApolloClient,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pageSize,
      order: TransactionOrderBy["CreatedAt"],
      ...(filterBy ? { type: filterBy } : {}),
    },
  });

  const columns = [
    { value: t("posRover.devices.date"), key: "date" },
    { value: t("posRover.devices.time"), key: "time" },
    { value: t("posRover.devices.type"), key: "type" },
    { value: t("posRover.devices.value"), key: "value" },
    { value: t("posRover.devices.device"), key: "device" },
  ];

  const data = useMemo(
    () =>
      transactions?.transactions.edges?.map((trans) => {
        const transaction = trans?.node;

        if (transaction) {
          const rate = transaction.payment?.rate.rate;
          const currency = transaction.citifydLot?.paymentDestination.currency;

          return {
            date: moment(transaction.createdAt).format("L"),
            time: moment(transaction.createdAt).format("hh:mm A"),
            type:
              transaction.type === "PAYMENT"
                ? typeKeys[transaction.payment?.type!]
                : typeKeys[transaction.type],
            value: currencyFormatter({
              value: rate?.value ? rate?.value + rate.fee : 0,
              currency: currency || "usd",
            }),
            device: `#${transaction.device?.shortCode}`,
          };
        }
      }),
    [transactions]
  );

  const { hasNextPage, hasPreviousPage, nextPage, previousPage } =
    usePagination({
      data: transactions?.transactions,
      pageSize: pageSize,
      refetch,
    });

  return (
    <div>
      <Grid.Row>
        <Grid.Col lg={6} offset={{ lg: 6 }} sm={12}>
          <Select
            disabled={loading}
            fullWidth
            value={filterBy ? filterBy : ""}
            labelSize="small"
            options={options}
            onChange={(event) => {
              setFilterBy(event.target.value);
            }}
          />
        </Grid.Col>
      </Grid.Row>

      <div className={styles.divisor}></div>
      <div className={styles.loadingContainer}>
        {loading && <LoadingIndicator withOverlay />}

        <div className={`${loading ? styles.loading : ""}`}>
          <Table striped columns={columns} data={data} responsive />
        </div>
      </div>

      <CursorPagination
        loading={loading}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        nextPage={nextPage}
        previousPage={previousPage}
        data={
          transactions?.transactions.edges
            ? transactions.transactions.edges
            : []
        }
      />
    </div>
  );
};
