import React from "react";
import { max, sum, sortByOrder } from "lodash";
import { getTranslator } from "../../../../../shared/services/languages";
import { getGatesSummaryForEvent } from "../../../../../shared/api";
import { localizeSystemGateName } from "../../../../../shared/services/gate-entries";

const formattedGates = (lots) => {
  return lots.map((lot) => {
    const amountOfCitifydEntries = sum(
      lot.gates,
      "eventGate.amountOfCitifydEntries"
    );
    const amountOfTicketmasterEntries = sum(
      lot.gates,
      "eventGate.amountOfTicketmasterEntries"
    );

    return {
      ...lot,
      amountOfCitifydEntries: amountOfCitifydEntries,
      amountOfTicketmasterEntries: amountOfTicketmasterEntries,
      amountOfEntries: amountOfCitifydEntries + amountOfTicketmasterEntries,
      gates: lot.gates.map((gate) => {
        const closeouts = gate.eventGate?.cashierCloseouts;

        return {
          ...gate,
          name: localizeSystemGateName(gate),
          lot: lot,
          eventGate: {
            ...gate.eventGate,
            lot: lot,
            gate: gate,
            name: localizeSystemGateName({
              name: gate.eventGate.name,
              isSystemGate: gate.isSystemGate,
            }),
            lastCashierCloseout:
              closeouts.length > 0 ? max(closeouts, "id") : null,
          },
        };
      }),
    };
  });
};

export const loadSummaryInfo = (eventId) => {
  return getGatesSummaryForEvent(eventId)
    .then((lots) => {
      return formattedGates(lots);
    })
    .catch((err) => err);
};

export const getSummaryTableData = ({ summaryInfo }) => {
  if (!summaryInfo) return null;

  const getTotal = (field) => {
    return sum(summaryInfo, (lot) => lot[field]);
  };

  const t = getTranslator();
  const columns = [
    {
      key: "property",
      value: t("event.property"),
      width: "40%",
    },
    {
      key: "sold",
      value: t("event.sold"),
      width: "10%",
    },
    {
      key: "entries",
      value: t("event.entries"),
      width: "15%",
    },
    {
      key: "unredeemed",
      value: t("event.unredeemed"),
      width: "20%",
    },
  ];

  const data = summaryInfo?.map((lot) => ({
    property: lot.name,
    sold: lot.sold?.toString(),
    entries: lot.amountOfEntries?.toString(),
    unredeemed: lot.amountOfUnredeemedTickets?.toString(),
  }));

  data.push({
    appearance: "white",
    property: <strong>{t("event.total")}</strong>,
    sold: <strong>{getTotal("sold")?.toString()}</strong>,
    entries: <strong>{getTotal("amountOfEntries")?.toString()}</strong>,
    unredeemed: (
      <strong>
        {(getTotal("sold") - getTotal("amountOfEntries"))?.toString()}
      </strong>
    ),
  });

  return {
    columns,
    data,
  };
};

export const getLotTableData = ({ lot, openGateView }) => {
  if (!lot) return {};

  const t = getTranslator();
  const columns = [
    {
      key: "gate",
      value: t("event.gate"),
      width: "40%",
    },
    {
      key: "beaconIds",
      value: t("event.beaconIds"),
      width: "20%",
    },
    {
      key: "entries",
      value: t("event.entries"),
      width: "40%",
    },
  ];

  const data = sortByOrder(lot.gates, ["isSystemGate", "eventGate.name"])?.map(
    (item) => ({
      onClick: () => openGateView(item.eventGate),
      gate: item.eventGate.name,
      beaconIds: item.eventGate.beaconUids.join(", "),
      entries: (
        item.eventGate.amountOfCitifydEntries +
        item.eventGate.amountOfTicketmasterEntries
      ).toString(),
    })
  );

  return {
    columns,
    data,
  };
};
