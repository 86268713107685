import React from "react";
import { trim, findWhere, some } from "lodash";
import { Text } from "@citifyd/style";
import moment from "moment-timezone";
import { getTranslator } from "../../../../shared/services/languages";
import { getScheduleSnapshot } from "../../../../shared/api";

const formatRate = (rate, currency) => {
  const t = getTranslator();

  const options = {
    amount: rate.value,
    currency: currency,
  };

  let subtype = null;

  if (rate.maxRate && rate.discount) {
    subtype = "maxRateAndEarlyBird";
    options.maxRate = rate.maxRate;
    options.earlyBirdMaxRate = rate.discount.maxRate;
  } else if (rate.maxRate) {
    subtype = "maxRate";
    options.maxRate = rate.maxRate;
  } else if (rate.discount) {
    subtype = "earlyBird";
    options.maxRate = rate.discount.maxRate;
  } else {
    subtype = "simple";
  }

  return t(`availabilityRateDescription.${rate.type}.${subtype}`, options);
};

const adjustAvailabilities = (lots) => {
  const t = getTranslator();
  return lots.map((lot) => {
    return {
      ...lot,
      availabilities: lot.availabilities.map((availability) => {
        if (availability.events.length) {
          availability.name = availability.events[0].name;
        } else {
          availability.name = trim(availability.name.replace(lot.name, ""));
        }

        availability.reservationCount = availability.reservationCount || 0;

        availability.start = moment.tz(
          availability.start,
          availability.timezoneName
        );
        availability.end = moment.tz(
          availability.end,
          availability.timezoneName
        );

        availability.startTime = t("defaultFormats.time", {
          date: availability.start,
        });
        availability.endTime = t("defaultFormats.time", {
          date: availability.end,
        });

        const now = moment().unix();
        availability.isActive =
          now >= availability.start.unix() && now <= availability.end.unix();

        const lotAvailability = findWhere(availability.lots, {
          id: lot.id,
        });
        availability.rate = formatRate(
          lotAvailability.rate,
          lot.country.currency
        );

        return availability;
      }),
    };
  });
};

export const loadSchedule = async ({ selectedProperty }) => {
  const options = {};

  if (selectedProperty) {
    options.lotId = selectedProperty;
  }

  return getScheduleSnapshot({ params: options })
    .then((lots) => ({
      propertiesWithAvailabilities: adjustAvailabilities(lots),
      hasAvailabilities: some(lots, (lot) => lot.availabilities.length > 0),
    }))
    .catch((err) => ({ error: err }));
};

export const getData = ({ properties }) => {
  if (!properties || properties?.length === 0) return {};

  const t = getTranslator();

  const columns = [
    {
      value: "",
      key: "startAndDateTime",
    },
    {
      value: "",
      key: "availabilityName",
    },
    {
      value: "",
      key: "rate",
    },
    {
      value: "",
      key: "parkers",
    },
  ];

  const formatActiveAvailability = ({ value, availability }) => {
    if (availability?.isActive) {
      return <Text weight="600">{value}</Text>;
    } else {
      return value;
    }
  };

  const data = properties
    .filter((item) => item.availabilities?.length > 0)
    .map((item) => {
      return {
        name: item.name,
        availabilities: item.availabilities.map((availability) => {
          return {
            ...(availability?.isActive && { appearance: "gray_light" }),
            startAndDateTime: {
              value: formatActiveAvailability({
                value: `${availability.startTime} - ${availability.endTime}`,
                availability,
              }),
              width: "20%",
              nowrap: "nowrap",
            },
            availabilityName: {
              value: formatActiveAvailability({
                value: availability.name,
                availability,
              }),
              width: "30%",
              nowrap: "nowrap",
            },
            rate: {
              value: formatActiveAvailability({
                value: availability.rate,
                availability,
              }),
              width: "30%",
              nowrap: "nowrap",
            },
            parkers: {
              value: formatActiveAvailability({
                value: t("dashboard.parkers", {
                  count: availability.reservationCount,
                }),
                availability,
              }),
              width: "20%",
              nowrap: "nowrap",
            },
          };
        }),
      };
    });

  return {
    columns,
    data,
  };
};
