import moment from "moment-timezone";

export const getLotsOptions = ({ lots, t, organizationId }) => {
  if (organizationId && organizationId !== "*") {
    lots = lots.filter((lot) => lot.organizationId === organizationId);
  }
  return [
    {
      label: t("revenue.revenueFilter.fields.allProperties"),
      value: "",
    },

    ...lots.map((lot) => ({ label: lot.name, value: lot.id })),
  ];
};

export const selectionToParams = (
  selection,
  enableDateRangeSelection,
  isEvent
) => {
  const { lotId, currency, dateRange, timezoneName, organizationId } =
    selection;

  let formattedDateRange;
  if (enableDateRangeSelection) {
    const startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    formattedDateRange = `${startDate},${endDate}`;
  }

  const dateKey = "dateRange";

  return {
    lot: lotId || undefined,
    currency: currency,
    [dateKey]: formattedDateRange,
    timezoneName: timezoneName || undefined,
    organizationId: organizationId || undefined,
  };
};
