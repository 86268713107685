"use strict";

import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { sum, find, escape, isEqual, isEmpty } from "lodash";
import { AlertMessage, Text } from "@citifyd/style";

import usePrevious from "../../../../hooks/usePrevious";
import LoadingManager from "../../../LoadingManager";
import { useService, useTranslator } from "../../../../hooks";
import RevenueFilter from "../RevenueFilter";
import RevenuePayoutsTable from "../RevenuePayoutsTable";
import { getFilterSelectionFromState } from "../../utils";
import GenerateReportModal from "../../../../../../shared/modals/GenerateReportModal";
import {
  getRevenuePayouts,
  getRevenuePayoutsCsv,
  getCountry,
  isRequestCancelled,
} from "../../../../../../shared/api";

import { useAuthentication } from "../../../../../../shared/react/contexts/authentication";
import { useModal } from "../../../../../../shared/react/contexts/modal";

const RevenuePayouts = ({ lots, currencies, organizationsOptions }) => {
  const t = useTranslator();
  const { openModal } = useModal();
  const $state = useService("$state");
  const $stateParams = useService("$stateParams");
  const { user: loggedUser } = useAuthentication();
  const lotId = $stateParams.lot;
  const [failedPayoutMessage, setFailedPayoutMessage] = useState(null);
  const abortRequest = useRef(null);
  const [payouts, setPayouts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totals, setTotals] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const prevParams = usePrevious($state.params);

  const getQueryOptionsFromFilterSelection = (filterSelection) => {
    return {
      rangeStart: moment(filterSelection.dateRange.startDate).format(
        "YYYY-MM-DD"
      ),
      rangeEnd: moment(filterSelection.dateRange.endDate).format("YYYY-MM-DD"),
      organizationId: filterSelection.organizationId
        ? Number(filterSelection.organizationId)
        : null,
      currency: filterSelection.currency,
      lotId: filterSelection.lotId,
    };
  };

  const calculateTotals = (payouts) => {
    const fields = ["amount", "amountForSelectedLot"];

    let totals = {};

    for (const field of fields) {
      totals[field] = sum(payouts, (payout) => {
        return payout[field];
      });
    }

    setTotals(totals);
  };

  const loadFailedPayoutMessage = async () => {
    const { countryCode } = loggedUser.organization;

    try {
      const country = await getCountry(countryCode);

      const { supportPhoneNumber, supportEmail } = country.citifydLocalInfo;

      const rawMessage = t("revenue.payoutFailed", {
        phoneNumber: supportPhoneNumber.value,
        email: "[email]",
      });

      const failedPayoutMessage = escape(rawMessage).replace(
        /\[email\]/g,
        `<a href="mailto:${supportEmail.value}">${supportEmail.value}</a>`
      );

      setFailedPayoutMessage(failedPayoutMessage);
    } catch (err) {
      console.log(`${countryCode}: country information could not be lodaded`);
    }
  };

  const load = async () => {
    setIsLoading(true);

    if (abortRequest.current) {
      abortRequest.current.abort();
      abortRequest.current = null;
    }

    const filterSelection = getFilterSelectionFromState($state, currencies);
    const options = getQueryOptionsFromFilterSelection(filterSelection);

    setCurrentFilter(filterSelection);

    try {
      abortRequest.current = new AbortController();

      const payouts = await getRevenuePayouts({
        params: options,
        signal: abortRequest.current.signal,
      });

      setPayouts(payouts);
      calculateTotals(payouts);

      const hasFailedPayment = Boolean(
        find(payouts, (payout) => payout.status === "failed")
      );

      if (loggedUser.organization && hasFailedPayment) {
        loadFailedPayoutMessage();
      }
      setIsLoading(false);
    } catch (err) {
      if (!isRequestCancelled(err)) {
        setIsLoading(false);
      }
      console.log("err", err);
    } finally {
      setIsLoading(false);
      abortRequest.current = null;
    }
  };

  useEffect(() => {
    if (
      !isEqual(prevParams, $state.params) &&
      !isEmpty($state.params?.dateRange)
    ) {
      load();
    }
  }, [$state.params]);

  function generateCsvReportDescription(filterSelection) {
    const options = {
      startDate: moment.tz(filterSelection.dateRange.startDate, "UTC"),
      endDate: moment.tz(filterSelection.dateRange.endDate, "UTC"),
    };

    if (filterSelection.lotId) {
      const lot = find(lots, (lot) => lot.id === Number(filterSelection.lotId));
      return t("revenue.csvReportName.payouts.lot", {
        ...options,
        lotName: lot.name,
      });
    } else {
      return t("revenue.csvReportName.payouts.currency", {
        ...options,
        currency: filterSelection.currency.toUpperCase(),
      });
    }
  }

  const generateCsvClicked = () => {
    const filterSelection = getFilterSelectionFromState($state);
    const options = getQueryOptionsFromFilterSelection(filterSelection);

    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription: generateCsvReportDescription(filterSelection),
      reportCsvRequest: getRevenuePayoutsCsv(options),
    });
  };

  return (
    <div>
      <RevenueFilter
        onClickGenerateCsv={generateCsvClicked}
        enableLotSelection
        lots={lots}
        data={payouts}
        currencies={currencies}
        organizationsOptions={organizationsOptions}
      />
      {failedPayoutMessage && (
        <AlertMessage>
          <p dangerouslySetInnerHTML={{ __html: failedPayoutMessage }}></p>
        </AlertMessage>
      )}
      {isLoading && <LoadingManager isLoading={isLoading} />}

      {payouts.length > 0 && !isLoading && (
        <RevenuePayoutsTable
          payouts={payouts}
          totals={totals}
          currentFilter={currentFilter}
          loggedUser={loggedUser}
          lotId={lotId}
        />
      )}

      {payouts.length === 0 && !isLoading && (
        <Text textAlign="center">{t("search.noResults")}</Text>
      )}
    </div>
  );
};

export default RevenuePayouts;
