import { useTranslator } from "../../../shared/react/hooks";

export const useTransactionFilter = () => {
  const t = useTranslator();

  const typeKeys = {
    CARD: t("posRover.devices.card"),
    CASH: t("posRover.devices.cash"),
    SCAN: t("posRover.devices.scan"),
    EXEMPTION_INGRESS: t("posRover.devices.exemptionIngress"),
    VIP_MEMBER_INGRESS: t("posRover.devices.vipMemberIngress"),
    CITIFYD_INGRESS: t("posRover.devices.citifydIngress"),
  };

  const options = [
    { value: "", label: t("posRover.devices.filters.filterByType") },
    { value: "PAYMENT", label: t("posRover.devices.filters.cashAndCard") },
    { value: "SCAN", label: t("posRover.devices.filters.scan") },
    {
      value: "EXEMPTION_INGRESS",
      label: t("posRover.devices.filters.exemption"),
    },
    { value: "VIP_MEMBER_INGRESS", label: t("posRover.devices.filters.vip") },
    { value: "CITIFYD_INGRESS", label: t("posRover.devices.filters.citifyd") },
  ];

  return { typeKeys, options };
};
