import { remove } from "lodash";
import { FormLot, PropertyConfiguration } from "../types";
import { END_TIME, START_TIME } from "../constants";
interface PropertyConfigurationProps {
  propertyConfiguration: PropertyConfiguration[];
  properties: FormLot[];
}

export const syncPropertyConfiguration = ({
  propertyConfiguration,
  properties,
}: PropertyConfigurationProps) => {
  propertyConfiguration = [...propertyConfiguration];

  addConfigurations({ propertyConfiguration, properties });
  removeConfigurations({ propertyConfiguration, properties });

  return propertyConfiguration;
};

const addConfigurations = ({
  propertyConfiguration,
  properties,
}: PropertyConfigurationProps) => {
  for (const property of properties) {
    // Do not add configuration if it already exists for this property
    const exists = propertyConfiguration.find(
      (p) => p.propertyId === property.value
    );
    if (exists) continue;

    propertyConfiguration.push({
      propertyId: property.value,
      propertyName: property.label,
      daysOfWeek: {
        mon: false,
        tue: true,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
      },
      hours: {
        mon: { start: START_TIME, end: END_TIME, openAllDay: false },
        tue: { start: START_TIME, end: END_TIME, openAllDay: false },
        wed: { start: START_TIME, end: END_TIME, openAllDay: false },
        thu: { start: START_TIME, end: END_TIME, openAllDay: false },
        fri: { start: START_TIME, end: END_TIME, openAllDay: false },
        sat: { start: START_TIME, end: END_TIME, openAllDay: false },
        sun: { start: START_TIME, end: END_TIME, openAllDay: false },
      },
      permitsMonthlyLimit: {
        quantity: "",
        noLimit: false,
      },
      permitsDailyLimit: {
        quantity: "",
        noLimit: false,
      },
      concurrentPermitsLimit: {
        quantity: "",
        noLimit: false,
      },
      blockedDates: [],
      permitType: "free",
      amountOfFreePermits: "",
    });
  }
};

const removeConfigurations = ({
  propertyConfiguration,
  properties,
}: PropertyConfigurationProps) => {
  remove(propertyConfiguration, (item) => {
    const exists = properties.find(
      (property) => property.value === item.propertyId
    );
    return !exists;
  });
};
