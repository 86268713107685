import { getRevenuePayoutItems } from "../../../shared/api";
import { getTranslator } from "../../../shared/services/languages";
import { sumAmount, filterItems, adjustForSingleDaySession } from "./utils";

const loadPayoutItems = async ({ payoutId, lotId }) => {
  const t = getTranslator();
  const payoutItems = {};

  const items = await getRevenuePayoutItems(payoutId, {
    params: {
      lotId,
    },
  });

  payoutItems.bundlePurchases = filterItems(items, "bundlePurchase");
  payoutItems.bundlePurchasesTotal = sumAmount(payoutItems.bundlePurchases);

  payoutItems.reservations = adjustForSingleDaySession(
    filterItems(items, "reservation")
  );
  payoutItems.reservationsTotal = sumAmount(payoutItems.reservations);
  payoutItems.reservedParkingPasses = adjustForSingleDaySession(
    filterItems(items, "reservedParkingPass")
  );
  payoutItems.reservedParkingPassesTotal = sumAmount(
    payoutItems.reservedParkingPasses
  );

  payoutItems.subscriptions = filterItems(items, "subscription");
  payoutItems.subscriptionsTotal = sumAmount(payoutItems.subscriptions);

  payoutItems.tickets = filterItems(items, "ticket");
  payoutItems.ticketsTotal = sumAmount(payoutItems.tickets);
  payoutItems.ticketCancellations = filterItems(items, "ticketCancellation");
  payoutItems.ticketCancellationsTotal = sumAmount(
    payoutItems.ticketCancellations
  );

  payoutItems.payoutReconciliations = filterItems(
    items,
    "payoutReconciliation"
  );
  payoutItems.payoutReconciliationsTotal = sumAmount(
    payoutItems.payoutReconciliations
  );

  payoutItems.summaryTotal = sumAmount(items);
  payoutItems.summary = [
    {
      refName: "onDemand",
      name: t("payout.onDemandPasses"),
      total: payoutItems.reservationsTotal,
      hasItems: payoutItems.reservations.length > 0,
    },
    {
      refName: "reservedParkingPasses",
      name: t("payout.reservedParkingPasses"),
      total: payoutItems.reservedParkingPassesTotal,
      hasItems: payoutItems.reservedParkingPasses.length > 0,
    },
    {
      refName: "eventPasses",
      name: t("payout.eventPasses"),
      total: payoutItems.ticketsTotal,
      hasItems: payoutItems.tickets.length > 0,
    },
    {
      refName: "bundles",
      name: t("payout.bundles"),
      total: payoutItems.bundlePurchasesTotal,
      hasItems: payoutItems.bundlePurchases.length > 0,
    },
    {
      refName: "monthlyParkers",
      name: t("payout.monthlyParking"),
      total: payoutItems.subscriptionsTotal,
      hasItems: payoutItems.subscriptions.length > 0,
    },
    {
      refName: "reconciliationTicketCancellations",
      name: t("payout.reconciliation"),
      total: payoutItems.ticketCancellationsTotal,
      hasItems: payoutItems.ticketCancellations.length > 0,
      hidden: payoutItems.ticketCancellationsTotal === 0,
    },
    {
      refName: "payout-reconciliations-section",
      name: t("payout.reconciliation"),
      total: payoutItems.payoutReconciliationsTotal,
      hasItems: payoutItems.payoutReconciliations.length > 0,
      hidden: payoutItems.payoutReconciliationsTotal === 0,
    },
  ];

  return payoutItems;
};

export default loadPayoutItems;
