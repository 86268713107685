"use strict";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  AdminGetAccountPermissionCompanyQuery,
  useAdminCreateAccountPermissionCompanyMutation,
  useAdminUpdateAccountPermissionCompanyMutation,
  useAdminUpdateAccountPermissionUserInvitationCompanyMutation,
  AdminGetAccountPermissionUserInvitationQuery,
} from "../../graphql/generated/graphql";

import { useAuthentication } from "../../shared/react/contexts/authentication";
import { useService } from "../../shared/react/hooks";
import ApolloClient from "../../shared/services/apolloClient";
import { showErrorAlert } from "../../shared/services/helper";
import { formatPhoneNumber } from "../../shared/utils/phone-number-formatting";

import { CompanyData, Lot } from "./types";

type FormValues = AddFormValues | EditFormValues;
type AddFormValues = CommonFormValues & { email: string };
type EditFormValues = CommonFormValues & { phoneNumber: string };
interface CommonFormValues {
  name: string;
  canContactParkers: boolean;
  selectAll: boolean;
  lots: { [lotId: string]: boolean };
}

const apolloClient = ApolloClient();

export const useAttendantMonitoringCompany = ({
  lots,
  companyData,
}: {
  lots: Lot[];
  companyData: CompanyData;
}) => {
  const { user } = useAuthentication();
  const $state = useService("$state");
  const isEditing = Boolean($state.params.id);
  const isPending = $state.params.pending;

  const [createAccountPermissionCompanyMutation] =
    useAdminCreateAccountPermissionCompanyMutation({
      client: apolloClient,
    });

  const [updateAccountPermissionCompanyMutation] =
    useAdminUpdateAccountPermissionCompanyMutation({
      client: apolloClient,
    });

  const [updateAccountPermissionUserInvitationUserMutation] =
    useAdminUpdateAccountPermissionUserInvitationCompanyMutation({
      client: apolloClient,
    });

  const initialValues = getInitialValues({
    allLots: lots,
    companyData,
    isPending,
  });
  const formikbag = useFormik<FormValues>({
    initialValues,
    validationSchema: yup.object().shape({
      name: isEditing ? yup.string() : yup.string().required(),
      email: isEditing ? yup.string().email() : yup.string().email().required(),
    }),

    onSubmit: async (values) => {
      const newLots = Object.entries(values.lots)
        .filter(([_lotId, selected]) => selected)
        .map(([lotId, _selected]) => Number(lotId));

      try {
        if (isEditing && !isPending && companyData) {
          const editValues = values as EditFormValues;

          await updateAccountPermissionCompanyMutation({
            variables: {
              organizationId: user.organization.id,
              userId: companyData.id,
              lots: newLots,
              canContactParkers: editValues.canContactParkers,
            },
          });
        } else if (isEditing && isPending && companyData) {
          const addValues = values as AddFormValues;
          await updateAccountPermissionUserInvitationUserMutation({
            variables: {
              organizationId: user.organization.id,
              inviteId: companyData.id,
              lots: newLots,
              canContactParkers: addValues.canContactParkers,
              name: addValues.name,
              email: addValues.email,
            },
          });
        } else {
          const addValues = values as AddFormValues;

          await createAccountPermissionCompanyMutation({
            variables: {
              organizationId: user.organization.id,
              name: addValues.name,
              email: addValues.email,
              canContactParkers: addValues.canContactParkers,
              lots: newLots,
            },
          });
        }
        $state.transitionTo("account-permissions");
      } catch (err) {
        showErrorAlert(err.data);
      }
    },
  });
  return formikbag;
};

const getInitialValues = ({
  allLots,
  companyData,
  isPending,
}: {
  companyData: CompanyData;
  allLots: Lot[];
  isPending: Boolean;
}): FormValues => {
  const lots = {};

  for (const lotEdge of allLots) {
    lots[lotEdge.id] = false;
  }

  if (!companyData) {
    return {
      name: "",
      email: "",
      canContactParkers: false,
      selectAll: false,
      lots,
    };
  }

  for (const lot of companyData.properties!) {
    lots[lot!.id] = true;
  }

  const selectedLots = Object.values(lots).every((value) => value);

  if (!isPending) {
    const data = companyData as Exclude<
      AdminGetAccountPermissionCompanyQuery["getAccountPermissionCompany"],
      null | undefined
    >;

    return {
      name: data.name ?? "",
      canContactParkers: data.canContactParkers,
      selectAll: selectedLots,
      phoneNumber: formatPhoneNumber(data.phoneNumber, data.phoneCountryCode),
      lots,
    };
  } else {
    const data = companyData as Exclude<
      AdminGetAccountPermissionUserInvitationQuery["getAccountPermissionUserInvitation"],
      null | undefined
    >;

    return {
      name: data.name,
      canContactParkers: data.canContactParkers,
      selectAll: selectedLots,
      lots,
      email: data.email,
    };
  }
};
