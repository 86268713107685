"use strict";

import loadScript from "load-script-global";
import moment from "moment-timezone";
import { getIntercomUserHash } from "../api";

import { getFullPhoneNumber } from "../utils/phone-number-formatting";

const { REACT_APP_INTERCOM_APP_KEY } = process.env;

let userHash = null;

const runOnIntercom = (callback) => {
  if (window.Intercom) {
    callback(window.Intercom);
  }
};

export const updateIntercom = (user = null) => {
  runOnIntercom((Intercom) => {
    if (user) {
      Intercom("update", user);
    } else {
      Intercom("update");
    }
  });
};

export const logoutIntercom = () => {
  runOnIntercom((Intercom) => {
    Intercom("shutdown");
  });
};

const intercomSettings = (user) => {
  const data = {
    user_id: String(user.id),
    name: user.name,
    email: user.email,
    phone: getFullPhoneNumber(user.phoneNumber, user.phoneCountryCode),
    created_at: moment(user.createdAt).unix(),
    unsubscribed_from_emails: !user.receiveEmailNotifications,
    language_override: user.language,
    is_facebook_user: user.isFacebookUser,
  };

  if (user.organization) {
    data.companies = [
      {
        company_id: user.organization.id,
        name: user.organization.name,
        country: user.organization.countryCode,
        has_cashier_report: user.organization.hasCashierReport,
        has_crm_access: user.organization.hasCashierReport,
        has_event_creation_access: user.organization.hasEventCreationAccess,
      },
    ];
  }
  return data;
};

export const saveIntercomUserData = (data) => {
  runOnIntercom((Intercom) => {
    const user = intercomSettings(data);

    if (user && !userHash) {
      getIntercomUserHash().then((response) => {
        userHash = response;
        Intercom("boot", {
          user_id: String(user.user_id),
          user_hash: userHash,
        });
      });
    } else {
      updateIntercom(user);
    }
  });
};

export const loadIntercom = () => {
  loadScript(
    {
      url: `https://widget.intercom.io/widget/${REACT_APP_INTERCOM_APP_KEY}`,
      global: "Intercom",
    },
    (err, Intercom) => {
      if (Intercom) {
        Intercom("boot", {
          app_id: REACT_APP_INTERCOM_APP_KEY,
        });
      }

      if (err) {
        console.warn(err);
      }
    }
  );
};
