import AddressFormatter from "@citifyd/address-formatter";
import AdminEditUserPhoneNumberModal from "../../../../shared/modals/AdminEditUserPhoneNumberModal";
import UserUpdateEmailModal from "../../../../shared/modals/UserUpdateEmailModal";
import UserMigrationTicketsModal from "../../../../shared/modals/UserMigrationTicketsModal";

import { getService } from "../../../../shared/react/utils";

export const openMigrationTicketsInformation = (
  migrationTickets,
  user,
  openModal
) => {
  const $state = getService("$state");

  migrationTickets = _.cloneDeep(migrationTickets);

  openModal(UserMigrationTicketsModal, { migrationTickets, user }, (result) => {
    if (result && result.action === "refresh") {
      $state.go($state.current.url, {}, { reload: true });
    }
  });
};

export const getAddress = (user) =>
  AddressFormatter.format(user.market.address, user.market.countryCode, "city");

export const openUserUpdateEmail = ({ user, loadUser, openModal }) => {
  openModal(UserUpdateEmailModal, {
    user,
    onClose: ({ userUpdated } = {}) => {
      if (userUpdated) {
        loadUser();
      }
    },
  });
};

export const openUserUpdatePhoneNumber = ({ user, loadUser, openModal }) => {
  openModal(AdminEditUserPhoneNumberModal, {
    user,
    onClose: ({ userUpdated } = {}) => {
      if (userUpdated) {
        loadUser();
      }
    },
  });
};
