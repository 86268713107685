"use strict";
import React, { useMemo } from "react";
import { Text } from "@citifyd/style";
import Table from "../../../shared/react/components/Table";
import { useService, useTranslator } from "../../../shared/react/hooks";
import { displayDate, formatValue } from "./functions/utils";
import GuestBadge from "../../../shared/react/components/GuestBadge";
import Permissions from "../../../shared/services/permissions";
import Total from "./Total.jsx";
import { useAuthentication } from "../../../shared/react/contexts/authentication";

const ReservedParkingPasses = React.forwardRef(
  ({ items, total, currency }, ref) => {
    const t = useTranslator();
    const $state = useService("$state");
    const { user, permissions } = useAuthentication();

    const columns = [
      ...(Permissions.userMaySeeUserInformation(permissions)
        ? [{ key: "name", value: t("payout.username") }]
        : []),
      { key: "property", value: t("payout.property") },
      { key: "startTime", value: t("payout.start") },
      { key: "endTime", value: t("payout.end") },
      {
        key: "total",
        value: <Text textAlign="right">{t("payout.revenue")}</Text>,
      },
    ];

    const data = useMemo(() => {
      return [
        ...items.map((item) => ({
          ...item,
          name: Permissions.userMaySeeUserInformation(permissions) ? (
            <>
              {item.user.name} {item.user.isGuest && <GuestBadge />}
            </>
          ) : (
            ""
          ),
          property: item.lot.name,
          startTime: displayDate({
            date: item.startTime,
            format: "shortDatetime",
            user,
          }),
          endTime: displayDate({
            date: item.endTime,
            format: "shortDatetime",
            user,
          }),
          total: <Total value={item.amount} currency={currency} />,

          onClick: Permissions.userMayOpenReservedParkingPassPage()
            ? () => {
                $state.go(
                  "reserved-parking-pass",
                  { id: item.id },
                  { enableBackLink: true }
                );
              }
            : null,
        })),
        {
          name: <Text bold>{t("payout.total")}</Text>,
          total: (
            <Text bold textAlign="right">
              {formatValue({
                value: total,
                currency,
              })}
            </Text>
          ),
        },
      ];
    }, [items, currency, total]);

    return (
      <div ref={ref}>
        <Text variant="h3" gutterBottom>
          {t("payout.reservedParkingPasses")}
        </Text>

        <Table
          columns={columns}
          data={data}
          gutterBottom
          responsive
          striped
          clickable
        />
      </div>
    );
  }
);
export default ReservedParkingPasses;
