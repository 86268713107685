import gql from "graphql-tag";

export default gql`
  query ($id: UUID!) {
    admin_getPermitPortalUser(id: $id) {
      id
      name
      identifier
      phoneCountryCode
      phoneNumber
      accessExpiresAt
      portals {
        name
        namespace
        id
      }
    }
  }
`;
