"use strict";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useService, useTranslator } from "../../shared/react/hooks";

import loadPayoutItems from "./functions/loadPayoutItems";

import PageHeader from "../../shared/react/components/PageHeader";
import PayoutTable from "./components/PayoutTable.jsx";
import HeaderActions from "./components/HeaderActions/HeaderActions.jsx";
import Summary from "./components/Summary.jsx";
import OnDemandParkers from "./components/OnDemandParkers.jsx";
import ReservedParkingPasses from "./components/ReservedParkingPasses.jsx";
import EventPasses from "./components/EventPasses.jsx";
import MonthlyParkers from "./components/MonthlyParkers.jsx";
import Bundles from "./components/Bundles.jsx";
import ReconciliationTicketCancellations from "./components/ReconciliationTicketCancellations.jsx";
import PayoutReconciliations from "./components/PayoutReconciliations.jsx";
import LoadingManager from "../../shared/react/components/LoadingManager";
import { getRevenuePayout } from "../../shared/api";

const Payout = () => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const [isLoading, setIsLoading] = useState(true);
  const [payout, setPayout] = useState();
  const [absoluteAmount, setAbsoluteAmount] = useState();
  const [payoutItems, setPayoutItems] = useState();
  const [lotId, setLotId] = useState(Number($stateParams.lot) || "");

  const lot = useMemo(
    () => payout?.lots.find((lot) => lot.id === Number(lotId)),
    [payout, lotId]
  );

  const tableRefs = {
    onDemand: useRef(),
    reservedParkingPasses: useRef(),
    eventPasses: useRef(),
    bundles: useRef(),
    monthlyParkers: useRef(),
    reconciliationTicketCancellations: useRef(),
    payoutReconciliations: useRef(),
  };

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const lotId = $stateParams.lot;
    const response = await getRevenuePayout($stateParams.payout);

    setPayout(response);
    setAbsoluteAmount(Math.abs(payout?.amount));
    setPayoutItems(
      await loadPayoutItems({
        payoutId: $stateParams.payout,
        lotId,
      })
    );
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <LoadingManager
          isLoading={isLoading}
          loadingMessage={t("revenue.loading")}
        />
      )}
      {!isLoading && (
        <>
          <Header currency={payout?.currency} lotId={lotId} />
          <PayoutTable payout={payout} absoluteAmount={absoluteAmount} />

          {payout && payoutItems && (
            <>
              <HeaderActions
                lot={lot}
                lotId={lotId}
                setLotId={setLotId}
                setPayoutItems={setPayoutItems}
                payout={payout}
                payoutId={$stateParams.payout}
                setIsLoading={setIsLoading}
              />
              <Summary
                lotName={lot?.name}
                tableRefs={tableRefs}
                items={payoutItems.summary}
                total={payoutItems.summaryTotal}
                currency={payout.currency}
              />
              {payoutItems.reservations.length > 0 && (
                <OnDemandParkers
                  ref={tableRefs.onDemand}
                  items={payoutItems.reservations}
                  total={payoutItems.reservationsTotal}
                  currency={payout.currency}
                />
              )}
              {payoutItems.reservedParkingPasses.length > 0 && (
                <ReservedParkingPasses
                  ref={tableRefs.reservedParkingPasses}
                  items={payoutItems.reservedParkingPasses}
                  total={payoutItems.reservedParkingPassesTotal}
                  currency={payout.currency}
                />
              )}
              {payoutItems.tickets.length > 0 && (
                <EventPasses
                  ref={tableRefs.eventPasses}
                  items={payoutItems.tickets}
                  total={payoutItems.ticketsTotal}
                  currency={payout.currency}
                />
              )}
              {payoutItems.bundlePurchases.length > 0 && (
                <Bundles
                  ref={tableRefs.bundles}
                  items={payoutItems.bundlePurchases}
                  total={payoutItems.bundlePurchasesTotal}
                  currency={payout.currency}
                />
              )}
              {payoutItems.subscriptions.length > 0 && (
                <MonthlyParkers
                  ref={tableRefs.monthlyParkers}
                  items={payoutItems.subscriptions}
                  total={payoutItems.subscriptionsTotal}
                  currency={payout.currency}
                />
              )}
              {payoutItems.ticketCancellations.length > 0 && (
                <ReconciliationTicketCancellations
                  ref={tableRefs.reconciliationTicketCancellations}
                  items={payoutItems.ticketCancellations}
                  total={payoutItems.ticketCancellationsTotal}
                  currency={payout.currency}
                />
              )}
              {payoutItems.payoutReconciliations.length > 0 && (
                <PayoutReconciliations
                  ref={tableRefs.payoutReconciliations}
                  items={payoutItems.payoutReconciliations}
                  total={payoutItems.payoutReconciliationsTotal}
                  currency={payout.currency}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const Header = ({ lotId, currency }) => {
  const t = useTranslator();
  return (
    <PageHeader>
      <PageHeader.Title
        weight="300"
        title={t("revenue.payoutHistory")}
        defaultState="reports"
        defaultParams={{
          reportType: "payouts",
          currency,
          lot: lotId || undefined,
        }}
      />
    </PageHeader>
  );
};

export default Payout;
