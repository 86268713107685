"use strict";

import React from "react";
import { Grid, Text } from "@citifyd/style";
import { useTranslator } from "../../../../../shared/react/hooks";
import GoogleMaps from "../../../../../shared/react/components/GoogleMaps";

export const Google = ({ handleDragEnd, coordinates }) => {
  const t = useTranslator();
  return (
    <>
      <Grid.Row>
        <Grid.Col xs={12} lg={6}>
          <Text gutterBottom variant="h5">
            {t("addProperty.labels.verifyLocation")}
          </Text>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col xs={12} lg={5}>
          <GoogleMaps
            handleDragEnd={handleDragEnd}
            initialCoordinates={coordinates}
          />
          <br />
        </Grid.Col>
        <Grid.Col xs={12} lg={7}>
          <Text>{t("addProperty.labels.adjust")}</Text>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};
