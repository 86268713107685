"use strict";

import React, { useMemo } from "react";
import { Icon, Input, Text } from "@citifyd/style";
import produce from "immer";
import { useFormikContext } from "formik";

import { useTranslator } from "../../../../shared/react/hooks";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import { getTranslatedDayName } from "../../../../shared/services/helper";
import { FormValues, PropertyConfiguration } from "../../types";

import Table from "../../../../shared/react/components/Table";
import { END_TIME, IS_MIDNIGHT, START_TIME } from "../../constants";

const SelectedDaysOfTheWeekTable = ({
  propertyConfig,
  index,
  prefix,
}: {
  propertyConfig: PropertyConfiguration;
  index: number;
  prefix: string;
}) => {
  const t = useTranslator();

  const { values, setValues, setFieldValue } = useFormikContext<FormValues>();

  const removeDay = (day) => {
    setValues(
      produce((values) => {
        values.propertyConfiguration[index].daysOfWeek[day] = false;
      })
    );
  };

  const columns = [
    {
      value: t("permits.permitters.settings.day"),
      key: "day",
      uppercase: false,
      textColor: "gray_dark",
      width: "20%",
    },
    {
      value: t("permits.permitters.settings.start"),
      nowrap: "nowrap",
      key: "start",
      uppercase: false,
      textColor: "gray_dark",
      width: "25%",
    },
    {
      value: t("permits.permitters.settings.end"),
      nowrap: "nowrap",
      key: "end",
      uppercase: false,
      textColor: "gray_dark",
      width: "25%",
    },
    {
      key: "openAllDay",
      width: "25%",
    },

    {
      key: "action",
      width: "5%",
    },
  ];

  const data = useMemo(
    () =>
      ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
        .filter((day) => propertyConfig.daysOfWeek[day])
        .map((day) => {
          let selectedDay = values.propertyConfiguration[index].hours[day];

          return {
            day: {
              value: <Text>{getTranslatedDayName(day, "dddd")}</Text>,
              nowrap: "nowrap",
              verticalAlign: "middle",
            },
            start: {
              value: selectedDay.openAllDay ? (
                <Input fullWidth value="12:00am" disabled />
              ) : (
                <FormikField
                  as={FIELD_TYPE.SIMPLE_TIME_INPUT}
                  name={`${prefix}.hours.${day}.start`}
                  handleChange={(value) => {
                    if (value === "00:00" && selectedDay.end === "00:00") {
                      setFieldValue(`${prefix}.hours.${day}.openAllDay`, true);
                    }
                  }}
                />
              ),
              nowrap: "nowrap",
              verticalAlign: "middle",
            },
            end: {
              value: selectedDay.openAllDay ? (
                <Input fullWidth value="12:00am" disabled />
              ) : (
                <FormikField
                  as={FIELD_TYPE.SIMPLE_TIME_INPUT}
                  name={`${prefix}.hours.${day}.end`}
                  handleChange={(value) => {
                    if (value === "00:00" && selectedDay.start === "00:00") {
                      setFieldValue(`${prefix}.hours.${day}.openAllDay`, true);
                    }
                  }}
                />
              ),

              nowrap: "nowrap",
              verticalAlign: "middle",
            },

            openAllDay: {
              value: (
                <FormikField
                  as={FIELD_TYPE.CHECKBOX}
                  label="24 hours"
                  name={`${prefix}.hours.${day}.openAllDay`}
                  handleChange={(e) => {
                    const unchecked = !e.target.checked;

                    if (
                      unchecked &&
                      selectedDay.start === IS_MIDNIGHT &&
                      selectedDay.end === IS_MIDNIGHT
                    ) {
                      setFieldValue(`${prefix}.hours.${day}.start`, START_TIME);
                      setFieldValue(`${prefix}.hours.${day}.end`, END_TIME);
                    }
                  }}
                />
              ),
              nowrap: "nowrap",
              verticalAlign: "middle",
            },
            action: {
              value: (
                <Icon
                  size="h3"
                  appearance="tertiary"
                  icon="times-circle"
                  onClick={() => removeDay(day)}
                />
              ),
              verticalAlign: "middle",
            },
          };
        }),
    [propertyConfig, values.propertyConfiguration]
  );

  return (
    <Table data={data} columns={columns} spacing headerAppearance="white" />
  );
};

export default SelectedDaysOfTheWeekTable;
