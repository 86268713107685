import React, { useEffect, useState, useMemo } from "react";
import { Text } from "@citifyd/style";
import { isEqual, isEmpty } from "lodash";
import { useLazyQuery } from "@apollo/client";
import useInfiniteScroll from "react-infinite-scroll-hook";

import usePrevious from "../../../../shared/react/hooks/usePrevious";
import { useService } from "../../../../shared/react/hooks";
import ApolloClient from "../../../../shared/services/apolloClient";
import Table from "../../../../shared/react/components/Table";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import ADMIN_LIST_RESERVED_PARKING_PASSES from "../../../../graphql/reservedParkingPasses/queries/AdminListReservedParkingPasses";
import Filters from "./Filters";
import {
  getFilterSelectionFromState,
  getTableData,
  selectDefaultFilters,
  getFormattedVariables,
  generateEmptyListMessage,
} from "./utils";

import styles from "./ReservedParking.module.scss";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const apolloClient = ApolloClient();

const ReservedParking = ({ selectedProperty, ...rest }) => {
  const $state = useService("$state");
  const { permissions } = useAuthentication();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(false);
  const [emptyListMessage, setEmptyListMessage] = useState(null);
  const prevParams = usePrevious($state.params);
  const [reservedParkingPasses, setReservedParkingPasses] = useState();
  const tableContent = useMemo(
    () => getTableData({ reservedParkingPasses, permissions }),
    [reservedParkingPasses]
  );

  const [getReservedParkingPasses, { data, error, loading, fetchMore }] =
    useLazyQuery(ADMIN_LIST_RESERVED_PARKING_PASSES, {
      client: apolloClient,
      fetchPolicy: "network-only",
    });

  const { endCursor, hasNextPage } = data
    ? data.admin_listReservedParkingPasses.pageInfo
    : {};

  const onFilterChange = () => {
    const { status, search, dateRange } = getFilterSelectionFromState();
    const currentFilter = {
      status,
      search,
      startDate: dateRange.start,
      endDate: dateRange.end,
    };
    setCurrentFilter(currentFilter);

    getReservedParkingPasses({
      variables: getFormattedVariables({ selectedProperty, currentFilter }),
    });
  };

  const loadMore = () => {
    if (!hasNextPage || isLoadingMore) {
      return;
    }

    setIsLoadingMore(true);
    fetchMore({
      variables: {
        after: endCursor,
      },
      updateQuery: (prevData, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevData;

        fetchMoreResult.admin_listReservedParkingPasses.edges = [
          ...prevData.admin_listReservedParkingPasses.edges,
          ...fetchMoreResult.admin_listReservedParkingPasses.edges,
        ];

        return fetchMoreResult;
      },
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: () => loadMore(),
    disabled: !hasNextPage,
    rootMargin: "0px 0px 400px 0px",
  });

  useEffect(() => {
    if (isLoadingMore) {
      setIsLoadingMore(false);
    }

    if (data) {
      setReservedParkingPasses(data?.admin_listReservedParkingPasses?.edges);
    }
  }, [data]);

  useEffect(() => {
    setEmptyListMessage(generateEmptyListMessage(selectedProperty));
    onFilterChange();
  }, [selectedProperty]);

  useEffect(() => {
    if (
      !isEqual(prevParams, $state.params) &&
      !isEmpty($state.params?.dateRange)
    ) {
      onFilterChange();
    } else {
      selectDefaultFilters($state);
    }
  }, [$state.params]);

  return (
    <div {...rest}>
      <Filters currentFilter={currentFilter} />
      {(loading || error) && (
        <LoadingManager isLoading={loading} hasError={error} />
      )}

      {reservedParkingPasses?.length === 0 && !loading && !error && (
        <Text variant="subtitle" gutterBottom>
          {emptyListMessage}
        </Text>
      )}

      {reservedParkingPasses?.length > 0 &&
        tableContent &&
        !loading &&
        !error && (
          <Table
            data={tableContent?.data}
            columns={tableContent?.columns}
            responsive
            striped
            showIconRight
            verticalAlignIconRight="middle"
          />
        )}

      {hasNextPage && (
        <div className={styles.sentryRef} ref={sentryRef}>
          {isLoadingMore && !!reservedParkingPasses?.length && (
            <LoadingManager isLoading={isLoadingMore} />
          )}
        </div>
      )}
    </div>
  );
};

export default ReservedParking;
