import React from "react";
import { Checkbox } from "@citifyd/style";
import moment from "moment-timezone";
import { getTranslator } from "../../../services/languages";

import styles from "./EventsSelector.module.scss";

export const getData = ({
  bundleGroups,
  events,
  handleSelectEvent,
  handleSelectBundle,
  selectedEvents,
  selectedBundles,
}) => {
  const t = getTranslator();

  const handleCheckbox = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const columns = [
    {
      key: "checkbox",
      value: "",
      width: "1%",
    },
    {
      key: "eventDate",
      value: t("userPurchaseTicket.eventDate"),
      width: "20%",
    },
    {
      key: "event",
      value: t("userPurchaseTicket.event"),
      width: "60%",
    },
    {
      key: "time",
      value: t("userPurchaseTicket.time"),
      width: "10%",
    },
  ];

  const dataBundleGroups = bundleGroups.map((bundleGroup) => {
    const isSelected = selectedBundles.find(
      (item) => item.id === bundleGroup.id
    );

    return {
      ...(!bundleGroup.soldOut && {
        onClick: () => handleSelectBundle(bundleGroup),
      }),
      hover: false,
      checkbox: {
        value: (
          <Checkbox
            checked={!!isSelected}
            onClick={(e) => e.stopPropagation()}
            disabled={bundleGroup.soldOut}
            onClick={handleCheckbox}
          />
        ),
        noVerticalPadding: true,
        verticalAlign: "middle",
      },
      eventDate: <>&mdash;</>,
      event: bundleGroup.name,
      time: <>&mdash;</>,
    };
  });

  const dataEvents = events.map((event) => {
    const isSelected = selectedEvents.find((item) => item.id === event.id);

    return {
      ...(!event.soldOut && { onClick: () => handleSelectEvent(event) }),
      hover: false,
      checkbox: {
        value: (
          <Checkbox
            checked={!!isSelected}
            onClick={handleCheckbox}
            disabled={event.soldOut}
          />
        ),
        noVerticalPadding: true,
        verticalAlign: "middle",
      },
      eventDate: {
        value: t("defaultFormats.date", {
          date: moment.tz(event.start, event.timezoneName),
        }),
        nowrap: "nowrap",
      },
      event: {
        value: (
          <>
            {event.name}
            {event.soldOut && <i>{t("userPurchaseTicket.soldOut")}</i>}
          </>
        ),
      },
      time: {
        value: t("defaultFormats.time", {
          date: moment.tz(event.start, event.timezoneName),
        }),
        nowrap: "nowrap",
      },
    };
  });

  const data = [
    ...dataBundleGroups,
    ...(bundleGroups.length && events.length
      ? [
          {
            appearance: "transparent",
            checkbox: {
              value: <hr className={styles.divisor} />,
              colSpan: 4,
            },
            eventDate: {
              hide: true,
            },
            event: {
              hide: true,
            },
            time: {
              hide: true,
            },
          },
        ]
      : []),
    ...dataEvents,
  ];

  return {
    columns,
    data,
  };
};
