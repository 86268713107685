"use strict";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, LoadingIndicator, Modal } from "@citifyd/style";

import FormikProvider from "../../react/components/FormikProvider";
import FormikField, { FIELD_TYPE } from "../../react/components/FormikField";

import { useTranslator } from "../../react/hooks.js";
import { validate } from "../../services/password-validator";

import PasswordRulesIndicator from "../../react/components/PasswordRulesIndicator/PasswordRulesIndicator.jsx";
import { updateUser, getUserPasswordRules } from "../../api";
import { showErrorAlert } from "../../services/helper";
import { useAuthentication } from "../../react/contexts/authentication";

const RECENT_PASSWORD_ADVICE_ERROR_CODE = 2519;

const ChangePassword = ({ open, onClose }) => {
  const { user } = useAuthentication();
  const t = useTranslator();
  const [listOfRules, setListOfRules] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmiting, setIsSubmiting] = useState(false);

  useEffect(() => {
    getRules();
  }, []);

  const getRules = async () => {
    const response = await getUserPasswordRules({
      params: {
        potentialOrganizationUser: true,
      },
    });

    setListOfRules(response);
    setIsLoading(false);
  };

  const formikbag = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },

    validationSchema: yup.object().shape({
      currentPassword: yup.string().required("Password is required"),
      newPassword: yup.string().required("Password is required"),
      confirmNewPassword: yup
        .string()
        .test(
          "passwords-match",
          t("modalChangePassword.passwordsMustMatch"),
          function (value) {
            return this.parent.newPassword === value;
          }
        )
        .required(),
    }),
    onSubmit: async (values) => {
      const sendToBackend = async (canUseRecentPassword) => {
        const passwordValidation = validate(values.newPassword, listOfRules);
        if (!passwordValidation.isValid) {
          return;
        }

        try {
          setIsSubmiting(true);
          await updateUser(user.id, {
            currentPassword: values.currentPassword,
            password: values.newPassword,
            checkRecentPasswords: true,
            canUseRecentPassword: Boolean(canUseRecentPassword),
          });

          onClose(false);
        } catch (response) {
          if (response?.data.error.code === RECENT_PASSWORD_ADVICE_ERROR_CODE) {
            if (
              window.confirm(
                t("modalChangePassword.usageOfPreviousPasswordConfirmation")
              )
            ) {
              sendToBackend(true);
            }
          } else {
            showErrorAlert(response.data);
          }
        } finally {
          setIsSubmiting(false);
        }
      };

      sendToBackend(false);
    },
  });

  const { handleSubmit } = formikbag;

  return (
    <Modal open={open} onClose={onClose}>
      <FormikProvider value={formikbag}>
        <Modal.Title>{t("modalChangePassword.title")}</Modal.Title>
        <Modal.Content>
          <FormikField
            label={t("modalChangePassword.current")}
            name="currentPassword"
            as={FIELD_TYPE.INPUT}
            type="password"
            autoFocus
            gutterBottom
          />
          <FormikField
            label={t("modalChangePassword.new")}
            name="newPassword"
            as={FIELD_TYPE.INPUT}
            type="password"
            gutterBottom
          />
          <PasswordRulesIndicator
            password={formikbag.values.newPassword}
            rules={listOfRules}
          />
          <FormikField
            label={t("modalChangePassword.confirm")}
            name="confirmNewPassword"
            as={FIELD_TYPE.INPUT}
            type="password"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="button"
            textColor="white"
            appearance="secondary"
            fullWidth
            justifyContent="center"
            uppercase
            onMouseDown={onClose}
          >
            {t("commonButtons.cancel")}
          </Button>
          <Button
            type="button"
            fullWidth
            justifyContent="center"
            uppercase
            onMouseDown={handleSubmit}
          >
            {t("commonButtons.save")}
          </Button>
        </Modal.Actions>
      </FormikProvider>
      {(isLoading || !listOfRules || isSubmiting) && (
        <LoadingIndicator isLoading={isLoading} withOverlay />
      )}
    </Modal>
  );
};

export default ChangePassword;
