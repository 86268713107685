"use strict";

import React, { useEffect, useRef, useState } from "react";
import { Button, LoadingIndicator, Modal, Text } from "@citifyd/style";
import { useFormik } from "formik";
import * as yup from "yup";

import FormikProvider from "../../../../shared/react/components/FormikProvider";
import PhoneField from "../PhoneField";
import CodeVerification from "./Components/CodeVerification.jsx";

import { useTranslator } from "../../../react/hooks";
import { getCountries } from "../../../api";

import checkNumberAndSendVerification from "./functions/checkNumberAndSendVerification";
import verifyPhoneNumber from "./functions/verifyPhoneNumber";
import getNormalizedValues from "./functions/getNormalizedValues";

import styles from "./PhoneVerification.module.scss";
import useLoadTracking from "../../hooks/useLoadTracking";
import onNextRender from "./functions/onNextRender";
import { showErrorAlert } from "../../../services/helper";

const PhoneVerification = ({ title, user, onVerify, onCancel, onLogout }) => {
  const t = useTranslator();
  const { isLoading, trackLoading, untrackLoading } = useLoadTracking();

  const [countries, setCountries] = useState([]);
  const [errorMessage, setErrorMessase] = useState("");
  const [readyForCode, setReadyForCode] = useState(false);

  const codeFieldRef = useRef();
  const phoneNumberFieldRef = useRef();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (user) {
      formikbag.setFieldValue("phoneCountryCode", user.phoneCountryCode);
      formikbag.setFieldValue("phoneNumber", user.phoneNumber);
    }
  }, [user]);

  const formikbag = useFormik({
    initialValues: {
      phoneCountryCode: user?.phoneCountryCode || "us",
      phoneNumber: user?.phoneNumber,
      code: "",
    },
    validationSchema: yup.object().shape({
      phoneNumber: yup.string().required().nullable(),
    }),
    onSubmit: async (values) => {
      values = getNormalizedValues(values);

      trackLoading("submit");

      let result;

      if (readyForCode) {
        result = await verifyPhoneNumber({ values, user });
      } else {
        result = await checkNumberAndSendVerification(values);
      }

      switch (result.status) {
        case "sent_code":
          setErrorMessase(null);
          onNextRender(() => codeFieldRef.current?.focus());
          setReadyForCode(true);
          break;
        case "verified":
          setErrorMessase(null);
          onVerify && (await onVerify(result.user));
          break;
        case "error":
          handleError(result.reason);
      }

      untrackLoading("submit");
    },
  });

  const handleError = (responseError) => {
    if (responseError.data.error) {
      setErrorMessase(responseError.data.error.message);
    } else {
      showErrorAlert(responseError.data);
    }
  };

  const loadCountries = async () => {
    trackLoading("countries");
    const countries = await getCountries();
    setCountries(countries);
    untrackLoading("countries");
  };

  const init = () => {
    loadCountries();
    onNextRender(() => phoneNumberFieldRef.current?.focus());
  };

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Modal.Title
        dangerouslySetInnerHTML={{
          __html:
            title ??
            t("phoneVerification.defaultTitle", { nonBreakingSpace: "&nbsp;" }),
        }}
      />
      <Modal.Content>
        <PhoneField
          countries={countries}
          countryCodeDefault={user?.phoneCountryCode || "us"}
          disabled={readyForCode}
          countryCodeFieldName="phoneCountryCode"
          phoneNumberFieldName="phoneNumber"
          ref={phoneNumberFieldRef}
        />
        {!readyForCode && !errorMessage && (
          <Text
            className={styles.sendCode}
            appearance="secondary"
            italic
            textAlign="center"
            weight="400"
          >
            {t("phoneVerification.smsDisclaimer")}
          </Text>
        )}
        {errorMessage && (
          <Text
            className={styles.errorMessage}
            appearance="error"
            textAlign="center"
            variant="body"
            weight="400"
          >
            {errorMessage}
          </Text>
        )}

        {readyForCode && (
          <CodeVerification
            codeFieldRef={codeFieldRef}
            phoneNumberFieldRef={phoneNumberFieldRef}
            trackLoading={trackLoading}
            untrackLoading={untrackLoading}
            handleError={handleError}
            values={formikbag.values}
            setReadyForCode={setReadyForCode}
            setErrorMessase={setErrorMessase}
          />
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button
          type="button"
          textColor="white"
          appearance="secondary"
          fullWidth
          justifyContent="center"
          uppercase
          onMouseDown={onLogout ?? onCancel}
        >
          {onLogout ? t("header.userNav.logout") : t("commonButtons.cancel")}
        </Button>
        <Button
          type="button"
          fullWidth
          justifyContent="center"
          uppercase
          onMouseDown={handleSubmit}
        >
          {t("commonButtons.continue")}
        </Button>
      </Modal.Actions>
      {isLoading && <LoadingIndicator withOverlay />}
    </FormikProvider>
  );
};

export default PhoneVerification;
