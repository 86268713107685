import gql from "graphql-tag";

export default gql`
  mutation AdminCreateVenue(
    $name: String!
    $administratorName: String
    $phone: String
    $email: String
    $venueAddress: JSON
    $latitude: Float!
    $longitude: Float!
    $OrganizationId: Int!
    $countryCode: String!
  ) {
    admin_createVenue(
      name: $name
      administratorName: $administratorName
      phone: $phone
      email: $email
      venueAddress: $venueAddress
      latitude: $latitude
      longitude: $longitude
      OrganizationId: $OrganizationId
      countryCode: $countryCode
    ) {
      id
    }
  }
`;
