import produce from "immer";
import {
  createPlan,
  deletePlan,
  getAllPlans,
  updatePlan,
} from "../../../../shared/api";
import {
  transformToDecimal,
  transformToInteger,
} from "../../../../shared/services/currencies";
import {
  getServerErrorMessage,
  showAlert,
} from "../../../../shared/services/helper";
import { getTranslator } from "../../../../shared/services/languages";

export const loadMonthlyPlans = async (lot) => {
  const plans = await getAllPlans({ params: { lotId: lot.id } });

  return plans.map((plan) => transformToFrontend({ monthlyPlan: plan, lot }));
};

export const saveMonthlyPlans = async ({
  lot,
  monthlyPlans,
  setMonthlyPlans,
  trackLoading,
  untrackLoading,
}) => {
  trackLoading("savePlan");
  const errors = [];

  for (const monthlyPlan of monthlyPlans) {
    try {
      if (monthlyPlan.removed) {
        await deletePlanWithState({ monthlyPlan, setMonthlyPlans });
      } else if (monthlyPlan.id && monthlyPlan.saved) {
        await updatePlanWithState({ monthlyPlan, setMonthlyPlans, lot });
      } else if (!monthlyPlan.id) {
        await createPlanWithState({ monthlyPlan, setMonthlyPlans, lot });
      }
    } catch (err) {
      errors.push({ error: err.data, monthlyPlan });
    }
  }

  if (errors.length) {
    const result = errors
      .map((item) => {
        return `${item.monthlyPlan.name}: ${getServerErrorMessage(item.error)}`;
      })
      .join("\n\n");

    const t = getTranslator();

    showAlert(
      t("addProperty.monthly.errorMessages.errorsFound", {
        errors: result,
        count: errors.length,
      })
    );
  }

  untrackLoading("savePlan");

  return errors.length === 0;
};

const deletePlanWithState = async ({ monthlyPlan, setMonthlyPlans }) => {
  await deletePlan(monthlyPlan.id);

  setMonthlyPlans(
    produce((monthlyPlans) => {
      const index = monthlyPlans.findIndex(
        (item) => item.id === monthlyPlan.id
      );
      monthlyPlans.splice(index, 1);
    })
  );
};

const updatePlanWithState = async ({ monthlyPlan, lot, setMonthlyPlans }) => {
  const result = await updatePlan(transformToBackend({ monthlyPlan, lot }));
  setMonthlyPlans(
    produce((monthlyPlans) => {
      const index = monthlyPlans.findIndex(
        (item) => item.id === monthlyPlan.id
      );
      monthlyPlans[index] = transformToFrontend({
        monthlyPlan: result.data.plan,
        lot,
      });
    })
  );
};

const createPlanWithState = async ({ monthlyPlan, lot, setMonthlyPlans }) => {
  const result = await createPlan(transformToBackend({ monthlyPlan, lot }));

  setMonthlyPlans(
    produce((monthlyPlans) => {
      const index = monthlyPlans.findIndex(
        (item) => item.clientId === monthlyPlan.clientId
      );
      monthlyPlans[index] = transformToFrontend({
        monthlyPlan: result.data.plan,
        lot,
      });
    })
  );
};

export const transformDaysOfWeekToFrontend = (daysOfWeek) => {
  let days = {};
  if (daysOfWeek.includes(1)) days.mon = true;
  if (daysOfWeek.includes(2)) days.tue = true;
  if (daysOfWeek.includes(3)) days.wed = true;
  if (daysOfWeek.includes(4)) days.thu = true;
  if (daysOfWeek.includes(5)) days.fri = true;
  if (daysOfWeek.includes(6)) days.sat = true;
  if (daysOfWeek.includes(7)) days.sun = true;

  return days;
};

const transformDaysOfWeekToBackend = (daysOfWeek) => {
  const days = [];
  if (daysOfWeek.mon) days.push(1);
  if (daysOfWeek.tue) days.push(2);
  if (daysOfWeek.wed) days.push(3);
  if (daysOfWeek.thu) days.push(4);
  if (daysOfWeek.fri) days.push(5);
  if (daysOfWeek.sat) days.push(6);
  if (daysOfWeek.sun) days.push(7);
  return days;
};

const transformToBackend = ({ monthlyPlan, lot }) => {
  return {
    lotId: lot.id,
    id: monthlyPlan.id,
    period: "month",
    name: monthlyPlan.name,
    price: transformToInteger(monthlyPlan.price, lot.country?.currency),
    billingCycle: parseInt(monthlyPlan.billingCycle),
    spaces: monthlyPlan.spaces,
    daysOfTheWeek: transformDaysOfWeekToBackend(monthlyPlan.daysOfWeek),
    startTime: `${monthlyPlan.startTime}:00`,
    endTime:
      monthlyPlan.endTime === "00:00"
        ? "24:00:00"
        : `${monthlyPlan.endTime}:00`,
    cancellationNoticePeriod: parseInt(monthlyPlan.cancellationNoticePeriod),
  };
};

const transformToFrontend = ({ monthlyPlan, lot }) => {
  return {
    id: monthlyPlan.id,
    lotId: lot.id,
    name: monthlyPlan.name,
    price: transformToDecimal(monthlyPlan.price, lot.country?.currency),
    billingCycle: String(monthlyPlan.billingCycle),
    spaces: monthlyPlan.spaces,
    daysOfWeek: transformDaysOfWeekToFrontend(monthlyPlan.daysOfTheWeek),
    startTime: monthlyPlan.startTime.substr(0, 5),
    endTime:
      monthlyPlan.endTime.substr(0, 5) === "24:00"
        ? "00:00"
        : monthlyPlan.endTime.substr(0, 5),
    cancellationNoticePeriod: String(monthlyPlan.cancellationNoticePeriod),
    showForm: false,
    saved: false,
    removed: false,
  };
};

export const showEditingMonthlyAlert = () => {
  const t = getTranslator();

  window.alert(t("addProperty.monthly.errorMessages.isEditingMonthlyPricing"));
  return false;
};

export const isEditingMonthly = (monthlyPlans) => {
  const filteredPlans = monthlyPlans.filter(
    (monthlyPlan) => monthlyPlan.showForm
  );
  return filteredPlans.length > 0;
};
