"use strict";

import React, { useEffect, useState } from "react";
import { AlertMessage, Text } from "@citifyd/style";

import { find, omit } from "lodash";
import produce from "immer";

import LotsSelector from "../../../../shared/react/components/LotsSelector";
import Footer from "../Footer/Footer.jsx";

import { useTranslator } from "../../../../shared/react/hooks";
import {
  groupEventsByRate,
  addSelectedBundleId,
  getEventsWithSelectedLotIds,
  hasMultipleCurrencies,
} from "../../../../shared/react/components/LotsSelector/utils.js";
import confirmLotSelections from "./utils";
import styles from "./LotSelection.module.scss";

const LotSelection = ({ steps, setSteps, passGiver }) => {
  const t = useTranslator();

  const [selectedEvents, setSelectedEvents] = useState(
    steps?.data?.eventSelected?.eventsSelected
  );

  const [eventsByRate, setEventsByRate] = useState([]);

  const [bundles, setBundles] = useState(
    addSelectedBundleId(steps?.data?.eventSelected?.bundlesSelected)
  );

  const handleSelectEventLot = ({ group, lot }) => {
    const currentLot = find(group?.events[0].lots, { id: lot?.id });
    if (currentLot.soldOut) return;

    const eventsByRateUpdated = eventsByRate.map((item) => {
      if (group === item) {
        return { ...item, selectedLotId: lot?.id };
      }

      return item;
    });

    const eventsWithSelectedLotIds =
      getEventsWithSelectedLotIds(eventsByRateUpdated);

    setSelectedEvents(_.union(...eventsWithSelectedLotIds));
    setEventsByRate(eventsByRateUpdated);
  };

  useEffect(() => {
    // has the user already been through this step and came back?
    if (steps.data.lotSelection) {
      const { bundles, events } = steps.data.lotSelection;
      setBundles(bundles);
      setSelectedEvents(events);
      setEventsByRate(
        events.map((event) => ({
          events: [omit(event, ["selectedLot"])],
          lots: event.lots,
          selectedLotId: event.selectedLot.id,
        }))
      );
    } else {
      setEventsByRate(
        groupEventsByRate(steps?.data?.eventSelected?.eventsSelected)
      );
    }
  }, []);

  const handleSelectBundleLot = ({ group, bundle }) => {
    const bundlesUpdated = bundles.map((item) => {
      if (group === item) {
        return { ...item, selectedBundleId: bundle?.id };
      }

      return item;
    });

    setBundles(bundlesUpdated);
  };

  const separateGroup = (group) => {
    const index = eventsByRate.indexOf(group);

    const newGroups = group.events.map((event) => ({
      events: [event],
      lots: group.lots,
      selectedLotId: group.selectedLotId,
    }));

    setEventsByRate(
      produce((eventsByRate) => {
        eventsByRate.splice(index, 1, ...newGroups);
      })
    );
  };

  const handleSubmit = () => {
    const data = {
      bundles: bundles,
      events: selectedEvents,
    };

    if (!confirmLotSelections(data)) {
      return false;
    }

    if (!hasMultipleCurrencies(data)) {
      setSteps({
        step: "confirmation",
        data: {
          ...steps.data,
          lotSelection: data,
        },
      });
    }
  };

  return (
    <>
      <LotsSelector
        bundles={bundles}
        handleSelectBundleLot={handleSelectBundleLot}
        eventsByRate={eventsByRate}
        handleSelectEventLot={handleSelectEventLot}
        separateGroup={separateGroup}
      />

      <AlertMessage appearance="tertiary" className={styles.alertMessage}>
        <Text appearance="white" weight="600">
          {t("inviteParkers.lotSelection.complimentaryNote", {
            giver: passGiver,
          })}
        </Text>
      </AlertMessage>
      <Text italic variant="small">
        {t("inviteParkers.lotSelection.feeDisclaimer")}
      </Text>
      <Footer handleSubmit={handleSubmit} />
    </>
  );
};

export default LotSelection;
