"use strict";

import React, { useMemo } from "react";

import RouterLink from "../../../../shared/react/components/RouterLink";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import Gates from "./Gates";
import PosRates from "./PosRates";
import Permissions from "../../../../shared/services/permissions";
import { useFormikContext } from "formik";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";
import { Text, Grid, Icon } from "@citifyd/style";
import { useTranslator } from "../../../../shared/react/hooks";
import { generateTimesArray } from "../../../../shared/services/helper";
import BoxContent from "../../../../shared/react/components/BoxContent";
import styles from "./PropertyCard.module.scss";
import AdjustRateByNumberOfTransactions from "./DynamicPricing/AdjustRateByNumberOfTransactions";
import AdjustRateByTimeBeforeTheEvent from "./DynamicPricing/AdjustRateByTimeBeforeTheEvent";
import Table from "../../../../shared/react/components/Table";
import AdvanceSales from "./DynamicPricing/AdvanceSales";

const PropertyCardContent = ({ formikIndex, lot, formikbag }) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const { permissions } = useAuthentication();
  const t = useTranslator();

  const canConfigureGates = Permissions.userMayConfigureGates(permissions);
  const canEditPosRates =
    Permissions.userMayManageLotAvailabilitiesPosRates(permissions);
  const posRates = values?.posRates;

  const timeOptions = useMemo(() => {
    const times = generateTimesArray();

    return times.map((time) => ({
      value: time.time,
      label: time.label,
    }));
  }, []);

  return (
    <BoxContent.Content>
      <Grid.Row className={styles.row} gutterWidth={10}>
        <Grid.Col xs={4}>
          <RowWithIcon icon="dollar-sign">
            {!lot.availability.disablePublicSale ? (
              <FormikField
                as={FIELD_TYPE.CURRENCY}
                currency={lot.country?.currency}
                disabled={lot.availability.rateEditingDisabled}
                fullWidth={false}
                name={`selectedLots[${formikIndex}].availability.rate.value`}
                inlineLabel={t("eventProperties.baseRate")}
              />
            ) : (
              <FormikField
                as={FIELD_TYPE.INPUT}
                disabled={true}
                fullWidth={false}
                name="mask"
                inlineLabel={t("eventProperties.baseRate")}
              />
            )}
          </RowWithIcon>
        </Grid.Col>
        <Grid.Col className={styles.checkbox}>
          <FormikField
            as={FIELD_TYPE.CHECKBOX}
            fullWidth={false}
            name={`selectedLots[${formikIndex}].availability.disablePublicSale`}
            label={t("eventProperties.notAvailable")}
            handleChange={(e) =>
              setFieldValue(
                `selectedLots[${formikIndex}].availability.disablePublicSale`,
                e.target.value !== "true"
              )
            }
          />
        </Grid.Col>
      </Grid.Row>
      <hr />
      <Grid.Row align="center" className={styles.row}>
        <Grid.Col xs={9}>
          <RowWithIcon icon={["far", "clock"]}>
            <Grid.Row align="center" gutterWidth={3}>
              <Grid.Col xs={7} sm={3}>
                <FormikField
                  fullWidth={false}
                  showErrorMessage={false}
                  as={FIELD_TYPE.DATEPICKER}
                  name={`selectedLots[${formikIndex}].availability.startDate`}
                />
              </Grid.Col>
              <Grid.Col xs={5} sm={3}>
                <FormikField
                  as={FIELD_TYPE.SELECT}
                  options={timeOptions}
                  showErrorMessage={false}
                  name={`selectedLots[${formikIndex}].availability.startTime`}
                  hasError={Boolean(
                    errors.selectedLots?.[formikIndex]?.availability?.startDate
                  )}
                />
              </Grid.Col>
              <Grid.Col sm={12} className={styles.mobileSeparator}>
                <span>&mdash;</span>
              </Grid.Col>
              <Grid.Col xs={7} sm={3}>
                <div className={styles.fieldBox}>
                  <span className={styles.desktopSeparator}>&mdash;</span>
                  <FormikField
                    fullWidth={false}
                    as={FIELD_TYPE.DATEPICKER}
                    showErrorMessage={false}
                    name={`selectedLots[${formikIndex}].availability.endDate`}
                  />
                </div>
              </Grid.Col>
              <Grid.Col xs={5} sm={3}>
                <FormikField
                  as={FIELD_TYPE.SELECT}
                  options={timeOptions}
                  showErrorMessage={false}
                  name={`selectedLots[${formikIndex}].availability.endTime`}
                  hasError={Boolean(
                    errors.selectedLots?.[formikIndex]?.availability?.endDate
                  )}
                />
              </Grid.Col>
            </Grid.Row>
          </RowWithIcon>
        </Grid.Col>
      </Grid.Row>
      <hr />
      <Grid.Row align="center" className={styles.row} gutterWidth={10}>
        <Grid.Col xs={4}>
          <RowWithIcon icon="car">
            <FormikField
              as={FIELD_TYPE.INPUT}
              type="number"
              fullWidth={false}
              showErrorMessage={false}
              name={`selectedLots[${formikIndex}].availability.spaces.regular`}
              inlineLabel={`/ ${lot.maxSpots}`}
            />
          </RowWithIcon>
        </Grid.Col>
        <Grid.Col>
          <Text variant="small">{t("eventProperties.spaces")}</Text>
        </Grid.Col>
      </Grid.Row>
      <hr />
      <AdditionalInformation formikIndex={formikIndex} lot={lot} />
      {lot.gates.length === 0 && canConfigureGates && (
        <>
          <hr />
          <Grid.Row align="center" className={styles.row}>
            <Grid.Col>
              <RowWithIcon icon="question" iconSize="h3">
                <Text variant="subtitle">{t("eventProperties.noGate")}</Text>
                &nbsp;
                <RouterLink
                  state="edit-property-step"
                  variant="subtitle"
                  underline
                  params={{ lotId: lot.id, step: 5 }}
                >
                  {t("eventProperties.setupGates")} &raquo;
                </RouterLink>
              </RowWithIcon>
            </Grid.Col>
          </Grid.Row>
        </>
      )}
      {lot.gates.length > 0 && canConfigureGates && <Gates gates={lot.gates} />}
      {canEditPosRates && posRates && <PosRates rates={posRates} lot={lot} />}
      <br />
      <DynamicPricing
        lot={lot}
        formikbag={formikbag}
        formikIndex={formikIndex}
      />
    </BoxContent.Content>
  );
};

const DynamicPricing = ({ lot, formikbag, formikIndex }) => {
  const t = useTranslator();

  return (
    <>
      <Table
        condensed
        className={styles.table}
        borderBottomColor="gray"
        headerAppearance="gray"
        hasNoResultsText={false}
        columns={[
          {
            value: t("eventProperties.dynamicPricing.dynamicPricing"),
            key: "rate",
            uppercase: false,
          },
        ]}
        data={[]}
      />
      <AdjustRateByNumberOfTransactions
        lot={lot}
        formikbag={formikbag}
        formikIndex={formikIndex}
      />
      <AdjustRateByTimeBeforeTheEvent
        lot={lot}
        formikbag={formikbag}
        formikIndex={formikIndex}
      />
      <AdvanceSales lot={lot} formikbag={formikbag} formikIndex={formikIndex} />
    </>
  );
};

const AdditionalInformation = ({ formikIndex, lot }) => {
  const t = useTranslator();
  const { permissions } = useAuthentication();

  const shouldShowBarcodeActivationCheckbox =
    Permissions.userMayUpdateLotAvailabilityBarcodeActivation(permissions);
  const shouldShowDisableServiceFeeCheckbox =
    Permissions.userMayDisableLotAvailabilityServiceFee(permissions);

  return (
    <>
      <Grid.Row>
        <Grid.Col>
          <Text weight={700} gutterBottom>
            {t("eventProperties.additionalInformation")}
          </Text>
        </Grid.Col>
      </Grid.Row>
      {!shouldShowBarcodeActivationCheckbox && (
        <Grid.Row>
          <Grid.Col>
            <FormikField
              as={FIELD_TYPE.CHECKBOX}
              name={`selectedLots[${formikIndex}].availability.barcodeActivation`}
              label={t("eventProperties.allowBarcodeVerification")}
              gutterBottom
            />
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col>
          <FormikField
            as={FIELD_TYPE.CHECKBOX}
            name={`selectedLots[${formikIndex}].availability.gpsVerification`}
            label={t("eventProperties.allowGpsVerification")}
            gutterBottom
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <FormikField
            as={FIELD_TYPE.CHECKBOX}
            name={`selectedLots[${formikIndex}].availability.vehicleRequired`}
            label={t("eventProperties.vehicleRequired")}
            gutterBottom
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <FormikField
            as={FIELD_TYPE.CHECKBOX}
            name={`selectedLots[${formikIndex}].availability.allowReentry`}
            label={t("eventProperties.allowRentry")}
            gutterBottom
          />
        </Grid.Col>
      </Grid.Row>
      {shouldShowDisableServiceFeeCheckbox && (
        <Grid.Row>
          <Grid.Col>
            <FormikField
              as={FIELD_TYPE.CHECKBOX}
              name={`selectedLots[${formikIndex}].availability.serviceFeeDisabled`}
              disabled={lot.availability.rateEditingDisabled}
              label={t("eventProperties.disableFee")}
              gutterBottom
            />
          </Grid.Col>
        </Grid.Row>
      )}
    </>
  );
};

const RowWithIcon = ({ icon, iconSize = "h2", children }) => (
  <div className={styles.fieldWithIcon}>
    <Icon
      className={styles.icon}
      size={iconSize}
      icon={icon}
      appearance="gray_darker"
    />
    {children}
  </div>
);

export default PropertyCardContent;
