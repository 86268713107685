"use strict";

import React, { RefObject, useState } from "react";
import { find } from "lodash";

import {
  Table,
  TableProps,
  Icon,
  Colors,
  TextAlign,
  VerticalAlign,
} from "@citifyd/style";

import TableRow from "./TableRow";
import { useTranslator } from "../../hooks";

export interface DataTableProps extends TableProps {
  cleanMode?: boolean;
  columns: ColumnProps[];
  condensed?: boolean;
  data?: { [key: string]: any };
  expanded?: boolean;
  fixed?: boolean;
  noResultsText?: string;
  hasNoResultsText?: boolean;
  headerAppearance?: Colors;
  headerGap?: boolean;
  noPadding?: boolean;
  responsive?: boolean;
  spacing?: boolean;
  shadow?: boolean;
  showHeader?: boolean;
  showIconRight?: boolean;
  striped?: boolean;
  textAlign?: TextAlign;
  textColor?: Colors;
  verticalAlignIconRight?: VerticalAlign;
}

type SortableKey = string | number;

export interface ColumnProps {
  key: any;
  value: string;
  border?: boolean;
  clickable?: boolean;
  colSpan?: number;
  nowrap?: boolean;
  onClick?: (e: React.MouseEvent, sortableKey: SortableKey) => void;
  orders?: SortableKey[];
  rowSpan?: number | string;
  uppercase?: boolean;
  sortable?: boolean;
  sortableKey?: SortableKey;
  textAlign?: TextAlign;
  textColor?: Colors;
  verticalAlign?: VerticalAlign;
  width?: string;
}

const DataTable = React.forwardRef<HTMLElement, DataTableProps>(
  (
    {
      cleanMode,
      columns = [],
      condensed,
      data = [],
      expanded,
      fixed,
      hasNoResultsText = true,
      headerAppearance,
      headerGap,
      noPadding,
      noResultsText,
      responsive,
      spacing,
      shadow,
      showHeader = true,
      showIconRight,
      striped,
      textColor,
      verticalAlignIconRight,
      ...rest
    },
    ref
  ) => {
    const t = useTranslator();

    const renderColumns = () => {
      const [sortingBy, setSortingBy] = useState(columns[0]?.sortableKey || 0);

      return columns.map((column, index) => {
        const sortableKey = column?.sortableKey || index;
        const propertyOrder = find(
          column.orders,
          (order) => order[0] === sortableKey
        );

        return (
          <Table.HeaderCell
            uppercase={column.uppercase}
            sortable={column.sortable}
            colSpan={column.colSpan}
            clickable={column.clickable}
            verticalAlign={column.verticalAlign}
            border={column.border}
            nowrap={column.nowrap}
            onClick={(e) => {
              if (typeof column?.onClick === "function") {
                column?.onClick(e, sortableKey);
              }

              if (column.sortable) {
                if (sortableKey !== sortingBy) {
                  setSortingBy(sortableKey);
                }
              }
            }}
            textAlign={column?.textAlign}
            textColor={column?.textColor}
            width={column.width}
            key={index}
          >
            {propertyOrder && (
              <Icon
                appearance={column?.textColor}
                icon={propertyOrder[1] === "asc" ? "caret-up" : "caret-down"}
              ></Icon>
            )}
            {column?.value || (typeof column === "string" && column)}
          </Table.HeaderCell>
        );
      });
    };

    const renderRows = () => {
      return data.map((item, index) => (
        <TableRow
          key={index}
          item={item}
          spacing={spacing}
          noPadding={noPadding}
          showIconRight={showIconRight}
          verticalAlignIconRight={verticalAlignIconRight}
          columns={columns}
        />
      ));
    };

    return (
      <div>
        <Table
          striped={striped}
          fixed={fixed}
          ref={ref as RefObject<HTMLTableElement>}
          condensed={condensed}
          cleanMode={cleanMode}
          responsive={responsive}
          {...rest}
        >
          {showHeader && (
            <Table.Head gap={headerGap}>
              <Table.Row appearance={headerAppearance}>
                {renderColumns()}
                {showIconRight && <Table.HeaderCell></Table.HeaderCell>}
              </Table.Row>
            </Table.Head>
          )}

          <Table.Body>
            {data.length > 0 ? (
              renderRows()
            ) : (
              <Table.Row>
                <Table.Cell colSpan={12} textAlign="center">
                  {hasNoResultsText
                    ? noResultsText
                      ? noResultsText
                      : t("commonTexts.noResults")
                    : ""}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
);

export default DataTable;
