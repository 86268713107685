"use strict";

import React from "react";
import { Link, Text } from "@citifyd/style";

import Table from "../Table";
import { useTranslator } from "../../hooks";

import { getEventsData, getBundlesData } from "./utils";
import styles from "./LotsSelector.module.scss";

const LotsSelector = ({
  bundles,
  eventsByRate,
  handleSelectBundleLot,
  handleSelectEventLot,
  separateGroup,
}) => {
  const t = useTranslator();

  const renderLots = ({ index, group, isBundles }) => {
    const { columns, data } = isBundles
      ? getBundlesData({ group, handleSelectBundleLot })
      : getEventsData({ group, handleSelectEventLot });

    let names;
    if (isBundles) {
      const [firstBundle] = group.bundles;
      names = [firstBundle.noGroup ? firstBundle.name : firstBundle.groupName];
    } else {
      names = group.events.map((event) => event.name);
    }

    return (
      <div className={styles.wrapper} key={index}>
        <div className={styles.groupTitleContainer}>
          <Text className={styles.groupTitle} gutterBottom variant="h5">
            {names.map((name, index) => (
              <span key={index}>
                {name}
                {index + 1 < names.length && <br />}
              </span>
            ))}
          </Text>

          {names.length > 1 && separateGroup && (
            <Text gutterBottom className={styles.splitGroupLink}>
              <Link onClick={() => separateGroup(group)} appearance="default">
                {t("inviteParkers.lotSelection.chooseDifferentLots")}
              </Link>
            </Text>
          )}
        </div>

        <Table columns={columns} data={data} striped responsive />
      </div>
    );
  };

  return (
    <>
      {bundles?.map((bundleGroup, index) =>
        renderLots({ index, group: bundleGroup, isBundles: true })
      )}
      {eventsByRate?.map((eventGroup, index) =>
        renderLots({ index, group: eventGroup })
      )}
    </>
  );
};

export default LotsSelector;
