import CurrencyFormatter from "@citifyd/currency-formatter";
import { mapValues, map } from "lodash";

import { getCurrentLanguage } from "../../shared/services/languages";
import { generateStripeCustomerUrl } from "../../shared/services/helper";

const generateFormattedAmountList = (amountPerCurrency) => {
  const language = getCurrentLanguage();

  const amounts = map(amountPerCurrency, (value, currency) =>
    CurrencyFormatter.format(value, {
      currency,
      language,
    })
  );

  return amounts.join(" + ");
};

export const getUser = (data) => {
  let user = data.user;
  user.address = user.mailingAddress || user.billingAddress;

  const totalBalance = mapValues(
    user.merchantValidationAmounts,
    (u) => u.totalBalance
  );

  user.formattedTotalBalance = generateFormattedAmountList(totalBalance);

  const totalValidations = mapValues(
    user.merchantValidationAmounts,
    (u) => u.totalValidations
  );

  user.formattedTotalValidations =
    generateFormattedAmountList(totalValidations);

  const totalAvailableForRefund = mapValues(
    user.merchantValidationAmounts,
    (u) => u.totalBalance - u.totalValidations
  );

  user.formattedTotalAvailableForRefund = generateFormattedAmountList(
    totalAvailableForRefund
  );

  user.stripeCustomerUrl = generateStripeCustomerUrl(user.stripeCustomerId);

  return user;
};
