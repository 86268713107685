"use strict";

import React from "react";
import classNames from "classnames";
import { Text, Grid } from "@citifyd/style";
import { useTranslator } from "../../../../shared/react/hooks";
import BoxContent from "../../../../shared/react/components/BoxContent";
import styles from "./PropertyItem.module.scss";

const PropertyItem = ({ lot, measurementSystem, onClick }) => {
  const t = useTranslator();
  return (
    <BoxContent
      onClick={onClick}
      appearance={lot.availability?.selected ? "gray_darker" : "secondary"}
      noShadow
      smallRadius
      className={classNames(styles.item)}
    >
      <BoxContent.Content>
        <Grid.Row>
          <Grid.Col xs={9}>
            <Text
              variant="body"
              weight="600"
              appearance={lot.availability?.selected && "white"}
            >
              {lot.name}
            </Text>
          </Grid.Col>
          <Grid.Col xs={3}>
            <Text
              textAlign="right"
              variant="body"
              weight="600"
              appearance={lot.availability?.selected && "white"}
            >
              {measurementSystem === "imperial" &&
                t("eventProperties.distanceFromEventMilesShort", {
                  distance: lot.distance.toFixed(2),
                })}
              {measurementSystem === "metric" &&
                t("eventProperties.distanceFromEventKilometersShort", {
                  distance: lot.distance.toFixed(2),
                })}
            </Text>
          </Grid.Col>
        </Grid.Row>
      </BoxContent.Content>
    </BoxContent>
  );
};

export default PropertyItem;
