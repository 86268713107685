import { Icon, List, Grid, Text, Tooltip } from "@citifyd/style";
import { TooltipProps } from "@citifyd/style/dist/types/Tooltip";
import React, { useEffect, useState } from "react";
import uuid from "uuid/v4";

import { useTranslator } from "../../../react/hooks";

export interface ListToSeeMoreProps {
  items: string[];
  itemsPerColumns?: number;
  andOthers?: boolean;
  limit?: number;
  tooltipProps: TooltipProps;
}

export interface TooltipContentProps {
  items: string[];
  itemsPerColumns?: number;
}

export interface ItemsList {
  items: string[];
}

const ListToSeeMore = ({
  items,
  itemsPerColumns = 7,
  limit = 3,
  tooltipProps,
  andOthers = true,
}: ListToSeeMoreProps) => {
  if (items.length > limit) {
    return (
      <NamesWithTooltip
        andOthers={andOthers}
        tooltipProps={tooltipProps}
        items={items}
        limit={limit}
        itemsPerColumns={itemsPerColumns}
      />
    );
  } else {
    return items.map((item, index) => (
      <>
        <span style={{ whiteSpace: "nowrap" }}>{item}</span>
        {index + 1 < items.length ? ", " : ""}
      </>
    ));
  }
};

const NamesWithTooltip = ({
  items,
  limit,
  itemsPerColumns,
  tooltipProps,
  andOthers,
}: Required<ListToSeeMoreProps>) => {
  const t = useTranslator();
  const [id, setId] = useState();

  useEffect(() => {
    setId(uuid());
  }, []);

  const others = items.length - limit;
  const itemsOthers = items.slice(limit);

  if (!id) {
    return null;
  }

  return (
    <>
      {items.slice(0, limit).map((item) => (
        <>
          <span style={{ whiteSpace: "nowrap" }}>{item}</span>,{" "}
        </>
      ))}
      <div style={{ display: "inline-block" }}>
        <Tooltip
          {...tooltipProps}
          id={`tooltip-${id}`}
          label={
            <Text weight="600">
              {andOthers &&
                t("commonTexts.andOthers", {
                  others,
                })}
              <Icon icon="info-circle" pull="right" size="h3" />
            </Text>
          }
        >
          <TooltipContent
            items={itemsOthers}
            itemsPerColumns={itemsPerColumns}
          />
        </Tooltip>
      </div>
    </>
  );
};

const TooltipContent = ({
  items,
  itemsPerColumns,
}: Required<TooltipContentProps>) => {
  const useTwoColumns = items.length >= itemsPerColumns;
  const halfIndex = Math.ceil(items.length / 2);

  return (
    <div
      style={{ width: useTwoColumns ? "400px" : undefined, minHeight: "50px" }}
    >
      <Grid.Row>
        {useTwoColumns ? (
          <>
            <Grid.Col>
              <ItemsList items={items.slice(0, halfIndex)} />
            </Grid.Col>
            <Grid.Col>
              <ItemsList items={items.slice(halfIndex)} />
            </Grid.Col>
          </>
        ) : (
          <Grid.Col>
            <ItemsList items={items} />
          </Grid.Col>
        )}
      </Grid.Row>
    </div>
  );
};

const ItemsList = ({ items }: ItemsList) => {
  return (
    <List>
      {items.map((item, key) => (
        <List.Item key={key} textAppearance="white">
          {item}
        </List.Item>
      ))}
    </List>
  );
};
export default ListToSeeMore;
