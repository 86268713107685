"use strict";

import React, { useState, useEffect } from "react";
import { Grid } from "@citifyd/style";

import ApolloClient from "../../../shared/services/apolloClient";
import ReservedParkingFullInformation from "../../../shared/react/components/ReservedParkingFullInformation";
import PageHeader from "../../../shared/react/components/PageHeader";
import RouterLinkButton from "../../../shared/react/components/RouterLinkButton";
import LoadingManager from "../../../shared/react/components/LoadingManager";
import { getService } from "../../../shared/react/utils";
import { useTranslator } from "../../../shared/react/hooks";
import Permissions from "../../../shared/services/permissions";

import ADMIN_GET_RESERVED_PARKING_PASS from "../../../graphql/reservedParkingPasses/queries/AdminGetReservedParkingPass";
import { useAuthentication } from "../../../shared/react/contexts/authentication";

function Page() {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [reservedParkingPass, setReservedParkingPass] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const { id } = getService("$stateParams");
      setIsLoading(true);

      ApolloClient()
        .query({
          query: ADMIN_GET_RESERVED_PARKING_PASS,
          variables: {
            passId: parseInt(id),
          },
        })
        .then((response) => {
          if (response?.data?.admin_getReservedParkingPass) {
            setReservedParkingPass(
              response?.data?.admin_getReservedParkingPass
            );
          } else {
            setHasError(true);
          }
        })
        .catch(() => {
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    fetchData();
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  return (
    <Grid>
      <ReservedParkingPageTitle reservedParkingPass={reservedParkingPass} />

      {reservedParkingPass && (
        <>
          <ReservedParkingFullInformation
            reservedParkingPass={reservedParkingPass}
          />
          <ReservedParkingActions reservedParkingPass={reservedParkingPass} />
        </>
      )}
    </Grid>
  );
}

const ReservedParkingPageTitle = ({ reservedParkingPass }) => {
  const t = useTranslator();

  if (!reservedParkingPass?.user || reservedParkingPass?.user?.isGuest) {
    return (
      <PageHeader>
        <PageHeader.Title title={t("reservedParkingPage.pageTitle")} />
      </PageHeader>
    );
  }

  return (
    <PageHeader>
      <PageHeader.Title
        defaultState="user"
        defaultParams={{ id: reservedParkingPass?.user?.id }}
      />
    </PageHeader>
  );
};

const ReservedParkingActions = ({ reservedParkingPass }) => {
  const t = useTranslator();
  const { permissions } = useAuthentication();
  const buttons = [];

  if (
    !reservedParkingPass.cancelledAt &&
    Permissions.userMayCreateRefunds(permissions)
  ) {
    buttons.push(
      <RouterLinkButton
        key="refund"
        state="reserved-parking-pass-refund"
        params={{ id: reservedParkingPass.id }}
        options={{ enableBackLink: true }}
        justifyContent="center"
        letterSpacing
        shadow
        uppercase
      >
        {t("reservedParkingPage.actionButtons.refund")}
      </RouterLinkButton>
    );
  }

  return buttons;
};

export default Page;
