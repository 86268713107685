import { useLazyQuery } from "@apollo/client";
import ApolloClient from "../../../../shared/services/apolloClient";
import ADMIN_GET_USER_PERMITS_PASSES from "../../../../graphql/permitPass/queries/AdminGetUserPermitsPasses";

const apolloClient = ApolloClient();

const usePermitPasses = ({ userId }) => {
  const [
    fetchPermitsPasses,
    {
      data: permitPassesData,
      loading: loadingPermitPasses,
      error: permitPassesError,
    },
  ] = useLazyQuery(ADMIN_GET_USER_PERMITS_PASSES, {
    variables: {
      userId,
    },
    client: apolloClient,
    fetchPolicy: "network-only",
  });

  const permitPasses = permitPassesData?.admin_listPermitPasses?.edges?.map(
    (edge) => ({ ...edge.node, price: edge?.node.calculatedPrice?.total ?? 0 })
  );

  return {
    fetchPermitsPasses,
    permitPasses,
    loadingPermitPasses,
    permitPassesError,
  };
};

export default usePermitPasses;
