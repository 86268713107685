"use strict";
import { get, find } from "lodash";
import i18next from "i18next";

import { getAcceptedLanguage } from "../../vendor/scripts/i18next-helper";

// we don't use i18next.t('languages.xxx') here because we want
// the language name in their own language here
const languages = [
  { key: "en-US", name: "English" },
  { key: "pt-BR", name: "Português" },
  { key: "ja-JP", name: "日本語" },
];

let userUpdated = false;

export const getAvailableLanguages = () => {
  return languages;
};

export const getLanguageName = (key) => {
  return get(find(languages, { key: key }), "name", null);
};

export const hasUserUpdatedLanguage = () => {
  return userUpdated;
};

export const changeLanguage = (key, manualUpdate = false) => {
  if (manualUpdate) {
    userUpdated = true;
  }
  key = getAcceptedLanguage(key);
  i18next.changeLanguage(key);

  const event = new Event("citifyd:changed-language");

  event.language = getCurrentLanguage();
  document.cookie = `citifyd_language=${key}`;
  document.dispatchEvent(event);
};

export const onLanguageChange = (callback) => {
  document.addEventListener("citifyd:changed-language", (event) => {
    callback(event.language);
  });
};

export const getCurrentLanguage = () => {
  return i18next.language;
};

export const getTranslator = () => {
  return (...args) => i18next.t(...args);
};
