"use strict";

import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { Text } from "@citifyd/style";
import { pick, find, isEqual, isEmpty } from "lodash";
import { useLazyQuery } from "@apollo/client";
import useInfiniteScroll from "react-infinite-scroll-hook";

import usePrevious from "../../../../shared/react/hooks/usePrevious";
import ApolloClient from "../../../../shared/services/apolloClient";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import GenerateReportModal from "../../../../shared/modals/GenerateReportModal";
import { getTransactionsReportsCsv } from "../../../../shared/api";
import Filter from "./Filter";
import Table from "./Table";
import { getFilterSelectionFromState } from "../../utils";
import styles from "./TransactionsReport.module.scss";

import ADMIN_TRANSACTIONS_REPORT from "../../../../graphql/reports/queries/AdminTransactionsReport";

import { useModal } from "../../../../shared/react/contexts/modal";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const apolloClient = ApolloClient();

const PER_PAGE = 10;

const TransactionReport = ({ className, lots, organizationsOptions }) => {
  const $state = useService("$state");
  const t = useTranslator();
  const { openModal } = useModal();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [params, setParams] = useState($state.params);
  const prevParams = usePrevious($state.params);
  const { user } = useAuthentication();

  const [getTransactions, { data, loading, error, fetchMore }] = useLazyQuery(
    ADMIN_TRANSACTIONS_REPORT,
    {
      client: apolloClient,
      fetchPolicy: "network-only",
    }
  );

  const getQueryOptionsFromFilterSelection = (filterSelection) => {
    return {
      startTime: moment(filterSelection.startDate).format(
        `YYYY-MM-DDT00:00:00Z`
      ),
      endTime: moment(filterSelection.endDate).format(`YYYY-MM-DDT23:59:59Z`),
      parkingMethod: filterSelection.parkingMethod || null,
      last4: filterSelection.lastFourDigits,
      phoneNumber: filterSelection.phoneNumber,
      parkingSessionId: filterSelection.transactionId,
      license: filterSelection.license,
      organizationId: filterSelection.organizationId
        ? Number(filterSelection.organizationId)
        : null,
      first: PER_PAGE,
      similarSearch: filterSelection.similarSearch || false,
      lotIds:
        filterSelection.lots?.split(",").map((item) => parseInt(item, 10)) ||
        [],
    };
  };

  useEffect(() => {
    if (!isEqual(prevParams, $state.params) && !isEmpty($state.params)) {
      setParams($state.params);
    }
  }, [$state.params]);

  useEffect(() => {
    if (params.dateRange) {
      const filterSelection = getFilterSelectionFromState({ user });
      const options = getQueryOptionsFromFilterSelection(filterSelection);

      getTransactions({
        variables: {
          ...options,
        },
      });
    }
  }, [params]);

  useEffect(() => {
    if (isLoadingMore) {
      setIsLoadingMore(false);
    }
  }, [data]);

  const generateCsvReportDescription = (options) => {
    const lot = find(lots, (lot) => lot.id === options.lotId);

    return t("reports.transactions.csvReportName.lot", {
      ...options,
      lotName: lot?.name,
      startTime: moment.tz(options.startTime, "UTC"),
      endTime: moment.tz(options.endTime, "UTC"),
    });
  };

  const generateCsvClicked = () => {
    const filterSelection = getFilterSelectionFromState({ user });
    const options = getQueryOptionsFromFilterSelection(filterSelection);

    const csvParams = {
      ...pick(options, [
        "transactionId",
        "startTime",
        "endTime",
        "resevationType",
        "timezoneName",
        "license",
      ]),
      lotIds: options.lotIds,
    };
    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription: generateCsvReportDescription(options),
      disableFilterDescriptionFirstCharacterLowercase: true,
      reportCsvRequest: getTransactionsReportsCsv(csvParams),
    });
  };

  const { edges } = data ? data.admin_transactionsReport : {};
  const { endCursor, hasNextPage } = data
    ? data.admin_transactionsReport?.pageInfo
    : {};

  const loadMore = () => {
    if (!hasNextPage || isLoadingMore) {
      return;
    }

    setIsLoadingMore(true);

    fetchMore({
      variables: {
        after: endCursor,
      },
      updateQuery: (prevData, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevData;

        fetchMoreResult.admin_transactionsReport.edges = [
          ...prevData.admin_transactionsReport.edges,
          ...fetchMoreResult.admin_transactionsReport.edges,
        ];

        return fetchMoreResult;
      },
    });
  };

  const [tableRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: () => loadMore(),
    disabled: !hasNextPage,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <div className={className}>
      <Filter
        onClickGenerateCsv={generateCsvClicked}
        organizationsOptions={organizationsOptions}
        lots={lots}
        disabled={loading || isLoadingMore || edges?.length === 0}
      />
      {loading || error ? (
        <LoadingManager isLoading={loading} hasError={error} />
      ) : (
        edges && (
          <>
            {edges?.length > 0 ? (
              <Table transactionsData={edges} />
            ) : (
              <Text>{t("reports.noResults")}</Text>
            )}
            {hasNextPage && (
              <div className={styles.tableRef} ref={tableRef}>
                {isLoadingMore && !!edges?.length && (
                  <LoadingManager isLoading={isLoadingMore} />
                )}
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};

export default TransactionReport;
