"use strict";

import React from "react";

import LoadingManager from "../../../../shared/react/components/LoadingManager";
import { useTranslator } from "../../../../shared/react/hooks";
import UserTicketsStats from "../UserTicketsStats";
import UserTicketsTable from "../UserTicketsTable";

const UserTickets = ({ user, isLoading, hasError }) => {
  const t = useTranslator();

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={t("user.tickets.loading")}
      />
    );
  }

  return (
    <>
      <UserTicketsStats user={user} />
      <UserTicketsTable user={user} />
    </>
  );
};

export default UserTickets;
