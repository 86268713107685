import { isEmpty, mapValues } from "lodash";

export const getInitialValues = (venue, addressSchema) => {
  if (!isEmpty(venue)) {
    return {
      address: venue.address,
      coordinates: {
        latitude: venue.latitude,
        longitude: venue.longitude,
      },
      countryCode: venue.countryCode,
      name: venue.name,
    };
  }

  return {
    address: addressSchema ? mapValues(addressSchema.properties, () => "") : "",
    coordinates: {
      latitude: "",
      longitude: "",
    },
    countryCode: "us",
    name: "",
  };
};
