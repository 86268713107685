"use strict";

import React, { useEffect, useState, useRef } from "react";
import { Select, Grid, DatePicker } from "@citifyd/style";
import moment from "moment-timezone";

import { useService, useTranslator } from "../../../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../../../shared/services/languages";
import SearchInput from "../../../../../shared/react/components/SearchInput";
import { validate, selectionToParams } from "./utils";

import styles from "./Filters.module.scss";

const ReservedParkingFilters = ({ currentFilter }) => {
  const t = useTranslator();
  const [errors, setErrors] = useState({});
  const $state = useService("$state");
  const [selection, setSelection] = useState({});
  const timer = useRef(null);
  const language = getCurrentLanguage();
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (currentFilter) {
      setSelection(currentFilter);
      setSearch(currentFilter?.search || "");
    }
  }, [currentFilter]);

  const statusOptions = [
    {
      label: t("schedule.reservedParkingFilter.status.all"),
      value: "",
    },
    {
      value: "FUTURE",
      label: t("schedule.reservedParkingFilter.status.future"),
    },
    {
      value: "ACTIVE",
      label: t("schedule.reservedParkingFilter.status.active"),
    },
    {
      value: "PAST",
      label: t("schedule.reservedParkingFilter.status.past"),
    },
  ];

  const updateFilters = (newSelection) => {
    if (!validate(newSelection, setErrors, t)) {
      return false;
    }

    $state.transitionTo($state.current.name, selectionToParams(newSelection), {
      location: "replace",
    });
  };

  const fieldChanged = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    const newSelection = {
      ...selection,
      [name]: value,
    };

    setSelection(newSelection);
    updateFilters(newSelection);
  };

  const setDate = (field, date) => {
    const newSelection = {
      ...selection,
      [field]: date,
    };

    setSelection(newSelection);
    updateFilters(newSelection);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);

    if (timer) {
      clearTimeout(timer.current);
    }

    const newSelection = {
      ...selection,
      search: e.target.value,
    };

    timer.current = setTimeout(() => {
      updateFilters(newSelection);
    }, 500);
  };

  return (
    <Grid.Row marginBottom>
      <Grid.Col className={styles.filter} xs={12} md={3}>
        <Select
          fullWidth
          name="status"
          labelSize="small"
          label={t("schedule.reservedParkingFilter.fields.status")}
          options={statusOptions}
          value={selection.status || ""}
          onChange={fieldChanged}
        />
      </Grid.Col>
      <Grid.Col className={styles.filter} xs={12} md={3}>
        <DatePicker
          labelSize="small"
          locale={language}
          onChange={(date) => setDate("startDate", date)}
          selectedDate={moment(selection.startDate).toDate()}
          label={t("schedule.reservedParkingFilter.fields.startDate")}
        />
      </Grid.Col>
      <Grid.Col className={styles.filter} xs={12} md={3}>
        <DatePicker
          locale={language}
          labelSize="small"
          onChange={(date) => setDate("endDate", date)}
          selectedDate={moment(selection.endDate).toDate()}
          label={t("schedule.reservedParkingFilter.fields.endDate")}
          error={errors["dateRange.end"]}
          errorMessage={t(errors["dateRange.end"])}
        />
      </Grid.Col>
      <Grid.Col className={styles.filter} xs={12} md={3}>
        <SearchInput
          value={search}
          id="search"
          label={t("schedule.reservedParkingFilter.fields.search")}
          labelSize="small"
          onChange={handleSearch}
          fullWidth
        />
      </Grid.Col>
    </Grid.Row>
  );
};

export default ReservedParkingFilters;
