import gql from "graphql-tag";

export default gql`
  mutation AdminCreatePermitPass(
    $phoneNumber: String!
    $phoneCountryCode: String!
    $lotId: Int!
    $startTime: DateTime!
    $endTime: DateTime
    $chargeType: PermitPassChargeType!
    $customPrice: Int
  ) {
    admin_createPermitPass(
      phoneNumber: $phoneNumber
      phoneCountryCode: $phoneCountryCode
      lotId: $lotId
      startTime: $startTime
      endTime: $endTime
      chargeType: $chargeType
      customPrice: $customPrice
    ) {
      id
      user {
        id
        name
      }
      lot {
        id
        name
      }
      vehicle {
        id
        label
        model
        license
      }
      isActive
      status
      startTime
      endTime
      activatedAt
      createdAt
    }
  }
`;
