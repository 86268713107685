"use strict";

import moment from "moment-timezone";
import { includes } from "lodash";

function getFilterSelectionFromState($state, currencies) {
  let dateRange = null;

  if ($state.params.dateRange) {
    const parts = $state.params.dateRange.split(",");

    const startDate = moment(parts[0]).toDate();
    const endDate = moment(parts[1]).toDate();

    dateRange = { startDate, endDate };
  } else {
    const { startDate, endDate } = getDefaultDateRange();

    dateRange = { startDate: startDate.toDate(), endDate: endDate.toDate() };
  }

  let lotId = $state.params.lot || null;
  let currency = $state.params.currency || currencies[0]?.value;
  let timezoneName = $state.params.timezoneName || null;
  let organizationId = $state.params.organizationId
    ? Number($state.params.organizationId)
    : null;

  return { lotId, currency, dateRange, timezoneName, organizationId };
}

const getDefaultDateRange = () => {
  const startDate = moment()
    .subtract(1, "month")
    .set({ hour: 0, minute: 0, seconds: 0 });

  const endDate = moment().set({ hour: 0, minute: 0, seconds: 0 });

  return { startDate, endDate };
};

function selectDefaultFilters(user, $state, $scope, fields) {
  const params = { ...$state.params };
  let changed = false;

  if (includes(fields, "currency") && !params.currency) {
    params.currency = $scope.currencies[0].value;
    changed = true;
  }

  if (includes(fields, "dateRange") && !params.dateRange) {
    let { startDate, endDate } = getDefaultDateRange();
    startDate = startDate.format("YYYY-MM-DD");
    endDate = endDate.format("YYYY-MM-DD");
    params.dateRange = `${startDate},${endDate}`;
    changed = true;
  }

  if (includes(fields, "timezoneName") && !params.timezoneName) {
    params.timezoneName = user.timezoneName;
  }

  if (changed) {
    $state.transitionTo($state.current.name, params, {
      location: "replace",
      notify: false,
      inherit: true,
    });
  }

  return { changed };
}

export { selectDefaultFilters, getFilterSelectionFromState };
