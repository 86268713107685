import React, { useEffect, useMemo } from "react";

import { Grid } from "@citifyd/style";
import PageHeader from "../../shared/react/components/PageHeader";
import { useTranslator } from "../../shared/react/hooks";
import RouterLinkButton from "../../shared/react/components/RouterLinkButton";
import Table from "../../shared/react/components/Table";
import ApolloClient from "../../shared/services/apolloClient";
import { useLazyQuery } from "@apollo/client";
import ADMIN_GET_PORTALS from "../../graphql/permitPortal/queries/AdminGetPortals";
import LoadingManager from "../../shared/react/components/LoadingManager";
import { getService } from "../../shared/react/utils";
import ListToSeeMore from "../../shared/react/components/ListToSeeMore";

const apolloClient = ApolloClient();

const Settings = () => {
  const t = useTranslator();

  const [getAllPortals, { data, loading, error }] = useLazyQuery(
    ADMIN_GET_PORTALS,
    {
      client: apolloClient,
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    getAllPortals();
  }, []);

  const portalsList = data ? data.admin_listPermitPortals.edges : [];

  const columns = [
    { key: "property", value: t("permits.permitters.settings.portalName") },
    { key: "url", value: t("permits.permitters.settings.urlPortal") },
    {
      key: "properties",
      value: t("permits.permitters.settings.parkingProperties"),
    },
  ];

  const openEditPortal = (userId) => {
    const $state = getService("$state");

    $state.go(
      "permitters-portal-settings-edit",
      { id: userId },
      { enableBackLink: true }
    );
  };

  const portals = useMemo(
    () =>
      portalsList?.map((portal) => {
        const portalNames = portal.node.lots.map(({ lot }) => lot.name);
        return {
          property: portal.node.name,
          url: portal.node.namespace,
          properties: <ListToSeeMore items={portalNames} />,
          clickable: true,
          onClick: () => openEditPortal(portal.node.id),
        };
      }),
    [portalsList]
  );

  if (error || loading) {
    return <LoadingManager isLoading={loading} hasError={Boolean(error)} />;
  }

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          title={t("permits.permitters.settings.pageTitle")}
          defaultState={"permitters"}
          weight="300"
        />
        <PageHeader.Actions>
          <PageHeader.Action>
            <RouterLinkButton
              size="small"
              letterSpacing
              uppercase
              state="permitters-portal-settings-add"
            >
              {t("permits.permitters.settings.addPortal")}
            </RouterLinkButton>
          </PageHeader.Action>
        </PageHeader.Actions>
      </PageHeader>
      <Table striped columns={columns} data={portals} />
    </Grid>
  );
};

export default Settings;
