import React, { useMemo, useState } from "react";
import { Button, Grid, Icon } from "@citifyd/style";

import Table from "../../../shared/react/components/Table";
import { useTranslator } from "../../../shared/react/hooks";
import { usePosRoverCtx } from "../hooks/usePosRoverCtx";
import styles from "./Devices.module.scss";

import { useDevicesActions } from "../hooks/useDevicesActions";
import { Device } from "../../../graphql-pos/generated/graphql";
import { RoverAddNewDeviceModal } from "../../../shared/modals/RoverAddNewDeviceModal/RoverAddNewDeviceModal";
import { useModal } from "../../../shared/react/contexts/modal";
import SearchInput from "../../../shared/react/components/SearchInput";
import { normalize } from "../../../shared/services/helper";

export const Devices = () => {
  const t = useTranslator();
  const [search, setSearch] = useState("");
  const { openModal } = useModal();

  const {
    devicesQuery: { devices },
  } = usePosRoverCtx();

  const getStatusColor = ({
    status,
  }: {
    status: "ACTIVE" | "PROVISIONED" | "READY" | "OFFLINE";
  }) => {
    switch (status) {
      case "ACTIVE":
        return "success";
      case "OFFLINE":
        return "error";
      case "PROVISIONED":
        return "completed";
    }
  };

  const columns = [
    { value: t("posRover.devices.id"), key: "id" },
    { value: t("posRover.devices.make"), key: "make" },
    { value: t("posRover.devices.model"), key: "model" },
    { value: t("posRover.devices.nickname"), key: "nickname" },
    { value: t("posRover.devices.enabled"), key: "enabled" },
    {
      value: t("posRover.devices.networkCarrier"),
      key: "networkCarrier",
    },
    { value: t("posRover.devices.imei"), key: "imei" },
    { value: t("posRover.devices.serial"), key: "serial" },
    { value: t("posRover.devices.status"), key: "status" },
    {
      value: t("posRover.devices.action"),
      key: "action",
      textAlign: "center",
    },
  ];

  const data = useMemo(() => {
    let filteredDevices = devices;

    if (search) {
      const normalizedSearch = normalize(search);
      const searchItem = (item) =>
        normalize(String(item)).includes(normalizedSearch);

      filteredDevices = devices?.filter((device) => {
        if (searchItem("enabled") || searchItem("yes")) {
          return device?.enabled;
        }

        if (searchItem("disabled") || searchItem("no")) {
          return !device?.enabled;
        }

        return (
          searchItem(device?.shortCode) ||
          searchItem(device?.name) ||
          searchItem(device?.make) ||
          searchItem(device?.model) ||
          searchItem(device?.networkCarrierName) ||
          searchItem(device?.imei) ||
          searchItem(device?.serial) ||
          searchItem(device?.state)
        );
      });
    }

    return filteredDevices?.map((device) => {
      if (!device) {
        return null;
      }
      const statusColor = getStatusColor({ status: device.state });

      return {
        id: `#${device?.shortCode}`,
        make: device?.make,
        model: device?.model,
        nickname: device?.name,
        enabled: device?.enabled ? "Yes" : "No",
        networkCarrier: device?.networkCarrierName,
        imei: device?.imei,
        serial: device?.serial,
        status: (
          <span style={{ whiteSpace: "nowrap" }}>
            <Icon
              icon="circle"
              pull="none"
              size="small"
              className={styles.icon}
              appearance={statusColor}
            />
            {device?.state}
          </span>
        ),

        action: {
          textAlign: "right",
          value: <ActionButtons device={device} />,
        },
      };
    });
  }, [devices, search]);

  return (
    <div className={styles.container}>
      <Grid>
        <Grid.Row marginBottom>
          <Grid.Col sm={3} offset={{ sm: 9 }}>
            <div className={styles.searchContainer}>
              <SearchInput
                labelSize="small"
                value={search}
                placeholder="search"
                onClear={() => setSearch("")}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <Button
                size="small"
                letterSpacing
                uppercase
                onClick={() => openModal(RoverAddNewDeviceModal)}
              >
                {t("posRover.commons.add")}
              </Button>
            </div>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col>
            <Table
              columns={columns}
              data={data}
              striped
              responsive
              noResultsText={t("posRover.devices.noDevicesFound")}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
};

const ActionButtons = ({ device }: { device: Device }) => {
  const t = useTranslator();
  const { onAssign, onCancel, onFinishButtonClick, onManageButtonClick } =
    useDevicesActions();

  if (device?.state === "ACTIVE") {
    return (
      <div className={styles.searchContainer}>
        <Button
          justifyContent="center"
          size="tiny"
          appearance="secondary"
          uppercase
          onClick={() => onFinishButtonClick(device)}
        >
          {t("posRover.commons.finish")}
        </Button>
        <Button
          justifyContent="center"
          size="tiny"
          uppercase
          onClick={() => onManageButtonClick({ device })}
        >
          {t("posRover.commons.manage")}
        </Button>
      </div>
    );
  }

  if (device?.state === "PROVISIONED") {
    return (
      <Button
        justifyContent="center"
        size="tiny"
        appearance="gray"
        uppercase
        onClick={() => onCancel(device)}
      >
        {t("posRover.commons.cancel")}
      </Button>
    );
  }

  if (device?.state === "READY") {
    return (
      <Button
        justifyContent="center"
        appearance="tertiary"
        size="tiny"
        uppercase
        onClick={() => onAssign(device)}
      >
        {t("posRover.commons.assign")}
      </Button>
    );
  }

  return <></>;
};
