"use strict";

import React, { useState, useMemo } from "react";
import { Text } from "@citifyd/style";
import currencyFormatter from "@citifyd/currency-formatter";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import Table from "../../../../shared/react/components/Table";
import { useTranslator } from "../../../../shared/react/hooks";
import {
  generateOrderForClickEvent,
  sort,
} from "../../../../shared/react/sorting";
import { getCurrentLanguage } from "../../../../shared/services/languages";

const PermitPasses = ({ permitPasses, isLoading, hasError }) => {
  const t = useTranslator();
  const language = getCurrentLanguage();
  const [orders, setOrders] = useState([["startTime", "desc"]]);

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        loadingMessage={t("user.permitPasses.loading")}
        hasError={hasError}
        errorMessage={t("user.errorLoadingPasses")}
      />
    );
  }

  if (permitPasses.length === 0) {
    return <Text>{t("user.permitPasses.empty")}</Text>;
  }

  const sortedPermitPasses = useMemo(() => {
    return sort({ items: permitPasses, order: orders });
  }, [permitPasses, orders]);

  if (!permitPasses.length) {
    return null;
  }

  const handleSort = (event, property) => {
    setOrders(
      generateOrderForClickEvent({
        event,
        currentOrder: orders,
        propertyName: property,
      })
    );
  };

  const columns = [
    {
      value: t("user.permitPasses.table.start"),
      key: "startTime",
      sortable: true,
      sortableKey: "startTime",
      onClick: (e) => handleSort(e, "startTime"),
      orders,
    },
    {
      value: t("user.permitPasses.table.end"),
      key: "endTime",
      sortable: true,
      sortableKey: "endTime",
      onClick: (e) => handleSort(e, "endTime"),
      orders,
    },
    {
      value: t("user.permitPasses.table.lot"),
      key: "lot",
      sortable: true,
      sortableKey: "lot",
      onClick: (e) => handleSort(e, "lot"),
      orders,
    },
    {
      value: t("user.permitPasses.table.price"),
      key: "price",
      sortable: true,
      sortableKey: "price",
      onClick: (e) => handleSort(e, "price"),
      orders,
    },
  ];

  const data = sortedPermitPasses?.map((permit) => ({
    startTime: t("defaultFormats.datetime", {
      date: permit.startTime,
    }),
    endTime: t("defaultFormats.datetime", {
      date: permit.endTime,
    }),
    lot: permit.lot.name,
    price: currencyFormatter.format(permit?.calculatedPrice?.total || 0, {
      currency: permit?.lot.country.currency || "usd",
      language,
    }),
  }));

  return (
    <Table
      spacing
      responsive
      columns={columns}
      sortable
      data={data}
      clickable
    />
  );
};
export default PermitPasses;
