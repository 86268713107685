"use strict";

import produce from "immer";
import { useTranslator } from "../../shared/react/hooks";
import { updateProperty } from "../../shared/api";
import { showErrorAlert } from "../../shared/services/helper";

export const useToggleApproval = ({ properties, setProperties }) => {
  const t = useTranslator();

  const toggleApproval = async (lotId) => {
    const property = properties.find((p) => p.id === lotId);

    if (!confirmToggleApproval({ property, t })) {
      return;
    }
    setProperties(
      produce((properties) => {
        const property = properties.find((p) => p.id === lotId);
        property.isUpdating = true;
      })
    );

    try {
      const property = properties.find((p) => p.id === lotId);
      const response = await updateProperty({
        id: property.id,
        isApproved: !property.isApproved,
      });
      setProperties(
        produce((properties) => {
          const property = properties.find((p) => p.id === lotId);
          property.isApproved = response.isApproved;
          return properties;
        })
      );
    } catch (err) {
      showErrorAlert(err.data);
    } finally {
      setProperties(
        produce((properties) => {
          const property = properties.find((p) => p.id === lotId);
          property.isUpdating = false;
          return properties;
        })
      );
    }
  };

  return toggleApproval;
};

const confirmToggleApproval = ({ property, t }) => {
  let message;

  if (property.isApproved) {
    message = t("allProperties.disapprovalConfirmation", {
      lotName: property.name,
    });
  } else {
    message = t("allProperties.approvalConfirmation", {
      lotName: property.name,
    });
  }

  return window.confirm(message);
};
