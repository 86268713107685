"use strict";

import { getService } from "../../../shared/react/utils";
import { getTranslator } from "../../../shared/services/languages";

import {
  patchState,
  startLoading,
  stopLoading,
  validateRules,
  markEditableRulesAsPersisted,
  patchPreviewState,
} from "./actions";

import {
  convertBackEndRuleToFrontEndFormat,
  convertFrontEndRuleToBackEndFormat,
  hasInvalidRule,
  generateRuleRowId,
} from "../utils/rules";

import _ from "lodash";
import {
  findProperty,
  getAdvancedRateDefinition,
  previewAdvancedRateDefinition,
  publishLatestAdvancedRateDefinition,
  saveAdvancedRateDefinition,
} from "../../../shared/api";
import {
  getServerErrorMessage,
  scrollTo,
  showErrorAlert,
} from "../../../shared/services/helper";
import { lotSetup } from "../utils/lotSetup";

export const fetchData =
  ({ lotId, advancedRateDefinitionId }) =>
  async (dispatch) => {
    const t = getTranslator();

    dispatch(
      startLoading({ message: t("advancedRateEditor.messages.loadingRates") })
    );

    const lot = await findProperty(lotId);

    const rateDefinition = await getAdvancedRateDefinition({
      lotId,
      id: advancedRateDefinitionId,
    });

    const rules = rateDefinition.rules.map((rule) =>
      convertBackEndRuleToFrontEndFormat(rule, lot.country.currency)
    );
    const editEnabled = rateDefinition.isLatestVersion;

    dispatch(
      patchState({
        lot,
        rateDefinition,
        editableRules: rules,
        editEnabled,
        lastRuleId: rules.length
          ? Math.max(...rules.map((rule) => rule.id))
          : 0,
        mode: rules.length > 0 || !editEnabled ? "view" : "edit",
      })
    );

    dispatch(stopLoading());
  };

export const generateTimeRangesPreview = () => async (dispatch, getState) => {
  const t = getTranslator();

  const { lot, editableRules } = getState();

  const backEndRules = editableRules.map((rule) =>
    convertFrontEndRuleToBackEndFormat(rule, lot.country.currency)
  );

  dispatch(
    startLoading({
      message: t("advancedRateEditor.messages.generatingPreview"),
    })
  );

  try {
    const { timeRanges } = await previewAdvancedRateDefinition(
      lot.id,
      backEndRules
    );

    for (const day in timeRanges) {
      const timeRangesOfDay = timeRanges[day];
      for (const timeRange of timeRangesOfDay) {
        timeRange.rules = timeRange.rules.map((rule) =>
          convertBackEndRuleToFrontEndFormat(rule, lot.country.currency)
        );
      }
    }

    dispatch(patchPreviewState({ timeRanges, error: null }));
  } catch (err) {
    dispatch(
      patchPreviewState({
        timeRanges: {},
        error: getServerErrorMessage(err.data),
      })
    );
  } finally {
    dispatch(stopLoading());
  }
};

export const changeMode = (mode) => (dispatch, getState) => {
  const validate = (validCallback) => {
    dispatch(validateRules());

    const { editableRules } = getState();
    if (hasInvalidRule(editableRules)) {
      dispatch(scrollToFirstInvalidRule(editableRules));
    } else {
      validCallback();
    }
  };

  if (mode === "edit") {
    dispatch(patchState({ mode: "edit" }));
  } else if (mode === "view") {
    validate(() => dispatch(patchState({ mode: "view" })));
  } else if (mode === "preview") {
    validate(() => {
      dispatch(generateTimeRangesPreview());
      dispatch(patchState({ mode: "preview" }));
    });
  }
};

export const saveRateDefinition =
  ({ publish = false, user }) =>
  async (dispatch, getState) => {
    const t = getTranslator();

    dispatch(validateRules());
    const { editableRules, lot } = getState();

    if (hasInvalidRule(editableRules)) {
      dispatch(patchState({ mode: "edit" }));
      dispatch(scrollToFirstInvalidRule(editableRules));
      return;
    }

    dispatch(
      startLoading({ message: t("advancedRateEditor.messages.saving") })
    );

    const backEndRules = editableRules.map((rule) =>
      convertFrontEndRuleToBackEndFormat(rule, lot.country.currency)
    );

    let saved = false;

    try {
      const response = await saveAdvancedRateDefinition({
        lotId: lot.id,
        advancedRateDefinition: { rules: backEndRules },
      });

      dispatch(patchState({ rateDefinition: response }));
      saved = true;

      if (publish) {
        dispatch(
          startLoading({ message: t("advancedRateEditor.messages.publishing") })
        );
        const response = await publishLatestAdvancedRateDefinition(lot.id);
        dispatch(patchState({ rateDefinition: response }));
      }
    } catch (err) {
      showErrorAlert(err.data);
    }

    dispatch(stopLoading());

    const $state = getService("$state");

    if (saved) {
      // Mark rules as persisted, so that we can remove the confirmation alert if user leaves the page without
      // saving.
      dispatch(markEditableRulesAsPersisted());

      // // Change to view mode.
      // dispatch(changeMode('view'))

      const isLotSetup = lotSetup();

      if (isLotSetup) {
        $state.go(
          "edit-property-step",
          { lotId: lot.id, step: 3 },
          { location: "replace" }
        );
      } else {
        $state.go("schedule", { property: lot.id, tab: "onDemand" });
      }

      // Makes the URL points to latest version after saving, in case it's being saved as a copy from a
      // previous version.
      // This is to make sure that if the user refreshes the page after saving, they'll not go back to
      // the previous version.
      // dispatch(changeUrlToLatest())
    }
  };

export const changeUrlToLatest = () => (_, getState) => {
  const $state = getService("$state");
  const { lot } = getState();

  $state.go(
    "edit-property-advanced-rates",
    { lotId: lot.id, advancedRateDefinitionId: "latest" },
    { location: "replace", notify: false }
  );
};

export const scrollToRule = (ruleId) => () => {
  scrollTo("#" + generateRuleRowId(ruleId));
};

export const scrollToFirstInvalidRule = () => (dispatch, getState) => {
  const { editableRules } = getState();
  const firstInvalidRule = _.find(
    editableRules,
    (rule) => !_.isEmpty(rule.errors)
  );
  if (firstInvalidRule) {
    dispatch(scrollToRule(firstInvalidRule.id));
  }
};

export const focusOnRule =
  (ruleId, wait = 100) =>
  () => {
    const rowId = generateRuleRowId(ruleId);

    setTimeout(() => {
      const firstInputOfRow = document.querySelector(`#${rowId} input`);
      if (firstInputOfRow) {
        firstInputOfRow.focus();
      }
    }, wait);
  };

export const focusOnLastRule =
  (wait = 100) =>
  (dispatch, getState) => {
    const { editableRules } = getState();
    if (editableRules.length) {
      const lastId = Math.max(...editableRules.map((rule) => rule.id));
      dispatch(focusOnRule(lastId, wait));
    }
  };

export const focusOnNewestRule =
  (ruleId, wait = 100) =>
  () => {
    const rowId = generateRuleRowId(ruleId);

    setTimeout(() => {
      const firstInputOfRow = document.querySelector(`#${rowId} input`);
      if (firstInputOfRow) {
        firstInputOfRow.focus();
      }
    }, wait);
  };
