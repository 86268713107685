"use strict";

import { useMemo } from "react";
import { useTranslator } from "../../../../shared/react/hooks";

const useBundlesAndEvents = ({ steps, passGiver }) => {
  const t = useTranslator();

  return useMemo(() => {
    if (!steps.data.lotSelection) {
      return [];
    }

    const events = steps.data.lotSelection.events.map((event) => ({
      name: event.name,
      lotName: event.selectedLot.name,
      quantity: 1,
      price: t("inviteParkers.confirmation.complimentary", {
        passGiver: passGiver,
      }),
    }));

    const bundles = steps.data.lotSelection.bundles.map((bundleGroup) => {
      const bundle = bundleGroup.bundles.find(
        (bundle) => bundle.id === bundleGroup.selectedBundleId
      );

      return {
        name: bundleGroup.name,
        lotName: bundle.lot.name,
        quantity: 1,
        price: t("inviteParkers.confirmation.complimentary", {
          passGiver: passGiver,
        }),
      };
    });

    return [...bundles, ...events];
  }, [steps]);
};
export default useBundlesAndEvents;
