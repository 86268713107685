import React from "react";
import moment from "moment-timezone";
import CurrencyFormatter from "@citifyd/currency-formatter";

import {
  getTranslator,
  getCurrentLanguage,
} from "../../../../shared/services/languages";

export const getData = (
  ticket,
  currentRate,
  selectedEvent,
  selectedLot,
  newRate,
  transfersReversedAmount,
  totalChargedOrRefunded,
  rateDifference
) => {
  const t = getTranslator();
  const language = getCurrentLanguage();

  const columns = [
    {
      key: "pass",
      value: t("ticketExchange.pass"),
      nowrap: "nowrap",
      width: "1%",
    },
    {
      key: "eventDate",
      value: t("ticketExchange.eventDate"),
      width: "15%",
    },
    {
      key: "event",
      value: t("ticketExchange.event"),
      width: "40%",
    },
    {
      key: "lot",
      value: t("ticketExchange.lot"),
      width: "30%",
    },
    {
      key: "amount",
      value: t("ticketExchange.amount"),
      width: "15%",
    },
  ];

  const data = [
    {
      pass: {
        value: t("ticketExchange.original"),
        nowrap: "nowrap",
      },
      eventDate: {
        value: t("defaultFormats.date", {
          date: moment.tz(ticket.event.start, ticket.event.timezoneName),
        }),
      },
      event: {
        value: ticket.event.name,
      },
      lot: {
        value: ticket.lot.name,
        nowrap: "nowrap",
      },
      amount: {
        value: CurrencyFormatter.format(currentRate, {
          currency: ticket.lot.country.currency || "usd",
          language,
        }),
      },
    },
    {
      pass: {
        value: t("ticketExchange.update"),
        nowrap: "nowrap",
      },
      eventDate: {
        value: t("defaultFormats.date", {
          date: moment.tz(selectedEvent.start, selectedEvent.timezoneName),
        }),
      },
      event: {
        value: selectedEvent.name,
      },
      lot: {
        value: selectedLot.name,
        nowrap: "nowrap",
      },
      amount: {
        value: CurrencyFormatter.format(newRate, {
          currency: selectedLot.country.currency || "usd",
          language,
        }),
      },
    },
    transfersReversedAmount > 0 && {
      lot: {
        value: t("ticketExchange.previous"),
        textColor: "tertiary",
      },
      amount: {
        value: CurrencyFormatter.format(transfersReversedAmount * -1, {
          currency: ticket.lot.country.currency || "usd",
          language,
        }),
        textColor: "tertiary",
      },
    },
    {
      lot: {
        value: (
          <b>
            {rateDifference >= 0
              ? t("ticketExchange.total")
              : t("ticketExchange.refund")}
          </b>
        ),
      },
      amount: {
        value: (
          <b>
            {CurrencyFormatter.format(Math.abs(totalChargedOrRefunded), {
              currency: ticket.lot.country.currency || "usd",
              language,
            })}
          </b>
        ),
      },
    },
  ];

  return {
    columns,
    data,
  };
};
