"use strict";

const getNormalizedValues = (values) => {
  return {
    ...values,
    phoneNumber: values.phoneNumber.replace(/[^0-9]/g, ""),
  };
};

export default getNormalizedValues;
