import React, { useState } from "react";
import { Button, LoadingIndicator, Modal } from "@citifyd/style";
import { useTranslator } from "../../react/hooks";
import {
  useCreateAgentMutation,
  useUpdateAgentMutation,
} from "../../../graphql-pos/generated/graphql";
import PosApolloClient from "../../services/posApolloClient";
import * as yup from "yup";
import { useFormik } from "formik";
import FormikField, { FIELD_TYPE } from "../../react/components/FormikField";
import FormikProvider from "../../react/components/FormikProvider";
import { showAlert } from "../../services/helper";
import { getGraphqlError } from "../../services/graphql-helper";

const posApolloClient = PosApolloClient();

interface FormProps {
  name: string;
  pin: string;
}

export const RoverEditAgentModal = ({
  agent,
  open,
  onClose,
}: {
  agent?: { id: string; name: string; citifydOrganizationId: string };
  open: boolean;
  onClose: () => void;
}) => {
  const t = useTranslator();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [createAgent] = useCreateAgentMutation({ client: posApolloClient });
  const [updateAgent] = useUpdateAgentMutation({ client: posApolloClient });
  const initialValues: FormProps = {
    name: agent?.id ? agent.name : "",
    pin: "",
  };

  const formikbag = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      pin: yup.string().required().min(4).max(4),
    }),

    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        if (agent?.id) {
          await updateAgent({
            variables: {
              id: agent.id,
              name: values.name,
              pin: values.pin,
            },
          });
        } else {
          await createAgent({
            variables: {
              name: values.name,
              pin: values.pin,
            },
          });
        }

        onClose();
      } catch (err) {
        console.log("Error occurred", err);
        const error = getGraphqlError(err);
        showAlert(error.message);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Modal open={open} onClose={onClose}>
        <Modal.Title>{t("posRover.devices.addNewDevice")}</Modal.Title>

        <Modal.Content>
          {isSubmitting && <LoadingIndicator />}

          <FormikField
            as={FIELD_TYPE.INPUT}
            gutterBottom
            name="name"
            label={t("posRover.agents.name")}
          />

          <FormikField
            as={FIELD_TYPE.INPUT}
            gutterBottom
            min="4"
            max={4}
            name="pin"
            label={t("posRover.agents.pin")}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={onClose}
            textColor="white"
            appearance="secondary"
            fullWidth
            justifyContent="center"
            uppercase
          >
            {t("posRover.commons.cancel")}
          </Button>
          <Button
            type="submit"
            fullWidth
            justifyContent="center"
            uppercase
            onMouseDown={() => handleSubmit()}
          >
            {t("posRover.commons.assign")}
          </Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  );
};
