"use strict";

import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Select, Grid, DatePicker, Label } from "@citifyd/style";

import SearchInput from "../../../../../shared/react/components/SearchInput";
import { useTranslator } from "../../../../../shared/react/hooks";
import {
  validateStartAndEndDate,
  getFilterSelectionFromState,
  getLotsOptions,
  getLotsByParams,
  getParkingMethodList,
  updateFilters,
  onDateChange,
  onBasicSelectChange,
} from "../../../utils";

import {
  getFormattedDate,
  getSelectionObject,
} from "../../../../../shared/utils/date-time-formatting";

import CSVDownload from "../../../../../shared/react/components/CSVDownload";
import { getCurrentLanguage } from "../../../../../shared/services/languages";
import MultiplePropertiesSelector from "../../MultiplePropertiesSelector";
import styles from "./Filter.module.scss";
import { useAuthentication } from "../../../../../shared/react/contexts/authentication";
import Permissions from "../../../../../shared/services/permissions";

const TransactionsFilter = ({
  lots,
  onClickGenerateCsv,
  disabled,
  organizationsOptions,
}) => {
  const t = useTranslator();
  const { user, permissions } = useAuthentication();

  const timer = useRef(null);
  const [selection, setSelection] = useState({});
  const [selectedLots, setSelectedLots] = useState([]);
  const [errors, setErrors] = useState({});
  const [lastFourDigits, setLastFourDigits] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const [license, setLicense] = useState("");
  const language = getCurrentLanguage();

  const { errors: startAndEndDateErrors, isValid: startAndEndDateValid } =
    useMemo(() => validateStartAndEndDate({ selection }), [selection]);

  const lotsOptions = useMemo(
    () =>
      getLotsOptions({
        lots,
        t,
        showAllPropertiesOption: false,
        organizationId: selection.organizationId,
      }),
    [lots, selection.organizationId]
  );

  useEffect(() => {
    const params = getFilterSelectionFromState({ user });

    const options = getLotsOptions({
      lots,
      t,
      showAllPropertiesOption: false,
    });

    setSelectedLots(getLotsByParams({ params, lots: options }));
    setLastFourDigits(params?.lastFourDigits || "");
    setTransactionId(Number(params?.transactionId) || "");
    setPhoneNumber(params?.phoneNumber || "");
    setLicense(params?.license || "");
    setSelection(params);
  }, []);

  useEffect(() => {
    if (startAndEndDateValid && !isEmpty(selection)) {
      updateFilters({ selection, lots, user });
    }
  }, [selection, startAndEndDateValid]);

  useEffect(() => {
    setErrors({});
  }, [startAndEndDateValid]);

  useEffect(() => {
    if (startAndEndDateErrors) {
      setErrors((old) => ({
        ...old,
        ...startAndEndDateErrors,
      }));
    }
  }, [startAndEndDateErrors]);

  const onInputTextChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const isLastFourDigits = name === "lastFourDigits";
    const isLicense = name === "license";
    const isPhoneNumber = name === "phoneNumber";
    const isTransactionId = name === "transactionId";

    if (isLastFourDigits) {
      setLastFourDigits(value);
    } else if (isLicense) {
      setLicense(value);
    } else if (isPhoneNumber) {
      setPhoneNumber(value);
    } else if (isTransactionId) {
      setTransactionId(value);
    }

    if (timer) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      if (isLastFourDigits || isLicense || isPhoneNumber || isTransactionId) {
        setSelection(getSelectionObject({ name, value, selection }));
      }
    }, 500);
  };

  const generateCsvClicked = () => {
    if (startAndEndDateValid && onClickGenerateCsv) {
      onClickGenerateCsv();
    }
  };

  return (
    <Grid.Row marginBottom>
      <Grid.Col md={11} className={styles.boxFilters}>
        <Grid.Row>
          {Permissions.userMaySeeParkingOperatorFilter(permissions) && (
            <Grid.Col>
              <Select
                fullWidth
                labelSize="small"
                label={t("reports.commonFilter.fields.parkingOperator")}
                options={organizationsOptions}
                value={selection.organizationId ?? "*"}
                onChange={(e) => {
                  const organizationId = e.target.value;

                  setSelection({ ...selection, organizationId, lots: null });

                  let filteredLots;

                  if (organizationId === "*") {
                    filteredLots = lots;
                  } else {
                    filteredLots = lots.filter(
                      (lot) => lot.organizationId === organizationId
                    );
                  }

                  setSelectedLots([
                    {
                      value: "*",
                      label: t("reports.commonFilter.fields.allOrganizations"),
                    },
                    ...filteredLots.map((lot) => ({
                      value: lot.id,
                      label: lot.name,
                    })),
                  ]);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col className={styles.column} sm={12} md={3}>
            <MultiplePropertiesSelector
              selection={selection}
              selectedLots={selectedLots}
              options={lotsOptions}
              setSelection={setSelection}
              setSelectedLots={setSelectedLots}
            />
          </Grid.Col>
          <Grid.Col className={styles.column} sm={12} md={3}>
            <Label variant="small">
              {t("reports.commonFilter.fields.startDate")}
            </Label>
            <Grid.Row gutterWidth={4}>
              <Grid.Col>
                <DatePicker
                  locale={language}
                  onChange={(date) =>
                    setSelection(
                      onDateChange({ field: "startDate", date, selection })
                    )
                  }
                  selectedDate={getFormattedDate(selection.startDate)}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col className={styles.column} sm={12} md={3}>
            <Label variant="small">
              {t("reports.commonFilter.fields.endDate")}
            </Label>
            <Grid.Row gutterWidth={4}>
              <Grid.Col>
                <DatePicker
                  locale={language}
                  onChange={(date) =>
                    setSelection(
                      onDateChange({ field: "endDate", date, selection })
                    )
                  }
                  selectedDate={getFormattedDate(selection.endDate)}
                  error={errors["endDate"]}
                  errorMessage={t(errors["endDate"])}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>

          <Grid.Col className={styles.column} sm={12} md={3}>
            <Select
              fullWidth
              name="parkingMethod"
              labelSize="small"
              label={t("reports.commonFilter.fields.reservationType")}
              options={getParkingMethodList()}
              value={selection.parkingMethod || ""}
              onChange={(e) =>
                setSelection(onBasicSelectChange({ e, selection }))
              }
            />
          </Grid.Col>
          <Grid.Col className={styles.column} sm={12} md={3}>
            <SearchInput
              fullWidth
              name="transactionId"
              labelSize="small"
              label={t("reports.transactionsFilter.fields.transactionId")}
              value={transactionId}
              onChange={(e) => onInputTextChange(e)}
            />
          </Grid.Col>
          <Grid.Col className={styles.column} sm={12} md={3}>
            <SearchInput
              fullWidth
              name="lastFourDigits"
              labelSize="small"
              label={t("reports.transactionsFilter.fields.lastFourDigits")}
              value={lastFourDigits}
              onChange={(e) => onInputTextChange(e)}
            />
          </Grid.Col>
          <Grid.Col
            className={classNames(styles.column, styles.licensePlateColumn)}
            sm={12}
            md={3}
          >
            <SearchInput
              fullWidth
              name="license"
              labelSize="small"
              label={t("reports.transactionsFilter.fields.licensePlate.label")}
              value={license}
              onChange={(e) => onInputTextChange(e)}
            />
          </Grid.Col>

          <Grid.Col className={styles.column} sm={12} md={3}>
            <SearchInput
              fullWidth
              name="phoneNumber"
              labelSize="small"
              label={t("reports.transactionsFilter.fields.phoneNumber")}
              value={phoneNumber}
              onChange={(e) => onInputTextChange(e)}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
      <Grid.Col sm={12} md={1} className={styles.boxCSV}>
        <CSVDownload
          disabled={errors["endDate"] || disabled}
          onClick={() => generateCsvClicked()}
        />
      </Grid.Col>
    </Grid.Row>
  );
};

export default TransactionsFilter;
