"use strict";

import React, { useState } from "react";
import { Grid } from "@citifyd/style";

import RouterLinkButton from "../../../../shared/react/components/RouterLinkButton";
import Permissions from "../../../../shared/services/permissions";
import ButtonTab from "../../../../shared/react/components/ButtonTab";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import Table from "../../../../shared/react/components/Table";
import styles from "./UserTicketsStats.module.scss";
import { getData, getEventData, getPassesByEvent } from "./utils";
import UserChart from "../UserChart";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import classNames from "classnames";
import { getUserTicketsByEvent } from "../../../../shared/api";

import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const UserTicketsStats = ({ user }) => {
  const t = useTranslator();
  const { user: loggedUser } = useAuthentication();
  const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedPassesByEvent, setLoadedPassesByEvent] = useState(false);
  const [eventsData, setEventsData] = useState({});

  const shouldShowPassesByEventTab = Boolean(loggedUser.organizationId);

  const {
    passesByStatus,
    passesByStatusColumns,
    passesByStatusData,
    redeemedPasses,
    redeemedPassesColumns,
    redeemedPassesData,
  } = getData({ user });

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <TicketsStats
            passes={passesByStatus.chartStyles}
            columns={passesByStatusColumns}
            data={passesByStatusData}
            items={[
              "purchased",
              "expired",
              "cancelled",
              "redeemed",
              "transferred",
              "parked",
            ]}
          />
        );
      case 2:
        return (
          <TicketsStats
            passes={eventsData.passesByEvent?.chartStyles}
            columns={eventsData.columns}
            data={eventsData.data}
            items={getPassesByEvent(eventsData.passesByEvent?.values)}
            withClass={false}
          />
        );
      case 3:
        return (
          <TicketsStats
            passes={redeemedPasses.chartStyles}
            columns={redeemedPassesColumns}
            data={redeemedPassesData}
            items={["expired", "parked", "future"]}
          />
        );
    }
  };

  const isActive = (tab) => tab === activeTab;

  const handleEventsTab = async () => {
    setActiveTab(2);

    if (loadedPassesByEvent || isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await getUserTicketsByEvent(user.id);
      const data = getEventData({ user, response });

      setEventsData(data);
    } catch (err) {
    } finally {
      setLoadedPassesByEvent(true);
      setIsLoading(false);
    }
  };

  return (
    <div
      className={classNames({
        [styles.wrapper]: user.tickets.length,
      })}
    >
      <ButtonTab
        isActive={isActive(1)}
        onClick={() => setActiveTab(1)}
        label={t("user.passesStatus")}
        className={styles.button}
      />
      {shouldShowPassesByEventTab && (
        <ButtonTab
          isActive={isActive(2)}
          onClick={() => handleEventsTab()}
          label={t("user.passesEvent")}
        />
      )}
      <ButtonTab
        isActive={isActive(3)}
        onClick={() => setActiveTab(3)}
        label={t("user.reeemedPasses")}
      />
      {isLoading ? (
        <LoadingManager isLoading={isLoading} />
      ) : (
        renderTabContent()
      )}
    </div>
  );
};

const TicketsStats = ({ columns, data, passes, items, withClass = true }) => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const { permissions } = useAuthentication();
  const { phoneCountryCode, phoneNumber, id: userId } = $stateParams;

  const shouldShowPurchaseTicketButton =
    Permissions.userMayCreateAssistedPurchases(permissions);

  const nonCustomer = phoneCountryCode && phoneNumber;
  const userParams = nonCustomer
    ? {
        id: userId,
        phoneCountryCode,
        phoneNumber,
      }
    : { id: userId };

  return (
    <Grid.Row gutterBottom>
      <Grid.Col sm={12} lg={6}>
        <Table
          gutterBottom
          borderBottom
          cleanMode
          data={data}
          columns={columns}
        />
        {shouldShowPurchaseTicketButton && (
          <RouterLinkButton
            size="small"
            uppercase
            shadow
            extraPadding
            state={
              nonCustomer
                ? "non-customer-purchase-ticket"
                : "user-purchase-ticket"
            }
            params={userParams}
          >
            {t("user.purchasePass")}
          </RouterLinkButton>
        )}
      </Grid.Col>
      <Grid.Col sm={12} lg={6}>
        <UserChart items={items} passes={passes} withClass={withClass} />
      </Grid.Col>
    </Grid.Row>
  );
};

export default UserTicketsStats;
