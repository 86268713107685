"use strict";

import React, { useMemo } from "react";
import { sum } from "lodash";
import moment from "moment-timezone";
import { Text } from "@citifyd/style";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import Table from "../../../../shared/react/components/Table";
import Permissions from "../../../../shared/services/permissions";
import { formatCurrency } from "../../utils";
import { useTableColumns } from "./utils";
import styles from "./Bundles.module.scss";

const Bundles = ({ bundlePurchases }) => {
  const t = useTranslator();
  const columns = useTableColumns();
  const $state = useService("$state");

  const userMayOpenBundlePurchasePage =
    Permissions.userMayOpenBundlePurchasePage();

  const formattedData = useMemo(() => {
    let bundlesList = bundlePurchases.map((bundlePurchase) => {
      return {
        ...(userMayOpenBundlePurchasePage && {
          onClick: () =>
            $state.go(
              "bundle-purchase",
              { id: bundlePurchase.id },
              { enableBackLink: true }
            ),
        }),
        name: bundlePurchase.user?.name,
        lotName: bundlePurchase.bundle?.lot?.name,
        time: t("defaultFormats.shortDatetime", {
          date: moment.tz(
            bundlePurchase.bundle?.createdAt,
            bundlePurchase.bundle?.lot?.timezoneName
          ),
        }),
        bundleName: bundlePurchase.bundle?.name,
        revenueAmount: {
          value: formatCurrency(bundlePurchase.netRevenueAmount),
          textAlign: "right",
          textColor:
            bundlePurchase.netRevenueAmount < 0 ? "tertiary" : "default",
        },
      };
    });

    bundlesList = [
      ...bundlesList,
      {
        name: <b>{t("dailyRevenue.total")}</b>,
        lotName: null,
        time: null,
        bundleName: null,
        revenueAmount: {
          value: (
            <b>{formatCurrency(sum(bundlePurchases, "netRevenueAmount"))}</b>
          ),
          textAlign: "right",
        },
      },
    ];

    return bundlesList;
  }, [bundlePurchases]);

  return (
    <>
      <div className={styles.revenueContent}>
        <Text gutterBottom variant="h3">
          {t("dailyRevenue.bundles")}
        </Text>

        <Table
          className={styles.table}
          data={formattedData}
          columns={columns}
          striped
          borderBottom
          clickable
        />
      </div>
    </>
  );
};

export default Bundles;
