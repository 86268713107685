"use strict";

import React, { useEffect, useState, useRef, useMemo } from "react";
import { Grid, Text, Button } from "@citifyd/style";
import Table from "../../../../../shared/react/components/Table";
import LoadingManager from "../../../../../shared/react/components/LoadingManager";
import PageHeader from "../../../../../shared/react/components/PageHeader";
import SearchInput from "../../../../../shared/react/components/SearchInput";
import { useTranslator } from "../../../../../shared/react/hooks";
import {
  loadGateInfo,
  getSystemGateData,
  getBeaconGateData,
  getUpdatedEntries,
  getEntriesCount,
  updateSelectedGates,
  getEntriesWithChangedGates,
} from "./utils";
import styles from "./Gate.module.scss";
import { useAuthentication } from "../../../../../shared/react/contexts/authentication";

const Gate = ({ setSelectedEventGate, selectedEventGate, event }) => {
  const t = useTranslator();
  const { permissions } = useAuthentication();
  const searchTimer = useRef(null);
  const timer = useRef(null);
  const [search, setSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [serverData, setServerData] = useState();
  const [updatingSelectedGates, setUpdatingSelectedGates] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [lastUpdate, setLastUpdate] = useState();
  const [entries, setEntries] = useState([]);
  const hasEntriesWithChangedGates = useMemo(
    () => Boolean(getEntriesWithChangedGates(entries)?.length),
    [entries]
  );

  const { columns: systemGatesColumns, data: systemGatesData } = useMemo(
    () =>
      getSystemGateData({
        entries,
        selectedEventGate,
        event,
        search,
        permissions,
        onGateChange: ({ entryId, gateId }) =>
          onGateChange({ entryId, gateId }),
      }),
    [entries, selectedEventGate, event, search]
  );
  const { columns: beaconGatesColumns, data: beaconGatesData } = useMemo(
    () =>
      getBeaconGateData({
        entries,
        event,
        search,
        permissions,
      }),
    [entries, event, search]
  );

  const onGateChange = ({ entryId, gateId }) => {
    const updatedEntries = entries.map((item) => ({
      ...item,
      ...(item.id === entryId && { changeEventGate: gateId || null }),
    }));

    setEntries(updatedEntries);
  };

  const onSearchInputChange = (e) => {
    const search = e.target?.value;
    clearInterval(timer?.current);

    if (searchTimer) {
      clearTimeout(searchTimer.current);
    }

    searchTimer.current = setTimeout(() => {
      setSearch(search);
    }, 500);
  };

  const setGateInfoInterval = () => {
    clearInterval(timer?.current);
    timer.current = setInterval(() => {
      fetcheGateInfo({ entries, lastUpdate, selectedEventGate });
    }, 20000);
  };

  const onUpdateSelectedGatesButtonClick = async () => {
    setUpdatingSelectedGates(true);

    updateSelectedGates(entries)
      .then(() => {
        fetcheGateInfo({ lastUpdate, selectedEventGate, reset: true });
      })
      .catch((err) => {
        setHasError(err);
      });
  };

  const fetcheGateInfo = async ({
    entries,
    lastUpdate,
    selectedEventGate,
    reset,
  }) => {
    if (reset) {
      setLastUpdate();
      setEntries([]);
      setIsLoading(false);
    }

    try {
      const gateInfo = await loadGateInfo({ lastUpdate, selectedEventGate });
      const updatedEntries = getUpdatedEntries({
        currentEntries: reset ? [] : entries,
        newEntries: gateInfo.entries,
      });

      setServerData(gateInfo);
      setLastUpdate(gateInfo.lastUpdate);
      setEntries(updatedEntries);
    } catch (err) {
      setHasError(err);
    } finally {
      setIsLoading(false);
      setUpdatingSelectedGates(false);
    }
  };

  useEffect(() => {
    setGateInfoInterval();
  }, [entries]);

  useEffect(() => {
    fetcheGateInfo({ lastUpdate, selectedEventGate });

    return () => {
      if (timer) {
        clearInterval(timer.current);
      }
    };
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  return (
    <div>
      <Grid.Row justify="space-between">
        <Grid.Col sm={12} md={8} lg={9}>
          <PageHeader.Title
            className={styles.gateTitle}
            onClick={() => setSelectedEventGate(null)}
            weight="300"
            title={t("event.gatePage.title", {
              lotName: selectedEventGate.lot.name,
              gateName: selectedEventGate.name,
            })}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={4} lg={3}>
          <SearchInput
            id="search"
            onChange={(e) => onSearchInputChange(e)}
            fullWidth
          />
        </Grid.Col>
      </Grid.Row>

      {/* System gate (e.g. GPS Entry) */}
      {selectedEventGate.gate?.isSystemGate && (
        <div>
          {updatingSelectedGates && (
            <LoadingManager
              loadingMessage={t("event.gatePage.updatingGates")}
              isLoading={updatingSelectedGates}
            />
          )}
          {!updatingSelectedGates &&
            getEntriesCount({ type: "Citifyd", serverData }) !== "" && (
              <>
                <div className={styles.systemGateHeader}>
                  <Text variant="subtitle">
                    {t("event.gateEntriesCount", {
                      count: getEntriesCount({ type: "Citifyd", serverData }),
                    })}
                  </Text>
                  {entries?.length > 0 && (
                    <Button
                      size="small"
                      disabled={!hasEntriesWithChangedGates}
                      uppercase
                      onClick={() => onUpdateSelectedGatesButtonClick()}
                    >
                      {t("event.update")}
                    </Button>
                  )}
                </div>
                {systemGatesData?.length > 0 && (
                  <Table
                    columns={systemGatesColumns}
                    data={systemGatesData}
                    striped
                  />
                )}
              </>
            )}
        </div>
      )}

      {/* Normal gate (beacons)  */}
      {!selectedEventGate.gate?.isSystemGate && (
        <div>
          {(getEntriesCount({ type: "Citifyd", serverData }) !== "" ||
            getEntriesCount({ type: "Ticketmaster", serverData }) !== "") && (
            <>
              <div className={styles.systemGateHeader}>
                <Text variant="subtitle">
                  {t("event.gateEntriesCount", {
                    count:
                      getEntriesCount({ type: "Citifyd", serverData }) +
                      getEntriesCount({ type: "Ticketmaster", serverData }),
                  })}
                </Text>
              </div>
              {beaconGatesData?.length > 0 && (
                <Table
                  columns={beaconGatesColumns}
                  data={beaconGatesData}
                  striped
                  responsive
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Gate;
