"use strict";

import React from "react";
import styles from "./AllPropertiesHeader.module.scss";
import { useTranslator } from "../../../../../shared/react/hooks";
import { Button } from "@citifyd/style";
import SearchInput from "../../../../../shared/react/components/SearchInput";
import PageHeader from "../../../../../shared/react/components/PageHeader";

const AllPropertiesHeader = ({
  filterApproved,
  filterVisible,
  onClickFilterApproved,
  onClickFilterVisible,
  setSearch,
}) => {
  const t = useTranslator();

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <PageHeader>
      <PageHeader.Title weight="300" title={t("allProperties.allProperties")} />
      <PageHeader.Actions>
        <Button
          onClick={onClickFilterApproved}
          appearance={filterApproved ? "primary" : "secondary"}
          size="small"
          letterSpacing
          uppercase
        >
          {t("allProperties.approved")}
        </Button>
        <Button
          onClick={onClickFilterVisible}
          appearance={filterVisible ? "primary" : "secondary"}
          size="small"
          letterSpacing
          uppercase
        >
          {t("allProperties.visible")}
        </Button>
        <div className={styles.searchField}>
          <SearchInput onChange={handleChangeSearch} />
        </div>
      </PageHeader.Actions>
    </PageHeader>
  );
};
export default AllPropertiesHeader;
