import { HttpLink } from "apollo-link-http";
import fetch from "unfetch";
import { setContext } from "apollo-link-context";
import { from } from "apollo-link";
import { onError } from "apollo-link-error";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import possibleTypes from "../data/possibleTypes.json";
import { generateAuthenticationHeaders } from "../api";

export { NetworkStatus } from "@apollo/client";

export default () => {
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    fetch,
  });

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        ...generateAuthenticationHeaders(),
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, extensions }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Code: ${extensions?.code}`
        )
      );
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
    if (response) console.log(`[response error]: ${response}`);
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes,
    }),
    link: from([authLink, errorLink, httpLink]),
  });
};
