"use strict";

import { createBrowserHistory } from "history";
import _ from "lodash";

export const history = createBrowserHistory();

export const routes = {
  dashboard: {
    url: "/",
    page: require("./pages/dashboard/dashboard"),
  },
  properties: {
    url: "/properties",
    page: require("./pages/properties/properties"),
  },
  account: {
    url: "/account",
    page: require("./pages/account/account"),
  },
  "add-property": {
    url: "/properties/new",
    title: "i18n:addProperty.pageTitle",
    page: require("./pages/add-property/add-property"),
  },

  "edit-property": {
    url: "/properties/:lotId",
    title: "i18n:addProperty.editPropertyPageTitle",
    page: require("./pages/add-property/add-property"),
  },
  "edit-property-step": {
    url: "/properties/:lotId/step/:step",
    title: "i18n:addProperty.editPropertyPageTitle",
    page: require("./pages/add-property/add-property"),
  },
  "edit-property-advanced-rates-lot-setup": {
    url: "/properties/:lotId/step/3/advanced-rates/:advancedRateDefinitionId",
    page: require("./pages/edit-property-advanced-rates/edit-property-advanced-rates"),
  },
  "edit-property-advanced-rates": {
    url: "/schedule/properties/:lotId/advanced-rates/:advancedRateDefinitionId",
    page: require("./pages/edit-property-advanced-rates/edit-property-advanced-rates"),
  },
  "list-property-advanced-rates": {
    url: "/schedule/properties/:lotId/advanced-rates",
    page: require("./pages/list-property-advanced-rates/list-property-advanced-rates"),
  },
  "revenue-on-date": {
    url: "/reports/daily/:date",
    page: require("./pages/revenue-on-date/revenue-on-date"),
  },
  "revenue-of-event": {
    url: "/reports/events/:event",
    page: require("./pages/revenue-of-event/revenue-of-event"),
  },
  payout: {
    url: "/reports/payouts/:payout",
    page: require("./pages/payout/payout"),
  },
  schedule: {
    url: "/schedule",
    reloadOnSearch: false,
    page: require("./pages/schedule/schedule"),
  },
  reports: {
    url: "/reports",
    page: require("./pages/reports"),
  },
  events: {
    url: "/events",
    redirect: "/schedule",
  },
  rover: {
    url: "/rover",
    page: require("./pages/pos-rover"),
  },
  "add-event": {
    url: "/events/new",
    title: "i18n:addEvent.pageTitle",
    page: require("./pages/add-event/add-event"),
  },
  "edit-event": {
    url: "/events/:eventId/edit",
    title: "i18n:addEvent.editEventPageTitle",
    page: require("./pages/add-event/add-event"),
  },
  "edit-event-properties": {
    url: "/events/:eventId/properties",
    page: require("./pages/edit-event-properties/edit-event-properties"),
  },
  event: {
    url: "/events/:id",
    page: require("./pages/event/event"),
  },
  "appendable-events": {
    url: "/append-to-event",
    page: require("./pages/appendable-events/appendable-events"),
  },
  users: {
    url: "/users",
    page: require("./pages/users/users"),
  },
  "non-customer-user": {
    url: "/users/:id-:phoneCountryCode-:phoneNumber",
    page: require("./pages/user/user"),
  },
  "invite-parkers": {
    url: "/users/new",
    page: require("./pages/invite-parkers/invite-parkers"),
  },
  user: {
    url: "/users/:id",
    page: require("./pages/user/user"),
  },
  "user-purchase-ticket": {
    url: "/users/:id/purchase-ticket",
    page: require("./pages/user-purchase-ticket/user-purchase-ticket"),
  },
  "non-customer-purchase-ticket": {
    url: "/users/:id-:phoneCountryCode-:phoneNumber/purchase-ticket",
    page: require("./pages/user-purchase-ticket/user-purchase-ticket"),
  },
  ticket: {
    url: "/tickets/:id",
    page: require("./pages/ticket/ticket"),
  },
  reservation: {
    url: "/reservations/:id",
    page: require("./pages/reservation/reservation"),
  },
  "reservation-refund": {
    url: "/reservations/:id/refund",
    page: require("./pages/reservation-refund/reservation-refund"),
  },
  subscription: {
    url: "/subscriptions/:id",
    page: require("./pages/subscription/subscription"),
  },
  "subscription-refund": {
    url: "/subscriptions/:id/refund",
    page: require("./pages/subscription-refund/subscription-refund"),
  },
  "reserved-parking-pass": {
    url: "/reserved-parking-passes/:id",
    page: require("./pages/reserved-parking-pass/reserved-parking-pass"),
  },
  "reserved-parking-pass-refund": {
    url: "/reserved-parking-passes/:id/refund",
    page: require("./pages/reserved-parking-pass-refund/reserved-parking-pass-refund"),
  },
  "ticket-transfer": {
    url: "/tickets/:id/transfer",
    page: require("./pages/ticket-transfer/ticket-transfer"),
  },
  "ticket-exchange": {
    url: "/tickets/:id/exchange",
    page: require("./pages/ticket-exchange/ticket-exchange"),
  },
  "ticket-refund": {
    url: "/tickets/:id/refund",
    page: require("./pages/ticket-refund/ticket-refund"),
  },
  "bundle-purchase": {
    url: "/bundle-purchases/:id",
    page: require("./pages/bundle-purchase/bundle-purchase"),
  },
  register: {
    url: "/register",
    page: require("./pages/register/register"),
  },
  login: {
    url: "/login",
    page: require("./pages/login/login"),
  },
  "signup-from-invitation": {
    url: "/signup/:code",
    page: require("./pages/signup-from-invitation/signup-from-invitation"),
  },
  "all-properties": {
    url: "/all-properties",
    page: require("./pages/all-properties/all-properties"),
  },
  organization: {
    url: "/organizations/:id",
    page: require("./pages/organization/organization"),
  },
  "referral-codes": {
    url: "/referral-codes",
    page: require("./pages/referral-codes/referral-codes"),
  },
  "my-account": {
    url: "/my-account",
    page: require("./pages/my-account/my-account"),
  },
  "account-permissions": {
    url: "/account-permissions",
    page: require("./pages/account-permissions/account-permissions"),
  },

  "add-management-permission": {
    url: "account-permissions/management/new",
    page: require("./pages/add-management-permission/add-management-permission"),
  },
  "management-permission-edit": {
    url: "account-permissions/management/:id/edit",
    page: require("./pages/add-management-permission/add-management-permission"),
  },

  "add-attendant-monitoring-company": {
    url: "account-permissions/attendant-monitoring-company/new",
    page: require("./pages/add-attendant-monitoring-company/add-attendant-monitoring-company"),
  },

  "attendant-monitoring-company-edit": {
    url: "account-permissions/attendant-monitoring-company/:id/edit",
    page: require("./pages/add-attendant-monitoring-company/add-attendant-monitoring-company"),
  },

  "stripe-account-setup": {
    url: "/stripe-account-setup",
    page: require("./pages/stripe-account-setup/stripe-account-setup"),
  },
  "stripe-account-setup-result": {
    url: "/stripe-account-setup/result?status",
    page: require("./pages/stripe-account-setup-result/stripe-account-setup-result"),
  },
  permits: {
    url: "/permits",
    page: require("./pages/permits/permits"),
  },
  "add-permit": {
    url: "/permits/new",
    page: require("./pages/add-permit/add-permit"),
  },
  "edit-permit": {
    url: "/permits/edit/:permitId",
    page: require("./pages/add-permit/add-permit"),
  },
  permit: {
    url: "/permit/:permitId",
    page: require("./pages/permit/permit"),
  },
  permitters: {
    url: "/permitters",
    page: require("./pages/permitters/permitters"),
  },
  "edit-permitters": {
    url: "/permitters/:id/edit",
    page: require("./pages/permitters-edit-user/permitters-edit-user"),
  },
  "add-permitters": {
    url: "/permitters/new",
    page: require("./pages/permitters-edit-user/permitters-edit-user"),
  },
  "permitters-portal-settings": {
    url: "/permitters/settings",
    page: require("./pages/permitters-portal-settings/permitters-portal-settings"),
  },
  "permitters-portal-settings-add": {
    url: "/permitters/settings/new",
    page: require("./pages/permitters-portal-settings-add/permitters-portal-settings-add"),
  },
  "permitters-portal-settings-edit": {
    url: "/permitters/settings/:id/edit",
    page: require("./pages/permitters-portal-settings-add/permitters-portal-settings-add"),
  },
  "user-permitters": {
    url: "/permitters/:id",
    page: require("./pages/permitters-user/permitters-user"),
  },
  venues: {
    url: "/venues",
    page: require("./pages/venues"),
  },
  "add-venue": {
    url: "/venues/new",
    page: require("./pages/add-venue/add-venue"),
  },
  "edit-venue": {
    url: "/venues/:venueId",
    title: "Edit Venue",
    page: require("./pages/add-venue/add-venue"),
  },
};
