"use strict";

import React, { useState, useEffect, useMemo } from "react";
import { Select, Grid, DatePicker, Label } from "@citifyd/style";
import { isEmpty } from "lodash";
import { useTranslator } from "../../../../../shared/react/hooks";
import {
  validateStartAndEndDate,
  getFilterSelectionFromState,
  getLotsOptions,
  getLotsByParams,
  updateFilters,
  onDateChange,
} from "../../../utils";

import { getFormattedDate } from "../../../../../shared/utils/date-time-formatting";
import { getCurrentLanguage } from "../../../../../shared/services/languages";
import MultiplePropertiesSelector from "../../MultiplePropertiesSelector";
import styles from "./Filter.module.scss";
import { useAuthentication } from "../../../../../shared/react/contexts/authentication";
import CSVDownload from "../../../../../shared/react/components/CSVDownload";
import PDFDownload from "../../../../../shared/react/components/PDFDownload";
import Permissions from "../../../../../shared/services/permissions";

const SummaryFilter = ({
  lots,
  organizationsOptions,
  onClickGenerateCsv,
  onClickGeneratePdf,
  revenueByPropertyReport,
}) => {
  const t = useTranslator();
  const { user, permissions } = useAuthentication();

  const [selection, setSelection] = useState({});
  const [selectedLots, setSelectedLots] = useState([]);

  const [errors, setErrors] = useState({});

  const language = getCurrentLanguage();
  const { errors: startAndEndDateErrors, isValid: startAndEndDateValid } =
    useMemo(() => validateStartAndEndDate({ selection }), [selection]);

  const lotsOptions = useMemo(
    () =>
      getLotsOptions({
        lots,
        t,
        showAllPropertiesOption: false,
        organizationId: selection.organizationId,
      }),
    [lots, selection.organizationId]
  );

  useEffect(() => {
    const params = getFilterSelectionFromState({ user });
    const options = getLotsOptions({
      lots,
      t,
      showAllPropertiesOption: false,
    });

    setSelectedLots(getLotsByParams({ params, lots: options }));
    setSelection({
      ...params,
    });
  }, []);

  useEffect(() => {
    if (startAndEndDateValid && !isEmpty(selection)) {
      updateFilters({ selection });
    }
  }, [selection, startAndEndDateValid]);

  useEffect(() => {
    setErrors({});
  }, [startAndEndDateValid]);

  useEffect(() => {
    if (startAndEndDateErrors) {
      setErrors((old) => ({
        ...old,
        ...startAndEndDateErrors,
      }));
    }
  }, [startAndEndDateErrors]);

  return (
    <Grid.Row marginBottom>
      <Grid.Col md={11} className={styles.boxFilters}>
        <Grid.Row>
          {Permissions.userMaySeeParkingOperatorFilter(permissions) && (
            <Grid.Col sm={12} md={3}>
              <Select
                fullWidth
                labelSize="small"
                label={t("reports.commonFilter.fields.parkingOperator")}
                options={organizationsOptions}
                value={selection.organizationId ?? "*"}
                onChange={(e) => {
                  const organizationId = e.target.value;

                  setSelection({ ...selection, organizationId, lots: null });

                  let filteredLots;

                  if (organizationId === "*") {
                    filteredLots = lots;
                  } else {
                    filteredLots = lots.filter(
                      (lot) => lot.organizationId === organizationId
                    );
                  }

                  setSelectedLots([
                    {
                      value: "*",
                      label: t("reports.commonFilter.fields.allOrganizations"),
                    },
                    ...filteredLots.map((lot) => ({
                      value: lot.id,
                      label: lot.name,
                    })),
                  ]);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col className={styles.column} sm={12} md={3}>
            <MultiplePropertiesSelector
              selection={selection}
              selectedLots={selectedLots}
              options={lotsOptions}
              setSelection={setSelection}
              setSelectedLots={setSelectedLots}
            />
          </Grid.Col>

          <Grid.Col className={styles.column} sm={12} md={3}>
            <Label variant="small">
              {t("reports.commonFilter.fields.startDate")}
            </Label>
            <Grid.Row gutterWidth={4}>
              <Grid.Col>
                <DatePicker
                  locale={language}
                  maxDate={new Date()}
                  onChange={(date) =>
                    setSelection(
                      onDateChange({ field: "startDate", date, selection })
                    )
                  }
                  selectedDate={getFormattedDate(selection.startDate)}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col className={styles.column} sm={12} md={3}>
            <Label variant="small">
              {t("reports.commonFilter.fields.endDate")}
            </Label>
            <Grid.Row gutterWidth={4}>
              <Grid.Col>
                <DatePicker
                  locale={language}
                  maxDate={new Date()}
                  onChange={(date) =>
                    setSelection(
                      onDateChange({ field: "endDate", date, selection })
                    )
                  }
                  selectedDate={getFormattedDate(selection.endDate)}
                  error={errors["endDate"]}
                  errorMessage={t(errors["endDate"])}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
      <Grid.Col sm={12} md={1}>
        <div className={styles.download}>
          <div className={styles.csv}>
            <CSVDownload
              onClick={onClickGenerateCsv}
              disabled={revenueByPropertyReport?.length === 0}
            />
          </div>

          <PDFDownload
            onClick={onClickGeneratePdf}
            disabled={revenueByPropertyReport?.length === 0}
          />
        </div>
      </Grid.Col>
    </Grid.Row>
  );
};

export default SummaryFilter;
