import { saveIntercomUserData } from "../../services/intercom";

export const updateIntercom = ({ loggedUser, organization }) => {
  const user = {
    ...loggedUser,
    organization: {
      ...loggedUser.organization,
      name: organization.name,
    },
  };

  saveIntercomUserData(user);
};
