"use strict";

import React, { useState, useEffect } from "react";
import { Text, Grid } from "@citifyd/style";
import Header from "./components/Header.jsx";
import UserInfo from "./components/UserInfo/UserInfo.jsx";
import EventSelection from "./components/EventSelection/EventSelection.jsx";
import LotSelection from "./components/LotSelection/LotSelection.jsx";
import Confirmation from "./components/Confirmation/Confirmation.jsx";
import Result from "./components/Result/Result.jsx";
import { useService, useTranslator } from "../../shared/react/hooks.js";
import styles from "./InviteParkers.module.scss";
import { useAuthentication } from "../../shared/react/contexts/authentication.js";

const InviteParkers = () => {
  const t = useTranslator();
  const [passGiver, setPassGiver] = useState();
  const $state = useService("$state");

  const { user } = useAuthentication();

  const [steps, setSteps] = useState({
    step: "user_info",
    data: {},
  });

  useEffect(() => {
    if (user.organization) {
      setPassGiver(user.organization.name);
    } else if (user.isAdmin) {
      // not a permission
      setPassGiver("Citifyd");
    } else {
      $state.go("dashboard", {}, { location: "replace" });
    }
  }, []);

  return (
    <Grid>
      {steps.step === "user_info" && (
        <>
          <Header text={t("inviteParkers.accountInfo.title")} />
          <div className={styles.description}>
            <Text gutterBottom variant="subtitle">
              {t("inviteParkers.accountInfo.description")}
            </Text>
          </div>
          <UserInfo steps={steps} setSteps={setSteps} />
        </>
      )}
      {steps.step === "event_selection" && (
        <>
          <Header
            text={t("inviteParkers.eventSelection.title")}
            onClick={() => setSteps({ ...steps, step: "user_info" })}
          />
          <EventSelection steps={steps} setSteps={setSteps} />
        </>
      )}
      {steps.step === "lot_selection" && (
        <>
          <Header
            text={t("inviteParkers.lotSelection.title")}
            onClick={() => setSteps({ ...steps, step: "event_selection" })}
          />
          <LotSelection
            steps={steps}
            setSteps={setSteps}
            passGiver={passGiver}
          />
        </>
      )}
      {steps.step === "confirmation" && (
        <Confirmation steps={steps} setSteps={setSteps} passGiver={passGiver} />
      )}
      {steps.step === "result" && <Result />}
    </Grid>
  );
};

export default InviteParkers;
