"use strict";

import React from "react";
import { Button } from "@citifyd/style";

import StickyFooter from "../../../../shared/react/components/StickyFooter";
import { useTranslator } from "../../../../shared/react/hooks";

const Footer = ({ handleSubmit }) => {
  const t = useTranslator();
  return (
    <StickyFooter justifyContent="flex-end">
      <Button uppercase onMouseDown={handleSubmit} extraPadding>
        {t("commonButtons.continue")}
      </Button>
    </StickyFooter>
  );
};

export default Footer;
