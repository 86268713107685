"use strict";

import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Grid } from "@citifyd/style";

import { useService, useTranslator } from "../../shared/react/hooks";
import PageHeader from "../../shared/react/components/PageHeader";
import LoadingManager from "../../shared/react/components/LoadingManager";
import useLoadTracking from "../../shared/react/hooks/useLoadTracking";
import { findProperty } from "../../shared/api";
import usePrevious from "../../shared/react/hooks/usePrevious";

import AMENITIES from "../../graphql/amenities/queries/Amenities";
import ApolloClient from "../../shared/services/apolloClient";

import Basics from "./components/Basics";
import ParkingConfiguration from "./components/ParkingConfiguration";
import RateSettings from "./components/RateSettings";
import MonthlyParking from "./components/MonthlyParking";
import RateGates from "./components/RateGates";
import { setPageTitle } from "../../shared/services/helper";

import { getAmountOfSteps } from "../../shared/services/lot-setup";
import Permissions from "../../shared/services/permissions";
import { useAuthentication } from "../../shared/react/contexts/authentication";

const apolloClient = ApolloClient();

const Page = () => {
  const t = useTranslator();
  const { permissions } = useAuthentication();

  const $stateParams = useService("$stateParams");
  const canConfigureGates = Permissions.userMayConfigureGates(permissions);
  const amountOfSteps = getAmountOfSteps(permissions);

  const mode = $stateParams.lotId ? "edit" : "add";
  const [step, setStep] = useState(
    mode === "add" ? 1 : parseInt($stateParams.step) || 1
  );
  const [amenities, setAmenities] = useState([]);
  const [lot, setLot] = useState({});
  const [hasError, setHasError] = useState(false);
  const prevStep = usePrevious($stateParams.step);
  const prevLotId = usePrevious($stateParams.lotId);

  useEffect(() => {
    if (prevStep !== $stateParams?.step) {
      if ($stateParams.step) {
        setStep(parseInt($stateParams.step));
      } else {
        setStep(1);
      }
    }
  }, [$stateParams, prevStep]);

  const { isLoading, loadingMessage, trackLoading, untrackLoading } =
    useLoadTracking({
      property: { message: t("addProperty.loading") },
    });

  const fetchData = async () => {
    try {
      trackLoading("property");

      const response = await findProperty($stateParams.lotId);

      setLot(response);

      setPageTitle(
        t("addProperty.editPropertyPageTitleName", {
          name: response.data.lot.name,
        })
      );
    } catch (err) {
      setHasError(true);
    } finally {
      untrackLoading("property");
    }
  };

  useEffect(() => {
    if (mode == "edit" && prevLotId !== $stateParams.lotId) {
      fetchData();
    }
  }, [$stateParams.lotId]);

  useEffect(() => {
    const loadAmenities = async () => {
      trackLoading("amenities");
      const response = await apolloClient.query({ query: AMENITIES });
      setAmenities(response.data.amenities);
      untrackLoading("amenities");
    };

    loadAmenities();
  }, []);

  const renderStep = ({ step, lot }) => {
    if (isLoading) {
      return (
        <LoadingManager
          isLoading={isLoading}
          hasError={hasError}
          loadingMessage={loadingMessage}
        />
      );
    }

    if (mode === "edit" && isEmpty(lot)) {
      return null;
    }

    if (step == 1) {
      return (
        <Basics
          amenities={amenities}
          lot={lot}
          mode={mode}
          onUpdate={(lot) => setLot(lot)}
        />
      );
    } else if (step == 2) {
      return <ParkingConfiguration lot={lot} mode={mode} />;
    } else if (step == 3) {
      return <RateSettings lot={lot} />;
    } else if (step == 4) {
      return <MonthlyParking lot={lot} canConfigureGates={canConfigureGates} />;
    } else {
      return <RateGates lot={lot} />;
    }
  };

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          defaultState={step == 1 ? "properties" : "edit-property-step"}
          defaultParams={{
            ...(step !== 1 && { lotId: $stateParams.lotId, step: step - 1 }),
          }}
          weight="300"
          title={t(`addProperty.steps.step${step}`, { steps: amountOfSteps })}
        />
      </PageHeader>
      {renderStep({ step, lot })}
    </Grid>
  );
};

export default Page;
