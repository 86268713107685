import gql from "graphql-tag";
import PageInfo from "../fragments/PageInfoFragment";
import ReservedParkingPass from "../fragments/ReservedParkingPassesFragment";

export default gql`
  query AdminListUserReservedParkingPasses($userId: Int!) {
    admin_listUserReservedParkingPasses(userId: $userId) {
      total
      edges {
        node {
          ...ReservedParkingPass
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfo}
  ${ReservedParkingPass}
`;
