"use strict";

import React, { FunctionComponent, ReactNode } from "react";
import { Box, Colors } from "@citifyd/style";

export interface BoxProps {
  children?: ReactNode;
  className?: string;
}

export interface BoxHeaderProps {
  appearance?: Colors;
  children?: ReactNode;
  className?: string;
  title?: string;
}
export interface HeaderActionsProps {
  children?: ReactNode;
  className?: string;
}
export interface ContentComponentProps {
  children?: ReactNode;
  noHorizontalPadding?: boolean;
}

export interface BoxFooterProps {
  appearance?: Colors;
  children?: ReactNode;
  className?: string;
}

type FooterComponent = FunctionComponent<BoxFooterProps>;
type ContentComponent = FunctionComponent<ContentComponentProps>;
type HeaderActionsComponent = FunctionComponent<HeaderActionsProps>;
type HeaderComponent = FunctionComponent<BoxHeaderProps>;
type BoxComponent = FunctionComponent<BoxProps> & {
  Header: HeaderComponent;
  HeaderActions: HeaderActionsComponent;
  Content: ContentComponent;
  Footer: FooterComponent;
};

const BoxContent: BoxComponent = ({
  children,
  className,
  ...props
}): JSX.Element => {
  return (
    <Box className={className} {...props}>
      {children}
    </Box>
  );
};

const Header: HeaderComponent = ({
  title,
  children,
  className,
  appearance,
  ...rest
}): JSX.Element => {
  return (
    <Box.Header
      className={className}
      title={title}
      appearance={appearance}
      {...rest}
    >
      {children}
    </Box.Header>
  );
};

const HeaderActions: HeaderActionsComponent = ({
  className,
  children,
}): JSX.Element => {
  return (
    <Box.HeaderActions className={className}>{children}</Box.HeaderActions>
  );
};

const Content: ContentComponent = ({
  children,
  noHorizontalPadding,
  ...rest
}): JSX.Element => {
  return (
    <Box.Content noHorizontalPadding={noHorizontalPadding} {...rest}>
      {children}
    </Box.Content>
  );
};

const Footer: FooterComponent = ({
  children,
  appearance,
  className,
  ...rest
}): JSX.Element => {
  return (
    <Box.Footer appearance={appearance} className={className} {...rest}>
      {children}
    </Box.Footer>
  );
};

BoxContent.Header = Header;
BoxContent.HeaderActions = HeaderActions;
BoxContent.Content = Content;
BoxContent.Footer = Footer;

export default BoxContent;
