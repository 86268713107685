"use strict";

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Grid } from "@citifyd/style";
import { useFormik } from "formik";

import FormikProvider from "../../shared/react/components/FormikProvider";
import LoadingManager from "../../shared/react/components/LoadingManager";
import PageHeader from "../../shared/react/components/PageHeader";
import EnterRecipient from "./components/EnterRecipient";
import Confirmation from "./components/Confirmation";
import { useService, useTranslator } from "../../shared/react/hooks";
import { makeValidationSchema, getTransfer } from "./utils";
import {
  getTicket,
  transferTicket,
  updateTicketTransfer,
  getCountries,
} from "../../shared/api";
import { showErrorAlert } from "../../shared/services/helper";

const TicketTransfer = () => {
  const t = useTranslator();
  const [step, setStep] = useState(1);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(
    t("ticketTransfer.loading")
  );
  const [countries, setCountries] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [phoneCountryCode, setPhoneCountryCode] = useState(null);
  const $state = useService("$state");
  const $stateParams = useService("$stateParams");
  const ticketId = $stateParams.id;

  const loadTicket = async () => {
    try {
      const ticket = await getTicket(ticketId);

      setTicket(ticket);
      setPhoneCountryCode(
        ticket?.user?.phoneCountryCode || ticket?.lot?.countryCode
      );
    } catch (err) {
      console.log("err", err);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCountries = async () => {
    try {
      const countries = await getCountries();

      setCountries(countries);
    } catch (err) {
      console.log("err", err);
      setHasError(true);
    } finally {
      loadTicket();
    }
  };

  useEffect(() => {
    loadCountries();
  }, []);

  const Step1 = (
    <EnterRecipient countries={countries} setStep={setStep} ticket={ticket} />
  );

  const renderStep = useCallback(() => {
    switch (step) {
      case 1:
        return Step1;
      case 2:
        return <Confirmation ticket={ticket} setStep={setStep} />;
      default:
        return Step1;
    }
  }, [step, ticket]);

  const updateTransfer = async () => {
    try {
      await updateTicketTransfer(ticketId, { confirm: true });

      $state.go("ticket", { id: ticket.id }, { location: "replace" });
    } catch (err) {
      console.log("err", err);
      showErrorAlert(err.data);
      setIsLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    setLoadingMessage(t("ticketTransfer.transferringPass"));

    const transfer = getTransfer(values);

    try {
      await transferTicket(ticketId, transfer);

      updateTransfer();
    } catch (err) {
      console.log("err", err);
      showErrorAlert(err.data);
      setIsLoading(false);
    }
  };

  const formikbag = useFormik({
    initialValues: {
      countryCode: phoneCountryCode || "us",
      phoneNumber: "",
      email: "",
      reason: "",
      requestedByEmail: false,
      requestedByPhone: false,
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: useMemo(() => makeValidationSchema(), []),
  });

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    );
  }

  return (
    <FormikProvider value={formikbag}>
      <Grid>
        <PageHeader>
          {step === 1 ? (
            <PageHeader.Title
              defaultState="ticket"
              defaultParams={{ id: ticket.id }}
              weight="300"
              title={t("ticketTransfer.enterRecipient")}
            />
          ) : (
            <PageHeader.Title
              onClick={() => setStep(1)}
              weight="300"
              title={t("ticketTransfer.confirmTransfer")}
            />
          )}
        </PageHeader>

        {renderStep()}
      </Grid>
    </FormikProvider>
  );
};

export default TicketTransfer;
