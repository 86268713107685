import gql from "graphql-tag";
import ReservedParkingPass from "../fragments/ReservedParkingPassesFragment";
import Cancellation from "../fragments/CancellationFragment";

export default gql`
  query AdminGetReservedParkingPass($passId: Int!) {
    admin_getReservedParkingPass(passId: $passId) {
      ...ReservedParkingPass
      cancellation {
        ...Cancellation
      }
    }
  }
  ${ReservedParkingPass}
  ${Cancellation}
`;
