import { getTranslator } from "../../../services/languages";

export const getAccountNumberLabel = (country) => {
  const t = getTranslator();

  if (country?.continent === "europe") {
    return t("modalEditPayment.commonFields.accountNumberIBAN");
  } else {
    return t("modalEditPayment.commonFields.accountNumber");
  }
};

export const getRoutingNumberLabel = (country) => {
  const t = getTranslator();

  if (country?.continent === "europe") {
    return t("modalEditPayment.commonFields.routingNumberOptional");
  } else {
    return t("modalEditPayment.commonFields.routingNumber");
  }
};
