"use strict";

import React, { useEffect, useState, useMemo } from "react";
import { Grid } from "@citifyd/style";
import { useFormik } from "formik";

import FormikProvider from "../../shared/react/components/FormikProvider";
import LoadingManager from "../../shared/react/components/LoadingManager";
import { useService, useTranslator } from "../../shared/react/hooks";
import UserPurchaseTicketEvent from "./components/UserPurchaseTicketEvent";
import UserPurchaseTicketLot from "./components/UserPurchaseTicketLot";
import UserPurchaseTicketReport from "./components/UserPurchaseTicketReport";
import UserPurchaseTicketPayment from "./components/UserPurchaseTicketPayment";
import {
  parseEvents,
  mountBundleGroups,
  validateForm,
  onFormSubmit,
  getBundlesValues,
  getEventsValues,
  hasVehicleRequired,
} from "./utils";
import { getAvailableEventsForAssistedPurchase } from "../../shared/api";
import { useAdminCreateVehicleMutation } from "../../graphql/generated/graphql";
import ApolloClient from "../../shared/services/apolloClient";
import { showAlert } from "../../shared/services/helper";
import { getGraphqlError } from "../../shared/services/graphql-helper";

const apolloClient = ApolloClient();

const UserPurchaseTicket = () => {
  const t = useTranslator();
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [events, setEvents] = useState([]);

  const [bundleGroups, setBundleGroups] = useState([]);
  const [submitResponse, setSubmitResponse] = useState();

  const $stateParams = useService("$stateParams");
  const { id: userId, phoneCountryCode, phoneNumber } = $stateParams;

  const [createVehicle] = useAdminCreateVehicleMutation({
    client: apolloClient,
  });

  const loadEvents = async () => {
    const id = phoneNumber
      ? `${userId}-${phoneCountryCode}-${phoneNumber}`
      : userId;

    try {
      const response = await getAvailableEventsForAssistedPurchase(id);

      setEvents(parseEvents(response.events));
      setBundleGroups(mountBundleGroups(response.bundles));
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadEvents();
  }, []);

  const onSubmit = async (values) => {
    if (!validateForm(values)) {
      return;
    }

    const bundles = getBundlesValues(values?.selectedBundles);
    const events = getEventsValues(values?.selectedEvents);

    setIsLoading(true);
    setLoadingMessage(
      t("userPurchaseTicket.purchasingPass", {
        count: events.length + bundles.length > 1,
      })
    );

    const submitForm = async (vehicleId) => {
      try {
        let response;

        if (vehicleId) {
          response = await onFormSubmit({
            values,
            vehicleId,
          });
        } else {
          response = await onFormSubmit({
            values,
          });
        }

        setStep(4);
        setSubmitResponse({
          data: response.data,
          events: values?.selectedEvents,
          bundles: values?.selectedBundles,
        });
      } catch (err) {
        console.log("Error occurred", err);
        const error = getGraphqlError(err);

        showAlert(error.message);
      } finally {
        setIsLoading(false);
        setLoadingMessage(null);
      }
    };

    if (hasVehicleRequired(values?.selectedEvents)) {
      try {
        const vehicleResponse = await createVehicle({
          variables: {
            userId: Number(userId),
            countryCode: values.licensePlate.countryCode.toLowerCase(),
            licenseInfo: { plate: values.licensePlate.plate },
          },
        });
        await submitForm(vehicleResponse.data.admin_createVehicle.id);
      } catch (err) {
        console.log("Error occurred", err);
        const error = getGraphqlError(err);

        showAlert(error.message);
      }
    } else {
      await submitForm();
    }
  };

  const formikbag = useFormik({
    initialValues: {
      selectedEvents: [],
      selectedBundles: [],
      selectedCardId: "",
      reason: "",
      requestedByEmail: false,
      requestedByPhone: false,
      licensePlate: {
        plate: "",
        countryCode: "us",
      },
    },
    onSubmit,
  });

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    );
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <UserPurchaseTicketEvent
            events={events}
            bundleGroups={bundleGroups}
            setStep={setStep}
          />
        );
      case 2:
        return <UserPurchaseTicketLot setStep={setStep} />;
      case 3:
        return <UserPurchaseTicketPayment setStep={setStep} />;
      case 4:
        return <UserPurchaseTicketReport submitResponse={submitResponse} />;
    }
  };

  return (
    <Grid>
      <FormikProvider value={formikbag}>{renderStep()}</FormikProvider>
    </Grid>
  );
};

export default UserPurchaseTicket;
