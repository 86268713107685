"use strict";

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Cookies from "js-cookie";
import uuid from "uuid/v4";
import { LoadingIndicator } from "@citifyd/style";

import { getMe, login, renewCurrentSession } from "../../api";
import {
  changeLanguage,
  getCurrentLanguage,
  hasUserUpdatedLanguage,
} from "../../services/languages";
import { saveIntercomUserData } from "../../services/intercom";

const ACCESS_TOKEN_COOKIE_NAME = "citifyd_access_token";
const IMPERSONATED_USER_ID_COOKIE = "impersonated_user_id";
export const PASSWORD_CHANGE_SUGGESTED = "password_change_suggested";

const AuthenticationContext = createContext();

const persistToken = (token) => {
  Cookies.set(ACCESS_TOKEN_COOKIE_NAME, token);
};

export const getAccessToken = () => {
  return Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || null;
};

export const getImpersonatedUserId = () => {
  return Cookies.get(IMPERSONATED_USER_ID_COOKIE);
};

export const getClientToken = () => {
  let token = window.localStorage.getItem("citifydClientToken");

  if (!token) {
    token = uuid();
    window.localStorage.setItem("citifydClientToken", token);
  }

  return token;
};

export const AuthenticationProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loggedUser, setLoggedUser] = useState(null);
  const [impersonatedUser, setImpersonatedUser] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const sessionRenewalInterval = useRef(null);

  useEffect(() => {
    onPageLoad();
  }, []);

  const onPageLoad = async () => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      setIsLoading(false);
      setLoggedUser(null);
      return;
    }

    try {
      const user = await reloadUser();

      if (user.currentSession.type !== "management") {
        logout();
        return;
      }

      renewSession();
      startSessionRenewalInterval();
    } catch (err) {
      console.log("Authentication failed on page load", err);
      Cookies.remove(IMPERSONATED_USER_ID_COOKIE);
    } finally {
      setIsLoading(false);
    }
  };

  const reloadUser = async () => {
    let returnedUser = null;

    const response = await getMe({
      params: { loadPermissions: true },
      headers: { "Impersonate-user-id": null },
    });
    setLoggedUser(response.user);
    changeLanguage(response.user.language);

    if (getImpersonatedUserId()) {
      const impersonated = await getMe({ params: { loadPermissions: true } });
      setImpersonatedUser(impersonated.user);
      setPermissions(impersonated.permissions);
      returnedUser = impersonated.user;
    } else {
      returnedUser = response.user;
      setPermissions(response.permissions);
      saveIntercomUserData(response.user);
    }

    return returnedUser;
  };

  const logout = () => {
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    Cookies.remove(IMPERSONATED_USER_ID_COOKIE);

    setImpersonatedUser(null);
    setLoggedUser(null);
    setPermissions([]);

    clearSessionRenewalInterval();
  };

  const setToken = async (token) => {
    Cookies.remove(IMPERSONATED_USER_ID_COOKIE);
    setImpersonatedUser(null);

    persistToken(token);

    const result = await reloadUser();
    renewSession();
    startSessionRenewalInterval();

    return result;
  };

  const removeImpersonation = async () => {
    setImpersonatedUser(null);
    Cookies.remove(IMPERSONATED_USER_ID_COOKIE);
    await reloadUser();
  };

  const renewSession = async () => {
    if (!getAccessToken()) {
      return false;
    }

    const response = await renewCurrentSession();

    if (response.renewed) {
      persistToken(response.accessToken);
      return true;
    }

    return false;
  };

  const startSessionRenewalInterval = () => {
    clearSessionRenewalInterval();

    sessionRenewalInterval.current = setInterval(
      () => renewSession(),
      10 * 60 * 1000 // 10 minutes
    );
  };

  const clearSessionRenewalInterval = () => {
    if (sessionRenewalInterval.current) {
      clearInterval(sessionRenewalInterval.current);
      sessionRenewalInterval.current = null;
    }
  };

  const impersonateUser = async (userId) => {
    Cookies.set(IMPERSONATED_USER_ID_COOKIE, userId);

    return reloadUser();
  };

  const tryAuthenticate = async (data) => {
    const language = getCurrentLanguage();
    const headers = {};
    const clientToken = getClientToken();

    if (clientToken) {
      headers["Citifyd-client-token"] = clientToken;
    }

    const response = await login({
      headers,
      data: { ...data, sessionType: "management" },
      params: { loadPermissions: true },
    });

    if (response.user) {
      persistToken(response.user.accessToken);
      setLoggedUser(response.user);
      setPermissions(response.permissions);

      startSessionRenewalInterval();

      // Customers can change their preferred language before login, and this change
      // should be persisted.
      // In case this happens, we send a broadcast down to our ctLanguagueSelector
      // directive to update the user's profile with the appropriate language.
      if (language !== response.user.language && !hasUserUpdatedLanguage()) {
        changeLanguage(response.user.language);
      }

      if (response.user.passwordChangeSuggested) {
        Cookies.set(PASSWORD_CHANGE_SUGGESTED, "1");
      } else {
        Cookies.remove(PASSWORD_CHANGE_SUGGESTED);
      }
    }

    return response;
  };

  const isImpersonating = () => Boolean(impersonatedUser);

  return (
    <AuthenticationContext.Provider
      value={{
        getAccessToken,
        impersonateUser,
        loggedUserIsAdmin: loggedUser?.isAdmin && loggedUser.role === "admin",
        isImpersonating,
        logout,
        originalUser: loggedUser,
        permissions,
        reloadUser,
        setToken,
        removeImpersonation,
        tryAuthenticate,
        user: impersonatedUser ?? loggedUser,
      }}
    >
      {!isLoading ? children : <LoadingIndicator />}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => useContext(AuthenticationContext);
