import React from "react";
import { Grid } from "@citifyd/style";
import { useFormikContext } from "formik";

import { useTranslator } from "../../../../shared/react/hooks";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import styles from "./TicketRefundSource.module.scss";

const TicketRefundSource = () => {
  const t = useTranslator();
  const { setFieldValue, values: refundOptions } = useFormikContext();

  const refunderOptions = [
    {
      value: "seller",
      label: t("refundPages.deductTicketFundsFrom.values.seller"),
    },
    {
      value: "citifyd",
      label: t("refundPages.deductTicketFundsFrom.values.citifyd"),
    },
  ];

  const stripeFeePayerOptions = [
    {
      value: "seller",
      label: t("refundPages.deductStripeProcessingFeeFundsFrom.values.seller"),
    },
    {
      value: "citifyd",
      label: t("refundPages.deductStripeProcessingFeeFundsFrom.values.citifyd"),
    },
  ];

  const handleRefunder = (e) => {
    const { value } = e.target;

    setFieldValue("refunder", value);

    if (refundOptions.feeRetentionMethod === "no_retention") {
      value === "citifyd"
        ? setFieldValue("stripeFeePayer", "citifyd")
        : setFieldValue("stripeFeePayer", "seller");
    }
  };

  return (
    <Grid.Row className={styles.grid}>
      <Grid.Col sm={12} lg={6}>
        <FormikField
          as={FIELD_TYPE.SELECT}
          options={refunderOptions}
          label={t("refundPages.deductTicketFundsFrom.label")}
          name="refunder"
          onChange={handleRefunder}
        />
        <br />
      </Grid.Col>
      <Grid.Col sm={12} lg={6}>
        {refundOptions.feeRetentionMethod === "no_retention" ? (
          <FormikField
            disabled={refundOptions.refunder === "citifyd"}
            as={FIELD_TYPE.SELECT}
            options={stripeFeePayerOptions}
            label={t("refundPages.deductStripeProcessingFeeFundsFrom.label")}
            name="stripeFeePayer"
          />
        ) : (
          <FormikField
            disabled
            as={FIELD_TYPE.SELECT}
            options={[
              {
                value: "customer",
                label: t(
                  "refundPages.deductStripeProcessingFeeFundsFrom.values.customer"
                ),
              },
            ]}
            label={t("refundPages.deductStripeProcessingFeeFundsFrom.label")}
            name="stripeFeePayer"
          />
        )}
      </Grid.Col>
    </Grid.Row>
  );
};

export default TicketRefundSource;
