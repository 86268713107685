"use strict";

import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { find, isEmpty, isEqual } from "lodash";
import { Text } from "@citifyd/style";
import { useLazyQuery } from "@apollo/client";

import usePrevious from "../../../../shared/react/hooks/usePrevious";
import ApolloClient from "../../../../shared/services/apolloClient";
import GenerateReportModal from "../../../../shared/modals/GenerateReportModal";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import { getRatesReportsCsv } from "../../../../shared/api";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import Filter from "./Filter";
import Table from "./Table";
import {
  getFilterSelectionFromState,
  getLotsIdsObjectByParams,
} from "../../utils";

import ADMIN_RATES_REPORT from "../../../../graphql/reports/queries/AdminRatesReport";
import { useModal } from "../../../../shared/react/contexts/modal";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const apolloClient = ApolloClient();

const RatesReport = ({ className, lots, organizationsOptions }) => {
  const $state = useService("$state");
  const { openModal } = useModal();
  const t = useTranslator();
  const [params, setParams] = useState($state.params);
  const [hasSelectedLot, setHasSelectedLot] = useState(false);
  const prevParams = usePrevious($state.params);
  const { user } = useAuthentication();

  const [getRates, { data, loading, error }] = useLazyQuery(
    ADMIN_RATES_REPORT,
    {
      client: apolloClient,
      fetchPolicy: "network-only",
    }
  );

  const getQueryOptionsFromFilterSelection = (filterSelection) => {
    const lotIds = getLotsIdsObjectByParams(filterSelection.lots);

    return {
      date: moment(filterSelection.ratesDate).format("YYYY-MM-DD"),
      lotId: lotIds?.length === 1 ? parseInt(lotIds) : null,
      organizationId: filterSelection.organizationId,
    };
  };

  useEffect(() => {
    if (!isEqual(prevParams, $state.params) && !isEmpty($state.params)) {
      setParams($state.params);
      if ($state.params.lots) {
        const lotIds = getLotsIdsObjectByParams($state.params.lots);
        setHasSelectedLot(lotIds?.length === 1 || !lotIds);
      }
    }
  }, [$state.params]);

  useEffect(() => {
    if (params.ratesDate && params.lots) {
      const filterSelection = getFilterSelectionFromState({ user });
      const options = getQueryOptionsFromFilterSelection(filterSelection);

      if (options?.lotId) {
        getRates({
          variables: {
            ...options,
          },
        });
      }
    }
  }, [params]);

  const generateCsvReportDescription = (options) => {
    const lot = find(lots, (lot) => lot.id === options.lotId);

    return t("reports.rates.csvReportName.lot", {
      ...options,
      lotName: lot?.name,
    });
  };

  const generateCsvClicked = () => {
    const filterSelection = getFilterSelectionFromState({ user });
    const options = getQueryOptionsFromFilterSelection(filterSelection);

    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription: generateCsvReportDescription(options),
      disableFilterDescriptionFirstCharacterLowercase: true,
      reportCsvRequest: getRatesReportsCsv(options),
    });
  };

  return (
    <div className={className}>
      <Filter
        onClickGenerateCsv={generateCsvClicked}
        lots={lots}
        disabled={loading || !hasSelectedLot}
        organizationsOptions={organizationsOptions}
      />
      {loading || error ? (
        <LoadingManager isLoading={loading} hasError={error} />
      ) : hasSelectedLot ? (
        data && <Table ratesData={data?.admin_ratesReport} />
      ) : (
        <Text variant="subtitle">{t("reports.ratesReportInstructions")}</Text>
      )}
    </div>
  );
};

export default RatesReport;
