"use strict";

import React from "react";
import { Text } from "@citifyd/style";

import Table from "../../../../shared/react/components/Table";
import { useTranslator } from "../../../../shared/react/hooks";

const TicketExchangeConfirmationNoPayment = React.memo(({ rateDifference }) => {
  const t = useTranslator();

  const columns = [
    {
      key: "column",
      value:
        rateDifference >= 0
          ? t("ticketExchange.noPayment")
          : t("ticketExchange.noRefund"),
    },
  ];

  return (
    <>
      <Text variant="h5" gutterBottom>
        {rateDifference >= 0
          ? t("ticketExchange.paymentMethod")
          : t("ticketExchange.noPayment")}
      </Text>

      <Table columns={columns} />
    </>
  );
});

export default TicketExchangeConfirmationNoPayment;
