"use strict";

import React, { useMemo } from "react";
import { Grid, Label } from "@citifyd/style";

import { useTranslator } from "../../../../shared/react/hooks";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";

import BoxContent from "../../../../shared/react/components/BoxContent";
import FieldDescription from "../FieldDescription";
import styles from "../CommonStyles.module.scss";
import SelectedDaysOfTheWeekTable from "./SelectedDaysOfTheWeekTable";
import { PropertyConfiguration as PropertyConfigurationProps } from "../../types";

const PropertyConfiguration = ({
  propertyConfig,
  index,
}: {
  propertyConfig: PropertyConfigurationProps;
  index: number;
}) => {
  const t = useTranslator();

  const prefix = `propertyConfiguration[${index}]`;

  const hasAnyDaySelected = useMemo(() => {
    if (!propertyConfig) return;

    for (const day in propertyConfig.daysOfWeek) {
      if (propertyConfig.daysOfWeek[day]) {
        return true;
      }
    }
    return false;
  }, [propertyConfig?.daysOfWeek]);

  return (
    <BoxContent>
      <BoxContent.Header title={propertyConfig.propertyName} />
      <BoxContent.Content>
        <Grid.Row>
          <Grid.Col sm={12} lg={9}>
            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <Grid.Row>
                  <Grid.Col xs={7} sm={9} lg={8}>
                    <FormikField
                      as={FIELD_TYPE.INPUT}
                      name={`${prefix}.permitsMonthlyLimit.quantity`}
                      disabled={propertyConfig.permitsMonthlyLimit?.noLimit}
                      label={t(
                        "permits.permitters.settings.permitsMonthlyLimit"
                      )}
                      type="number"
                      min="1"
                    />
                    <FieldDescription>
                      {t(
                        "permits.permitters.settings.description.permitsMonthlyLimit"
                      )}
                    </FieldDescription>
                  </Grid.Col>
                  <Grid.Col xs={5} sm={3} lg={4}>
                    <div className={styles.noLimit}>
                      <FormikField
                        name={`${prefix}permitsMonthlyLimit.noLimit`}
                        label={t("permits.permitters.settings.noLimit")}
                        as={FIELD_TYPE.CHECKBOX}
                        fullWidth
                      />
                    </div>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <Grid.Row>
                  <Grid.Col xs={7} sm={9} lg={8}>
                    <FormikField
                      as={FIELD_TYPE.INPUT}
                      name={`${prefix}concurrentPermitsLimit.quantity`}
                      disabled={propertyConfig.concurrentPermitsLimit?.noLimit}
                      label={t(
                        "permits.permitters.settings.concurrentPermitsLimit"
                      )}
                      type="number"
                      min="1"
                    />
                    <FieldDescription>
                      {t(
                        "permits.permitters.settings.description.concurrentPermitsLimit"
                      )}
                    </FieldDescription>
                  </Grid.Col>
                  <Grid.Col xs={5} sm={3} lg={4}>
                    <div className={styles.noLimit}>
                      <FormikField
                        name={`${prefix}concurrentPermitsLimit.noLimit`}
                        label={t("permits.permitters.settings.noLimit")}
                        as={FIELD_TYPE.CHECKBOX}
                        fullWidth
                      />
                    </div>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col sm={12} lg={6}>
                <Grid.Row>
                  <Grid.Col xs={7} sm={9} lg={8}>
                    <FormikField
                      as={FIELD_TYPE.INPUT}
                      name={`${prefix}permitsDailyLimit.quantity`}
                      disabled={propertyConfig.permitsDailyLimit?.noLimit}
                      label={t("permits.permitters.settings.permitsDailyLimit")}
                      type="number"
                      min="1"
                    />
                    <FieldDescription>
                      {t(
                        "permits.permitters.settings.description.permitsDailyLimit"
                      )}
                    </FieldDescription>
                  </Grid.Col>
                  <Grid.Col xs={5} sm={3} lg={4}>
                    <div className={styles.noLimit}>
                      <FormikField
                        name={`${prefix}permitsDailyLimit.noLimit`}
                        label={t("permits.permitters.settings.noLimit")}
                        as={FIELD_TYPE.CHECKBOX}
                        fullWidth
                      />
                    </div>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
              <Grid.Col sm={12} lg={6}>
                <Label>{t("permits.permitters.settings.permitType")}</Label>
                <div>
                  <FormikField
                    as={FIELD_TYPE.RADIO}
                    name={`${prefix}permitType`}
                    label={t("permits.permitters.settings.free")}
                    value="free"
                    checked={propertyConfig?.permitType === "free"}
                  />
                  <FormikField
                    as={FIELD_TYPE.RADIO}
                    name={`${prefix}permitType`}
                    label={t("permits.permitters.settings.paid")}
                    value="paid"
                    checked={propertyConfig?.permitType === "paid"}
                    className={styles.radio}
                  />
                  <FormikField
                    as={FIELD_TYPE.RADIO}
                    name={`${prefix}permitType`}
                    label={t("permits.permitters.settings.mixed")}
                    value="mixed"
                    checked={propertyConfig?.permitType === "mixed"}
                    className={styles.radio}
                  />
                </div>
                <FieldDescription>
                  {t("permits.permitters.settings.description.permitType")}
                </FieldDescription>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col sm={12} lg={3}>
            <Grid.Row>
              <Grid.Col>
                <FormikField
                  name={`${prefix}blockedDates`}
                  label={t("permits.permitters.settings.blockedDays")}
                  as={FIELD_TYPE.MULTI_DATEPICKER}
                />
                <FieldDescription>
                  {t("permits.permitters.settings.description.blockedDays")}
                </FieldDescription>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <FormikField
                  as={FIELD_TYPE.INPUT}
                  name={`${prefix}amountOfFreePermits`}
                  disabled={propertyConfig.permitType !== "mixed"}
                  label={t("permits.permitters.settings.amountOfFreePermits")}
                  type="number"
                  min="0"
                />

                <FieldDescription>
                  {t(
                    "permits.permitters.settings.description.amountOfFreePermits"
                  )}
                </FieldDescription>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col sm={12} lg={6}>
            <FormikField
              as={FIELD_TYPE.DAYS_OF_WEEK}
              name={`${prefix}daysOfWeek`}
              label={t("permits.permitters.settings.daysOfWeek")}
              gutterBottom
            />
            <FieldDescription>
              {t("permits.permitters.settings.description.daysOfWeek")}
            </FieldDescription>
            {hasAnyDaySelected && (
              <Grid.Row>
                <Grid.Col>
                  <SelectedDaysOfTheWeekTable
                    propertyConfig={propertyConfig}
                    index={index}
                    prefix={prefix}
                  />
                </Grid.Col>
              </Grid.Row>
            )}
          </Grid.Col>
          <Grid.Col></Grid.Col>
        </Grid.Row>
      </BoxContent.Content>
    </BoxContent>
  );
};

export default PropertyConfiguration;
