"use strict";

import React, { useEffect, useState } from "react";
import { Button, Icon, Grid } from "@citifyd/style";
import classNames from "classnames";
import _ from "lodash";

import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import FormikProvider from "../../../../shared/react/components/FormikProvider";
import { getCountries } from "../../../../shared/api";
import { useTranslator } from "../../../../shared/react/hooks";
import useUserInfoStep from "./useUserInfoStep";
import Footer from "../Footer/Footer.jsx";
import PhoneField from "../../../../shared/react/components/PhoneField";
import styles from "./UserInfo.module.scss";
import { showErrorAlert } from "../../../../shared/services/helper";

const UserInfo = ({ steps, setSteps }) => {
  const t = useTranslator();
  const [countries, setCountries] = useState([]);
  const formikbag = useUserInfoStep({ setSteps });

  useEffect(() => {
    loadCountries();

    // has the user already been through this step and came back?
    if (steps.data.userInfo) {
      formikbag.setFieldValue("users", steps.data.userInfo.users);
    }
  }, []);

  const loadCountries = async () => {
    try {
      const countries = await getCountries();

      setCountries(countries);
    } catch (err) {
      showErrorAlert(err);
    }
  };

  const users = formikbag.values.users;

  const addMoreUsers = () => {
    formikbag.setFieldValue("users", [
      ...users,
      { name: "", email: "", phoneNumber: "", phoneCountryCode: "us" },
    ]);
  };
  const isEmpty = (user) => {
    const fields = ["name", "email", "phoneNumber"];
    return _.every(fields, (field) => user[field].trim() === "");
  };

  const canAddAccount = () => {
    const last = _.last(users);

    return !last || isEmpty(last);
  };

  const removeUserAt = (index) => {
    formikbag.setFieldValue(
      "users",
      users.filter((_, i) => index !== i)
    );
  };

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      {users.map((_user, i) => (
        <Grid.Row marginBottom>
          <Grid.Col xs={12} md={3}>
            <FormikField
              label={i === 0 ? t("inviteParkers.accountInfo.table.name") : null}
              name={`users[${i}].name`}
              as={FIELD_TYPE.INPUT}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={3}>
            <FormikField
              label={
                i === 0 ? t("inviteParkers.accountInfo.table.email") : null
              }
              name={`users[${i}].email`}
              as={FIELD_TYPE.INPUT}
            />
          </Grid.Col>
          <Grid.Col xs={12} md={5}>
            <PhoneField
              countries={countries}
              countryCodeDefault={formikbag.values.users[i].phoneCountryCode}
              label={
                i === 0
                  ? t("inviteParkers.accountInfo.table.phoneNumber")
                  : null
              }
              countryCodeFieldName={`users[${i}].phoneCountryCode`}
              phoneNumberFieldName={`users[${i}].phoneNumber`}
            />
          </Grid.Col>
          <Grid.Col
            xs={12}
            md={1}
            className={`${i === 0 ? styles.firstColButton : ""}`}
          >
            <Button
              onMouseDown={() => removeUserAt(i)}
              disabled={users.length === 1}
              appearance="tertiary"
              size="small"
              justifyContent="center"
              rounded
              className={classNames(styles.button, {
                [styles.removeButtons]: i > 0,
              })}
            >
              <Icon icon="times" pull={null} size="h3" appearance="white" />
            </Button>
          </Grid.Col>
        </Grid.Row>
      ))}
      <Button
        onMouseDown={() => addMoreUsers()}
        disabled={canAddAccount()}
        appearance="primary"
        size="small"
        justifyContent="center"
        className={styles.button}
        rounded
      >
        <Icon icon="plus" pull={null} size="h3" appearance="white" />
      </Button>

      <Footer handleSubmit={handleSubmit} />
    </FormikProvider>
  );
};

export default UserInfo;
