"use strict";

import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Text, Grid } from "@citifyd/style";

import usePrevious from "../../shared/react/hooks/usePrevious";
import PageHeader from "../../shared/react/components/PageHeader";
import { useService, useTranslator } from "../../shared/react/hooks";
import LoadingManager from "../../shared/react/components/LoadingManager";
import EventHeader from "../../shared/react/components/EventHeader";
import { getService } from "../../shared/react/utils";
import { getEvent } from "../../shared/api";

import RevenueOfEventSubHeader from "./components/RevenueOfEventSubHeader";
import RevenueOfEventSummary from "./components/RevenueOfEventSummary";
import RevenueOfEventTickets from "./components/RevenueOfEventTickets";
import { parseTickets } from "./utils";
import styles from "./RevenueOfEvent.module.scss";
import { getRevenueForEvent } from "../../shared/api";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import Permissions from "../../shared/services/permissions";

const RevenueOfEvent = () => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const { user, permissions } = useAuthentication();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [event, setEvent] = useState(null);
  const [lots, setLots] = useState([]);
  const [selectedLots, setSelectedLots] = useState([]);
  const [summary, setSummary] = useState({});
  const [hasCashPayments, setHasCashPayments] = useState(false);
  const currentLot = $stateParams.currentLot;
  const lotId = $stateParams.lot || currentLot;
  const [lotSelected, setLotSelected] = useState(
    Number(lotId) || Number(currentLot) || ""
  );
  const prevParams = usePrevious($stateParams);

  const canExportToPdf = Permissions.userMayExportPdf(permissions);
  const dateRange = $stateParams.dateRange;
  const eventId = $stateParams.event;
  const currency = $stateParams.currency;

  const calculateSummary = (tickets, selectedLots) => {
    const lots = _(selectedLots)
      .map((lot) => {
        const lotTickets = tickets.filter((t) => t.lot.id === lot.id);

        return [
          lot.id,
          {
            totalTickets: lotTickets.length,
            totalTicketsRedeemed: tickets.filter(
              (t) => t.redeemedLot && t.redeemedLot.id === lot.id
            ).length,
            netRevenue: _.sum(lotTickets, (t) => t.payment.netRevenue),
          },
        ];
      })
      .zipObject()
      .value();

    const totals = {};

    for (const field of [
      "totalTickets",
      "totalTicketsRedeemed",
      "netRevenue",
    ]) {
      totals[field] = _.sum(lots, (lot) => lot[field]);
    }

    return { lots, totals };
  };

  const fetchRevenue = async (lots, selectedLots) => {
    const lotId = getService("$stateParams").lot;

    try {
      const response = await getRevenueForEvent(eventId, {
        params: {
          currency,
          lotId,
        },
      });

      const tickets = parseTickets(response.tickets, t);

      setSummary(calculateSummary(tickets, selectedLots));

      const hasCashPayments = _.some(
        tickets,
        (t) => t.payment.method === "cash"
      );

      setHasCashPayments(hasCashPayments);

      for (const lot of lots) {
        lot.tickets = tickets.filter((ticket) => ticket.lot.id === lot.id);
      }

      setLots(lots);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEvent = async () => {
    const lotId =
      getService("$stateParams").lot &&
      parseInt(getService("$stateParams").lot);

    setHasError(false);

    try {
      const event = await getEvent(eventId);

      const lots = _(event.lots)
        .filter(
          (lot) => user.isAdmin || lot.organizationId === user.organizationId
        )
        .sortBy("name")
        .value();

      const selectedLots = lots.filter((lot) => !lotId || lotId === lot.id);

      setEvent(event);
      setSelectedLots(selectedLots);
      fetchRevenue(lots, selectedLots);
    } catch (err) {
      setHasError(true);
    }
  };

  useEffect(() => {
    if (!_.isEqual(prevParams, $stateParams)) {
      fetchEvent();
      setIsLoading(true);
    }
  }, [$stateParams]);

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          defaultState="reports"
          defaultParams={{
            reportType: "events",
            currency,
            lot: currentLot || undefined,
            dateRange,
          }}
          title={t("revenue.revenuePerEvent")}
        />
      </PageHeader>
      {event && (
        <>
          <EventHeader event={event} showActions={false} />
          <RevenueOfEventSubHeader
            event={event}
            lots={lots}
            setLotSelected={setLotSelected}
            lotSelected={lotSelected}
            canExportToPdf={canExportToPdf}
            currency={currency}
            lotId={lotId}
          />
        </>
      )}
      {isLoading || hasError ? (
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      ) : (
        <>
          <RevenueOfEventSummary
            selectedLots={selectedLots}
            summary={summary}
            currency={currency}
          />
          <RevenueOfEventTickets
            selectedLots={selectedLots}
            summary={summary}
            currency={currency}
          />
          {hasCashPayments && (
            <Text variant="subtitle" className={styles.hasCashPayments}>
              * {t("revenue.eventReportCashPaymentDisclaimer")}
            </Text>
          )}
        </>
      )}
    </Grid>
  );
};

export default RevenueOfEvent;
