"use strict";

import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, Grid, Icon, Text } from "@citifyd/style";
import { cloneDeep, pick } from "lodash";

import LoadingManager from "../../shared/react/components/LoadingManager";
import RouterLink from "../../shared/react/components/RouterLink";
import StickyFooter from "../../shared/react/components/StickyFooter";
import { useTranslator, useService } from "../../shared/react/hooks";
import PageHeader from "../../shared/react/components/PageHeader";
import FormikProvider from "../../shared/react/components/FormikProvider";

import MyAccountForm from "./components/MyAccountForm";
import MyAccountDevices from "./components/MyAccountDevices";
import VerifyPhoneNumberModal from "../../shared/modals/VerifyPhoneNumberModal";
import ChangePasswordModal from "../../shared/modals/ChangePasswordModal";

import styles from "./MyAccount.module.scss";

import { showErrorAlert } from "../../shared/services/helper";

import { getMySessions, updateMySession, updateUser } from "../../shared/api";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import { useModal } from "../../shared/react/contexts/modal";

const EDITABLE_FIELDS = ["name", "email", "twoFactorAuthenticationEnabled"];

const MyAccount = () => {
  const t = useTranslator();
  const { openModal } = useModal();
  const $state = useService("$state");
  const devicesToRemove = useRef([]);
  const [twoFactorDevices, setTwoFactorDevices] = useState(null);
  const [editUser, setEditUser] = useState(null);
  const [hasError, setHasError] = useState(false);
  const { user, reloadUser } = useAuthentication();

  const [loadingMessage, setLoadingMessage] = useState(
    t("myAccount.loadingInfo")
  );
  const [isLoading, setIsLoading] = useState(true);

  const openChangePasswordModal = () => openModal(ChangePasswordModal);

  const openPhoneNumberModal = () => {
    openModal(VerifyPhoneNumberModal, {
      user,
      title: t("myAccount.updatePhoneNumber"),
    });
  };

  const filterTwoFactorSessions = (sessions) => {
    return sessions.filter((s) => s.permanentTwoFactorAuthentication);
  };

  const loadDevices = async () => {
    try {
      const sessions = await getMySessions();

      setTwoFactorDevices(filterTwoFactorSessions(sessions));
    } catch (err) {
      showErrorAlert(err.data);
    } finally {
      setIsLoading(false);
    }
  };

  const loadUser = async () => {
    setIsLoading(true);

    try {
      const response = await reloadUser();

      setEditUser(cloneDeep(response));
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingMessage(t("myAccount.loadingDevices"));
      loadDevices();
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  const removeDeletedDevices = async () => {
    for (const deviceId of devicesToRemove.current) {
      await updateMySession(deviceId, {
        permanentTwoFactorAuthentication: false,
      });
    }

    reloadUser().then(() => $state.go("account"));
  };

  const onSubmit = async (values) => {
    const data = pick(values, EDITABLE_FIELDS);

    setLoadingMessage(t("myAccount.savingInformation"));
    setIsLoading(true);

    if (data.twoFactorAuthenticationEnabled === false) {
      devicesToRemove.current = twoFactorDevices.map((device) => device.id);
    }

    try {
      await updateUser(user.id, data);

      removeDeletedDevices();
    } catch (err) {
      setIsLoading(false);
      showErrorAlert(err.data);
    }
  };

  const formikbag = useFormik({
    initialValues: {
      name: editUser?.name || "",
      email: editUser?.email || "",
      twoFactorAuthenticationEnabled:
        editUser?.twoFactorAuthenticationEnabled || false,
    },
    onSubmit,
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      email: yup.string().email().required(),
    }),
    enableReinitialize: true,
  });

  const { handleSubmit } = formikbag;

  return (
    <Grid className={styles.myAccount}>
      <PageHeader>
        <PageHeader.Title
          defaultState="account"
          title={t("myAccount.accountInformation")}
        />
      </PageHeader>
      {isLoading ? (
        <LoadingManager
          isLoading={isLoading}
          loadingMessage={loadingMessage}
          hasError={hasError}
        />
      ) : (
        <div className={styles.content}>
          <FormikProvider value={formikbag}>
            <MyAccountForm />
            <Grid.Row>
              <Grid.Col>
                <Button
                  fullWidth
                  shadow
                  uppercase
                  letterSpacing
                  justifyContent="center"
                  onClick={openPhoneNumberModal}
                  size="small"
                >
                  {t("myAccount.updatePhoneNumber")}
                </Button>
              </Grid.Col>
              <Grid.Col>
                <Button
                  fullWidth
                  shadow
                  uppercase
                  letterSpacing
                  justifyContent="center"
                  onClick={openChangePasswordModal}
                  size="small"
                >
                  {t("myAccount.changePassword")}
                </Button>
              </Grid.Col>
            </Grid.Row>
            <MyAccountDevices
              editUser={editUser}
              twoFactorDevices={twoFactorDevices}
              setTwoFactorDevices={setTwoFactorDevices}
              devicesToRemove={devicesToRemove.current}
            />
            <StickyFooter justifyContent="space-between">
              <RouterLink state="account" className={styles.backLink}>
                <Icon size="subtitle" icon="chevron-left" appearance="white" />
                <Text variant="subtitle" appearance="white">
                  {t("commonButtons.goBackWithoutSaving")}
                </Text>
              </RouterLink>
              <Button uppercase onMouseDown={handleSubmit} extraPadding>
                {t("commonButtons.save")}
              </Button>
            </StickyFooter>
          </FormikProvider>
        </div>
      )}
    </Grid>
  );
};

export default MyAccount;
