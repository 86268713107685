import classNames from "classnames";
import React from "react";

import styles from "./Flag.module.scss";

const Flag = ({ code }) => {
  return (
    <span
      className={classNames(styles.flag, {
        [styles["flag-" + code]]: code,
      })}
    />
  );
};

export default Flag;
