import { getTranslator } from "../../../../../shared/services/languages";

export const getTableColumns = () => {
  const t = getTranslator();

  return [
    {
      value: t("reports.transactionsTable.id"),
      key: "id",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.createdAt"),
      key: "transactionCreatedAt",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.reservationType"),
      key: "parkingMethod",
      verticalAlign: "center",
    },

    {
      value: t("reports.transactionsTable.start"),
      key: "startDateAndTime",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.end"),
      key: "endDateAndTime",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.timezone"),
      key: "timezone",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.initialRate"),
      key: "initialRate",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.completedRate"),
      key: "rate",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.amount"),
      key: "amount",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.citifydFees"),
      key: "citifydFees",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.procFees"),
      key: "procFees",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.feeSub"),
      key: "feeSub",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.netRevenue"),
      key: "netRevenue",
      verticalAlign: "center",
    },

    {
      value: t("reports.transactionsTable.license"),
      key: "license",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.property"),
      key: "property",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.state"),
      key: "state",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.parker"),
      key: "parker",
      verticalAlign: "center",
    },
    {
      value: t("reports.transactionsTable.phoneNumber"),
      key: "phoneNumber",
      verticalAlign: "center",
    },
  ];
};
