"use strict";

import React, { createContext, useContext, useEffect, useState } from "react";
import { checkStripeConnectedAccountsStatus } from "../../api";
import { useAuthentication } from "./authentication";

const StripeStatusContext = createContext();

export const StripeStatusProvider = ({ children }) => {
  const { user } = useAuthentication();
  const [stripeStatus, setStripeStatus] = useState(null);

  const reloadStripeStatus = async () => {
    if (!user) {
      clearStripeStatus();
      return;
    }

    try {
      const response = await checkStripeConnectedAccountsStatus();
      setStripeStatus(response.data.result);
    } catch (err) {
      console.log("Error loading Stripe connected accounts status", err);
    }
  };

  const clearStripeStatus = () => {
    setStripeStatus(null);
  };

  useEffect(() => {
    reloadStripeStatus();
  }, [user]);

  return (
    <StripeStatusContext.Provider
      value={{ clearStripeStatus, reloadStripeStatus, stripeStatus }}
    >
      {children}
    </StripeStatusContext.Provider>
  );
};

export const useStripeStatus = () => useContext(StripeStatusContext);
