"use strict";

import React, { useEffect, useMemo, useState } from "react";
import { Text, Grid } from "@citifyd/style";

import { useService, useTranslator } from "../../shared/react/hooks";
import {
  useCurrencies,
  changeQueryParams,
  useLots,
  useOrganizationsOptions,
} from "./hooks";
import useLoadTracking from "../../shared/react/hooks/useLoadTracking";
import styles from "./Reports.module.scss";

import BoxContent from "../../shared/react/components/BoxContent";
import LoadingManager from "../../shared/react/components/LoadingManager";
import PageHeader from "../../shared/react/components/PageHeader";
import RatesReport from "./components/Rates";
import Revenue from "../../shared/react/components/Revenue";
import SelectReport from "./components/SelectReport";
import SummaryReport from "./components/Summary";
import TransactionsReport from "./components/Transactions";
import RevenueByPropertyReport from "./components/RevenueByPropertyReport/RevenueByPropertyReport";

const Reports = () => {
  const t = useTranslator();
  const organizationsOptions = useOrganizationsOptions();
  const loadTracking = useLoadTracking();
  const { isLoading } = loadTracking;

  const $state = useService("$state");
  const [currencies, currenciesError] = useCurrencies(loadTracking);
  const [lots, lotsError] = useLots(loadTracking);

  const [selectedReport, setSelectedReport] = useState(
    $state.params.reportType
  );

  const title = useMemo(() => {
    if (selectedReport === "summary") return t("reports.summaryReport");
    if (selectedReport === "revenueByProperty")
      return t("reports.revenueByProperty");

    if (selectedReport === "transactions")
      return t("reports.transactionsReport");
    if (selectedReport === "rates") return t("reports.ratesReport");
    if (selectedReport === "daily") return t("revenue.revenuePerDay");
    if (selectedReport === "events") return t("revenue.revenuePerEvent");
    if (selectedReport === "payouts") return t("revenue.payoutHistory");
  }, [selectedReport]);

  useEffect(() => {
    if (selectedReport !== null) {
      changeQueryParams({ reportType: selectedReport });
    }
  }, [selectedReport]);

  useEffect(() => {
    if ($state.params.reportType) {
      setSelectedReport($state.params.reportType);
    }
  }, [$state.params.reportType]);

  const hasError = currenciesError || lotsError;

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        loadingMessage={t("reports.loading")}
        hasError={hasError}
      />
    );
  }

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title weight="300" title={t("reports.reports")} />
      </PageHeader>

      <SelectReport
        selectedReport={selectedReport}
        onChange={(report) => {
          setSelectedReport(report);
        }}
      />

      <Title title={title} selectedReport={selectedReport} />

      {!selectedReport && (
        <Text variant="subtitle" textAlign="center">
          {t("reports.chooseReport")}
        </Text>
      )}
      {selectedReport && (
        <BoxContent smallRadius>
          <BoxContent.Content>
            {selectedReport === "summary" && (
              <SummaryReport
                currencies={currencies}
                lots={lots}
                organizationsOptions={organizationsOptions}
              />
            )}
            {selectedReport === "revenueByProperty" && (
              <RevenueByPropertyReport
                lots={lots}
                organizationsOptions={organizationsOptions}
              />
            )}
            {selectedReport === "transactions" && (
              <TransactionsReport
                organizationsOptions={organizationsOptions}
                lots={lots}
              />
            )}
            {selectedReport === "rates" && (
              <RatesReport
                lots={lots}
                organizationsOptions={organizationsOptions}
              />
            )}
            {(selectedReport === "daily" ||
              selectedReport === "events" ||
              selectedReport === "payouts") && (
              <Revenue
                organizationsOptions={organizationsOptions}
                currencies={currencies}
                lots={lots}
                selectedReport={selectedReport}
                setSelectedReport={setSelectedReport}
              />
            )}
          </BoxContent.Content>
        </BoxContent>
      )}
    </Grid>
  );
};

const Title = ({ title, selectedReport }) => {
  const $state = useService("$state");

  return selectedReport === "transactions" && $state.params._origin ? (
    <PageHeader>
      <PageHeader.Title weight="300" title={title} backState />
    </PageHeader>
  ) : (
    <Text variant="h1" weight="300" className={styles.text} gutterBottom>
      {title}
    </Text>
  );
};

export default Reports;
