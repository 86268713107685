"use strict";
import React from "react";
import { Grid } from "@citifyd/style";

import { PermissionCompanies } from "./PermissionCompanies/PermissionCompanies";
import { PermissionInternalUsers } from "./PermissionInternalUsers/PermissionInternalUsers";
import PageHeader from "../../shared/react/components/PageHeader";

import Permissions from "../../shared/services/permissions";

import { useAuthentication } from "../../shared/react/contexts/authentication";
import { useTranslator } from "../../shared/react/hooks";

const PAGE_SIZE = 10;

const AccountPermissions = () => {
  const t = useTranslator();
  const { permissions } = useAuthentication();

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          weight="300"
          defaultState="account"
          title={t("accountPermissions.pageTitle")}
        />
      </PageHeader>
      {Permissions.userMayManageAccount(permissions) && (
        <PermissionInternalUsers pageSize={PAGE_SIZE} />
      )}
      {Permissions.userMayManageAccountAttendant(permissions) && (
        <PermissionCompanies pageSize={PAGE_SIZE} />
      )}
    </Grid>
  );
};

export default AccountPermissions;
