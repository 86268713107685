import { useEffect, useMemo, useRef, useState } from "react";
import { useAdminLotsQuery } from "../../../graphql/generated/graphql";
import { useTranslator } from "../../../shared/react/hooks";
import ApolloClient from "../../../shared/services/apolloClient";
import { LotOption } from "./types";

const apolloClient = ApolloClient();

export const useLotOptions = () => {
  const t = useTranslator();

  const populatedInitialSelectedLots = useRef<boolean>(false);
  const [selectedLots, setSelectedLots] = useState<LotOption[]>([]);

  const { data, loading } = useAdminLotsQuery({
    client: apolloClient,
  });

  const lotsOptions = useMemo(
    () =>
      (data?.admin_lots?.edges ?? []).map((lot) => ({
        value: lot.node.id,
        label: lot.node.name,
      })),
    [data?.admin_lots?.edges]
  );

  useEffect(() => {
    if (populatedInitialSelectedLots.current || !lotsOptions.length) return;

    populatedInitialSelectedLots.current = true;
    setSelectedLots([
      { value: "*", label: t("commonTexts.allProperties") },
      ...lotsOptions,
    ]);
  }, [lotsOptions]);

  return { lotsOptions, selectedLots, setSelectedLots, loadingLots: loading };
};
