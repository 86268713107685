"use strict";
import React from "react";
import { Text } from "@citifyd/style";
import { formatValue } from "./functions/utils";

const Total = ({ value, currency }) => {
  return (
    <Text textAlign="right" appearance={value >= 0 ? "default" : "tertiary"}>
      {formatValue({
        value,
        currency,
      })}
    </Text>
  );
};

export default Total;
