"use strict";
import React, { useMemo, useState } from "react";
import { Modal, Button, LoadingIndicator, Text, Icon } from "@citifyd/style";
import { useTranslator } from "../../react/hooks";

const GenerateReportModal = ({
  open,
  onClose,
  fileFormat,
  filterDescription,
  reportCsvRequest,
  disableFilterDescriptionFirstCharacterLowercase,
}) => {
  const [step, setStep] = useState("confirm");

  const adjustedFilterDescription = useMemo(() => {
    return disableFilterDescriptionFirstCharacterLowercase
      ? filterDescription
      : filterDescription[0].toLowerCase() + filterDescription.substr(1);
  }, [filterDescription]);

  return (
    <Modal open={open} onClose={onClose}>
      {step === "confirm" && (
        <Confirm
          onClose={onClose}
          setStep={setStep}
          fileFormat={fileFormat}
          reportCsvRequest={reportCsvRequest}
          adjustedFilterDescription={adjustedFilterDescription}
        />
      )}
      {step === "generating" && <Generating />}
      {step === "generated" && (
        <Generated
          adjustedFilterDescription={adjustedFilterDescription}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

const Confirm = ({
  setStep,
  fileFormat,
  adjustedFilterDescription,
  onClose,
  reportCsvRequest,
}) => {
  const t = useTranslator();

  fileFormat = fileFormat.toLowerCase();

  // Generate report
  const generate = () => {
    if (fileFormat === "csv" || fileFormat === "pdf") {
      setStep("generating");

      return reportCsvRequest
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `file.${fileFormat}`);
          document.body.appendChild(link);
          link.click();
          setStep("generated");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Modal.Title>{t("modalGenerateReport.createReportTitle")}</Modal.Title>
      <Modal.Content>
        <Text gutterBottom textAlign="center" variant="subtitle">
          {t("modalGenerateReport.createReportText", {
            reportDescription: adjustedFilterDescription,
            fileFormat: fileFormat.toUpperCase(),
          })}
        </Text>
        <Text gutterBottom textAlign="center" variant="subtitle">
          {t("modalGenerateReport.customize")}
        </Text>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onMouseDown={onClose}
          type="button"
          fullWidth
          justifyContent="center"
          uppercase
          textColor="white"
          appearance="secondary"
        >
          {t("commonButtons.cancel")}
        </Button>
        <Button
          type="button"
          fullWidth
          justifyContent="center"
          uppercase
          onMouseDown={generate}
        >
          {t("commonButtons.continue")}
        </Button>
      </Modal.Actions>
    </>
  );
};

const Generating = () => {
  const t = useTranslator();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <>
      <Modal.Title>{t("modalGenerateReport.generatingTitle")}</Modal.Title>
      <Modal.Content>
        <Text gutterBottom textAlign="center" variant="subtitle">
          {t("modalGenerateReport.generatingText")}
        </Text>
        {isSafari && (
          <Text gutterBottom textAlign="center" variant="subtitle">
            {t("modalGenerateReport.generatingSafariAdvice")}
          </Text>
        )}

        <LoadingIndicator />
      </Modal.Content>
    </>
  );
};

const Generated = ({ onClose, adjustedFilterDescription }) => {
  const t = useTranslator();
  return (
    <>
      <Modal.Title>{t("modalGenerateReport.generatedTitle")}</Modal.Title>
      <Modal.Content>
        <Text textAlign="center" gutterBottom>
          <Icon
            pull={null}
            appearance="secondary"
            icon={["far", "check-circle"]}
            size="3x"
          />
        </Text>

        <Text gutterBottom textAlign="center" variant="subtitle">
          {t("modalGenerateReport.generatedText", {
            reportDescription: adjustedFilterDescription,
          })}
        </Text>
      </Modal.Content>
      <Modal.Actions display="block">
        <Button
          type="button"
          justifyContent="center"
          uppercase
          onMouseDown={onClose}
          extraPadding
        >
          {t("commonButtons.ok")}
        </Button>
      </Modal.Actions>
    </>
  );
};

export default GenerateReportModal;
