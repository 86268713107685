"use strict";

import { some } from "lodash";

const hasPermission = (permissions, permissionKey) => {
  return some(permissions, (p) => p.key === permissionKey);
};

export default {
  // Snapshots
  userMayAccessSnapshots: (permissions) => {
    return hasPermission(permissions, "view-snapshots");
  },

  userMayAccessEnforcement: (permissions) => {
    return hasPermission(permissions, "enforcements");
  },
  // Schedule
  userMayAccessSchedule: (permissions) => {
    return (
      hasPermission(permissions, "view-schedule") ||
      hasPermission(permissions, "manage-events") ||
      hasPermission(permissions, "manage-user-tickets") ||
      hasPermission(permissions, "monitor-events")
    );
  },

  userMayEditEvent: (permissions) => {
    return (
      hasPermission(permissions, "manage-events") ||
      hasPermission(permissions, "manage-user-tickets") ||
      hasPermission(permissions, "monitor-events")
    );
  },

  // Add event
  userMayCreateEvent: ({ permissions, hasEventCreationAccess, user }) => {
    return (
      user?.isAdmin ||
      (hasPermission(permissions, "manage-events") && hasEventCreationAccess)
    );
  },

  userMayAppendLotToEvent: ({ permissions, hasEventCreationAccess, user }) => {
    return (
      !user?.isAdmin &&
      hasPermission(permissions, "manage-events") &&
      !hasEventCreationAccess
    );
  },

  userMaySeeAllVenues: (permissions) => {
    return hasPermission(permissions, "manage-all-venues");
  },

  // Reports
  userMayAccessReports: (permissions) => {
    return (
      hasPermission(permissions, "view-reports") ||
      hasPermission(permissions, "view-revenue")
    );
  },

  // Permits
  userMaySeePermits: (permissions) => {
    return (
      hasPermission(permissions, "view-permits") ||
      hasPermission(permissions, "manage-permit-portals")
    );
  },

  // Account
  userMayEditOrganizationInformation: (permissions) => {
    return hasPermission(permissions, "manage-organization-information");
  },

  userMayChangeAccountInformation: (permissions) => {
    return hasPermission(permissions, "account-settings");
  },

  userMayChangeAccountPermissions: (permissions) => {
    return (
      hasPermission(permissions, "create-attendant") ||
      hasPermission(permissions, "create-management")
    );
  },

  userMayManageAccountAttendant: (permissions) => {
    return hasPermission(permissions, "create-attendant");
  },

  userMayManageAccount: (permissions) => {
    return hasPermission(permissions, "create-management");
  },

  userMayChangePaymentInformation: (permissions) => {
    return hasPermission(permissions, "manage-payment-information");
  },

  // Properties
  userMaySeeLots: (permissions) => {
    return (
      hasPermission(permissions, "see-lots") ||
      hasPermission(permissions, "manage-lots")
    );
  },

  userMayCreateLot: (permissions) => {
    return hasPermission(permissions, "manage-lots");
  },

  userMayEditLot: (permissions) => {
    return hasPermission(permissions, "manage-lots");
  },

  userMayGenerateUsersCsv: (permissions) => {
    return hasPermission(permissions, "generate-users-csv");
  },

  // Lime
  userMayAccessLimeDashboard: (permissions, user) => {
    return hasPermission(permissions, "lime:access") || user?.isAdmin;
  },

  // POS
  userMayAccessRover: (permissions, user) => {
    return hasPermission(permissions, "pos:access") || user?.isAdmin;
  },

  userMayManageLotAvailabilitiesPosRates: (permissions) => {
    return hasPermission(permissions, "manage-lot-availabilities-pos-rates");
  },

  // Live
  userMayAccessCitifydLive: (permissions) => {
    return hasPermission(permissions, "access-citifyd-live");
  },

  userMaySeeUserInformation: (permissions) => {
    return (
      hasPermission(permissions, "view-user-information") ||
      hasPermission(permissions, "create-parker-signup-invitations")
    );
  },

  userMaySeeAllProperties: (permissions) => {
    return hasPermission(permissions, "see-all-lots");
  },

  // Citifyd Admin
  userMayUpdateLotAvailabilityBarcodeActivation: (permissions) => {
    return hasPermission(
      permissions,
      "manage-lot-availabilities-barcode-activation"
    );
  },

  userMayManageReferralCodes: (permissions) => {
    return hasPermission(permissions, "manage-referral-codes");
  },

  userMayConfigureGates: (permissions) => {
    return hasPermission(permissions, "configure-gates");
  },

  userMayAuthenticateAsOthers: (permissions) => {
    return hasPermission(permissions, "impersonate-users");
  },

  userMayDestroySessions: (permissions) => {
    return hasPermission(permissions, "manage-sessions");
  },

  userMayDisableLotAvailabilityServiceFee: (permissions) => {
    return hasPermission(permissions, "disable-service-fee");
  },

  userMayChangeSMSCode: (permissions) => {
    return hasPermission(permissions, "manage-sms-code");
  },

  userMayManageMigrationTickets: (permissions) => {
    return hasPermission(permissions, "manage-migration-tickets");
  },

  userMayChangeLotUnavailableMessage: (permissions) => {
    return hasPermission(permissions, "manage-lot-unavailable-message");
  },

  userMaySeeParkingOperatorFilter: (permissions) => {
    return hasPermission(permissions, "see-parking-operator-filter");
  },

  userMayExportPdf: (permissions) => {
    return hasPermission(permissions, "export-pdf");
  },

  // Refunds
  userMayCreateRefunds: (permissions) => {
    return hasPermission(permissions, "create-refunds");
  },

  userMayRefundSubscription: (permissions) => {
    return hasPermission(permissions, "refund-subscription");
  },

  // Receipts
  userMayExportReceipts: (permissions) => {
    return hasPermission(permissions, "export-receipts");
  },

  // Tickets
  userMayManageUserTickets: (permissions) => {
    return hasPermission(permissions, "manage-user-tickets");
  },

  userMayCreateAssistedPurchases: (permissions) => {
    return hasPermission(permissions, "create-assisted-purchases");
  },

  userMayOpenReservationPage: () => {
    return true;
  },

  userMayOpenReservedParkingPassPage: () => {
    return true;
  },

  userMayOpenSubscriptionPage: () => {
    return true;
  },

  userMayOpenTicketPage: () => {
    return true;
  },

  userMayOpenBundlePurchasePage: () => {
    return true;
  },

  userMayManageAllVenues: ({ permissions }) => {
    return hasPermission(permissions, "manage-all-venues");
  },
};
