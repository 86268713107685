import { Button, Grid, Text } from "@citifyd/style";
import React, { useMemo, useState } from "react";
import { useAgentsQuery } from "../../../graphql-pos/generated/graphql";
import { RoverEditAgentModal } from "../../../shared/modals/RoverEditAgentModal/RoverEditAgentModal";
import BoxContent from "../../../shared/react/components/BoxContent";
import SearchInput from "../../../shared/react/components/SearchInput";
import { useModal } from "../../../shared/react/contexts/modal";
import { useTranslator } from "../../../shared/react/hooks";
import { normalize } from "../../../shared/services/helper";
import PosApolloClient from "../../../shared/services/posApolloClient";
import styles from "./Agents.module.scss";

const posApolloClient = PosApolloClient();

export const Agents = () => {
  const t = useTranslator();
  const { openModal } = useModal();

  const [search, setSearch] = useState("");

  const { data: agents } = useAgentsQuery({ client: posApolloClient });

  const filteredAgents = useMemo(() => {
    if (!agents) return null;

    const normalizedSearch = normalize(search);
    const searchItem = (item) =>
      normalize(String(item)).includes(normalizedSearch);

    return agents.agents.filter((agent) => searchItem(agent?.name));
  }, [agents, search]);

  return (
    <div className={styles.container}>
      <Grid>
        <Grid.Row marginBottom>
          <Grid.Col sm={3} offset={{ sm: 9 }}>
            <div className={styles.searchContainer}>
              <SearchInput
                className={styles.search}
                labelSize="small"
                value={search}
                placeholder="search"
                onClear={() => setSearch("")}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <Button
                size="small"
                letterSpacing
                uppercase
                onClick={() => openModal(RoverEditAgentModal)}
              >
                {t("posRover.commons.add")}
              </Button>
            </div>
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          {filteredAgents?.map((agent) => (
            <Grid.Col xs={12} sm={4} md={3} lg={2}>
              <BoxContent>
                <BoxContent.Content>
                  <Text gutterBottom textAlign="center" variant="subtitle">
                    {agent.name}
                  </Text>
                  <div>
                    <Button
                      fullWidth
                      justifyContent="center"
                      size="small"
                      onClick={() => openModal(RoverEditAgentModal, { agent })}
                    >
                      {t("posRover.commons.manage")}
                    </Button>
                  </div>
                </BoxContent.Content>
              </BoxContent>
            </Grid.Col>
          ))}
        </Grid.Row>
      </Grid>
    </div>
  );
};
