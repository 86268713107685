"use strict";

import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";

let stripePromise;

export const loadStripeOnce = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }

  return stripePromise;
};

export const useStripePromise = () => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripeOnce());
  }, []);

  return stripePromise;
};
