"use strict";

import React from "react";
import { Text } from "@citifyd/style";

import Table from "../../../../shared/react/components/Table";
import { useTranslator } from "../../../../shared/react/hooks";
import styles from "./TicketRefundDestination.module.scss";
import { getData } from "./utils";

const TicketRefundDestination = React.memo(
  ({ currency, refundDestinations, isCashPayment, canSeeCardExpiration }) => {
    const t = useTranslator();

    const { columns, data } = getData(
      refundDestinations,
      canSeeCardExpiration,
      currency
    );

    return (
      <div className={styles.refundDestination}>
        {!!refundDestinations?.length && (
          <>
            <Text component="h5" variant="subtitle" gutterBottom>
              {t("refundPages.refundDestination")}
            </Text>
            <Table columns={columns} responsive data={data} />
          </>
        )}

        {isCashPayment && <div>{t("ticketRefund.cashRefundDisclaimer")}</div>}
      </div>
    );
  }
);

export default TicketRefundDestination;
