"use strict";

import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { LoadingIndicator } from "@citifyd/style";
import EventsSelector from "../../../../shared/react/components/EventsSelector";
import { getEvents } from "./utils";
import Footer from "../Footer/Footer.jsx";
import { getAvailableEventsForAssistedPurchase } from "../../../../shared/api.js";

const EventSelection = ({ steps, setSteps }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [bundleGroups, setBundleGroups] = useState([]);
  const [eventsSelected, setEventsSelected] = useState([]);
  const [bundlesSelected, setBundlesSelected] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const filteredEvents = useMemo(() => {
    return getEvents(bundlesSelected, events, eventsSelected).newEvents;
  }, [bundlesSelected, events, eventsSelected]);

  const handleSelectEvent = (item) => {
    const isSelected = eventsSelected.find((event) => event.id === item.id);

    const events = isSelected
      ? eventsSelected.filter((event) => event.id !== item.id)
      : [...eventsSelected, item];

    setEventsSelected(events);
  };

  const handleSelectBundle = (item) => {
    const isSelected = bundlesSelected.find((bundle) => bundle.id === item.id);

    const bundles = isSelected
      ? bundlesSelected.filter((bundle) => bundle.id !== item.id)
      : [...bundlesSelected, item];

    setBundlesSelected(bundles);

    const { newSelectedEvents } = getEvents(bundles, events, eventsSelected);

    setEventsSelected(newSelectedEvents);
  };

  const mountBundleGroups = (bundles) => {
    bundles.forEach((bundle) => {
      if (!bundle.groupName) {
        bundle.groupName = Math.random();
        bundle.noGroup = true;
      }
    });

    let bundlesByGroup = _.groupBy(bundles, "groupName");

    return _.map(bundlesByGroup, (bundles, groupName) => {
      return {
        name: bundles[0].noGroup ? bundles[0].name : groupName,
        bundles: bundles,
        id: bundles[0].id,
      };
    });
  };

  const parseEvents = (events) => {
    return events.map((event) => {
      event.lots.forEach((lot) => {
        const regular = _.find(lot.spaces, { name: "regular" });
        const max = _.get(regular, "max", 0);
        const occupied = _.get(regular, "occupied", 0);

        lot.soldOut = max - occupied === 0;
      });

      event.soldOut = _.every(event.lots, "soldOut");

      return event;
    });
  };

  const load = async () => {
    // has the user already been through this step and came back?
    if (steps.data.eventSelected) {
      const { events, bundleGroups, eventsSelected, bundlesSelected } =
        steps.data.eventSelected;
      setEvents(events);
      setBundleGroups(bundleGroups);
      setEventsSelected(eventsSelected);
      setBundlesSelected(bundlesSelected);
    } else {
      setIsLoading(true);
      const response = await getAvailableEventsForAssistedPurchase();

      setEvents(parseEvents(response.events));
      setBundleGroups(mountBundleGroups(response.bundles));
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    if (eventsSelected.length || bundlesSelected.length) {
      setSteps({
        step: "lot_selection",
        data: {
          userInfo: steps.data.userInfo,
          eventSelected: {
            events,
            bundleGroups,
            eventsSelected,
            bundlesSelected,
          },
        },
      });
    } else {
      setSteps({
        step: "confirmation",
        data: { userInfo: steps.data.userInfo },
      });
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator isLoading={isLoading} />}

      {!isLoading && (
        <EventsSelector
          events={filteredEvents}
          bundleGroups={bundleGroups}
          handleSelectEvent={handleSelectEvent}
          handleSelectBundle={handleSelectBundle}
          selectedEvents={eventsSelected}
          selectedBundles={bundlesSelected}
        />
      )}
      <Footer handleSubmit={handleSubmit} />
    </>
  );
};

export default EventSelection;
