import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

(function () {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
})();
