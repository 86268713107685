"use strict";
import React, { useMemo, useState } from "react";
import { Icon, LoadingIndicator } from "@citifyd/style";

import {
  AccountPermissionType,
  useAdminGetAccountPermissionCompaniesQuery,
  useAdminGetAccountPermissionUserInvitationsQuery,
} from "../../../graphql/generated/graphql";

import { useTranslator } from "../../../shared/react/hooks";
import { useLotOptions } from "./useLotOptions";
import ApolloClient from "../../../shared/services/apolloClient";

import BoxContent from "../../../shared/react/components/BoxContent";
import RouterLinkButton from "../../../shared/react/components/RouterLinkButton";
import { ActivePermissionCompanies } from "./ActivePermissionCompanies";
import { PendingPermissionCompanies } from "./PendingPermissionCompanies";

import styles from "../AccountPermissions.module.scss";

interface PermissionCompaniesProps {
  pageSize: number;
}

const apolloClient = ApolloClient();

export const PermissionCompanies = ({ pageSize }: PermissionCompaniesProps) => {
  const t = useTranslator();

  const [debouncedSearch, setDebouncedSearch] = useState("");

  const { lotsOptions, selectedLots, setSelectedLots, loadingLots } =
    useLotOptions();

  const selectedLotsToBackned = useMemo(
    () =>
      selectedLots
        .filter(({ value }) => value !== "*")
        .map((lot) => lot.value as number),
    [selectedLots]
  );

  const {
    data: permissionCompanies,
    loading: loadingCompanies,
    refetch: refetchPermissionCompanies,
  } = useAdminGetAccountPermissionCompaniesQuery({
    variables: {
      first: pageSize,
      search: debouncedSearch,
      lotIds:
        lotsOptions.length === selectedLotsToBackned.length
          ? []
          : selectedLotsToBackned,
    },
    client: apolloClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const {
    data: pendingData,
    loading: loadingPendingCompanies,
    refetch: refetchPendingPermissionCompanies,
  } = useAdminGetAccountPermissionUserInvitationsQuery({
    variables: {
      first: pageSize,
      type: AccountPermissionType["Company"],
    },
    client: apolloClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const companiesData = permissionCompanies?.getAccountPermissionCompanies;
  const pendingCompanyData = pendingData?.getAccountPermissionUserInvitations;

  return (
    <BoxContent>
      <BoxContent.Header
        title={t("accountPermissions.permissionCompanies.pageTitle")}
      >
        <BoxContent.HeaderActions>
          <RouterLinkButton
            state="add-attendant-monitoring-company"
            size="small"
            letterSpacing
            uppercase
          >
            {t("commonButtons.add")} <Icon icon="plus-circle" pull="none" />
          </RouterLinkButton>
        </BoxContent.HeaderActions>
      </BoxContent.Header>

      <BoxContent.Content>
        <div className={styles.container}>
          <ActivePermissionCompanies
            loading={loadingLots || loadingCompanies}
            lotsOptions={lotsOptions}
            pageSize={pageSize}
            permissionCompanies={companiesData}
            refetch={refetchPermissionCompanies}
            setDebouncedSearch={setDebouncedSearch}
            selectedLots={selectedLots}
            setSelectedLots={setSelectedLots}
          />

          <PendingPermissionCompanies
            pageSize={pageSize}
            refetch={refetchPendingPermissionCompanies}
            loading={loadingPendingCompanies}
            pendingPermissions={pendingCompanyData}
          />

          {(!companiesData ||
            !pendingCompanyData ||
            loadingCompanies ||
            loadingPendingCompanies) && <LoadingIndicator withOverlay />}
        </div>
      </BoxContent.Content>
    </BoxContent>
  );
};
