import React from "react";
import { Field } from "formik";
import {
  Checkbox,
  Input,
  Radio,
  Textarea,
  Label,
  Select,
  CreatableSelect,
  MultiDatePicker,
  MultiSelect,
} from "@citifyd/style";
import SimpleTimeInput from "react-simple-time-input";
import CurrencyFormatter from "@citifyd/currency-formatter";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import { omit } from "lodash";

import FormikDatePicker from "../FormikDatePicker";
import { getCurrentLanguage } from "../../../services/languages";
import DaysOfWeek from "../DaysOfWeek";

export const FIELD_TYPE = {
  TEXTAREA: "textarea",
  INPUT: "input",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  SELECT: "select",
  MULTI_SELECT: "multiSelect",
  CREATABLE_SELECT: "creatableSelect",
  DATEPICKER: "datepicker",
  MULTI_DATEPICKER: "multiDatePicker",
  SIMPLE_TIME_INPUT: "simpleTimeInput",
  CURRENCY: "currency",
  CUSTOM: "custom",
  DAYS_OF_WEEK: "daysOfWeek",
};

const FormikField = (
  {
    name,
    label,
    placeholder,
    as,
    type = "text",
    fullWidth = true,
    description,
    required,
    info,
    currency,
    handleBlur,
    handleChange,
    customComponent,
    showErrorMessage = true,
    multiple,
    clockMode,
    requireTouchForError = true,
    hasError: hasErrorProp,
    ...props
  },
  ref
) => {
  const renderLabel = (hasError) => {
    if (
      label &&
      as !== FIELD_TYPE.CHECKBOX &&
      as !== FIELD_TYPE.RADIO &&
      as !== FIELD_TYPE.MULTI_DATEPICKER
    ) {
      return (
        <Label error={hasError} required={required}>
          {label}
        </Label>
      );
    }

    return null;
  };

  const renderField = (field, hasError, errorMessage, form) => {
    const { onBlur, onChange, ...rest } = field;

    const fieldBlur = (e) => {
      onBlur(e);
      handleBlur && handleBlur();
    };

    const fieldChange = (e, i) => {
      if (as == FIELD_TYPE.MULTI_SELECT) {
        form.setFieldValue(field.name, e);
      } else if (as === FIELD_TYPE.SELECT) {
        form.setFieldValue(field.name, multiple ? e : e.target?.value);
      } else if (as === FIELD_TYPE.DAYS_OF_WEEK) {
        form.setFieldValue(field.name, {
          ...field.value,
          [e]: !field.value[e],
        });
      } else if (as === FIELD_TYPE.CREATABLE_SELECT) {
        form.setFieldValue(
          field.name,
          e.map((item) => ({ label: item.value, value: item.value }))
        );
      } else if (as === FIELD_TYPE.MULTI_DATEPICKER) {
        form.setFieldValue(field.name, e);
      } else if (as === FIELD_TYPE.SIMPLE_TIME_INPUT) {
        form.setFieldValue(field.name, e);
      } else {
        onChange(e);
      }
      handleChange && handleChange(e);
    };

    switch (as) {
      case FIELD_TYPE.TEXTAREA:
        return (
          <Textarea
            fullWidth
            placeholder={placeholder}
            error={hasError}
            errorMessage={showErrorMessage && errorMessage}
            onChange={fieldChange}
            onBlur={fieldBlur}
            ref={ref}
            {...rest}
            {...props}
          />
        );
      case FIELD_TYPE.SELECT:
        return (
          <Select
            fullWidth={fullWidth}
            placeholder={placeholder}
            error={hasError}
            multiple={multiple}
            errorMessage={showErrorMessage && errorMessage}
            onChange={fieldChange}
            onBlur={fieldBlur}
            ref={ref}
            {...rest}
            {...props}
          />
        );

      case FIELD_TYPE.MULTI_SELECT:
        return (
          <MultiSelect
            allItemsLabel={props.allItemsLabel}
            fullWidth={fullWidth}
            placeholder={placeholder}
            label={props.label}
            labelSize={props.labelSize}
            error={hasError}
            errorMessage={showErrorMessage && errorMessage}
            onChange={fieldChange}
            onBlur={fieldBlur}
            ref={ref}
            {...rest}
            {...props}
          />
        );

      case FIELD_TYPE.DAYS_OF_WEEK:
        return (
          <DaysOfWeek
            {...props}
            fullWidth={fullWidth}
            daysOfWeek={field.value}
            onChange={fieldChange}
            error={hasError}
            errorMessage={showErrorMessage && errorMessage}
          />
        );
      case FIELD_TYPE.SIMPLE_TIME_INPUT:
        return (
          <SimpleTimeInput
            {...props}
            onValueChange={fieldChange}
            clockMode={clockMode}
            value={field.value}
            as={(props) => (
              <Input
                fullWidth
                error={hasError}
                errorMessage={showErrorMessage && errorMessage}
                {...props}
              ></Input>
            )}
          />
        );
      case FIELD_TYPE.CREATABLE_SELECT:
        return (
          <CreatableSelect
            fullWidth={fullWidth}
            placeholder={placeholder}
            error={hasError}
            onChange={fieldChange}
            onBlur={fieldBlur}
            ref={ref}
            {...rest}
            {...props}
          />
        );
      case FIELD_TYPE.RADIO:
        return (
          <Radio
            label={label}
            placeholder={placeholder}
            checked={field.value}
            error={hasError}
            onChange={fieldChange}
            ref={ref}
            {...rest}
            {...props}
          />
        );
      case FIELD_TYPE.CHECKBOX:
        return (
          <Checkbox
            label={label}
            checked={field.value}
            placeholder={placeholder}
            error={hasError}
            errorMessage={showErrorMessage && errorMessage}
            onChange={fieldChange}
            ref={ref}
            {...rest}
            {...props}
          />
        );
      case FIELD_TYPE.DATEPICKER:
        return (
          <FormikDatePicker
            error={hasError}
            errorMessage={showErrorMessage && errorMessage}
            className="disable-focus"
            onChange={handleChange}
            onBlur={fieldBlur}
            ref={ref}
            {...rest}
            {...props}
          />
        );
      case FIELD_TYPE.MULTI_DATEPICKER:
        return (
          <>
            <MultiDatePicker
              selectedDates={field.value}
              error={hasError}
              errorMessage={showErrorMessage && errorMessage}
              onChange={fieldChange}
              onBlur={fieldBlur}
              fullWidth={fullWidth}
              dateFormatter={(date) => moment(date).format("L")}
              label={label}
              ref={ref}
              {...rest}
              {...props}
            />
          </>
        );
      case FIELD_TYPE.CURRENCY:
        const language = getCurrentLanguage();
        return (
          <NumberFormat
            fullWidth={fullWidth}
            customInput={Input}
            prefix={CurrencyFormatter.getPrefix({ currency, language })}
            suffix={CurrencyFormatter.getSuffix({ currency, language })}
            thousandSeparator={false}
            decimalSeparator={CurrencyFormatter.getDecimalSeparator({
              language,
            })}
            onValueChange={(values) => {
              form.setFieldValue(name, values.value);
            }}
            onBlur={fieldBlur}
            fixedDecimalScale
            decimalScale={CurrencyFormatter.isZeroDecimal({ currency }) ? 0 : 2}
            allowNegative={false}
            isNumericString
            error={hasError}
            errorMessage={showErrorMessage && errorMessage}
            placeholder={placeholder}
            {...omit(rest, ["onChange"])}
            {...props}
          />
        );
      case FIELD_TYPE.CUSTOM:
        return customComponent({
          ...rest,
          ...props,
          onBlur: fieldBlur,
          onChange: fieldChange,
          fullWidth: true,
          placeholder,
          info,
          error: hasError,
          type,
          ref,
        });
      default:
        return (
          <Input
            onBlur={fieldBlur}
            onChange={fieldChange}
            fullWidth={fullWidth}
            type={type}
            placeholder={placeholder}
            errorMessage={showErrorMessage && errorMessage}
            error={hasError}
            ref={ref}
            {...rest}
            {...props}
          />
        );
    }
  };

  return (
    <Field name={name}>
      {({ field, meta, form }) => {
        let hasError;

        if (typeof hasErrorProp === "boolean") {
          hasError = hasErrorProp;
        } else {
          hasError = requireTouchForError
            ? meta.touched && meta.error
            : meta.error;
        }

        return (
          <>
            {renderLabel(hasError)}
            {info && <p>{info}</p>}
            {renderField(field, hasError, meta.error, form)}
            {description && <p>{description}</p>}
          </>
        );
      }}
    </Field>
  );
};

export default React.forwardRef(FormikField);
