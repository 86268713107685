"use strict";
import moment from "moment-timezone";
import {
  cloneDeep,
  filter,
  findWhere,
  flatten,
  groupBy,
  isEqual,
  includes,
  map,
  pairs,
  pluck,
  sortBy,
  some,
  sum,
  uniq,
  where,
} from "lodash";
import {
  getCurrentLanguage,
  getTranslator,
} from "../../shared/services/languages";

const mountAvailabilityGroup = (name, filter, availabilities, lotId) => {
  const t = getTranslator();
  let filteredAvailabilities = where(availabilities, filter);

  let availabilityGroups = groupBy(filteredAvailabilities, "timeRange");
  availabilityGroups = sortBy(pairs(availabilityGroups), "0");

  if (!availabilityGroups.length) {
    return [
      {
        identifier: name,
        name: name,
        filter: filter,
        daysOfWeek: null,
        startTime: null,
        endTime: null,
        availabilitiesIds: [],
        reservationCount: 0,
        spacesCount: 0,
        rate: null,
        isActive: () => {
          return false;
        },
      },
    ];
  }

  return map(availabilityGroups, (group) => {
    let availabilities = group[1];

    let daysOfWeek = availabilities.map((availability) => {
      return {
        number: availability.start.day(),
        name: moment(availability.start)
          .locale(getCurrentLanguage())
          .format("ddd"),
      };
    });

    daysOfWeek = sortBy(daysOfWeek, "number");
    daysOfWeek = daysOfWeek.map((day) => day.name);

    let reservationCount = sum(availabilities, (a) => {
      return a.reservationCount || 0;
    });

    const rates = uniq(
      availabilities.map((a) => a.lots[0].rate),
      isEqual
    );

    return {
      identifier: name + "-" + availabilities.map((a) => a.id).join(","),
      name: name,
      filter: filter,
      daysOfWeek: daysOfWeek.join(", "),
      startTime: t("defaultFormats.time", {
        date: availabilities[0].start,
      }),
      endTime: t("defaultFormats.time", {
        date: availabilities[0].end,
      }),
      availabilitiesIds: pluck(availabilities, "id"),
      reservationCount: reservationCount,
      spacesCount: countActiveSpaces(availabilities, lotId),
      rate: rates.length === 1 ? rates[0] : null,

      isActive: () => {
        let now = moment().unix();

        return some(availabilities, (availability) => {
          return (
            now >= availability.start.unix() && now <= availability.end.unix()
          );
        });
      },
    };
  });
};

const countActiveSpaces = (availabilities) => {
  let now = moment().unix();

  let active = filter(availabilities, (availability) => {
    return now >= availability.start.unix() && now <= availability.end.unix();
  });

  if (!active.length) {
    active = [availabilities[0]];
  }

  const lots = flatten(active.map((availability) => availability.lots));

  const spaces = sum(
    lots.map((lot) => findWhere(lot.spaces, { name: "regular" })),
    "max"
  );

  return spaces;
};

const moveDateToCurrentWeek = (date, timezoneName) => {
  return moment()
    .tz(timezoneName)
    .day(date.day())
    .hour(date.hour())
    .minute(date.minute())
    .second(date.second());
};

export const availabilityGroupGenerator = (
  lotId,
  availabilities,
  timezoneName
) => {
  const t = getTranslator();
  availabilities = cloneDeep(availabilities);

  availabilities.forEach((availability) => {
    // Transform "start" and "end" of availabilities into moment() objects
    availability.start = moment.tz(availability.start, timezoneName);
    availability.end = moment.tz(availability.end, timezoneName);

    // Move dates to current week (so that we can detect if the availability is
    // happening right now)
    availability.start = moveDateToCurrentWeek(
      availability.start,
      timezoneName
    );
    availability.end = moveDateToCurrentWeek(availability.end, timezoneName);

    // Add startTime and endTime properties to availabilities
    availability.startTime = availability.start.locale("en").format("HH:mm");
    availability.endTime = availability.end.locale("en").format("HH:mm");
    availability.timeRange =
      availability.startTime + "-" + availability.endTime;

    // Detects if it's weekday or weekend
    availability.type = includes([0, 6], availability.start.day())
      ? "weekend"
      : "weekday";
  });

  return flatten([
    mountAvailabilityGroup(
      t("schedule.availabilityGroupNames.weekday"),
      { type: "weekday", period: "day" },
      availabilities,
      lotId
    ),

    mountAvailabilityGroup(
      t("schedule.availabilityGroupNames.weeknight"),
      { type: "weekday", period: "evening" },
      availabilities,
      lotId
    ),

    mountAvailabilityGroup(
      t("schedule.availabilityGroupNames.weekend"),
      { type: "weekend", period: "day" },
      availabilities,
      lotId
    ),

    mountAvailabilityGroup(
      t("schedule.availabilityGroupNames.weekendEvening"),
      { type: "weekend", period: "evening" },
      availabilities,
      lotId
    ),
  ]);
};
