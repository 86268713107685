"use strict";

import React, { useMemo, useEffect, useState, Fragment } from "react";
import { AlertMessage, Grid, Text, ToggleButton } from "@citifyd/style";

import { useDispatch, useSelector, useStore, Provider } from "react-redux";
import { changeMode, fetchData } from "../redux/thunk-actions";
import createStore from "../redux/store";

import RulesTable from "./RulesTable.jsx";
import Preview from "./Preview.jsx";
import Footer from "./Footer.jsx";

import PageHeader from "../../../shared/react/components/PageHeader";
import BoxContent from "../../../shared/react/components/BoxContent";
import LoadingManager from "../../../shared/react/components/LoadingManager";

import { useService, useTranslator } from "../../../shared/react/hooks";
import { getTranslator } from "../../../shared/services/languages";

import { setPageUnloadConfirmation } from "../../../shared/services/helper";
import ButtonTab from "../../../shared/react/components/ButtonTab";
import styles from "../styles/EditPropertyAdvancedRatesPage.module.scss";
import { getAmountOfSteps } from "../../../shared/services/lot-setup";
import { useAuthentication } from "../../../shared/react/contexts/authentication";
import { lotSetup } from "../utils/lotSetup";

const Page = () => {
  const [store, setStore] = useState(null);
  useEffect(() => setStore(createStore()), []);

  if (!store) {
    return null;
  }

  return (
    <Provider store={store}>
      <PageContent />
    </Provider>
  );
};

const PageContent = () => {
  const dispatch = useDispatch();
  const t = useTranslator();
  const { permissions } = useAuthentication();
  const store = useStore();
  const isLoading = useSelector((state) => state.isLoading);
  const loadingMessage = useSelector((state) => state.loadingMessage);
  const rateDefinition = useSelector((state) => state.rateDefinition);
  const amountOfSteps = getAmountOfSteps(permissions);
  const lot = useSelector((state) => state.lot);

  const $stateParams = useService("$stateParams");
  const isLotSetup = lotSetup();

  useEffect(() => {
    const { lotId, advancedRateDefinitionId } = $stateParams;

    dispatch(fetchData({ lotId, advancedRateDefinitionId }));
  }, []);

  useEffect(() => {
    setPageUnloadConfirmation(() => {
      return generateUnsavedRuleConfirmationMessageIfNeeded(store);
    });
  }, []);

  return (
    <div>
      {!rateDefinition && (
        <LoadingManager isLoading={isLoading} loadingMessage={loadingMessage} />
      )}

      {rateDefinition && (
        <Fragment>
          <PageHeader>
            {isLotSetup ? (
              <PageHeader.Title
                defaultState="edit-property-step"
                defaultParams={{ lotId: $stateParams.lotId, step: 2 }}
                title={t(`addProperty.steps.step3`, {
                  steps: amountOfSteps,
                })}
              />
            ) : (
              <PageHeader.Title
                defaultState="schedule"
                defaultParams={{ property: $stateParams.lotId }}
              />
            )}
          </PageHeader>

          {!lot.advancedRateDefinitionEnabled && lot.setupIsComplete && (
            <AlertMessage className={styles.alertMessage}>
              {t("addProperty.youHaveSimpleRates")}
            </AlertMessage>
          )}
          <RulesEditor />
        </Fragment>
      )}
    </div>
  );
};

const RulesEditor = () => {
  const t = useTranslator();
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.mode);
  const isLoading = useSelector((state) => state.isLoading);
  const loadingMessage = useSelector((state) => state.loadingMessage);
  const editEnabled = useSelector((state) => state.editEnabled);
  const lot = useSelector((state) => state.lot);
  const $state = useService("$state");

  const actionButtons = useMemo(() => {
    return ["view", editEnabled && "edit", "preview"]
      .filter((item) => item)
      .map((item) => ({
        text: t(`advancedRateEditor.buttons.mode.${item}`),
        onClick: () => dispatch(changeMode(item)),
        active: item === mode,
      }));
  }, [mode, editEnabled]);

  return (
    <>
      <BoxContent>
        <BoxContent.Header
          title={t("advancedRateEditor.pageTitleWithLotName", {
            lotName: lot.name,
          })}
        >
          <BoxContent.HeaderActions>
            <div className={styles.toggleButton}>
              <Text className={styles.text} appearance="white">
                {t("advancedRateEditor.switchToSimpleRateSelection")}
              </Text>
              <ToggleButton
                onChange={() =>
                  $state.go(
                    `/properties/${lot.id}/step/3?switchingFromAdvanced=true`
                  )
                }
                light
                selected
              />
            </div>
          </BoxContent.HeaderActions>
        </BoxContent.Header>

        <BoxContent.Content noHorizontalPadding>
          <Grid>
            <Grid.Row className={styles.buttonsTab}>
              <Grid.Col>
                {actionButtons.map((item, index) => (
                  <ButtonTab
                    key={index}
                    label={item.text}
                    isActive={item.active}
                    onClick={item.onClick}
                  />
                ))}
              </Grid.Col>
            </Grid.Row>
          </Grid>

          {isLoading && (
            <LoadingManager
              isLoading={isLoading}
              loadingMessage={loadingMessage}
            />
          )}

          {!isLoading && (
            <Fragment>
              {["view", "edit"].includes(mode) && <RulesTable />}
              {mode === "preview" && <Preview />}
            </Fragment>
          )}
        </BoxContent.Content>
      </BoxContent>

      <Footer />
    </>
  );
};

const generateUnsavedRuleConfirmationMessageIfNeeded = (store) => {
  const t = getTranslator();
  const { hasUnsavedRules } = store.getState();
  if (hasUnsavedRules) {
    return t("advancedRateEditor.messages.exitWithoutSavingConfirmation");
  }
};

export default Page;
