import React from "react";
import { List, LoadingIndicator, Text } from "@citifyd/style";

import BoxContent from "../../../../shared/react/components/BoxContent";
import { DeviceQuery } from "../../../../graphql-pos/generated/graphql";

import { usePosRoverCtx } from "../../hooks/usePosRoverCtx";

export interface ProcessingDevicesProps {
  ids: string[];
  add: (id: string) => void;
  remove: (id: string) => void;
  includes: (id: string) => boolean;
}

export const Card = ({
  device,
  groupActions,
  listItem,
}: {
  device: DeviceQuery["device"];
  groupActions?: (variables?: any) => any;
  listItem?: (item?: any) => any;
}) => {
  const {
    devicesQuery: { processingDevices },
  } = usePosRoverCtx();

  return (
    <BoxContent>
      {processingDevices.includes(device.id) && (
        <LoadingIndicator withOverlay />
      )}

      <BoxContent.Content>
        <Text weight="600">
          #{device.shortCode} {device.name ?? ""}
        </Text>
        <List>
          <List.Divisor />
          {listItem && listItem(device)}
        </List>
      </BoxContent.Content>
      {groupActions && (
        <BoxContent.Footer appearance="white">
          {groupActions(device)}
        </BoxContent.Footer>
      )}
    </BoxContent>
  );
};
