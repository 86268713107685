import { useEffect, useState } from "react";
import { useService } from "../../shared/react/hooks";

const useDeletedAlert = () => {
  const [hasDeletedUser, setHasDeletedUser] = useState(false);
  const $state = useService("$state");

  useEffect(() => {
    if ($state.params.deleted) {
      setHasDeletedUser(true);
      setTimeout(() => {
        setHasDeletedUser(false);
        $state.transitionTo(
          $state.current.name,
          {},
          { location: "replace", notify: false, inherit: false }
        );
      }, 3000);
    }
  }, []);

  return hasDeletedUser;
};

export default useDeletedAlert;
