"use strict";
import React from "react";
import { useMemo } from "react";
import { validate } from "../../../services/password-validator";
import { useTranslator } from "../../hooks";
import { Icon, Text } from "@citifyd/style";
import styles from "./PasswordRulesIndicator.module.scss";

const PasswordRulesIndicator = ({ password, rules }) => {
  const t = useTranslator();
  const LABELS = {
    uppercase: t("passwordRulesIndicator.uppercase"),
    lowercase: t("passwordRulesIndicator.lowercase"),
    number: t("passwordRulesIndicator.number"),
    special: t("passwordRulesIndicator.special"),
  };
  const result = useMemo(
    () => rules && validate(password, rules),
    [password, rules]
  );

  if (!rules) {
    return null;
  }
  return (
    <ul className={styles.list}>
      {rules.min && (
        <Item
          validation={result.invalidMin}
          text={t("passwordRulesIndicator.min", { amount: rules.min })}
        />
      )}
      {rules.features.map((feature) => {
        const isInvalid = result.missingFeatures.find(
          (missingFeature) => missingFeature === feature
        );
        return (
          <Item key={feature} validation={isInvalid} text={LABELS[feature]} />
        );
      })}
    </ul>
  );
};

const Item = ({ validation, text }) => {
  return (
    <li className={styles.item}>
      {validation ? (
        <Icon icon="circle" size="h3" appearance="gray" />
      ) : (
        <Icon icon="check-circle" size="h3" appearance="success" />
      )}
      <Text component="span" variant="small" className={styles.text}>
        {text}
      </Text>
    </li>
  );
};

export default PasswordRulesIndicator;
