"use strict";
import React from "react";
import { Icon, LoadingIndicator } from "@citifyd/style";

import {
  AccountPermissionType,
  useAdminGetAccountPermissionInternalUsersQuery,
  useAdminGetAccountPermissionUserInvitationsQuery,
} from "../../../graphql/generated/graphql";
import { useTranslator } from "../../../shared/react/hooks";

import ApolloClient from "../../../shared/services/apolloClient";
import BoxContent from "../../../shared/react/components/BoxContent";
import RouterLinkButton from "../../../shared/react/components/RouterLinkButton";

import { ActivePermissionInternalUser } from "./ActivePermissionInternalUser";
import { PendingInternalUserInvitations } from "./PendingInternalUserInvitations";

import styles from "../AccountPermissions.module.scss";

const apolloClient = ApolloClient();

export const PermissionInternalUsers = ({ pageSize }: { pageSize: number }) => {
  const t = useTranslator();

  const {
    data: permissionInternalUser,
    loading: loadingPermissionInternalUser,
    refetch: refetchPermissionInternalUser,
  } = useAdminGetAccountPermissionInternalUsersQuery({
    variables: {
      first: pageSize,
    },
    client: apolloClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const {
    data: pendingPermissionData,
    loading: loadingPendingPermission,
    refetch: refetchPendingPermissionInternalUserInvitations,
  } = useAdminGetAccountPermissionUserInvitationsQuery({
    variables: {
      first: pageSize,
      type: AccountPermissionType["InternalUser"],
    },
    client: apolloClient,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const permissionInternalUserData =
    permissionInternalUser?.getAccountPermissionInternalUsers;
  const pendingInvitationsData =
    pendingPermissionData?.getAccountPermissionUserInvitations;

  return (
    <BoxContent>
      <BoxContent.Header
        title={t("accountPermissions.permissionInternalUser.pageTitle")}
      >
        <BoxContent.HeaderActions>
          <RouterLinkButton
            state="add-management-permission"
            size="small"
            letterSpacing
            uppercase
          >
            {t("commonButtons.add")} <Icon icon="plus-circle" pull="none" />
          </RouterLinkButton>
        </BoxContent.HeaderActions>
      </BoxContent.Header>

      <BoxContent.Content>
        <div className={styles.container}>
          <ActivePermissionInternalUser
            loading={loadingPermissionInternalUser}
            pageSize={pageSize}
            permissionInternalUser={permissionInternalUserData}
            refetch={refetchPermissionInternalUser}
          />

          <PendingInternalUserInvitations
            loading={loadingPendingPermission}
            pageSize={pageSize}
            pendingPermissions={pendingInvitationsData}
            refetch={refetchPendingPermissionInternalUserInvitations}
          />

          {(!permissionInternalUserData ||
            !pendingPermissionData ||
            loadingPermissionInternalUser ||
            loadingPendingPermission) && <LoadingIndicator withOverlay />}
        </div>
      </BoxContent.Content>
    </BoxContent>
  );
};
