"use strict";

import React from "react";
import { Label, Grid } from "@citifyd/style";
import { useFormikContext } from "formik";

import styles from "./Form.module.scss";
import InstantImageUpload from "../../../../shared/react/components/InstantImageUpload";
import { useTranslator } from "../../../../shared/react/hooks";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const Images = () => {
  const t = useTranslator();

  const { errors, touched } = useFormikContext();

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid.Row>
        <Grid.Col xs={4}>
          <Label
            variant="body"
            required
            error={errors?.images?.main && touched?.images?.main}
          >
            {t("addProperty.labels.mainPhoto")}
          </Label>
          <Image imageKey="main" />
        </Grid.Col>

        <Grid.Col xs={8}>
          <Label variant="body" style={{ marginLeft: "20px" }}>
            {t("addProperty.labels.additionalPhotos")}
          </Label>

          <div className={styles.smallImageGroup}>
            <Image imageKey="additional1" small />
            <Image imageKey="additional2" small />
            <Image imageKey="additional3" small />
          </div>

          <div className={styles.smallImageGroup}>
            <Image imageKey="additional4" small />
            <Image imageKey="additional5" small />
            <Image imageKey="additional6" small />
          </div>
        </Grid.Col>
      </Grid.Row>
    </DndProvider>
  );
};

const Image = ({ imageKey, small }) => {
  const { errors, touched, values, setValues } = useFormikContext();
  const { images } = values;

  const onChangeImage = (key, state) => {
    setValues((values) => ({
      ...values,
      images: { ...values.images, [key]: state },
    }));
  };

  const [, drag] = useDrag(() => ({
    type: "image",
    item: { imageKey },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        const from = item.imageKey;
        const to = dropResult.imageKey;

        if (from === to) return;

        setValues((values) => ({
          ...values,
          images: {
            ...values.images,
            [from]: values.images[to],
            [to]: values.images[from],
          },
        }));
      }
    },
  }));

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "image",
    drop: () => ({ imageKey }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div ref={drop} style={{ opacity: isOver && canDrop ? "0.5" : "1" }}>
      <div ref={drag} className={small ? styles.smallImage : null}>
        <InstantImageUpload
          small={small}
          state={images[imageKey]}
          onChangeState={(state) => onChangeImage(imageKey, state)}
          errorImage={errors?.images?.[imageKey]}
          touchedImage={touched?.images?.[imageKey]}
          uploadPresetSuffix="lots"
          defaultTransformation="t_on_demand_map_lot_image_2"
        />
      </div>
    </div>
  );
};

export default Images;
