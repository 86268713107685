import { PermissionOption } from "../../add-management-permission/usePermissionOptions";

export const transformPermissionsToFrontend = ({
  permissions,
  permissionOptions,
}: {
  permissions: string[];
  permissionOptions: PermissionOption[];
}) => {
  let result: string[] = [];

  for (let permissionOption of permissionOptions) {
    const includes = permissionOption.permissions.every((permission) =>
      permissions.includes(permission)
    );

    if (includes) {
      result.push(permissionOption.label);
    }
  }

  return result;
};
