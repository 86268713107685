"use strict";

import React, { useEffect, useRef, useState } from "react";
import {
  AlertMessage,
  Button,
  Label,
  Modal,
  Radio,
  Text,
  Tooltip,
} from "@citifyd/style";
import _ from "lodash";

import FormikProvider from "../../react//components/FormikProvider";
import FormikField, { FIELD_TYPE } from "../../react/components/FormikField";

import useEditPayment from "./useEditPayment";
import { useTranslator } from "../../react/hooks";
import useLoadTracking from "../../react/hooks/useLoadTracking";
import LoadingManager from "../../react/components/LoadingManager";

import { loadStripeOnce } from "../../react/stripe";
import {
  preloadStripeConnectedAccount,
  preloadCountry,
} from "./functions/preloadCountryAndStripeConnectedAccount";
import {
  getAccountNumberLabel,
  getRoutingNumberLabel,
} from "./functions/labels";

import styles from "./EditPaymentModal.module.scss";
import { showErrorAlert } from "../../services/helper";
import { useAuthentication } from "../../react/contexts/authentication";

const EditPaymentModal = ({ open, onClose }) => {
  const t = useTranslator();

  const { user } = useAuthentication();
  const countryRef = useRef(null);
  const previousBankAccountRef = useRef(null);
  const stripeConnectedAccountRef = useRef(null);
  const stripe = useRef(null);

  const [modalTitle, setModalTitle] = useState(t("modalEditPayment.setUp"));
  const [accountNumberLabel, setAccountNumberLabel] = useState();
  const [routingNumberLabel, setRoutingNumberLabel] = useState();

  const [errorMessage, setErrorMessage] = useState("");

  const { isLoading, loadingMessage, trackLoading, untrackLoading } =
    useLoadTracking({
      bankAccount: {
        message: t("modalEditPayment.loadingPaymentInformation"),
      },
      submitting: t("modalEditPayment.savingInformation"),
    });

  const formikbag = useEditPayment({
    onClose,
    country: countryRef.current,
    previousBankAccount: previousBankAccountRef.current,
    stripeConnectedAccountRef,
    stripe,
    errorMessage,
    setErrorMessage,
    trackLoading,
    untrackLoading,
  });

  useEffect(() => {
    load();
  }, []);

  const setFieldLabelsAndRules = () => {
    if (previousBankAccountRef.current) {
      setModalTitle(t("modalEditPayment.edit"));
    }
    setAccountNumberLabel(getAccountNumberLabel(countryRef.current));
    setRoutingNumberLabel(getRoutingNumberLabel(countryRef.current));
  };

  const setBankAccount = (bankAccount) => {
    if (bankAccount) {
      const values = {
        routingNumber: bankAccount.routingNumber,
        accountNumber: "********" + bankAccount.last4,
        accountHolderName: bankAccount.accountHolderName,
        accountHolderType: bankAccount.accountHolderType,
        isRoutingNumberRequired: countryRef.current.continent !== "europe",
      };
      formikbag.setValues(values);

      previousBankAccountRef.current = _.cloneDeep(values);
    } else {
      previousBankAccountRef.current = null;
    }
  };

  const setAccountHolderType = (accountHolderType) => {
    formikbag.setFieldValue("accountHolderType", accountHolderType);
  };

  const load = async () => {
    try {
      stripe.current = await loadStripeOnce();
      trackLoading("bankAccount");

      await preloadStripeConnectedAccount({
        stripeConnectedAccountRef,
      });

      await preloadCountry({
        stripeConnectedAccountRef,
        countryRef,
        user,
      });

      setBankAccount(_.get(stripeConnectedAccountRef.current, "bankAccount"));
      setFieldLabelsAndRules();
      untrackLoading("bankAccount");
    } catch (err) {
      console.log("Error on preload", err);
      setTimeout(() => onClose(), 100);
      showErrorAlert(err.data);
    }
  };

  const { handleSubmit } = formikbag;

  return (
    <Modal open={open} onClose={onClose}>
      <FormikProvider value={formikbag}>
        <Modal.Title>{modalTitle}</Modal.Title>
        {isLoading && (
          <LoadingManager isLoading={true} loadingMessage={loadingMessage} />
        )}

        {!isLoading && (
          <>
            <Modal.Content>
              {errorMessage && (
                <AlertMessage className={styles.gutterBottom}>
                  <Text>{errorMessage}</Text>
                </AlertMessage>
              )}

              <div className={styles.accountType}>
                <Label>{t("modalEditPayment.accountType")}</Label>
                <div>
                  <Radio
                    small
                    className={styles.radio}
                    gutterBottom
                    label={t("modalEditPayment.individual")}
                    checked={
                      formikbag.values.accountHolderType === "individual"
                    }
                    onChange={() => setAccountHolderType("individual")}
                  />
                  <Radio
                    small
                    className={styles.radio}
                    gutterBottom
                    label={t("modalEditPayment.business")}
                    checked={formikbag.values.accountHolderType === "company"}
                    onChange={() => setAccountHolderType("company")}
                  />
                </div>
                <Tooltip
                  id="default"
                  className={styles.tooltip}
                  labelProps={{
                    className: styles.tooltipLabel,
                    appearance: "tertiary",
                    gutterBottom: true,
                    variant: "small",
                  }}
                  label={t("modalEditPayment.whatsThis")}
                >
                  {t("modalEditPayment.tooltip")}
                </Tooltip>
              </div>

              <FormikField
                fullWidth
                gutterBottom
                as={FIELD_TYPE.INPUT}
                name="accountNumber"
                label={accountNumberLabel}
              />
              <FormikField
                fullWidth
                gutterBottom
                as={FIELD_TYPE.INPUT}
                name="routingNumber"
                label={routingNumberLabel}
              />
              <FormikField
                fullWidth
                gutterBottom
                as={FIELD_TYPE.INPUT}
                name="accountHolderName"
                label={t("modalEditPayment.commonFields.accountHolderName")}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                type="button"
                textColor="white"
                appearance="secondary"
                fullWidth
                justifyContent="center"
                uppercase
                onMouseDown={onClose}
              >
                {t("commonButtons.cancel")}
              </Button>
              <Button
                type="button"
                fullWidth
                justifyContent="center"
                uppercase
                onMouseDown={handleSubmit}
              >
                {t("commonButtons.save")}
              </Button>
            </Modal.Actions>
          </>
        )}
      </FormikProvider>
    </Modal>
  );
};

export default EditPaymentModal;
