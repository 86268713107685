"use strict";

import React, { useEffect, useState } from "react";
import { Grid } from "@citifyd/style";
import { find } from "lodash";

import PageHeader from "../../shared/react/components/PageHeader";
import LoadingManager from "../../shared/react/components/LoadingManager";
import { useTranslator, useService } from "../../shared/react/hooks";
import BundlePurchaseData from "./components/BundlePurchaseData";
import { getBundlePurchase } from "../../shared/api";
import { setPageTitle } from "../../shared/services/helper";

const BundlePurchase = () => {
  const t = useTranslator();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const $stateParams = useService("$stateParams");
  const [bundlePurchase, setBundlePurchase] = useState();
  const bundlePurchaseId = $stateParams.id;

  const loadBundlePurchase = async () => {
    setHasError(false);
    setIsLoading(true);

    try {
      const bundlePurchase = await getBundlePurchase(bundlePurchaseId);

      bundlePurchase.purchaseTransaction = find(bundlePurchase.transactions, {
        type: "purchase",
      });

      setPageTitle(
        t("bundlePurchase.pageTitleWithNumber", {
          packageNumber: bundlePurchase.id,
        })
      );

      setBundlePurchase(bundlePurchase);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadBundlePurchase();
  }, []);

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={t("ticket.loadingPass")}
        onClickTryAgain={loadBundlePurchase}
      />
    );
  }

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          defaultState="dashboard"
          title={t("back.backDashboard")}
        />
      </PageHeader>

      <BundlePurchaseData bundlePurchase={bundlePurchase} />
    </Grid>
  );
};

export default BundlePurchase;
