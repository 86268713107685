import gql from "graphql-tag";

import UsAddress from "./UsAddress";
import BrAddress from "./BrAddress";
import CaAddress from "./CaAddress";
import FiAddress from "./FiAddress";
import JpAddress from "./JpAddress";

export default gql`
  ${UsAddress}
  ${BrAddress}
  ${JpAddress}
  ${CaAddress}
  ${FiAddress}
  fragment Address on Address {
    ...UsAddress
    ...BrAddress
    ...JpAddress
    ...CaAddress
    ...FiAddress
  }
`;
