import React from "react";
import jstz from "jstz";

import { useTranslator } from "../../../../shared/react/hooks";
import PageHeader from "../../../../shared/react/components/PageHeader";
import RouterLinkButton from "../../../../shared/react/components/RouterLinkButton";
import SearchInput from "../../../../shared/react/components/SearchInput";
import CSVDownload from "../../../../shared/react/components/CSVDownload";
import styles from "./UsersListHeader.module.scss";
import GenerateReportModal from "../../../../shared/modals/GenerateReportModal";
import Permissions from "../../../../shared/services/permissions";

import { getUsersListCsv } from "../../../../shared/api";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";
import { useModal } from "../../../../shared/react/contexts/modal";

const UserListHeader = ({
  searchQuery,
  setSearchQuery,
  sortBy,
  sortReverse,
}) => {
  const t = useTranslator();
  const { openModal } = useModal();
  const { permissions } = useAuthentication();

  const handleSearchQuery = (e) => setSearchQuery(e.target.value);

  const filledSearchQuery = searchQuery.trim() !== "";

  const getFilterDescription = () => {
    let localeKey = "users.filterDescription";
    let localeOptions = {};

    if (filledSearchQuery) {
      localeKey += "WithSearchQuery";
      localeOptions.searchQuery = searchQuery.trim();
    }

    return t(localeKey, localeOptions);
  };

  const generateCsvClicked = () => {
    const data = {
      timezoneName: jstz.determine().name(),
      order: sortBy,
      reverse: sortReverse,
      ...(filledSearchQuery && { search: searchQuery.trim() }),
    };
    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription: getFilterDescription(),
      reportCsvRequest: getUsersListCsv(data),
    });
  };

  return (
    <>
      <PageHeader.Action className={styles.searchMobile}>
        <SearchInput
          value={searchQuery}
          id="search"
          onChange={handleSearchQuery}
          fullWidth
        />
      </PageHeader.Action>
      <PageHeader className={styles.pageHeader}>
        <PageHeader.Title
          variant="h3"
          component="h2"
          title={t("users.allUsers")}
        />
        <PageHeader.Actions>
          <PageHeader.Action>
            <RouterLinkButton
              uppercase
              size="small"
              extraPadding
              shadow
              letterSpacing
              state="invite-parkers"
              nowrap
            >
              {t("users.inviteNewUsers")}
            </RouterLinkButton>
          </PageHeader.Action>
          <PageHeader.Action className={styles.searchDesktop}>
            <SearchInput
              value={searchQuery}
              id="search"
              onChange={handleSearchQuery}
              fullWidth
            />
          </PageHeader.Action>

          {Permissions.userMayGenerateUsersCsv(permissions) && (
            <PageHeader.Action>
              <CSVDownload onClick={() => generateCsvClicked()} />
            </PageHeader.Action>
          )}
        </PageHeader.Actions>
      </PageHeader>
    </>
  );
};

export default UserListHeader;
