import React, { useEffect, useState, useMemo } from "react";
import { Text } from "@citifyd/style";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import Table from "../../../../shared/react/components/Table";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import {
  loadMonthlyData,
  getMonthlyTableData,
  getSubscriptionsTableData,
} from "./utils";

import styles from "./Monthly.module.scss";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const Monthly = ({ selectedProperty, ...rest }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const { permissions } = useAuthentication();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [monthlyPlan, setMonthlyPlan] = useState(null);
  const [subscriptions, setSubscriptions] = useState(null);
  const monthlyTableContent = useMemo(
    () =>
      getMonthlyTableData({
        monthlyPlan,
        selectedProperty,
      }),
    [monthlyPlan, selectedProperty]
  );

  const subscriptionsTableContent = useMemo(
    () =>
      getSubscriptionsTableData({
        subscriptions,
        selectedProperty,
        permissions,
      }),
    [subscriptions, selectedProperty]
  );

  const loadData = async () => {
    if (!selectedProperty) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    try {
      const response = await loadMonthlyData(selectedProperty);
      setMonthlyPlan(response.monthlyPlan);
      setSubscriptions(response.subscriptions);
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [selectedProperty]);

  useEffect(() => {
    loadData();
  }, [$state.params.property]);

  if (isLoading) {
    return (
      <div {...rest}>
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      </div>
    );
  }

  return (
    <div {...rest}>
      {!selectedProperty && (
        <Text variant="subtitle">
          {t("schedule.monthlyPropertyInstructions")}
        </Text>
      )}
      {selectedProperty && (
        <>
          {!monthlyPlan && !isLoading && (
            <Text gutterBottom variant="subtitle">
              {t("schedule.monthlyRateDefinition")}
            </Text>
          )}
          {monthlyTableContent && (
            <div className={styles.monthlyTable}>
              <Table
                data={monthlyTableContent?.data}
                columns={monthlyTableContent?.columns}
                responsive
                striped
              />
            </div>
          )}
          {subscriptionsTableContent && (
            <>
              <Text variant="subtitle" weight="700" gutterBottom>
                {t("schedule.parkers")}
              </Text>
              <Table
                data={subscriptionsTableContent?.data}
                columns={subscriptionsTableContent?.columns}
                responsive
                striped
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Monthly;
