import React, { useEffect, useState, useMemo } from "react";
import { Text, Grid } from "@citifyd/style";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { useService, useTranslator } from "../../../../shared/react/hooks";
import Table from "../../../../shared/react/components/Table";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import CSVDownload from "../../../../shared/react/components/CSVDownload";
import GenerateReportModal from "../../../../shared/modals/GenerateReportModal";
import StatusSelector from "./StatusSelector";
import StatusButtons from "./StatusButtons";
import SearchFilter from "./SearchFilter";
import {
  getTableData,
  generateEmptyListMessage,
  getFilterDescription,
  loadEvents,
  loadStats,
  getCsvParams,
} from "./utils";
import { getEventsCsv } from "../../../../shared/api";

import styles from "./Events.module.scss";
import { useModal } from "../../../../shared/react/contexts/modal";
import Permissions from "../../../../shared/services/permissions";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const Events = ({ selectedProperty, ...rest }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const { permissions } = useAuthentication();
  const { openModal } = useModal();
  const [events, setEvents] = useState([]);
  const [ended, setEnded] = useState(false);
  const [stats, setStats] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStats, setIsLoadingStats] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [emptyListMessage, setEmptyListMessage] = useState(null);
  const tableContent = useMemo(() => getTableData(events), [events]);

  const userMayEditEvent = Permissions.userMayEditEvent(permissions);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading || isLoadingMore,
    hasNextPage: !ended,
    onLoadMore: () => loadMore(),
    disabled: ended,
    rootMargin: "0px 0px 200px 0px",
  });

  const loadMore = () => {
    if (ended || isLoadingMore) {
      return;
    }

    setCurrentPage((old) => old + 1);
  };

  const generateCsvClicked = () => {
    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription: getFilterDescription(),
      reportCsvRequest: getEventsCsv(getCsvParams()),
    });
  };

  const fetchStats = () => {
    setIsLoadingStats(true);

    loadStats()
      .then((stats) => {
        setIsLoadingStats(false);

        if (!stats) {
          setHasError(true);
        }

        setStats(stats);
      })
      .catch((err) => setHasError(err));
  };

  const fetchEvents = (offset) => {
    if (offset > 0) {
      setIsLoadingMore(true);
    } else {
      setIsLoading(true);
    }

    loadEvents(offset)
      .then((data) => {
        setIsLoading(false);
        setIsLoadingMore(false);

        if (!data?.events) {
          setHasError(true);
        }

        setEnded(data?.hasEnded);

        const result =
          offset === 0 ? data.events : [...events, ...data?.events];

        setEvents(result.filter((item) => item));
      })
      .catch((err) => setHasError(err));
  };

  const onChangeParams = () => {
    setEmptyListMessage(generateEmptyListMessage(selectedProperty));
    fetchEvents(0);
  };

  useEffect(() => {
    if (currentPage > 0 && !isLoadingMore && !ended) {
      fetchEvents(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchStats();
    onChangeParams();
  }, [$state.params.property]);

  useEffect(() => {
    setEmptyListMessage(generateEmptyListMessage(selectedProperty));
  }, [selectedProperty]);

  useEffect(() => {
    onChangeParams();
  }, [$state.params.status, $state.params.search]);

  useEffect(() => {
    fetchEvents(0);
    fetchStats();

    return () => {
      setCurrentPage(0);
    };
  }, []);

  return (
    <div {...rest}>
      <Grid.Row align="end">
        <Grid.Col md={7} lg={9}>
          <Text variant="h4">{t("schedule.eventParking")}</Text>
        </Grid.Col>
        <Grid.Col md={5} lg={3}>
          <StatusSelector stats={stats} disabled={isLoadingStats} />

          <div className={styles.searchAndCSV}>
            <SearchFilter isLoading={isLoading} />
            {userMayEditEvent && (
              <CSVDownload
                disabled={isLoading}
                className={styles.csv}
                onClick={generateCsvClicked}
              />
            )}
          </div>
        </Grid.Col>
      </Grid.Row>
      <StatusButtons stats={stats} disabled={isLoadingStats} />
      {(isLoading || hasError) && (
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      )}
      {events?.length === 0 && !isLoading && !hasError && (
        <Text variant="subtitle" gutterBottom>
          {emptyListMessage}
        </Text>
      )}

      {events?.length > 0 && tableContent && !isLoading && !hasError && (
        <Table
          data={tableContent?.data}
          columns={tableContent?.columns}
          responsive
          striped
          clickable={userMayEditEvent}
          showIconRight={userMayEditEvent}
          verticalAlignIconRight="middle"
        />
      )}
      {!ended && !hasError && tableContent && (
        <div className={styles.sentryRef} ref={sentryRef}>
          {isLoadingMore && events?.length > 0 && (
            <LoadingManager isLoading={isLoadingMore} />
          )}
        </div>
      )}
    </div>
  );
};

export default Events;
