"use strict";

import React, { useEffect, useState } from "react";
import { Modal } from "@citifyd/style";
import _ from "lodash";

import StepShow from "./components/StepShow.jsx";
import StepMigrationResult from "./components/StepMigrationResult.jsx";
import styles from "./UserMigrationTicketsModal.module.scss";

const UserMigrationTicketsModal = ({
  migrationTickets,
  open,
  onClose,
  user,
}) => {
  const [migrationResult, setMigrationResult] = useState();
  const [step, setStep] = useState("show");
  const [migrationAvailable, setMigrationAvailable] = useState();

  useEffect(() => {
    init();
  }, []);

  const isMigrationAvailable = () => {
    return _.some(migrationTickets, { status: "READY" });
  };

  const init = () => {
    setMigrationAvailable(isMigrationAvailable());
  };

  return (
    <Modal open={open} onClose={onClose} className={styles.modalSize}>
      {step === "show" && (
        <StepShow
          migrationTickets={migrationTickets}
          onClose={onClose}
          setMigrationResult={setMigrationResult}
          setStep={setStep}
          migrationAvailable={migrationAvailable}
          user={user}
        />
      )}
      {step === "migration-result" && (
        <StepMigrationResult
          migrationResult={migrationResult}
          onClose={onClose}
        />
      )}
    </Modal>
  );
};

export default UserMigrationTicketsModal;
