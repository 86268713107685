"use strict";
import React from "react";

import { Button, Grid, Text } from "@citifyd/style";

import { useTranslator } from "../../shared/react/hooks";
import FormikProvider from "../../shared/react/components/FormikProvider";
import FormikField, {
  FIELD_TYPE,
} from "../../shared/react/components/FormikField";

import { useAttendantMonitoringCompany } from "./useAttendantMonitoringCompany";
import { CompanyData, Lot } from "./types";

import PageHeader from "../../shared/react/components/PageHeader";
import StickyFooter from "../../shared/react/components/StickyFooter";
import BoxContent from "../../shared/react/components/BoxContent";
import CheckboxWithTooltip from "../../shared/react/components/CheckboxWithTooltip/CheckboxWithTooltip";

export const Form = ({
  lots,
  companyData,
  isEditing,
  isPending,
}: {
  lots: Lot[];
  companyData: CompanyData;
  isEditing: Boolean;
  isPending: Boolean;
}) => {
  const t = useTranslator();

  const formikbag = useAttendantMonitoringCompany({
    lots,
    companyData,
  });

  const handleChangeAllSelectedLots = (e) => {
    const selectAllValue = e.target.checked;

    setFieldValue("selectAll", selectAllValue);
    const updatedLots = {};

    lots.forEach((lot) => {
      updatedLots[lot.id] = selectAllValue;
    });

    setFieldValue("lots", updatedLots);
  };

  const useTwoColumns = lots.length >= 6;
  const halfIndex = Math.ceil(lots.length / 2);

  const { handleSubmit, values, setFieldValue } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Grid>
        <PageHeader>
          <PageHeader.Title
            weight="300"
            defaultState="account-permissions"
            title={
              isEditing
                ? t(
                    "accountPermissions.permissionCompanies.editAttendantsMonitoringCompanies"
                  )
                : t(
                    "accountPermissions.permissionCompanies.addAttendantsMonitoringCompanies"
                  )
            }
          />
        </PageHeader>
        <BoxContent>
          <BoxContent.Content>
            <Grid.Row>
              <Grid.Col xs={12} sm={6}>
                <FormikField
                  as={FIELD_TYPE.INPUT}
                  gutterBottom
                  name="name"
                  disabled={isEditing && !isPending}
                  label={t("accountPermissions.permissionCompanies.name")}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={6}>
                {(!isEditing || isPending) && (
                  <FormikField
                    as={FIELD_TYPE.INPUT}
                    gutterBottom
                    name="email"
                    label={t("accountPermissions.permissionCompanies.email")}
                  />
                )}

                {isEditing && !isPending && (
                  <FormikField
                    as={FIELD_TYPE.INPUT}
                    gutterBottom
                    name="phoneNumber"
                    disabled={isEditing}
                    label={t(
                      "accountPermissions.permissionCompanies.phoneNumber"
                    )}
                  />
                )}
                <CheckboxWithTooltip
                  tooltip={t(
                    "accountPermissions.permissionCompanies.description"
                  )}
                  id={"notifyWhenSomeoneParking"}
                  label={t(
                    "accountPermissions.permissionCompanies.canContactParkersByTextMessage"
                  )}
                  name="canContactParkers"
                />
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Text gutterBottom variant="h4" weight="600">
                  {t(
                    "accountPermissions.permissionCompanies.assignProperties.title"
                  )}
                </Text>
                <Text gutterBottom>
                  {t(
                    "accountPermissions.permissionCompanies.assignProperties.description"
                  )}
                </Text>

                <FormikField
                  gutterBottom
                  as={FIELD_TYPE.CHECKBOX}
                  label={t(
                    "accountPermissions.permissionCompanies.assignProperties.selectAll"
                  )}
                  name="selectAll"
                  checked={values.selectAll}
                  onChange={(e) => handleChangeAllSelectedLots(e)}
                />

                {useTwoColumns ? (
                  <Grid.Row>
                    <Grid.Col xs={12} sm={6}>
                      {lots.slice(0, halfIndex).map((lot) => (
                        <LotField lot={lot} formikbag={formikbag} />
                      ))}
                    </Grid.Col>

                    <Grid.Col xs={12} sm={6}>
                      {lots.slice(halfIndex).map((lot) => (
                        <LotField lot={lot} formikbag={formikbag} />
                      ))}
                    </Grid.Col>
                  </Grid.Row>
                ) : (
                  <Grid.Row>
                    <Grid.Col>
                      {lots.map((lot) => (
                        <LotField lot={lot} formikbag={formikbag} />
                      ))}
                    </Grid.Col>
                  </Grid.Row>
                )}
              </Grid.Col>
            </Grid.Row>
          </BoxContent.Content>
        </BoxContent>
      </Grid>

      <StickyFooter isBlocked={false} justifyContent="flex-end">
        <Button uppercase onMouseDown={handleSubmit} extraPadding>
          {t(
            "accountPermissions.permissionInternalUser.assignPermissions.addPermission"
          )}
        </Button>
      </StickyFooter>
    </FormikProvider>
  );
};

const LotField = ({ lot, formikbag }) => {
  const { values, setFieldValue } = formikbag;

  const handleChangeSelectLots = (e) => {
    const checked = e.target.checked;

    if (values.selectAll && !checked) {
      setFieldValue("selectAll", false);
      return;
    }

    const name = e.target.name.split(".").pop();

    const allOthersChecked = Object.entries(values.lots)
      .filter(([key, _value]) => key !== name)
      .every(([_key, checked]) => checked);

    if (checked && allOthersChecked) {
      setFieldValue("selectAll", true);
    }
  };
  return (
    <div key={lot.id}>
      <FormikField
        gutterBottom
        as={FIELD_TYPE.CHECKBOX}
        label={lot.name}
        handleChange={(e) => handleChangeSelectLots(e)}
        name={`lots.${lot.id}`}
      />
    </div>
  );
};
