"use strict";

import React, { useMemo } from "react";
import CurrencyFormatter from "@citifyd/currency-formatter";
import moment from "moment-timezone";
import { get, last } from "lodash";
import { Text, Grid, AlertMessage } from "@citifyd/style";

import UserLink from "../../../shared/react/components/UserLink";
import { SubscriptionStatusBadges } from "../../../shared/react/components/SubscriptionStatusBadge";
import Dictionary, {
  DictionaryItem,
} from "../../../shared/react/components/Dictionary";
import BoxContent from "../../../shared/react/components/BoxContent";
import Address from "../../../shared/react/components/Address";
import PaymentMethod from "../../../shared/react/components/PaymentMethod";
import styles from "../Subscription.module.scss";

import { calculateSubscriptionStatuses } from "../../../shared/utils/status-calculation";
import { useTranslator } from "../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../shared/services/languages";

function SubscriptionOverviewBox({ subscription: baseSubscription }) {
  const t = useTranslator();

  const latestSubscription = useMemo(
    () => last(baseSubscription.allSubscriptions),
    [baseSubscription]
  );
  const statuses = useMemo(
    () => calculateSubscriptionStatuses(latestSubscription),
    [latestSubscription]
  );

  return (
    <BoxContent>
      <BoxContent.Header title={t("subscriptionPage.pageTitle")} />
      <BoxContent.Content>
        <SuspensionAlert subscription={latestSubscription} />
        <Grid.Row>
          <Grid.Col xs={12} md={6}>
            <Text component="h3" variant="h3" bold>
              {baseSubscription.plan.lot.name}
            </Text>
          </Grid.Col>
          <Grid.Col xs={12} md={6} className={styles.status}>
            <SubscriptionStatusBadges statuses={statuses} />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row className={styles.dictionaryGrid}>
          <Grid.Col xs={12} md={6} className={styles.dictionaryGridItem}>
            <Dictionary withTopLine>
              <DictionaryItem
                label={t("subscriptionPage.overviewBox.purchasedBy")}
              >
                <UserLink
                  appearance="tertiary"
                  underline={false}
                  user={baseSubscription.user}
                />
              </DictionaryItem>
              <DictionaryItem
                label={t("subscriptionPage.overviewBox.licensePlate")}
              >
                {baseSubscription.vehicle.licenseInfo.plate}
              </DictionaryItem>
              {!latestSubscription.suspendedAt && (
                <>
                  <DictionaryItem
                    label={t("subscriptionPage.overviewBox.nextRenewal.label")}
                  >
                    <NextRenewalTime
                      subscription={latestSubscription}
                      timezoneName={baseSubscription.plan.lot.timezoneName}
                    />
                  </DictionaryItem>
                  {latestSubscription.nextRenewal && (
                    <DictionaryItem
                      label={t("subscriptionPage.overviewBox.nextRenewalPrice")}
                    >
                      <NextRenewalPrice subscription={latestSubscription} />
                    </DictionaryItem>
                  )}
                  {!latestSubscription.nextRenewal && (
                    <DictionaryItem
                      label={t("subscriptionPage.overviewBox.expiresOn")}
                    >
                      <SubscriptionExpiration
                        subscription={latestSubscription}
                        timezoneName={baseSubscription.plan.lot.timezoneName}
                      />
                    </DictionaryItem>
                  )}
                </>
              )}
              <DictionaryItem
                label={t("subscriptionPage.overviewBox.lotAddress")}
                multiline
              >
                <Address entity={baseSubscription.plan.lot} format="full" />
              </DictionaryItem>
            </Dictionary>
          </Grid.Col>
          <Grid.Col xs={12} md={6} className={styles.dictionaryGridItem}>
            <Dictionary withTopLine>
              <DictionaryItem
                label={t("subscriptionPage.overviewBox.paymentMethod")}
              >
                <PaymentMethod card={latestSubscription.card} />
              </DictionaryItem>
              <DictionaryItem
                label={t("subscriptionPage.overviewBox.lastNameOnCard")}
              >
                <CardLastName card={latestSubscription.card} />
              </DictionaryItem>
            </Dictionary>
          </Grid.Col>
        </Grid.Row>
      </BoxContent.Content>
    </BoxContent>
  );
}

function SuspensionAlert({ subscription }) {
  const t = useTranslator();
  const failedRenewalAttempts = get(
    subscription,
    "failedRenewalAttempts.length",
    0
  );

  if (subscription.suspendedAt) {
    return (
      <AlertMessage className={styles.alertMessage}>
        <Text>
          {t("subscriptionPage.overviewBox.subscriptionSuspended", {
            count: failedRenewalAttempts,
          })}
        </Text>
      </AlertMessage>
    );
  }

  if (failedRenewalAttempts > 0) {
    return (
      <AlertMessage className={styles.alertMessage}>
        <Text>{t("subscriptionPage.overviewBox.renewalAttemptFailed")}</Text>
      </AlertMessage>
    );
  }

  return null;
}

function NextRenewalTime({ subscription, timezoneName }) {
  const t = useTranslator();
  if (subscription.nextRenewal) {
    const time = moment.tz(subscription.nextRenewal.time, timezoneName);
    return t("defaultFormats.datetimeWithTimezone", { date: time });
  } else {
    return t("subscriptionPage.overviewBox.nextRenewal.values.disabled");
  }
}

function NextRenewalPrice({ subscription }) {
  const language = getCurrentLanguage();

  return CurrencyFormatter.format(
    subscription.nextRenewal.price + subscription.nextRenewal.citifydFee,
    { currency: subscription.currency, language }
  );
}

function SubscriptionExpiration({ subscription, timezoneName }) {
  const t = useTranslator();
  if (subscription.effectiveUntil) {
    const time = moment.tz(subscription.effectiveUntil, timezoneName);
    return t("defaultFormats.datetimeWithTimezone", { date: time });
  } else {
    return "—";
  }
}

function CardLastName({ card }) {
  const cardName = card && card.name;
  return (cardName || "").trim().split(" ").reverse()[0] || "—";
}

export default SubscriptionOverviewBox;
