"use strict";

import React, { useState, useEffect, useMemo } from "react";
import { find, defer, isEqual, isEmpty } from "lodash";
import { Grid } from "@citifyd/style";

import LoadingManager from "../../shared/react/components/LoadingManager";
import PageHeader from "../../shared/react/components/PageHeader";
import CSVDownload from "../../shared/react/components/CSVDownload";

import usePrevious from "../../shared/react/hooks/usePrevious";
import { useService, useTranslator } from "../../shared/react/hooks";
import { fetchLots, filterTransactions, getLotIds } from "./utils";
import AllPropertiesSelect from "./components/AllPropertiesSelect.jsx";
import Summary from "./components/Summary";
import Reservations from "./components/Reservations";
import ReservedParkingPass from "./components/ReservedParkingPass";
import Tickets from "./components/Tickets";
import Subscriptions from "./components/Subscriptions";
import Bundles from "./components/Bundles";
import GenerateReportModal from "../../shared/modals/GenerateReportModal";
import {
  getRevenueCurrencies,
  getRevenueForDate,
  getRevenueForDateCsv,
} from "../../shared/api";
import { getService } from "../../shared/react/utils";
import useLoadTracking from "../../shared/react/hooks/useLoadTracking";

import { useModal } from "../../shared/react/contexts/modal";
import { useAuthentication } from "../../shared/react/contexts/authentication";

import { getTimezoneValue } from "../../shared/utils/date-time-formatting";

const Page = () => {
  const t = useTranslator();
  const { openModal } = useModal();
  const { user } = useAuthentication();
  const [lots, setLots] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [data, setData] = useState([]);
  const [filteredLots, setFilteredLots] = useState(null);
  const $state = useService("$state");
  const { date, currency, lot, timezoneName } = $state.params;

  const timezone = getTimezoneValue("UTC");

  const lotId = Number(lot);

  const getStartAndEndTimes = () => {
    const { dateRange } = $state.params;

    if (!dateRange) return {};

    const [startDateTime, endDateTime] = dateRange.split(",");
    const [startDate, startTime] = startDateTime.split("T");
    const [endDate, endTime] = endDateTime.split("T");

    const times = {};

    if (startDate === date) {
      times.startTime = `${startTime}${timezone}`;
    }
    if (endDate === date) {
      times.endTime = `${endTime}${timezone}`;
    }

    return times;
  };

  const { trackLoading, untrackLoading, isLoading } = useLoadTracking();
  const prevParams = usePrevious($state.params);

  const propertiesList = useMemo(() => {
    const propertiesList = (filteredLots || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));

    return [
      { label: t("revenue.revenueFilter.fields.allProperties"), value: "" },
      ...propertiesList,
    ];
  }, [filteredLots]);

  const generateCsvClicked = () => {
    const startAndEndTimes = getStartAndEndTimes();
    const options = {
      lotId: lot,
      currency,
      ...startAndEndTimes,
    };

    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription: generateCsvReportDescription(),
      reportCsvRequest: getRevenueForDateCsv(date, options),
    });
  };

  const generateCsvReportDescription = () => {
    if (lot) {
      const lot = find(lots, (lot) => lot.id === lotId);
      return t("dailyRevenue.csvReportName.date.lot", {
        date: date,
        lotName: lot.name,
      });
    } else {
      return t("dailyRevenue.csvReportName.date.currency", {
        date: date,
        currency: currency,
      });
    }
  };

  async function fetchTransactionData(lots) {
    trackLoading("transactionsData");
    const startAndEndTimes = getStartAndEndTimes();

    const data = await getRevenueForDate(date, {
      params: {
        currency,
        timezoneName,
        ...startAndEndTimes,
      },
    });

    try {
      const $state = getService("$state");
      if ($state.params.lot) {
        setData(
          filterTransactions({
            transactions: data,
            lotId: parseInt($state.params.lot),
          })
        );
      } else {
        setData(data);
      }

      const lotIds = getLotIds(data);
      setFilteredLots(lots.filter((lot) => lotIds.includes(lot.id)));
    } catch (err) {
      setHasError(true);
    } finally {
      untrackLoading("transactionsData");
    }
  }

  const fetchInitialData = async () => {
    trackLoading("initialData");
    try {
      const currencies = await getRevenueCurrencies();
      const lots = await fetchLots(user);

      setLots(lots);
      setCurrencies(currencies);
      return { lots, currencies };
    } catch (err) {
      setHasError(true);
      throw err;
    } finally {
      untrackLoading("initialData");
    }
  };

  useEffect(() => {
    if (
      !isEqual(prevParams, $state.params) &&
      !isEmpty($state.params.currency)
    ) {
      fetchInitialData().then(({ lots }) => fetchTransactionData(lots));
    }
  }, [$state.params]);

  const onPropertyChange = (id) => {
    const params = {
      date,
      _origin: $state.params._origin,
      timezoneName,
    };

    if (id) {
      const lot = find(lots, (lot) => lot.id === id);
      params.lot = id;
      params.currency = lot?.country?.currency;
    } else {
      params.currency = currencies[0];
    }

    const test = $state.go($state.current.name, params, {
      inherit: false,
      location: "replace",
      notify: false,
    });

    defer(() => fetchTransactionData(lots));
  };

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          defaultState="reports"
          defaultParams={{
            reportType: "daily",
            currency: currency,
            lot: lotId || undefined,
          }}
          title={t("dailyRevenue.revenueOnDate", {
            date: date,
          })}
        />
        <PageHeader.Actions>
          <PageHeader.Action>
            <CSVDownload onClick={() => generateCsvClicked()} />
          </PageHeader.Action>
        </PageHeader.Actions>
      </PageHeader>

      <AllPropertiesSelect
        disabled={isLoading}
        selected={lotId}
        properties={propertiesList}
        onChange={(e) => onPropertyChange(parseInt(e.target.value))}
      />

      {(isLoading || hasError) && (
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      )}

      {!isLoading && <Summary data={data} />}

      {!isLoading && data?.reservations?.length > 0 && (
        <Reservations reservations={data.reservations} />
      )}

      {!isLoading && data?.reservedParkingPasses?.length > 0 && (
        <ReservedParkingPass
          reservedParkingPasses={data.reservedParkingPasses}
        />
      )}

      {!isLoading && data?.tickets?.length > 0 && (
        <Tickets tickets={data?.tickets} />
      )}

      {!isLoading && data?.bundlePurchases?.length > 0 && (
        <Bundles bundlePurchases={data?.bundlePurchases} />
      )}

      {!isLoading && data?.subscriptions?.length > 0 && (
        <Subscriptions subscriptions={data?.subscriptions} />
      )}
    </Grid>
  );
};

export default Page;
