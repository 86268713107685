import gql from "graphql-tag";

export default gql`
  mutation AdminUpdatePermitPortalUser(
    $id: UUID!
    $input: UpdatePermitPortalUserInput!
  ) {
    admin_updatePermitPortalUser(id: $id, input: $input) {
      id
    }
  }
`;
