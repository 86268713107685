"use strict";

import React, { useState } from "react";
import { Icon, Text, Grid } from "@citifyd/style";
import { get } from "lodash";
import moment from "moment-timezone";
import CurrencyFormatter from "@citifyd/currency-formatter";

import TransactionExtraInformation from "../../../../shared/react/components/TransactionExtraInformation";
import PaymentMethod from "../../../../shared/react/components/PaymentMethod";
import RouterLink from "../../../../shared/react/components/RouterLink";
import Address from "../../../../shared/react/components/Address";
import UserLink from "../../../../shared/react/components/UserLink";
import Dictionary, {
  DictionaryItem,
} from "../../../../shared/react/components/Dictionary";
import TicketStatusBadge from "../../../../shared/react/components/TicketStatusBadge";
import BoxContent from "../../../../shared/react/components/BoxContent";
import GenerateReceiptModal from "../../../../shared/modals/GenerateReceiptModal";
import { useTranslator } from "../../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../../shared/services/languages";
import Permissions from "../../../../shared/services/permissions";
import styles from "../TicketTransactionData/TicketTransactionData.module.scss";
import {
  calculateTicketPrice,
  getCardDetails,
  shouldShowAvailableDates,
} from "./utils";
import { useModal } from "../../../../shared/react/contexts/modal";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const TicketMainTransactionBox = ({ ticket }) => {
  const t = useTranslator();
  const { permissions } = useAuthentication();
  const language = getCurrentLanguage();
  const { openModal } = useModal();

  const canSeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);
  const assistedPurchase = ticket.bundlePurchase
    ? ticket.bundlePurchase.assistedPurchase
    : ticket.assistedPurchase;
  const purchaseDate =
    ticket?.purchaseTransaction?.createdAt || ticket.createdAt;
  const isComplimentary = get(assistedPurchase, "isComplimentary", false);

  const ticketPrice = calculateTicketPrice(ticket);

  const card = ticket?.purchaseTransaction?.card;

  const { lastNameOnCard, cardExpirationDate, paymentStatus } = getCardDetails(
    isComplimentary,
    card
  );

  const downloadTransactionReceipt = (e) => {
    openModal(GenerateReceiptModal, {
      ticketId: ticket.id,
      forceLanguageSelection: e?.shiftKey,
    });
  };

  const { shouldShowAvailabilityStartDate, shouldShowAvailabilityEndDate } =
    shouldShowAvailableDates(ticket);

  return (
    <BoxContent>
      <BoxContent.Header
        title={t("transaction.transactionNumber", {
          transactionNumber: ticket.transactionNumber,
        })}
      >
        {ticket.purchaseTransaction &&
          Permissions.userMayExportReceipts(permissions) && (
            <BoxContent.HeaderActions>
              <div
                className={styles.downloadPdfReceipt}
                title={t("transaction.downloadPdfReceipt")}
                onClick={(e) => downloadTransactionReceipt(e)}
              >
                <Icon icon={["far", "file"]} size="h2" appearance="white" />
              </div>
            </BoxContent.HeaderActions>
          )}
      </BoxContent.Header>

      <BoxContent.Content>
        <div className={styles.ticketHeader}>
          <Text component="span" variant="h3" weight="700">
            {ticket.event.name}
          </Text>
          <div>
            {ticket.statusTags.map((statusTag) => (
              <React.Fragment key={statusTag}>
                &nbsp;
                <TicketStatusBadge statusTag={statusTag} />
              </React.Fragment>
            ))}
          </div>
        </div>

        <Grid.Row>
          <Grid.Col xs={12} md={6}>
            <Dictionary>
              {canSeeUserInformation && (
                <DictionaryItem
                  textComponent="span"
                  label={t("transaction.purchasedBy")}
                >
                  {ticket.purchaser && (
                    <UserLink
                      appearance="tertiary"
                      underline={false}
                      user={ticket.purchaser}
                    />
                  )}
                  {!ticket.purchaser && ticket.isPosPurchase && (
                    <span>POS User</span>
                  )}
                </DictionaryItem>
              )}

              <DictionaryItem
                textComponent="span"
                label={t("transaction.eventDate")}
              >
                {t("defaultFormats.date", {
                  date: moment.tz(
                    ticket.event?.start,
                    ticket.event?.timezoneName
                  ),
                })}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.eventTime")}
              >
                {t("defaultFormats.time", {
                  date: moment.tz(
                    ticket.event?.start,
                    ticket.event?.timezoneName
                  ),
                })}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.venue")}
              >
                {ticket.event.venueName}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.lot")}
                multiline
              >
                {ticket.lot.name} <br />
                <Address entity={ticket.lot} format="full" />
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.passDuration")}
              >
                {shouldShowAvailabilityStartDate
                  ? t("defaultFormats.datetime", {
                      date: moment.tz(
                        ticket.availability.start,
                        ticket.availability.timezoneName
                      ),
                    })
                  : t("defaultFormats.time", {
                      date: moment.tz(
                        ticket.availability.start,
                        ticket.availability.timezoneName
                      ),
                    })}{" "}
                -{" "}
                {shouldShowAvailabilityEndDate
                  ? t("defaultFormats.datetime", {
                      date: moment.tz(
                        ticket.availability.end,
                        ticket.availability.timezoneName
                      ),
                    })
                  : t("defaultFormats.time", {
                      date: moment.tz(
                        ticket.availability.end,
                        ticket.availability.timezoneName
                      ),
                    })}
              </DictionaryItem>
            </Dictionary>
          </Grid.Col>

          <Grid.Col xs={12} md={6}>
            <Dictionary>
              <DictionaryItem
                textComponent="span"
                label={t("transaction.price")}
              >
                {ticket.bundlePurchase ? (
                  <RouterLink
                    appearance="tertiary"
                    state="bundle-purchase"
                    params={{ id: ticket.bundlePurchase.id }}
                  >
                    {t("transaction.package")}
                  </RouterLink>
                ) : ticketPrice !== null ? (
                  <span>
                    {CurrencyFormatter.format(ticketPrice, {
                      currency: ticket.currency,
                      language,
                    })}
                    {ticket.incentive && (
                      <>
                        {" "}
                        {t("transaction.incentiveUsed", {
                          amount: CurrencyFormatter.format(
                            ticket.incentive.amount,
                            {
                              currency: ticket.currency,
                              language,
                            }
                          ),
                        })}
                      </>
                    )}
                  </span>
                ) : (
                  "—"
                )}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.purchaseDate")}
              >
                {purchaseDate
                  ? t("defaultFormats.date", {
                      date: moment.tz(
                        purchaseDate,
                        ticket.availability.timezoneName
                      ),
                    })
                  : "—"}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.purchaseTime")}
              >
                {purchaseDate
                  ? t("defaultFormats.time", {
                      date: moment.tz(
                        purchaseDate,
                        ticket.availability.timezoneName
                      ),
                    })
                  : "—"}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.paymentMethod")}
              >
                {!isComplimentary ? (
                  <span className={styles.payment}>
                    <PaymentMethod
                      card={card}
                      isCashPayment={ticket?.purchaseTransaction?.isCashPayment}
                    />
                  </span>
                ) : ticket.assistedPurchase.organization ? (
                  ticket.assistedPurchase.organization.name
                ) : (
                  "Citifyd"
                )}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.lastNameOnCard")}
              >
                {lastNameOnCard || "—"}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.expirationDate")}
              >
                {cardExpirationDate || "—"}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.paymentStatus")}
              >
                {paymentStatus || "—"}
              </DictionaryItem>
            </Dictionary>
          </Grid.Col>
        </Grid.Row>
      </BoxContent.Content>
      {assistedPurchase && (
        <BoxContent.Footer>
          <Text weight="700">
            {t("transaction.fulfilledBy", {
              name: assistedPurchase.purchaser.name,
            })}
          </Text>
          <Text>{assistedPurchase.reason}</Text>
          <Text italic>
            <TransactionExtraInformation data={assistedPurchase.extra} />
          </Text>
        </BoxContent.Footer>
      )}
    </BoxContent>
  );
};

export default TicketMainTransactionBox;
