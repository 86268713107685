import { cloneDeep, mapValues, includes, some } from "lodash";
import moment from "moment-timezone";
import {
  updateProperty,
  updatePropertySchedule,
} from "../../../../../../shared/api";
import { transformToInteger } from "../../../../../../shared/services/currencies";
import { getTranslatedDayName } from "../../../../../../shared/services/helper";
import { getTranslator } from "../../../../../../shared/services/languages";
import { createWeeklySchedule } from "./Pricing/utils";

export const getDayNames = (type) => {
  if (type === "small") {
    return {
      mon: getTranslatedDayName("Mon"),
      tue: getTranslatedDayName("Tue"),
      wed: getTranslatedDayName("Wed"),
      thu: getTranslatedDayName("Thu"),
      fri: getTranslatedDayName("Fri"),
      sat: getTranslatedDayName("Sat"),
      sun: getTranslatedDayName("Sun"),
    };
  } else if (type === "smallest") {
    const dayNames = {
      mon: getTranslatedDayName("Monday"),
      tue: getTranslatedDayName("Tuesday"),
      wed: getTranslatedDayName("Wednesday"),
      thu: getTranslatedDayName("Thursday"),
      fri: getTranslatedDayName("Friday"),
      sat: getTranslatedDayName("Saturday"),
      sun: getTranslatedDayName("Sunday"),
    };

    return getSmallestDayNames(dayNames);
  }
};

export const saveDailyPricing = ({
  lot,
  pricingSettings,
  trackLoading,
  untrackLoading,
}) => {
  if (!confirmSelectedDays(pricingSettings)) {
    return Promise.resolve(false);
  }

  trackLoading("setupDaily");

  var pricing = generateInitialAvailabilitiesObject(lot, pricingSettings);

  let result;

  if (lot.setupIsComplete) {
    result = updatePropertySchedule(lot.id, pricing);
  } else {
    result = updateProperty({
      id: lot.id,
      availabilities: pricing,
    });
  }

  return result
    .then((resp) => {
      return Promise.resolve(resp);
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => {
      untrackLoading("setupDaily");
    });
};

export const getPricingSettings = ({ lot, weeklySchedule }) => {
  const currency = lot.country.currency;

  const dayOpeningHours = weeklySchedule
    .filter((availabilities) => availabilities.period === "day")
    .map((availability) =>
      moment
        .tz(availability.start, availability.timezoneName)
        .format("ddd")
        .toLocaleLowerCase()
    );

  const nightOpeningHours = weeklySchedule
    .filter((availabilities) => availabilities.period === "evening")
    .map((availability) =>
      moment
        .tz(availability.start, availability.timezoneName)
        .format("ddd")
        .toLocaleLowerCase()
    );

  const weeklyScheduleData = weeklySchedule?.map((weekly) => ({
    ...weekly,
    dayOfWeek: moment
      .tz(weekly.start, weekly.timezoneName)
      .format("ddd")
      .toLocaleLowerCase(),
  }));

  const weekdays = weeklyScheduleData?.filter(
    (weekly) =>
      weekly.period === "day" &&
      weekly.dayOfWeek !== "sat" &&
      weekly.dayOfWeek !== "sun"
  );

  const weeknights = weeklyScheduleData?.filter(
    (weekly) =>
      weekly.period === "evening" &&
      weekly.dayOfWeek !== "sat" &&
      weekly.dayOfWeek !== "sun"
  );

  const weekends = weeklyScheduleData?.filter(
    (weekly) =>
      weekly.period === "day" &&
      (weekly.dayOfWeek === "sat" || weekly.dayOfWeek === "sun")
  );

  const weekendEvenings = weeklyScheduleData?.filter(
    (weekly) =>
      weekly.period === "evening" &&
      (weekly.dayOfWeek === "sat" || weekly.dayOfWeek === "sun")
  );

  const isSimpleRatesConfigured =
    weeklySchedule.length && !lot.advancedRateDefinitionEnabled;

  const isOpenInTheDay = (day) => lot.openingHours?.[day].length > 0;

  return {
    days: {
      mon: isSimpleRatesConfigured
        ? dayOpeningHours?.includes("mon")
        : isOpenInTheDay("mon"),
      tue: isSimpleRatesConfigured
        ? dayOpeningHours?.includes("tue")
        : isOpenInTheDay("tue"),
      wed: isSimpleRatesConfigured
        ? dayOpeningHours?.includes("wed")
        : isOpenInTheDay("wed"),
      thu: isSimpleRatesConfigured
        ? dayOpeningHours?.includes("thu")
        : isOpenInTheDay("thu"),
      fri: isSimpleRatesConfigured
        ? dayOpeningHours?.includes("fri")
        : isOpenInTheDay("fri"),
      sat: isSimpleRatesConfigured
        ? dayOpeningHours?.includes("sat")
        : isOpenInTheDay("sat"),
      sun: isSimpleRatesConfigured
        ? dayOpeningHours?.includes("sun")
        : isOpenInTheDay("sun"),
    },

    evenings: {
      mon: isSimpleRatesConfigured
        ? nightOpeningHours?.includes("mon")
        : isOpenInTheDay("mon"),
      tue: isSimpleRatesConfigured
        ? nightOpeningHours?.includes("tue")
        : isOpenInTheDay("tue"),
      wed: isSimpleRatesConfigured
        ? nightOpeningHours?.includes("wed")
        : isOpenInTheDay("wed"),
      thu: isSimpleRatesConfigured
        ? nightOpeningHours?.includes("thu")
        : isOpenInTheDay("thu"),
      fri: isSimpleRatesConfigured
        ? nightOpeningHours?.includes("fri")
        : isOpenInTheDay("fri"),
      sat: isSimpleRatesConfigured
        ? nightOpeningHours?.includes("sat")
        : isOpenInTheDay("sat"),
      sun: isSimpleRatesConfigured
        ? nightOpeningHours?.includes("sun")
        : isOpenInTheDay("sun"),
    },

    weekdays: createWeeklySchedule({
      schedule: weekdays,
      rateType: "weekdays",
      currency,
    }),

    weeknights: createWeeklySchedule({
      schedule: weeknights,
      rateType: "weeknights",
      currency,
    }),

    weekends: createWeeklySchedule({
      schedule: weekends,
      rateType: "weekends",
      currency,
    }),

    weekendEvenings: createWeeklySchedule({
      schedule: weekendEvenings,
      rateType: "weekendEvenings",
      currency,
    }),
  };
};

const generateInitialAvailabilitiesObject = (lot, settings) => {
  const currency = lot.country.currency;
  let weekdays = transformPricingObject(settings?.weekdays.data, currency);
  let weeknights = transformPricingObject(settings?.weeknights.data, currency);
  let weekends = transformPricingObject(settings?.weekends.data, currency);
  let weekendEvenings = transformPricingObject(
    settings.weekendEvenings.data,
    currency
  );

  let pricing = mapValues(settings.days, function (_, day) {
    let availabilities = [];

    const isOpenDay = settings.days[day];
    const isOpenEvening = settings.evenings[day];

    let isWeekend = includes(["sat", "sun"], day);
    let daytimeData = isWeekend ? weekends : weekdays;
    let eveningData = isWeekend ? weekendEvenings : weeknights;
    let data;

    if (isOpenDay && daytimeData) {
      data = cloneDeep(daytimeData);
      data.period = "day";
      availabilities.push(data);
    } else {
      availabilities.push({ period: "day", delete: true });
    }

    if (isOpenEvening && eveningData) {
      data = cloneDeep(eveningData);
      data.period = "evening";
      availabilities.push(data);
    } else {
      availabilities.push({ period: "evening", delete: true });
    }

    return availabilities;
  });

  return pricing;
};

const getSmallestDayNames = (dayNames) => {
  const smallestDayNames = {};

  for (const day in dayNames) {
    let dayName = dayNames[day];
    let length = 1;
    let hasEqual;
    let partialDayName;

    do {
      partialDayName = dayName.substr(0, length);

      hasEqual = some(
        dayNames,
        (anotherDayName) =>
          anotherDayName !== dayName &&
          anotherDayName.substr(0, length) === partialDayName
      );

      if (hasEqual) {
        length++;
      }
    } while (hasEqual && length <= dayName.length);

    smallestDayNames[day] = partialDayName;
  }

  return smallestDayNames;
};

const confirmSelectedDays = (pricingSettings) => {
  const t = getTranslator();

  if (
    isLotOpenOnAnyWeekday(pricingSettings) &&
    !hasWeekdayPricing(pricingSettings)
  ) {
    window.alert(t("addProperty.pricing.errorMessages.weekdayPricingMissing"));
    return false;
  }

  if (
    isLotOpenOnAnyWeekendDay(pricingSettings) &&
    !hasWeekendPricing(pricingSettings)
  ) {
    window.alert(t("addProperty.pricing.errorMessages.weekendPricingMissing"));
    return false;
  }

  return true;
};

const transformPricingObject = (obj, currency) => {
  if (!obj) {
    return null;
  }

  let result = {
    startTime: obj.startTime + ":00",
    endTime: obj.endTime + ":00",
    rate: {
      type: obj.rateType,
      value: transformToInteger(obj.rateValue, currency),
    },
    spaces: [
      {
        name: "regular",
        max: obj.spaces,
      },
    ],
  };

  if (obj.maxRate) {
    result.rate.maxRate = transformToInteger(obj.maxRate, currency);
  }

  if (obj?.discount.enabled) {
    result.rate.discount = {
      maxRate: transformToInteger(obj.discount.maxRate, currency),
      arriveBefore: obj.discount.arriveBefore + ":00",
    };
  }

  return result;
};

const isLotOpenOnAny = ({ pricingSettings, days }) =>
  some(days, (day) => pricingSettings.days[day]);

const hasWeekdayPricing = (pricingSettings) =>
  pricingSettings.weekdays?.data || pricingSettings.weeknights?.data;

const hasWeekendPricing = (pricingSettings) =>
  pricingSettings.weekends?.data || pricingSettings.weekendEvenings?.data;

const isLotOpenOnAnyWeekday = (pricingSettings) =>
  isLotOpenOnAny({
    pricingSettings,
    days: ["mon", "tue", "wed", "thu", "fri"],
  });

const isLotOpenOnAnyWeekendDay = (pricingSettings) =>
  isLotOpenOnAny({
    pricingSettings,
    days: ["sat", "sun"],
  });
