import { useMemo } from "react";

import { useAuthentication } from "../contexts/authentication";
import Permissions from "../../../shared/services/permissions";

const useVenues = () => {
  const { permissions } = useAuthentication();

  const canManageAllVenues = useMemo(() => {
    return Permissions.userMayManageAllVenues({ permissions });
  }, [permissions]);

  return { canManageAllVenues };
};

export default useVenues;
