"use strict";

import React, { useEffect, useState, useRef } from "react";
import { Select, Text, Grid } from "@citifyd/style";
import BoxContent from "../../../../shared/react/components/BoxContent";
import { useTranslator } from "../../../../shared/react/hooks";
import Table from "../../../../shared/react/components/Table";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import { loadSchedule, getData } from "./utils";
import styles from "./Schedule.module.scss";

const Schedule = ({ properties }) => {
  const t = useTranslator();
  const timer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingProperties, setIsLoadingProperties] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [propertiesWithAvailabilities, setPropertiesWithAvailabilities] =
    useState([]);
  const [hasAvailabilities, setHasAvailabilities] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const refreshRate = 3;

  const { columns, data } = getData({
    properties: propertiesWithAvailabilities,
  });

  const resetState = () => {
    setPropertiesWithAvailabilities([]);
    setIsLoadingProperties(true);
  };

  const fetchProperties = async ({
    selectedProperty,
    shouldResetAll = true,
  }) => {
    if (shouldResetAll) {
      resetState();
    }

    const properties = await loadSchedule({ selectedProperty });
    setHasError(properties?.error);
    setPropertiesWithAvailabilities(properties?.propertiesWithAvailabilities);
    setHasAvailabilities(properties?.hasAvailabilities);
    setIsLoading(false);
    setIsLoadingProperties(false);
    refreshTimeout({ selectedProperty });
  };

  const onSelectPropertyChange = (e) => {
    const selectedProperty = e.target?.value;
    clearTimeout(timer?.current);
    setSelectedProperty(selectedProperty);
    fetchProperties({ selectedProperty });
  };

  const refreshTimeout = ({ selectedProperty }) => {
    clearTimeout(timer?.current);
    timer.current = setTimeout(() => {
      fetchProperties({ selectedProperty, shouldResetAll: false });
    }, refreshRate * 1000);
  };

  useEffect(() => {
    if (options?.length === 0) {
      const list = properties?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      list.unshift({
        value: "",
        label: t("dashboard.dropdownOptionAllProperties"),
      });
      setOptions(list);
    }
  }, [properties]);

  useEffect(() => {
    fetchProperties({ selectedProperty });

    return () => {
      if (timer) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  return (
    <BoxContent>
      <BoxContent.Header title={t("dashboard.schedule")} />
      <BoxContent.Content>
        <Grid.Row justify="space-between" className={styles.filtersRow}>
          <Grid.Col sm={12} md={6} lg={4}>
            <Select
              fullWidth
              name="properties"
              options={options}
              defaultValue=""
              disabled={isLoadingProperties}
              value={selectedProperty || ""}
              onChange={(e) => onSelectPropertyChange(e)}
            />
          </Grid.Col>
        </Grid.Row>
        {isLoadingProperties && (
          <LoadingManager isLoading={isLoadingProperties} />
        )}

        {(!hasAvailabilities || !isLoadingProperties) && data?.length === 0 && (
          <Text textAlign="center">{t("dashboard.noSchedule")}</Text>
        )}

        {data &&
          columns &&
          propertiesWithAvailabilities?.length > 0 &&
          data.map((item, index) => (
            <div key={index} className={styles.tableBox}>
              <Text weight="600" gutterBottom>
                {item.name}
              </Text>
              <Table
                spacing
                columns={columns}
                data={item.availabilities}
                responsive
                showHeader={false}
              />
            </div>
          ))}
      </BoxContent.Content>
    </BoxContent>
  );
};

export default Schedule;
