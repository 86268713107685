"use strict";

import { useFormik } from "formik";
import * as yup from "yup";
import {
  isoStringToLocalDateAndTime,
  localDateAndTimeToIsoString,
} from "../../../shared/react/dateUtils";
import ADMIN_CREATE_PERMIT_PORTAL_USER from "../../../graphql/permitPortal/mutations/AdminCreatePermitPortalUser";
import ADMIN_UPDATE_PERMIT_PORTAL_USER from "../../../graphql/permitPortal/mutations/AdminUpdatePermitPortalUser";
import { useMutation } from "@apollo/client";
import { getService } from "../../../shared/react/utils";
import { showErrorAlert } from "../../../shared/services/helper";
import { portalToOption } from "../utils";
import { difference } from "lodash";
import moment from "moment";
import { validatePhoneNumberSchema } from "../../../shared/utils/validate-phone-number";
import { useTranslator } from "../../../shared/react/hooks";
import { useAuthentication } from "../../../shared/react/contexts/authentication";

const useEditUser = ({
  userData,
  apolloClient,
  trackLoading,
  untrackLoading,
  isEditing,
  permitPortals,
}) => {
  const t = useTranslator();
  const { user: loggedUser } = useAuthentication();

  const [addPermitPortalUser] = useMutation(ADMIN_CREATE_PERMIT_PORTAL_USER, {
    client: apolloClient,
  });

  const [updatePermitPortalUser] = useMutation(
    ADMIN_UPDATE_PERMIT_PORTAL_USER,
    {
      client: apolloClient,
    }
  );

  const initialValues = getInitialValues(userData, loggedUser, permitPortals);

  return useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      phoneNumber: yup
        .string()
        .when(["countryCode"], (countryCode) =>
          validatePhoneNumberSchema(countryCode)
        ),
      name: yup.string().required(),
      identifier: yup.string().required(),
      permitPortal: yup
        .array()
        .min(1, t("commonErrors.validation.mustHaveAtLeast1Item"))
        .required(),
      date: yup
        .string()
        .nullable(true)
        .when(["neverExpiresDate"], {
          is: (neverExpiresDate) => !neverExpiresDate,
          then: yup.string().nullable(true).required(),
        }),
    }),
    onSubmit: async (values) => {
      const permitPortalIds = values.permitPortal
        .map((permit) => ({ id: permit.value }))
        .filter((permit) => permit.id !== "*");

      const newAccessExpiresAt = values.neverExpiresDate
        ? null
        : localDateAndTimeToIsoString(
            values.date,
            values.time,
            loggedUser.timezoneName
          );

      const input = {
        name: values.name,
        identifier: values.identifier,
        permitPortals: permitPortalIds,
        phoneCountryCode: values.countryCode,
        phoneNumber: values.phoneNumber.replace(/[^0-9]/g, ""),
        accessExpiresAt: newAccessExpiresAt,
      };

      try {
        const $state = getService("$state");

        trackLoading("isSubmittingUser");

        if (isEditing) {
          const dateChanged = !isSameDate(
            userData.accessExpiresAt,
            newAccessExpiresAt
          );

          if (dateChanged) {
            input.accessExpiresAt = newAccessExpiresAt;
          }

          const previousPortalIds = userData.portals.map((portal) => portal.id);
          const selectedPortalIds = input.permitPortals.map(
            (portal) => portal.id
          );
          const removedPortalIds = difference(
            previousPortalIds,
            selectedPortalIds
          );

          for (const portalId of removedPortalIds) {
            input.permitPortals.push({ id: portalId, delete: true });
          }
          await updatePermitPortalUser({
            variables: { id: userData?.id, input },
          });
        } else {
          await addPermitPortalUser({
            variables: { input },
          });
        }

        $state.go("permitters");
      } catch (err) {
        console.log({ err });
        showErrorAlert(err.message);
      } finally {
        untrackLoading("isSubmittingUser");
      }
    },
  });
};

const isSameDate = (date1, date2) => {
  if ((date1 === null) !== (date2 === null)) {
    return true;
  }

  if (date1 === null) {
    return false;
  }

  return moment(date1).isSame(date2);
};

const getInitialValues = (userData, loggedUser, permitPortals) => {
  if (userData) {
    let date = null;
    let time = null;

    if (userData.accessExpiresAt) {
      [date, time] = isoStringToLocalDateAndTime(
        userData?.accessExpiresAt,
        loggedUser.timezoneName
      );
    }
    const permitPortalValue = [];
    if (permitPortals.length === userData?.portals.length) {
      permitPortalValue.push({
        value: "*",
        label: "Select All",
      });
    }
    permitPortalValue.push(...userData.portals.map(portalToOption));

    return {
      name: userData.name,
      phoneNumber: userData.phoneNumber,
      countryCode: userData.phoneCountryCode || "us",
      identifier: userData.identifier,
      permitPortal: permitPortalValue,
      date: date,
      time: time,
      neverExpiresDate: !userData.accessExpiresAt,
    };
  } else {
    return {
      name: "",
      phoneNumber: "",
      countryCode: "us",
      identifier: "",
      permitPortal: permitPortals,
      date: new Date(),
      time: moment().add(1, "hour").startOf("hour").format("HH:mm"),
      neverExpiresDate: false,
    };
  }
};

export default useEditUser;
