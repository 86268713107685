"use strict";

import React, { useEffect } from "react";
import { Grid } from "@citifyd/style";
import AccountInformation from "./componets/AccountInformation";

import { useService } from "../../shared/react/hooks";
import EditPaymentModal from "../../shared/modals/EditPaymentModal";
import { useModal } from "../../shared/react/contexts/modal";
import { useAuthentication } from "../../shared/react/contexts/authentication";

const Account = () => {
  const $state = useService("$state");
  const $location = useService("$location");
  const { user: loggedUser } = useAuthentication();
  const { openModal } = useModal();

  const openEditPaymentModal = () => {
    openModal(EditPaymentModal);
  };

  const openModalFromQueryString = () => {
    const open = $location.search().open;

    switch (open) {
      case "payment":
        openEditPaymentModal();
        break;
    }
  };

  useEffect(() => {
    if (loggedUser?.role !== "localManager") {
      openModalFromQueryString();
    }
  }, []);

  if (loggedUser && loggedUser.role === "localManager") {
    $state.go("account");
    return null;
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Col>
          <AccountInformation openEditPaymentModal={openEditPaymentModal} />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default Account;
