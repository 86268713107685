import React, { useMemo } from "react";
import {
  Button,
  Colors,
  Grid,
  Icon,
  List,
  LoadingIndicator,
  Text,
} from "@citifyd/style";
import moment from "moment-timezone";

import DevicesCollapse from "./DevicesCollapse/DevicesCollapse";
import { Transactions } from "./Transactions";

import { DeviceQuery } from "../../../graphql-pos/generated/graphql";

import { usePosRoverCtx } from "../hooks/usePosRoverCtx";
import { useTranslator } from "../../../shared/react/hooks";
import { useAuthentication } from "../../../shared/react/contexts/authentication";

import styles from "./Overview.module.scss";
import { useDevicesActions } from "../hooks/useDevicesActions";

export const ROVER_POLLING = 10000;

export const Overview = () => {
  const t = useTranslator();
  const { user } = useAuthentication();
  const { onAssign, onCancel } = useDevicesActions();

  const {
    devicesQuery: { devices, loadingDevices },
  } = usePosRoverCtx();

  const active = useMemo(
    () => devices?.filter((item) => item?.state === "ACTIVE"),
    [devices]
  );
  const provisioned = useMemo(
    () => devices?.filter((item) => item?.state === "PROVISIONED"),
    [devices]
  );
  const ready = useMemo(
    () => devices?.filter((item) => item?.state === "READY"),
    [devices]
  );
  const offline = useMemo(
    () => devices?.filter((item) => item?.state === "OFFLINE"),
    [devices]
  );

  if (loadingDevices) {
    return <LoadingIndicator withOverlay />;
  }

  return (
    <Grid>
      <Grid.Row className={styles.overview}>
        <Grid.Col xs={12} lg={6}>
          <DevicesCollapse
            devices={active}
            active
            title={
              <Title appearance="success" text={t("posRover.devices.active")} />
            }
          />

          <DevicesCollapse
            devices={provisioned}
            groupActions={(device: DeviceQuery["device"]) => {
              return (
                <Button
                  appearance="gray"
                  fullWidth
                  justifyContent="center"
                  size="small"
                  uppercase
                  onClick={() => onCancel(device)}
                >
                  {t("posRover.commons.cancel")}
                </Button>
              );
            }}
            title={
              <Title
                appearance="completed"
                text={t("posRover.devices.provisioned")}
              />
            }
            listItem={() => (
              <List.Item>
                <Text gutterBottom>
                  {t("posRover.devices.waitingForAuthentication")}
                </Text>
              </List.Item>
            )}
          />

          <DevicesCollapse
            devices={ready}
            listItem={() => (
              <List.Item>
                <Text gutterBottom>
                  {t("posRover.devices.readForAssignment")}
                </Text>
              </List.Item>
            )}
            groupActions={(device: DeviceQuery["device"]) => {
              return (
                <Button
                  appearance="tertiary"
                  fullWidth
                  justifyContent="center"
                  size="small"
                  textColor="white"
                  uppercase
                  onClick={() => onAssign(device)}
                >
                  {t("posRover.commons.assign")}
                </Button>
              );
            }}
            title={
              <Title
                appearance="secondary"
                text={t("posRover.devices.readyForProvisioning")}
              />
            }
          />

          <DevicesCollapse
            devices={offline}
            listItem={(device: DeviceQuery["device"]) => (
              <>
                <List.Item>{device.name}</List.Item>
                <List.Item>{device.model}</List.Item>
                <List.Item>
                  {t("defaultFormats.numberDatetime", {
                    date: moment(device.lastOnline).tz(user.timezoneName),
                  })}
                </List.Item>
              </>
            )}
            title={
              <Title
                appearance="error"
                text={t("posRover.devices.offlineDevices")}
              />
            }
          />
        </Grid.Col>
        <Grid.Col xs={12} lg={6}>
          <Transactions />
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

const Title = ({ appearance, text }: { appearance: Colors; text: string }) => {
  return (
    <div className={styles.title}>
      <Icon
        className={styles.icon}
        icon="circle"
        size="small"
        pull="none"
        appearance={appearance}
      />
      <Text variant="subtitle">{text}</Text>
    </div>
  );
};
