"use strict";

import React, { useEffect, useMemo, useState } from "react";
import { Grid, Label } from "@citifyd/style";

import { getEventFilters } from "../../../../../shared/api";

import { useTranslator } from "../../../../../shared/react/hooks";

import FormikField, {
  FIELD_TYPE,
} from "../../../../../shared/react/components/FormikField";

import Mode from "../../../mode";
import { syncLinkedDate } from "../../../../../shared/react/dateUtils";
import { generateTimesArray } from "../../../../../shared/services/helper";

const Form = ({ formikbag, venues, mode }) => {
  const t = useTranslator();

  const [lastStartDateUpdate, setLastStartDateUpdate] = useState(null);
  const [eventType, setEventType] = useState(null);
  const adjustAvailabilityDates = () => setLastStartDateUpdate(new Date());

  const timeOptions = useMemo(
    () =>
      generateTimesArray().map((time) => ({
        value: time.time,
        label: time.label,
      })),
    []
  );

  const venueOptions = useMemo(
    () =>
      venues.map((venue) => ({
        value: venue.id,
        label: venue.name,
      })),
    [venues]
  );

  const waitlistOptions = useMemo(
    () => [
      { label: t("addEvent.waitlistOptions.enabled"), value: "true" },
      { label: t("addEvent.waitlistOptions.disabled"), value: "false" },
    ],
    []
  );

  const fetchEventTypes = async () => {
    try {
      const { eventFilters } = await getEventFilters();

      const formattedTypes = eventFilters?.eventType?.options?.map((item) => ({
        label: item.name.plural,
        value: item.code,
      }));

      setEventType(formattedTypes);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchEventTypes();
  }, []);

  useEffect(() => {
    if (mode === Mode.EDIT || !lastStartDateUpdate) {
      return;
    }

    const { timezoneName } = venues.find(
      (venue) => venue.id === parseInt(formikbag.values.venueId)
    );

    const [updatedAvailabilityStartDate, updatedAvailabilityStartTime] =
      syncLinkedDate({
        previousDate: formikbag.values.previousStartDate,
        previousTime: formikbag.values.previousStartTime,
        newDate: formikbag.values.startDate,
        newTime: formikbag.values.startTime,
        linkedDate: formikbag.values.defaultAvailabilityStartDate,
        linkedTime: formikbag.values.defaultAvailabilityStartTime,
        timezoneName,
      });

    const [updatedAvailabilityEndDate, updatedAvailabilityEndTime] =
      syncLinkedDate({
        previousDate: formikbag.values.previousStartDate,
        previousTime: formikbag.values.previousStartTime,
        newDate: formikbag.values.startDate,
        newTime: formikbag.values.startTime,
        linkedDate: formikbag.values.defaultAvailabilityEndDate,
        linkedTime: formikbag.values.defaultAvailabilityEndTime,
        timezoneName,
      });

    formikbag.setValues(
      (values) => ({
        ...values,
        previousStartDate: formikbag.values.startDate,
        previousStartTime: formikbag.values.startTime,
        defaultAvailabilityStartDate: updatedAvailabilityStartDate,
        defaultAvailabilityStartTime: updatedAvailabilityStartTime,
        defaultAvailabilityEndDate: updatedAvailabilityEndDate,
        defaultAvailabilityEndTime: updatedAvailabilityEndTime,
      }),
      true
    );
  }, [lastStartDateUpdate]);

  return (
    <>
      <Grid.Row marginBottom>
        <Grid.Col>
          <FormikField
            fullWidth
            disabled={!formikbag.values.isEditable}
            as={FIELD_TYPE.INPUT}
            name="name"
            label={t("addEvent.labels.eventName")}
            placeholder={t("addEvent.placeholders.eventName")}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row marginBottom>
        <Grid.Col>
          <FormikField
            disabled={!formikbag.values.isEditable}
            fullWidth
            as={FIELD_TYPE.SELECT}
            name="venueId"
            label={t("addEvent.labels.venue")}
            options={venueOptions}
          />
        </Grid.Col>
      </Grid.Row>

      <Label>{t("addEvent.labels.start")}</Label>

      <Grid.Row>
        <Grid.Col sm={6}>
          <Grid.Row marginBottom gutterWidth={3}>
            <Grid.Col>
              <FormikField
                disabled={!formikbag.values.isEditable}
                fullWidth
                as={FIELD_TYPE.DATEPICKER}
                name="startDate"
                shouldResetTime
                minDate={mode === Mode.ADD ? new Date() : ""}
                handleChange={adjustAvailabilityDates}
              />
            </Grid.Col>
            <Grid.Col>
              <FormikField
                disabled={!formikbag.values.isEditable}
                fullWidth
                as={FIELD_TYPE.SELECT}
                options={timeOptions}
                label=""
                name="startTime"
                handleChange={adjustAvailabilityDates}
              />
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>

      {mode === Mode.ADD && (
        <>
          <Label>{t("addEvent.labels.duration")}</Label>
          <Grid.Row marginBottom>
            <Grid.Col xs={12} sm={6}>
              <Grid.Row gutterWidth={3}>
                <Grid.Col xs={7}>
                  <FormikField
                    fullWidth
                    as={FIELD_TYPE.DATEPICKER}
                    name="defaultAvailabilityStartDate"
                    requireTouchForError={false}
                    shouldResetTime
                    minDate={new Date()}
                  />
                </Grid.Col>
                <Grid.Col xs={5}>
                  <FormikField
                    fullWidth
                    as={FIELD_TYPE.SELECT}
                    options={timeOptions}
                    name="defaultAvailabilityStartTime"
                    hasError={Boolean(
                      formikbag.errors.defaultAvailabilityStartDate
                    )}
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col xs={12} sm={6}>
              <Grid.Row gutterWidth={3}>
                <Grid.Col xs={7}>
                  <FormikField
                    fullWidth
                    as={FIELD_TYPE.DATEPICKER}
                    name="defaultAvailabilityEndDate"
                    shouldResetTime
                    requireTouchForError={false}
                    minDate={new Date()}
                  />
                </Grid.Col>
                <Grid.Col xs={5}>
                  <FormikField
                    fullWidth
                    as={FIELD_TYPE.SELECT}
                    options={timeOptions}
                    name="defaultAvailabilityEndTime"
                    hasError={Boolean(
                      formikbag.errors.defaultAvailabilityEndDate
                    )}
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </>
      )}
      <Grid.Row marginBottom>
        <Grid.Col xs={12} sm={6}>
          <FormikField
            gutterBottom
            disabled={!formikbag.values.isEditable}
            as={FIELD_TYPE.SELECT}
            options={waitlistOptions}
            label={t("addEvent.labels.waitlist")}
            name="waitlist"
          />
        </Grid.Col>

        {eventType && (
          <Grid.Col xs={12} sm={6}>
            <FormikField
              as={FIELD_TYPE.SELECT}
              options={eventType}
              label={t("addEvent.labels.eventType")}
              name="eventType"
            />
          </Grid.Col>
        )}
      </Grid.Row>
    </>
  );
};

export default Form;
