"use strict";

import React, { useState, useRef } from "react";
import classNames from "classnames";
import { Icon } from "@citifyd/style";

import Table from "../../../../../shared/react/components/Table";
import { useTranslator } from "../../../../../shared/react/hooks";
import { tableColumns, rowObject } from "./utils";
import styles from "./Table.module.scss";

const RatesTable = ({ ratesData }) => {
  const t = useTranslator();
  const [showArrow, setShowArrow] = useState(true);
  const tableRef = useRef();

  const separator = {
    rate: null,
    appearance: "white",
  };

  const mapRates = () =>
    ratesData.rates?.map((item) => rowObject({ rate: item, type: "rateName" }));

  const mapSessions = [
    rowObject({
      rate: {
        name: t("reports.ratesTable.totalSessions"),
        values: ratesData.sessions.totals,
      },
    }),
    rowObject({
      rate: {
        name: t("reports.ratesTable.percentageSessions"),
        values: ratesData.sessions.percentages,
      },
      type: "percentage",
    }),
  ];

  const mapAmounts = [
    rowObject({
      rate: {
        name: t("reports.ratesTable.totalAmount"),
        values: ratesData.amounts.totals,
      },
      type: "amount",
      currency: ratesData.currency,
    }),
    rowObject({
      rate: {
        name: t("reports.ratesTable.percentageAmount"),
        values: ratesData.amounts.percentages,
      },
      type: "percentage",
    }),
  ];

  const data = [
    ...mapRates(),
    mapRates()?.length > 0 && separator,
    ...mapSessions,
    separator,
    ...mapAmounts,
  ];

  return (
    <div className={styles.tableBox}>
      <Table
        fixed
        striped
        columns={[
          {
            value: t("reports.ratesTable.rate"),
            key: "rate",
            width: "220px",
          },
        ]}
        data={data}
        className={styles.ratesTable}
      />
      <div className={styles.rateBoard}>
        <Table
          ref={tableRef}
          onScroll={() => showArrow && setShowArrow(false)}
          striped
          fixed
          columns={tableColumns()}
          data={data}
          responsive
        />
        <Icon
          onClick={() =>
            tableRef?.current?.scrollTo({
              top: 0,
              left: 1000,
              behavior: "smooth",
            })
          }
          className={classNames(styles.arrowIcon, {
            [styles.hideArrow]: !showArrow,
          })}
          icon="arrow-circle-right"
        />
      </div>
    </div>
  );
};

export default RatesTable;
