"use strict";

import {
  getAvailableLanguages,
  getTranslator,
} from "../../../services/languages";

const getLanguages = ({ lot, purchaser, loggedUser }) => {
  const t = getTranslator();

  return getAvailableLanguages().map((language) => {
    const languageName = t(`languages.${language.key}`);

    let languageLabel;
    switch (language.key) {
      case purchaser?.language:
        languageLabel = t(
          `modalGenerateReceipt.languageSelection.labels.parker`,
          { language: languageName }
        );
        break;

      case lot?.country?.language:
        languageLabel = t(`modalGenerateReceipt.languageSelection.labels.lot`, {
          language: languageName,
        });
        break;

      case loggedUser.language:
        languageLabel = t(
          `modalGenerateReceipt.languageSelection.labels.your`,
          { language: languageName }
        );
        break;

      default:
        languageLabel = languageName;
    }

    return {
      key: language.key,
      label: languageLabel,
    };
  });
};

export default getLanguages;
