"use strict";

import React, { useState } from "react";
import { Grid, Text, Button } from "@citifyd/style";
import { useFormikContext } from "formik";
import { get } from "lodash";

import FormikField from "../../../../shared/react/components/FormikField";
import PhoneField from "../../../../shared/react/components/PhoneField";
import { useTranslator } from "../../../../shared/react/hooks";
import { getCountry } from "../../../../shared/api";
import StickyFooter from "../../../../shared/react/components/StickyFooter";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import styles from "./EnterRecipient.module.scss";
import { getErrorMessages } from "../../../../shared/services/schema-validator";
import { showAlert, showErrorAlert } from "../../../../shared/services/helper";

const EnterRecipient = ({ countries, setStep }) => {
  const t = useTranslator();
  const { values, errors } = useFormikContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleContinue = async (e) => {
    e?.preventDefault();
    const phoneNumber = values.phoneNumber?.replace(/\s/g, "");
    const email = values.email.trim();

    if (errors.email) {
      showAlert(t("ticketTransfer.invalidEmail"));
      return;
    }

    if (phoneNumber === "" && email === "") {
      showAlert(t("ticketTransfer.phoneOrEmailRequired"));
      return;
    }

    let promise;

    if (phoneNumber !== "") {
      promise = getCountry(values.countryCode);
    } else {
      promise = Promise.resolve();
    }

    setIsLoading(true);

    try {
      const country = await promise;
      const schema = get(country, "schemas.phoneNumber");

      if (schema) {
        const errors = getErrorMessages(schema, {
          phoneNumber,
        });
        if (errors.length) {
          showAlert(errors[0]);
          setIsLoading(false);
        } else {
          setStep(2);
        }
      } else {
        setStep(2);
      }
    } catch (err) {
      showErrorAlert(err.data);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingManager isLoading={isLoading} />;
  }

  return (
    <form onSubmit={handleContinue}>
      <Grid.Row>
        <Grid.Col xs={12} md={6}>
          <PhoneField
            placeholder={t("permits.form.phoneNumber")}
            countries={countries}
            countryCodeDefault={values.countryCode || "us"}
            label={t("ticketTransfer.recipientsPhone")}
          />
        </Grid.Col>
        <Grid.Col xs={12} md={6}>
          <Grid.Row>
            <Grid.Col xs={12} md={1} className={styles.or}>
              <Text>{t("ticketTransfer.or")}</Text>
            </Grid.Col>
            <Grid.Col xs={12} md={11}>
              <FormikField
                placeholder={t("ticketTransfer.email")}
                label={t("ticketTransfer.recipientsEmail")}
                name="email"
              />
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>

      <StickyFooter justifyContent="flex-end">
        <Button uppercase onMouseDown={handleContinue} extraPadding>
          {t("commonButtons.continue")}
        </Button>
      </StickyFooter>
    </form>
  );
};

export default EnterRecipient;
