import gql from "graphql-tag";

export default gql`
  query AdminSummaryReport(
    $lotIds: [Int!]!
    $startDate: DateTime!
    $endDate: DateTime!
    $currency: String!
    $organizationId: Int
  ) {
    admin_summaryReport(
      lotIds: $lotIds
      startTime: $startDate
      endTime: $endDate
      currency: $currency
      organizationId: $organizationId
    ) {
      totalTransactions
      revenue
      processingFees
      refundsIssued
      refundsAmount
      returningCustomers
      customersRegistered
      citifydFees
      citifydServiceFeeApplied
      subsidiesPaid
      newCustomers
      net
    }
  }
`;
