import { updateUser } from "../../../../api";
import { getTranslator } from "../../../../services/languages";
import { verifyPhoneNumber as verify } from "../../../../api";

const verifyPhoneNumber = async ({ values, user }) => {
  const t = getTranslator();

  if (_.isEmpty(values.code)) {
    return {
      status: "error",
      reason: {
        data: {
          error: {
            message: t("phoneVerification.codeIsRequired"),
          },
        },
      },
    };
  }

  try {
    const phoneNumbersResponse = await verify({
      phoneCountryCode: values.phoneCountryCode,
      phoneNumber: values.phoneNumber,
      code: values.code,
    });

    const response = await updateUser(user.id, {
      phoneCountryCode: values.phoneCountryCode,
      phoneNumber: values.phoneNumber,
      phoneVerificationToken: phoneNumbersResponse.token,
    });

    return {
      status: "verified",
      user: response.data.user,
    };
  } catch (err) {
    return { status: "error", reason: err };
  }
};
export default verifyPhoneNumber;
