"use strict";

import React from "react";

const Hide = ({ if: condition, children }) => {
  return (
    <div style={{ display: condition ? "none" : "block" }}>{children}</div>
  );
};

export default Hide;
