"use strict";

import React from "react";
import { Badge } from "@citifyd/style";
import classNames from "classnames";

import { useTranslator } from "../../hooks";
import styles from "./TicketStatusBadge.module.scss";

const TicketStatusBadge = React.memo(({ statusTag, fullWidth }) => {
  const t = useTranslator();
  const label = t(`ticketStatusTags.${statusTag}`);

  const getBadgeAppearance = () => {
    let appearance;

    switch (statusTag) {
      case "purchased":
      case "additional":
        appearance = {
          appearance: "white",
          textAppearance: "gray_darker",
          ...(fullWidth && { className: styles.fullWidth }),
        };
        break;

      case "cancelled":
        appearance = {
          appearance: "tertiary",
          textAppearance: "white",
          ...(fullWidth && { className: styles.fullWidth }),
        };
        break;

      case "redeemed":
        appearance = {
          className: classNames(styles.redeemed, {
            [styles.fullWidth]: fullWidth,
          }),
          textAppearance: "white",
        };
        break;

      case "transferred":
        appearance = {
          className: classNames(styles.transferred, {
            [styles.fullWidth]: fullWidth,
          }),
        };
        break;

      case "parked":
        appearance = {
          appearance: "completed",
          textAppearance: "white",
          ...(fullWidth && { className: styles.fullWidth }),
        };
        break;

      case "expired":
        appearance = {
          appearance: "gray_darker",
          textAppearance: "white",
          ...(fullWidth && { className: styles.fullWidth }),
        };
        break;

      case "deleted":
        appearance = {
          appearance: "error",
          textAppearance: "white",
          ...(fullWidth && { className: styles.fullWidth }),
        };
        break;

      default:
        appearance = {
          appearance: "gray",
          ...(fullWidth && { className: styles.fullWidth }),
        };
        break;
    }

    return appearance;
  };

  return <Badge {...getBadgeAppearance()}>{label}</Badge>;
});

export default TicketStatusBadge;
