"use strict";

import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { isEqual, isEmpty } from "lodash";

import usePrevious from "../../../../shared/react/hooks/usePrevious";
import ApolloClient from "../../../../shared/services/apolloClient";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import { useService } from "../../../../shared/react/hooks";
import Filter from "./Filter";
import Table from "./Table";
import { getFilterSelectionFromState } from "../../utils";

import ADMIN_SUMMARY_REPORT from "../../../../graphql/reports/queries/AdminSummaryReport";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";
import moment from "moment";

const apolloClient = ApolloClient();

const SummaryReport = ({ lots, currencies, organizationsOptions }) => {
  const $state = useService("$state");
  const [params, setParams] = useState($state.params);
  const prevParams = usePrevious($state.params);
  const { user } = useAuthentication();

  const [getSummary, { data, loading, error }] = useLazyQuery(
    ADMIN_SUMMARY_REPORT,
    {
      client: apolloClient,
      fetchPolicy: "network-only",
    }
  );

  const getQueryOptionsFromFilterSelection = (filterSelection) => {
    return {
      startDate: moment(filterSelection.startDate).format(
        `YYYY-MM-DDT00:00:00Z`
      ),
      endDate: moment(filterSelection.endDate).format(`YYYY-MM-DDT23:59:59Z`),
      organizationId: filterSelection.organizationId
        ? Number(filterSelection.organizationId)
        : null,
      lotIds:
        filterSelection.lots?.split(",").map((item) => parseInt(item, 10)) ||
        [],
      currency: filterSelection.currency,
    };
  };

  useEffect(() => {
    if (!isEqual(prevParams, $state.params) && !isEmpty($state.params)) {
      setParams($state.params);
    }
  }, [$state.params]);

  useEffect(() => {
    if (params.dateRange) {
      const filterSelection = getFilterSelectionFromState({ user });
      const options = getQueryOptionsFromFilterSelection(filterSelection);
      getSummary({
        variables: {
          ...options,
        },
      });
    }
  }, [params]);

  const generateCsvClicked = () => {};

  return (
    <div>
      <Filter
        onClickGenerateCsv={generateCsvClicked}
        lots={lots}
        currencies={currencies}
        organizationsOptions={organizationsOptions}
      />
      {loading || error ? (
        <LoadingManager isLoading={loading} hasError={error} />
      ) : (
        data && <Table summaryData={data.admin_summaryReport} />
      )}
    </div>
  );
};

export default SummaryReport;
