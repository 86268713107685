"use strict";

import React from "react";

import ReactRouter from "./shared/react/components/ReactRouter";
import AfterAuthModals from "./shared/react/components/AfterAuthModals";
import { AuthenticationProvider } from "./shared/react/contexts/authentication";
import { ModalProvider } from "./shared/react/contexts/modal";
import { StripeStatusProvider } from "./shared/react/contexts/stripeStatus";

const CitifydApp = () => (
  <AuthenticationProvider>
    <StripeStatusProvider>
      <ModalProvider>
        <AfterAuthModals>
          <ReactRouter />
        </AfterAuthModals>
      </ModalProvider>
    </StripeStatusProvider>
  </AuthenticationProvider>
);

export default CitifydApp;
