"use strict";

import React, { useMemo } from "react";
import { Button, Icon, Badge, Grid, Text } from "@citifyd/style";
import { transformToInteger } from "../../../../shared/services/currencies";
import styles from "./MonthlyParking.module.scss";
import { useTranslator } from "../../../../shared/react/hooks";
import classNames from "classnames";
import { getSelectedWeekDaysString, getSummaryTimeRange } from "../utils";

const ViewPlan = ({ currency, plan, onRemove, onEdit, index }) => {
  const t = useTranslator();

  const selectedWeekDays = useMemo(
    () => getSelectedWeekDaysString({ selectedDays: plan.daysOfWeek }),
    [plan.daysOfWeek]
  );

  const timeRange = useMemo(() => getSummaryTimeRange(plan), [plan]);

  return (
    <Grid.Row>
      <Grid.Col
        sm={11}
        className={classNames({ [styles.disabled]: plan.removed })}
      >
        <div style={{ display: "flex" }}>
          <Text
            bold
            className={styles.text}
            variant="subtitle"
            style={{ paddingRight: "5px" }}
          >
            {plan.name}
          </Text>
          {!plan.id && (
            <Badge appearance="success" textAppearance="white" weight="400">
              {t("addProperty.new")}
            </Badge>
          )}
        </div>

        <Text className={styles.text}>
          {t("addProperty.perMonth", {
            amount: transformToInteger(plan.price, currency),
            currency: currency,
          })}
        </Text>

        <Text className={styles.text}>{timeRange}</Text>

        <Text className={styles.text}>{selectedWeekDays}</Text>

        <Text className={styles.text}>
          {t("addProperty.billedOnDayOfMonth", {
            day: plan.billingCycle,
          })}
        </Text>
        {plan.cancellationNoticePeriod > 0 && (
          <Text className={styles.text}>
            {t("addProperty.cancellationNotice", {
              cancel: plan.cancellationNoticePeriod,
            })}
          </Text>
        )}
        {plan.cancellationNoticePeriod === 0 && (
          <Text className={styles.text}>{t("addProperty.noCancellation")}</Text>
        )}
        <Text className={styles.text}>
          {t("addProperty.monthlyPassesAvailable", {
            spaces: plan.spaces,
          })}
        </Text>
      </Grid.Col>
      <Grid.Col sm={1} className={styles.actionButtons}>
        <Button
          disabled={plan.removed}
          appearance="transparent"
          onClick={() => onEdit({ index })}
        >
          <Icon icon="pencil-alt" pull="right" size="h2" />
        </Button>
        <Button appearance="transparent" onClick={() => onRemove({ index })}>
          {plan.removed ? (
            <Icon icon="trash-restore-alt" pull="left" size="h2" />
          ) : (
            <Icon icon="trash" pull="left" size="h2" />
          )}
        </Button>
      </Grid.Col>
    </Grid.Row>
  );
};

export default ViewPlan;
