"use strict";

import React, { useCallback, ReactNode } from "react";
import { useService } from "../../hooks";
import { Button, Colors } from "@citifyd/style";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface RouterLinkButtonProps {
  appearance?: Colors;
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  extraPadding?: boolean;
  fullWidth?: boolean;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  justifyContent?: "flex-start" | "center" | "flex-end";
  letterSpacing?: boolean;
  nowrap?: boolean;
  rounded?: boolean;
  size?: "tiny" | "small" | "medium";
  shadow?: boolean;
  textColor?: Colors;
  uppercase?: boolean;
  state: string;
  params?: { [key: string]: string };
  options?: any;
}

const RouterLinkButton = ({
  state = "",
  params = {},
  options = {},
  children,
  ...props
}: RouterLinkButtonProps) => {
  const $state = useService("$state");
  const onClick = useCallback(
    () => $state.go(state, params, options),
    [state, params, options]
  );

  return (
    <Button {...props} onClick={onClick}>
      {children}
    </Button>
  );
};

export default RouterLinkButton;
