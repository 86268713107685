import { getEvent } from "../../shared/api";
import { setPageTitle, showErrorAlert } from "../../shared/services/helper";

export const loadEvent = async (eventId) => {
  return getEvent(eventId)
    .then((response) => {
      setPageTitle(response.name);

      return response;
    })
    .catch((err) => {
      if (err.status === 404) {
        window.location.replace("/");
      } else {
        showErrorAlert(err);

        return err;
      }
    });
};
