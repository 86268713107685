"use strict";

import React, { useMemo } from "react";
import { Text } from "@citifyd/style";
import { useFormikContext } from "formik";

import Table from "../../../../shared/react/components/Table";
import { useTranslator } from "../../../../shared/react/hooks";
import { getData } from "./utils.js";
import styles from "./TicketExchangeConfirmationSummary.module.scss";

const TicketExchangeConfirmationSummary = ({
  ticket,
  currentRate,
  newRate,
  transfersReversedAmount,
  totalChargedOrRefunded,
  rateDifference,
}) => {
  const t = useTranslator();
  const { values } = useFormikContext();
  const { selectedEvent, selectedLot } = values;

  const { columns, data } = useMemo(
    () =>
      getData(
        ticket,
        currentRate,
        selectedEvent,
        selectedLot,
        newRate,
        transfersReversedAmount,
        totalChargedOrRefunded,
        rateDifference
      ),
    []
  );

  return (
    <>
      <Text variant="h5" gutterBottom>
        {t("ticketExchange.exchangeSummary")}
      </Text>

      <Table
        columns={columns}
        data={data}
        striped
        responsive
        className={styles.table}
        gutterBottom
      />
    </>
  );
};

export default TicketExchangeConfirmationSummary;
