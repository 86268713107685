import gql from "graphql-tag";

export default gql`
  fragment Cancellation on Cancellation {
    feeRetentionMethod
    id
    reason
    refunded
    refunder
    requestedByEmail
    requestedByPhone
    stripeFeePayer
    transactions {
      type
      totalAmount
      currency
    }
    user {
      id
      name
    }
  }
`;
