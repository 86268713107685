"use strict";

import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { sum, find, isEqual, isEmpty } from "lodash";
import { Text } from "@citifyd/style";

import usePrevious from "../../../../hooks/usePrevious";
import LoadingManager from "../../../LoadingManager";
import { useService, useTranslator } from "../../../../hooks";
import RevenueFilter from "../RevenueFilter";
import RevenueDailyTable from "../RevenueDailyTable";
import { getFilterSelectionFromState } from "../../utils";
import GenerateReportModal from "../../../../../modals/GenerateReportModal";
import {
  getRevenueDatesCsv,
  getRevenueDates,
  isRequestCancelled,
} from "../../../../../api";

import { useModal } from "../../../../contexts/modal";

const RevenueDaily = ({
  className,
  lots,
  currencies,
  organizationsOptions,
}) => {
  const $state = useService("$state");
  const t = useTranslator();
  const { openModal } = useModal();
  const abortRequest = useRef(null);
  const [dates, setDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totals, setTotals] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const prevParams = usePrevious($state.params);

  const getQueryOptionsFromFilterSelection = (filterSelection) => {
    // We send offset = 00:00 because the back-end requires it, but the
    // back-end should always filter using the lot timezone.
    const offset = "+00:00";

    return {
      rangeStart: moment(filterSelection.dateRange.startDate).format(
        `YYYY-MM-DDT00:00:00${offset}`
      ),
      rangeEnd: moment(filterSelection.dateRange.endDate).format(
        `YYYY-MM-DDT23:59:59${offset}`
      ),
      currency: filterSelection.currency,
      lotId: filterSelection.lotId,
      organizationId: filterSelection.organizationId
        ? Number(filterSelection.organizationId)
        : null,
      timezoneName: filterSelection.timezoneName,
    };
  };

  const calculateTotals = (dates) => {
    const fields = [
      "reservationCount",
      "reservedParkingPassCount",
      "ticketCount",
      "subscriptionCount",
      "netRevenueAmount",
    ];

    let totals = {};

    for (const field of fields) {
      totals[field] = sum(dates, (date) => {
        return date[field];
      });
    }

    setTotals(totals);
  };

  const load = async () => {
    setIsLoading(true);

    if (abortRequest.current) {
      abortRequest.current.abort();
      abortRequest.current = null;
    }

    const filterSelection = getFilterSelectionFromState($state);
    const options = getQueryOptionsFromFilterSelection(filterSelection);

    setCurrentFilter(filterSelection);

    try {
      abortRequest.current = new AbortController();

      const revenue = await getRevenueDates({
        params: options,
        signal: abortRequest.current.signal,
      });

      setDates(revenue);
      calculateTotals(revenue);

      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
      if (!isRequestCancelled(err)) {
        setIsLoading(false);
      }
    } finally {
      abortRequest.current = null;
    }
  };

  useEffect(() => {
    if (
      !isEqual(prevParams, $state.params) &&
      !isEmpty($state.params?.dateRange)
    ) {
      load();
    }
  }, [$state.params]);

  function generateCsvReportDescription(filterSelection) {
    const options = {
      startDate: moment.tz(filterSelection.dateRange.startDate, "UTC"),
      endDate: moment.tz(filterSelection.dateRange.endDate, "UTC"),
    };

    if (filterSelection.lotId) {
      const lot = find(lots, (lot) => lot.id === Number(filterSelection.lotId));
      return t("dailyRevenue.csvReportName.daily.lot", {
        ...options,
        lotName: lot.name,
      });
    } else {
      return t("dailyRevenue.csvReportName.daily.currency", {
        ...options,
        currency: filterSelection.currency.toUpperCase(),
      });
    }
  }

  const generateCsvClicked = () => {
    const filterSelection = getFilterSelectionFromState($state);
    const options = getQueryOptionsFromFilterSelection(filterSelection);

    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription: generateCsvReportDescription(filterSelection),
      reportCsvRequest: getRevenueDatesCsv(options),
    });
  };

  return (
    <div className={className}>
      <RevenueFilter
        organizationsOptions={organizationsOptions}
        onClickGenerateCsv={generateCsvClicked}
        enableLotSelection
        lots={lots}
        currencies={currencies}
        data={dates}
      />
      {isLoading && <LoadingManager isLoading={isLoading} />}
      {dates.length > 0 && !isLoading && (
        <RevenueDailyTable
          dates={dates}
          totals={totals}
          currentFilter={currentFilter}
        />
      )}
      {dates.length === 0 && !isLoading && (
        <Text textAlign="center">{t("search.noResults")}</Text>
      )}
    </div>
  );
};

export default RevenueDaily;
