"use strict";

import React from "react";
import { Text } from "@citifyd/style";
import classNames from "classnames";
import { useTranslator } from "../../../../shared/react/hooks";
import styles from "./Properties.module.scss";

const LegendList = ({ total, anyLotHasLongTermParkingPlan }) => {
  const t = useTranslator();
  return (
    <>
      <Text
        className={styles.spaces}
        variant="subtitle"
        dangerouslySetInnerHTML={{
          __html: t("propertySnapshot.totalSpaces", {
            occupied: total?.occupied,
            count: total?.spaces,
            startBold: "<strong>",
            endBold: "</strong>",
          }),
        }}
      />
      <Text className={classNames(styles.legendColor, styles.space)}>
        {t("propertySnapshot.availableSpaces", {
          count: total?.available,
        })}
      </Text>
      <Text className={classNames(styles.legendColor, styles.ondemand)}>
        {t("propertySnapshot.onDemandParkers", {
          count: total?.ondemand,
        })}
      </Text>
      <Text className={classNames(styles.legendColor, styles.permit)}>
        {t("propertySnapshot.permitParkers", { count: total?.permit })}
      </Text>
      <Text className={classNames(styles.legendColor, styles.reservedparking)}>
        {t("propertySnapshot.reservedParkingParkers", {
          count: total?.reservedparking,
        })}
      </Text>
      {anyLotHasLongTermParkingPlan && (
        <Text className={classNames(styles.legendColor, styles.monthly)}>
          {t("propertySnapshot.monthlyParkers", {
            count: total?.monthly,
          })}
        </Text>
      )}
      <Text className={classNames(styles.legendColor, styles.purchased)}>
        {t("propertySnapshot.eventPassesPurchased", {
          count: total?.purchased,
        })}
      </Text>
      <Text className={classNames(styles.legendColor, styles.parked)}>
        {t("propertySnapshot.eventPassesParked", {
          count: total?.parked,
        })}
      </Text>
    </>
  );
};

export default LegendList;
