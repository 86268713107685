"use strict";

import React, { useMemo } from "react";
import { useFormikContext, Form } from "formik";
import { Button, Text } from "@citifyd/style";

import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import Table from "../../../../shared/react/components/Table";
import StickyFooter from "../../../../shared/react/components/StickyFooter";
import { useTranslator } from "../../../../shared/react/hooks";
import styles from "./Confirmation.module.scss";
import { getData } from "./utils";

const Confirmation = ({ ticket }) => {
  const t = useTranslator();
  const { handleSubmit, values } = useFormikContext();

  if (!ticket) {
    return null;
  }

  const {
    columnsTransferSummary,
    dataTransferSummary,
    columnsRecipient,
    dataRecipient,
  } = useMemo(() => getData({ ticket, values }), []);

  return (
    <Form>
      <Text component="h5" variant="subtitle" gutterBottom>
        {t("ticketTransfer.transferSummary")}
      </Text>
      <Table
        columns={columnsTransferSummary}
        data={dataTransferSummary}
        responsive
        gutterBottom
      />
      <br />
      <Text component="h5" variant="subtitle" gutterBottom>
        {t("ticketTransfer.recipient")}
      </Text>
      <Table
        columns={columnsRecipient}
        data={dataRecipient}
        responsive
        gutterBottom
      />
      <br />
      <FormikField
        as={FIELD_TYPE.TEXTAREA}
        name="reason"
        label={t("ticketTransfer.reason")}
        className={styles.textarea}
        gutterBottom
      />
      <FormikField
        as={FIELD_TYPE.CHECKBOX}
        name="requestedByPhone"
        label={t("ticketTransfer.requestedPhone")}
        gutterBottom
      />
      <br />
      <FormikField
        as={FIELD_TYPE.CHECKBOX}
        name="requestedByEmail"
        label={t("ticketTransfer.requestedEmail")}
      />
      <StickyFooter justifyContent="flex-end">
        <Button uppercase onMouseDown={handleSubmit} extraPadding>
          {t("commonButtons.save")}
        </Button>
      </StickyFooter>
    </Form>
  );
};

export default Confirmation;
