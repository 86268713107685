import React from "react";
import { Label, Icon } from "@citifyd/style";
import classNames from "classnames";
import { useTranslator } from "../../hooks";
import styles from "./ColorSelector.module.scss";

export const AVAILABLE_COLORS = [
  "#ffa00b",
  "#498d86",
  "#0685aa",
  "#f47921",
  "#62c3a7",
  "#7576ce",
  "#b1678c",
];

const ColorSelector = ({ availableColors = [], selectedColor, setColor }) => {
  const t = useTranslator();

  return (
    <div className={styles.colorSelector}>
      <Label>{t("Color")}</Label>
      <div className={styles.buttons}>
        {availableColors.map((color, index) => {
          const selected = selectedColor === color;

          return (
            <button
              key={index}
              type="button"
              className={classNames(styles.button, {
                [styles.active]: selected,
              })}
              style={{ backgroundColor: color }}
              onClick={() => setColor("color", color)}
            >
              {selected && <Icon className={styles.icon} icon="check" />}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ColorSelector;
