"use strict";
import { getRevenuePayoutItemsCsv } from "../../../../shared/api";
import GenerateReportModal from "../../../../shared/modals/GenerateReportModal";
import { getTranslator } from "../../../../shared/services/languages";

const generateCsvReportDescription = ({ lot, lotId, payout }) => {
  const t = getTranslator();
  if (lotId) {
    return t("payout.csvReportName.lot", {
      payoutDescription: payout.description,
      lotName: lot.name,
    });
  } else {
    return t("payout.csvReportName.general", {
      payoutDescription: payout.description,
    });
  }
};

const generateCsvClicked = ({ lotId, payoutId, lot, payout, openModal }) => {
  const options = {
    lotId: lotId,
  };

  openModal(GenerateReportModal, {
    fileFormat: "csv",
    filterDescription: generateCsvReportDescription({ lotId, lot, payout }),
    disableFilterDescriptionFirstCharacterLowercase: true,
    reportCsvRequest: getRevenuePayoutItemsCsv(payoutId, options),
  });
};

export default generateCsvClicked;
