"use strict";

import React from "react";
import { Text } from "@citifyd/style";
import CurrencyFormatter from "@citifyd/currency-formatter";

import { useTranslator } from "../../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../../shared/services/languages";
import Table from "../../../../shared/react/components/Table";
import styles from "./RevenueOfEventSummary.module.scss";

const RevenueOfEventSummary = ({ selectedLots, summary, currency }) => {
  const t = useTranslator();
  const language = getCurrentLanguage();

  const columns = [
    {
      key: "garage",
      value: t("revenue.garage"),
    },
    {
      key: "sold",
      value: t("revenue.sold"),
      textAlign: "right",
    },
    {
      key: "redeemed",
      value: t("revenue.redeemed"),
      textAlign: "right",
    },
    {
      key: "total",
      value: t("revenue.totalSm"),
      textAlign: "right",
    },
  ];

  const data = selectedLots.map((lot) => ({
    garage: lot.name,
    sold: {
      value: summary.lots[lot.id].totalTickets || "0",
      textAlign: "right",
    },
    redeemed: {
      value: summary.lots[lot.id].totalTicketsRedeemed || "0",
      textAlign: "right",
    },
    total: {
      value: CurrencyFormatter.format(summary.lots[lot.id].netRevenue, {
        currency: currency,
        language,
      }),
      textAlign: "right",
      textColor: summary.lots[lot.id].netRevenue < 0 ? "tertiary" : "default",
    },
  }));

  const renderCell = (value) => (
    <Text textAlign="right" weight="700" className={styles.total}>
      {value}
    </Text>
  );

  const dataWithTotal = [
    ...data,
    {
      garage: (
        <Text weight="700" className={styles.total}>
          {t("dailyRevenue.total")}
        </Text>
      ),
      sold: {
        value: renderCell(summary.totals.totalTickets || "0"),
        textAlign: "right",
      },
      redeemed: {
        value: renderCell(summary.totals.totalTicketsRedeemed || "0"),
        textAlign: "right",
      },
      total: {
        value: renderCell(
          CurrencyFormatter.format(summary.totals.netRevenue, {
            currency: currency,
            language,
          })
        ),
        textAlign: "right",
        textColor: summary.totals.netRevenue < 0 ? "tertiary" : "default",
      },
    },
  ];

  return (
    <>
      <Text className={styles.title} variant="h3" component="h2">
        {t("revenue.summary")}
      </Text>
      <Table
        columns={columns}
        data={dataWithTotal}
        striped
        responsive
        borderBottom
        className={styles.table}
      />
    </>
  );
};

export default RevenueOfEventSummary;
