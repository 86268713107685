"use strict";

import React, { useEffect, useState } from "react";
import { Grid } from "@citifyd/style";

import PageHeader from "../../shared/react/components/PageHeader";
import LoadingManager from "../../shared/react/components/LoadingManager";
import { useTranslator, useService } from "../../shared/react/hooks";
import TicketTransactionData from "./components/TicketTransactionData";
import { getTicket } from "../../shared/api";
import { setPageTitle } from "../../shared/services/helper";

const Ticket = () => {
  const t = useTranslator();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const loadingMessage = t("ticket.loadingPass");
  const $stateParams = useService("$stateParams");
  const [ticket, setTicket] = useState();
  const ticketId = $stateParams.id;

  const loadTicket = async () => {
    setHasError(false);

    try {
      const ticket = await getTicket(ticketId);

      setPageTitle(
        t("ticket.pageTitleWithData", {
          id: ticket.id,
          eventName: ticket.event.name,
        })
      );
      setTicket(ticket);
    } catch (err) {
      console.log("err", err);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTicket();
  }, []);

  if (isLoading || hasError) {
    return (
      <LoadingManager
        isLoading={isLoading}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    );
  }

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          defaultState={ticket?.user ? "user" : "users"}
          defaultParams={{ id: ticket?.user?.id }}
        />
      </PageHeader>

      <TicketTransactionData ticket={ticket} actionsEnabled />
    </Grid>
  );
};

export default Ticket;
