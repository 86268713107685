import moment from "moment-timezone";

export const selectDefaultFilters = ($state) => {
  const params = { ...$state.params };
  let changed = false;

  if (!params.dateRange) {
    const start = moment().startOf("month").format("YYYY-MM-DD");
    const end = moment().endOf("month").format("YYYY-MM-DD");
    params.dateRange = `${start}:${end}`;
    changed = true;
  }

  if (changed) {
    $state.transitionTo($state.current.name, params, {
      location: "replace",
      notify: false,
      inherit: true,
    });
  }
  return { changed };
};

const getDefaultDate = (month) =>
  moment(new Date())[month]("month").format("YYYY-MM-DD");

const getValidDate = (date, month) =>
  moment(date).isValid()
    ? moment(date).format("YYYY-MM-DD")
    : getDefaultDate(month);

const validStatus = ["CANCELLED", "ACTIVATED", "EXPIRED", "PENDING"];

export const getFilterSelectionFromState = (params) => {
  const parts = params.dateRange.split(":");
  let start = getValidDate(parts[0], "startOf");
  let end = getValidDate(parts[1], "endOf");

  if (moment(start).isAfter(end)) {
    start = getDefaultDate("startOf");
    end = getDefaultDate("endOf");
  }

  const dateRange = { start, end };

  const status = validStatus.includes(params.status?.toUpperCase())
    ? params.status?.toUpperCase()
    : null;
  const search = params.search || null;

  return { status, search, dateRange };
};
