"use strict";

import React from "react";
import { Tab } from "@citifyd/style";
import Properties from "../Properties";
import Purchases from "../Purchases";
import Waitlist from "../Waitlist";

import styles from "./Tab.module.scss";

const EventTab = ({ active, name, tab, event }) => {
  const renderContent = () => {
    switch (tab) {
      case "gates":
        return <Properties event={event} className={styles.content} />;
      case "tickets":
        return <Purchases event={event} className={styles.content} />;
      case "waitlist":
        return <Waitlist event={event} className={styles.content} />;
    }
  };

  return (
    <Tab.Item active={active} name={name}>
      {renderContent()}
    </Tab.Item>
  );
};

export default EventTab;
