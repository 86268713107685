"use strict";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { chunk, findIndex } from "lodash";
import produce from "immer";
import { Icon, Text, Grid, AlertMessage } from "@citifyd/style";
import styles from "./AccountLots.module.scss";

import { useTranslator } from "../../../../shared/react/hooks";
import Permissions from "../../../../shared/services/permissions";
import LoadingManager from "../../../../shared/react/components/LoadingManager";

import { getAllProperties } from "../../../../shared/api";

import Lot from "./Lot.jsx";
import PageHeader from "../../../../shared/react/components/PageHeader";
import RouterLinkButton from "../../../../shared/react/components/RouterLinkButton";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";
import BoxContent from "../../../../shared/react/components/BoxContent";

function AccountLots() {
  const t = useTranslator();
  const { permissions } = useAuthentication();

  const [lots, setLots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await getAllProperties();
        setLots(response);
      } catch (err) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const canCreateLots = useMemo(() => {
    return Permissions.userMayCreateLot(permissions);
  }, []);

  const canEditLots = useMemo(() => {
    return Permissions.userMayEditLot(permissions);
  }, []);

  const updateLotOnList = useCallback(
    (lotId, newLotInformation) => {
      setLots(
        produce(lots, (lots) => {
          const lotIndex = findIndex(lots, (lot) => lot.id === lotId);
          lots[lotIndex] = newLotInformation;
        })
      );
    },
    [lots]
  );

  return (
    <>
      <PageHeader>
        <PageHeader.Title weight="300" title={t("accountInfo.properties")} />
        <PageHeader.Actions>
          {!isLoading && canCreateLots && (
            <RouterLinkButton
              size="small"
              letterSpacing
              uppercase
              state="add-property"
              iconRight="plus-circle"
            >
              {t("accountInfo.add")}
            </RouterLinkButton>
          )}
        </PageHeader.Actions>
      </PageHeader>

      <BoxContent noShadow>
        <BoxContent.Content>
          <Text appearance="gray_dark">
            <div
              dangerouslySetInnerHTML={{
                __html: t("accountInfo.parkingTypeDescription"),
              }}
            />
          </Text>
          <Text appearance="gray_dark">
            {t("accountInfo.enhancedPropertyCustomizationDescription")}
          </Text>
        </BoxContent.Content>
      </BoxContent>
      <br />
      <LotList
        lots={lots}
        canEditLots={canEditLots}
        updateLotOnList={updateLotOnList}
        isLoading={isLoading}
        hasError={hasError}
      />
    </>
  );
}

function LotList({ lots, canEditLots, updateLotOnList, isLoading, hasError }) {
  const chunks = useMemo(() => chunk(lots, 2), [lots]);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  if (!lots.length) {
    return <NoLots />;
  }

  const renderChunks = () =>
    chunks.map((lots) =>
      lots.map((lot) => (
        <Grid.Col md={6} key={lot.id}>
          <Lot
            lot={lot}
            canEditLot={canEditLots}
            updateLotOnList={updateLotOnList}
          />
        </Grid.Col>
      ))
    );

  return <Grid.Row className={styles.lotList}>{renderChunks()}</Grid.Row>;
}

function NoLots() {
  const t = useTranslator();
  return (
    <div className={styles.noLots}>
      <Text gutterBottom variant="h5">
        {t("accountInfo.setup")}
      </Text>
      <Text>{t("accountInfo.start")}</Text>
    </div>
  );
}

export default AccountLots;
