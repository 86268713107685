import React, { useMemo, useState } from "react";
import moment from "moment";
import styles from "./Transactions.module.scss";

import {
  InputMaybe,
  TransactionOrderBy,
  TransactionType,
  useTransactionsQuery,
} from "../../../graphql-pos/generated/graphql";
import Table from "../../../shared/react/components/Table";

import PosApolloClient from "../../../shared/services/posApolloClient";
import { currencyFormatter } from "../../../shared/utils/currencyFormtter";
import { usePagination } from "../../../shared/react/components/CursorPagination/usePagination";
import { CursorPagination } from "../../../shared/react/components/CursorPagination/CursorPagination";
import { Grid, LoadingIndicator, Select } from "@citifyd/style";

import { useTranslator } from "../../../shared/react/hooks";
import { useTransactionFilter } from "../hooks/useTransactionFilter";

const posApolloClient = PosApolloClient();

const pageSize = 10;
export const Transactions = () => {
  const t = useTranslator();
  const { options, typeKeys } = useTransactionFilter();
  const [filterBy, setFilterBy] = useState<InputMaybe<TransactionType>>(null);

  const {
    refetch,
    data: transactions,
    loading,
  } = useTransactionsQuery({
    client: posApolloClient,
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    variables: {
      first: pageSize,
      order: TransactionOrderBy["CreatedAt"],
      ...(filterBy ? { type: filterBy } : {}),
    },
  });

  const columns = [
    { value: t("posRover.devices.date"), key: "date" },
    { value: t("posRover.devices.time"), key: "time" },
    { value: t("posRover.devices.type"), key: "type" },
    { value: t("posRover.devices.value"), key: "value" },
    { value: t("posRover.devices.device"), key: "device" },
    { value: t("posRover.devices.agent"), key: "agent" },
    { value: t("posRover.devices.gate"), key: "gate" },
    { value: t("posRover.devices.event"), key: "event" },
  ];

  const data = useMemo(
    () =>
      transactions?.transactions.edges?.map((trans) => {
        const transaction = trans?.node;

        if (transaction) {
          const rate = transaction.payment?.rate.rate;
          const currency = transaction.citifydLot?.paymentDestination.currency;

          return {
            date: moment(transaction.createdAt).format("L"),
            time: moment(transaction.createdAt).format("hh:mm A"),
            type:
              transaction.type === "PAYMENT"
                ? typeKeys[transaction.payment?.type!]
                : typeKeys[transaction.type],
            value: currencyFormatter({
              value: rate?.value ? rate?.value + rate.fee : 0,
              currency: currency || "usd",
            }),
            device: `#${transaction.device?.shortCode}`,
            agent: transaction.agent?.name,
            gate: transaction.citifydGate?.name,
            event: transaction.citifydEvent?.name,
          };
        }
      }),
    [transactions]
  );

  const { hasNextPage, hasPreviousPage, nextPage, previousPage } =
    usePagination({
      data: transactions?.transactions,
      pageSize: pageSize,
      refetch,
    });

  return (
    <div className={styles.container}>
      <Grid>
        <Grid.Row marginBottom>
          <Grid.Col sm={3} offset={{ sm: 9 }}>
            <Select
              disabled={loading}
              fullWidth
              value={filterBy ? filterBy : ""}
              labelSize="small"
              options={options}
              onChange={(event) => {
                setFilterBy(event.target.value);
              }}
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col>
            {loading && <LoadingIndicator withOverlay />}

            <Table striped columns={columns} data={data} responsive />

            <CursorPagination
              loading={loading}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              nextPage={nextPage}
              previousPage={previousPage}
              data={
                transactions?.transactions.edges
                  ? transactions.transactions.edges
                  : []
              }
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
};
