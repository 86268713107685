import { getAllProperties } from "../../shared/api";

export const fetchProperties = (user) => {
  var options = {};

  if (user.isAdmin) {
    options.all = true;
  }

  return getAllProperties({ params: options });
};
