"use strict";

import React from "react";
import { LoadingIndicator, Grid, Button } from "@citifyd/style";
import { useTranslator } from "../../../../../shared/react/hooks";
import Address from "../../../../../shared/react/components/Address";
import RouterLinkButton from "../../../../../shared/react/components/RouterLinkButton";

import styles from "./LotDetails.module.scss";
import RouterLink from "../../../../../shared/react/components/RouterLink";

const LotDetails = ({ property, toggleApproval }) => {
  const t = useTranslator();

  if (property.isUpdating) {
    return <LoadingIndicator />;
  }

  const mountMapUrl =
    "http://google.com/maps?q=" + property.latitude + "," + property.longitude;

  return (
    <Grid.Row>
      <Grid.Col sm={12} md={3} justify="start">
        <img src={property.photoUrl} />
      </Grid.Col>
      <Grid.Col sm={12} md={9} justify="start">
        <div className={styles.detail}>
          <h4>{property.name}</h4>
          <address>
            <Address entity={property} format="full" />
          </address>
          <ul>
            <li>
              {t("allProperties.createdOn", { date: property.createdAt })}
            </li>
            <li>
              {property.organization && (
                <span>
                  {t("allProperties.owner")}: {property.organization.name}{" "}
                  <RouterLink
                    state="user"
                    params={{ id: property.organization.OwnerId }}
                  >
                    ({t("allProperties.contactInfo")})
                  </RouterLink>
                </span>
              )}
            </li>
            <li>
              {t("allProperties.approved")}:{" "}
              {property.isApproved ? t("yes") : t("no")}
            </li>
            <li>
              {t("allProperties.visible")}:{" "}
              {property.isVisible ? t("yes") : t("no")}
            </li>
          </ul>
          <div className={styles.lotActions}>
            <Button
              extraPadding
              size="small"
              letterSpacing
              uppercase
              href={mountMapUrl}
              target="_blank"
            >
              {t("allProperties.openMap")}
            </Button>
            <RouterLinkButton
              state="edit-property"
              params={{ lotId: property.id }}
              extraPadding
              size="small"
              letterSpacing
              uppercase
            >
              {t("allProperties.editProperty")}
            </RouterLinkButton>
            <Button
              onClick={() => toggleApproval(property.id)}
              appearance={!property.isApproved ? "success" : "error"}
              extraPadding
              size="small"
              letterSpacing
              uppercase
            >
              {property.isApproved
                ? t("allProperties.disapprove")
                : t("allProperties.approve")}
            </Button>
          </div>
        </div>
      </Grid.Col>
    </Grid.Row>
  );
};

export default LotDetails;
