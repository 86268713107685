import gql from "graphql-tag";

export default gql`
  query AdminVenues($first: Int, $after: String) {
    admin_venues(first: $first, after: $after) {
      total
      edges {
        node {
          __typename
          countryCode
          id
          name
          venueAddress
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
