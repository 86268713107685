import React, { useState, useMemo, ReactNode } from "react";
import { LoadingIndicator, Grid, Text, SectionCollapse } from "@citifyd/style";

import { ActiveCard } from "./ActiveCard";
import { Card } from "./Card";
import SearchInput from "../../../../shared/react/components/SearchInput";

import {
  DeviceQuery,
  DevicesQuery,
} from "../../../../graphql-pos/generated/graphql";

import { usePosRoverCtx } from "../../hooks/usePosRoverCtx";
import { useTranslator } from "../../../../shared/react/hooks";
import { normalize } from "../../../../shared/services/helper";

interface DevicesCollapseProps {
  active?: Boolean;
  devices: DevicesQuery["devices"];
  groupActions?: (variables?: any) => any;
  listItem?: (item?: any) => any;
  title: ReactNode | string;
}

const DevicesCollapse = ({
  active = false,
  devices,
  groupActions,
  listItem,
  title,
}: DevicesCollapseProps) => {
  const {
    devicesQuery: { loadingDevices },
  } = usePosRoverCtx();

  const t = useTranslator();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [search, setSearch] = useState<string>("");

  const filteredDevices = useMemo(() => {
    let filtered = devices;

    if (search) {
      const normalizedSearch = normalize(search);
      const searchItem = (item) =>
        normalize(String(item)).includes(normalizedSearch);

      filtered = devices?.filter((device) => {
        return (
          searchItem(device?.currentSession?.agent.name) ||
          searchItem(device?.shortCode) ||
          searchItem(device?.name) ||
          searchItem(device?.make) ||
          searchItem(device?.model) ||
          searchItem(device?.networkCarrierName) ||
          searchItem(device?.imei) ||
          searchItem(device?.serial) ||
          searchItem(device?.currentProvision?.citifydLot.name)
        );
      });
    }

    return filtered;
  }, [devices, search]);

  return (
    <Grid.Row marginBottom>
      <Grid.Col xs={12}>
        <SectionCollapse isOpen={isOpen} onToggle={(value) => setIsOpen(value)}>
          <SectionCollapse.Header title={title} />
          <SectionCollapse.HeaderActions>
            <Grid.Visible md lg xl xxxl>
              <SearchInput
                labelSize="small"
                value={search}
                placeholder="search"
                onClear={() => setSearch("")}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Grid.Visible>
          </SectionCollapse.HeaderActions>

          <SectionCollapse.Content>
            {loadingDevices && <LoadingIndicator />}

            {!loadingDevices && filteredDevices?.length === 0 && (
              <Text textAlign="center" variant="body">
                {t("posRover.devices.noDevicesFound")}
              </Text>
            )}
            <>
              <Grid.Visible xs sm>
                <SearchInput
                  gutterBottom
                  labelSize="small"
                  value={search}
                  placeholder="search"
                  onClear={() => setSearch("")}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </Grid.Visible>
              {filteredDevices && filteredDevices.length > 0 && !active && (
                <Grid.Row>
                  {filteredDevices?.map((device, index) => (
                    <Grid.Col xs={12} sm={6} key={index}>
                      <Card
                        device={device as DeviceQuery["device"]}
                        groupActions={groupActions}
                        listItem={listItem}
                      />
                    </Grid.Col>
                  ))}
                </Grid.Row>
              )}
              {filteredDevices && filteredDevices.length > 0 && active && (
                <Grid.Row>
                  {filteredDevices?.map((item, index) => (
                    <Grid.Col xs={12} sm={6}>
                      <ActiveCard
                        device={item as DeviceQuery["device"]}
                        key={index}
                      />
                    </Grid.Col>
                  ))}
                </Grid.Row>
              )}
            </>
          </SectionCollapse.Content>
        </SectionCollapse>
      </Grid.Col>
    </Grid.Row>
  );
};

export default DevicesCollapse;
