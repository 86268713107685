"use strict";

import axios from "axios";
import moment from "moment-timezone";
import jstz from "jstz";

import { getService } from "../react/utils";
import { getCurrentLanguage, getTranslator } from "../services/languages";

import {
  deburr,
  flatten,
  indexBy,
  isFunction,
  isString,
  get,
  map,
  mapValues,
  sortBy,
} from "lodash";

// e.g.
// getTranslatedDayName('Monday') returns 'Segunda-feira'
// getTranslatedDayName('Mon') returns 'Seg'
// getTranslatedDayName('Mon', 'dddd') returns 'Segunda-feira'
// when the selected i18next language is pt-BR
export const getTranslatedDayName = (day, format) => {
  if (!format) {
    format = day.length === 3 ? "ddd" : "dddd";
  }
  return moment()
    .locale("en")
    .day(day)
    .locale(getCurrentLanguage())
    .format(format);
};

export const confirmPageUnload = (okCallback, cancelCallback) => {
  let confirmationFunction = window.onbeforeunload || function () {};
  let confirmationMessage = confirmationFunction();

  if (confirmationMessage) {
    if (window.confirm(confirmationMessage)) {
      if (isFunction(okCallback)) {
        okCallback(true);
      }
    } else if (isFunction(cancelCallback)) {
      cancelCallback();
    }
  } else if (isFunction(okCallback)) {
    okCallback(false);
  }
};

export const setPageUnloadConfirmation = (func) => {
  const $rootScope = getService("$rootScope");
  let previousUnloadHandler = window.onbeforeunload;

  window.onbeforeunload = func;

  $rootScope.$on("$stateChangeSuccess", () => {
    window.onbeforeunload = previousUnloadHandler;
  });
};

export const formatDuration = (duration) => {
  const t = getTranslator();
  duration = moment.duration(duration, "minutes");

  const days = duration.get("days");
  const hours = duration.get("hours") + duration.get("days") * 24;
  const minutes = duration.get("minutes");

  let str;
  if (days === 1) {
    str = t("timeDuration.day", {
      days: days,
    });
  } else if (days > 1) {
    str = t("timeDuration.day_plural", {
      days: days,
    });
  } else if (hours >= 1) {
    str = t("timeDuration.hoursAndMinutes", {
      hours: hours,
      minutes: minutes,
    });
  } else {
    str = t("timeDuration.minutes", {
      minutes: minutes,
    });
  }

  return str;
};

export const calculateReservationDuration = (startTime, endTime) => {
  const end = endTime ? moment(endTime) : moment();
  const duration = moment.duration(end.diff(startTime));

  return formatDuration(duration);
};

export const sendHiddenForm = (url, data, target) => {
  const form = document.createElement("form");
  form.style.display = "none";
  form.setAttribute("target", target);
  form.setAttribute("action", url);
  form.setAttribute("method", "post");

  for (const key in data) {
    const input = document.createElement("input");
    input.setAttribute("name", key);
    input.value = data[key];

    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();

  setTimeout(() => {
    document.body.removeChild(form);
  }, 100);
};

export const isNil = (value) => {
  return value === null || value === undefined;
};

export const scrollTo = (selectorOrElement, margin = -16) => {
  const element =
    typeof selectorOrElement === "string"
      ? document.querySelector(selectorOrElement)
      : selectorOrElement;

  if (!element) {
    return false;
  }

  const bodyRect = document.body.getBoundingClientRect();
  const elemRect = element.getBoundingClientRect();
  const offsetTop = elemRect.top - bodyRect.top;

  const top = offsetTop + margin;

  window.scroll({ top, left: 0, behavior: "smooth" });

  return true;
};

export const setPageTitle = (title, only) => {
  if (!title) {
    document.title = "Citifyd";
    return;
  }

  document.title = title + (only ? "" : " - Citifyd");
};

export const spacesArrayToObject = (spaces) => {
  const spacesByName = indexBy(spaces, "name");
  return mapValues(spacesByName, (space) => space.max);
};

export const spacesObjectToArray = (spaces) => {
  return map(spaces, (value, key) => {
    return { name: key, max: parseInt(value, 10) };
  });
};

// Generate an array with the day times from 30 to 30 minutes.
// Pass a string as argument to limit the start of the times array, e.g. by passing "14:30" (2:30pm)
// you'll only get values starting from "15:00" (3pm)
export const generateTimesArray = (startAfter) => {
  const t = getTranslator();
  const times = [];
  const date = moment().startOf("day");

  if (isString(startAfter)) {
    startAfter = parseInt(startAfter.replace(":", ""), 10);
  }

  for (let i = 0; i < 48; i++) {
    const number = parseInt(date.locale("en").format("HHmm"), 10);

    if (!startAfter || number > startAfter) {
      times.push({
        time: date.locale("en").format("HH:mm"),
        label: t("timeSelector.displayFormat", { time: date }),
        number: number,
      });
    }

    date.add(30, "minutes");
  }

  return times;
};

export const timeStringToObject = (str) => {
  const time = str.split(":");
  const hours = parseInt(time[0], 10);
  const minutes = parseInt(time[1], 10);

  return {
    hours: hours,
    minutes: minutes,
    minutesOfDay: 60 * hours + minutes,
  };
};

export const dateAndTimeToMomentObject = (date, time, timezoneName) => {
  const timeObj = isString(time) ? timeStringToObject(time) : time;
  const momentObj = timezoneName
    ? moment.tz(moment(date).locale("en").format("YYYY-MM-DD"), timezoneName)
    : moment(date);

  momentObj.set("hours", timeObj.hours);
  momentObj.set("minutes", timeObj.minutes);

  return momentObj;
};

// Get the local timezone name. Example: "America/Los_Angeles"
export const getLocalTimezoneName = () => {
  return jstz.determine().name();
};

// Check if the arguments happen on the same date.
// You can pass a string, a Date object or a moment object.
export const isSameDate = (d1, d2, timezoneName) => {
  const m1 = timezoneName ? moment.tz(d1, timezoneName) : moment(d1);
  const m2 = timezoneName ? moment.tz(d2, timezoneName) : moment(d2);

  return (
    m1.locale("en").format("YYYY-MM-DD") ===
    m2.locale("en").format("YYYY-MM-DD")
  );
};

// Shows a browser alert, waiting the time for the loading spinner to hide if
// it's the case
export const showAlert = (message) => {
  setTimeout(() => {
    window.alert(message);
  }, 50);
};

// Extract error message from the server
export const getServerErrorMessage = (response) => {
  const t = getTranslator();
  let message = get(response, "error.message");

  if (!message) {
    message = t("commonErrors.connectionProblem");
  }

  return message;
};

// Shows an error message coming from the server
export const showErrorAlert = (response) => {
  showAlert(getServerErrorMessage(response));
};

export const timeToObject = (time) => {
  if (!isString(time)) {
    return;
  }
  const m = moment(time, "HH:mm:ss", true).locale("en");

  if (!m.isValid()) {
    return;
  }

  return {
    hour: m.get("hour"),
    minute: m.get("minute"),
    second: m.get("second"),
  };
};

export const calculateRateDifference = (a, b) => {
  return a.value + a.fee - (b.value + b.fee);
};

export const planifyCountries = (countriesByContinent) => {
  const countries = countriesByContinent.map((continent) => {
    continent.countries.forEach((country) => {
      country.continent = continent.name;
    });

    return continent.countries;
  });

  return sortBy(flatten(countries), (country) => country.name);
};

export const groupCloseItemsBy = (list, groupConditionFn, groupObjectFn) => {
  const newList = [];
  let currentGroup = null;

  const addCurrentGroupToList = () => {
    if (currentGroup) {
      newList.push(groupObjectFn(currentGroup));
      currentGroup = null;
    }
  };

  for (const item of list) {
    if (groupConditionFn(item)) {
      if (!currentGroup) {
        currentGroup = [];
      }

      currentGroup.push(item);
    } else {
      addCurrentGroupToList();
      newList.push(item);
    }
  }

  addCurrentGroupToList();
  return newList;
};

export const milesToKilometers = (miles) => {
  return miles / 0.62137;
};

export const uploadImage = async (
  formIdOrElement,
  uploadPresetSuffix,
  abortController
) => {
  const {
    REACT_APP_CLOUDINARY_UPLOAD_PRESET_PREFIX: CLOUDINARY_UPLOAD_PRESET_PREFIX,
  } = process.env;
  const formElement =
    formIdOrElement instanceof Element ||
    formIdOrElement instanceof HTMLDocument
      ? formIdOrElement
      : document.getElementById(formIdOrElement);

  if (!formElement) {
    throw new Error(`Could not find form with id ${formId} for image upload.`);
  }

  // Form passed as reference must have file-type input with name "file" inside.
  const formData = new window.FormData(formElement);
  formData.append(
    "upload_preset",
    `${CLOUDINARY_UPLOAD_PRESET_PREFIX}-${uploadPresetSuffix}`
  );

  const cloudName = "citifyd";

  const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

  const response = await axios({
    method: "post",
    url: uploadUrl,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
    signal: abortController?.signal,
  });

  return {
    cloudName,
    format: response.data["format"],
    publicId: response.data["public_id"],
    url: response.data["secure_url"],
  };
};

export const extractImageUrl = (image) => {
  if (image.mode === "url") {
    return image.url;
  }

  if (image.mode === "cloudinary") {
    const { cloudName, defaultTransformation, publicId } = image.cloudinary;
    return `https://res.cloudinary.com/${cloudName}/image/upload/${defaultTransformation}/${publicId}.jpg`;
  }

  throw new Error(`Unexpected image mode ${mode}`);
};

export const generateStripeCustomerUrl = (stripeCustomerId) => {
  const { REACT_APP_ENV: ENV } = process.env;
  const name = ENV;
  const parts = [
    "https://dashboard.stripe.com",
    name !== "production" ? "test" : null,
    "customers",
    stripeCustomerId,
  ];
  return parts.filter((p) => p).join("/");
};

export const normalize = (str) => deburr(str).toLowerCase();
