"use strict";

import React, { useEffect, useState } from "react";
import { Grid } from "@citifyd/style";

import PageHeader from "../../shared/react/components/PageHeader";
import { useService, useTranslator } from "../../shared/react/hooks";
import Permissions from "../../shared/services/permissions";
import LoadingManager from "../../shared/react/components/LoadingManager";
import UserPasses from "./components/UserPasses";
import UserSummary from "./components/UserSummary";
import { getUser } from "./utils";
import {
  getMigrationTickets,
  getNonCustomerUserData,
  getUserData,
} from "../../shared/api";
import { setPageTitle } from "../../shared/services/helper";
import { useAuthentication } from "../../shared/react/contexts/authentication";

import ChangeLogs from "./components/ChangeLogs";

const User = () => {
  const t = useTranslator();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [nonCustomer, setNonCustomer] = useState();
  const $stateParams = useService("$stateParams");
  const $state = useService("$state");
  const { user: loggedUser, permissions } = useAuthentication();
  const { isAdmin } = loggedUser;
  const [migrationTickets, setMigrationTickets] = useState();
  const [migrationTicketsLoadError, setMigrationTicketsLoadError] = useState();
  const canSeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  const { id: userId, phoneCountryCode, phoneNumber } = $stateParams;

  const fetchMigrationTickets = async (user) => {
    try {
      const tickets = await getMigrationTickets({
        params: {
          email: user.email,
          phoneNumber: user.phoneNumber,
          userId,
        },
      });

      setMigrationTickets(tickets);
    } catch (err) {
      setMigrationTicketsLoadError(true);
    }
  };

  const loadUser = async () => {
    setIsLoading(true);

    let userPromise, nonCustomer;

    if (phoneCountryCode && phoneNumber) {
      nonCustomer = true;
      userPromise = getNonCustomerUserData(
        userId,
        phoneCountryCode,
        phoneNumber
      );
    } else {
      nonCustomer = false;
      userPromise = getUserData(userId);
    }

    setNonCustomer(nonCustomer);

    try {
      const response = await userPromise;

      // makes sure user is accessing the full-featured page if the user is customer
      if (nonCustomer && response.isCustomer) {
        $state.go("user", { id: response.id }, { location: "replace" });
        return;
      }

      const user = getUser(response);

      setPageTitle(
        t("user.pageTitleWithName", {
          name: user.isGuest ? t("user.guestBadge") : user.name,
        })
      );

      // load migration passes information (admin only)
      if (isAdmin && !user.isGuest) {
        await fetchMigrationTickets(user);
      }

      setUser(user);
    } catch (err) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  if (isLoading) {
    return <LoadingManager isLoading={isLoading} />;
  }

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          defaultState={canSeeUserInformation ? "users" : "dashboard"}
          weight="300"
        />
      </PageHeader>
      <UserSummary
        user={user}
        nonCustomer={nonCustomer}
        loadUser={loadUser}
        migrationTickets={migrationTickets}
        migrationTicketsLoadError={migrationTicketsLoadError}
      />
      <UserPasses partialUser={user} nonCustomer={nonCustomer} />
      <ChangeLogs userId={user.id} />
    </Grid>
  );
};

export default User;
