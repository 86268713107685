import { uniq } from "lodash";

const languageSelectionRequired = ({
  forceLanguageSelection,
  lot,
  purchaser,
  loggedUser,
}) => {
  if (forceLanguageSelection) {
    return true;
  }

  const languages = [
    purchaser?.language,
    lot.country?.language,
    loggedUser.language,
  ];

  const allLanguagesAreEqual = uniq(languages).length === 1;
  return !allLanguagesAreEqual;
};

export default languageSelectionRequired;
