"use strict";

import React, { useState, useEffect, useMemo } from "react";
import { Label, Select, Grid, DatePicker } from "@citifyd/style";
import { find } from "lodash";
import moment from "moment-timezone";

import { useService, useTranslator } from "../../../../hooks";
import { getFilterSelectionFromState } from "../../utils";
import styles from "./RevenueFilter.module.scss";
import { selectionToParams, getLotsOptions } from "./utils";
import CSVDownload from "../../../CSVDownload";
import { getCurrentLanguage } from "../../../../../services/languages";
import { getService } from "../../../../../../shared/react/utils";

import { useAuthentication } from "../../../../../../shared/react/contexts/authentication";

import {
  getFormattedDate,
  getFormattedTime,
  getTimes,
  onTimeChange,
} from "../../../../../utils/date-time-formatting";
import { onDateChange } from "../../../../../../pages/reports/utils";
import Permissions from "../../../../../services/permissions";

const MAX_RANGE_ALLOWED_IN_MONTHS = 6;

const RevenueFilter = ({
  enableLotSelection,
  enableDateRangeSelection = true,
  lots = [],
  currencies = [],
  onClickGenerateCsv,
  organizationsOptions,
  isEvent = false,
}) => {
  const t = useTranslator();
  const { user, permissions } = useAuthentication();

  const $state = useService("$state");

  const { isAdmin } = user;

  const [selection, setSelection] = useState({});
  const [errors, setErrors] = useState({});
  const language = getCurrentLanguage();

  useEffect(() => {
    const $state = getService("$state");

    const params = getFilterSelectionFromState($state, currencies);

    setSelection(params);
    validate(params);
  }, []);

  const updateFilters = (newSelection) => {
    if (!validate(newSelection)) {
      return false;
    }

    $state.transitionTo(
      $state.current.name,
      {
        ...$state.params,
        ...selectionToParams(newSelection, enableDateRangeSelection, isEvent),
      },
      {
        location: "replace",
        notify: false,
        inherit: false,
      }
    );
  };

  const fieldChanged = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    let newSelection = {
      ...selection,
      [name]: value,
    };

    if (name === "lotId") {
      const lot = find(lots, (lot) => lot.id === value);

      if (lot) {
        newSelection = {
          ...newSelection,
          currency: lot.country.currency,
          timezoneName: lot.timezoneName,
        };
      }
    }

    setSelection(newSelection);
    updateFilters(newSelection);
  };

  const validate = (newSelection) => {
    if (enableDateRangeSelection && newSelection.dateRange) {
      const { dateRange } = newSelection;
      const startDate = moment(dateRange.startDate);
      const endDate = moment(dateRange.endDate);
      let valid = true;

      if (startDate.isAfter(endDate)) {
        setErrors((old) => ({
          ...old,
          ["dateRange.endDate"]: t(
            "revenue.revenueFilter.errors.endDate.mustBeAfterStartDate"
          ),
        }));
        valid = false;
      }

      if (endDate.diff(startDate, "months") > MAX_RANGE_ALLOWED_IN_MONTHS) {
        setErrors((old) => ({
          ...old,
          ["dateRange.endDate"]: t(
            "revenue.revenueFilter.errors.endDate.rangeLimitReached",
            {
              maxMonths: MAX_RANGE_ALLOWED_IN_MONTHS,
            }
          ),
        }));
        valid = false;
      }

      if (valid) {
        setErrors({});
      }

      return valid;
    }
  };

  const setDateRange = (dateRange) => {
    const newSelection = { ...selection, dateRange };
    setSelection(newSelection);
    updateFilters(newSelection);
  };

  const lotsOptions = useMemo(
    () =>
      getLotsOptions({
        lots,
        t,
        organizationId: selection.organizationId,
      }),
    [lots, selection.organizationId]
  );

  const generateCsvClicked = () => {
    if (!!validate(selection) && onClickGenerateCsv) {
      onClickGenerateCsv();
    }
  };

  return (
    <>
      <Grid.Row marginBottom>
        {Permissions.userMaySeeParkingOperatorFilter(permissions) && (
          <Grid.Col sm={3}>
            <Select
              fullWidth
              labelSize="small"
              name="organizationId"
              label={t("reports.commonFilter.fields.parkingOperator")}
              options={organizationsOptions}
              value={selection.organizationId ?? "*"}
              onChange={(e) => fieldChanged(e)}
            />
          </Grid.Col>
        )}

        {enableLotSelection && (
          <Grid.Col sm={3}>
            <Select
              fullWidth
              name="lotId"
              labelSize="small"
              label={t("revenue.revenueFilter.fields.property")}
              options={lotsOptions}
              defaultValue=""
              value={parseInt(selection.lotId) || ""}
              onChange={(e) => fieldChanged(e)}
              maxMenuHeight={300}
            />
          </Grid.Col>
        )}
        {enableDateRangeSelection && (
          <Grid.Col sm={5}>
            <Grid.Row>
              <Grid.Col>
                <Grid.Row>
                  <Grid.Col sm={12}>
                    <Label variant="small">
                      {t("revenue.revenueFilter.fields.startDate")}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row gutterWidth={4}>
                  <Grid.Col>
                    <DatePicker
                      locale={language}
                      onChange={(date) => {
                        setDateRange(
                          onDateChange({
                            field: "startDate",
                            date,
                            selection: selection.dateRange,
                          })
                        );
                      }}
                      selectedDate={getFormattedDate(
                        selection.dateRange?.startDate
                      )}
                      error={errors["dateRange.startDate"]}
                      errorMessage={t(errors["dateRange.startDate"])}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
              <Grid.Col>
                <Grid.Row>
                  <Grid.Col sm={12}>
                    <Label variant="small">
                      {t("revenue.revenueFilter.fields.endDate")}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row gutterWidth={3}>
                  <Grid.Col>
                    <DatePicker
                      locale={language}
                      onChange={(date) => {
                        setDateRange(
                          onDateChange({
                            field: "endDate",
                            date,
                            selection: selection.dateRange,
                          })
                        );
                      }}
                      selectedDate={getFormattedDate(
                        selection.dateRange?.endDate
                      )}
                      error={errors["dateRange.endDate"]}
                      errorMessage={t(errors["dateRange.endDate"])}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        )}
        <Grid.Col
          sm={user?.isAdmin ? 1 : 4}
          className={styles.boxCSV}
          alignItems="flex-end"
        >
          <CSVDownload
            // disabled={errors['dateRange.end'] || data.length === 0}
            onClick={generateCsvClicked}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row marginBottom>
        {isAdmin && $state.params.reportType === "daily" && (
          <Grid.Col sm={3}>
            <Select
              fullWidth
              name="currency"
              labelSize="small"
              label={t("revenue.revenueFilter.fields.currency")}
              options={currencies}
              value={selection.currency || ""}
              onChange={(e) => fieldChanged(e)}
            />
          </Grid.Col>
        )}
      </Grid.Row>
    </>
  );
};

export default RevenueFilter;
