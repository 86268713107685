import gql from "graphql-tag";

export default gql`
  query AdminTransactionsReport(
    $lotIds: [Int!]!
    $startTime: DateTime!
    $endTime: DateTime!
    $last4: String
    $license: String
    $similarSearch: Boolean!
    $parkingMethod: TransactionsReportParkingMethods
    $after: String
    $before: String
    $first: Int
    $last: Int
    $phoneNumber: String
    $organizationId: Int
    $parkingSessionId: Int
  ) {
    admin_transactionsReport(
      lotIds: $lotIds
      startTime: $startTime
      endTime: $endTime
      last4: $last4
      license: $license
      similarSearch: $similarSearch
      parkingMethod: $parkingMethod
      after: $after
      before: $before
      first: $first
      last: $last
      phoneNumber: $phoneNumber
      organizationId: $organizationId
      parkingSessionId: $parkingSessionId
    ) {
      total
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          startTime
          endTime
          initialRate
          rate
          amount
          license
          lotName
          parkerName
          phoneNumber
          phoneCountryCode
          parkingSessionId
          email
          currency
          parkingMethod
          stripeAmount
          state
          createdAt
          citifydAmount
          citifydServiceFeeApplied
          subsidiesPaid
          externalAmount
          timezoneName
        }
      }
    }
  }
`;
