"use strict";

import React from "react";
import { isEmpty } from "lodash";
import { Select } from "@citifyd/style";
import { useService, useTranslator } from "../../../../../shared/react/hooks";
import { showStat } from "../utils";

import styles from "./StatusSelector.module.scss";

const StatusFilter = ({
  stats,
  disabled,
  selectedStatus,
  setSelectedStatus,
}) => {
  const t = useTranslator();
  const $state = useService("$state");

  const onChange = (e) => {
    setSelectedStatus(e.target?.value);
    $state.transitionTo(
      $state.current.name,
      {
        ...$state.params,
        status: e.target?.value,
      },
      { location: "replace", notify: false, inherit: false }
    );
  };

  const options = !isEmpty(stats)
    ? [
        {
          label: `${showStat(stats.sold)} ${t("event.sold")}`,
          value: "sold",
        },
        {
          label: `${showStat(stats.parked)} ${t("event.parked")}`,
          value: "parked",
        },
        {
          label: `${showStat(stats.transferred)} ${t("event.transfers")}`,
          value: "transferred",
        },
        {
          label: `${showStat(stats.cancelled)} ${t("event.cancellations")}`,
          value: "cancelled",
        },
      ]
    : [];

  return (
    <Select
      className={styles.select}
      onChange={onChange}
      value={selectedStatus}
      disabled={disabled}
      fullWidth
      options={options}
    />
  );
};

export default StatusFilter;
