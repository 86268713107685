import gql from "graphql-tag";

export default gql`
  query AdminListPermitPasses(
    $status: PermitPassStatus
    $startDate: Date!
    $endDate: Date!
    $searchTerm: String
    $first: Int
    $after: String
    $sortBy: PermitPassesOrder!
    $sortDirection: OrderDirection!
  ) {
    admin_listPermitPasses(
      status: $status
      startDate: $startDate
      endDate: $endDate
      searchTerm: $searchTerm
      first: $first
      after: $after
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) @connection(key: "adminListPermitPasses") {
      total
      edges {
        node {
          __typename
          id
          user {
            id
            name
          }
          requester {
            name
          }
          lot {
            id
            name
            timezoneName
          }
          vehicle {
            id
            label
            model
            license
          }
          phoneNumber
          phoneCountryCode
          isActive
          status
          startTime
          endTime
          activatedAt
          createdAt
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
