import { getMeasurementSystemFromCountryCode } from "../../../../shared/services/settings";

export const generateMaxDistanceOptions = ({ t, user }) => {
  const measurementSystem = getMeasurementSystemFromCountryCode(
    user.phoneCountryCode
  );

  switch (measurementSystem) {
    case "metric":
      return [
        {
          miles: 1,
          label: t("appendableEvents.kilometers", { count: 2 }),
        },
        {
          miles: 6,
          label: t("appendableEvents.kilometers", { count: 10 }),
        },
      ];

    case "imperial":
      return [
        {
          miles: 1,
          label: t("appendableEvents.miles", { count: 1 }),
        },
        {
          miles: 5,
          label: t("appendableEvents.miles", { count: 5 }),
        },
      ];
  }
};
