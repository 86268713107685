"use strict";

import React from "react";

import ReservationTransactionBox from "./ReservationTransactionBox.jsx";
import FeeBreakdownBox from "./FeeBreakdownBox.jsx";
import CancellationBox from "./CancellationBox.jsx";

export default function ReservationStatusBadge({ reservation }) {
  return (
    <div>
      <ReservationTransactionBox reservation={reservation} />
      <FeeBreakdownBox reservation={reservation} />
      {reservation.cancellation && (
        <CancellationBox reservation={reservation} />
      )}
    </div>
  );
}
