import React, { useMemo } from "react";

import {
  Button,
  Icon,
  Label,
  LoadingIndicator,
  Grid,
  Text,
} from "@citifyd/style";

import FormikProvider from "../../../shared/react/components/FormikProvider";
import FormikField, {
  FIELD_TYPE,
} from "../../../shared/react/components/FormikField";
import PhoneField from "../../../shared/react/components/PhoneField";
import RouterLink from "../../../shared/react/components/RouterLink";
import StickyFooter from "../../../shared/react/components/StickyFooter";
import useLoadTracking from "../../../shared/react/hooks/useLoadTracking";

import { generateTimesArray } from "../../../shared/services/helper";

import useEditUser from "./useEditUser";
import { useTranslator } from "../../../shared/react/hooks";

import styles from "./Body.module.scss";

const Body = ({
  apolloClient,
  countries,
  isEditing,
  userData,
  permitPortals,
}) => {
  const t = useTranslator();

  const { isLoading, trackLoading, untrackLoading } = useLoadTracking({});

  const timeOptions = useMemo(
    () =>
      generateTimesArray().map((time) => ({
        value: time.time,
        label: time.label,
      })),
    []
  );

  const formikbag = useEditUser({
    userData,
    apolloClient,
    trackLoading,
    untrackLoading,
    isEditing,
    permitPortals,
  });

  const { handleSubmit } = formikbag;

  if (isLoading) {
    return <LoadingIndicator isLoading={true} />;
  }
  return (
    <FormikProvider value={formikbag}>
      <Grid>
        <Grid.Row>
          <Grid.Col>
            <FormikField
              as={FIELD_TYPE.INPUT}
              name="name"
              label={t("permits.permitters.name")}
              gutterBottom
            />
          </Grid.Col>
          <Grid.Col>
            <PhoneField
              countries={countries}
              countryCodeDefault={formikbag.values.countryCode}
              gutterBottom
              label={t("permits.permitters.phone")}
            />
          </Grid.Col>
          <Grid.Col>
            <FormikField
              as={FIELD_TYPE.INPUT}
              name="identifier"
              gutterBottom
              label={t("permits.permitters.identifier")}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={4}>
            <FormikField
              label={t("permits.permitters.permitPortal")}
              as={FIELD_TYPE.MULTI_SELECT}
              options={permitPortals}
              name="permitPortal"
            />
          </Grid.Col>

          <Grid.Col sm={4}>
            <Grid.Row>
              <Grid.Col sm={8}>
                <Label> {t("permits.permitters.accessEndDate")}</Label>
                <div className={styles.dateTime}>
                  <FormikField
                    as={FIELD_TYPE.DATEPICKER}
                    minDate={isEditing ? "" : new Date()}
                    disabled={formikbag.values.neverExpiresDate}
                    name="date"
                    shouldResetTime
                    requireTouchForError={false}
                  />
                  <div>
                    <FormikField
                      as={FIELD_TYPE.SELECT}
                      options={timeOptions}
                      disabled={formikbag.values.neverExpiresDate}
                      name="time"
                      hasError={Boolean(formikbag.errors.date)}
                    />
                  </div>
                </div>
              </Grid.Col>
              <Grid.Col sm={4}>
                <div className={styles.checkbox}>
                  <FormikField
                    name="neverExpiresDate"
                    label={t("permits.permitters.never")}
                    as={FIELD_TYPE.CHECKBOX}
                    fullWidth
                  />
                </div>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid>

      <StickyFooter justifyContent="space-between">
        <RouterLink state={"permitters"} className={styles.backLink}>
          <Icon size="subtitle" icon="chevron-left" appearance="white" />
          <Text variant="subtitle" appearance="white">
            {t("commonButtons.goBackWithoutSaving")}
          </Text>
        </RouterLink>
        <Button uppercase onMouseDown={handleSubmit} extraPadding>
          {t("permits.form.save")}
        </Button>
      </StickyFooter>
    </FormikProvider>
  );
};

export default Body;
