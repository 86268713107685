"use strict";

import React from "react";

import { Icon, Text, ToggleButton, Tooltip } from "@citifyd/style";
import { useTranslator } from "../../../../../shared/react/hooks";
import styles from "./DynamicPricing.module.scss";
import BoxContent from "../../../../../shared/react/components/BoxContent";
import classNames from "classnames";

const BoxHeader = ({ title, isOpened, tooltip, selected, handleChange }) => {
  const t = useTranslator();

  return (
    <BoxContent.Header
      className={classNames(
        styles.boxContent,
        isOpened ? styles.noBorderRadius : styles.boxContentBorder
      )}
      appearance="gray_dark"
      title={
        <div className={styles.text}>
          <Text appearance="white" variant="subtitle">
            {title}
          </Text>
          <Tooltip
            id={tooltip}
            label={
              <Icon
                icon="question-circle"
                appearance="white"
                size="h3"
                className={styles.icon}
              />
            }
          >
            <div className={styles.tooltip}>
              <Text appearance="white">{tooltip}</Text>
            </div>
          </Tooltip>
        </div>
      }
      small
    >
      <BoxContent.HeaderActions>
        <div className={styles.headerTitle}>
          <Text
            variant="subtitle"
            className={styles.toggleButton}
            appearance="white"
          >
            {selected
              ? t("commonButtons.enabled")
              : t("commonButtons.disabled")}
          </Text>
          <ToggleButton selected={selected} light onChange={handleChange} />
        </div>
      </BoxContent.HeaderActions>
    </BoxContent.Header>
  );
};

export default BoxHeader;
