"use strict";

import CurrencyFormatter from "@citifyd/currency-formatter";
import {
  getTranslator,
  getCurrentLanguage,
} from "../../../shared/services/languages";
import { transformToInteger } from "../../../shared/services/currencies";
import {
  formatDuration,
  getTranslatedDayName,
} from "../../../shared/services/helper";

const moment = require("moment-timezone");

export function formatPrice(price, currency) {
  if (!price) return null;

  const language = getCurrentLanguage();

  const priceAsInteger = transformToInteger(price, currency);
  return CurrencyFormatter.format(priceAsInteger, {
    currency: currency,
    language,
  });
}

export function formatRuleDaysOfWeek(days) {
  return ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
    .filter((day) => days[day])
    .map((day) => getTranslatedDayName(day))
    .join(", ");
}

export function formatRuleEndType(endType) {
  const t = getTranslator();
  return t(`advancedRateEditor.fields.end.options.${endType}.label`);
}

export function formatRuleEnd(ruleEnd) {
  if (!ruleEnd) return null;

  const t = getTranslator();

  if (ruleEnd.type === "duration") {
    const duration =
      Number(ruleEnd.duration.hours) * 60 + Number(ruleEnd.duration.minutes);
    return formatDuration(duration);
  } else if (ruleEnd.type === "time") {
    const timeMoment = moment(ruleEnd.time.time, "HH:mm");
    if (ruleEnd.time.days === 1) {
      return t("advancedRateEditor.fields.end.options.time.view.nextDay", {
        time: timeMoment,
      });
    } else if (ruleEnd.time.days > 1) {
      return t("advancedRateEditor.fields.end.options.time.view.daysLater", {
        time: timeMoment,
        days: ruleEnd.time.days,
      });
    } else {
      return t("advancedRateEditor.fields.end.options.time.view.sameDay", {
        time: timeMoment,
      });
    }
  } else {
    throw new Error(`Unknown rule end type '${ruleEnd.type}'`);
  }
}

export function formatTimeRange(time) {
  const startMoment = moment(time.start, "HH:mm");
  const endMoment = moment(time.end, "HH:mm");

  const t = getTranslator();
  const start = t("timeSelector.displayFormat", { time: startMoment });
  const end = t("timeSelector.displayFormat", { time: endMoment });

  return `${start} - ${end}`;
}
