import gql from "graphql-tag";

export default gql`
  query AdminCalculatePermitPassPrice(
    $lotId: Int!
    $startTime: DateTime!
    $endTime: DateTime
  ) {
    admin_calculatePermitPassPrice(
      lotId: $lotId
      startTime: $startTime
      endTime: $endTime
    ) {
      value
      fee
      total
    }
  }
`;
