import { getCountries, getCountry } from "../../../api";
import { planifyCountries } from "../../../services/helper";

export const loadCountries = async () => {
  const countries = await getCountries();

  return planifyCountries(countries).map((country) => ({
    label: country.name,
    value: country.isoCode,
  }));
};

export const loadSelectedCountryData = async (
  countryCode,
  trackLoading,
  untrackLoading,
  formikbag
) => {
  trackLoading("loadSelectedCountryData");
  try {
    const data = await getCountry(countryCode);
    formikbag.setFieldValue("selectedCountryData", data);
  } catch (err) {
    console.log("err", err);
  } finally {
    untrackLoading("loadSelectedCountryData");
  }
};

export const countryPostalCodeProperty = (countryData, propertyName) => {
  return (
    countryData?.schemas?.address?.properties?.postalCode[propertyName] ?? null
  );
};

export const COMPLETE_CARD_CLASS_NAME = "citifyd-stripe-card-complete";

// Checks if the card field from Stripe is complete imperatively, when the user
// hasn't yet typed anything on the field.
// Based on the solution from hofman-stripe at:
// https://github.com/stripe-archive/react-stripe-elements/issues/283#issuecomment-772659591
export const isStripeCardComplete = (cardRef) => {
  return (
    cardRef.current.getElementsByClassName(COMPLETE_CARD_CLASS_NAME).length > 0
  );
};
