import { checkPhoneNumber, sendPhoneNumberVerification } from "../../../../api";
import { getTranslator } from "../../../../services/languages";

const checkNumberAndSendVerification = async (values) => {
  const t = getTranslator();

  try {
    const response = await checkPhoneNumber({
      phoneCountryCode: values.phoneCountryCode,
      phoneNumber: values.phoneNumber,
      checkOtherUsersOnly: true,
    });

    if (response.userExists) {
      const message = t("phoneVerification.phoneNumberAlreadyTaken");
      const error = new Error(message);
      error.data = { error: { message: message } };
      throw error;
    }

    await sendPhoneNumberVerification({
      phoneCountryCode: values.phoneCountryCode,
      phoneNumber: values.phoneNumber,
      method: "textMessage",
    });

    return {
      status: "sent_code",
    };
  } catch (err) {
    return {
      status: "error",
      reason: err,
    };
  }
};

export default checkNumberAndSendVerification;
