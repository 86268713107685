"use strict";

import React, { useEffect, useRef, useState } from "react";
import { useFormik, FormikProvider } from "formik";
import { isEmpty } from "lodash";
import classNames from "classnames";
import { Button, Icon, Text } from "@citifyd/style";
import * as yup from "yup";

import Form from "./Form.jsx";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import RouterLink from "../../../../shared/react/components/RouterLink";
import StickyFooter from "../../../../shared/react/components/StickyFooter";

import { useTranslator } from "../../../../shared/react/hooks";
import useLoadTracking from "../../../../shared/react/hooks/useLoadTracking";

import {
  getAddressValidation,
  getDefaultContryCodeForNewLot,
  onCreateLot,
  onUpdateLot,
  formatOpeningHoursToBackend,
  transformAdditionalPhotosToBackend,
  transformAmenitiesToBackend,
} from "./utils";
import { getInitialValues } from "./getInitialValues";
import styles from "./Basics.module.scss";
import { showErrorAlert } from "../../../../shared/services/helper";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";
import BoxContent from "../../../../shared/react/components/BoxContent";
import { useAddressSchema } from "./useAddressSchema.js";
import { goToNextStep } from "../goToNextStep.js";

const Basics = ({ amenities, lot, mode, onUpdate }) => {
  const t = useTranslator();
  const { reloadUser, user } = useAuthentication();

  const [countryCode, setCountryCode] = useState(lot?.countryCode);
  const [hasError, setHasError] = useState(false);

  const { isLoading, loadingMessage, trackLoading, untrackLoading } =
    useLoadTracking({
      uploadImage: { message: t("addProperty.loadingMessages.uploadingPhoto") },
      createLot: {
        message: t("addProperty.loadingMessages.creatingProperty"),
      },
      updateLot: {
        message: t("addProperty.loadingMessages.updatingProperty"),
      },
      countryList: {
        message: t("addProperty.loadingMessages.loadingSettings"),
      },
      addressSchema: {
        message: t("addProperty.loadingMessages.loadingSettings"),
      },
      defaultCountryCode: {
        message: t("addProperty.loadingMessages.loadingSettings"),
      },
    });

  const {
    addressSchema,
    availableCountries,
    loadAddressSchema,
    shouldShowCountryDropdown,
  } = useAddressSchema({ setHasError, trackLoading, untrackLoading });

  const loadDefaultCountryCode = async () => {
    trackLoading("defaultCountryCode");
    try {
      const response = await getDefaultContryCodeForNewLot(user);

      setCountryCode(response);
      loadAddressSchema(response);
    } catch (err) {
      setHasError(true);
    } finally {
      untrackLoading("defaultCountryCode");
    }
  };

  useEffect(() => {
    if (isEmpty(lot)) {
      loadDefaultCountryCode();
    } else {
      loadAddressSchema(lot.countryCode);
    }
  }, []);

  const refreshUser = () => {
    return reloadUser();
  };

  const onSubmit = async (values) => {
    for (let image in formikbag.values.images) {
      if (formikbag.values.images[image].uploading) {
        alert("Uploading image, wait for it to finish");
        return;
      }
    }

    try {
      let lotId;

      if (mode === "add") {
        trackLoading("createLot");

        const createdLot = await onCreateLot({
          ...values,
          notes: values?.importantNotes,
          about: values?.aboutProperty,
          countryCode: values?.countryCode || countryCode,
          openingHours: formatOpeningHoursToBackend(
            values.daysOfWeek,
            values.hours
          ),
          photo: values.images.main.image,
          additionalPhotos: transformAdditionalPhotosToBackend(values.images),
          amenityCodes: transformAmenitiesToBackend(values.amenities),
          extraInfo: { hasPhysicalGates: values.hasPhysicalGates },
          notifyAboutEvents: values.notifyEventsNearThisProperty,
          reservedParkingEnabled: values.reservedParking,
        });

        lotId = createdLot.id;

        untrackLoading("createLot");
      } else {
        trackLoading("updateLot");

        const updatedLot = await onUpdateLot({
          lot,
          newData: {
            ...values,
            notes: values?.importantNotes,
            about: values?.aboutProperty,
            longitude: values?.coordinates?.longitude,
            latitude: values?.coordinates?.latitude,
            openingHours: formatOpeningHoursToBackend(
              values.daysOfWeek,
              values.hours
            ),
            additionalPhotos: transformAdditionalPhotosToBackend(
              values?.images
            ),
            photo: values.images.main.image,
            amenityCodes: transformAmenitiesToBackend(values.amenities),
            extraInfo: { hasPhysicalGates: values.hasPhysicalGates },
            notifyAboutEvents: values.notifyEventsNearThisProperty,
            reservedParkingEnabled: values.reservedParking,
          },
        });

        onUpdate(updatedLot);

        lotId = lot.id;

        untrackLoading("updateLot");
      }

      goToNextStep({ lotId, step: 2 });

      refreshUser();
    } catch (response) {
      showErrorAlert(response.data);
    }
  };

  const formikbag = useFormik({
    initialValues: getInitialValues(lot, addressSchema, amenities),
    onSubmit,
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      ...(addressSchema
        ? {
            address: yup
              .object()
              .shape(getAddressValidation(addressSchema, yup)),
          }
        : null),
      maxSpots: yup.number().required(),

      images: yup.object().shape({
        main: yup.object().shape({ image: yup.object().required() }),
      }),
    }),
  });

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      {(isLoading || hasError) && (
        <LoadingManager
          isLoading={isLoading}
          hasError={hasError}
          loadingMessage={loadingMessage}
        />
      )}
      {/*Hide instead of removing the form while loading, otherwise
    the image selection on the file input is lost */}
      <div
        className={classNames({
          [styles.hideContent]:
            isLoading || (!availableCountries.length && !addressSchema),
        })}
      >
        <BoxContent>
          <BoxContent.Header title="Property setup" />

          <BoxContent.Content>
            <Form
              addressSchema={addressSchema}
              amenities={amenities}
              availableCountries={availableCountries}
              countryCode={countryCode}
              formikbag={formikbag}
              loadAddressSchema={loadAddressSchema}
              lot={lot}
              mode={mode}
              setCountryCode={setCountryCode}
              shouldShowCountryDropdown={shouldShowCountryDropdown}
            />
          </BoxContent.Content>
        </BoxContent>
        <StickyFooter isBlocked={false}>
          <RouterLink state="properties" className={styles.backLink}>
            <Icon size="subtitle" icon="chevron-left" appearance="white" />
            <Text variant="subtitle" appearance="white">
              {t("commonButtons.goBackWithoutSaving")}
            </Text>
          </RouterLink>

          <Button shadow extraPadding uppercase onMouseDown={handleSubmit}>
            {t("commonButtons.continue")}
          </Button>
        </StickyFooter>
      </div>
    </FormikProvider>
  );
};

export default Basics;
