"use strict";

import React from "react";
import { get, find } from "lodash";
import { Grid, Button } from "@citifyd/style";

import RouterLinkButton from "../../../../shared/react/components/RouterLinkButton";
import { useTranslator, useService } from "../../../../shared/react/hooks";
import { updateTicketTransfer as updateTransfer } from "../../../../shared/api";
import styles from "./TicketTransactionsActions.module.scss";
import { showErrorAlert } from "../../../../shared/services/helper";
import Permissions from "../../../../shared/services/permissions";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const TicketTransactionActions = ({ ticket }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const { permissions } = useAuthentication();
  const $stateParams = useService("$stateParams");

  const getPendingTransfer = () => {
    var vouchers = get(ticket, "vouchers");

    return find(vouchers, function (voucher) {
      return !voucher.redeemedAt && !voucher.deletedAt;
    });
  };

  const hasPendingTransfer = () => {
    return Boolean(getPendingTransfer());
  };

  const updateTicketTransfer = async (data) => {
    try {
      await updateTransfer(parseInt($stateParams.id), data);
    } catch (err) {
      showErrorAlert(err.data);
    } finally {
      $state.go($state.current.url, $stateParams, {
        reload: true,
        location: "replace",
      });
    }
  };

  const cancelTransfer = () => {
    if (
      !window.confirm(
        t("transaction.ticketTransactionActions.transferCancelConfirmation")
      )
    ) {
      return;
    }

    updateTicketTransfer({ confirm: false });
  };

  const resendTransferCode = () => {
    if (
      !window.confirm(
        t("transaction.ticketTransactionActions.resendTransferCodeConfirmation")
      )
    ) {
      return;
    }

    updateTicketTransfer({ regenerate: true });
  };

  const shouldShowResendTransferCodeButton = () => {
    const transfer = getPendingTransfer();

    return (
      transfer &&
      (get(transfer, "extra.email") || get(transfer, "extra.phoneNumber"))
    );
  };

  const shouldShowRefundButton = () => {
    const canRefund = Permissions.userMayCreateRefunds(permissions);

    return (
      canRefund &&
      ticket &&
      ticket.cancellationAllowed &&
      !ticket.ticketCancellation &&
      !ticket.bundlePurchase
    );
  };

  const shouldShowTransferButton = () => {
    return ticket && ticket.transferAllowed;
  };

  const shouldShowExchangeButton = () => {
    return ticket && ticket.exchangeAllowed;
  };

  return (
    <div>
      {hasPendingTransfer() && (
        <Grid.Row>
          <Grid.Col xs={12} md={3}>
            <Button
              className={styles.button}
              onClick={cancelTransfer}
              uppercase
              fullWidth
              justifyContent="center"
              letterSpacing
              shadow
            >
              {t("transaction.cancelTransfer")}
            </Button>
          </Grid.Col>
          {shouldShowResendTransferCodeButton() && (
            <Grid.Col xs={12} md={3}>
              <Button
                className={styles.button}
                onClick={resendTransferCode}
                uppercase
                fullWidth
                justifyContent="center"
                letterSpacing
                shadow
              >
                {t("transaction.sendCodeAgain")}
              </Button>
            </Grid.Col>
          )}
        </Grid.Row>
      )}

      {!hasPendingTransfer() && (
        <Grid.Row>
          {shouldShowRefundButton() && (
            <Grid.Col xs={12} md={3}>
              <RouterLinkButton
                className={styles.button}
                state="ticket-refund"
                params={{ id: ticket.id }}
                uppercase
                fullWidth
                justifyContent="center"
                letterSpacing
                shadow
              >
                {t("transaction.refundPass")}
              </RouterLinkButton>
            </Grid.Col>
          )}

          {shouldShowTransferButton() && (
            <Grid.Col xs={12} md={3}>
              <RouterLinkButton
                className={styles.button}
                state="ticket-transfer"
                params={{ id: ticket.id }}
                uppercase
                fullWidth
                justifyContent="center"
                letterSpacing
                shadow
              >
                {t("transaction.transferPass")}
              </RouterLinkButton>
            </Grid.Col>
          )}

          {shouldShowExchangeButton() && (
            <Grid.Col xs={12} md={3}>
              <RouterLinkButton
                className={styles.button}
                state="ticket-exchange"
                params={{ id: ticket.id, sameEvent: true }}
                uppercase
                fullWidth
                justifyContent="center"
                letterSpacing
                shadow
              >
                {t("transaction.exchangePass")}
              </RouterLinkButton>
            </Grid.Col>
          )}

          {shouldShowExchangeButton() && (
            <Grid.Col xs={12} md={3}>
              <RouterLinkButton
                className={styles.button}
                state="ticket-exchange"
                params={{ id: ticket.id, sameEvent: true }}
                uppercase
                fullWidth
                justifyContent="center"
                letterSpacing
                shadow
              >
                {t("transaction.changeLot")}
              </RouterLinkButton>
            </Grid.Col>
          )}
        </Grid.Row>
      )}
    </div>
  );
};

export default TicketTransactionActions;
