"use strict";
import React, { useMemo } from "react";
import moment from "moment-timezone";

import { Button, Icon, Text } from "@citifyd/style";

import {
  AdminGetAccountPermissionInternalUsersQuery,
  AdminGetAccountPermissionInternalUsersQueryVariables,
  useAdminDeleteAccountPermissionInternalUserMutation,
} from "../../../graphql/generated/graphql";

import Table from "../../../shared/react/components/Table";
import { Pagination } from "../Pagination/Pagination";

import ApolloClient from "../../../shared/services/apolloClient";
import { usePagination } from "../Pagination/usePagination";
import { useTranslator } from "../../../shared/react/hooks";
import { useAuthentication } from "../../../shared/react/contexts/authentication";
import { getService } from "../../../shared/react/utils";

import styles from "../AccountPermissions.module.scss";
import { usePermissionOptions } from "../../add-management-permission/usePermissionOptions";
import { transformPermissionsToFrontend } from "./transformPermissionsToFrontend";

import { ListToSeeMorePermissions } from "./ListToSeeMorePermissions";

interface PermissionInternalUserProps {
  loading: boolean;
  pageSize: number;
  permissionInternalUser?: AdminGetAccountPermissionInternalUsersQuery["getAccountPermissionInternalUsers"];
  refetch: (
    variables: AdminGetAccountPermissionInternalUsersQueryVariables
  ) => any;
}

const apolloClient = ApolloClient();

export const ActivePermissionInternalUser = ({
  loading,
  pageSize,
  permissionInternalUser,
  refetch,
}: PermissionInternalUserProps) => {
  const t = useTranslator();
  const { user } = useAuthentication();
  const permissionOptions = usePermissionOptions();

  const { hasNextPage, hasPreviousPage, nextPage, previousPage } =
    usePagination({
      data: permissionInternalUser,
      pageSize,
      refetch,
    });

  const editPermission = (id) => {
    const $state = getService("$state");

    $state.transitionTo(
      "management-permission-edit",
      { id },
      { enableBackLink: true }
    );
  };

  const [deletePermissionInternalUser] =
    useAdminDeleteAccountPermissionInternalUserMutation({
      client: apolloClient,
    });

  const deleteInternalUser = async ({ userId }: { userId: number }) => {
    if (window.confirm(t("commonConfirmations.areYouSure"))) {
      await deletePermissionInternalUser({
        variables: {
          organizationId: user.organization.id,
          userId,
        },
      });
      refetch({
        first: pageSize,
        last: null,
        after: null,
        before: null,
      });
    }
  };

  const columns = [
    {
      value: t("accountPermissions.permissionInternalUser.name"),
      uppercase: false,
      key: "name",
      width: "20%",
    },
    {
      value: t("accountPermissions.permissionInternalUser.email"),
      uppercase: false,
      key: "email",
      width: "25%",
    },
    {
      value: t("accountPermissions.permissionInternalUser.permissions"),
      uppercase: false,
      key: "permissions",
      width: "25%",
    },
    {
      value: t("accountPermissions.permissionInternalUser.lastLogin"),
      uppercase: false,
      key: "lastLoginAt",
      width: "20%",
    },

    {
      value: t("accountPermissions.actions"),
      uppercase: false,
      key: "actions",
      width: "10%",
      textAlign: "right",
    },
  ];

  const data = useMemo(
    () =>
      permissionInternalUser?.edges.map((edge) => {
        const isOwner = edge.node.isOwner;
        const isMyself = edge.node.isMyself;

        const permissions = transformPermissionsToFrontend({
          permissions: edge.node.permissions,
          permissionOptions,
        });

        return {
          ...edge.node,
          name: {
            value: edge.node.name,
          },
          email: {
            value: edge.node.email,
          },
          permissions: {
            value: <ListToSeeMorePermissions permissions={permissions} />,
          },

          lastLoginAt: {
            value: moment(edge.node.lastLoginAt).format("lll"),
          },
          actions: {
            textAlign: "right",
            value:
              isOwner || isMyself ? (
                ""
              ) : (
                <>
                  <Button
                    justifyContent="center"
                    appearance="transparent"
                    onClick={() => editPermission(edge.node.id)}
                  >
                    <Icon icon="pencil-alt" />
                  </Button>

                  <Button
                    justifyContent="center"
                    appearance="transparent"
                    onClick={() => {
                      deleteInternalUser({ userId: edge.node.id });
                    }}
                  >
                    <Icon icon="trash" />
                  </Button>
                </>
              ),
          },
        };
      }),
    [permissionInternalUser?.edges]
  );

  return (
    <div className={styles.container}>
      {data && (
        <>
          <Text weight="300" variant="h5" gutterBottom>
            {t(
              "accountPermissions.permissionInternalUser.permissionInternalUserDescription"
            )}
          </Text>
          <Table
            headerAppearance="transparent"
            spacing
            columns={columns}
            data={data}
            responsive
          />
          {(hasNextPage || hasPreviousPage) && (
            <Pagination
              loading={loading}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              nextPage={nextPage}
              previousPage={previousPage}
              data={permissionInternalUser?.edges}
            />
          )}
        </>
      )}
    </div>
  );
};
