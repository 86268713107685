import { isEmpty, mapValues } from "lodash";
export const getInitialValues = (
  lot,
  addressSchema,
  defaultListOfAmenities
) => {
  if (!isEmpty(lot)) {
    return {
      name: lot.name,
      address: lot.address,
      aboutProperty: lot.about,
      importantNotes: lot.notes,
      directionsNotes: lot.directionsNotes,
      maxSpots: lot.maxSpots,
      color: lot.color,
      coordinates: { latitude: lot.latitude, longitude: lot.longitude },
      countryCode: lot.countryCode,
      hasImage: true,
      daysOfWeek: formatDaysOfWeekFrontend(lot.openingHours),
      images: {
        main: { image: lot.photo, uploading: false },
        additional1: { image: lot.additionalPhotos[0], uploading: false },
        additional2: { image: lot.additionalPhotos[1], uploading: false },
        additional3: { image: lot.additionalPhotos[2], uploading: false },
        additional4: { image: lot.additionalPhotos[3], uploading: false },
        additional5: { image: lot.additionalPhotos[4], uploading: false },
        additional6: { image: lot.additionalPhotos[5], uploading: false },
      },
      hours: formatOpeningHoursToFrontend(lot?.openingHours),
      amenities: transformAmenitiesToFrontend({
        selectedAmenityCodes: lot.amenityCodes,
        defaultListOfAmenities,
      }),
      hasPhysicalGates: lot.extraInfo.hasPhysicalGates,
      notifyEventsNearThisProperty: lot.notifyAboutEvents,
      reservedParking: lot.reservedParkingEnabled,
    };
  }

  return {
    name: "",
    address: addressSchema ? mapValues(addressSchema.properties, () => "") : "",
    aboutProperty: "",
    importantNotes: "",
    directionsNotes: "",
    maxSpots: "",
    coordinates: { latitude: "", longitude: "" },
    hasImage: false,
    images: {
      main: { image: null, uploading: false },
      additional1: { image: null, uploading: false },
      additional2: { image: null, uploading: false },
      additional3: { image: null, uploading: false },
      additional4: { image: null, uploading: false },
      additional5: { image: null, uploading: false },
      additional6: { image: null, uploading: false },
    },
    daysOfWeek: {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
      sun: true,
    },
    hours: {
      mon: { start: "08:00", end: "18:00" },
      tue: { start: "08:00", end: "18:00" },
      wed: { start: "08:00", end: "18:00" },
      thu: { start: "08:00", end: "18:00" },
      fri: { start: "08:00", end: "18:00" },
      sat: { start: "08:00", end: "18:00" },
      sun: { start: "08:00", end: "18:00" },
    },
    amenities: [],
    hasPhysicalGates: false,
    notifyEventsNearThisProperty: false,
    reservedParking: false,
  };
};

const transformAmenitiesToFrontend = ({
  selectedAmenityCodes,
  defaultListOfAmenities,
}) => {
  const result = defaultListOfAmenities.map((amenity) => [
    amenity.code,
    selectedAmenityCodes.includes(amenity.code),
  ]);

  return Object.fromEntries(result);
};

const formatDaysOfWeekFrontend = (openingHours) => {
  let formattedDaysOffWeek = {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  };

  for (let day in openingHours) {
    if (openingHours[day].length) {
      formattedDaysOffWeek[day] = true;
    }
  }

  return formattedDaysOffWeek;
};

const formatOpeningHoursToFrontend = (openingHours) => {
  const openingHoursData = Object.entries(openingHours);
  let formattedOpeningHours = {};
  let formattedHours = {};

  for (let opening of openingHoursData) {
    const [day, hours] = opening;

    if (hours[0]) {
      formattedHours = {
        start: hours[0].start === "24:00" ? "00:00" : hours[0].start,
        end: hours[0].end === "24:00" ? "00:00" : hours[0].end,
      };
    } else {
      formattedHours = { start: "00:00", end: "00:00" };
    }

    formattedOpeningHours = { ...formattedOpeningHours, [day]: formattedHours };
  }

  return formattedOpeningHours;
};
