"use strict";

import React from "react";

import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import { useTranslator } from "../../../../shared/react/hooks";
import styles from "./TicketRefundReason.module.scss";

const TicketRefundReason = ({}) => {
  const t = useTranslator();

  return (
    <div className={styles.refundReason}>
      <FormikField
        as={FIELD_TYPE.TEXTAREA}
        name="reason"
        label={t("refundPages.reason")}
        className={styles.textarea}
        gutterBottom
      />
      <FormikField
        as={FIELD_TYPE.CHECKBOX}
        name="requestedByPhone"
        label={t("refundPages.requestedPhone")}
        gutterBottom
      />
      <br />
      <FormikField
        as={FIELD_TYPE.CHECKBOX}
        name="requestedByEmail"
        label={t("refundPages.requestedEmail")}
      />
    </div>
  );
};

export default TicketRefundReason;
