"use strict";

import React from "react";
import { Grid, Select } from "@citifyd/style";
import { find } from "lodash";

import { useTranslator, useService } from "../../../../shared/react/hooks";
import { getService } from "../../../../shared/react/utils";
import PDFDownload from "../../../../shared/react/components/PDFDownload";
import CSVDownload from "../../../../shared/react/components/CSVDownload";

import styles from "./RevenueOfEventSubHeader.module.scss";
import GenerateReportModal from "../../../../shared/modals/GenerateReportModal";
import {
  getRevenueForEventCsv,
  getRevenueForEventPdfUrl,
} from "../../../../shared/api";
import { sendHiddenForm } from "../../../../shared/services/helper";
import { useModal } from "../../../../shared/react/contexts/modal";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const RevenueOfEventSubHeader = ({
  event,
  lotId,
  lots = [],
  lotSelected,
  setLotSelected,
  canExportToPdf,
  currency,
}) => {
  const t = useTranslator();
  const $state = useService("$state");
  const { getAccessToken } = useAuthentication();
  const { openModal } = useModal();

  const generatePdfClicked = () => {
    const accessToken = getAccessToken();
    const options = {
      lotId,
      currency,
    };

    const url = getRevenueForEventPdfUrl(event.id, options);
    sendHiddenForm(url, { access_token: accessToken }, "_report_" + event.id);
  };

  const generateCsvReportDescription = () => {
    if (lotId) {
      const lot = find(lots, (lot) => lot.id === Number(lotId));

      return t("revenue.csvReportName.event.lot", {
        eventName: event.name,
        lotName: lot.name,
      });
    } else {
      return t("revenue.csvReportName.event.currency", {
        eventName: event.name,
        currency,
      });
    }
  };

  const generateCsvClicked = () => {
    const options = {
      lotId,
      currency,
    };

    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription: generateCsvReportDescription(),
      reportCsvRequest: getRevenueForEventCsv(event.id, options),
    });
  };

  const options = [
    {
      label: t("revenue.revenueFilter.fields.allProperties"),
      value: "",
    },
    ...lots.map((lot) => ({ label: lot.name, value: lot.id })),
  ];

  const fieldChanged = (e) => {
    const $stateParams = getService("$stateParams");

    const lotId = e.target.value;

    const params = {
      event: $stateParams.event,
      dateRange: $stateParams.dateRange,
      currentLot: $stateParams.currentLot || undefined,
    };

    if (lotId) {
      const lot = lots.filter((lot) => lot.id === lotId)[0];

      params.lot = lotId;
      params.currency = lot.country.currency;
    } else {
      params.currency = currency;
    }

    setLotSelected(lotId);

    $state.transitionTo($state.current.name, params, {
      location: "replace",
      notify: false,
      inherit: false,
    });
  };

  return (
    <Grid.Row>
      <Grid.Col lg={4} md={6} xs={12} className={styles.select}>
        <Select
          fullWidth
          name="lotId"
          label={t("revenue.revenueFilter.fields.property")}
          options={options}
          value={lotSelected || ""}
          onChange={(e) => fieldChanged(e)}
        />
      </Grid.Col>
      <Grid.Col className={styles.exportButtons}>
        {canExportToPdf && <PDFDownload onClick={generatePdfClicked} />}
        <CSVDownload onClick={generateCsvClicked} />
      </Grid.Col>
    </Grid.Row>
  );
};

export default RevenueOfEventSubHeader;
