"use strict";

import React from "react";
import { Text } from "@citifyd/style";
import { useTranslator } from "../../../../shared/react/hooks";

const Result = () => {
  const t = useTranslator();
  return (
    <div>
      <Text variant="h1" weight="300" gutterBottom>
        {t("inviteParkers.result.title")}
      </Text>
      <Text weight="400" gutterBottom>
        {t("inviteParkers.result.explaination")}
      </Text>
      <Text weight="400" gutterBottom>
        {t("inviteParkers.result.userVerificationDisclaimer")}
      </Text>
    </div>
  );
};

export default Result;
