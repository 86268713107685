import React, { useState } from "react";
import {
  Button,
  Grid,
  Icon,
  LoadingIndicator,
  Modal,
  Text,
  Tooltip,
} from "@citifyd/style";
import { useTranslator } from "../../react/hooks";
import {
  DeviceQuery,
  useCreateDeviceMutation,
} from "../../../graphql-pos/generated/graphql";
import PosApolloClient from "../../services/posApolloClient";
import * as yup from "yup";
import { useFormik } from "formik";
import FormikField, { FIELD_TYPE } from "../../react/components/FormikField";
import FormikProvider from "../../react/components/FormikProvider";
import { getGraphqlError } from "../../services/graphql-helper";
import { showAlert } from "../../services/helper";

const posApolloClient = PosApolloClient();

interface FormProps {
  shortCode: string;
  make: string;
  modal: string;
  nickname: string;
  enabled: "enabled" | "disabled";
  networkCarrier: string;
  imei: string;
  serial: string;
}

export const RoverAddNewDeviceModal = ({
  device,
  open,
  onClose,
}: {
  device: DeviceQuery["device"];
  open: boolean;
  onClose: () => void;
}) => {
  const t = useTranslator();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [createDevice] = useCreateDeviceMutation({ client: posApolloClient });

  const initialValues: FormProps = {
    shortCode: "",
    make: "",
    modal: "",
    nickname: "",
    enabled: "enabled",
    networkCarrier: "",
    imei: "",
    serial: "",
  };

  const formikbag = useFormik({
    initialValues,
    validationSchema: yup.object().shape({}),

    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        await createDevice({
          variables: {
            shortCode: values.shortCode,
            make: values.make,
            model: values.modal,
            name: values.nickname,
            enabled: values.enabled === "enabled",
            networkCarrierName: values.networkCarrier,
            imei: values.imei,
            serial: values.serial,
          },
        });
        onClose();
      } catch (err) {
        console.log("Error occurred", err);
        const error = getGraphqlError(err);
        showAlert(error.message);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Modal open={open} onClose={onClose}>
        <Modal.Title>{t("posRover.devices.addNewDevice")}</Modal.Title>

        <Modal.Content>
          {isSubmitting && <LoadingIndicator />}
          <Grid.Row>
            <Grid.Col xs={12} lg={6}>
              <FormikField
                as={FIELD_TYPE.INPUT}
                gutterBottom
                name="shortCode"
                label={
                  <>
                    {t("posRover.devices.id")}
                    <Tooltip
                      id="id"
                      place="right"
                      label={
                        <Icon
                          icon="question-circle"
                          pull="right"
                          size="subtitle"
                        />
                      }
                    >
                      <Text appearance="white" weight="400">
                        a short code to identify a device
                      </Text>
                    </Tooltip>
                  </>
                }
              />
            </Grid.Col>
            <Grid.Col xs={12} lg={6}>
              <FormikField
                as={FIELD_TYPE.INPUT}
                gutterBottom
                name="nickname"
                label={t("posRover.devices.nickname")}
              />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={12} lg={6}>
              <FormikField
                as={FIELD_TYPE.INPUT}
                gutterBottom
                name="make"
                label={t("posRover.devices.make")}
              />
            </Grid.Col>
            <Grid.Col xs={12} lg={6}>
              <FormikField
                as={FIELD_TYPE.INPUT}
                gutterBottom
                name="modal"
                label={t("posRover.devices.model")}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col xs={12} lg={6}>
              <FormikField
                as={FIELD_TYPE.INPUT}
                name="networkCarrier"
                gutterBottom
                label={t("posRover.devices.networkCarrier")}
              />
            </Grid.Col>
            <Grid.Col style={{ alignSelf: "flex-end" }}>
              <div style={{ alignSelf: "flex-end" }}>
                <FormikField
                  as={FIELD_TYPE.RADIO}
                  gutterBottom
                  name="enabled"
                  label={t("posRover.devices.enabled")}
                  value="enabled"
                  checked={formikbag.values.enabled === "enabled"}
                />
                <div>
                  <FormikField
                    as={FIELD_TYPE.RADIO}
                    name="enabled"
                    gutterBottom
                    label={t("posRover.devices.disabled")}
                    value="disabled"
                    checked={formikbag.values.enabled === "disabled"}
                  />
                </div>
              </div>
            </Grid.Col>
          </Grid.Row>

          <FormikField
            as={FIELD_TYPE.INPUT}
            gutterBottom
            name="imei"
            label={t("posRover.devices.imei")}
          />

          <FormikField
            as={FIELD_TYPE.INPUT}
            gutterBottom
            name="serial"
            label={t("posRover.devices.serial")}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={onClose}
            textColor="white"
            appearance="secondary"
            fullWidth
            justifyContent="center"
            uppercase
          >
            cancel
          </Button>
          <Button
            type="submit"
            fullWidth
            justifyContent="center"
            uppercase
            onMouseDown={() => handleSubmit()}
          >
            Assign
          </Button>
        </Modal.Actions>
      </Modal>
    </FormikProvider>
  );
};
