"use strict";

import React from "react";
import produce from "immer";
import { last } from "lodash";

import { Button, Collapse, Icon } from "@citifyd/style";
import BoxContent from "../../../../../shared/react/components/BoxContent";
import FormikField, {
  FIELD_TYPE,
} from "../../../../../shared/react/components/FormikField";
import { useTranslator } from "../../../../../shared/react/hooks";
import Table from "../../../../../shared/react/components/Table";
import BoxHeader from "./BoxHeader";
import styles from "./DynamicPricing.module.scss";
import classNames from "classnames";

const AdjustRateByNumberOfTransactions = ({ lot, formikbag, formikIndex }) => {
  const t = useTranslator();

  const { setValues, values } = formikbag;
  const rate = values.selectedLots[formikIndex].availability.rate;

  const columns = [
    {
      value: t("eventProperties.dynamicPricing.numberOfTransactions"),
      key: "numberOfTransactions",
      width: "50%",
      uppercase: false,
    },
    {
      value: t("eventProperties.dynamicPricing.rate"),
      key: "rate",
      width: "25%",
      uppercase: false,
    },
    {
      value: "",
      key: "padding",
      width: "25%",
    },
    {
      value: "",
      key: "action",
      textAlign: "right",
    },
  ];

  const data = rate.dynamic?.transaction?.map((rule, ruleIndex) => {
    return {
      numberOfTransactions: {
        value: (
          <FormikField
            as={FIELD_TYPE.INPUT}
            type="number"
            min="1"
            name={`selectedLots[${formikIndex}].availability.rate.dynamic.transaction[${ruleIndex}].rule`}
          />
        ),
        nowrap: "nowrap",
      },
      rate: {
        value: (
          <FormikField
            as={FIELD_TYPE.CURRENCY}
            name={`selectedLots[${formikIndex}].availability.rate.dynamic.transaction[${ruleIndex}].value`}
            currency={lot.country.currency}
          />
        ),
        nowrap: "nowrap",
      },
      padding: {
        value: <></>,
      },
      action: {
        value: (
          <Button
            onMouseDown={() => removeRate(ruleIndex)}
            appearance="tertiary"
            size="small"
            justifyContent="center"
            className={styles.button}
            rounded
          >
            <Icon icon="times" pull={null} size="h3" appearance="white" />
          </Button>
        ),
        verticalAlign: "middle",
      },
    };
  });

  const addNewRate = () => {
    setValues(
      produce((values) => {
        values.selectedLots[
          formikIndex
        ].availability.rate.dynamic.transaction.push({
          rule: "",
          value: "",
        });
      })
    );
  };

  const removeRate = (ruleIndex) => {
    setValues(
      produce((values) => {
        const dynamic =
          values.selectedLots[formikIndex].availability.rate.dynamic;
        if (dynamic.transaction.length === 1) {
          dynamic.transactionEnabled = false;
        } else {
          dynamic.transaction.splice(ruleIndex, 1);
        }
      })
    );
  };

  const handleChange = () => {
    setValues(
      produce((values) => {
        const rate = values.selectedLots[formikIndex].availability.rate;

        if (!rate.dynamic) rate.dynamic = {};
        rate.dynamic.transactionEnabled = !rate.dynamic.transactionEnabled;
        if (!rate.dynamic.transaction)
          rate.dynamic.transaction = [{ rule: "", value: "" }];
      })
    );
  };

  const canAddNewRate = () => {
    const lastRate = last(rate?.dynamic?.transaction);
    return !lastRate || (lastRate.rule !== "" && lastRate.value !== "");
  };

  return (
    <BoxContent noShadow>
      <BoxHeader
        title={t(
          "eventProperties.dynamicPricing.adjustRateByNumberOfTransactions"
        )}
        tooltip={t(
          "eventProperties.dynamicPricing.adjustRateByNumberOfTransactionsMessage"
        )}
        selected={rate.dynamic?.transactionEnabled}
        handleChange={handleChange}
      />
      <Collapse isOpened={rate.dynamic?.transactionEnabled}>
        <BoxContent.Content>
          <Table
            columns={columns}
            data={data}
            spacing
            headerAppearance="transparent"
          />
          <Button
            onMouseDown={() => addNewRate()}
            disabled={!canAddNewRate()}
            appearance="primary"
            size="small"
            justifyContent="center"
            className={classNames(styles.button, styles.addRateButton)}
            rounded
          >
            <Icon icon="plus" pull={null} size="h3" appearance="white" />
          </Button>
        </BoxContent.Content>
      </Collapse>
    </BoxContent>
  );
};

export default AdjustRateByNumberOfTransactions;
