import gql from "graphql-tag";

export default gql`
  query AdminRatesReport($lotId: Int!, $date: Date!, $organizationId: Int) {
    admin_ratesReport(
      lotId: $lotId
      date: $date
      organizationId: $organizationId
    ) {
      rates {
        name
        values
      }
      sessions {
        totals
        percentages
      }
      amounts {
        totals
        percentages
      }
      currency
    }
  }
`;
