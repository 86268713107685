"use strict";

import { useFormik } from "formik";
import { mapValues, forEach, filter, reduce } from "lodash";
import * as yup from "yup";
import {
  getAddedLotAvailabilities,
  getRemovedLotAvailabilities,
  startDateValidation,
  endDateValidation,
  transformToEventGatesArray,
  getFormattedNearbyLots,
  getPosRatesObject,
  getEventGatesObject,
} from "./utils";
import { showErrorAlert } from "../../shared/services/helper";
import { getService } from "../../shared/react/utils";
import { appendToEvent, getEvent, updateEventGates } from "../../shared/api";
import { useService } from "../../shared/react/hooks";
import Permissions from "../../shared/services/permissions";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import { getTranslator } from "../../shared/services/languages";

export const useEventFormik = ({ event, trackLoading, untrackLoading }) => {
  const $state = useService("$state");
  const { permissions } = useAuthentication();

  const formikbag = useFormik({
    initialValues: {
      selectedLots: [],
      mask: "--",
      eventGates: {},
      posRates: [],
    },
    validationSchema: yup.object().shape({
      posRates: yup.lazy((obj) =>
        yup.object(
          mapValues(obj, () =>
            yup.array().of(
              yup.object().shape({
                label: yup.string().required(true),
              })
            )
          )
        )
      ),
      ...(Permissions.userMayConfigureGates(permissions)
        ? {
            eventGates: yup.lazy((obj) =>
              yup.object(
                mapValues(obj, () =>
                  yup.object().shape({
                    belongsToSelectedLot: yup.boolean(),
                    selected: yup.boolean(),
                    beaconUids: yup
                      .array()
                      .when(["selected", "belongsToSelectedLot"], {
                        is: true,
                        then: yup.array().min(1).required(true),
                        otherwise: yup.array().min(0),
                      }),
                    type: yup
                      .string()
                      .when(["selected", "belongsToSelectedLot"], {
                        is: true,
                        then: yup.string().required(true),
                      }),
                  })
                )
              )
            ),
          }
        : {}),

      selectedLots: yup.array().of(
        yup.object().shape({
          availability: yup.object().shape({
            rate: yup.object().shape({
              value: yup
                .string()
                .trim()
                .test(
                  "isPublicSale",
                  true,
                  (value, context) =>
                    context?.from[1]?.value?.disablePublicSale || value
                ),
              dynamic: yup
                .object()
                .shape({
                  transaction: yup.array().when(["transactionEnabled"], {
                    is: (transactionEnabled) => transactionEnabled,
                    then: yup.array().of(
                      yup.object().shape({
                        rule: yup.number().required().positive().min(1),
                        value: yup.string().required(),
                      })
                    ),
                  }),
                  time: yup.array().when(["timeEnabled"], {
                    is: (timeEnabled) => timeEnabled,
                    then: yup.array().of(
                      yup.object().shape({
                        value: yup.string().required(),
                      })
                    ),
                  }),
                })
                .default({}),
            }),
            saleCutoffByTransaction: yup
              .string()
              .nullable(true)
              .when(["saleCutoffByTransactionEnabled"], {
                is: (saleCutoffByTransactionEnabled) =>
                  saleCutoffByTransactionEnabled,
                then: yup.string().nullable(true).required(),
              }),

            spaces: yup.object().shape({
              regular: yup.number().required(),
            }),

            startDate: yup.date().when(["startTime"], (startTime) =>
              yup.date().test({
                test: (startDate) =>
                  startDateValidation({
                    date: startDate,
                    time: startTime,
                    event,
                  }),
              })
            ),

            endDate: yup.date().when(["endTime"], (endTime) =>
              yup.date().test({
                test: (endDate) =>
                  endDateValidation({
                    date: endDate,
                    time: endTime,
                    event,
                  }),
              })
            ),
          }),
        })
      ),
    }),
    onSubmit: async (values) => {
      const { posRates, selectedLots, eventGates } = values;
      const removed = getRemovedLotAvailabilities(event);
      const added = getAddedLotAvailabilities({
        event,
        selectedLots,
        posRates,
      });

      try {
        await saveProperties({
          added,
          removed,
          trackLoading,
          untrackLoading,
        });
        await saveEventGates({
          eventGates,
          removedLotAvailabilities: removed,
          trackLoading,
          untrackLoading,
        });
      } catch (response) {
        console.log("Error on save", response);
        showErrorAlert(response.data);
        return;
      }

      trackLoading("redirectingAfterSubmit");

      if (Permissions.userMayEditEvent(permissions)) {
        $state.go("events");
      } else {
        $state.go("appendable-events", { maxDistance });
      }
    },
  });

  return formikbag;
};

export const validateMaxRuleOnNumberOfTransactions = ({
  selectedLots,
  actions,
}) => {
  const t = getTranslator();
  let isValid = true;

  for (let lotIndex = 0; lotIndex < selectedLots.length; lotIndex++) {
    const lot = selectedLots[lotIndex];
    const { maxSpots } = lot;

    const items = lot?.availability?.rate.dynamic?.transaction;
    if (!items) continue;

    for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
      const item = items[itemIndex];

      if (item.rule > maxSpots) {
        actions.setFieldError(
          `selectedLots[${lotIndex}].availability.rate.dynamic.transaction[${itemIndex}].rule`,
          t("eventProperties.dynamicPricing.cannotBeGreaterThan", { maxSpots })
        );
        isValid = false;
      }
    }
  }

  return isValid;
};

const saveProperties = ({ added, removed, trackLoading, untrackLoading }) => {
  const $stateParams = getService("$stateParams");
  const eventId = $stateParams.eventId;
  trackLoading("savingProperty");

  return appendToEvent(eventId, {
    add: added,
    remove: removed,
  }).finally(() => untrackLoading("savingProperty"));
};

const saveEventGates = ({
  eventGates: editEventGates,
  removedLotAvailabilities,
  trackLoading,
  untrackLoading,
}) => {
  const $stateParams = getService("$stateParams");
  const eventId = $stateParams.eventId;

  trackLoading("savingEventGates");

  forEach(removedLotAvailabilities, (lot) => {
    const eventGates = filter(editEventGates, { lotId: lot.lotId });

    forEach(eventGates, (eventGate) => (eventGate.selected = false));
  });

  const eventGatesUpdated = transformToEventGatesArray(editEventGates);

  return updateEventGates(eventId, { eventGates: eventGatesUpdated }).finally(
    () => untrackLoading("savingEventGates")
  );
};

export const loadLots = async ({
  setFieldValue,
  setEvent,
  eventId,
  measurementSystem,
  setHasError,
  trackLoading,
  untrackLoading,
}) => {
  const $stateParams = getService("$stateParams");
  const maxDistance = $stateParams.maxDistance || "2";
  trackLoading("loadingProperty");
  try {
    const event = await getEvent(eventId, {
      params: {
        maxDistance: maxDistance,
      },
    });

    const nearbyLots = getFormattedNearbyLots({ event }, measurementSystem);

    setFieldValue(
      "eventGates",
      getEventGatesObject({ event, lots: nearbyLots })
    );

    setFieldValue("posRates", getPosRatesObject(event.lots));

    setEvent({ ...event, nearbyLots: nearbyLots });
  } catch (error) {
    console.log({ error });
    setHasError(true);
  } finally {
    untrackLoading("loadingProperty");
  }
};
