import "./styles/app.scss";
import "@citifyd/style/dist/main.css";

import CitifydApp from "./root-component";

function App() {
  return <CitifydApp />;
}

export default App;
