"use strict";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useAdminCreateAccountPermissionInternalUserMutation,
  AdminGetAccountPermissionInternalUserQuery,
  useAdminUpdateAccountPermissionInternalUserMutation,
  AdminGetAccountPermissionUserInvitationQuery,
  useAdminUpdateAccountPermissionUserInvitationUserMutation,
} from "../../graphql/generated/graphql";

import ApolloClient from "../../shared/services/apolloClient";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import { useService } from "../../shared/react/hooks";
import { showErrorAlert } from "../../shared/services/helper";

import { PermissionOption } from "./usePermissionOptions";

export type UserData =
  | AdminGetAccountPermissionInternalUserQuery["getAccountPermissionInternalUser"]
  | AdminGetAccountPermissionUserInvitationQuery["getAccountPermissionUserInvitation"];

export interface FormValues {
  employeesName: string;
  employeesEmail: string;
  selectAll: boolean;
  checkboxes: {
    accessSnapshot: boolean;
    viewSchedule: boolean;
    manageEvents: boolean;
    accessReports: boolean;
    viewParkerProfilesAndCustomerRelationTools: boolean;
    viewPermits: boolean;
    exportReceipts: boolean;
    manageOrganizationInformation: boolean;
    accountSettings: boolean;
    paymentInformation: boolean;
    createAttendantPermissions: boolean;
    createManagementPermissions: boolean;
    setupAndManageProperties: boolean;
    accessCitifydLive: boolean;
  };
}

const apolloClient = ApolloClient();

export const useManagementPermission = ({
  permissionOptions,
  userData,
}: {
  permissionOptions: PermissionOption[];
  userData: UserData;
}) => {
  const { user } = useAuthentication();
  const $state = useService("$state");
  const isEditing = Boolean($state.params.id);
  const isPending = $state.params.pending;

  const [createAccountPermissionInternalUserMutation] =
    useAdminCreateAccountPermissionInternalUserMutation({
      client: apolloClient,
    });

  const [updateAccountPermissionInternalUserMutation] =
    useAdminUpdateAccountPermissionInternalUserMutation({
      client: apolloClient,
    });

  const [updateAccountPermissionUserInvitationUserMutation] =
    useAdminUpdateAccountPermissionUserInvitationUserMutation({
      client: apolloClient,
    });

  const initialValues = getInitialValues({ userData, permissionOptions });

  const formikbag = useFormik<FormValues>({
    initialValues,
    validationSchema: yup.object().shape({
      employeesName: isEditing ? yup.string() : yup.string().required(),
      employeesEmail: isEditing
        ? yup.string().email()
        : yup.string().email().required(),
    }),

    onSubmit: async (values) => {
      let permissions: string[] = [];

      for (let option of permissionOptions) {
        if (values.checkboxes[option.name]) {
          permissions.push(...option.permissions);
        }
      }

      try {
        if (isEditing && !isPending && userData) {
          await updateAccountPermissionInternalUserMutation({
            variables: {
              organizationId: user.organization.id,
              userId: userData.id,
              permissions,
            },
          });
        } else if (isEditing && isPending && userData) {
          await updateAccountPermissionUserInvitationUserMutation({
            variables: {
              organizationId: user.organization.id,
              inviteId: userData.id,
              permissions,
              name: values.employeesName,
              email: values.employeesEmail,
            },
          });
        } else {
          await createAccountPermissionInternalUserMutation({
            variables: {
              organizationId: user.organization.id,
              name: values.employeesName,
              email: values.employeesEmail,
              permissions,
            },
          });
        }

        $state.transitionTo("account-permissions");
      } catch (err) {
        showErrorAlert(err.data);
      }
    },
  });

  return formikbag;
};

const getInitialValues = ({
  userData,
  permissionOptions,
}: {
  userData: UserData;
  permissionOptions: PermissionOption[];
}): FormValues => {
  if (userData) {
    const permissions = transformPermissionsToFrontend({
      userPermissions: userData.permissions as string[],
      permissionOptions,
    });

    return {
      employeesName: userData.name ?? "",
      employeesEmail: userData.email ?? "",
      selectAll: Object.values(permissions).every(
        (permission) => permission === true
      ),
      checkboxes: permissions,
    };
  } else {
    return {
      employeesName: "",
      employeesEmail: "",
      selectAll: false,
      checkboxes: {
        accessSnapshot: false,
        viewSchedule: false,
        manageEvents: false,
        accessReports: false,
        viewParkerProfilesAndCustomerRelationTools: false,
        exportReceipts: false,
        viewPermits: false,
        manageOrganizationInformation: false,
        accountSettings: false,
        paymentInformation: false,
        createAttendantPermissions: false,
        createManagementPermissions: false,
        setupAndManageProperties: false,
        accessCitifydLive: false,
      },
    };
  }
};

const transformPermissionsToFrontend = ({
  userPermissions,
  permissionOptions,
}: {
  userPermissions: string[];
  permissionOptions: PermissionOption[];
}): FormValues["checkboxes"] => {
  let checkboxes = {} as FormValues["checkboxes"];

  for (let option of permissionOptions) {
    checkboxes[option.name] = option.permissions.every((permission) =>
      userPermissions.includes(permission)
    );
  }
  return checkboxes;
};
