import moment from "moment-timezone";

// On the back-end, datetimes are stored as ISO 8601 strings (called here as "iso strings"),
// which are usually stored in UTC (e.g. `2021-08-19T15:00:00Z`). We usually need to convert
// these points in time to the appropriate timezone based on the context, to show the the
// opening times of a lot to a parker, or render a form for the parking provider to change
// these opening times, in a manner that makes sense to them.
//
// Because of that, storing only the datetime in UTC is not enough. We also need to have the
// timezone of the model stored together with it. So the back-end must return both the datetime
// (the point in time, usually in UTC, in the iso format) and the timezone name in which it
// should be represented, so that the front-end can properly convert it.
//
// In the forms we have on the front-end that allow changing a datetime, we usually have two
// fields for a given datetime: a date picker and a time picker. Since we have two distinct
// fields, we need to split the datetime in a date variable, and a time variable.
//
// Our date pickers deal with a JS Date object (which always represents date in the timezone
// of the user's machine, which could be different from the timezone of the lot), while our
// time pickers use the time as a string (e.g. `10:00`).
//
// The functions in this file were made to help converting a ISO String coming from the back-end
// to Date objects and time strings used in our forms, and vice-versa, so that we can pull data
// from the back-end and show on the screen, and get data from the forms and send back to the
// back-end.

const getTimeObject = (str) => {
  const parts = str.split(":");
  return {
    hour: parts[0],
    minute: parts[1],
    second: 0,
  };
};

export const localDateAndTimeToIsoString = (localDate, time, timezoneName) =>
  localDateAndTimeToMoment(localDate, time, timezoneName).format();

export const localDateAndTimeToMoment = (localDate, time, timezoneName) => {
  const ymd = moment(localDate).locale("en").format("YYYY-MM-DD");
  return moment.tz(ymd, timezoneName).set(getTimeObject(time));
};

export const isoStringToLocalDate = (isoString, timezoneName) => {
  const ymd = moment(isoString).tz(timezoneName).format("YYYY-MM-DD");
  return moment(ymd).toDate();
};

export const isoStringToTime = (isoString, timezoneName) => {
  return moment(isoString).tz(timezoneName).locale("en").format("HH:mm");
};

export const isoStringToLocalDateAndTime = (isoString, timezoneName) => {
  const localDate = isoStringToLocalDate(isoString, timezoneName);
  const time = isoStringToTime(isoString, timezoneName);
  return [localDate, time];
};

export const momentToLocalDateAndTime = isoStringToLocalDateAndTime;

export const syncLinkedDate = ({
  previousDate,
  previousTime,
  newDate,
  newTime,
  linkedDate,
  linkedTime,
  timezoneName,
}) => {
  const previousMoment = localDateAndTimeToMoment(
    previousDate,
    previousTime,
    timezoneName
  );
  const newMoment = localDateAndTimeToMoment(newDate, newTime, timezoneName);
  const diff = newMoment.diff(previousMoment);

  const linkedMoment = localDateAndTimeToMoment(
    linkedDate,
    linkedTime,
    timezoneName
  );

  linkedMoment.add(diff);

  return momentToLocalDateAndTime(linkedMoment, timezoneName);
};
