"use strict";

import React, { useEffect, useState } from "react";
import { Button, LoadingIndicator, Text } from "@citifyd/style";

import FormikProvider from "../../../shared/react/components/FormikProvider";
import FormikField, {
  FIELD_TYPE,
} from "../../../shared/react/components/FormikField";
import PasswordRulesIndicator from "../../../shared/react/components/PasswordRulesIndicator";
import useRegister from "../useRegister.js";
import { useTranslator } from "../../../shared/react/hooks";
import styles from "./Form.module.scss";
import BoxContent from "../../../shared/react/components/BoxContent";
import { getUserPasswordRules } from "../../../shared/api";
import { getPrivacyPolicyUrl } from "../../../shared/services/settings";

const Form = ({ setStep, setUser, leave }) => {
  const t = useTranslator();
  const [loadingRules, setLoadingRules] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formikbag = useRegister({ setStep, setUser, setIsSubmitting });
  const [listOfRules, setListOfRules] = useState();
  const privacyPolicyUrl = getPrivacyPolicyUrl();

  useEffect(() => {
    getRules();
  }, []);

  const getRules = async () => {
    setLoadingRules(true);
    try {
      const response = await getUserPasswordRules({
        params: {
          potentialOrganizationUser: true,
        },
      });

      setListOfRules(response);
    } finally {
      setLoadingRules(false);
    }
  };

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <BoxContent className={styles.formContainer}>
        <BoxContent.Content>
          <Text textAlign="center" variant="h1" weight="300" gutterBottom>
            {t("register.welcome")}
          </Text>

          <FormikField
            label={t("register.emailAddress")}
            as={FIELD_TYPE.INPUT}
            name="email"
            gutterBottom
          />
          <FormikField
            label={t("register.fullName")}
            as={FIELD_TYPE.INPUT}
            name="name"
            gutterBottom
          />
          <FormikField
            label={t("register.password")}
            as={FIELD_TYPE.INPUT}
            name="password"
            type="password"
            gutterBottom
          />
          <PasswordRulesIndicator
            password={formikbag.values.password}
            rules={listOfRules}
          />
          <FormikField
            label={t("register.confirmPassword")}
            as={FIELD_TYPE.INPUT}
            type="password"
            name="passwordConfirm"
            gutterBottom
          />
          <div className={styles.footer}>
            <Button
              onMouseDown={() => leave()}
              type="button"
              textColor="white"
              appearance="secondary"
              fullWidth
              justifyContent="center"
              uppercase
            >
              {t("commonButtons.cancel")}
            </Button>
            <Button
              onMouseDown={handleSubmit}
              type="button"
              fullWidth
              justifyContent="center"
              uppercase
            >
              {t("commonButtons.continue")}
            </Button>
          </div>
          <div>
            <Text appearance="secondary" italic textAlign="center" weight="400">
              {t("privacyPolicyDisclaimer.line1")}
            </Text>
            <Text
              appearance="secondary"
              italic
              textAlign="center"
              className={styles.privacyPolicyUrl}
              weight="400"
              dangerouslySetInnerHTML={{
                __html: t("privacyPolicyDisclaimer.line2", {
                  hyperlinkStart: `<a href="${privacyPolicyUrl}" target="_blank">`,
                  hyperlinkEnd: "</a>",
                }),
              }}
            ></Text>
          </div>
          {(loadingRules || isSubmitting) && (
            <LoadingIndicator
              isLoading={loadingRules || isSubmitting}
              withOverlay
            />
          )}
        </BoxContent.Content>
      </BoxContent>
    </FormikProvider>
  );
};

export default Form;
