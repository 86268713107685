"use strict";

import React, { ChangeEvent } from "react";
import { Select, Grid, Input } from "@citifyd/style";

import { useTranslator } from "../../../shared/react/hooks";
import styles from "./SubscriptionRefund.module.scss";
import { useAuthentication } from "../../../shared/react/contexts/authentication.js";
import { useSubscriptionRefundContext } from "./SubscriptionRefundContext.jsx";
import { currencyFormatter } from "../../../shared/utils/currencyFormtter";
import CurrencyMaskedInput from "../../../shared/react/components/CurrencyMaskedInput";

export default function RefundSource() {
  const t = useTranslator();
  const {
    refundOptions,
    setRefundOptions,
    userInterfaceSettings,
    partialAmount,
    setPartialAmount,
  } = useSubscriptionRefundContext();
  const { user } = useAuthentication();

  if (!user.isAdmin) {
    return null;
  }

  if (!userInterfaceSettings || !userInterfaceSettings.allowChangingRefunder) {
    return null;
  }

  const changeRefunder = (e) => {
    const newRefunder = e.target.value.toLowerCase();
    setRefundOptions({
      ...refundOptions,
      refunder: newRefunder,
      // Makes sure that whenever the user changes the refunder, that refunder will be responsible
      // for paying the Stripe processing fees by default.
      stripeFeePayer: newRefunder,
      amount: newRefunder,
    });
  };

  const changeStripeFeePayer = (e) =>
    setRefundOptions({ ...refundOptions, stripeFeePayer: e.target.value });

  return (
    <div className={styles.boxContent}>
      <Grid.Row>
        <Grid.Col xs={12} md={4}>
          <CurrencyMaskedInput
            value={partialAmount}
            fullWidth
            label="Partial refund amount"
            onChange={(amount) => setPartialAmount(amount)}
          />
        </Grid.Col>
        <Grid.Col
          xs={12}
          md={userInterfaceSettings.allowChangingStripeFeePayer ? 4 : 12}
        >
          <Select
            fullWidth
            label={t("refundPages.deductTicketFundsFrom.label")}
            onChange={changeRefunder}
            value={refundOptions.refunder}
            options={[
              {
                label: t("refundPages.deductTicketFundsFrom.values.seller"),
                value: "seller",
              },
              {
                label: t("refundPages.deductTicketFundsFrom.values.citifyd"),
                value: "citifyd",
              },
            ]}
          />

          <br />
        </Grid.Col>
        {userInterfaceSettings.allowChangingStripeFeePayer && (
          <Grid.Col xs={12} md={4}>
            <Select
              fullWidth
              label={t("refundPages.deductStripeProcessingFeeFundsFrom.label")}
              onChange={changeStripeFeePayer}
              value={refundOptions.stripeFeePayer}
              disabled={refundOptions.refunder === "citifyd"}
              options={[
                {
                  label: t(
                    "refundPages.deductStripeProcessingFeeFundsFrom.values.seller"
                  ),
                  value: "seller",
                },
                {
                  label: t(
                    "refundPages.deductStripeProcessingFeeFundsFrom.values.citifyd"
                  ),
                  value: "citifyd",
                },
              ]}
            />
          </Grid.Col>
        )}
      </Grid.Row>
    </div>
  );
}
