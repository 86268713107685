"use strict";

const FEATURE_DEFINITIONS = {
  uppercase: {
    pattern: /[A-Z]/,
    message: "one uppercase [character]",
  },
  lowercase: {
    pattern: /[a-z]/,
    message: "one lowercase [character]",
  },
  special: {
    pattern: /[^a-zA-Z0-9 ]/,
    message: "one special [character]",
  },
  number: {
    pattern: /[0-9]/,
    message: "one number",
  },
};

export function validate(password, validationObject) {
  password = (password || "").toString();

  let result = {
    isValid: true,
    invalidMin: false,
    missingFeatures: [],
  };

  if (validationObject.min && password.length < validationObject.min) {
    result.isValid = false;
    result.invalidMin = true;
  }

  if (validationObject.features) {
    for (const feature of validationObject.features) {
      const { pattern } = FEATURE_DEFINITIONS[feature];
      if (!pattern.test(password)) {
        result.isValid = false;
        result.missingFeatures.push(feature);
      }
    }
  }

  return result;
}
