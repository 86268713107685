import React, { useEffect } from "react";
import { useAuthentication } from "../../contexts/authentication";
import ChangePasswordModal from "../../../modals/ChangePasswordModal";
import VerifyPhoneNumberModal from "../../../modals/VerifyPhoneNumberModal";
import { useModal } from "../../contexts/modal";
import { useService } from "../../hooks";

const AfterAuthModals = ({ children }) => {
  const $state = useService("$state");
  const { user, isImpersonating } = useAuthentication();
  const { openModal } = useModal();

  useEffect(() => {
    if (!user || isImpersonating()) return;

    if (!user.verifiedPhoneNumber && $state.current.name !== "register") {
      showVerifyPhoneNumberModal(user);
    } else if (user.passwordChangeRequired) {
      showPasswordChangeRequirementModal();
    }
  }, [user]);

  const showPasswordChangeRequirementModal = () => {
    openModal(ChangePasswordModal, {
      changeRequired: true,
      closeOnEscape: false,
    });
  };

  const showVerifyPhoneNumberModal = (user) => {
    openModal(VerifyPhoneNumberModal, {
      user: user,
      logoutLink: true,
      closeOnEscape: false,
    });
  };

  return children;
};

export default AfterAuthModals;
