"use strict";

import React, { useContext } from "react";
import { List, Box } from "@citifyd/style";

import { useTranslator, useService } from "../../hooks";
import whiteLogo from "../../../../images/white-logo.png";
import styles from "./DropdownHeader.module.scss";

import EditPaymentModal from "../../../modals/EditPaymentModal";
import { logoutIntercom } from "../../../services/intercom";
import Permissions from "../../../services/permissions";
import { useModal } from "../../contexts/modal";
import { useAuthentication } from "../../contexts/authentication";

const DropdownHeader = ({ setShowDropdown }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const { openModal } = useModal();
  const {
    user: loggedUser,
    getAccessToken,
    permissions,
    logout,
    originalUser,
  } = useAuthentication();

  const userMayAccessLimeDashboard = Permissions.userMayAccessLimeDashboard(
    permissions,
    loggedUser
  );
  const userMayAccessRover = Permissions.userMayAccessRover(
    permissions,
    loggedUser
  );
  const userMayAccessCitifydLive =
    Permissions.userMayAccessCitifydLive(permissions);
  const userMayChangePaymentInformation =
    Permissions.userMayChangePaymentInformation(permissions);

  const openLimeDashboard = () => {
    const url = process.env.REACT_APP_LIME_DASHBOARD_URL.replace(/\/$/, "");
    window.open(`${url}/signin?token=${getAccessToken()}`);
  };

  const openEditPaymentModal = () => {
    openModal(EditPaymentModal);
    setShowDropdown(false);
  };

  const openLiveApp = () => {
    const url = process.env.REACT_APP_LIVE_APP_URL.replace(/\/$/, "");
    window.open(`${url}/login?token=${getAccessToken()}`);
  };

  const onLogout = () => {
    if (!window.confirm(t("header.userNav.areYouSure"))) {
      return;
    }

    logout();
    logoutIntercom();
    window.location = "/";
  };

  return (
    <Box
      smallRadius
      className={styles.dropdownContent}
      onClick={(e) => e.stopPropagation()}
    >
      <Box.Content>
        <List hover="primary">
          {loggedUser?.role !== "localManager" && (
            <List.Item
              onClick={() => {
                $state.go("account");
              }}
            >
              {t("header.userNav.account")}
            </List.Item>
          )}

          {userMayChangePaymentInformation && (
            <List.Item textComponent="a" onClick={openEditPaymentModal}>
              {t("header.userNav.payment")}
            </List.Item>
          )}

          {(userMayAccessCitifydLive ||
            userMayAccessLimeDashboard ||
            userMayAccessRover) && <List.Divisor></List.Divisor>}

          {userMayAccessCitifydLive && (
            <List.Item
              textComponent="a"
              customIconLeft={
                <img src={whiteLogo} className={styles.citifydLogo} />
              }
              onClick={openLiveApp}
            >
              {t("header.userNav.citifydLive")}
            </List.Item>
          )}

          {userMayAccessLimeDashboard && (
            <List.Item
              textComponent="a"
              customIconLeft={
                <img src={whiteLogo} className={styles.citifydLogo} />
              }
              onClick={openLimeDashboard}
            >
              {t("header.userNav.openLime")}
            </List.Item>
          )}

          {(userMayAccessCitifydLive ||
            userMayAccessLimeDashboard ||
            userMayAccessRover) && <List.Divisor></List.Divisor>}

          {originalUser?.id === loggedUser?.id && (
            <List.Item textComponent="a" onClick={onLogout}>
              {t("header.userNav.logout")}
            </List.Item>
          )}
        </List>
      </Box.Content>
    </Box>
  );
};

export default DropdownHeader;
