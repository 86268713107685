"use strict";
import React, { useEffect, useState } from "react";

import { Grid, Text, Button } from "@citifyd/style";
import { useTranslator } from "../../shared/react/hooks";
import PageHeader from "../../shared/react/components/PageHeader";
import LoadingManager from "../../shared/react/components/LoadingManager";
import styles from "./StripeAccountSetup.module.scss";
import {
  generateStripeConnectedAccountVerificationLink,
  getStripeConnectedAccounts,
  getCountry,
} from "../../shared/api";
import { useAuthentication } from "../../shared/react/contexts/authentication";

const StripeAccountSetup = () => {
  const t = useTranslator();
  const [hasError, setHasError] = useState(false);
  const [email, setEmail] = useState();

  const { user: loggedUser } = useAuthentication();

  useEffect(() => {
    redirectToStripe();
  }, []);

  const getExistingStripeAccount = async () => {
    const stripeAccount = await getStripeConnectedAccounts();

    if (!stripeAccount) {
      throw new Error("Stripe account not found");
    }
    return stripeAccount;
  };

  const generateSetupLink = async (stripeAccountId) => {
    const response = await generateStripeConnectedAccountVerificationLink(
      stripeAccountId
    );

    return response;
  };

  const showErrorMessage = async () => {
    setHasError(true);

    const { countryCode } = loggedUser.organization;

    try {
      const country = await getCountry(countryCode);
      const { supportEmail } = country.citifydLocalInfo;
      setEmail(supportEmail.value);
    } catch (err) {
      console.log(`${countryCode}: country information could not be lodaded`);
    }
  };

  const redirectToStripe = async () => {
    try {
      const stripeAccount = await getExistingStripeAccount();
      const url = await generateSetupLink(stripeAccount.stripeAccountId);
      window.location.replace(url);
    } catch (err) {
      console.log(err);
      showErrorMessage();
    }
  };
  const tryAgain = () => {
    setHasError(false);
    redirectToStripe();
  };
  if (!hasError) {
    return <LoadingManager isLoading={!hasError} />;
  }
  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          backState
          weight="300"
          title={t("stripeAccountSetup.accountVerificationInformation")}
        />
      </PageHeader>
      <Text variant="subtitle">{t("stripeAccountSetup.errorOccurred")}</Text>
      <div className={styles.buttons}>
        <Button onClick={tryAgain} justifyContent="center" shadow uppercase>
          {t("stripeAccountSetup.tryAgain")}
        </Button>
        {email && (
          <Button
            href={`mailto:${email}`}
            justifyContent="center"
            shadow
            uppercase
          >
            {t("stripeAccountSetup.contactSupport")}
          </Button>
        )}
      </div>
    </Grid>
  );
};

export default StripeAccountSetup;
