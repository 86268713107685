"use strict";

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Text, Button, Box, List, LoadingIndicator } from "@citifyd/style";
import { useTranslator } from "../../hooks";
import styles from "./LanguageSelector.module.scss";
import { updateUser } from "../../../api";
import { showErrorAlert } from "../../../services/helper";
import {
  changeLanguage,
  getAvailableLanguages,
  getLanguageName,
  onLanguageChange,
  getCurrentLanguage,
} from "../../../services/languages";
import { useAuthentication } from "../../contexts/authentication";

const LanguageSelector = () => {
  const t = useTranslator();
  const node = useRef();
  const { user } = useAuthentication();
  const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguage());
  const [showLanguages, setShowLanguages] = useState(false);
  const [hideSelectorFadeOut, setHideSelectorFadeOut] = useState(true);
  const [showUpdateOverlay, setShowUpdateOverlay] = useState(false);

  useEffect(() => {
    onLanguageChange((language) => {
      setCurrentLanguage(language);
    });
  }, []);

  useEffect(() => {
    if (showLanguages) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showLanguages]);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    setShowLanguages(false);
  };

  const toogleSelector = () => {
    if (!showLanguages) {
      setHideSelectorFadeOut(false);
      setTimeout(() => {
        setShowLanguages(true);
      }, 200);
    } else {
      setShowLanguages(false);
      setTimeout(() => {
        setHideSelectorFadeOut(true);
      }, 200);
    }
  };

  const onChange = async (language) => {
    if (language === getCurrentLanguage()) {
      return;
    }

    changeLanguage(language, true);
    setShowUpdateOverlay(true);

    try {
      if (user) {
        await updateUser(user.id, { language });
      }

      window.location.reload();
    } catch (err) {
      showErrorAlert(err.data);
      setShowUpdateOverlay(false);
    }
  };

  return (
    <>
      {showUpdateOverlay && (
        <div className={styles.languageOverlay}>
          <div className={styles.dialog}>
            <LoadingIndicator noMargin />
            <Text variant="subtitle" textAlign="center">
              {t("languageSelector.applyingLanguage")}
            </Text>
          </div>
        </div>
      )}

      <div className={styles.languageSelector} ref={node}>
        <Button
          appearance="transparent"
          uppercase
          textColor="white"
          iconRight={showLanguages ? "chevron-down" : "chevron-up"}
          onClick={(e) => {
            toogleSelector();
            e.stopPropagation();
          }}
        >
          {getLanguageName(currentLanguage)}
        </Button>

        <Box
          smallRadius
          className={classNames(styles.languagesContent, {
            [styles.enter]: showLanguages,
            [styles.hide]: hideSelectorFadeOut,
          })}
          onClick={(e) => e.stopPropagation()}
        >
          <Box.Content>
            <List border hover="primary">
              {getAvailableLanguages().map((language, index) => (
                <List.Item
                  key={index}
                  bold
                  uppercase
                  textComponent="a"
                  onClick={(e) => {
                    onChange(language.key);
                    e.stopPropagation();
                  }}
                  iconSize="lg"
                  iconRight={
                    language.key === currentLanguage
                      ? ["far", "check-circle"]
                      : ["far", "circle"]
                  }
                  iconAppearance={
                    language.key === currentLanguage ? "primary" : "default"
                  }
                >
                  {language.name}
                </List.Item>
              ))}
            </List>
          </Box.Content>
        </Box>
      </div>
    </>
  );
};

export default LanguageSelector;
