import { useMemo } from "react";
import { useTranslator } from "../../shared/react/hooks";
import { FormValues } from "./useManagementPermission";

export type PermissionOption = {
  name: keyof FormValues["checkboxes"];
  label: string;
  permissions: string[];
};

export const usePermissionOptionColumns = (): PermissionOption[][] => {
  const t = useTranslator();

  return useMemo(
    () => [
      [
        {
          name: "accessSnapshot",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.accessSnapshot"
          ),
          permissions: ["view-snapshots"],
        },
        {
          name: "viewSchedule",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.viewSchedule"
          ),
          permissions: ["view-schedule"],
        },
        {
          name: "manageEvents",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.manageEvents"
          ),
          permissions: [
            "manage-events",
            "monitor-events",
            "manage-user-tickets",
          ],
        },
        {
          name: "accessReports",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.accessReports"
          ),
          permissions: ["view-revenue", "view-reports"],
        },
        {
          name: "viewParkerProfilesAndCustomerRelationTools",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.viewParkerProfilesAndCustomerRelationTools"
          ),
          permissions: [
            "view-user-information",
            "create-parker-signup-invitations",
          ],
        },
        {
          name: "viewPermits",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.viewPermits"
          ),
          permissions: ["view-permits", "manage-permit-portals"],
        },
        {
          name: "exportReceipts",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.exportReceipts"
          ),
          permissions: ["export-receipts"],
        },
      ],
      [
        {
          name: "manageOrganizationInformation",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.manageOrganizationInformation"
          ),
          permissions: ["manage-organization-information"],
        },
        {
          name: "paymentInformation",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.paymentInformation"
          ),
          permissions: ["manage-payment-information"],
        },
        // This permission is currently disabled, but can be enabled at any time
        // {
        //   name: "createAttendantPermissions",
        //   label: t(
        //     "accountPermissions.permissionInternalUser.assignPermissions.attendantPermissions"
        //   ),
        //   permissions: ["create-attendant"],
        // },
        {
          name: "createManagementPermissions",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.managementPermissions"
          ),
          permissions: ["create-management"],
        },
        {
          name: "setupAndManageProperties",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.setupAndManageProperties"
          ),
          permissions: [
            "manage-lots",
            "manage-lot-plans",
            "manage-lot-availabilities",
            "manage-payment-information",
          ],
        },
        {
          name: "accessCitifydLive",
          label: t(
            "accountPermissions.permissionInternalUser.assignPermissions.accessCitifydLive"
          ),
          permissions: ["access-citifyd-live"],
        },
      ],
    ],
    []
  );
};

export const usePermissionOptions = () => {
  const permissionColumns = usePermissionOptionColumns();
  return useMemo(() => permissionColumns.flat(), []);
};
