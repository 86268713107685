"use strict";

import React, { useMemo } from "react";
import { Text } from "@citifyd/style";
import classNames from "classnames";
import styles from "./DaysOfWeek.module.scss";
import { getTranslatedDayName } from "../../../services/helper";

const DaysOfWeek = ({
  daysOfWeek,
  error,
  errorMessage,
  onChange,
  small,
  fullWidth,
  gutterBottom,
}) => {
  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  return (
    <>
      <div
        className={classNames(styles.days, {
          [styles.small]: small,
          [styles.fullWidth]: fullWidth,
          [styles.gutterBottom]: gutterBottom,
        })}
      >
        {days.map((day) => (
          <button
            key={day}
            className={classNames({
              [styles.selected]: daysOfWeek[day],
              [styles.error]: error,
            })}
            onClick={() => onChange(day)}
          >
            {getTranslatedDayName(day)[0]}
          </button>
        ))}
      </div>
      {error && errorMessage && (
        <Text variant={small ? "small" : "body"} appearance="error">
          {errorMessage}
        </Text>
      )}
    </>
  );
};

export default DaysOfWeek;
