"use strict";

import React, { useMemo, useState } from "react";
import { AlertMessage, Grid, Text } from "@citifyd/style";
import PageHeader from "../../shared/react/components/PageHeader";
import SearchInput from "../../shared/react/components/SearchInput";
import RouterLinkButton from "../../shared/react/components/RouterLinkButton";
import Table from "../../shared/react/components/Table";

import { useService, useTranslator } from "../../shared/react/hooks";
import { getService } from "../../shared/react/utils";
import { normalize } from "../../shared/services/helper";
import ApolloClient from "../../shared/services/apolloClient";
import { useLazyQuery } from "@apollo/client";
import ADMIN_LIST_PERMIT_PORTALS from "../../graphql/permitPortal/queries/AdminListPermitPortals";
import ADMIN_LIST_PERMIT_PORTAL_USERS from "../../graphql/permitPortal/queries/AdminListPermitPortalUsers";
import { useEffect } from "react";
import LoadingManager from "../../shared/react/components/LoadingManager";
import moment from "moment-timezone";
import useDeletedAlert from "./useDeletedAlert";
import styles from "./Permitters.module.scss";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import BoxContent from "../../shared/react/components/BoxContent";

const apolloClient = ApolloClient();

const Permitters = () => {
  const t = useTranslator();
  const [search, setSearch] = useState("");
  const hasDeletedUser = useDeletedAlert();
  const $stateParams = useService("$stateParams");

  const [getPermitPortalsUser, { data, loading, error }] = useLazyQuery(
    ADMIN_LIST_PERMIT_PORTAL_USERS,
    {
      client: apolloClient,
      fetchPolicy: "network-only",
    }
  );

  // here is checking if the user has a registered portal, because to be able to add a permitter it is necessary to create a portal first
  const [
    getPermitPortals,
    {
      data: permitPortalsData,
      loading: loadingPortals,
      error: permitPortalsError,
    },
  ] = useLazyQuery(ADMIN_LIST_PERMIT_PORTALS, {
    client: apolloClient,
    fetchPolicy: "cache-first",
  });

  const hasPortals =
    permitPortalsData?.admin_listPermitPortals?.edges.length > 0;

  const userData = data ? data.admin_listPermitPortalUser.edges : [];
  useEffect(() => {
    getPermitPortals();
    getPermitPortalsUser();
  }, [hasDeletedUser]);

  const filteredUsers = useMemo(() => {
    if (search) {
      const normalizedSearch = normalize(search);
      const searchItem = (item) =>
        normalize(String(item)).includes(normalizedSearch);

      return userData?.filter(
        (user) =>
          searchItem(user.node.name) ||
          searchItem(user.node.phoneNumber) ||
          searchItem(user.node.identifier)
      );
    }

    return userData;
  }, [search, userData]);

  if (error || permitPortalsError || loading || loadingPortals) {
    return (
      <LoadingManager
        isLoading={loading}
        hasError={Boolean(error) || Boolean(permitPortalsError)}
      />
    );
  }

  return (
    <Grid>
      {hasDeletedUser && (
        <AlertMessage appearance="success" className={styles.deleteUser}>
          <Text appearance="white">
            The permitter <b>{$stateParams.deletedUser}</b> has been deleted
          </Text>
        </AlertMessage>
      )}

      <PageHeader>
        <PageHeader.Title
          weight="300"
          defaultState="permits"
          title={t("permits.permitters.pageTitle")}
        />
        {hasPortals && <Header setSearch={setSearch} />}
      </PageHeader>

      {hasPortals && <Body usersFromBackend={filteredUsers} />}
      {!hasPortals && <NoPortalFound />}
    </Grid>
  );
};

const Header = ({ setSearch }) => {
  const t = useTranslator();

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };
  return (
    <PageHeader.Actions>
      <PageHeader.Action>
        <SearchInput id="search" fullWidth onChange={handleChangeSearch} />
      </PageHeader.Action>
      <PageHeader.Action>
        <RouterLinkButton
          size="small"
          letterSpacing
          uppercase
          state="permitters-portal-settings"
        >
          {t("permits.permitters.settings.settings")}
        </RouterLinkButton>

        <RouterLinkButton
          size="small"
          letterSpacing
          uppercase
          state="add-permitters"
        >
          {t("permits.permitters.addPermitter")}
        </RouterLinkButton>
      </PageHeader.Action>
    </PageHeader.Actions>
  );
};

const Body = ({ usersFromBackend }) => {
  const t = useTranslator();
  const { user: loggedUser } = useAuthentication();

  const handleDetals = (userId) => {
    const $state = getService("$state");
    $state.go("user-permitters", { id: userId }, { enableBackLink: true });
  };

  const users = useMemo(
    () =>
      usersFromBackend?.map((user) => ({
        ...user.node,
        clickable: true,
        onClick: () => handleDetals(user.node.id),
        accessEndDate: !user.node.accessExpiresAt
          ? t("permits.permitters.never")
          : t("defaultFormats.datetime", {
              date: moment.tz(
                user.node.accessExpiresAt,
                loggedUser.timezoneName
              ),
            }),
      })),
    [usersFromBackend]
  );

  const columns = [
    { key: "name", value: t("permits.permitters.name") },
    { key: "phoneNumber", value: t("permits.permitters.phone") },
    { key: "identifier", value: t("permits.permitters.identifier") },
    {
      key: "accessEndDate",
      value: t("permits.permitters.accessEndDate"),
    },
  ];

  return users.length ? (
    <Table striped columns={columns} data={users} responsive />
  ) : (
    <Text textAlign="center">{t("permits.noFound")}</Text>
  );
};

const NoPortalFound = () => {
  const t = useTranslator();
  return (
    <BoxContent noShadow smallRadius>
      <BoxContent.Content>
        <Text variant="h4" weight={"400"} gutterBottom textAlign="center">
          {t("permits.permitters.noPortals")}
        </Text>
        <Text
          gutterBottom
          italic
          variant="h5"
          weight={"300"}
          textAlign="center"
        >
          {t("permits.permitters.description")}
        </Text>
        <div className={styles.addPortalButton}>
          <RouterLinkButton
            size="small"
            letterSpacing
            uppercase
            state="permitters-portal-settings-add"
          >
            {t("permits.permitters.settings.addPortal")}
          </RouterLinkButton>
        </div>
      </BoxContent.Content>
    </BoxContent>
  );
};

export default Permitters;
