import { exportBundlePurchaseReceipt, exportTicketReceipt } from "../../../api";
import { getTranslator } from "../../../services/languages";
import { saveAs } from "file-saver";

export const downloadTicket = async ({ ticketId, language }) => {
  const t = getTranslator();

  try {
    const response = await exportTicketReceipt(ticketId, language);

    const data = new Blob([response], { type: "application/pdf" });
    saveAs(data, `parking-receipt-${ticketId}.pdf`);

    return {
      status: "success",
    };
  } catch (err) {
    return {
      status: "error",
      reason: t("transaction.pdfGenerationError"),
    };
  }
};

export const downloadBundlePurchase = async ({
  bundlePurchaseId,
  language,
}) => {
  const t = getTranslator();

  try {
    const response = await exportBundlePurchaseReceipt(
      bundlePurchaseId,
      language
    );

    const data = new Blob([response], { type: "application/pdf" });
    saveAs(data, `bundle-purchase-${bundlePurchaseId}.pdf`);

    return {
      status: "success",
    };
  } catch (err) {
    console.log("Error", err);

    return {
      status: "error",
      reason: t("transaction.pdfGenerationError"),
    };
  }
};
