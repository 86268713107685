"use strict";

import React, { useEffect, useState } from "react";
import { Text } from "@citifyd/style";

import { useTranslator } from "../../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../../shared/services/languages";

import styles from "./ParkingApp.module.scss";

const ParkingApp = () => {
  const t = useTranslator();
  const [appleImage, setAppleImage] = useState();
  const [googleImage, setGoogleImage] = useState();
  const currentLanguage = getCurrentLanguage()?.split("-")[0];
  const storeImages = ["en", "ja", "pt"];

  useEffect(() => {
    const includedLanguage = storeImages.some((el) =>
      currentLanguage.includes(el)
    );
    const language = includedLanguage ? currentLanguage : "en";

    //this was causing a warning because the component did not exist, we are only mounting it when it exists
    let mounted = true;

    import(`../../../../images/app-store-badge_${language}.png`).then(
      (image) => {
        if (mounted) setAppleImage(image.default);
      }
    );

    import(`../../../../images/google-play-badge_${language}.png`).then(
      (image) => {
        if (mounted) setGoogleImage(image.default);
      }
    );

    return () => {
      mounted = false;
    };
  }, [currentLanguage]);

  return (
    <div className={styles.container}>
      <Text
        className={styles.title}
        variant="h1"
        textAlign="center"
        appearance="white"
        dangerouslySetInnerHTML={{
          __html: t("login.parkingApp.title", {
            nonBreakingSpace: "&nbsp;",
            interpolation: { escapeValue: true },
          }),
        }}
      />

      <Text
        className={styles.subtitle}
        variant="subtitle"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t("login.parkingApp.description"),
        }}
      />

      <div className={styles.downloadButtons}>
        <a
          href="https://itunes.apple.com/us/app/citifyd/id984113093?ls=1&mt=8"
          target="_blank"
        >
          <img src={appleImage} className={styles.badge} />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.citifyd.Citifyd"
          target="_blank"
        >
          <img src={googleImage} className={styles.badge} />
        </a>
      </div>
    </div>
  );
};

export default ParkingApp;
