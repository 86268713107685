"use strict";

import React from "react";
import { Text } from "@citifyd/style";
import { useTranslator } from "../../../react/hooks";

const InformativeMessage = ({
  clearPhoneNumber,
  skipTriggerMigration,
  forcePhoneNumberVerification,
}) => {
  const t = useTranslator();

  return (
    <>
      {clearPhoneNumber && (
        <Message text={t("modalEditUserPhoneNumber.disclaimers.clear")} />
      )}
      {!clearPhoneNumber &&
        !skipTriggerMigration &&
        !forcePhoneNumberVerification && (
          <Message
            text={t(
              "modalEditUserPhoneNumber.disclaimers.verificationPendingTickets"
            )}
          />
        )}
      {!clearPhoneNumber && forcePhoneNumberVerification && (
        <Message
          text={t("modalEditUserPhoneNumber.disclaimers.markAsVerified")}
        />
      )}
    </>
  );
};

const Message = ({ text }) => {
  return (
    <Text appearance="secondary" italic textAlign="center" weight="400">
      {text}
    </Text>
  );
};

export default InformativeMessage;
