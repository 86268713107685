"use strict";

import React, { useEffect, useState } from "react";

import { getService } from "../../utils";
import { selectDefaultFilters } from "./utils";
import { useAuthentication } from "../../contexts/authentication";

import RevenueEvents from "./components/RevenueEvents";
import RevenueDaily from "./components/RevenueDaily";
import RevenuePayouts from "./components/RevenuePayouts";

const Revenue = ({
  selectedReport,
  currencies,
  lots,
  organizationsOptions,
}) => {
  const $state = getService("$state");
  const { user } = useAuthentication();
  const [selectedDefaultFilters, setSelectedDefaultFilters] = useState(false);

  useEffect(() => {
    setDefaultParams({ currencies });
    setSelectedDefaultFilters(true);
  }, [selectedReport]);

  const setDefaultParams = ({ currencies }) => {
    selectDefaultFilters(user, $state, { currencies }, [
      "currency",
      "dateRange",
      "timezoneName",
    ]);
  };

  if (!selectedDefaultFilters) {
    return null;
  }

  return (
    <>
      {selectedReport === "daily" && (
        <RevenueDaily
          lots={lots}
          currencies={currencies}
          organizationsOptions={organizationsOptions}
        />
      )}
      {selectedReport === "events" && (
        <RevenueEvents
          lots={lots}
          currencies={currencies}
          organizationsOptions={organizationsOptions}
        />
      )}
      {selectedReport === "payouts" && (
        <RevenuePayouts
          lots={lots}
          currencies={currencies}
          organizationsOptions={organizationsOptions}
        />
      )}
    </>
  );
};

export default Revenue;
