"use strict";

import React, { useState, useEffect, useMemo } from "react";
import { Text, Button, Grid } from "@citifyd/style";
import { useFormikContext } from "formik";
import { some } from "lodash";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import AddCardModal from "../../../../shared/modals/AddCardModal";
import StickyFooter from "../../../../shared/react/components/StickyFooter";
import Table from "../../../../shared/react/components/Table";
import PageHeader from "../../../../shared/react/components/PageHeader";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import {
  getData,
  getPaymentMethodsData,
  complimentaryOptionShouldAppear,
} from "./utils";
import { hasSelectedItems, hasVehicleRequired } from "../../utils";
import { getAllCards, getCountries } from "../../../../shared/api";

import styles from "./UserPurchaseTicketPayment.module.scss";
import { useModal } from "../../../../shared/react/contexts/modal";
import ApolloClient from "../../../../shared/services/apolloClient";
import { planifyCountries } from "../../../../shared/services/helper";

const apolloClient = ApolloClient();

const UserPurchaseTicketPayment = ({ setStep }) => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const { openModal } = useModal();
  const { id: userId } = $stateParams;
  const [canSeeCardExpiration, setCanSeeCardExpiration] = useState(false);

  const [countries, setCountries] = useState();

  const [cards, setCards] = useState([]);
  const [complimentaryOption, setComplimentaryOption] = useState(false);

  const { setFieldValue, values, handleSubmit } = useFormikContext();
  const { selectedEvents, selectedBundles, selectedCardId, licensePlate } =
    values;

  const currentSeletedEvent = useMemo(() => {
    return selectedEvents.filter((event) => event.selected);
  }, [selectedEvents]);

  useEffect(() => {
    const validEvents = selectedEvents.map((item) => {
      return {
        ...item,
        ...(item?.selectedLot && { selected: true }),
      };
    });
    const validBundles = selectedBundles.map((item) => {
      return {
        ...item,
        ...(item?.selectedBundleId && { selected: true }),
      };
    });

    setFieldValue("selectedEvents", validEvents);
    setFieldValue("selectedBundles", validBundles);
    setFieldValue("licensePlate.plate", licensePlate.plate);
    setFieldValue("licensePlate.countryCode", licensePlate.countryCode);

    loadCards();
    loadCountries();
  }, []);

  const loadCountries = async () => {
    const response = await getCountries();
    const list = planifyCountries(response);

    const countriesOptions = list.map((country) => ({
      value: country.isoCode,
      label: `${getUnicodeFlagIcon(country.isoCode)}${" "}${country.name}`,
    }));

    setCountries(countriesOptions);
  };

  const openCardModal = () => {
    openModal(AddCardModal, {
      userId,
      onClose: (result = {}) => {
        if (result?.action === "added") {
          setCards((oldCards) => [...oldCards, result.card]);
          setFieldValue("selectedCardId", result.card.id);
        }
      },
    });
  };

  const loadCards = () => {
    getAllCards(userId).then((response) => {
      setCards(response.cards);
      setCanSeeCardExpiration(some(response.cards, (card) => card.expMonth));
      setComplimentaryOption(response.complimentaryOption);
    });
  };

  const handleSelectEvent = (item) => {
    const events = selectedEvents.map((event) => {
      if (event.id === item.id) {
        event.selected = !event.selected;
      }
      return event;
    });
    setFieldValue("selectedEvents", events);
  };

  const handleSelectBundle = (item) => {
    if (
      complimentaryOptionShouldAppear({ complimentaryOption, bundles: item }) &&
      selectedCardId === "_complimentary"
    ) {
      setFieldValue("selectedCardId", null);
    }

    const bundles = selectedBundles.map((bundle) => {
      if (bundle.id === item.id) {
        bundle.selected = !bundle.selected;
      }
      return bundle;
    });

    setFieldValue("selectedBundles", bundles);
  };

  const handleSelectCard = ({ cardId }) => {
    setFieldValue("selectedCardId", cardId);
  };

  const { columns, data } = getData({
    handleSelectEvent,
    handleSelectBundle,
    selectedEvents,
    selectedBundles,
  });

  const { columns: paymentMethodsColumns, data: paymentMethodsData } =
    getPaymentMethodsData({
      cards,
      canSeeCardExpiration,
      complimentaryOption,
      selectedCardId,
      handleSelectCard,
      selectedEvents,
      selectedBundles,
    });

  return (
    <>
      <PageHeader>
        <PageHeader.Title
          onClick={() => setStep(2)}
          weight="300"
          title={t("userPurchaseTicket.confirmPurchaseAndPaymentMethod")}
        />
      </PageHeader>

      <Text gutterBottom variant="h5">
        {t("userPurchaseTicket.purchaseSummary")}
      </Text>
      <Grid.Row marginBottom>
        <Grid.Col>
          <Table columns={columns} responsive striped data={data} />
        </Grid.Col>
      </Grid.Row>

      {hasVehicleRequired(currentSeletedEvent) && (
        <Grid.Row marginBottom>
          <Grid.Col>
            <FormikField
              as={FIELD_TYPE.INPUT}
              name="licensePlate.plate"
              label={t("userPurchaseTicket.licensePlate")}
              gutterBottom
            />
          </Grid.Col>

          <Grid.Col>
            <FormikField
              as={FIELD_TYPE.SELECT}
              options={countries}
              name="licensePlate.countryCode"
              label={t("userPurchaseTicket.countries")}
              gutterBottom
            />
          </Grid.Col>
        </Grid.Row>
      )}

      {hasSelectedItems({ selectedBundles, selectedEvents }) && (
        <>
          <Text gutterBottom variant="h5">
            {t("userPurchaseTicket.paymentMethod")}
          </Text>
          {cards?.length > 0 && (
            <Table
              columns={paymentMethodsColumns}
              responsive
              striped
              data={paymentMethodsData}
            />
          )}

          <Button
            shadow
            uppercase
            onMouseDown={openCardModal}
            extraPadding
            className={styles.addCard}
          >
            {t("userPurchaseTicket.addCard")}
          </Button>
        </>
      )}

      {hasSelectedItems({ selectedBundles, selectedEvents }) && (
        <div className={styles.refundReason}>
          <FormikField
            as={FIELD_TYPE.TEXTAREA}
            name="reason"
            label={t("userPurchaseTicket.reason")}
            className={styles.textarea}
            gutterBottom
          />
          <FormikField
            as={FIELD_TYPE.CHECKBOX}
            name="requestedByPhone"
            label={t("userPurchaseTicket.requestedPhone")}
            gutterBottom
          />
          <br />
          <FormikField
            as={FIELD_TYPE.CHECKBOX}
            name="requestedByEmail"
            label={t("userPurchaseTicket.requestedEmail")}
          />
        </div>
      )}

      {hasSelectedItems({ selectedBundles, selectedEvents }) && (
        <StickyFooter justifyContent="flex-end">
          <Button
            uppercase
            type="submit"
            onMouseDown={handleSubmit}
            extraPadding
          >
            {t("commonButtons.continue")}
          </Button>
        </StickyFooter>
      )}
    </>
  );
};

export default UserPurchaseTicketPayment;
