"use strict";

import React from "react";
import produce from "immer";
import { last } from "lodash";

import { Button, Collapse, Icon } from "@citifyd/style";
import FormikField, {
  FIELD_TYPE,
} from "../../../../../shared/react/components/FormikField";
import BoxHeader from "./BoxHeader";
import styles from "./DynamicPricing.module.scss";
import classNames from "classnames";
import Table from "../../../../../shared/react/components/Table";
import { useTranslator } from "../../../../../shared/react/hooks";
import BoxContent from "../../../../../shared/react/components/BoxContent";

const AdjustRateByTimeBeforeTheEvent = ({ lot, formikbag, formikIndex }) => {
  const t = useTranslator();

  const { setValues, values } = formikbag;
  const rate = values.selectedLots[formikIndex].availability.rate;

  const options = [
    {
      value: 360,
      label: t("eventProperties.dynamicPricing.hoursBefore", {
        hours: "6",
      }),
    },
    {
      value: 720,
      label: t("eventProperties.dynamicPricing.hoursBefore", {
        hours: "12",
      }),
    },
    {
      value: 1440,
      label: t("eventProperties.dynamicPricing.hoursBefore", {
        hours: "24",
      }),
    },
    {
      value: 2880,
      label: t("eventProperties.dynamicPricing.hoursBefore", {
        hours: "48",
      }),
    },
    {
      value: 4320,
      label: t("eventProperties.dynamicPricing.hoursBefore", {
        hours: "72",
      }),
    },
  ];

  const columns = [
    {
      value: t("eventProperties.dynamicPricing.remainingTime"),
      key: "remainingTime",
      width: "50%",
      uppercase: false,
    },
    {
      value: t("eventProperties.dynamicPricing.rate"),
      key: "rate",
      width: "25%",
      uppercase: false,
    },
    {
      value: "",
      key: "padding",
      width: "25%",
    },
    {
      value: "",
      key: "action",
      textAlign: "right",
    },
  ];

  const data = rate.dynamic?.time?.map((rule, ruleIndex) => {
    return {
      remainingTime: {
        value: (
          <FormikField
            as={FIELD_TYPE.SELECT}
            name={`selectedLots[${formikIndex}].availability.rate.dynamic.time[${ruleIndex}].rule`}
            options={options}
          />
        ),
        nowrap: "nowrap",
      },
      rate: {
        value: (
          <FormikField
            as={FIELD_TYPE.CURRENCY}
            name={`selectedLots[${formikIndex}].availability.rate.dynamic.time[${ruleIndex}].value`}
            currency={lot.country.currency}
          />
        ),
        nowrap: "nowrap",
      },
      padding: {
        value: <></>,
      },
      action: {
        value: (
          <Button
            onMouseDown={() => removeRate(ruleIndex)}
            appearance="tertiary"
            size="small"
            justifyContent="center"
            className={styles.button}
            rounded
          >
            <Icon icon="times" pull={null} size="h3" appearance="white" />
          </Button>
        ),
        verticalAlign: "middle",
      },
    };
  });

  const addNewRate = () => {
    setValues(
      produce((values) => {
        values.selectedLots[formikIndex].availability.rate.dynamic.time.push({
          rule: 360,
          value: "",
        });
      })
    );
  };

  const removeRate = (ruleIndex) => {
    setValues(
      produce((values) => {
        const dynamic =
          values.selectedLots[formikIndex].availability.rate.dynamic;
        if (dynamic.time.length === 1) {
          dynamic.timeEnabled = false;
        } else {
          dynamic.time.splice(ruleIndex, 1);
        }
      })
    );
  };

  const canAddNewRate = () => {
    const lastRate = last(rate?.dynamic?.time);
    return !lastRate || (lastRate.rule !== "" && lastRate.value !== "");
  };

  const handleChange = () => {
    setValues(
      produce((values) => {
        const rate = values.selectedLots[formikIndex].availability.rate;

        if (!rate.dynamic) rate.dynamic = {};
        rate.dynamic.timeEnabled = !rate.dynamic.timeEnabled;
        if (!rate.dynamic.time) rate.dynamic.time = [{ rule: 360, value: "" }];
      })
    );
  };

  return (
    <BoxContent noShadow>
      <BoxHeader
        title={t(
          "eventProperties.dynamicPricing.adjustRateByTimeBeforeTheEvent"
        )}
        tooltip={t(
          "eventProperties.dynamicPricing.adjustRateByTimeBeforeTheEventMessage"
        )}
        selected={rate.dynamic?.timeEnabled}
        handleChange={handleChange}
        isOpened={rate.dynamic?.timeEnabled}
      />
      <Collapse isOpened={rate.dynamic?.timeEnabled}>
        <BoxContent.Content>
          <Table
            columns={columns}
            data={data}
            spacing
            headerAppearance="transparent"
          />
          <Button
            appearance="primary"
            size="small"
            onMouseDown={() => addNewRate()}
            disabled={!canAddNewRate()}
            justifyContent="center"
            className={classNames(styles.button, styles.addRateButton)}
            rounded
          >
            <Icon icon="plus" pull={null} size="h3" appearance="white" />
          </Button>
        </BoxContent.Content>
      </Collapse>
    </BoxContent>
  );
};

export default AdjustRateByTimeBeforeTheEvent;
