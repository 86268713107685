import * as yup from "yup";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { getTranslator } from "../services/languages";

export const validatePhoneNumber = ({ phoneNumber, countryCode }) => {
  if (!phoneNumber) {
    return true;
  }

  const country = countryCode.toUpperCase();
  const number = parsePhoneNumberFromString(phoneNumber, country);

  if ((number && number.country !== country) || !number) {
    return false;
  }

  return number.isValid();
};

export const validatePhoneNumberSchema = (countryCode) => {
  const t = getTranslator();

  return yup
    .string()
    .required()
    .test({
      name: "phoneNumber",
      message: t("commonErrors.validation.phoneNumber"),
      test: (phoneNumber) => {
        return validatePhoneNumber({ phoneNumber, countryCode });
      },
    });
};
