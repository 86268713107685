import React, { useEffect, useState, useRef } from "react";
import moment from "moment-timezone";
import { Grid, Icon } from "@citifyd/style";

import { formatPhoneNumber } from "../../shared/utils/phone-number-formatting";
import { useService, useTranslator } from "../../shared/react/hooks";
import { parseUsers } from "./utils";
import UsersList from "./components/UsersList";
import UsersListHeader from "./components/UsersListHeader";
import UsersStats from "./components/UsersStats";
import PageHeader from "../../shared/react/components/PageHeader";
import HighlightText from "../../shared/react/components/HighlightText";
import GuestBadge from "../../shared/react/components/GuestBadge";
import styles from "./Users.module.scss";
import { getAllUsers } from "../../shared/api";
import { useAuthentication } from "../../shared/react/contexts/authentication";

const PER_PAGE = 40;

const Users = () => {
  const $state = useService("$state");
  const t = useTranslator();
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [ended, setEnded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { loggedUserIsAdmin, user: loggedUser } = useAuthentication();
  const [searchQuery, setSearchQuery] = useState("");
  const timer = useRef(null);
  const firstRender = useRef(true);
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortReverse, setSortReverse] = useState(true);
  const [data, setData] = useState([]);

  const reloadUsers = () => {
    setData([]);
    setEnded(false);
    setCurrentPage(0);
    setHasError(false);
    loadUsers(0, PER_PAGE, true);
  };

  useEffect(() => {
    if (currentPage > 0 && (!isLoading || !ended)) {
      loadUsers(currentPage * PER_PAGE, PER_PAGE);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!firstRender.current) {
      if (timer) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => reloadUsers(), 500);
    }
  }, [searchQuery]);

  useEffect(() => {
    reloadUsers();
    firstRender.current = false;
  }, [sortBy, sortReverse]);

  const formatDate = (type, date) =>
    t(`defaultFormats.${type}`, {
      date: moment(date).tz(loggedUser?.timezoneName),
    });

  const renderUserName = (user) => (
    <div className={styles.username}>
      {user.formattedName && (
        <HighlightText
          flags="-i"
          text={user.formattedName}
          highlight={searchQuery}
        />
      )}
      {user.isGuest && <GuestBadge />}{" "}
      {loggedUserIsAdmin && user.status === "blocked" && (
        <Icon appearance="error" icon={"ban"} style={{ marginLeft: 4 }} />
      )}
    </div>
  );

  const handleClick = (user) => {
    if (user.nonCustomer) {
      $state.go("non-customer-user", {
        id: user.id,
        phoneCountryCode: user.phoneCountryCode,
        phoneNumber: user.phoneNumber,
      });
    } else {
      $state.go("user", { id: user.id }, { enableBackLink: true });
    }
  };

  const loadUsers = async (offset, limit, reload) => {
    setIsLoading(true);

    const filledSearchQuery = searchQuery?.trim() !== "";

    const options = {
      offset,
      limit,
      order: sortBy,
      reverse: sortReverse,
      ...(filledSearchQuery && { search: searchQuery?.trim() }),
    };

    try {
      const response = await getAllUsers({ params: options });
      const newUsers = parseUsers(response, t);
      const newUsersFormatted = newUsers.map((user) => {
        const regDate = formatDate("date", user.createdAt);
        const regTime = formatDate("time", user.createdAt);
        const ticketsAmount = user.reservedParkingPassesCount
          ? user.ticketsPurchasedCount + user.reservedParkingPassesCount
          : user.ticketsPurchasedCount;

        return {
          onClick: () => handleClick(user),
          regDate: {
            value: regDate,
            verticalAlign: "middle",
          },
          regTime: {
            value: regTime,
            verticalAlign: "middle",
          },
          name: {
            value: renderUserName(user),
            verticalAlign: "middle",
            noPadding: true,
          },
          phone: {
            value: (
              <HighlightText
                flags="-i"
                text={formatPhoneNumber(
                  user.phoneNumber,
                  user.phoneCountryCode
                )}
                highlight={searchQuery}
              />
            ),
            verticalAlign: "middle",
          },
          tickets: {
            value: ticketsAmount || "0",
            textAlign: "right",
            verticalAlign: "middle",
          },
          amountOfReservations: {
            value: user.reservationsCount || "0",
            textAlign: "right",
            verticalAlign: "middle",
          },
        };
      });

      const newData = [...(reload ? [] : data), ...newUsersFormatted];

      setData(newData);

      if (response.length < PER_PAGE) {
        setEnded(true);
      }
    } catch (err) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          variant="h1"
          component="h1"
          title={t("users.pageTitle")}
          weight="300"
        />
      </PageHeader>
      <UsersStats />
      <UsersListHeader
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        sortBy={sortBy}
        sortReverse={sortReverse}
      />
      <UsersList
        sortBy={sortBy}
        sortReverse={sortReverse}
        setSortBy={setSortBy}
        setSortReverse={setSortReverse}
        setCurrentPage={setCurrentPage}
        data={data}
        ended={ended}
        isLoading={isLoading}
        hasError={hasError}
      />
    </Grid>
  );
};

export default Users;
