"use strict";

import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Icon, Text, Grid, ToggleButton, Button, Modal } from "@citifyd/style";

import { useTranslator } from "../../../../shared/react/hooks";
import { calculateLotStatus } from "../../../../shared/utils/status-calculation";

import BoxContent from "../../../../shared/react/components/BoxContent";
import Address from "../../../../shared/react/components/Address";
import RouterLink from "../../../../shared/react/components/RouterLink";

import styles from "./Lot.module.scss";
import { copyProperty, updateProperty } from "../../../../shared/api";
import { showErrorAlert } from "../../../../shared/services/helper";
import { getService } from "../../../../shared/react/utils";

function Lot({ lot, updateLotOnList, canEditLot }) {
  const [showingCopyModal, setShowingCopyModal] = useState(false);
  const types = [
    { value: "covered", label: "Covered" },
    { value: "uncovered", label: "Uncovered" },
    { value: "garage", label: "Garage" },
  ];

  return (
    <>
      {showingCopyModal && (
        <CopyModal
          types={types}
          onClose={() => setShowingCopyModal(false)}
          lot={lot}
        />
      )}
      <BoxContent className={styles.lotBox}>
        <BoxContent.Header title={lot.name}>
          <BoxContent.HeaderActions>
            <Text
              component="a"
              href="#"
              onClick={(e) => {
                setShowingCopyModal(true);
                e.preventDefault();
              }}
            >
              <Icon icon="copy" size="h2" appearance="white" pull="right" />
            </Text>
            {canEditLot && <EditLotLink lot={lot} />}

            <Button
              justifyContent="right"
              appearance="transparent"
              onClick={() => editPermission(edge.node.id)}
            >
              <Icon icon="pencil-alt" pull="none" />
            </Button>
          </BoxContent.HeaderActions>
        </BoxContent.Header>
        <BoxContent.Content>
          <LotInfo lot={lot} types={types} />
          <LotStatus lot={lot} updateLotOnList={updateLotOnList} />
        </BoxContent.Content>
      </BoxContent>
    </>
  );
}
const CopyModal = ({ onClose, lot, types }) => {
  const t = useTranslator();
  const handleCopy = async (type) => {
    const newLot = await copyProperty(lot.id);

    const nameWithoutType = types
      .reduce((acc, t) => acc.replace(`(${t.label})`, ""), newLot.name)
      .trim();

    await updateProperty({
      id: newLot.id,
      name: `${nameWithoutType} (${type.label})`,
      amenityCodes: [
        ...newLot.amenityCodes.filter(
          (a) => !types.map((type) => type.value).includes(a)
        ),
        type.value,
      ],
    });

    const $state = getService("$state");
    $state.go("edit-property", { lotId: newLot.id });
  };
  return (
    <Modal open={true} onClose={onClose}>
      <Modal.Title textAlign="left">{t("accountInfo.copy")}</Modal.Title>

      <Text gutterBottom>
        <div
          dangerouslySetInnerHTML={{
            __html: t("accountInfo.youAreCopyingProperty", { name: lot.name }),
          }}
        />
      </Text>

      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        {types
          .filter((type) => !lot.amenityCodes.includes(type.value))
          .map((type) => (
            <div>
              <Button
                size="small"
                justifyContent="center"
                onClick={() => handleCopy(type)}
                fullWidth
              >
                {type.label}
              </Button>
            </div>
          ))}
      </div>
    </Modal>
  );
};

function EditLotLink({ lot }) {
  return (
    <RouterLink
      state="edit-property"
      params={{ lotId: lot.id }}
      className={styles.editLink}
    >
      <Icon icon="pencil-alt" appearance="white" />
    </RouterLink>
  );
}

function LotInfo({ lot, types }) {
  const t = useTranslator();

  const name =
    types.find((type) => lot.amenityCodes.includes(type.value))?.label ||
    "Not specified";

  return (
    <Fragment>
      <Grid.Row>
        <Grid.Col className={styles.lotInfo}>
          <Address className={styles.address} entity={lot} format="full" />
          <LotImage lot={lot} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col>
          <Text>{t("accountInfo.lots.statuses.type", { name })}</Text>
        </Grid.Col>
      </Grid.Row>
    </Fragment>
  );
}

function LotImage({ lot }) {
  return <img src={lot.photoUrl} className={styles.photo} />;
}

function LotStatus({ lot: inputLot, updateLotOnList }) {
  const t = useTranslator();

  const [lot, setLot] = useState();
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => setLot(inputLot), [inputLot]);

  const status = useMemo(() => lot && calculateLotStatus(lot), [lot]);
  const toggleSelected = status === "active";
  const toggleVisible = ["active", "inactive"].includes(status);

  const toggle = useCallback(async () => {
    const isVisible = !lot.isVisible;

    setIsUpdating(true);
    setLot({ ...lot, isVisible: isVisible });

    try {
      const response = await updateProperty({ id: lot.id, isVisible });
      updateLotOnList(lot.id, response);
    } catch (err) {
      console.log("error", err);
      showErrorAlert(err.data);
      setLot(inputLot);
    } finally {
      setIsUpdating(false);
    }
  }, [lot, updateLotOnList]);

  if (!lot) {
    return null;
  }

  return (
    <div className={styles.visibilityToggle}>
      {toggleVisible && (
        <ToggleButton
          selected={toggleSelected}
          onChange={toggle}
          disabled={isUpdating}
        />
      )}
      {isUpdating && (
        <Text variant="subtitle">
          {t("accountInfo.lots.statuses.updating")}
        </Text>
      )}
      {!isUpdating && (
        <Text variant="subtitle">
          {t(`accountInfo.lots.statuses.${status}`)}
        </Text>
      )}
    </div>
  );
}

export default Lot;
