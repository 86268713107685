import React from "react";
import moment from "moment-timezone";
import { Select } from "@citifyd/style";
import { get, forEach, findIndex, filter, sortBy } from "lodash";
import { getService } from "../../../../../shared/react/utils";
import { getTranslator } from "../../../../../shared/services/languages";

import { getVehicleLicense } from "../../../../../shared/utils/vehicles";
import HighlightText from "../../../../../shared/react/components/HighlightText";
import {
  getEntriesForEventGate,
  getTicketsWithNoEntries,
  updateParkerGateEntry,
} from "../../../../../shared/api";
import Permissions from "../../../../../shared/services/permissions";

const removeSystemGates = (gates) =>
  filter(gates, (gate) => !gate.isSystemGate);

const sortLotsForGatesDropdown = ({ selectedEventGate }) => {
  // first sort by name
  const lots = sortBy(selectedEventGate.allLots, "name");

  // put lot of selected gate in first position
  const selectedLotIndex = findIndex(lots, {
    id: selectedEventGate.lot?.id,
  });

  if (selectedLotIndex !== -1) {
    var selectedLot = lots[selectedLotIndex];
    lots.splice(selectedLotIndex, 1);
    lots.unshift(selectedLot);
  }

  return lots;
};

const entriesBySearch = ({ entries, event, search }) => {
  const t = getTranslator();

  const filters = (entry) =>
    [
      entry.vehicle?.licensePlate || entry.vehicle?.license,
      entry.user?.name,
      entry.entryTime &&
        t("defaultFormats.time", {
          date: moment(entry.entryTime).tz(event.timezoneName),
        }),
    ].map((item) => item?.toLowerCase());

  return entries.filter((entry) =>
    !search
      ? entry
      : filters(entry)?.join(",")?.indexOf(search.toLowerCase()) > -1
  );
};

export const getEntriesWithChangedGates = (entries) =>
  filter(entries, (entry) => entry.changeEventGate);

export const updateSelectedGates = (entries) => {
  const changedEntries = getEntriesWithChangedGates(entries);
  const $stateParams = getService("$stateParams");

  let promise = Promise.resolve();

  forEach(changedEntries, (entry) => {
    promise = promise
      .then(() =>
        updateParkerGateEntry(
          $stateParams?.id,
          entry.user.id,
          entry.changeEventGate
        )
      )
      .catch((err) => err);
  });

  return promise.catch((err) => err);
};

export const getEntriesCount = ({ serverData, type }) =>
  get(serverData, "amountOf" + type + "Entries", "");

export const getUpdatedEntries = ({ currentEntries, newEntries }) =>
  newEntries.map((entry) => {
    const index = findIndex(currentEntries, { id: entry.id });

    if (index !== -1) {
      const changeEventGate = currentEntries[index].changeEventGate;

      return {
        ...entry,
        changeEventGate,
      };
    } else {
      return entry;
    }
  });

export const loadGateInfo = ({ lastUpdate, selectedEventGate }) => {
  const $stateParams = getService("$stateParams");
  const eventId = $stateParams?.id;
  const options = {};
  let requestPromise;

  if (lastUpdate) {
    options.updatedAfter = lastUpdate;
  }

  if (selectedEventGate.id === -1) {
    requestPromise = getTicketsWithNoEntries(
      eventId,
      selectedEventGate.lot.id
    ).then((tickets) => {
      forEach(tickets, (ticket) => {
        if (ticket.reservations.length) {
          ticket.vehicle = ticket.reservations[0].vehicle;
        }
      });

      return {
        entries: tickets,
        amountOfCitifydEntries: tickets.length,
      };
    });
  } else {
    requestPromise = getEntriesForEventGate(eventId, selectedEventGate.id, {
      params: options,
    });
  }

  return requestPromise;
};

export const getSystemGateData = ({
  entries,
  search,
  event,
  permissions,
  selectedEventGate,
  onGateChange,
}) => {
  const t = getTranslator();
  const canSeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  const dropdownOptions = sortLotsForGatesDropdown({
    selectedEventGate,
  })
    ?.filter((lot) => removeSystemGates(lot.gates).length)
    ?.map((lot) => ({
      label: lot.name,
      options: lot.gates
        ?.filter((gate) => !gate.isSystemGate)
        ?.map((gate) => ({
          label: gate.eventGate.name,
          value: gate.eventGate.id,
        })),
    }));

  const columns = [
    {
      key: "entryTime",
      value: t("event.entryTime"),
      width: "20%",
      nowrap: "nowrap",
    },
    {
      key: "licensePlate",
      value: t("event.licensePlate"),
      width: "20%",
      nowrap: "nowrap",
    },
    { key: "name", value: t("event.name"), width: "35%", nowrap: "nowrap" },
    {
      key: "assignGate",
      value: t("event.assignGate"),
      width: "25%",
      nowrap: "nowrap",
    },
  ];

  const data = entriesBySearch({ entries, event, search })?.map((entry) => ({
    entryTime: {
      value: entry.entryTime ? (
        <HighlightText
          flags="-i"
          text={t("defaultFormats.time", {
            date: moment(entry.entryTime).tz(event.timezoneName),
          })}
          highlight={search}
        />
      ) : (
        "—"
      ),
      verticalAlign: "middle",
    },
    licensePlate: {
      value: getVehicleLicense(entry.vehicle) ? (
        <HighlightText
          flags="-i"
          text={getVehicleLicense(entry.vehicle)}
          highlight={search}
        />
      ) : (
        "—"
      ),
      verticalAlign: "middle",
    },
    ...(canSeeUserInformation && {
      name: {
        value: (
          <HighlightText
            flags="-i"
            text={entry.user?.name}
            highlight={search}
          />
        ),
        verticalAlign: "middle",
      },
    }),
    assignGate: (
      <Select
        fullWidth
        onChange={(e) =>
          onGateChange({ entryId: entry.id, gateId: e.target?.value })
        }
        value={entry.changeEventGate || ""}
        options={[
          { label: t("event.selectGate"), value: "" },
          ...dropdownOptions,
        ]}
      />
    ),
  }));

  return {
    columns,
    data,
  };
};

export const getBeaconGateData = ({ entries, search, event, permissions }) => {
  const t = getTranslator();
  const canSeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);

  const columns = [
    {
      key: "entryTime",
      value: t("event.entryTime"),
      width: "20%",
      nowrap: "nowrap",
    },
    {
      key: "licensePlate",
      value: t("event.licensePlate"),
      width: "20%",
      nowrap: "nowrap",
    },
    { key: "name", value: t("event.name"), width: "35%", nowrap: "nowrap" },
  ];

  const data = entriesBySearch({ entries, event, search })?.map((entry) => ({
    entryTime: {
      value: entry.entryTime ? (
        <HighlightText
          flags="-i"
          text={t("defaultFormats.time", {
            date: moment(entry.entryTime).tz(event.timezoneName),
          })}
          highlight={search}
        />
      ) : (
        "—"
      ),
      verticalAlign: "middle",
    },
    licensePlate: {
      value: getVehicleLicense(entry.vehicle) ? (
        <HighlightText
          flags="-i"
          text={getVehicleLicense(entry.vehicle)}
          highlight={search}
        />
      ) : (
        "—"
      ),
      verticalAlign: "middle",
    },
    ...(canSeeUserInformation && {
      name: {
        value: (
          <HighlightText flags="-i" text={entry.user.name} highlight={search} />
        ),
        verticalAlign: "middle",
      },
    }),
  }));

  return {
    columns,
    data,
  };
};
