"use strict";

import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Icon, Text, Button } from "@citifyd/style";
import { isEmpty } from "lodash";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import FormikProvider from "../../../../shared/react/components/FormikProvider";
import Table from "../../../../shared/react/components/Table";
import BoxContent from "../../../../shared/react/components/BoxContent";
import RouterLink from "../../../../shared/react/components/RouterLink";
import StickyFooter from "../../../../shared/react/components/StickyFooter";
import useLoadTracking from "../../../../shared/react/hooks/useLoadTracking";
import { getTableColumns } from "./utils.js";
import styles from "./RateGates.module.scss";

import { updateProperty } from "../../../../shared/api";
import { showErrorAlert } from "../../../../shared/services/helper";

const RateGates = ({ lot }) => {
  const t = useTranslator();
  const $state = useService("$state");

  const { isLoading, loadingMessage, trackLoading, untrackLoading } =
    useLoadTracking({
      saveGateInfo: {
        message: t("addProperty.loadingMessages.savingGateInfo"),
      },
    });

  const formikbag = useFormik({
    initialValues: {
      gates: lot.gates
        ?.filter((gate) => !gate.isSystemGate)
        .map((gate) => ({
          name: gate.name,
          beaconUids: gate.beaconUids?.map((uid) => ({
            value: uid,
            label: uid,
          })),
          type: gate.type,
          armedGate: gate.armedGate,
          reportsStatus: gate.reportsStatus,
          hasBarrier: gate.hasBarrier,
          automaticActivation: gate.automaticActivation,
          hasAttendant: gate.hasAttendant,
          id: gate.id,
        })),
    },
    validationSchema: yup.object().shape({
      gates: yup.array().of(
        yup.object().shape({
          name: yup.string().required(),
        })
      ),
    }),
    onSubmit: async () => {
      formikbag.setFieldValue("gates", [
        ...gates,
        {
          name: "",
          beaconUids: [],
          isSystemGate: false,
          type: "ingress",
          armedGate: false,
          reportsStatus: false,
          hasBarrier: false,
          automaticActivation: false,
          hasAttendant: false,
        },
      ]);
    },
  });

  const { handleSubmit, values } = formikbag;

  const gates = values?.gates;

  const adjustGates = () => {
    return gates.map((item) => ({
      ...item,
      beaconUids: (item.beaconUids || [])
        .map((gate) => gate.value.trim())
        .filter((gate) => gate !== ""),
    }));
  };

  const onSave = () => {
    trackLoading("saveGateInfo");

    const data = {
      id: lot.id,
      gates: adjustGates(gates),
    };

    updateProperty(data)
      .then(() => {
        $state.go("properties");
      })
      .catch((response) => {
        untrackLoading("saveGateInfo");
        showErrorAlert(response.data);
      });
  };

  const removeGateAt = ({ gateId, index }) => {
    if (gateId && !window.confirm(t("addProperty.gates.confirmRemoval"))) {
      return false;
    }

    if (gateId) {
      const gatesUpdated = gates.map((item) => {
        if (item.id === gateId) {
          return {
            ...item,
            destroy: true,
          };
        } else {
          return item;
        }
      });

      formikbag.setFieldValue("gates", gatesUpdated);
    } else {
      formikbag.setFieldValue(
        "gates",
        gates.filter((_, i) => index !== i)
      );
    }
  };

  const tableData = gates
    ?.map((item, index) => ({
      name: (
        <div className={styles.formField}>
          <FormikField
            name={`gates[${index}].name`}
            as={FIELD_TYPE.INPUT}
            placeholder={t("addProperty.placeholders.gateName")}
          />
        </div>
      ),
      beaconUids: (
        <div className={styles.formField}>
          <FormikField
            name={`gates[${index}].beaconUids`}
            options={item.beaconUids}
            as={FIELD_TYPE.CREATABLE_SELECT}
          />
        </div>
      ),
      type: (
        <div className={styles.formField}>
          <FormikField
            name={`gates[${index}].type`}
            as={FIELD_TYPE.SELECT}
            options={[
              { label: t("addProperty.ingress"), value: "ingress" },
              { label: t("addProperty.egress"), value: "egress" },
            ]}
          />
        </div>
      ),
      gate: (
        <FormikField
          name={`gates[${index}].armedGate`}
          as={FIELD_TYPE.CHECKBOX}
        />
      ),
      reportsStatus: (
        <FormikField
          name={`gates[${index}].reportsStatus`}
          as={FIELD_TYPE.CHECKBOX}
        />
      ),
      hasBarrier: (
        <FormikField
          name={`gates[${index}].hasBarrier`}
          as={FIELD_TYPE.CHECKBOX}
        />
      ),
      automaticActivation: (
        <FormikField
          name={`gates[${index}].automaticActivation`}
          as={FIELD_TYPE.CHECKBOX}
        />
      ),
      hasAttendant: (
        <FormikField
          name={`gates[${index}].hasAttendant`}
          as={FIELD_TYPE.CHECKBOX}
        />
      ),
      remove: (
        <Button
          appearance="transparent"
          onClick={() => removeGateAt({ gateId: item.id, index })}
        >
          <Icon size="h2" icon="trash" />
        </Button>
      ),
      empty: "",
      destroy: item.destroy,
    }))
    .filter((item) => !Boolean(item.destroy));

  if (isLoading) {
    return (
      <LoadingManager isLoading={isLoading} loadingMessage={loadingMessage} />
    );
  }

  return (
    <FormikProvider value={formikbag}>
      {!isEmpty(lot) && (
        <>
          <BoxContent>
            <BoxContent.Header
              title={t("addProperty.gates.title", { lotName: lot.name })}
            >
              <BoxContent.HeaderActions>
                <Button appearance="transparent" onClick={() => handleSubmit()}>
                  <Icon icon="plus" size="h2" appearance="white" />
                </Button>
              </BoxContent.HeaderActions>
            </BoxContent.Header>
            <BoxContent.Content>
              <Table columns={getTableColumns()} data={tableData} cleanMode />
            </BoxContent.Content>
          </BoxContent>

          <StickyFooter isBlocked={false}>
            <RouterLink
              state="edit-property"
              params={{ lotId: lot.id }}
              className={styles.backLink}
            >
              <Icon size="subtitle" icon="chevron-left" appearance="white" />
              <Text variant="subtitle" appearance="white">
                {t("commonButtons.goBackWithoutSaving")}
              </Text>
            </RouterLink>

            <Button shadow extraPadding uppercase onMouseDown={() => onSave()}>
              {t("commonButtons.saveAndPost")}
            </Button>
          </StickyFooter>
        </>
      )}
    </FormikProvider>
  );
};

export default RateGates;
