"use strict";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { method, mapValues } from "lodash";

import ApolloClient from "../../../shared/services/apolloClient";
import {
  getReservedParkingPass,
  getReservedParkingPassDefaultRefundOptions,
  getReservedParkingPassRefundPreview,
} from "../utils";
import { useTranslator } from "../../../shared/react/hooks";
import { getService } from "../../../shared/react/utils";

import ADMIN_CANCEL_AND_REFUND_RESERVED_PARKING_PASS from "../../../graphql/reservedParkingPasses/mutations/AdminCancelAndRefundReservedParkingPass";
import { showErrorAlert } from "../../../shared/services/helper";

export const ReservedParkingPassRefundContext = createContext();

export const useReservedParkingPassRefundContext = () =>
  useContext(ReservedParkingPassRefundContext);

export default function ReservedParkingPassRefundContextProvider({
  reservedParkingPassId,
  children,
}) {
  const t = useTranslator();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [reservedParkingPass, setReservedParkingPass] = useState(null);
  const [defaultRefundOptions, setDefaultRefundOptions] = useState(null);
  const [refundOptions, setRefundOptions] = useState(null);
  const [refundPreview, setRefundPreview] = useState(null);

  // Load reservedParkingPass
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const reservedParkingPass = await getReservedParkingPass(
          reservedParkingPassId
        );
        setReservedParkingPass(reservedParkingPass);
      } catch (err) {
        setHasError(true);
      }

      try {
        const reservedParkingPassDefaultOptions =
          await getReservedParkingPassDefaultRefundOptions(
            reservedParkingPassId
          );

        setDefaultRefundOptions(
          mapValues(reservedParkingPassDefaultOptions, method("toLowerCase"))
        );
      } catch (err) {
        setHasError(true);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function getRefundPreview(reservedParkingPass, refundOptions) {
      try {
        const reservedParkingPassRefundPreview =
          await getReservedParkingPassRefundPreview({
            reservedParkingPass,
            refundOptions,
          });
        setRefundPreview(reservedParkingPassRefundPreview);
        setIsLoading(false);
      } catch (err) {
        setHasError(true);
        setIsLoading(false);
      }
    }

    if (reservedParkingPass && defaultRefundOptions) {
      if (reservedParkingPass.cancelledAt) {
        redirectToReservedParkingPass(reservedParkingPassId);
      }

      const refundOptions = {
        ...defaultRefundOptions,
        reason: "",
        requestedByPhone: false,
        requestedByEmail: false,
      };

      setRefundOptions(refundOptions);
      getRefundPreview(reservedParkingPass, refundOptions);
    }
  }, [reservedParkingPass, defaultRefundOptions]);

  const requestRefund = useCallback(() => {
    setIsLoading(true);
    setLoadingMessage(t("refundPages.requestingRefund"));

    ApolloClient()
      .mutate({
        mutation: ADMIN_CANCEL_AND_REFUND_RESERVED_PARKING_PASS,
        variables: {
          passId: parseInt(reservedParkingPassId),
          ...refundOptions,
          refunder: refundOptions?.refunder?.toUpperCase(),
          feeRetentionMethod: refundOptions?.feeRetentionMethod?.toUpperCase(),
          stripeFeePayer: refundOptions?.stripeFeePayer?.toUpperCase(),
        },
      })
      .then(() => {
        redirectToReservedParkingPass(reservedParkingPassId);
      })
      .catch((err) => {
        setIsLoading(false);
        showErrorAlert(err?.data);
      });
  }, [reservedParkingPassId, refundOptions]);

  const redirectToReservedParkingPass = useCallback(() => {
    const $state = getService("$state");
    $state.go(
      "reserved-parking-pass",
      { id: reservedParkingPassId },
      { location: "replace" }
    );
  }, [reservedParkingPassId]);

  return (
    <ReservedParkingPassRefundContext.Provider
      value={{
        reservedParkingPassId,
        reservedParkingPass,
        isLoading,
        loadingMessage,
        hasError,
        refundOptions,
        setRefundOptions,
        refundPreview,
        redirectToReservedParkingPass,
        requestRefund,
      }}
    >
      {children}
    </ReservedParkingPassRefundContext.Provider>
  );
}
