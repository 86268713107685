"use strict";

import React from "react";
import classNames from "classnames";

import { useTranslator } from "../../hooks";
import exportCSVImage from "../../../../images/Export to CSV icon@3x.png";
import styles from "./CSVDownload.module.scss";

const CSVDownload = ({ onClick, disabled, className }) => {
  const t = useTranslator();

  return (
    <a
      onClick={() => (disabled ? {} : onClick())}
      disabled={disabled}
      className={classNames(styles.icon, className, {
        [styles.disabled]: disabled,
      })}
    >
      <img
        src={exportCSVImage}
        alt={t("commonButtons.exportAsCsv")}
        title={t("commonButtons.exportAsCsv")}
      />
    </a>
  );
};

export default CSVDownload;
