"use strict";

import i18next from "i18next";
import i18nextXHRBackend from "i18next-xhr-backend";
import {
  getAcceptedLanguage,
  getAvailableLanguages,
  DEFAULT_LANGUAGE,
} from "./i18next-helper";

import CurrencyFormatter from "@citifyd/currency-formatter";
import { getOrdinal } from "@citifyd/ordinal";

import moment from "moment-timezone";
import _ from "lodash";

function loadLocales(url, options, callback, data) {
  try {
    let locale = require(`../../locales/${url}.json`);

    callback(locale, { status: "200" });
  } catch (e) {
    callback(null, { status: "404" });
  }
}

function getCookieValue(a) {
  var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : null;
}

function getAcceptedBrowserLanguage() {
  const browserLanguage =
    getCookieValue("citifyd_language") ||
    navigator.language ||
    navigator.userLanguage ||
    "en";
  return getAcceptedLanguage(browserLanguage);
}

const getFallbackLanguage = (() => {
  const availableLanguages = getAvailableLanguages();
  const defaultLanguageCode = DEFAULT_LANGUAGE.split("-")[0].toLowerCase();
  const cache = {};

  return function getFallbackLanguage(language) {
    if (!language) {
      return DEFAULT_LANGUAGE;
    }

    language = language.toLowerCase();

    if (!cache[language]) {
      let [code, region] = language.split("-");
      code = code.toLowerCase();
      region = (region || "").toUpperCase();

      const fallback = availableLanguages
        .filter(
          (language) => language.code === code && language.region !== region
        )
        .map((language) => `${language.code}-${language.region}`);

      if (code !== defaultLanguageCode) {
        fallback.push(DEFAULT_LANGUAGE);
      }

      cache[language] = fallback;
    }

    return cache[language];
  };
})();

/**
 * i18next.js
 *
 * Configures support for i18next.
 */
(function () {
  const acceptedBrowserLanguage = getAcceptedBrowserLanguage();

  // initialize i18next backend
  i18next.use(i18nextXHRBackend);

  // add currency processor
  i18next.use({
    type: "postProcessor",
    name: "processCurrency",
    process: (value, key, options, translator) => {
      return value.replace(/\{\$([^$]+)\$\}/g, (_, content) => {
        let [variable, currencyOption] = content
          .split(",")
          .map((x) => x.trim());

        if (!currencyOption) {
          currencyOption = "currency";
        }

        const amount = options[variable];
        return CurrencyFormatter.format(amount, {
          currency: options[currencyOption],
          language: options.lng,
        });
      });
    },
  });

  // configure initial i18next configuration
  i18next.init({
    debug: process.env.NODE_ENV === "development",
    lng: acceptedBrowserLanguage,
    fallbackLng: getFallbackLanguage,

    // Adds support for rendering a currency value using {$variable$}
    // For it to work, the `currency` variable needs to be defined.
    // e.g. t('key', { amount: 1500, currency: 'usd' })
    // where key = `total: {$amount$}`
    // renders "total: $15.00".
    postProcess: ["processCurrency"],

    backend: {
      loadPath: "{{lng}}",
      parse: (data) => data,
      ajax: loadLocales,
    },

    interpolation: {
      // Deactivate HTML escape since angular already takes care of that.
      // If escaping is enabled, i18next ends up escaping the slash character,
      // causing a M/D/YYYY date to render as M&#x2F;D&#x2F;YYYY
      escapeValue: false,

      // Formatters defined:
      //
      // If the value is a date, you can use {{date, dateFormat:M/D/YYYY}} in your
      // locale file to select the date format. For more details on moment.js formatting
      // see the docs: https://momentjs.com/docs/#/displaying/
      //
      // If you have a number variable and you want to show it in ordinal format, use
      // {{num, ordinal}}. If the language used has particularities such as gender for
      // words (portuguese has), you can pass options to it in this format:
      // {{num, ordinal:gender=female}} (key=val&key2=val2)
      format: (value, format, lng) => {
        if (format.startsWith("dateFormat:")) {
          if (!moment.isMoment(value)) {
            value = moment(value);
          }
          return value
            .locale(lng)
            .format(format.replace("dateFormat:", "").trim());
        }

        if (format === "ordinal" || format.startsWith("ordinal:")) {
          let options = {};

          // options passed?
          if (format.startsWith("ordinal:")) {
            const optionsQueryString = format.replace("ordinal:", "").trim();
            const optionPairs = optionsQueryString.split("&").map((item) => {
              const parts = item.split("=");
              return [parts[0], parts.slice(1).join("=")];
            });
            options = _.zipObject(optionPairs);
          }

          return getOrdinal(value, lng.split("-")[0], options);
        }

        return value;
      },
    },

    useCookie: false,
    useLocalStorage: false,
  });

  i18next.on("initialized", (options) => {
    moment.locale(options.lng);
  });

  i18next.on("languageChanged", (lng) => {
    moment.locale(lng);
  });
})();
