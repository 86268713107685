import gql from "graphql-tag";
import ReservedParkingPass from "../fragments/ReservedParkingPassesFragment";

export default gql`
  query reservedParkingPassFeeBreakdown($passId: Int!) {
    reservedParkingPassFeeBreakdown(passId: $passId) {
      reservedParkingPass {
        ...ReservedParkingPass
      }
      firstStep {
        availabilityAllocationsWithPriceCalculation {
          priceCalculation {
            startTime
            endTime
            total
            rate {
              type
              value
              maxValue
              ruleName
            }
          }
        }
        citifydFee
        totalAmount
      }
      finalResult {
        amountCharged
      }
    }
  }
  ${ReservedParkingPass}
`;
