import React from "react";
import { Select } from "@citifyd/style";

import {
  onPropertiesChange,
  getLotsByCurrency,
  getCurrencyOfSelectedLots,
} from "../../utils";
import { useTranslator } from "../../../../shared/react/hooks";

const MultiplePropertiesSelector = ({
  options,
  setSelection,
  selection,
  setSelectedLots,
  selectedLots,
}) => {
  const t = useTranslator();

  return (
    <Select
      multiple
      searchable
      fullWidth
      allItemsLabel={t("reports.commonFilter.fields.allProperties")}
      allItemsOption={t("reports.commonFilter.fields.allProperties")}
      name="lots"
      labelSize="small"
      label={t("reports.commonFilter.fields.property")}
      options={options}
      maxMenuHeight={300}
      isOptionDisabled={(option) =>
        option?.currency &&
        getCurrencyOfSelectedLots(selectedLots) &&
        option?.currency !== getCurrencyOfSelectedLots(selectedLots)
      }
      value={selectedLots}
      onChange={(items) => {
        setSelection(
          onPropertiesChange({
            items: getLotsByCurrency(items),
            selection,
          })
        );
        setSelectedLots(getLotsByCurrency(items));
      }}
    />
  );
};

export default MultiplePropertiesSelector;
