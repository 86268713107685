import { last } from "lodash";
import moment from "moment-timezone";
import { getTranslatedDayName } from "../../../shared/services/helper";
import { getTranslator } from "../../../shared/services/languages";

export const getSelectedWeekDaysString = ({ selectedDays, type = "all" }) => {
  const t = getTranslator();

  if (!selectedDays) {
    return "";
  }

  let days, names;

  if (type === "weekend") {
    days = ["sat", "sun"];
    names = [getTranslatedDayName("Saturday"), getTranslatedDayName("Sunday")];
  } else if (type === "weekday") {
    days = ["mon", "tue", "wed", "thu", "fri"];
    names = [
      getTranslatedDayName("Monday"),
      getTranslatedDayName("Tuesday"),
      getTranslatedDayName("Wednesday"),
      getTranslatedDayName("Thursday"),
      getTranslatedDayName("Friday"),
    ];
  } else {
    days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    names = [
      getTranslatedDayName("Monday"),
      getTranslatedDayName("Tuesday"),
      getTranslatedDayName("Wednesday"),
      getTranslatedDayName("Thursday"),
      getTranslatedDayName("Friday"),
      getTranslatedDayName("Saturday"),
      getTranslatedDayName("Sunday"),
    ];
  }

  let items = [];
  const list = days.map((day) => selectedDays[day]);

  list.forEach((value, index) => {
    if (value) {
      const name = names[index];
      const lastItem = last(items);

      if (lastItem) {
        lastItem.end = name;
      } else {
        items.push({ start: name, end: null });
      }
    } else {
      items.push(null);
    }
  });

  items = items.filter((item) => item);

  items = items.map((item) => {
    if (item.end) {
      return t("addProperty.pricing.summary.weekdayRange", {
        firstWeekday: item.start,
        lastWeekday: item.end,
      });
    } else {
      return item.start;
    }
  });

  return items.join(", ");
};

export const getSummaryTimeRange = ({ startTime, endTime }) => {
  const t = getTranslator();

  const getTimeObject = (str) => {
    const parts = str.split(":");
    return {
      hour: parts[0],
      minute: parts[1],
      second: 0,
    };
  };

  return t("addProperty.pricing.summary.timeRange", {
    startTime: moment().set(getTimeObject(startTime)),
    endTime: moment().set(getTimeObject(endTime)),
  });
};
