"use strict";

import { uniq } from "lodash";

const getEventIdsFromSelectedBundles = (bundles) =>
  uniq(
    bundles
      .map((bundle) => bundle?.bundles?.map((item) => item.eventIds))
      .flat(2)
  );

export const getEvents = (bundles, events, selectedEvents) => {
  const eventsIds = getEventIdsFromSelectedBundles(bundles);
  const newEvents = events.filter((event) => !eventsIds.includes(event.id));
  const newSelectedEvents = selectedEvents
    ? selectedEvents.filter((event) => !eventsIds.includes(event.id))
    : [];

  return {
    newEvents,
    newSelectedEvents,
  };
};
