import gql from "graphql-tag";

export default gql`
  query {
    admin_listPermitPortals {
      total
      edges {
        node {
          id
          name
          namespace
          lots {
            lot {
              name
            }
          }
        }
      }
    }
  }
`;
