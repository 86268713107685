"use strict";

import React, { useState, useEffect, useMemo } from "react";
import { Grid, Text, Icon } from "@citifyd/style";
import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment-timezone";

import Address from "../../shared/react/components/Address";
import { formatPhoneNumber } from "../../shared/utils/phone-number-formatting";
import LoadingManager from "../../shared/react/components/LoadingManager";
import BoxContent from "../../shared/react/components/BoxContent";
import ApolloClient from "../../shared/services/apolloClient";
import Dictionary, {
  DictionaryItem,
} from "../../shared/react/components/Dictionary";
import PageHeader from "../../shared/react/components/PageHeader";
import RouterLink from "../../shared/react/components/RouterLink";
import PermitBadge from "./components/PermitBadge";
import { permitStatus } from "./utils";
import { useService, useTranslator } from "../../shared/react/hooks";
import styles from "./Permit.module.scss";
import ADMIN_GET_PERMIT_PASS from "../../graphql/permitPass/queries/AdminGetPermitPass";
import ADMIN_DELETE_PERMIT_PASS from "../../graphql/permitPass/mutations/AdminDeletePermitPass";
import PermitAlertModal from "./PermitAlertModal.jsx";
import currencyFormatter from "@citifyd/currency-formatter";
import { getCurrentLanguage } from "../../shared/services/languages";
import { getLastName } from "../../shared/utils/utils";
import PaymentMethod from "../../shared/react/components/PaymentMethod";
import { useModal } from "../../shared/react/contexts/modal";

const apolloClient = ApolloClient();

const Permit = () => {
  const t = useTranslator();
  const { openModal } = useModal();
  const $stateParams = useService("$stateParams");
  const language = getCurrentLanguage();

  const [hasError, setHasError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    t("permits.information.loading")
  );
  const { permitId, status, dateRange, search, permitterId } = $stateParams;

  const [
    fetchPermit,
    {
      data: fetchPermitData,
      loading: fetchPermitLoading,
      error: fetchPermitError,
    },
  ] = useLazyQuery(ADMIN_GET_PERMIT_PASS, {
    variables: {
      id: parseInt(permitId),
    },
    client: apolloClient,
    fetchPolicy: "network-only",
  });

  const [
    deletePermit,
    {
      data: deletePermitData,
      loading: deletePermitLoading,
      error: deletePermitError,
    },
  ] = useMutation(ADMIN_DELETE_PERMIT_PASS, {
    client: apolloClient,
  });

  useEffect(() => {
    fetchPermit();
  }, []);

  useEffect(() => {
    if (deletePermitData) {
      fetchPermit();
    }
  }, [deletePermitData]);

  useEffect(() => {
    if (fetchPermitError || deletePermitError) {
      setHasError(true);
    }
  }, [fetchPermitError, deletePermitError]);

  const isLoading = useMemo(
    () => fetchPermitLoading || deletePermitLoading,
    [fetchPermitLoading, deletePermitLoading]
  );

  const errorMessage = useMemo(
    () => (fetchPermitError ?? deletePermitError)?.message,
    [fetchPermitError, deletePermitError]
  );

  const handleDelete = async () => {
    if (window.confirm(t("permits.information.confirmCancel"))) {
      setLoadingMessage(t("permits.information.canceling"));

      deletePermit({
        variables: {
          id: parseInt(permitId),
        },
      });
    }
  };

  const onClickTryAgain = () => {
    setHasError(false);

    if (fetchPermitError) {
      fetchPermit();
    }
  };

  const editPermitAlertModal = (e) => {
    openModal(PermitAlertModal, {
      children: t("permits.form.mensagemPermitCanBeEdited"),
    });
  };
  const deletePermitAlertModal = (e) => {
    openModal(PermitAlertModal, {
      children: t("permits.form.mensagemPermitCanBeDeleted"),
    });
  };

  if (isLoading || hasError || !fetchPermitData) {
    return (
      <LoadingManager
        isLoading={isLoading}
        loadingMessage={loadingMessage}
        hasError={hasError}
        errorMessage={errorMessage}
        onClickTryAgain={onClickTryAgain}
      />
    );
  }

  const { admin_getPermitPass: permit } = fetchPermitData;

  const getDate = (date, timezoneName) => moment.tz(date, timezoneName);

  const lastNameOnCard = getLastName(permit?.card?.name);

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title
          defaultState={!permitterId ? "permits" : "user-permitters"}
          defaultParams={{ status, search, dateRange, id: permitterId }}
          weight="300"
          title={
            !permitterId
              ? t("permits.backToPermits")
              : t("permits.backToPermissions")
          }
        />
      </PageHeader>

      <BoxContent>
        <BoxContent.Header title={t("permits.permitInformation")}>
          <BoxContent.HeaderActions>
            <>
              {permit.status === "PENDING" &&
                !permit?.calculatedPrice?.total &&
                !permit?.isActive && (
                  <RouterLink
                    state="edit-permit"
                    params={{ permitId: permit.id }}
                  >
                    <Icon
                      icon="pencil-alt"
                      appearance="white"
                      size="h2"
                      className={styles.icon}
                      title={t("permits.information.edit")}
                    />
                  </RouterLink>
                )}

              {permit.status !== "EXPIRED" &&
                permit.status !== "CANCELLED" &&
                !permit?.isActive && (
                  <Icon
                    icon="trash-alt"
                    appearance="white"
                    size="h2"
                    className={styles.icon}
                    onClick={() => handleDelete()}
                    title={t("permits.information.cancel")}
                  />
                )}
            </>

            {permit?.isActive && permit?.calculatedPrice.total && (
              <>
                <Icon
                  icon="pencil-alt"
                  appearance="white"
                  size="h2"
                  className={styles.icon}
                  onClick={() => editPermitAlertModal()}
                  title={t("permits.information.edit")}
                />

                <Icon
                  icon="trash-alt"
                  appearance="white"
                  size="h2"
                  className={styles.icon}
                  onClick={() => deletePermitAlertModal()}
                  title={t("permits.information.cancel")}
                />
              </>
            )}
          </BoxContent.HeaderActions>
        </BoxContent.Header>
        <BoxContent.Content>
          <Grid.Row>
            <Grid.Col xs={12} md={6}>
              <Text component="h3" variant="h3" bold>
                {permit.lot?.name}
              </Text>
            </Grid.Col>
            <Grid.Col xs={12} md={6} align="end">
              <PermitBadge status={permit.status}>
                {t(`permits.status.${permitStatus[permit.status]}`)}
              </PermitBadge>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col xs={12} md={6}>
              <Grid.Row>
                <Grid.Col>
                  <Dictionary>
                    <DictionaryItem
                      label={t("permits.information.phoneNumber")}
                    >
                      {permit.phoneNumber?.length > 2
                        ? formatPhoneNumber(
                            permit.phoneNumber,
                            permit.phoneCountryCode
                          )
                        : "—"}
                    </DictionaryItem>

                    <DictionaryItem label={t("permits.information.license")}>
                      {permit.vehicle?.license || "—"}
                    </DictionaryItem>

                    <DictionaryItem label={t("permits.information.start")}>
                      {t("defaultFormats.datetime", {
                        date: getDate(
                          permit.startTime,
                          permit.lot?.timezoneName
                        ),
                      })}
                    </DictionaryItem>

                    <DictionaryItem label={t("permits.information.end")}>
                      {t("defaultFormats.datetime", {
                        date: getDate(permit.endTime, permit.lot?.timezoneName),
                      })}
                    </DictionaryItem>

                    <DictionaryItem
                      multiline
                      label={t("permits.information.lotAddress")}
                    >
                      <Address entity={permit.lot} format="full" />
                    </DictionaryItem>
                  </Dictionary>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col xs={12} md={6}>
              <Grid.Row>
                <Grid.Col>
                  <Dictionary>
                    <DictionaryItem
                      label={t("permits.information.typeOfPermit")}
                    >
                      {permit.calculatedPrice
                        ? t("permits.information.paid")
                        : t("permits.information.free")}
                    </DictionaryItem>

                    <DictionaryItem label={t("permits.information.amount")}>
                      {currencyFormatter.format(
                        permit.calculatedPrice
                          ? permit.calculatedPrice.total
                          : 0,
                        { currency: "usd", language }
                      )}
                    </DictionaryItem>

                    <DictionaryItem
                      label={t("permits.information.paymentMethod")}
                    >
                      <PaymentMethod card={permit?.card} />
                    </DictionaryItem>

                    <DictionaryItem label={t("permits.information.lastName")}>
                      {lastNameOnCard || "—"}
                    </DictionaryItem>
                    <DictionaryItem label={t("permits.information.activated")}>
                      {permit.activatedAt
                        ? t("defaultFormats.datetime", {
                            date: getDate(
                              permit.activatedAt,
                              permit.lot?.timezoneName
                            ),
                          })
                        : "—"}
                    </DictionaryItem>
                    <DictionaryItem label={t("permits.information.requester")}>
                      {permit.requester?.name || "—"}
                    </DictionaryItem>
                  </Dictionary>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </BoxContent.Content>
      </BoxContent>
    </Grid>
  );
};

export default Permit;
