"use strict";

import revalidator from "revalidator";
import { isPlainObject, isString, cloneDeep } from "lodash";

const parsePatterns = (obj) => {
  for (let key in obj) {
    if (key === "pattern" && isString(obj[key]) && obj[key].startsWith("/")) {
      let parts = obj[key].match(/^\/(.*)\/([^/]*)$/);
      obj[key] = new RegExp(parts[1], parts[2]);
    } else if (isPlainObject(obj[key])) {
      parsePatterns(obj[key]);
    }
  }

  return obj;
};

const getErrors = (schema, value) => {
  schema = parsePatterns(cloneDeep(schema));

  // Workaround on Revalidator bug described on
  // https://github.com/flatiron/revalidator/issues/122
  if (schema.type !== "array" && !isPlainObject(value)) {
    return [
      {
        attribute: "type",
        property: "it",
        message: "must be an object",
      },
    ];
  }

  let result = revalidator.validate(value, schema, {
    validateFormatsStrict: true,
    cast: true,
    additionalProperties: false,
  });

  return result.valid ? [] : result.errors;
};

export const getErrorMessages = (schema, value) => {
  let errors = getErrors(schema, value);
  return errors.map((error) => error.message);
};
