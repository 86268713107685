"use strict";

import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { history } from "../../routes";
import $state from "../react/compatibilities/state";
import { convertQueryStringToObject } from "../react/compatibilities/utils";
import { getService } from "./utils";
import { getTranslator } from "../services/languages";

export function useService(serviceName) {
  const params = useParams();
  const queryStrings = convertQueryStringToObject(
    history.location?.search?.substring(1)
  );

  if (serviceName === "$stateParams") {
    return useMemo(
      () => ({ ...params, ...queryStrings }),
      [params, queryStrings]
    );
  }

  if (serviceName === "$state") {
    return useMemo(() => {
      const state = $state.params;
      delete state.params;

      state.params = { ...params, ...queryStrings };
      return $state;
    }, [params, queryStrings]);
  }

  return useMemo(() => getService(serviceName), []);
}

export function useTranslator() {
  return useMemo(() => getTranslator(), []);
}
