import { useTranslator } from "../../../../../shared/react/hooks";

import Table from "../../../../../shared/react/components/Table";
import CurrencyFormatter from "@citifyd/currency-formatter";
import { getCurrentLanguage } from "../../../../../shared/services/languages";
import { useMemo } from "react";

const RatesTable = ({ rates }) => {
  const t = useTranslator();

  const formatCurrency = ({ value }) => {
    const language = getCurrentLanguage();

    return CurrencyFormatter.format(value, {
      currency: "usd",
      language,
    });
  };

  const data = useMemo(
    () =>
      rates.map((rate) => ({
        ...rate,
        totalTransactions: {
          value: rate.totalTransactions?.toString(),
          textAlign: "right",
        },
        revenue: {
          value: formatCurrency({ value: rate.revenue }),
          textAlign: "right",
        },
        processingFees: {
          value: formatCurrency({ value: rate.processingFees }),
          textAlign: "right",
        },
        citifydFees: {
          value: formatCurrency({ value: rate.citifydServiceFeeApplied }),
          textAlign: "right",
        },
        subsidiesPaid: {
          value: formatCurrency({ value: rate.subsidiesPaid }),
          textAlign: "right",
        },
        net: {
          value: formatCurrency({ value: rate.net }),
          textAlign: "right",
        },
      })),
    [rates]
  );

  const columns = useMemo(
    () => [
      {
        value: t("reports.propertyReport.rates.rateName"),
        key: "rateName",
      },
      {
        value: t("reports.propertyReport.rates.numberOfTransactions"),
        key: "totalTransactions",
        textAlign: "right",
      },
      {
        value: t("reports.propertyReport.rates.customersReceipts"),
        key: "revenue",
        textAlign: "right",
      },
      {
        value: t("reports.propertyReport.rates.processingFees"),
        key: "processingFees",
        textAlign: "right",
      },
      {
        value: t("reports.propertyReport.rates.citifydFees"),
        key: "citifydFees",
        textAlign: "right",
      },
      {
        value: t("reports.propertyReport.rates.subsidiesPaid"),
        key: "subsidiesPaid",
        textAlign: "right",
      },
      {
        value: t("reports.propertyReport.rates.net"),
        key: "net",
        textAlign: "right",
      },
    ],
    []
  );
  return <Table striped columns={columns} data={data} />;
};

export default RatesTable;
