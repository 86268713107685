import React from "react";
import moment from "moment-timezone";
import classNames from "classnames";
import { Text, Icon } from "@citifyd/style";
import BoxContent from "../BoxContent";
import Address from "../Address";
import { useTranslator, useService } from "../../hooks";
import Permissions from "../../../services/permissions";

import styles from "./EventHeader.module.scss";
import { useAuthentication } from "../../contexts/authentication";

const EventHeader = ({ event, showActions, fullInfo }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const { permissions } = useAuthentication();
  const userMayEditEvent = Permissions.userMayEditEvent(permissions);

  const onCloseIcon = () => {
    window.history.back();
  };

  const onEditIcon = () => {
    $state.transitionTo("edit-event", {
      eventId: event.id,
    });
  };

  const isSameDate = () =>
    moment(event.start)
      .startOf("day")
      .isSame(moment(event.lotsLatestOpeningTimeAllowed).startOf("day"));

  return (
    <BoxContent
      className={styles.header}
      smallRadius
      noShadow
      appearance="gray_darker"
    >
      <BoxContent.Content className={styles.content}>
        <div className={styles.image}>
          <img src={event.images?.image1 || event.images?.image2} />
        </div>
        <div className={styles.eventInfo}>
          <Text appearance="white" variant="subtitle">
            {event.name}
          </Text>
          <Text appearance="secondary" variant="subtitle"></Text>
          {!fullInfo && event.venue?.name && (
            <Text appearance="secondary" variant="subtitle">
              {event.venue?.name}
            </Text>
          )}
          {fullInfo ? (
            <Text appearance="secondary" variant="subtitle">
              {event.venue?.name},&nbsp;
              <Address entity={event.venue} format="full" />
            </Text>
          ) : (
            <Text appearance="secondary" variant="subtitle">
              {t("event.eventStartDate", {
                date: moment.tz(event.start, event.timezoneName),
              })}
            </Text>
          )}
        </div>

        {fullInfo && (
          <div className={classNames(styles.eventInfo, styles.fullInfo)}>
            <Text appearance="white" variant="subtitle">
              {t("eventProperties.eventStart", {
                date: moment.tz(event.start, event.timezoneName),
              })}
            </Text>
            <Text appearance="secondary" variant="subtitle">
              {t(
                isSameDate()
                  ? "eventProperties.latestOpeningAllowed.time"
                  : "eventProperties.latestOpeningAllowed.dateAndTime",
                {
                  time: moment.tz(
                    event.lotsLatestOpeningTimeAllowed,
                    event.timezoneName
                  ),
                }
              )}
            </Text>
            <Text appearance="secondary" variant="subtitle">
              {t(
                isSameDate()
                  ? "eventProperties.earliestClosingAllowed.time"
                  : "eventProperties.earliestClosingAllowed.dateAndTime",
                {
                  time: moment.tz(
                    event.lotsEarliestClosingTimeAllowed,
                    event.timezoneName
                  ),
                }
              )}
            </Text>
          </div>
        )}
        {showActions && (
          <div className={styles.actions}>
            <div className={styles.icon} onClick={() => onCloseIcon()}>
              <Icon appearance="secondary" icon="times" pull={null} size="h1" />
            </div>
            {userMayEditEvent && (
              <div className={styles.icon} onClick={() => onEditIcon()}>
                <Icon
                  appearance="secondary"
                  icon="pencil-alt"
                  pull={null}
                  size="h2"
                />
              </div>
            )}
          </div>
        )}
      </BoxContent.Content>
    </BoxContent>
  );
};

export default EventHeader;
