"use strict";

import React, { useEffect, useMemo, useState } from "react";
import { Input, Text, Radio, Checkbox } from "@citifyd/style";

import { useSelector, useDispatch } from "react-redux";
import { updateRuleField, duplicateRule, deleteRule } from "../redux/actions";
import { generateRuleRowId } from "../utils/rules";

import SimpleTimeInput from "react-simple-time-input";
import { useTranslator } from "../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../shared/services/languages";
import Table from "../../../shared/react/components/Table";

import CurrencyFormatter from "@citifyd/currency-formatter";
import NumberFormat from "react-number-format";
import moment from "moment-timezone";
import styles from "../styles/EditMode.module.scss";
import DaysOfWeek from "../../../shared/react/components/DaysOfWeek";

const EditableTable = ({ columns }) => {
  const dispatch = useDispatch();
  const lot = useSelector((state) => state.lot);

  const rules = useSelector((state) => state.editableRules);

  const updateField = (ruleId, fieldPath, fieldValue) =>
    dispatch(updateRuleField(ruleId, fieldPath, fieldValue));

  const data = rules.map((rule) => {
    const toggleDay = (day) =>
      updateField(rule.id, `days.${day}`, !rule.days[day]);

    return {
      rowId: generateRuleRowId(rule.id),
      id: { value: rule.id, textAlign: "center" },
      name: <NameColumn rule={rule} updateField={updateField} />,
      price: <PriceColumn rule={rule} lot={lot} updateField={updateField} />,
      type: <EndTypeColumn rule={rule} updateField={updateField} />,
      end: <EndColumn rule={rule} updateField={updateField} />,
      time: <TimeColumn rule={rule} updateField={updateField} />,
      days: (
        <>
          <DaysOfWeek
            daysOfWeek={rule.days}
            onChange={toggleDay}
            error={Boolean(rule.errors["days"])}
            errorMessage={rule.errors["days"]}
            small
          />
        </>
      ),
      actions: <ActionsColumn rule={rule} updateField={updateField} />,
    };
  });

  return (
    <Table
      spacing
      sortable
      gutterBottom
      headerAppearance="white"
      columns={columns}
      data={data}
    />
  );
};

const NameColumn = ({ rule, updateField }) => {
  return useMemo(
    () => (
      <>
        <Input
          fullWidth
          size="small"
          value={rule.name}
          error={rule.errors["name"]}
          onChange={(e) => updateField(rule.id, "name", e.target.value)}
        />
        <ErrorMessage rule={rule} fieldPath="name" />
      </>
    ),
    [rule]
  );
};

const CustomInput = (inputProps) => (
  <Input size="small" {...inputProps}></Input>
);

const PriceColumn = ({ rule, lot, updateField }) => {
  const language = getCurrentLanguage();
  const { currency } = lot.country;

  return useMemo(
    () => (
      <div className={styles.priceColumn}>
        <NumberFormat
          value={rule.price}
          prefix={CurrencyFormatter.getPrefix({ currency, language })}
          suffix={CurrencyFormatter.getSuffix({ currency, language })}
          thousandSeparator={false}
          decimalSeparator={CurrencyFormatter.getDecimalSeparator({ language })}
          onValueChange={(values) =>
            updateField(rule.id, "price", values.value)
          }
          fixedDecimalScale
          decimalScale={CurrencyFormatter.isZeroDecimal({ currency }) ? 0 : 2}
          allowNegative={false}
          isNumericString
          error={rule.errors["price"]}
          customInput={CustomInput}
        />
        <ErrorMessage rule={rule} fieldPath="price" />
      </div>
    ),
    [rule]
  );
};

const EndTypeColumn = ({ rule, updateField }) => {
  const t = useTranslator();

  return useMemo(
    () => (
      <div className={styles.endTypeColumn}>
        <Radio
          small
          gutterBottom
          label={t("advancedRateEditor.fields.end.options.duration.label")}
          checked={rule?.end?.type === "duration"}
          onChange={() => updateField(rule.id, "end.type", "duration")}
        />
        <Radio
          small
          label={t("advancedRateEditor.fields.end.options.time.label")}
          checked={rule?.end?.type === "time"}
          onChange={() => updateField(rule.id, "end.type", "time")}
        />
      </div>
    ),
    [rule]
  );
};

const EndColumn = ({ rule, updateField }) => {
  return useMemo(
    () => (
      <>
        {rule.end.type === "duration" ? (
          <EndDurationSelector rule={rule} updateField={updateField} />
        ) : (
          <EndTimeSelector rule={rule} updateField={updateField} />
        )}
        <ErrorMessage rule={rule} fieldPath="end" />
      </>
    ),
    [rule]
  );
};

const EndDurationSelector = ({ rule, updateField }) => {
  const t = useTranslator();

  const changed = (fieldPath) => (event) => {
    const value = String(event.target.value);
    if (/^[0-9]+$/.test(value)) {
      updateField(rule.id, fieldPath, Number(value));
    } else if (value.trim() === "") {
      updateField(rule.id, fieldPath, "");
    } else {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.endDurationColumn}>
      <div>
        <Input
          size="small"
          error={rule.errors["end"]}
          value={rule.end.duration.hours}
          onChange={changed("end.duration.hours")}
          min="0"
        />
        <Text variant="tiny">
          {t("advancedRateEditor.fields.end.options.duration.edit.hours")}
        </Text>
      </div>

      <div>
        <Input
          size="small"
          error={rule.errors["end"]}
          value={rule.end.duration.minutes}
          onChange={changed("end.duration.minutes")}
          min="0"
          max="59"
        />
        <Text variant="tiny">
          {t("advancedRateEditor.fields.end.options.duration.edit.minutes")}
        </Text>
      </div>
    </div>
  );
};

function EndTimeSelector({ rule, updateField }) {
  const t = useTranslator();

  const timeChanged = (value) => updateField(rule.id, "end.time.time", value);
  const nextDayChanged = (event) =>
    updateField(rule.id, "end.time.days", event.target.checked ? 1 : 0);

  return (
    <div className={styles.endTimeColumn}>
      <SimpleTimeInput
        value={rule.end.time.time}
        onValueChange={timeChanged}
        className={styles.input}
        as={(props) => (
          <Input error={rule.errors["end"]} size="small" {...props}></Input>
        )}
      />
      <Checkbox
        size="small"
        label={t("advancedRateEditor.fields.end.options.time.edit.nextDay")}
        checked={rule.end.time.days === 1}
        onChange={nextDayChanged}
      />
    </div>
  );
}

const TimeColumn = ({ rule, updateField }) => {
  const t = useTranslator();

  const endsOnNextDay = useMemo(() => {
    // disable "next day" disclaimer for this specific scenario as it could confuse the user
    if (rule.time.end === "00:00" && rule.time.start !== "00:00") {
      return false;
    }

    const start = moment(rule.time.start, "HH:mm");
    const end = moment(rule.time.end, "HH:mm");
    return moment(end).isSameOrBefore(start);
  }, [rule.time.start, rule.time.end]);

  return useMemo(
    () => (
      <div className={styles.timeColumn}>
        <div className={styles.timeFields}>
          <div className={styles.field}>
            <SimpleTimeInput
              value={rule.time.start}
              onValueChange={(value) =>
                updateField(rule.id, "time.start", value)
              }
              as={(props) => (
                <Input
                  size="small"
                  fullWidth
                  error={rule.errors["time"]}
                  {...props}
                ></Input>
              )}
            />
          </div>
          <span className={styles.separator}>-</span>
          <div className={styles.field}>
            <SimpleTimeInput
              value={rule.time.end}
              onValueChange={(value) => updateField(rule.id, "time.end", value)}
              as={(props) => (
                <Input
                  size="small"
                  fullWidth
                  error={rule.errors["time"]}
                  {...props}
                ></Input>
              )}
            />
            {endsOnNextDay && (
              <Text variant="tiny">
                {t("advancedRateEditor.fields.time.nextDay")}
              </Text>
            )}
          </div>
        </div>

        <ErrorMessage rule={rule} fieldPath="time" />
      </div>
    ),
    [rule]
  );
};

function ActionsColumn({ rule }) {
  const t = useTranslator();
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const callback = () => setIsDropdownOpen(false);
    window.addEventListener("click", callback);
    return () => window.removeEventListener("click", callback);
  });

  const toggle = (event) => {
    setIsDropdownOpen(!isDropdownOpen);
    event.stopPropagation();
  };

  const stopPropagation = (event) => event.stopPropagation();

  const duplicateClicked = (event) => {
    event.preventDefault();
    dispatch(duplicateRule(rule.id));
    setIsDropdownOpen(false);
  };

  const deleteClicked = (event) => {
    event.preventDefault();
    if (
      rule.name.trim() === "" ||
      window.confirm(
        t("advancedRateEditor.messages.deleteRuleConfirmation", {
          ruleName: rule.name,
        })
      )
    ) {
      dispatch(deleteRule(rule.id));
    }
    setIsDropdownOpen(false);
  };

  return useMemo(
    () => (
      <div className={styles.actionsColumn}>
        <button
          type="button"
          className={styles.actionsButtons}
          onClick={toggle}
        >
          <span>&hellip;</span>
        </button>

        {isDropdownOpen && (
          <div className={styles.actionsDropdown} onClick={stopPropagation}>
            <Text component="a" href="#" onClick={duplicateClicked}>
              {t("advancedRateEditor.buttons.duplicateRule")}
            </Text>
            <Text component="a" href="#" onClick={deleteClicked}>
              {t("advancedRateEditor.buttons.deleteRule")}
            </Text>
          </div>
        )}
      </div>
    ),
    [rule, isDropdownOpen]
  );
}

function ErrorMessage({ rule, fieldPath }) {
  const error = rule.errors[fieldPath];
  if (error) {
    return (
      <Text variant="small" appearance="error" className={styles.errorMessage}>
        {error}
      </Text>
    );
  }

  return null;
}

export default EditableTable;
