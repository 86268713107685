import CurrencyFormatter from "@citifyd/currency-formatter";

import {
  getCurrentLanguage,
  getTranslator,
} from "../../../../shared/services/languages";

export const getData = (
  canSeeRefundCardExpiration,
  refundDestinations,
  ticket
) => {
  const t = getTranslator();
  const language = getCurrentLanguage();

  const columns = [
    {
      key: "card",
      value: t("ticketExchange.card"),
    },
    {
      key: "cardNumber",
      value: t("ticketExchange.cardNumber"),
    },
    canSeeRefundCardExpiration && {
      key: "expiration",
      value: t("ticketExchange.expiration"),
    },
    {
      key: "amount",
      value: t("ticketExchange.amount"),
    },
  ];

  const data = refundDestinations.map((refundDestination) => ({
    card: {
      value: refundDestination.card.brand,
    },
    cardNumber: {
      value: `•••• •••• •••• ${refundDestination.card.last4}`,
    },
    ...(canSeeRefundCardExpiration && {
      expiration: {
        value: refundDestination.card.expMonth
          ? `${refundDestination.card.expMonth}/${refundDestination.card.expYear}`
          : "",
      },
    }),
    amount: {
      value: CurrencyFormatter.format(refundDestination.amount, {
        currency: ticket.lot.country.currency || "usd",
        language,
      }),
    },
  }));

  return {
    columns,
    data,
  };
};
