import gql from "graphql-tag";

export default gql`
  query {
    amenities {
      code
      description
      label
      disables
    }
  }
`;
