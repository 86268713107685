import React from "react";

import { useTranslator } from "../../../../shared/react/hooks";
import FormikField from "../../../../shared/react/components/FormikField";

const MyAccountForm = () => {
  const t = useTranslator();

  return (
    <>
      <FormikField name="name" label={t("myAccount.fullName")} />
      <FormikField name="email" label={t("myAccount.email")} />
    </>
  );
};

export default MyAccountForm;
