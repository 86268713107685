"use strict";

import React, { useEffect, useState } from "react";
import { Select, Text, Grid } from "@citifyd/style";
import { getTranslator } from "../../../../shared/services/languages";
import Address from "../../../../shared/react/components/Address";
import styles from "./PropertySelector.module.scss";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const PropertySelector = ({
  selectedProperty,
  onChange,
  properties,
  ...rest
}) => {
  const t = getTranslator();
  const { user } = useAuthentication();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (options?.length === 0) {
      const list = properties?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      list.unshift({ value: "", label: t("schedule.dropdownEmptyOptionName") });
      setOptions(list);
    }
  }, [properties]);

  return (
    <Grid.Row align="center" className={styles.propertySelector}>
      <Grid.Col xs={12} sm={6} lg={3}>
        <Select
          maxMenuHeight={300}
          id="propertySelector"
          onChange={(e) => onChange(e.target)}
          fullWidth
          value={selectedProperty?.id || ""}
          options={options}
          {...rest}
        />
      </Grid.Col>
      <Grid.Col xs={12} sm={6} lg={6}>
        {!selectedProperty && (
          <>
            {user.isAdmin && (
              <Text variant="small">{t("schedule.viewingNoteSystem")}</Text>
            )}
            {!user.isAdmin && (
              <Text variant="small">{t("schedule.viewingNoteYours")}</Text>
            )}
          </>
        )}

        {selectedProperty && (
          <>
            <div>
              <Text variant="small">
                <Address entity={selectedProperty} format="full_inline" />
              </Text>
            </div>
            <div>
              <Text variant="small">
                {t("schedule.spaces", { spots: selectedProperty.maxSpots })}
              </Text>
            </div>
          </>
        )}
      </Grid.Col>
    </Grid.Row>
  );
};

export default PropertySelector;
