import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState, useMemo } from "react";
import { getRevenueCurrencies } from "../../shared/api";
import ApolloClient from "../../shared/services/apolloClient";
import { getCurrencyName } from "../../shared/services/currencies";
import ADMIN_GET_ORGANIZATIONS from "../../graphql/organizations/queries/AdminOrganizations";
import { getService } from "../../shared/react/utils";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import { useTranslator } from "../../shared/react/hooks";
import { useAdminLotsQuery } from "../../graphql/generated/graphql";

const apolloClient = ApolloClient();

export const useCurrencies = (loadTracking) => {
  const { trackLoading, untrackLoading } = loadTracking;

  const [currencies, setCurrencies] = useState([]);
  const [currenciesError, setCurrenciesError] = useState(false);

  const loadCurrencies = async () => {
    try {
      trackLoading("currencies");

      const response = await getRevenueCurrencies();

      const currencies = response.map((code) => ({
        value: code,
        label: getCurrencyName(code),
      }));

      setCurrencies(currencies);
    } catch (err) {
      console.log("Error loading currencies", err);
      setCurrenciesError(err);
    } finally {
      untrackLoading("currencies");
    }
  };

  useEffect(() => {
    loadCurrencies();
  }, []);

  return [currencies, currenciesError];
};

export const useLots = (loadTracking) => {
  const { trackLoading, untrackLoading } = loadTracking;

  const { data, error, loading } = useAdminLotsQuery({
    client: apolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (loading) {
      trackLoading("lots");
    } else {
      untrackLoading("lots");
    }
  }, [loading]);

  const lots = useMemo(
    () => data?.admin_lots?.edges?.map((item) => item.node) ?? [],
    [data]
  );

  return [lots, error];
};

export const useOrganizationsOptions = () => {
  const t = useTranslator();
  const { user } = useAuthentication();
  const { isAdmin } = user;

  const [getOrganizations, { data }] = useLazyQuery(ADMIN_GET_ORGANIZATIONS, {
    client: apolloClient,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (isAdmin) {
      getOrganizations();
    }
  }, []);

  const organizationsOptions = useMemo(
    () => [
      {
        value: "*",
        label: t("reports.commonFilter.fields.allOrganizations"),
      },
      ...(data?.organizations ?? []).map((organization) => ({
        value: organization.id,
        label: `${organization.name} - ID: ${organization.id}`,
      })),
    ],
    [data?.organizations]
  );

  useEffect(() => {
    if (isAdmin) {
      getOrganizations();
    }
  }, []);

  return organizationsOptions;
};

export const changeQueryParams = (newParams) => {
  const $state = getService("$state");
  const params = { ...$state.params };

  $state.transitionTo(
    $state.current.name,
    { ...params, ...newParams },
    {
      location: "replace",
      notify: false,
      inherit: true,
    }
  );
};
