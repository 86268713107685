import moment from "moment-timezone";
import { getService } from "../../../../../shared/react/utils";

const MAX_RANGE_ALLOWED_IN_MONTHS = 12;

export const validate = (newSelection, setErrors, t) => {
  const { startDate, endDate } = newSelection;
  const start = moment(startDate);
  const end = moment(endDate);
  let valid = true;

  if (start.isAfter(end)) {
    setErrors((old) => ({
      ...old,
      ["dateRange.end"]: t(
        "schedule.reservedParkingFilter.errors.endDate.mustBeAfterStartDate"
      ),
    }));
    valid = false;
  }

  if (end.diff(start, "months") > MAX_RANGE_ALLOWED_IN_MONTHS) {
    setErrors((old) => ({
      ...old,
      ["dateRange.end"]: t(
        "schedule.reservedParkingFilter.errors.endDate.rangeLimitReached",
        {
          maxMonths: MAX_RANGE_ALLOWED_IN_MONTHS,
        }
      ),
    }));
    valid = false;
  }

  if (valid) {
    setErrors({});
  }

  return valid;
};

export const selectionToParams = (selection) => {
  const $state = getService("$state");
  const { status, search } = selection;

  let formattedDateRange;
  const startDate = moment(selection.startDate).format("YYYY-MM-DD");
  const endDate = moment(selection.endDate).format("YYYY-MM-DD");
  formattedDateRange = `${startDate}:${endDate}`;

  return {
    ...$state.params,
    status: status?.toLowerCase(),
    search,
    dateRange: formattedDateRange,
  };
};
