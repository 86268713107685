"use strict";

import React, { useMemo } from "react";
import { reduce, sum, sortByOrder } from "lodash";
import moment from "moment-timezone";
import { Text, Grid } from "@citifyd/style";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import Table from "../../../../shared/react/components/Table";
import RouterLink from "../../../../shared/react/components/RouterLink";
import GuestBadge from "../../../../shared/react/components/GuestBadge";
import { formatCurrency } from "../../utils";
import { useTableColumns } from "./utils";
import { getVehicleLicense } from "../../../../shared/utils/vehicles";
import Permissions from "../../../../shared/services/permissions";
import { formatPhoneNumber } from "../../../../shared/utils/phone-number-formatting";
import styles from "./Reservations.module.scss";
import {
  calculateReservationDuration,
  formatDuration,
  isSameDate,
  timeToObject,
} from "../../../../shared/services/helper";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";

const Reservations = ({ reservations }) => {
  const t = useTranslator();
  const { permissions } = useAuthentication();

  const columns = useTableColumns();
  const { date, currency } = useService("$stateParams");

  const userMaySeeUserInformation =
    Permissions.userMaySeeUserInformation(permissions);
  const userMayOpenReservationPage = Permissions.userMayOpenReservationPage();

  const arrivedBeforeDiscountTime = (arrivedAt, rate, timezoneName) => {
    if (!rate.discount) {
      return false;
    }

    const arrivedAtTime = moment.tz(arrivedAt, timezoneName);

    var arriveBefore = moment
      .tz(arrivedAtTime, timezoneName)
      .set(timeToObject(rate.discount.arriveBefore));

    return (
      arrivedAtTime.isBefore(arriveBefore) || arrivedAtTime.isSame(arriveBefore)
    );
  };

  const calculateRateDescription = (allocation, timezoneName, currency) => {
    const options = {
      amount: allocation.rate.value,
      currency: currency,
    };

    let subtype = null;

    if (
      arrivedBeforeDiscountTime(
        allocation.startTime,
        allocation.rate,
        timezoneName
      )
    ) {
      options.maxRate = allocation.rate.discount.maxRate;
      subtype = "earlyBird";
    } else if (allocation.rate.maxRate) {
      options.maxRate = allocation.rate.maxRate;
      subtype = "maxRate";
    } else {
      subtype = "simple";
    }

    return t(
      `availabilityRateDescription.${allocation.rate.type}.${subtype}`,
      options
    );
  };

  const calculatePaymentProcessingFeeAdjustment = (reservation) => {
    return (
      reservation.originalPaymentProcessingFee -
      reservation.paymentProcessingFee
    );
  };

  const adjustReservation = (reservation) => {
    // the duration of each allocation is ceiled by the minutes
    reservation.availabilityAllocations.forEach(function (allocation) {
      allocation.rateDescription = calculateRateDescription(
        allocation,
        reservation.lot.timezoneName,
        reservation.currency
      );
      allocation.duration = calculateReservationDuration(
        allocation.startTime,
        allocation.endTime || moment().format()
      );
      allocation.chargedDuration = Math.floor(
        moment
          .duration(
            moment(allocation.endTime || moment()).diff(allocation.startTime)
          )
          .asMinutes()
      );
    });

    // the total duration should be the sum of the minutes charged on the reservation
    reservation.duration = formatDuration(
      reduce(
        reservation.availabilityAllocations,
        function (accumulatedDuration, allocation) {
          if (!accumulatedDuration) {
            return allocation.chargedDuration;
          }

          return accumulatedDuration + allocation.chargedDuration;
        },
        null
      )
    );

    reservation.startTimeOnSelectedDate = isSameDate(
      reservation.startTime,
      moment.tz(date, reservation.lot.timezoneName),
      reservation.lot.timezoneName
    );

    reservation.endTimeOnSelectedDate = isSameDate(
      reservation.endTime || reservation.expirationTime,
      moment.tz(date, reservation.lot.timezoneName),
      reservation.lot.timezoneName
    );

    reservation.isSelected = false;
    reservation.paymentProcessingFeeAdjustment =
      calculatePaymentProcessingFeeAdjustment(reservation);

    return reservation;
  };

  const renderTransactionsTable = (reservation) => {
    const columns = [
      {
        value: t("dailyRevenue.start"),
        key: "start",
      },
      {
        value: t("dailyRevenue.end"),
        key: "end",
      },
      {
        value: t("dailyRevenue.rate"),
        key: "rate",
      },
      {
        value: t("dailyRevenue.duration"),
        key: "duration",
      },
      {
        value: t("dailyRevenue.value"),
        textAlign: "right",
        key: "revenueAmount",
      },
    ];

    let formattedData = reservation.availabilityAllocations.map((item) => {
      return {
        appearance: "white",
        borderBottom: true,
        start: t("dailyRevenue.reservationAvailabilityAllocationDateTime", {
          dateTime: moment.tz(item.startTime, reservation?.lot?.timezoneName),
        }),
        end: item.endTime
          ? t("dailyRevenue.reservationAvailabilityAllocationDateTime", {
              dateTime: moment.tz(item.endTime, reservation?.lot?.timezoneName),
            })
          : "—",
        rate: item.rateDescription,
        duration: item.duration,
        revenueAmount: {
          value: formatCurrency(item.value.value, currency),
          textAlign: "right",
        },
      };
    });

    formattedData = [
      ...formattedData,
      {
        appearance: "white",
        borderBottom: true,
        start: <b>{t("dailyRevenue.transactionFees")}</b>,
        end: null,
        rate: null,
        duration: null,
        revenueAmount: {
          value: (
            <b>
              {formatCurrency(
                reservation.originalPaymentProcessingFee * -1,
                currency
              )}
            </b>
          ),
          textAlign: "right",
          textColor:
            reservation.originalPaymentProcessingFee > 0
              ? "tertiary"
              : "default",
        },
      },
      reservation.paymentProcessingFeeAdjustment !== 0
        ? {
            appearance: "white",
            borderBottom: true,
            start: <b>{t("dailyRevenue.transactionFeesAdjustment")}</b>,
            end: null,
            rate: null,
            duration: null,
            revenueAmount: {
              value: (
                <b>
                  {formatCurrency(
                    reservation.paymentProcessingFeeAdjustment,
                    currency
                  )}
                </b>
              ),
              textAlign: "right",
              textColor:
                reservation.paymentProcessingFeeAdjustment < 0
                  ? "tertiary"
                  : "default",
            },
          }
        : null,
      {
        appearance: "white",
        start: <b>{t("dailyRevenue.totalSm")}</b>,
        end: null,
        rate: null,
        duration: null,
        revenueAmount: {
          value: (
            <b>{formatCurrency(reservation.netRevenueAmount, currency)}</b>
          ),
          textAlign: "right",
        },
      },
    ];

    return (
      <Table
        headerAppearance="secondary"
        condensed
        columns={columns}
        data={formattedData.filter((column) => column !== null)}
      />
    );
  };

  const renderSessionInformationTable = (reservation) => {
    const vehicleInformation = () => {
      const data = [];

      if (reservation.vehicle?.color) {
        data.push(reservation.vehicle.color);
      }
      if (reservation.vehicle?.model) {
        data.push(reservation.vehicle.model);
      }
      if (reservation.vehicle?.make) {
        data.push(reservation.vehicle.make);
      }

      return data.join(" ");
    };

    const columns = [
      {
        value: (
          <div>
            <Text>{t("dailyRevenue.parkingSessionInfo")}</Text>
            {userMayOpenReservationPage && (
              <RouterLink
                state="reservation"
                underline
                params={{ id: reservation.id }}
                options={{ enableBackLink: true }}
                appearance="tertiary"
              >
                {t("dailyRevenue.more")}
              </RouterLink>
            )}
          </div>
        ),
        key: "item",
      },
    ];

    const data = [
      {
        data: <b>{t("dailyRevenue.plate")}</b>,
        value: getVehicleLicense(reservation.vehicle),
      },
      {
        data: <b>{t("dailyRevenue.vehicle")}</b>,
        value: vehicleInformation(),
      },
      {
        data: <b>{t("dailyRevenue.username")}</b>,
        value: userMaySeeUserInformation && reservation?.user?.name,
      },
      {
        data: <b>{t("dailyRevenue.email")}</b>,
        value: userMaySeeUserInformation && reservation?.user?.email,
      },
      {
        data: <b>{t("dailyRevenue.phoneNumber")}</b>,
        value:
          userMaySeeUserInformation &&
          formatPhoneNumber(
            reservation?.user?.phoneNumber,
            reservation?.user?.phoneCountryCode
          ),
      },
    ];

    const columnsInnerTable = [
      {
        key: "data",
      },
      {
        key: "value",
      },
    ];

    const innerTable = [
      {
        item: (
          <Table
            fixed
            cleanMode
            condensed
            showHeader={false}
            columns={columnsInnerTable}
            data={data}
          />
        ),
      },
    ];

    return (
      <Table
        className={styles.smallTable}
        cleanMode
        gutterBottom
        condensed
        gutterBottom
        columns={columns}
        data={innerTable}
      />
    );
  };

  const renderExpandedContent = (reservation) => {
    return (
      <Grid.Row>
        <Grid.Col md={8}>{renderTransactionsTable(reservation)}</Grid.Col>
        <Grid.Col md={4}>{renderSessionInformationTable(reservation)}</Grid.Col>
      </Grid.Row>
    );
  };

  const formattedData = useMemo(() => {
    const sortedData = sortByOrder(reservations, ["endTime"], ["desc"]);

    let reservationsList = sortedData
      .map(adjustReservation)
      .map((reservation) => {
        const status =
          (reservation.paymentStatus === "pending" &&
            t("dailyRevenue.statusValues.pending")) ||
          (reservation.paymentStatus === "complete" &&
            t("dailyRevenue.statusValues.complete"));

        const revenueAmount = reservation.netRevenueAmount
          ? formatCurrency(reservation.netRevenueAmount, currency)
          : t("dailyRevenue.cancelled");

        return {
          clickable: true,
          expanded: renderExpandedContent(reservation),
          name: reservation.user?.isGuest ? (
            <GuestBadge />
          ) : (
            reservation.user?.name
          ),

          license: getVehicleLicense(reservation.vehicle),
          lotName: reservation.lot?.name,
          startTime: t("defaultFormats.shortDatetime", {
            date: moment.tz(
              reservation.startTime,
              reservation.lot?.timezoneName
            ),
          }),
          endTime: reservation.endTime
            ? t("defaultFormats.shortDatetime", {
                date: moment.tz(
                  reservation.endTime,
                  reservation.lot?.timezoneName
                ),
              })
            : "—",
          duration: reservation.duration,
          status: status,
          revenueAmount: {
            value: revenueAmount,
            textAlign: "right",
            textColor:
              reservation.netRevenueAmount < 0 ? "tertiary" : "default",
          },
        };
      });

    reservationsList = [
      ...reservationsList,
      {
        name: <b>{t("dailyRevenue.total")}</b>,
        license: null,
        lotName: null,
        startTime: null,
        endTime: null,
        duration: null,
        status: null,
        revenueAmount: {
          value: (
            <b>
              {formatCurrency(sum(reservations, "netRevenueAmount"), currency)}
            </b>
          ),
          textAlign: "right",
        },
      },
    ];

    return reservationsList;
  }, [reservations]);

  return (
    <>
      <div className={styles.revenueContent}>
        <Text gutterBottom variant="h3">
          {t("dailyRevenue.onDemandParkers")}
        </Text>

        <Table
          className={styles.table}
          data={formattedData}
          columns={columns}
          className={styles.mainTable}
          responsive
          borderBottom
          striped
          clickable
        />
      </div>
    </>
  );
};

export default Reservations;
