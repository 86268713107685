"use strict";

import React, { useEffect, useState, useRef } from "react";
import { Grid, Text, Tooltip } from "@citifyd/style";
import { sortBy } from "lodash";
import BoxContent from "../../../../shared/react/components/BoxContent";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import { useTranslator } from "../../../../shared/react/hooks";
import LegendList from "./LegendList";
import Breakdown from "./Breakdown";
import { loadSnapshots, generateGraphStylesForProperty } from "./utils";
import styles from "./Properties.module.scss";

const SvgCircle = ({ item, type }) => (
  <circle
    className={styles[type]}
    style={item.graphStyles[type]}
    r="16"
    cx="16"
    cy="16"
  />
);

const Properties = ({ onSnapshotsLoaded }) => {
  const t = useTranslator();
  const [properties, setProperties] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [total, setTotal] = useState();
  const [anyLotHasLongTermParkingPlan, setAnyLotHasLongTermParkingPlan] =
    useState();
  const refreshRate = 3;
  const timer = useRef(null);

  useEffect(() => {
    const fetchSnapshots = async () => {
      const snapshots = await loadSnapshots();
      setProperties(
        snapshots?.properties?.map((item) => ({
          ...item,
          graphStyles: generateGraphStylesForProperty(item),
        }))
      );
      setTotal(snapshots?.total);
      setHasError(snapshots?.error);
      setAnyLotHasLongTermParkingPlan(snapshots?.anyLotHasLongTermParkingPlan);
      setIsLoading(false);
      onSnapshotsLoaded();
    };

    fetchSnapshots();

    timer.current = setInterval(() => fetchSnapshots(), refreshRate * 1000);

    return () => {
      if (timer) {
        clearInterval(timer.current);
      }
    };
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  return (
    <BoxContent>
      <BoxContent.Header title={t("dashboard.myProperties")} />
      <BoxContent.Content>
        <Grid.Row>
          <Grid.Col sm={12} md={4} lg={3}>
            <LegendList
              total={total}
              anyLotHasLongTermParkingPlan={anyLotHasLongTermParkingPlan}
            />
          </Grid.Col>
          <Grid.Hidden xs sm>
            <Grid.Col md={8} lg={9} className={styles.chartsColumn}>
              <Grid.Row>
                {sortBy(properties, "name")?.map((item, index) => (
                  <Grid.Col key={index} md={6} lg={3}>
                    <div className={styles.chartBox}>
                      <Text variant="subtitle" textAlign="center" gutterBottom>
                        {item.name}
                      </Text>
                      <div className={styles.chart}>
                        <svg viewBox="0 0 32 32">
                          <SvgCircle item={item} type="reservedparking" />
                          <SvgCircle item={item} type="permit" />
                          <SvgCircle item={item} type="ondemand" />
                          <SvgCircle item={item} type="monthly" />
                          <SvgCircle item={item} type="purchased" />
                          <SvgCircle item={item} type="parked" />
                        </svg>
                        <Tooltip
                          id={`breakdown-${index}`}
                          place="bottom"
                          border
                          effect="float"
                          borderColor="grayDark"
                          backgroundColor="white"
                          label={
                            <span className={styles.percent}>
                              {t("propertySnapshot.percentageOccupied", {
                                count: item.percentageOccupied,
                              })}
                            </span>
                          }
                        >
                          <Breakdown
                            property={item}
                            anyLotHasLongTermParkingPlan={
                              anyLotHasLongTermParkingPlan
                            }
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </Grid.Col>
                ))}
              </Grid.Row>
            </Grid.Col>
          </Grid.Hidden>
        </Grid.Row>
      </BoxContent.Content>
    </BoxContent>
  );
};

export default Properties;
