"use strict";
import React, { useMemo } from "react";
import { Text } from "@citifyd/style";
import Table from "../../../shared/react/components/Table";
import { useTranslator } from "../../../shared/react/hooks";
import { formatValue } from "./functions/utils";
import Total from "./Total.jsx";

const PayoutReconciliations = React.forwardRef(
  ({ items, total, currency }, ref) => {
    const t = useTranslator();

    const columns = [
      { key: "description", value: t("payout.description") },
      {
        key: "total",
        value: <Text textAlign="right">{t("payout.revenue")}</Text>,
      },
    ];

    const data = useMemo(() => {
      return [
        ...items.map((item) => ({
          ...item,
          description: item.description,

          total: <Total value={item.amount} currency={currency} />,
        })),
        {
          total: (
            <Text textAlign="right" bold>
              {formatValue({
                value: total,
                currency,
              })}
            </Text>
          ),
        },
      ];
    }, [items, currency, total]);

    return (
      <div ref={ref}>
        <Text variant="h3" gutterBottom>
          {t("payout.reconciliation")}
        </Text>
        <Table
          columns={columns}
          data={data}
          gutterBottom
          responsive
          striped
          clickable
        />
      </div>
    );
  }
);

export default PayoutReconciliations;
