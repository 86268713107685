"use strict";

import React, { useState } from "react";
import { Button, LoadingIndicator, Modal, Text } from "@citifyd/style";
import { useFormik } from "formik";
import * as yup from "yup";
import FormikProvider from "../../react/components/FormikProvider";
import FormikField, { FIELD_TYPE } from "../../react/components/FormikField";
import { useTranslator } from "../../react/hooks";
import { updateUser } from "../../api";
import { showErrorAlert } from "../../services/helper";

const UserUpdateEmailModal = ({ open, onClose, user }) => {
  const t = useTranslator();

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>{t("modalUpdateEmail.update")}</Modal.Title>
      <Form user={user} onClose={onClose} />
    </Modal>
  );
};

const Form = ({ onClose, user }) => {
  const t = useTranslator();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formikbag = useFormik({
    initialValues: {
      email: user.email,
      skipTriggerMigration: true,
      forceEmailVerification: false,
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
    }),
    onSubmit: async (values) => {
      try {
        if (values.email === originalEmail && !values.forceEmailVerification) {
          return window.alert(t("modalUpdateEmail.emailChangeRequired"));
        }

        const body = {
          email: values.email,
          forceSkipMigrationTrigger: values.skipTriggerMigration,
        };

        if (values.forceEmailVerification) body.verifiedEmail = true;
        setIsSubmitting(true);
        await updateUser(user.id, body);

        onClose({ userUpdated: true });
      } catch (response) {
        showErrorAlert(response.data);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const originalEmail = user.email;
  const isOriginalEmailVerified = user.verifiedEmail;
  const hasEmailChanged = formikbag.values.email !== originalEmail;

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Modal.Content>
        <FormikField
          name="email"
          label={t("modalUpdateEmail.email")}
          as={FIELD_TYPE.INPUT}
          fullWidth
          gutterBottom
        />

        {hasEmailChanged && (
          <div>
            <FormikField
              name="skipTriggerMigration"
              label={t("modalUpdateEmail.skip")}
              as={FIELD_TYPE.CHECKBOX}
              fullWidth
              gutterBottom
            />
          </div>
        )}

        {(!isOriginalEmailVerified || hasEmailChanged) && (
          <div>
            <FormikField
              name="forceEmailVerification"
              label={t("modalUpdateEmail.mark")}
              as={FIELD_TYPE.CHECKBOX}
              fullWidth
              gutterBottom
            />
          </div>
        )}
        {formikbag.values.forceEmailVerification && (
          <Text appearance="secondary" italic textAlign="center" weight="400">
            {t("modalUpdateEmail.caution")}
          </Text>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onMouseDown={onClose}
          type="button"
          fullWidth
          justifyContent="center"
          uppercase
          textColor="white"
          appearance="secondary"
        >
          {t("commonButtons.cancel")}
        </Button>
        <Button
          onMouseDown={handleSubmit}
          type="button"
          fullWidth
          justifyContent="center"
          extraPadding
          uppercase
        >
          {t("commonButtons.continue")}
        </Button>
      </Modal.Actions>
      {isSubmitting && (
        <LoadingIndicator isLoading={isSubmitting} withOverlay />
      )}
    </FormikProvider>
  );
};

export default UserUpdateEmailModal;
