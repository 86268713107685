import React, { useEffect, useState, useRef } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

import markerIcon from "../../../../images/map_marker.png";
import styles from "./GoogleMaps.module.scss";
import { useDeepCompareEffectForMaps } from "./utils";

const Marker = ({ onDragEnd, ...options }) => {
  const [marker, setMarker] = useState();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        icon: {
          url: markerIcon,
          scaledSize: new google.maps.Size(30, 38),
        },
        ...options,
      });

      google.maps.event.clearListeners(marker, "dragend");

      marker.addListener("dragend", (e) => onDragEnd(e.latLng));
    }
  }, [marker, options]);

  return null;
};

const Map = ({ children, onIdle, center, ...options }) => {
  const ref = useRef();
  const [map, setMap] = useState();

  useEffect(() => {
    if (ref.current && !map) {
      setTimeout(
        () => setMap(new window.google.maps.Map(ref.current, options)),
        500
      );
    }
  }, [ref, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      map.panTo(center);
    }
  }, [map, center]);

  useEffect(() => {
    if (map) {
      google.maps.event.clearListeners(map, "idle");

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onIdle]);

  return (
    <>
      <div ref={ref} className={styles.container} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const GoogleMaps = ({ initialCoordinates, handleDragEnd }) => {
  const [lat, setLat] = useState(initialCoordinates.latitude);
  const [lng, setLng] = useState(initialCoordinates.longitude);
  const [center, setCenter] = useState({
    lat: initialCoordinates.latitude,
    lng: initialCoordinates.longitude,
  });
  const [zoom, setZoom] = useState(16);

  useEffect(() => {
    if (
      initialCoordinates.latitude !== lat ||
      initialCoordinates.longitude !== lng
    ) {
      setCenter({
        lat: initialCoordinates.latitude,
        lng: initialCoordinates.longitude,
      });
      setLat(initialCoordinates.latitude);
      setLng(initialCoordinates.longitude);
    }
  }, [initialCoordinates]);

  const onDragEnd = (position) => {
    const lat = position.lat();
    const lng = position.lng();

    setLat(lat);
    setLng(lng);
    setCenter({ lat, lng });
    handleDragEnd({ lat, lng });
  };

  const onIdle = (m) => {
    setZoom(m.getZoom());
  };

  return (
    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <Map onIdle={onIdle} center={center} zoom={zoom}>
        <Marker
          draggable={true}
          onDragEnd={onDragEnd}
          position={{ lat, lng }}
        />
      </Map>
    </Wrapper>
  );
};

export default GoogleMaps;
