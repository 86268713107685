import React, { useContext } from "react";
import classNames from "classnames";
import { Text } from "@citifyd/style";

import NavBar from "../NavBar";
import { useTranslator } from "../../hooks";
import styles from "../NavBar/NavBar.module.scss";
import { useAuthentication } from "../../contexts/authentication";
import { getService } from "../../utils";

const OriginalUserNotificationBar = () => {
  const t = useTranslator();
  const {
    originalUser,
    user: loggedUser,
    removeImpersonation,
    isImpersonating,
  } = useAuthentication();

  if (!isImpersonating()) {
    return null;
  }

  const returnToOriginalUser = async (event) => {
    event.preventDefault();
    const $state = getService("$state");
    await removeImpersonation();
    $state.go("dashboard", {}, { location: "replace", reload: true });
  };

  return (
    <NavBar>
      <div className={classNames(styles.text, styles.centered)}>
        <Text>
          {t("index.currentlyAutheticated", { name: loggedUser.name })}{" "}
          <a href="" onClick={returnToOriginalUser}>
            {t("index.switchBack", { name: originalUser.name })}
          </a>
        </Text>
      </div>
    </NavBar>
  );
};

export default OriginalUserNotificationBar;
