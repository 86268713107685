"use strict";

import React from "react";
import { List, Text } from "@citifyd/style";

import RouterLink from "../../../../shared/react/components/RouterLink";
import { useTranslator } from "../../../../shared/react/hooks";
import styles from "../../Organization.module.scss";

const OrganizationUsers = ({ organization }) => {
  const t = useTranslator();

  if (organization.users.length <= 0) {
    return null;
  }

  return (
    <div className={styles.section}>
      <Text variant="h2" component="h2" className={styles.subtitle}>
        {t("organization.users")}
      </Text>
      <List className={styles.list}>
        {organization.users.map((user) => (
          <List.Item key={user.id}>
            <span>
              <RouterLink
                underline
                state="user"
                params={{ id: user.id }}
                variant="subtitle"
              >
                {user.name}
              </RouterLink>{" "}
              {user.id === organization.owner.id && t("organization.owner")}
              {user.id !== organization.owner.id &&
                `(${user.organizationRole})`}
            </span>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default OrganizationUsers;
