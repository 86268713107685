"use strict";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getSubscription } from "../../../shared/api";

import { getSubscriptionRefundPreview } from "./utils";
import { useTranslator } from "../../../shared/react/hooks";
import { getService } from "../../../shared/react/utils";
import { showErrorAlert } from "../../../shared/services/helper";
import ADMIN_GENERATE_SUBSCRIPTION_DEFAULT_REFUND_OPTIONS from "../../../graphql/subscriptions/queries/AdminGenerateSubscriptionDefaultRefundOptions";
import ADMIN_REFUND_SUBSCRIPTION from "../../../graphql/subscriptions/mutations/AdminRefundSubscription";
import ApolloClient from "../../../shared/services/apolloClient";

async function calculateDefaultCancellationOptions(subscriptionId) {
  const response = await ApolloClient().query({
    query: ADMIN_GENERATE_SUBSCRIPTION_DEFAULT_REFUND_OPTIONS,
    variables: {
      subscriptionId: parseInt(subscriptionId),
    },
  });

  const refundOptions =
    response?.data?.admin_generateSubscriptionDefaultRefundOptions;
  if (!refundOptions) {
    throw new Error("Could not load default refund options");
  }

  return refundOptions;
}

export const SubscriptionRefundContext = createContext();

export const useSubscriptionRefundContext = () =>
  useContext(SubscriptionRefundContext);

export default function SubscriptionRefundContextProvider({
  subscriptionId,
  children,
}) {
  const t = useTranslator();

  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [refundOptions, setRefundOptions] = useState(null);
  const [partialAmount, setPartialAmount] = useState(null);
  const [refundPreview, setRefundPreview] = useState(null);

  const userInterfaceSettings = {
    allowChangingRefunder: true,
    allowChangingStripeFeePayer: true,
    allowChangingFeeRetentionMethod: false,
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const subscription = await getSubscription(subscriptionId);
        setSubscription(subscription);

        const defaultRefundOptions = await calculateDefaultCancellationOptions(
          subscriptionId
        );

        const refundOptions = {
          ...defaultRefundOptions,
          reason: "",
          requestedByPhone: false,
          requestedByEmail: false,
        };

        setRefundOptions(refundOptions);

        const refundPreview = await getSubscriptionRefundPreview({
          subscription,
          refundOptions,
        });

        setRefundPreview(refundPreview);

        let amount = refundPreview.refundDestinations.reduce(
          (ac, refundDestination) => ac + refundDestination.amount,
          0
        );

        setPartialAmount(amount);
      } catch (err) {
        console.log({ err });
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  const requestRefund = useCallback(async () => {
    setIsLoading(true);
    setLoadingMessage(t("refundPages.requestingRefund"));

    try {
      await ApolloClient().mutate({
        mutation: ADMIN_REFUND_SUBSCRIPTION,
        variables: {
          id: parseInt(subscriptionId),
          ...refundOptions,
          refunder: refundOptions?.refunder?.toUpperCase(),
          feeRetentionMethod: refundOptions?.feeRetentionMethod?.toUpperCase(),
          stripeFeePayer: refundOptions?.stripeFeePayer?.toUpperCase(),
          partialAmount,
        },
      });

      redirectToReservation(subscriptionId);
    } catch (err) {
      setIsLoading(false);
      showErrorAlert(err.data);
    }
  }, [subscriptionId, refundOptions]);

  const redirectToReservation = useCallback(
    (subscriptionId) => {
      const $state = getService("$state");
      $state.go(
        "subscription",
        { id: subscriptionId },
        { location: "replace" }
      );
    },
    [subscriptionId]
  );

  return (
    <SubscriptionRefundContext.Provider
      value={{
        subscriptionId,
        subscription,
        isLoading,
        loadingMessage,
        hasError,
        refundOptions,
        setRefundOptions,
        refundPreview,
        userInterfaceSettings,
        requestRefund,
        partialAmount,
        setPartialAmount,
      }}
    >
      {children}
    </SubscriptionRefundContext.Provider>
  );
}
