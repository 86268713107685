"use strict";

import { includes } from "lodash";
import { getTranslator } from "../services/languages";

export const getClientSessionDescription = (session, glue) => {
  const t = getTranslator();

  const parts = [];
  const { app, appVersion, browser, os, osVersion, device } =
    session.clientInformation;
  const isMobile = includes(["ios", "android"], os.toLowerCase());

  if (app) {
    let str = app;
    if (appVersion) {
      str += ` (${appVersion})`;
    }
    parts.push(str);
  }

  if (browser || os) {
    let osWithDevice = "";
    if (os) {
      osWithDevice = os + (osVersion ? ` ${osVersion}` : "");

      if (isMobile) {
        osWithDevice += ` (${device})`;
      }
    }

    if (browser && browser !== app) {
      if (os) {
        parts.push(`${browser} on ${osWithDevice}`);
      } else {
        parts.push(browser);
      }
    } else if (os) {
      parts.push(osWithDevice);
    }
  } else {
    parts.push(t("sessions.unknownDevice"));
  }

  return parts.join(glue || " - ");
};
