"use strict";
import React from "react";

import ListToSeeMore from "../../../shared/react/components/ListToSeeMore";
import { useTranslator } from "../../../shared/react/hooks";
import { usePermissionOptions } from "../../add-management-permission/usePermissionOptions";
import styles from "../AccountPermissions.module.scss";

export const ListToSeeMorePermissions = ({
  permissions,
}: {
  permissions: string[];
}) => {
  const t = useTranslator();
  const permissionOptions = usePermissionOptions();

  return (
    <>
      {permissionOptions.length == permissions.length && (
        <span className={styles.text}>
          {t("accountPermissions.permissionInternalUser.allPermissions")}
        </span>
      )}

      <ListToSeeMore
        tooltipProps={{ place: "right" }}
        items={permissions}
        limit={permissionOptions.length === permissions.length ? 0 : 2}
        andOthers={permissionOptions.length !== permissions.length}
        itemsPerColumns={permissions.length + 1}
      />
    </>
  );
};
