"use strict";

import React from "react";
import { Grid, Text } from "@citifyd/style";
import { useFormikContext } from "formik";

import { useTranslator } from "../../../shared/react/hooks";
import GoogleMaps from "../../../shared/react/components/GoogleMaps";

export const VenueMap = ({ coordinates }) => {
  const t = useTranslator();
  const { setValues, values } = useFormikContext();

  const handleDragEnd = ({ lat, lng }) => {
    if (!lat || !lng) return;

    setValues({
      ...values,
      coordinates: {
        latitude: lat,
        longitude: lng,
      },
    });
  };

  return (
    <>
      <Grid.Row>
        <Grid.Col xs={12} lg={6}>
          <Text gutterBottom variant="h5">
            {t("addVenue.form.labels.location")}
          </Text>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col xs={12} lg={12}>
          <GoogleMaps
            handleDragEnd={handleDragEnd}
            initialCoordinates={coordinates}
          />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};
