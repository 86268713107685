"use strict";

import React from "react";
import { useTranslator } from "../../hooks";
import { camelCase, kebabCase } from "lodash";
import { Badge } from "@citifyd/style";

export default function ReservationStatusBadge({ status }) {
  const t = useTranslator();

  const label = t("reservations.statuses." + camelCase(status));

  const getBadgeAppearance = () => {
    const kebabCaseStatus = kebabCase(status);
    let appearance;

    switch (kebabCaseStatus) {
      case "ongoing":
        appearance = { appearance: "white" };
        break;

      case "complete":
        appearance = { appearance: "completed", textAppearance: "white" };
        break;

      case "cancelled":
        appearance = { appearance: "tertiary", textAppearance: "white" };
        break;

      default:
        appearance = { appearance: "gray" };
        break;
    }

    return appearance;
  };

  return <Badge {...getBadgeAppearance()}>{label}</Badge>;
}
