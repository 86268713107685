import React from "react";
import { getTranslator } from "../../../../shared/services/languages";

import { getPropertyWeeklySchedule } from "../../../../shared/api";
import { availabilityGroupGenerator } from "../../../../shared/services/availability-group-generator";

export const getTableData = ({ availabilityGroups, selectedProperty }) => {
  if (!availabilityGroups || !selectedProperty) return null;

  const t = getTranslator();
  const columns = [
    {
      key: "type",
      value: t("schedule.type"),
    },
    {
      key: "hours",
      value: t("schedule.hours"),
    },
    {
      key: "days",
      value: t("schedule.days"),
    },
    {
      key: "passes",
      value: t("schedule.passes"),
    },
    {
      key: "rates",
      value: t("schedule.rate"),
    },
  ];

  const data = availabilityGroups?.map((item) => ({
    type: item.name,
    hours:
      item.startTime && item.endTime ? (
        <>
          {item.startTime} &mdash; {item.endTime}
        </>
      ) : (
        <>&mdash;</>
      ),
    days: item.daysOfWeek || <>&mdash;</>,
    passes: `${item.reservationCount}/${item.spacesCount}`,
    rates: item.rate ? (
      t(`schedule.rateDescriptions.${item.rate.type}`, {
        amount: item.rate.value,
        currency: selectedProperty.country.currency,
      })
    ) : (
      <>&mdash;</>
    ),
  }));

  return {
    columns,
    data,
  };
};

export const loadAvailabilities = async (selectedProperty) => {
  return getPropertyWeeklySchedule(selectedProperty.id).then(
    (availabilities) => {
      return {
        availabilityGroups: availabilityGroupGenerator(
          selectedProperty.id,
          availabilities,
          selectedProperty.timezoneName
        ),
        hasRatesDefiniton: availabilities.length > 0,
      };
    }
  );
};
