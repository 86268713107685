"use strict";
import React, { useEffect, useState } from "react";
import { Button, LoadingIndicator, Modal, Text } from "@citifyd/style";
import { useTranslator } from "../../react/hooks";

import { useFormik } from "formik";
import * as yup from "yup";

import FormikProvider from "../../react/components/FormikProvider";
import FormikField, { FIELD_TYPE } from "../../react/components/FormikField";
import { updateUser } from "../../api";
import { showErrorAlert } from "../../services/helper";
import { useAuthentication } from "../../react/contexts/authentication";

const NotificationSettingsModal = ({ open, onClose }) => {
  const t = useTranslator();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <Modal.Title>{t("modalNotificationSettings.how")}</Modal.Title>
      <Form
        onClose={onClose}
        setIsLoading={setIsLoading}
        setIsSubmitting={setIsSubmitting}
      />
      {(isLoading || isSubmitting) && (
        <LoadingIndicator isLoading={isLoading} withOverlay />
      )}
    </Modal>
  );
};

const Form = ({ onClose, setIsLoading, setIsSubmitting }) => {
  const t = useTranslator();
  const { user, reloadUser } = useAuthentication();

  useEffect(() => {
    load();
  }, []);

  const formikbag = useFormik({
    initialValues: {
      receiveEmailNotifications: false,
      receiveTextMessageNotifications: false,
    },

    validationSchema: yup.object().shape({}),
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        await updateUser(user.id, values);

        reloadUser();

        onClose();
      } catch (response) {
        showErrorAlert(response.data);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const load = async () => {
    const response = await reloadUser();
    formikbag.setFieldValue(
      "receiveEmailNotifications",
      response.receiveEmailNotifications
    );
    formikbag.setFieldValue(
      "receiveTextMessageNotifications",
      response.receiveTextMessageNotifications
    );
    setIsLoading(false);
  };

  const { handleSubmit } = formikbag;

  return (
    <FormikProvider value={formikbag}>
      <Modal.Content>
        <div>
          <FormikField
            label={t("modalNotificationSettings.email")}
            name="receiveEmailNotifications"
            as={FIELD_TYPE.CHECKBOX}
            gutterBottom
          />
        </div>
        <div>
          <FormikField
            label={t("modalNotificationSettings.text")}
            name="receiveTextMessageNotifications"
            as={FIELD_TYPE.CHECKBOX}
            gutterBottom
          />
        </div>

        <Text appearance="secondary" italic textAlign="center" weight="400">
          {t("modalNotificationSettings.sends")}
        </Text>
      </Modal.Content>

      <Modal.Actions>
        <Button
          onMouseDown={onClose}
          type="button"
          textColor="white"
          appearance="secondary"
          fullWidth
          justifyContent="center"
          uppercase
        >
          {t("commonButtons.cancel")}
        </Button>
        <Button
          onMouseDown={handleSubmit}
          type="button"
          fullWidth
          justifyContent="center"
          uppercase
        >
          {t("commonButtons.continue")}
        </Button>
      </Modal.Actions>
    </FormikProvider>
  );
};

export default NotificationSettingsModal;
