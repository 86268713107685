"use strict";

import React, { useState, useEffect } from "react";
import { Text } from "@citifyd/style";
import _ from "lodash";

import StickyFooter from "../../../../shared/react/components/StickyFooter";
import Table from "../../../../shared/react/components/Table";
import RouterLinkButton from "../../../../shared/react/components/RouterLinkButton";
import BoxContent from "../../../../shared/react/components/BoxContent";
import PageHeader from "../../../../shared/react/components/PageHeader";
import { useTranslator, useService } from "../../../../shared/react/hooks";
import {
  tableColumns,
  getSuccessfulTransactions,
  getRejectedTransactions,
  find,
} from "./utils";

const UserPurchaseTicketReport = ({
  submitResponse: { data, events, bundles },
}) => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const { id: userId } = $stateParams;
  const [summary, setSummary] = useState();
  const [rejected, setRejected] = useState();

  useEffect(() => {
    const dataset = _.map(data, (event) => {
      return {
        ...event,
        event: find(events, bundles, event.purchaseInfo),
      };
    });

    const summary = _.groupBy(dataset, "state");
    setSummary(summary);
    setRejected(
      _.chain(summary?.rejected)
        .groupBy("reason")
        .map((value, key) => {
          return {
            reason: key,
            items: value,
          };
        })
        .value()
    );
  }, []);

  return (
    <>
      <PageHeader>
        <PageHeader.Title
          weight="300"
          title={t("userPurchaseTicket.purchaseReport")}
        />
      </PageHeader>

      {summary?.fulfilled && (
        <>
          <Text gutterBottom variant="h5">
            {t("userPurchaseTicket.successfulTransactions")}
          </Text>
          <BoxContent>
            <BoxContent.Content noHorizontalPadding>
              <Table
                showHeader={false}
                columns={tableColumns}
                data={getSuccessfulTransactions(summary?.fulfilled)}
                responsive
                striped
              />
            </BoxContent.Content>
          </BoxContent>
        </>
      )}
      {summary?.rejected && (
        <>
          <Text gutterBottom variant="h5">
            {t("userPurchaseTicket.unsuccessfulTransactions")}
          </Text>
          {rejected.map((object, index) => (
            <BoxContent key={index}>
              <BoxContent.Header
                small
                appearance="tertiary"
                title={object?.reason}
              ></BoxContent.Header>

              <BoxContent.Content noHorizontalPadding>
                <Table
                  showHeader={false}
                  columns={tableColumns}
                  responsive
                  striped
                  data={getRejectedTransactions(object?.items)}
                />
              </BoxContent.Content>
            </BoxContent>
          ))}
        </>
      )}

      <StickyFooter justifyContent="flex-end">
        <RouterLinkButton
          state="user"
          params={{ id: userId }}
          extraPadding
          uppercase
        >
          {t("commonButtons.continue")}
        </RouterLinkButton>
      </StickyFooter>
    </>
  );
};

export default UserPurchaseTicketReport;
