"use strict";

import { getTranslator } from "../../../../shared/services/languages";
import { formatPhoneNumber } from "../../../../shared/utils/phone-number-formatting";
import { pick } from "lodash";
import moment from "moment-timezone";

export const generateDiffText = ({ previous, current }) => {
  const t = getTranslator();
  const changes = [];

  for (let field in previous) {
    if (previous[field] !== current[field]) {
      changes.push({ field, from: previous[field], to: current[field] });
    }
  }

  return changes
    .map((change) => {
      const field = t(`user.changelogs.diff.keys.${change.field}`);
      return t("user.changelogs.diff.text", {
        field,
        from: change.from,
        to: change.to,
      });
    })
    .join(", ");
};

export const transformChangelogToText = (changelog) => {
  const t = getTranslator();
  const metadata = changelog.metadata;
  const endTimeFormatted = moment(metadata?.endTime).tz(changelog.timezoneName);

  const texts = {
    MONTHLY_PARKING_VEHICLE_UPDATE: () =>
      t("user.changelogs.monthlyParking.licensePlateUpdated", {
        previousPlate: metadata?.previous.licensePlate,
        currentPlate: metadata?.current.licensePlate,
        subscriptionId: metadata?.subscriptionId,
      }),
    MONTHLY_PARKING_PAYMENT_METHOD_UPDATE: () =>
      t("user.changelogs.monthlyParking.creditCardUpdated", {
        previousCard: metadata?.previous.last4,
        currentCard: metadata?.current.last4,
        subscriptionId: metadata?.subscriptionId,
      }),
    MONTHLY_PARKING_PASS_CANCELATION: () =>
      t("user.changelogs.monthlyParking.passCanceled", {
        subscriptionId: metadata?.subscriptionId,
      }),
    MONTHLY_PARKING_PASS_EXPIRATION: () =>
      t("user.changelogs.monthlyParking.passExpired", {
        subscriptionId: metadata?.subscriptionId,
        endTime: endTimeFormatted,
      }),
    MONTHLY_PARKING_PASS_REINSTATOR: () =>
      t("user.changelogs.monthlyParking.passReactivated", {
        subscriptionId: metadata?.subscriptionId,
      }),
    MONTHLY_PARKING_PASS_ACQUISITION: () =>
      t("user.changelogs.monthlyParking.purchasedPass", {
        subscriptionId: metadata?.subscriptionId,
      }),
    MONTHLY_PARKING_PASS_RENEWED: () =>
      t("user.changelogs.monthlyParking.passRenewed", {
        subscriptionId: metadata?.subscriptionId,
      }),
    MONTHLY_PARKING_SENT_ACQUISITION_EMAIL: () =>
      t("user.changelogs.monthlyParking.purchasedPassSentByEmail", {
        subscriptionId: metadata?.subscriptionId,
        email: metadata?.email,
      }),
    MONTHLY_PARKING_SENT_CANCELATION_EMAIL: () =>
      t("user.changelogs.monthlyParking.canceledPassSentByEmail", {
        subscriptionId: metadata?.subscriptionId,
        email: metadata?.email,
      }),
    MONTHLY_PARKING_SENT_RENEWAL_EMAIL: () =>
      t("user.changelogs.monthlyParking.renewedPassSentByEmail", {
        subscriptionId: metadata?.subscriptionId,
        email: metadata?.email,
      }),

    HOURLY_PARKING_PASS_ACQUISITION: () =>
      t("user.changelogs.hourlyParking.purchasedPass", {
        reservationId: metadata?.reservationId,
      }),
    HOURLY_PARKING_PASS_CANCELATION: () =>
      t("user.changelogs.hourlyParking.passCanceled", {
        reservationId: metadata?.reservationId,
      }),
    HOURLY_PARKING_PASS_EXPIRATION: () => {
      return t("user.changelogs.hourlyParking.passExpired", {
        reservationId: metadata?.ReservationId ?? metadata?.reservationId,
        endTime: endTimeFormatted,
      });
    },
    HOURLY_PARKING_PASS_EXTENSION: () =>
      t("user.changelogs.hourlyParking.extendedPass", {
        reservationId: metadata?.reservationId,
      }),
    HOURLY_PARKING_SENT_RECEIPT_EMAIL: () =>
      t("user.changelogs.hourlyParking.receiptSentByEmail", {
        reservationId: metadata?.reservationId,
        email: metadata?.email,
      }),
    HOURLY_PARKING_SENT_RECEIPT_SMS: () =>
      t("user.changelogs.hourlyParking.receiptSentBySMS", {
        reservationId: metadata?.reservationId,
        phoneNumber: formatPhoneNumber(
          metadata?.phoneNumber,
          metadata?.phoneCountryCode
        ),
      }),

    EVENT_PARKING_PASS_ACQUISITION: () =>
      t("user.changelogs.eventParking.purchasedPass", {
        ticketId: metadata?.ticketId,
      }),
    EVENT_PARKING_PASS_EXPIRATION: () =>
      t("user.changelogs.eventParking.passExpired", {
        reservationId: metadata?.ReservationId ?? metadata?.reservationId,
        endTime: endTimeFormatted,
      }),
    EVENT_PARKING_PASS_CANCELATION: () =>
      t("user.changelogs.eventParking.passCanceled", {
        ticketId: metadata?.ticketId,
      }),
    EVENT_PARKING_PASS_TRANSFER: () =>
      t("user.changelogs.eventParking.extendedPass", {
        userId: metadata?.current.userId,
        ticketId: metadata?.ticketId,
      }),
    EVENT_PARKING_PASS_EXCHANGE: () =>
      t("user.changelogs.eventParking.exchangedPass", {
        previousEventId: metadata?.previous.eventId,
        currentEventId: metadata?.current.eventId,
        ticketId: metadata?.ticketId,
      }),
    EVENT_PARKING_LICENSE_PLATE_UPDATE: () =>
      t("user.changelogs.eventParking.licensePlateUpdated", {
        previousPlate: metadata?.previous.license,
        currentPlate: metadata?.current.license,
        ticketId: metadata?.ticketId,
      }),
    EVENT_PARKING_SENT_TRANSFER_SMS: () =>
      t("user.changelogs.eventParking.transferSentBySMS", {
        ticketId: metadata?.ticketId,
        phoneNumber: formatPhoneNumber(
          metadata?.phoneNumber,
          metadata?.countryCode
        ),
      }),
    EVENT_PARKING_SENT_TRANSFER_EMAIL: () =>
      t("user.changelogs.eventParking.transferSentByEmail", {
        ticketId: metadata?.ticketId,
        email: metadata?.email,
      }),
    EVENT_PARKING_SENT_RECEIPT_EMAIL: () =>
      t("user.changelogs.eventParking.receiptSentByEmail", {
        ticketId: metadata?.ticketId,
        email: metadata?.email,
      }),
    RESERVED_PARKING_PASS_ACQUISITION: () =>
      t("user.changelogs.reservedParking.purchasedPass", {
        reservationId: metadata?.passId,
      }),
    RESERVED_PARKING_PASS_CANCELATION: () =>
      t("user.changelogs.reservedParking.passCanceled", {
        reservationId: metadata?.passId,
      }),
    RESERVED_PARKING_PASS_EXPIRATION: () =>
      t("user.changelogs.reservedParking.passExpired", {
        reservationId: metadata?.ReservationId ?? metadata?.reservationId,
        endTime: endTimeFormatted,
      }),
    RESERVED_PARKING_LICENSE_PLATE_UPDATE: () =>
      t("user.changelogs.reservedParking.licensePlateUpdated", {
        previousPlate: metadata?.previous.license,
        currentPlate: metadata?.current.license,
        reservationId: metadata?.passId,
      }),
    RESERVED_PARKING_SENT_RECEIPT_EMAIL: () =>
      t("user.changelogs.reservedParking.receiptSentByEmail", {
        email: metadata?.email,
        reservationId: metadata?.passId,
      }),

    USER_ACCOUNT_VEHICLE_ADDED: () =>
      t("user.changelogs.userAccount.vehicleAdded", {
        licenseInfo: metadata?.licenseInfo.license,
        vehicleId: metadata?.VehicleId,
      }),
    USER_ACCOUNT_VEHICLE_EDITED: () => {
      const diffFields = ["license", "countryCode", "makeCode", "color"];
      const previous = pick(metadata?.previous, diffFields);
      const current = pick(metadata?.current, diffFields);

      return t("user.changelogs.userAccount.vehicleEdited", {
        diff: generateDiffText({ previous, current }),
        vehicleId: metadata?.previous.VehicleId,
      });
    },
    USER_ACCOUNT_VEHICLE_REMOVED: () =>
      t("user.changelogs.userAccount.vehicleRemoved", {
        licensePlate: metadata?.license,
        vehicleId: metadata?.VehicleId,
      }),
    USER_ACCOUNT_CREDIT_CARD_ADDED: () =>
      t("user.changelogs.userAccount.creditCardAdded", {
        card: metadata?.last4,
        cardId: metadata?.CardId,
      }),

    USER_ACCOUNT_CREDIT_CARD_EDITED: () =>
      t("user.changelogs.userAccount.creditCardEdited", {
        previous: metadata?.previous.last4,
        current: metadata?.current.last4,
        cardId: metadata?.CardId,
      }),

    USER_ACCOUNT_CREDIT_CARD_REMOVED: () =>
      t("user.changelogs.userAccount.creditCardRemoved", {
        card: metadata?.last4,
        cardId: metadata?.CardId,
      }),

    USER_ACCOUNT_PHONE_NUMBER_UPDATED: () =>
      t("user.changelogs.userAccount.phoneNumberUpdated", {
        previous: formatPhoneNumber(
          metadata?.previous.phoneNumber,
          metadata?.previous.phoneCountryCode
        ),
        current: formatPhoneNumber(
          metadata?.current.phoneNumber,
          metadata?.current.phoneCountryCode
        ),
      }),

    USER_ACCOUNT_PASSWORD_UPDATED: () =>
      t("user.changelogs.userAccount.passwordUpdate"),

    USER_ACCOUNT_EMAIL_UPDATED: () =>
      t("user.changelogs.userAccount.emailUpdated", {
        previous: metadata?.previous.email,
        current: metadata?.current.email,
      }),

    USER_ACCOUNT_NAME_UPDATED: () =>
      t("user.changelogs.userAccount.nameUpdated", {
        previous: metadata?.previous.name,
        current: metadata?.current.name,
      }),
    USER_ACCOUNT_REGULAR_CREATED: () =>
      t("user.changelogs.userAccount.regularCreated", {
        platform: metadata?.device.platform,
      }),
    USER_ACCOUNT_GUEST_CREATED: () =>
      t("user.changelogs.userAccount.guestCreated", {
        platform: metadata?.device.platform,
      }),
    LOT_UPDATED: () =>
      t("user.changelogs.lot.updated", {
        lotId: metadata?.previous.id,
        lotName: metadata?.previous.name,
      }),
    LOT_CREATED: () =>
      t("user.changelogs.lot.created", {
        lotId: metadata?.lot.id,
        lotName: metadata?.lot.name,
      }),

    LOT_DELETED: () =>
      t("user.changelogs.lot.deleted", {
        lotId: metadata?.lotId,
      }),
  };

  return texts[changelog.action]();
};
