import { useState } from "react";
import { getUserSubscriptions } from "../../../../shared/api";

import { useService } from "../../../../shared/react/hooks";
import { transformSubscriptions } from "./utils";

const useSubscriptions = () => {
  const { id } = useService("$stateParams");
  const [subscriptions, setSubscriptions] = useState(null);
  const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false);
  const [subscriptionsError, setSubscriptionsError] = useState(false);

  const fetchSubscriptions = async () => {
    try {
      const subscriptions = await getUserSubscriptions(id);
      setSubscriptions(transformSubscriptions(subscriptions));
    } catch (err) {
      setSubscriptionsError(true);
    } finally {
      setSubscriptionsLoaded(true);
    }
  };

  return {
    fetchSubscriptions,
    subscriptions,
    subscriptionsLoaded,
    subscriptionsError,
  };
};

export default useSubscriptions;
