"use strict";

import React, { useState, useEffect, Fragment } from "react";
import { Grid } from "@citifyd/style";

import ReservationFullInformation from "../../../shared/react/components/ReservationFullInformation";
import PageHeader from "../../../shared/react/components/PageHeader";
import RouterLinkButton from "../../../shared/react/components/RouterLinkButton";

import LoadingManager from "../../../shared/react/components/LoadingManager";
import Permissions from "../../../shared/services/permissions";

import { getService } from "../../../shared/react/utils";
import { useTranslator } from "../../../shared/react/hooks";
import { findReservation } from "../../../shared/api";
import { useAuthentication } from "../../../shared/react/contexts/authentication";

function Page() {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [reservation, setReservation] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const { id } = getService("$stateParams");
      setIsLoading(true);
      try {
        const reservation = await findReservation(id);
        setReservation(reservation);
      } catch (err) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, []);

  if (isLoading || hasError) {
    return <LoadingManager isLoading={isLoading} hasError={hasError} />;
  }

  return (
    <Grid>
      <ReservationPageTitle reservation={reservation} />

      {reservation && (
        <Fragment>
          <ReservationFullInformation reservation={reservation} />
          <ReservationActions reservation={reservation} />
        </Fragment>
      )}
    </Grid>
  );
}

function ReservationPageTitle({ reservation }) {
  const t = useTranslator();

  if (!reservation || !reservation.user) {
    return (
      <PageHeader>
        <PageHeader.Title title={t("reservationPage.pageTitle")} />
      </PageHeader>
    );
  }

  return (
    <PageHeader>
      <PageHeader.Title
        defaultState="user"
        defaultParams={{ id: reservation.user.id }}
      />
    </PageHeader>
  );
}

function ReservationActions({ reservation }) {
  const t = useTranslator();
  const { permissions } = useAuthentication();
  const buttons = [];

  if (
    !reservation.cancelledAt &&
    Permissions.userMayCreateRefunds(permissions)
  ) {
    buttons.push(
      <RouterLinkButton
        key="refund"
        state="reservation-refund"
        params={{ id: reservation.id }}
        options={{ enableBackLink: true }}
        justifyContent="center"
        letterSpacing
        shadow
        uppercase
      >
        {t("reservationPage.actionButtons.refund")}
      </RouterLinkButton>
    );
  }

  return buttons;
}

export default Page;
