import React from "react";
import CurrencyFormatter from "@citifyd/currency-formatter";

import PaymentMethod from "../../../../shared/react/components/PaymentMethod";
import {
  getCurrentLanguage,
  getTranslator,
} from "../../../../shared/services/languages";

export const getData = (refundDestinations, canSeeCardExpiration, currency) => {
  const t = getTranslator();
  const language = getCurrentLanguage();

  const columns = [
    {
      key: "card",
      value: t("refundPages.card"),
      width: "38%",
    },
    canSeeCardExpiration && {
      key: "expiration",
      value: t("refundPages.expiration"),
    },
    {
      key: "amount",
      value: t("refundPages.amount"),
    },
  ];

  const data = refundDestinations.map((refundDestination) => ({
    card: {
      value: <PaymentMethod card={refundDestination.card} />,
      verticalAlign: "middle",
    },
    ...(canSeeCardExpiration && {
      expiration: {
        value:
          refundDestination.card.expMonth &&
          `${refundDestination.card.expMonth}/${refundDestination.card.expYear}`,
        verticalAlign: "middle",
      },
    }),
    amount: {
      value: CurrencyFormatter.format(refundDestination.amount, {
        currency,
        language,
      }),
      verticalAlign: "middle",
    },
  }));

  return {
    columns,
    data,
  };
};
