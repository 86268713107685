"use strict";

import React, { useState } from "react";
import Summary from "./Summary";
import Gate from "./Gate";

const Properties = ({ event, ...rest }) => {
  const [selectedEventGate, setSelectedEventGate] = useState();

  return (
    <div {...rest}>
      {!selectedEventGate && (
        <Summary setSelectedEventGate={setSelectedEventGate} />
      )}
      {selectedEventGate && (
        <Gate
          event={event}
          setSelectedEventGate={setSelectedEventGate}
          selectedEventGate={selectedEventGate}
        />
      )}
    </div>
  );
};

export default Properties;
