"use strict";

import React from "react";
import { Grid } from "@citifyd/style";

import PageHeader from "../../shared/react/components/PageHeader";
import RouterLinkButton from "../../shared/react/components/RouterLinkButton";
import { useTranslator } from "../../shared/react/hooks";
import useVenues from "../../shared/react/hooks/useVenues";

import VenueList from "./components/VenueList";

const Venues = () => {
  const t = useTranslator();
  const { canManageAllVenues } = useVenues();

  if (!canManageAllVenues) return null;

  return (
    <Grid>
      <PageHeader>
        <PageHeader.Title weight="300" title={t("venues.pageTitle")} />
        <PageHeader.Actions>
          <RouterLinkButton
            size="small"
            letterSpacing
            uppercase
            state="add-venue"
            iconRight="plus-circle"
          >
            {t("accountInfo.add")}
          </RouterLinkButton>
        </PageHeader.Actions>
      </PageHeader>

      <VenueList />
    </Grid>
  );
};

export default Venues;
