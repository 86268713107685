"use strict";

import _ from "lodash";
import ApolloClient from "../../../shared/services/apolloClient";

import ADMIN_GENERATE_SUBSCRIPTION_REFUND_PREVIEW from "../../../graphql/subscriptions/queries/AdminGenerateSubscriptionRefundPreview";

const calculateRefundPreview = ({ refundPreview }) => {
  const refundDestinations = _(refundPreview)
    .filter((operation) =>
      ["purchase", "payment", "authorization"].includes(
        operation.transaction.type?.toLowerCase()
      )
    )
    .groupBy((operation) => _.get(operation, "transaction.card.id"))
    .map((operationsPerCard) => ({
      card: operationsPerCard[0].transaction.card,
      amount: _.sum(operationsPerCard, "amountToReverse"),
    }))
    .value();

  return {
    refundDestinations,
    refundPreview,
  };
};

export const getSubscriptionRefundPreview = async ({
  subscription,
  refundOptions,
}) => {
  const response = await ApolloClient().query({
    query: ADMIN_GENERATE_SUBSCRIPTION_REFUND_PREVIEW,
    variables: {
      passId: parseInt(subscription.id),
      refundAmount: subscription.financial.value + subscription.financial.fee,
      feeRetentionMethod: refundOptions.feeRetentionMethod.toUpperCase(),
      refunder: refundOptions.refunder.toUpperCase(),
    },
  });

  const refundPreview = response.data.admin_generateSubscriptionRefundPreview;

  if (!refundPreview) {
    throw new Error("Could not load refund preview");
  }

  return calculateRefundPreview({
    refundPreview: response.data.admin_generateSubscriptionRefundPreview,
  });
};
