"use strict";

import { useFormik } from "formik";
import * as yup from "yup";
import { joinOrganizationInvitation } from "../../shared/api";
import { useTranslator } from "../../shared/react/hooks";
import { showErrorAlert } from "../../shared/services/helper";
import { getCurrentLanguage } from "../../shared/services/languages";
import { useAuthentication } from "../../shared/react/contexts/authentication";

const useSignupFromInvitation = ({
  invitationCode,
  trackLoading,
  untrackLoading,
}) => {
  const t = useTranslator();
  const { setToken } = useAuthentication();

  return useFormik({
    initialValues: {
      phoneNumber: "",
      countryCode: "us",
      password: "",
      confirmPassword: "",
      userExists: false,
    },
    validationSchema: yup.object().shape({
      phoneNumber: yup.string().when("userExists", (userExists, field) => {
        if (!userExists) {
          return field.required();
        } else {
          return field;
        }
      }),
      password: yup.string().required("Password is required"),
      confirmPassword: yup.string().when("userExists", (userExists, field) => {
        if (!userExists) {
          return field
            .test(
              "passwords-match",
              t("modalChangePassword.passwordsMustMatch"),
              function (value) {
                return this.parent.password === value;
              }
            )
            .required();
        } else {
          return field;
        }
      }),
    }),

    onSubmit: async (values) => {
      try {
        trackLoading("submitting");
        const response = await joinOrganizationInvitation(invitationCode, {
          password: values.password,
          phoneNumber: values.phoneNumber.replace(/[^0-9]/g, ""),
          language: getCurrentLanguage(),
        });

        await setToken(response);
        window.location = "/";
      } catch (response) {
        showErrorAlert(response.data);
        untrackLoading("submitting");
      }
    },
  });
};

export default useSignupFromInvitation;
