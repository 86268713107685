"use strict";
import React, { useState } from "react";
import { Modal } from "@citifyd/style";
import { Elements } from "@stripe/react-stripe-js";

import { useTranslator } from "../../react/hooks";
import { getCurrentLanguage } from "../../services/languages";

import Form from "./components/Form/Form.jsx";
import { useStripePromise } from "../../react/stripe";

const AddCardModal = ({ open, onClose, userId }) => {
  const t = useTranslator();
  const language = getCurrentLanguage();
  const stripePromise = useStripePromise();

  if (!stripePromise) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Title>{t("modalAddCard.addCard")}</Modal.Title>
      <Elements stripe={stripePromise} options={{ locale: language }}>
        <Form onClose={onClose} userId={userId} />
      </Elements>
    </Modal>
  );
};

export default AddCardModal;
