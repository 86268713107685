"use strict";

import React, { useRef } from "react";
import SearchInput from "../../../../../shared/react/components/SearchInput";
import { useService } from "../../../../../shared/react/hooks";

const SearchFilter = ({ search, setSearch, ...rest }) => {
  const $state = useService("$state");
  const timer = useRef(null);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (timer) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      $state.transitionTo(
        $state.current.name,
        {
          ...$state.params,
          search: value,
        },
        { location: "replace", notify: false, inherit: false }
      );
    }, 500);
  };

  return (
    <SearchInput
      value={search}
      id="search"
      labelSize="small"
      onChange={handleSearch}
      fullWidth
      {...rest}
    />
  );
};

export default SearchFilter;
