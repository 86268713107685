"use strict";

import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslator } from "../../../shared/react/hooks";
import {
  showErrorAlert,
  uploadImage as upload,
} from "../../../shared/services/helper";
import { getTranslator } from "../../../shared/services/languages";
import { getService } from "../../../shared/react/utils";
import { difference } from "lodash";

import ApolloClient from "../../../shared/services/apolloClient";

import { transformToBackend } from "../conversions/transformToBackend";
import { transformToFrontend } from "../conversions/transformToFrontend";
import {
  CreatePermitPortalInput,
  UpdatePermitPortalInput,
  useAdminCreatePermitPortalMutation,
  useAdminUpdatePermitPortalMutation,
} from "../../../graphql/generated/graphql";

import { FormValues } from "../types";
import { usePermitPortalCtx } from "./usePermitPortalCtx";
import useLoadTracking from "../../../shared/react/hooks/useLoadTracking";

const apolloClient = ApolloClient();

const useSettings = ({
  imageInputRef,
}: {
  imageInputRef: React.MutableRefObject<HTMLInputElement | undefined>;
}) => {
  const t = useTranslator();
  const { trackLoading, untrackLoading } = useLoadTracking({});
  const { lots, portalData, isEditing } = usePermitPortalCtx();

  const [addPortalSettings] = useAdminCreatePermitPortalMutation({
    client: apolloClient,
  });

  const [updatePortalSettings] = useAdminUpdatePermitPortalMutation({
    client: apolloClient,
  });

  const initialValues = transformToFrontend(portalData!);

  return useFormik<FormValues>({
    initialValues,
    validationSchema: yup.object().shape({
      property: yup.string().required(),
      urlPortal: yup
        .string()
        .required()
        .matches(/^[a-z0-9-]+$/, t("commonErrors.validation.url")),
      hasImage: yup.string().required(),
      permitIssuePeriod: yup.string().required().nullable(true),

      maximumLimitOfFuturePermits: yup
        .number()
        .nullable(true)
        .positive()
        .min(1)
        .when(["unlimitedMaximumLimitOfFuturePermits"], {
          is: (unlimitedMaximumLimitOfFuturePermits) =>
            !unlimitedMaximumLimitOfFuturePermits,
          then: yup.number().nullable(true).required(),
        }),
      maximumPermitLength: yup
        .number()
        .nullable(true)
        .positive()
        .min(1)
        .when(["unlimitedMaximumPermitLength"], {
          is: (unlimitedMaximumPermitLength) => !unlimitedMaximumPermitLength,
          then: yup.number().nullable(true).required(),
        }),
      properties: yup
        .array()
        .min(1, t("commonErrors.validation.mustHaveAtLeast1Item"))
        .required(),
      propertyConfiguration: yup.array().of(
        yup.object().shape({
          permitsMonthlyLimit: yup.object().shape({
            quantity: yup
              .number()
              .nullable(true)
              .positive()
              .min(1)
              .when(["noLimit"], {
                is: (noLimit) => !noLimit,
                then: yup.number().nullable(true).required(),
              }),
          }),
          permitsDailyLimit: yup.object().shape({
            quantity: yup
              .number()
              .nullable(true)
              .positive()
              .min(1)
              .when(["noLimit"], {
                is: (noLimit) => !noLimit,
                then: yup.number().nullable(true).required(),
              }),
          }),
          concurrentPermitsLimit: yup.object().shape({
            quantity: yup
              .number()
              .nullable(true)
              .positive()
              .min(1)
              .when(["noLimit"], {
                is: (noLimit) => !noLimit,
                then: yup.number().nullable(true).required(),
              }),
          }),
          amountOfFreePermits: yup
            .number()
            .nullable(true)
            .min(0)
            .when(["permitType"], {
              is: (permitType) => permitType === "mixed",
              then: yup.number().nullable(true).required(),
            }),
        })
      ),
    }),
    onSubmit: async (values, actions) => {
      const logo = await uploadImage({ imageInput: imageInputRef.current });

      try {
        const $state = getService("$state");
        trackLoading("isSubmitting");

        if (isEditing) {
          const input = transformToBackend({
            logo,
            lots,
            values,
          }) as UpdatePermitPortalInput;

          const previousLotIds = (portalData?.lots ?? []).map(
            ({ lot }) => lot.id
          );
          const selectedLotIds = input.lots!.map((lot) => lot.lotId);
          const removedLotIds = difference(previousLotIds, selectedLotIds);

          for (const lotId of removedLotIds) {
            input.lots!.push({ lotId, delete: true });
          }

          await updatePortalSettings({
            variables: {
              id: portalData?.id,
              input,
            },
          });
        } else {
          const input = transformToBackend({
            logo,
            lots,
            values,
          }) as CreatePermitPortalInput;

          await addPortalSettings({
            variables: {
              input,
            },
          });
        }

        $state.go("permitters-portal-settings");
      } catch (err) {
        const t = getTranslator();

        if (err?.graphQLErrors?.[0]?.extensions?.param === "namespace") {
          actions.setFieldError(
            "urlPortal",
            t("permits.permitters.errors.urlAlreadyExists")
          );
        } else {
          showErrorAlert(err?.message);
        }
      } finally {
        untrackLoading("isSubmitting");
      }
    },
  });
};

const uploadImage = async ({ imageInput }) => {
  if (imageInput.files.length === 0) {
    return;
  }

  const { cloudName, publicId, format } = await upload(
    imageInput.form,
    "events"
  );

  return {
    mode: "CLOUDINARY",
    cloudinary: {
      cloudName,
      publicId,
      format,
      defaultTransformation: "t_event_image",
    },
  };
};

export default useSettings;
