"use strict";
import _ from "lodash";

import { useFormik } from "formik";
import * as yup from "yup";
import { createParkerSignupInvitation } from "../../../../shared/api";
import { showErrorAlert } from "../../../../shared/services/helper";

const useConfirmation = ({ steps, setSteps, setIsSubmitting }) => {
  return useFormik({
    initialValues: {
      reason: "",
      requestedPhone: false,
      requestedEmail: false,
    },
    validationSchema: yup.object().shape({
      reason: yup.string().required(),
    }),

    onSubmit: async (values) => {
      const events = steps.data.lotSelection.events.map((event) => ({
        eventId: event.id,
        availabilityId: event.selectedLot.availability.id,

        lotId: event.selectedLot.id,
      }));
      const bundles = steps.data.lotSelection.bundles.map((bundle) =>
        _.pick(bundle, "id")
      );
      const data = {
        users: steps.data.userInfo.users,
        events,
        bundles,
        extra: {
          requestedPhone: values.requestedPhone,
          requestedEmail: values.requestedEmail,
        },
        reason: values.reason,
      };

      try {
        setIsSubmitting(true);
        await createParkerSignupInvitation(data);

        setSteps({
          step: "result",
        });
      } catch (response) {
        showErrorAlert(response.data);
      } finally {
        setIsSubmitting(false);
      }
    },
  });
};
export default useConfirmation;
