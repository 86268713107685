import moment from "moment-timezone";
import { dateAndTimeToMomentObject } from "../../../../shared/services/helper";

export const getFormattedDate = (date, time) =>
  dateAndTimeToMomentObject(date, time).toDate();

export const getDate = ({ field, permit }) =>
  permit
    ? moment(permit[field]).tz(permit.lot.timezoneName).toDate()
    : new Date();

export const getTime = ({ field, permit }) => {
  if (permit) {
    return moment
      .tz(permit[field], permit.lot.timezoneName)
      .locale("en")
      .format("HH:mm");
  }

  if (field === "startTime") {
    return moment().add(1, "hour").startOf("hour").format("HH:mm");
  } else {
    return moment().add(2, "hours").startOf("hour").format("HH:mm");
  }
};

export const formatDate = (date, time, timezone) =>
  moment(date).set(getTimeObject(time)).tz(timezone).format();

const getTimeObject = (str) => {
  const parts = str.split(":");
  return {
    hour: parts[0],
    minute: parts[1],
    second: 0,
  };
};
