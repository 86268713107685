import { getTranslator } from "../../../../shared/services/languages";

export const getLabels = () => {
  const t = getTranslator();

  const dateLabels = {
    payment: t("transaction.ticketExchangeBox.dateLabels.payment"),
    refund: t("transaction.ticketExchangeBox.dateLabels.refund"),
  };

  const timeLabels = {
    payment: t("transaction.ticketExchangeBox.timeLabels.payment"),
    refund: t("transaction.ticketExchangeBox.timeLabels.refund"),
  };

  const exchangeCardActionLabels = {
    payment: t("transaction.ticketExchangeBox.cardActionLabels.payment"),
    refund: t("transaction.ticketExchangeBox.cardActionLabels.refund"),
  };

  const exchangeStatusLabels = {
    payment: t("transaction.ticketExchangeBox.statusLabels.payment"),
    refund: t("transaction.ticketExchangeBox.statusLabels.refund"),
  };

  const exchangeStatuses = {
    payment: t("transaction.ticketExchangeBox.statuses.payment"),
    refund: t("transaction.ticketExchangeBox.statuses.refund"),
  };

  return {
    dateLabels,
    timeLabels,
    exchangeCardActionLabels,
    exchangeStatusLabels,
    exchangeStatuses,
  };
};
