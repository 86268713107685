import { useFormik } from "formik";
import * as yup from "yup";
import { createReferralCode, updateReferralCode } from "../../../api";
import { showErrorAlert } from "../../../services/helper";
import Mode from "../../../utils/mode";

const useReferralCodeForm = ({ mode, setIsSubmiting, onClose }) => {
  return useFormik({
    initialValues: {
      code: "",
      incentiveAmount: "",
      maxReferrals: "",
    },
    validationSchema: yup.object().shape({
      code: yup.string().required(),
      incentiveAmount: yup.number().required(),
      maxReferrals: yup.number().positive().min(0),
    }),
    onSubmit: async (values) => {
      const data = {
        code: values.code,
        incentiveAmount: parseFloat(values.incentiveAmount) * 100,
        maxReferrals: values.maxReferrals || null,
      };
      try {
        setIsSubmiting(true);
        if (mode === Mode.EDIT) {
          await updateReferralCode(data);
        } else {
          await createReferralCode(data);
        }
        onClose({ refreshUsers: true });
      } catch (data) {
        showErrorAlert(data.data);
      } finally {
        setIsSubmiting(false);
      }
    },
  });
};

export default useReferralCodeForm;
