"use strict";

import React from "react";
import { Button, Select, Text, Modal } from "@citifyd/style";
import { useTranslator } from "../../../react/hooks";
import styles from "./LanguageSelection.module.scss";

const LanguageSelection = ({
  languages,
  download,
  onClose,
  selectedLanguage,
  setSelectedLanguage,
}) => {
  const t = useTranslator();
  return (
    <>
      <Modal.Title>
        {t("modalGenerateReceipt.languageSelection.title")}
      </Modal.Title>
      <Modal.Content>
        <Text variant="subtitle" className={styles.description}>
          {t("modalGenerateReceipt.languageSelection.description")}
        </Text>
        <Select
          fullWidth
          value={selectedLanguage}
          options={languages.map((language) => ({
            label: language.label,
            value: language.key,
          }))}
          onChange={(e) => setSelectedLanguage(e.target.value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          appearance="secondary"
          extraPadding
          fullWidth
          justifyContent="center"
          textColor="white"
          uppercase
          onClick={onClose}
        >
          {t("commonButtons.cancel")}
        </Button>
        <Button
          onClick={() => download({ language: selectedLanguage })}
          extraPadding
          fullWidth
          justifyContent="center"
          uppercase
        >
          {t("commonButtons.continue")}
        </Button>
      </Modal.Actions>
    </>
  );
};

export default LanguageSelection;
