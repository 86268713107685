"use strict";

import React, { useState, useEffect, useMemo } from "react";
import { Select, Grid, DatePicker, Label } from "@citifyd/style";
import { groupBy, isEmpty } from "lodash";
import { useTranslator } from "../../../../../shared/react/hooks";
import {
  validateStartAndEndDate,
  getFilterSelectionFromState,
  getLotsOptions,
  getLotsByParams,
  updateFilters,
  onDateChange,
  onBasicSelectChange,
} from "../../../utils";

import { getFormattedDate } from "../../../../../shared/utils/date-time-formatting";
import { getCurrentLanguage } from "../../../../../shared/services/languages";
import MultiplePropertiesSelector from "../../MultiplePropertiesSelector";
import styles from "./Filter.module.scss";
import { useAuthentication } from "../../../../../shared/react/contexts/authentication";
import Permissions from "../../../../../shared/services/permissions";

const SummaryFilter = ({ lots, currencies, organizationsOptions }) => {
  const t = useTranslator();
  const { user, permissions } = useAuthentication();

  const [selection, setSelection] = useState({});
  const [selectedLots, setSelectedLots] = useState([]);

  const [hasSameCurrency, setHasSameCurrency] = useState(false);
  const [errors, setErrors] = useState({});

  const language = getCurrentLanguage();
  const { errors: startAndEndDateErrors, isValid: startAndEndDateValid } =
    useMemo(() => validateStartAndEndDate({ selection }), [selection]);

  const lotsOptions = useMemo(
    () =>
      getLotsOptions({
        lots,
        t,
        showAllPropertiesOption: false,
        organizationId: selection.organizationId,
      }),
    [lots, selection.organizationId]
  );

  useEffect(() => {
    const params = getFilterSelectionFromState({ user });
    const options = getLotsOptions({
      lots,
      t,
      showAllPropertiesOption: false,
    });

    const currencies = Object.keys(groupBy(options, "currency"));
    const hasMultipleCurrencies = currencies.length > 1;

    setHasSameCurrency(!hasMultipleCurrencies);
    setSelectedLots(getLotsByParams({ params, lots: options }));
    setSelection({
      ...params,
      ...(!hasMultipleCurrencies &&
        !isEmpty(currencies) && { currency: currencies[0] }),
    });
  }, []);

  useEffect(() => {
    if (startAndEndDateValid && !isEmpty(selection)) {
      updateFilters({ selection });
    }
  }, [selection, startAndEndDateValid]);

  useEffect(() => {
    setErrors({});
  }, [startAndEndDateValid]);

  useEffect(() => {
    if (startAndEndDateErrors) {
      setErrors((old) => ({
        ...old,
        ...startAndEndDateErrors,
      }));
    }
  }, [startAndEndDateErrors]);

  return (
    <Grid.Row marginBottom>
      <Grid.Col className={styles.boxFilters}>
        <Grid.Row>
          {Permissions.userMaySeeParkingOperatorFilter(permissions) && (
            <Grid.Col>
              <Select
                fullWidth
                labelSize="small"
                label={t("reports.commonFilter.fields.parkingOperator")}
                options={organizationsOptions}
                value={selection.organizationId ?? "*"}
                onChange={(e) => {
                  setSelection({
                    ...selection,
                    organizationId: e.target.value,
                    lots: null,
                  });
                  setSelectedLots([
                    { value: "*", label: "All properties" },
                    ...lots.map((lot) => ({
                      value: lot.id,
                      label: lot.name,
                    })),
                  ]);
                }}
              />
            </Grid.Col>
          )}
          <Grid.Col className={styles.column} sm={12} md={3}>
            <MultiplePropertiesSelector
              selection={selection}
              selectedLots={selectedLots}
              options={lotsOptions}
              setSelection={setSelection}
              setSelectedLots={setSelectedLots}
            />
          </Grid.Col>

          <Grid.Col className={styles.column} sm={12} md={2}>
            <Label variant="small">
              {t("reports.commonFilter.fields.startDate")}
            </Label>
            <Grid.Row gutterWidth={4}>
              <Grid.Col>
                <DatePicker
                  locale={language}
                  maxDate={new Date()}
                  onChange={(date) =>
                    setSelection(
                      onDateChange({ field: "startDate", date, selection })
                    )
                  }
                  selectedDate={getFormattedDate(selection.startDate)}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col className={styles.column} sm={12} md={2}>
            <Label variant="small">
              {t("reports.commonFilter.fields.endDate")}
            </Label>
            <Grid.Row gutterWidth={4}>
              <Grid.Col>
                <DatePicker
                  locale={language}
                  maxDate={new Date()}
                  onChange={(date) =>
                    setSelection(
                      onDateChange({ field: "endDate", date, selection })
                    )
                  }
                  selectedDate={getFormattedDate(selection.endDate)}
                  error={errors["endDate"]}
                  errorMessage={t(errors["endDate"])}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          {!hasSameCurrency && (
            <Grid.Col className={styles.column} sm={12} md={2}>
              <Select
                fullWidth
                name="currency"
                labelSize="small"
                label={t("reports.commonFilter.fields.currency")}
                options={currencies}
                value={selection.currency || ""}
                onChange={(e) =>
                  setSelection(onBasicSelectChange({ e, selection }))
                }
              />
            </Grid.Col>
          )}
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  );
};

export default SummaryFilter;
