"use strict";

import React, { useState, useEffect } from "react";
import { Tab } from "@citifyd/style";

import SmallLoadingIndicator from "../../../../shared/react/components/SmallLoadingIndicator";
import TabName from "../../../../shared/react/components/TabName";
import { useTranslator } from "../../../../shared/react/hooks";
import UserTickets from "../UserTickets";
import ReservedParkingPasses from "../ReservedParkingPasses";
import OnDemandReservations from "../OnDemandReservations";
import MonthlySubscriptions from "../MonthlySubscriptions";
import styles from "./UserPasses.module.scss";
import useTickets from "../UserTickets/hooks";
import useReservedParkingPasses from "../ReservedParkingPasses/hooks";
import useReservations from "../OnDemandReservations/hooks";
import useSubscriptions from "../MonthlySubscriptions/hooks";
import PermitPasses from "../PermitPasses/PermitPasses.jsx";
import usePermitPasses from "../PermitPasses/hooks";

const UserPasses = ({ partialUser, nonCustomer }) => {
  const t = useTranslator();
  const [user, setUser] = useState(partialUser);
  const [activeTab, setActiveTab] = useState(0);

  const { fetchTickets, ticketsLoaded, ticketsError } = useTickets({
    nonCustomer,
    setUser,
  });

  const {
    fetchPermitsPasses,
    permitPasses,
    loadingPermitPasses,
    permitPassesError,
  } = usePermitPasses({ userId: user?.id });

  const {
    fetchReservedParkingPasses,
    reservedParkingPasses,
    reservedParkingPassesLoaded,
    reservedParkingPassesError,
  } = useReservedParkingPasses();

  const {
    fetchReservations,
    reservations,
    reservationsLoaded,
    reservationsError,
  } = useReservations();

  const {
    fetchSubscriptions,
    subscriptions,
    subscriptionsLoaded,
    subscriptionsError,
  } = useSubscriptions();

  useEffect(() => {
    fetchTickets();
    fetchReservedParkingPasses();
    fetchReservations();
    fetchSubscriptions();
    fetchPermitsPasses();
  }, []);

  const tabs = [
    {
      locale: "user.purchasesTabs.tickets",
      count: user.tickets?.length,
      component: (
        <UserTickets
          user={user}
          isLoading={!ticketsLoaded}
          hasError={ticketsError}
        />
      ),
    },
    {
      locale: "user.purchasesTabs.reservedParkingPasses",
      count: reservedParkingPasses?.total,
      component: (
        <ReservedParkingPasses
          isLoading={!reservedParkingPassesLoaded}
          hasError={reservedParkingPassesError}
          reservedParkingPasses={reservedParkingPasses?.edges}
        />
      ),
    },
    {
      locale: "user.purchasesTabs.reservations",
      count: reservations?.length,
      component: (
        <OnDemandReservations
          reservations={reservations}
          isLoading={!reservationsLoaded}
          hasError={reservationsError}
        />
      ),
    },
    {
      locale: "user.purchasesTabs.subscriptions",
      count: subscriptions?.length,
      component: (
        <MonthlySubscriptions
          subscriptions={subscriptions}
          isLoading={!subscriptionsLoaded}
          hasError={subscriptionsError}
        />
      ),
    },
    {
      locale: "user.purchasesTabs.permits",
      count: permitPasses?.length,
      component: (
        <PermitPasses
          permitPasses={permitPasses}
          isLoading={loadingPermitPasses}
          hasError={permitPassesError}
        />
      ),
    },
  ];

  const renderTabs = () =>
    tabs.map((tab, index) => {
      const { locale, count, component } = tab;
      const text = t(locale, { count: count || 0 });
      const active = index === activeTab;

      const item = {
        big:
          !count && typeof count !== "number" ? (
            <SmallLoadingIndicator
              appearance={active ? "gray_darker" : "white"}
            />
          ) : (
            count
          ),
        small: text,
      };

      return (
        <Tab.Item
          key={index}
          active={active}
          onClick={() => setActiveTab(index)}
          className={styles.tab}
          name={<TabName active={active} item={item} />}
        >
          {component}
        </Tab.Item>
      );
    });

  return <Tab stateless>{renderTabs()}</Tab>;
};

export default UserPasses;
