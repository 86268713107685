"use strict";

import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslator } from "../../../../shared/react/hooks";

const useUserInfoStep = ({ setSteps }) => {
  const t = useTranslator();
  return useFormik({
    initialValues: {
      users: [
        {
          name: "",
          email: "",
          phoneNumber: "",
          phoneCountryCode: "us",
        },
      ],
    },
    validationSchema: yup.object().shape({
      users: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .required(t("inviteParkers.accountInfo.errors.missingName")),

          email: yup
            .string()
            .email()
            .when(["phoneNumber"], {
              is: (phoneNumber) => !phoneNumber,
              then: yup
                .string()
                .required(
                  t(
                    "inviteParkers.accountInfo.errors.missingEmailOrPhoneNumber"
                  )
                ),
            }),
          phoneNumber: yup.number(),
        })
      ),
    }),

    onSubmit: async (values) => {
      setSteps((steps) => ({
        step: "event_selection",
        data: {
          userInfo: {
            users: values.users.map((user) => {
              return {
                ...user,
                phoneNumber: user.phoneNumber.replace(/[^0-9]/g, ""),
                phoneCountryCode: !user.phoneNumber
                  ? ""
                  : user.phoneCountryCode,
              };
            }),
          },
        },
      }));
    },
  });
};
export default useUserInfoStep;
