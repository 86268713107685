import React from "react";
import moment from "moment-timezone";
import CurrencyFormatter from "@citifyd/currency-formatter";

import { getService } from "../../../../shared/react/utils";
import {
  getCurrentLanguage,
  getTranslator,
} from "../../../../shared/services/languages";

import HighlightText from "../../../../shared/react/components/HighlightText";
import TicketStatusBadge from "../../../../shared/react/components/TicketStatusBadge";

const getDefaultFilterOptions = () => {
  const t = getTranslator();

  return [
    {
      value: ["purchased", "additional"],
      label: t("user.ticketFilters.purchased"),
    },
    { value: "parked", label: t("user.ticketFilters.parked") },
    {
      value: "transferred",
      label: t("user.ticketFilters.transferred"),
    },
    { value: "redeemed", label: t("user.ticketFilters.redeemed") },
    { value: "cancelled", label: t("user.ticketFilters.cancelled") },
    { value: "expired", label: t("user.ticketFilters.expired") },
  ];
};

const filterByStatus = (ticket, statusFilter) => {
  if (typeof statusFilter === "string") {
    return statusFilter === ticket.statusTag;
  } else {
    return statusFilter.includes(ticket.statusTag);
  }
};

export const getFilterOptions = (tickets) => {
  const t = getTranslator();
  const filterOptions = getDefaultFilterOptions();

  const filters = filterOptions.filter((filter) =>
    tickets.some((ticket) => filterByStatus(ticket, filter.value))
  );

  return [
    {
      value: "",
      label: t("user.dropdownEmptyOptionName"),
    },
    ...filters,
  ];
};

export const getData = ({ tickets, handleSort, orders, search }) => {
  const t = getTranslator();
  const $state = getService("$state");
  const language = getCurrentLanguage();

  const columns = [
    {
      key: "start",
      value: t("user.purchase"),
      onClick: (e) => handleSort(e, "start"),
      sortableKey: "start",
      width: "15%",
    },
    {
      key: "eventDate",
      value: t("user.eventDate"),
      nowrap: "nowrap",
      onClick: (e) => handleSort(e, "event.start"),
      sortableKey: "event.start",
      width: "15%",
    },
    {
      key: "event",
      value: t("user.event"),
      nowrap: "nowrap",
      onClick: (e) => handleSort(e, "event.name"),
      sortableKey: "event.name",
      width: "20%",
    },
    {
      key: "lot",
      value: t("user.lot"),
      nowrap: "nowrap",
      onClick: (e) => handleSort(e, "lot.name"),
      sortableKey: "lot.name",
      width: "25%",
    },
    {
      key: "price",
      value: t("user.price"),
      nowrap: "nowrap",
      onClick: (e) => handleSort(e, "price"),
      sortableKey: "price",
      width: "10%",
    },
    {
      key: "status",
      value: t("user.status"),
      nowrap: "nowrap",
      onClick: (e) => handleSort(e, "statusTag"),
      sortableKey: "statusTag",
      width: "15%",
    },
  ];

  const columnsFormatted = columns.map((column) => ({
    ...column,
    sortable: true,
    orders,
  }));

  const handleClick = (id) => {
    $state.go("ticket", { id }, { enableBackLink: true });
  };

  const data = tickets.map((ticket) => ({
    onClick: () => handleClick(ticket.id),
    start: {
      value: t("defaultFormats.date", {
        date: moment.tz(ticket.createdAt, ticket.lot.timezoneName),
      }),
      nowrap: "nowrap",
      verticalAlign: "middle",
    },
    eventDate: {
      value: t("defaultFormats.date", {
        date: moment.tz(ticket.event.start, ticket.event.timezoneName),
      }),
      nowrap: "nowrap",
      verticalAlign: "middle",
    },
    event: {
      value: (
        <HighlightText flags="-i" text={ticket.event.name} highlight={search} />
      ),
      verticalAlign: "middle",
    },
    lot: {
      value: ticket.lot.name,
      nowrap: "nowrap",
      verticalAlign: "middle",
    },
    price: {
      value: ticket.bundlePurchase
        ? t("user.package")
        : CurrencyFormatter.format(ticket.price, {
            currency: ticket.currency,
            language,
          }),
      verticalAlign: "middle",
    },
    status: {
      value: <TicketStatusBadge statusTag={ticket.statusTag} />,
      noHorizontalPadding: true,
      verticalAlign: "middle",
    },
  }));

  return {
    columns: columnsFormatted,
    data,
  };
};

export const statusCondition = (status, statusTag) => {
  if (status.includes(",")) {
    const statuses = status.split(",");
    return statuses.includes(statusTag);
  }

  return status === statusTag;
};
