"use strict";

import React, { useMemo } from "react";
import { cloneDeep } from "lodash";
import { Grid } from "@citifyd/style";
import moment from "moment-timezone";

import VehicleColorIndicator from "../../../../shared/react/components/VehicleColorIndicator";
import { getVehicleLicense } from "../../../../shared/utils/vehicles";
import { useTranslator } from "../../../../shared/react/hooks";
import BoxContent from "../../../../shared/react/components/BoxContent";
import Dictionary, {
  DictionaryItem,
} from "../../../../shared/react/components/Dictionary";
import styles from "../TicketTransactionData/TicketTransactionData.module.scss";
import { getGateEntryAuthentication } from "./utils";

const TicketParkingInfoBox = ({ ticket }) => {
  const t = useTranslator();
  const gateEntry = useMemo(() => cloneDeep(ticket.gateEntries[0]), []);

  if (gateEntry) {
    gateEntry.authentication = getGateEntryAuthentication(gateEntry);
  }

  if (!gateEntry) {
    return null;
  }

  return (
    <BoxContent>
      <BoxContent.Header
        appearance="completed"
        title={t("transaction.parkingInformation")}
        className={styles.informationHeader}
      />
      <BoxContent.Content>
        <Grid.Row>
          <Grid.Col sm={12} md={6}>
            <Dictionary withDivisor={false}>
              <DictionaryItem
                textComponent="span"
                label={t("transaction.timeOfIngress")}
              >
                {t("defaultFormats.time", {
                  date: moment.tz(
                    gateEntry.entryTime,
                    ticket.availability.timezoneName
                  ),
                })}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.authentication")}
              >
                {gateEntry.authentication}
              </DictionaryItem>
            </Dictionary>
          </Grid.Col>

          <Grid.Col sm={12} md={6}>
            <Dictionary withDivisor={false}>
              <DictionaryItem
                textComponent="span"
                label={t("transaction.licensePlate")}
              >
                {getVehicleLicense(gateEntry.vehicle) || "—"}
              </DictionaryItem>

              <DictionaryItem
                textComponent="span"
                label={t("transaction.vehicle")}
              >
                <span className={styles.vehicleColor}>
                  <VehicleColorIndicator vehicle={gateEntry.vehicle} />
                  {gateEntry.vehicle?.make || "—"}
                </span>
              </DictionaryItem>
            </Dictionary>
          </Grid.Col>
        </Grid.Row>
      </BoxContent.Content>
    </BoxContent>
  );
};

export default TicketParkingInfoBox;
