"use strict";

import React, { useState } from "react";
import { Button } from "@citifyd/style";
import { useFormikContext } from "formik";

import EventsSelector from "../../../../shared/react/components/EventsSelector";
import StickyFooter from "../../../../shared/react/components/StickyFooter";
import PageHeader from "../../../../shared/react/components/PageHeader";

import { useService, useTranslator } from "../../../../shared/react/hooks";

import { getEvents } from "./utils";

const UserPurchaseTicketEvent = ({ events, bundleGroups, setStep }) => {
  const t = useTranslator();
  const $stateParams = useService("$stateParams");
  const { id: userId } = $stateParams;
  const { setFieldValue, values } = useFormikContext();
  const { selectedEvents, selectedBundles } = values;
  const [filteredEvents, setFilteredEvents] = useState(
    getEvents(selectedBundles, events).newEvents
  );

  const handleSelectEvent = (item) => {
    const isSelected = selectedEvents.find((event) => event.id === item.id);

    const events = isSelected
      ? selectedEvents.filter((event) => event.id !== item.id)
      : [...selectedEvents, item];

    setFieldValue("selectedEvents", events);
  };

  const handleSelectBundle = (item) => {
    const isSelected = selectedBundles.find((bundle) => bundle.id === item.id);

    const bundles = isSelected
      ? selectedBundles.filter((bundle) => bundle.id !== item.id)
      : [...selectedBundles, item];

    setFieldValue("selectedBundles", bundles);

    const { newEvents, newSelectedEvents } = getEvents(
      bundles,
      events,
      selectedEvents
    );

    setFieldValue("selectedEvents", newSelectedEvents);
    setFilteredEvents(newEvents);
  };

  const hasSelection = !!selectedEvents.length || !!selectedBundles.length;

  return (
    <>
      <PageHeader>
        <PageHeader.Title
          defaultState="user"
          defaultParams={{ id: userId }}
          weight="300"
          title={t("userPurchaseTicket.chooseEvents")}
        />
      </PageHeader>

      <EventsSelector
        events={filteredEvents}
        bundleGroups={bundleGroups}
        handleSelectEvent={handleSelectEvent}
        handleSelectBundle={handleSelectBundle}
        selectedEvents={selectedEvents}
        selectedBundles={selectedBundles}
      />

      {hasSelection && (
        <StickyFooter justifyContent="flex-end">
          <Button uppercase onMouseDown={() => setStep(2)} extraPadding>
            {t("commonButtons.continue")}
          </Button>
        </StickyFooter>
      )}
    </>
  );
};

export default UserPurchaseTicketEvent;
