interface UsePaginationProps {
  data?: {
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  };
  refetch: (variables: any) => any;
  pageSize: number;
}

export const usePagination = ({
  data,
  refetch,
  pageSize,
}: UsePaginationProps) => {
  const { hasNextPage, hasPreviousPage, startCursor, endCursor } =
    data?.pageInfo ?? {};

  const nextPage = () => {
    if (hasNextPage) {
      refetch({
        first: pageSize,
        last: null,
        after: endCursor,
        before: null,
      });
    }
  };

  const previousPage = () => {
    if (hasPreviousPage) {
      refetch({
        first: null,
        last: pageSize,
        after: null,
        before: startCursor,
      });
    }
  };

  return {
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
    startCursor,
    endCursor,
  };
};
