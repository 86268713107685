"use strict";

import React, { useEffect, useState, useMemo } from "react";

import { Grid, Text, Icon, Button } from "@citifyd/style";

import { defer, isEqual, reduce } from "lodash";

import LoadingManager from "../../shared/react/components/LoadingManager";
import FormikProvider from "../../shared/react/components/FormikProvider";
import EventHeader from "../../shared/react/components/EventHeader";
import StickyFooter from "../../shared/react/components/StickyFooter";
import RouterLink from "../../shared/react/components/RouterLink";
import useLoadTracking from "../../shared/react/hooks/useLoadTracking";
import PageHeader from "../../shared/react/components/PageHeader";
import { useService, useTranslator } from "../../shared/react/hooks";
import { getMeasurementSystemFromCountryCode } from "../../shared/services/settings";
import PropertyItem from "./components/PropertyItem";
import PropertyCard from "./components/PropertyCard";
import styles from "./EditEventProperties.module.scss";
import Permissions from "../../shared/services/permissions";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import {
  loadLots,
  useEventFormik,
  validateMaxRuleOnNumberOfTransactions,
} from "./useEventFormik";
import { convertLotFromBackend, validateLotAvailabilities } from "./utils";

const EditEventProperties = () => {
  const t = useTranslator();
  const [hasError, setHasError] = useState(false);
  const [event, setEvent] = useState();
  const { user, permissions } = useAuthentication();
  const $stateParams = useService("$stateParams");
  const userMayEditEvent = Permissions.userMayEditEvent(permissions);
  const eventId = $stateParams.eventId;

  const measurementSystem = useMemo(
    () => getMeasurementSystemFromCountryCode(user.phoneCountryCode),
    [user]
  );

  const { isLoading, loadingMessage, trackLoading, untrackLoading } =
    useLoadTracking({
      loadingProperty: {
        message: t("eventProperties.loadingMessages.loadingProperties"),
      },
      savingProperty: {
        message: t("eventProperties.loadingMessages.makingPropertiesAvailable"),
      },
      savingEventGates: {
        message: t("eventProperties.loadingMessages.settingUpGates"),
      },
      redirectingAfterSubmit: {
        message: t("eventProperties.loadingMessages.redirecting"),
      },
    });

  const formikbag = useEventFormik({
    event,
    trackLoading,
    untrackLoading,
  });

  const { handleSubmit, values, setFieldValue, setValues } = formikbag;
  const selectedLots = useMemo(() => values?.selectedLots, [values]);
  const posRates = useMemo(() => values?.posRates, [values]);
  const eventGates = useMemo(() => values?.eventGates, [values]);

  const onPropertyClick = (lotId) => {
    const lots = event.nearbyLots?.map((item) => {
      if (item.id === lotId) {
        return {
          ...item,
          availability: {
            ...item.availability,
            selected: !item.availability.selected,
          },
        };
      } else {
        return { ...item };
      }
    });

    setEvent((currentEvent) => ({ ...currentEvent, nearbyLots: lots }));
  };

  useEffect(() => {
    if (!event) return;

    setValues((values) => {
      const newSelectedLots = event.nearbyLots
        .filter((lot) => lot.availability.selected)
        .map((lot) => {
          const formikLot = values.selectedLots?.find(
            (formikLot) => formikLot.id === lot.id
          );
          return formikLot ? formikLot : convertLotFromBackend(lot);
        });

      return {
        ...values,
        selectedLots: newSelectedLots,
      };
    });
  }, [event]);

  useEffect(() => {
    if (selectedLots) {
      const newGatesObject = reduce(
        eventGates,
        (acc, { gateId, lotId, ...rest }) => ({
          ...acc,
          [gateId]: {
            ...rest,
            gateId,
            lotId,
            belongsToSelectedLot: selectedLots.some((lot) => lot.id === lotId),
          },
        }),
        {}
      );

      // Check if the newGatesObject is different from the current state
      const isGatesObjectChanged = !isEqual(newGatesObject, eventGates);

      // Update the state only if the gatesObject has changed
      if (isGatesObjectChanged) {
        setFieldValue("eventGates", newGatesObject);
      }
    }
  }, [selectedLots, eventGates, setFieldValue]);

  useEffect(() => {
    loadLots({
      setFieldValue,
      setEvent,
      eventId,
      measurementSystem,
      setHasError,
      trackLoading,
      untrackLoading,
    });
  }, []);

  const onSubmit = () => {
    defer(() => {
      const validations = [
        () =>
          validateLotAvailabilities({
            event,
            selectedLots,
            posRates,
          }),
        () =>
          validateMaxRuleOnNumberOfTransactions({
            selectedLots,
            actions: formikbag,
          }),
      ];

      for (const validation of validations) {
        const isValid = validation();
        if (!isValid) return;
      }

      handleSubmit();
    });
  };

  if (isLoading || hasError || !event) {
    return (
      <LoadingManager
        isLoading={isLoading || !event}
        hasError={hasError}
        loadingMessage={loadingMessage}
      />
    );
  }

  return (
    <FormikProvider value={formikbag}>
      <Grid>
        <PageHeader>
          <PageHeader.Title
            defaultState={userMayEditEvent ? "edit-event" : "appendable-events"}
            defaultParams={userMayEditEvent ? { eventId: eventId } : null}
            weight="300"
            title={t("titles.setupProperties")}
          />
        </PageHeader>
        <EventHeader event={event} showActions={false} fullInfo={true} />

        {event.nearbyLots.length === 0 && (
          <Text variant="subtitle">{t("eventProperties.noProperties")}</Text>
        )}

        {event.nearbyLots.length > 0 && (
          <Grid.Row>
            <Grid.Col xs={12} lg={4}>
              <Text className={styles.columnTitle} variant="h4" weight="600">
                {t("eventProperties.select")}
              </Text>
              {event.nearbyLots.map((lot, index) => (
                <PropertyItem
                  key={index}
                  setEvent={setEvent}
                  measurementSystem={measurementSystem}
                  onClick={() => onPropertyClick(lot.id)}
                  lot={lot}
                />
              ))}
            </Grid.Col>
            {selectedLots?.length > 0 && (
              <Grid.Col xs={12} lg={8}>
                <Text className={styles.columnTitle} variant="h4" weight="600">
                  {t("eventProperties.setup")}
                </Text>
                {selectedLots.map((lot, index) => (
                  <PropertyCard
                    formikbag={formikbag}
                    key={index}
                    measurementSystem={measurementSystem}
                    formikIndex={index}
                    lot={lot}
                  />
                ))}
              </Grid.Col>
            )}
          </Grid.Row>
        )}
      </Grid>

      {(selectedLots?.length > 0 || event?.lots?.length > 0) && (
        <StickyFooter justifyContent="space-between">
          <RouterLink
            state={userMayEditEvent ? "edit-event" : "appendable-events"}
            params={userMayEditEvent ? { eventId: eventId } : null}
            className={styles.backLink}
          >
            <Icon size="subtitle" icon="chevron-left" appearance="white" />
            <Text variant="subtitle" appearance="white">
              {t("commonButtons.goBackWithoutSaving")}
            </Text>
          </RouterLink>
          <Button
            type="submit"
            uppercase
            onMouseDown={() => onSubmit()}
            extraPadding
          >
            {t("eventProperties.saveSchedule")}
          </Button>
        </StickyFooter>
      )}
    </FormikProvider>
  );
};

export default EditEventProperties;
