"use strict";

import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@citifyd/style";

import BoxContent from "../../shared/react/components/BoxContent";
import { useService } from "../../shared/react/hooks";
import LoadingManager from "../../shared/react/components/LoadingManager";
import useLoadTracking from "../../shared/react/hooks/useLoadTracking";
import ParkingAppStep from "./components/ParkingApp";
import AuthenticationStep from "./components/Authentication";
import TwoFactorAuthenticationStep from "./components/TwoFactorAuthentication";
import { useAuthentication } from "../../shared/react/contexts/authentication";

const Login = () => {
  const { setToken, user: loggedUser } = useAuthentication();
  const [step, setStep] = useState("authentication");
  const $location = useService("$location");
  const $state = useService("$state");
  const [twoStepCredentials, setTwoStepCredentials] = useState();

  const { isLoading, trackLoading, untrackLoading } = useLoadTracking();

  const handleSuccessfulLogin = async (user) => {
    const source = $location.search().source;
    const redirectTo = source || "/";

    $state.transitionTo(
      decodeURIComponent(redirectTo),
      {},
      { location: "replace" }
    );
  };

  const onTwoFactorAuthenticationRequired = (values) => {
    setStep("two-factor");
    setTwoStepCredentials(values);
  };

  useEffect(() => {
    if (loggedUser) {
      handleSuccessfulLogin();
      return;
    }

    const token = $location.search().token;

    trackLoading("init");

    const notLoggedIn = () => {
      setStep("authentication");
      untrackLoading("init");
    };

    if (token) {
      setToken(token).then(handleSuccessfulLogin, notLoggedIn);
    } else {
      notLoggedIn();
    }
  }, []);

  if (isLoading) {
    return <LoadingManager isLoading={true} />;
  }

  return (
    <Grid>
      <Grid.Row justify="space-around">
        <Grid.Col sm={6} lg={5}>
          <BoxContent appearance="primary">
            <BoxContent.Content>
              <ParkingAppStep />
            </BoxContent.Content>
          </BoxContent>
        </Grid.Col>
        <Grid.Col sm={6} lg={5}>
          <BoxContent>
            <BoxContent.Content>
              {step === "authentication" && (
                <AuthenticationStep
                  handleSuccessfulLogin={handleSuccessfulLogin}
                  onTwoFactorAuthenticationRequired={
                    onTwoFactorAuthenticationRequired
                  }
                />
              )}
              {step === "two-factor" && twoStepCredentials && (
                <TwoFactorAuthenticationStep
                  credentials={twoStepCredentials}
                  handleSuccessfulLogin={handleSuccessfulLogin}
                />
              )}
            </BoxContent.Content>
          </BoxContent>
        </Grid.Col>
      </Grid.Row>
    </Grid>
  );
};

export default Login;
