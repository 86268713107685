"use strict";

import React, { useMemo } from "react";
import { Icon, Text } from "@citifyd/style";
import { useFormikContext } from "formik";

import { useTranslator } from "../../../../../shared/react/hooks";
import FormikField, {
  FIELD_TYPE,
} from "../../../../../shared/react/components/FormikField";
import {
  generateTimesArray,
  getTranslatedDayName,
} from "../../../../../shared/services/helper";

import Table from "../../../../../shared/react/components/Table";

const SelectedDaysOfTheWeekTable = ({ daysOfWeek }) => {
  const t = useTranslator();

  const { setValues } = useFormikContext();

  const timeOptions = useMemo(
    () =>
      generateTimesArray().map((time) => ({
        value: time.time,
        label: time.label,
      })),
    []
  );

  const removeDay = (day) => {
    setValues((values) => ({
      ...values,
      daysOfWeek: { ...values.daysOfWeek, [day]: false },
    }));
  };

  const columns = [
    {
      value: t("permits.permitters.settings.day"),
      key: "day",
      uppercase: false,
      textColor: "gray_dark",
      width: "25%",
    },
    {
      value: t("permits.permitters.settings.start"),
      nowrap: "nowrap",
      key: "start",
      uppercase: false,
      textColor: "gray_dark",
    },
    {
      value: t("permits.permitters.settings.end"),
      nowrap: "nowrap",
      key: "end",
      uppercase: false,
      textColor: "gray_dark",
    },
    {
      key: "action",
    },
  ];

  const data = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
    .filter((day) => daysOfWeek?.[day])
    .map((day) => {
      return {
        day: {
          value: <Text>{getTranslatedDayName(day, "dddd")}</Text>,
          nowrap: "nowrap",
          verticalAlign: "middle",
        },
        start: {
          value: (
            <FormikField
              as={FIELD_TYPE.SELECT}
              options={timeOptions}
              name={`hours.${day}.start`}
            />
          ),
          nowrap: "nowrap",
          verticalAlign: "middle",
        },
        end: {
          value: (
            <FormikField
              as={FIELD_TYPE.SELECT}
              options={timeOptions}
              name={`hours.${day}.end`}
            />
          ),
          nowrap: "nowrap",
          verticalAlign: "middle",
        },
        action: {
          value: (
            <Icon
              size="h3"
              appearance="tertiary"
              icon="times-circle"
              title={t("myAccount.removeDevice")}
              onClick={() => removeDay(day)}
            />
          ),
          verticalAlign: "middle",
        },
      };
    });

  const hasAnySelected = Object.entries(daysOfWeek).some(
    ([_key, isSelected]) => isSelected
  );

  if (hasAnySelected) {
    return (
      <Table data={data} columns={columns} spacing headerAppearance="white" />
    );
  } else {
    return null;
  }
};

export default SelectedDaysOfTheWeekTable;
