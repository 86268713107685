"use strict";

import React from "react";
import { Text, Tooltip } from "@citifyd/style";
import moment from "moment";
import { zipObject, map, times } from "lodash";
import CurrencyFormatter from "@citifyd/currency-formatter";
import { getCurrentLanguage } from "../../../../../shared/services/languages";

import styles from "./Table.module.scss";

export const ratesTimes = times(24, (n) => n);

const timesColumns = ratesTimes.map((item) => ({
  value: moment(item, "hh:mm").format("h A"),
  key: item,
  width: "80px",
  textAlign: "center",
}));

export const rowObject = ({ rate, type, currency }) => {
  const language = getCurrentLanguage();

  const formattedValue = (value) => {
    switch (type) {
      case "amount": {
        return CurrencyFormatter.format(value, {
          currency,
          language,
        });
      }
      case "percentage":
        return `${value?.toString()}%`;
      default:
        return value?.toString();
    }
  };

  const response = zipObject(
    ratesTimes,
    map(ratesTimes, (item) => ({
      value: (
        <Text textAlign="center">{formattedValue(rate.values[item])}</Text>
      ),
      verticalAlign: "middle",
      textAlign: "center",
    }))
  );

  return {
    rate: {
      value:
        type === "rateName" ? (
          <Tooltip
            id={rate?.name}
            label={rate?.name}
            labelProps={{ className: styles.ellipsis }}
          >
            {rate?.name}
          </Tooltip>
        ) : (
          <Text>{rate?.name}</Text>
        ),
      verticalAlign: "middle",
    },
    ...response,
  };
};

export const tableColumns = () => [...timesColumns];
