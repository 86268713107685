import gql from "graphql-tag";

export default gql`
  query {
    admin_listPermitPortalUser {
      edges {
        node {
          id
          identifier
          name
          phoneCountryCode
          phoneNumber
          accessExpiresAt
        }
      }
    }
  }
`;
