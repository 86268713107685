import gql from "graphql-tag";

export default gql`
  fragment ReservedParkingPass on ReservedParkingPass {
    id
    startTime
    endTime
    status
    cancelledAt
    user {
      name
      id
      isGuest
    }
    currency
    transactionNumber
    calculatedPrice {
      value
      fee
      total
    }
    vehicle {
      license
    }
    card {
      id
      last4
      name
      tokenizationMethod
      brand
    }
    lot {
      id
      name
      timezoneName
      rawAddress
      countryCode
    }
  }
`;
