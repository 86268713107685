import React, { useEffect, useState } from "react";
import { Button, Text } from "@citifyd/style";

import NavBar from "../NavBar";
import { useService, useTranslator } from "../../hooks";
import styles from "../NavBar/NavBar.module.scss";
import ChangePasswordModal from "../../../modals/ChangePasswordModal";
import { useModal } from "../../contexts/modal";
import {
  PASSWORD_CHANGE_SUGGESTED,
  useAuthentication,
} from "../../contexts/authentication";
import Cookies from "js-cookie";

const PasswordChangeNotificationBar = () => {
  const t = useTranslator();
  const $rootScope = useService("$rootScope");
  const { user } = useAuthentication();

  const { openModal } = useModal();
  const [showNotificationBar, setShowNotificationBar] = useState();

  useEffect(() => {
    $rootScope.$on("$stateChangeSuccess", () => {
      setShowNotificationBar(
        Cookies.get(PASSWORD_CHANGE_SUGGESTED) ? true : false
      );
    });
  }, []);

  const openChangePasswordModal = () => {
    openModal(ChangePasswordModal);
    onHideNotificationBar();
  };

  const onHideNotificationBar = () => {
    Cookies.remove(PASSWORD_CHANGE_SUGGESTED);

    setShowNotificationBar(false);
  };

  if (!showNotificationBar || !user) {
    return null;
  }

  return (
    <NavBar>
      <div className={styles.textWithButtons}>
        <div className={styles.text}>
          <Text>{t("index.lastPassword", { n: 90 })}</Text>
        </div>
        <div className={styles.buttons}>
          <Button
            onClick={openChangePasswordModal}
            appearance="white"
            uppercase
            size="small"
          >
            {t("index.changePassword")}
          </Button>
          <Button
            onClick={onHideNotificationBar}
            appearance="white"
            uppercase
            size="small"
          >
            {t("index.dismiss")}
          </Button>
        </div>
      </div>
    </NavBar>
  );
};

export default PasswordChangeNotificationBar;
