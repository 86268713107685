"use strict";
import React, { useEffect, useState } from "react";
import { LoadingIndicator } from "@citifyd/style";

import {
  useAdminGetAccountPermissionCompanyLazyQuery,
  useAdminGetAccountPermissionUserInvitationLazyQuery,
} from "../../graphql/generated/graphql";

import ApolloClient from "../../shared/services/apolloClient";
import { useService } from "../../shared/react/hooks";
import { useAuthentication } from "../../shared/react/contexts/authentication";
import { getAllProperties } from "../../shared/api";
import { getServerErrorMessage, showAlert } from "../../shared/services/helper";

import { Lot } from "./types";
import { Form } from "./Form";

const apolloClient = ApolloClient();

const AddAttendantMonitoringCompany = () => {
  const { user } = useAuthentication();
  const $state = useService("$state");
  const isEditing = Boolean($state.params.id);

  const isPending = $state.params.pending;

  const [lotsData, setLotsData] = useState<Lot[]>();
  const [errorMessage, setErrorMessage] = useState(false);
  const [loadingLots, setLoadingLots] = useState(true);

  const [getCompanyData, { data: userCompanyData, loading: loadingUser }] =
    useAdminGetAccountPermissionCompanyLazyQuery({
      client: apolloClient,
      fetchPolicy: "network-only",
    });

  const [
    getPendingUserData,
    { data: pendingCompanyData, loading: loadingPendingCompanyData },
  ] = useAdminGetAccountPermissionUserInvitationLazyQuery({
    client: apolloClient,
    fetchPolicy: "network-only",
  });

  const companyData =
    userCompanyData?.getAccountPermissionCompany ||
    pendingCompanyData?.getAccountPermissionUserInvitation;

  useEffect(() => {
    loadProperties();
    getCompany();
  }, []);

  const loadProperties = async () => {
    setLoadingLots(true);

    try {
      const result = await getAllProperties({
        params: {
          includeOwner: true,
          order: "id",
          reverse: true,
        },
      });

      setLotsData(result);
    } catch (error) {
      setErrorMessage(getServerErrorMessage(error));
      showAlert(errorMessage);
    } finally {
      setLoadingLots(false);
    }
  };

  const getCompany = async () => {
    if (isEditing && !isPending) {
      await getCompanyData({
        variables: {
          userId: Number($state.params.id),
          organizationId: user.organization.id,
        },
      });
    } else if (isEditing && isPending) {
      await getPendingUserData({
        variables: {
          inviteId: Number($state.params.id),
          organizationId: user.organization.id,
        },
      });
    }
  };

  if (loadingUser || loadingLots || loadingPendingCompanyData)
    return <LoadingIndicator />;
  if (!lotsData) return null;
  if (isEditing && !companyData) return null;

  return (
    <Form
      companyData={companyData}
      lots={lotsData}
      isEditing={isEditing}
      isPending={isPending}
    />
  );
};

export default AddAttendantMonitoringCompany;
