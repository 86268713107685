"use strict";

import React from "react";
import { isEmpty } from "lodash";
import { Select } from "@citifyd/style";
import { useService, useTranslator } from "../../../../../shared/react/hooks";
import { showStat, getEventStatus } from "../utils";

import styles from "./StatusSelector.module.scss";

const StatusFilter = ({ stats, disabled }) => {
  const t = useTranslator();
  const $state = useService("$state");
  const currentStatus = getEventStatus($state.params?.status);

  const onChange = (e) => {
    $state.transitionTo(
      $state.current.name,
      {
        ...$state.params,
        status: e.target?.value,
      },
      {
        location: "replace",
      }
    );
  };

  const options = !isEmpty(stats)
    ? [
        {
          label: `${showStat(stats.future)} ${t("schedule.upcoming")}`,
          value: "future",
        },
        {
          label: `${showStat(stats.today)} ${t("schedule.today")}`,
          value: "today",
        },
        {
          label: `${showStat(stats.week)} ${t("schedule.week")}`,
          value: "week",
        },
        {
          label: `${showStat(stats.month)} ${t("schedule.month")}`,
          value: "month",
        },
        {
          label: `${showStat(stats.past)} ${t("schedule.past")}`,
          value: "past",
        },
      ]
    : [];

  return (
    <>
      <hr className={styles.divisor} />
      <Select
        className={styles.select}
        onChange={onChange}
        value={currentStatus}
        disabled={disabled}
        fullWidth
        options={options}
      />
    </>
  );
};

export default StatusFilter;
