"use strict";

import React from "react";
import moment from "moment-timezone";
import { Icon } from "@citifyd/style";

import { formatPhoneNumber } from "../../../../shared/utils/phone-number-formatting";
import Table from "../../../../shared/react/components/Table";
import GuestBadge from "../../../../shared/react/components/GuestBadge";
import { useTranslator, useService } from "../../../../shared/react/hooks";
import styles from "./PermitsTable.module.scss";
import { permitStatus } from "../../../permit/utils";
import { selectionToParams } from "../PermitsFilters/utils";

const PermitsTable = ({
  data,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  currentFilter = {},
}) => {
  const t = useTranslator();
  const $state = useService("$state");

  const renderColumnTitle = (label, value) => (
    <span className={styles.columnTitle}>
      {label}
      {sortBy === value && (
        <Icon
          appearance="white"
          icon={sortDirection === "DESC" ? "caret-down" : "caret-up"}
          pull="right"
        ></Icon>
      )}
    </span>
  );

  const getColumnOnClick = (value) => () => {
    setSortBy(value);

    if (sortBy === value) {
      setSortDirection((old) => (old === "ASC" ? "DESC" : "ASC"));
    } else {
      setSortDirection("DESC");
    }
  };

  const tableHeaderProps = {
    sortable: true,
    verticalAlign: "middle",
  };

  const columns = [
    {
      key: "phoneNumber",
      nowrap: "nowrap",
      value: renderColumnTitle(t("permits.permitsTable.phone"), "PHONE_NUMBER"),
      onClick: getColumnOnClick("PHONE_NUMBER"),
      ...tableHeaderProps,
    },
    {
      key: "user",
      value: renderColumnTitle(t("permits.permitsTable.parker"), "USER"),
      onClick: getColumnOnClick("USER"),
      ...tableHeaderProps,
    },
    {
      key: "property",
      value: renderColumnTitle(t("permits.permitsTable.property"), "LOT"),
      onClick: getColumnOnClick("LOT"),
      ...tableHeaderProps,
    },
    {
      key: "license",
      value: renderColumnTitle(t("permits.permitsTable.license"), "VEHICLE"),
      onClick: getColumnOnClick("VEHICLE"),
      ...tableHeaderProps,
    },
    {
      key: "requester",
      value: renderColumnTitle(
        t("permits.permitsTable.requester"),
        "REQUESTER"
      ),
      onClick: getColumnOnClick("REQUESTER"),
      ...tableHeaderProps,
    },
    {
      key: "start",
      value: renderColumnTitle(t("permits.permitsTable.start"), "START_TIME"),
      onClick: getColumnOnClick("START_TIME"),
      ...tableHeaderProps,
    },
    {
      key: "end",
      value: renderColumnTitle(t("permits.permitsTable.end"), "END_TIME"),
      onClick: getColumnOnClick("END_TIME"),
      ...tableHeaderProps,
    },
    {
      key: "status",
      value: renderColumnTitle(t("permits.permitsTable.status"), "STATUS"),
      onClick: getColumnOnClick("STATUS"),
      ...tableHeaderProps,
    },
  ];

  const getDate = (date, timezoneName) => moment.tz(date, timezoneName);

  const permits = data.map(({ node: permit }) => ({
    onClick: () =>
      $state.go("permit", {
        permitId: permit.id,
        ...selectionToParams(currentFilter),
      }),
    phoneNumber: {
      value:
        permit?.phoneNumber.length > 2
          ? formatPhoneNumber(permit.phoneNumber, permit.phoneCountryCode)
          : "—",
      verticalAlign: "middle",
    },
    user: {
      value: permit.user?.name || <GuestBadge />,
      verticalAlign: "middle",
    },
    property: {
      value: permit.lot?.name,
      verticalAlign: "middle",
    },
    license: {
      value: permit.vehicle?.license || "—",
      verticalAlign: "middle",
    },
    requester: {
      value: permit.requester?.name || "—",
      verticalAlign: "middle",
    },
    start: {
      value: t("defaultFormats.shortDatetime", {
        date: getDate(permit.startTime, permit.lot?.timezoneName),
      }),
      verticalAlign: "middle",
      nowrap: "nowrap",
    },
    end: {
      value: t("defaultFormats.shortDatetime", {
        date: getDate(permit.endTime, permit.lot?.timezoneName),
      }),
      verticalAlign: "middle",
      nowrap: "nowrap",
    },
    status: {
      value: (
        <span className={styles.status}>
          {t(`permits.status.${permitStatus[permit.status]}`)}
        </span>
      ),
      verticalAlign: "middle",
    },
  }));

  return (
    <Table
      responsive
      striped
      columns={columns}
      data={permits}
      showIconRight
      verticalAlignIconRight="middle"
    />
  );
};

export default PermitsTable;
