"use strict";

import { useFormik } from "formik";
import * as yup from "yup";
import {
  createStripeConnectedAccount,
  updateStripeConnectedAccount,
} from "../../api";
import { getService } from "../../react/utils";
import { showAlert, showErrorAlert } from "../../services/helper";
import { useStripeStatus } from "../../react/contexts/stripeStatus";
import { changedBankInfo, validate } from "./functions/validate";

const useEditPayment = ({
  onClose,
  country,
  stripe,
  previousBankAccount,
  trackLoading,
  stripeConnectedAccountRef,
  untrackLoading,
}) => {
  const $state = getService("$state");
  const { clearStripeStatus } = useStripeStatus();

  return useFormik({
    initialValues: {
      accountNumber: "",
      routingNumber: "",
      accountHolderName: "",
      isRoutingNumberRequired: false,
      error: false,
    },

    validationSchema: yup.object().shape({
      accountNumber: yup.string().required(),
      routingNumber: yup
        .string()
        .when("isRoutingNumberRequired", (isRoutingNumberRequired, schema) => {
          if (isRoutingNumberRequired) {
            return schema.required();
          } else {
            return schema;
          }
        }),
      accountHolderName: yup.string().required(),
    }),

    onSubmit: async (bankAccount, actions) => {
      if (
        !validate({
          bankAccount,
          previousBankAccount,
          actions,
        })
      ) {
        return false;
      }

      trackLoading("submitting");

      if (
        changedBankInfo({
          previousBankAccount,
          bankAccount,
        })
      ) {
        const countryCode = country.isoCode;
        const currency = country.currency;

        const tokenData = {
          country: countryCode.toUpperCase(),
          currency: currency.toUpperCase(),
          account_number: bankAccount.accountNumber,
          account_holder_type: bankAccount.accountHolderType,
          account_holder_name: bankAccount.accountHolderName,
        };

        if (bankAccount.routingNumber) {
          tokenData.routing_number = bankAccount.routingNumber;
        }

        try {
          const result = await stripe.current.createToken(
            "bank_account",
            tokenData
          );

          if (result.error) {
            throw result.error;
          }

          const bankAccountToken = result.token.id;

          let stripeConnectedAccount = null;

          if (!stripeConnectedAccountRef.current) {
            stripeConnectedAccount = await createStripeConnectedAccount({
              bankAccountToken,
              countryCode: country.isoCode,
            });
          } else if (bankAccountToken) {
            stripeConnectedAccount = await updateStripeConnectedAccount(
              stripeConnectedAccountRef.current.stripeAccountId,
              bankAccountToken
            );
          }

          if (
            shouldRedirectToStripeAccountVerification({
              previousBankAccount,
              stripeConnectedAccount,
            })
          ) {
            clearStripeStatus();
            onClose();
            $state.go("stripe-account-setup");
          } else {
            clearStripeStatus();
            onClose();
          }
        } catch (err) {
          console.log("Stripe create bank account token error", err);

          if (err.code === "account_number_invalid") {
            err.message = t("modalEditPayment.errors.invalidAccountNumber");
          }

          if (err.code === "routing_number_invalid") {
            err.message = t("modalEditPayment.errors.invalidRoutingNumber");
          }

          if (err.message.match(/must only contain katakana/i)) {
            err.message = t("modalEditPayment.errors.katakanaOnlyRequired");
          }

          if (err.message) {
            showAlert(err.message);
          } else {
            showErrorAlert(err.data);
          }
        } finally {
          untrackLoading("submitting");
        }
      }

      untrackLoading("submitting");
      onClose();
    },
  });
};

const shouldRedirectToStripeAccountVerification = ({
  previousBankAccount,
  stripeConnectedAccount,
}) => {
  // If this is the first bank account created, we should always redirect the user to the Stripe verification
  if (!previousBankAccount) {
    return true;
  }

  // Otherwise, only redirect if Stripe says the account has currently due requirements
  return (
    stripeConnectedAccount && stripeConnectedAccount.hasCurrentlyDueRequirements
  );
};

export default useEditPayment;
