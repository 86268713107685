import { useState } from "react";
import { getUserReservations } from "../../../../shared/api";

import { useService } from "../../../../shared/react/hooks";
import { transformReservations } from "./utils";

const useReservations = () => {
  const { id } = useService("$stateParams");
  const [reservations, setReservations] = useState(null);
  const [reservationsLoaded, setReservationsLoaded] = useState(false);
  const [reservationsError, setReservationsError] = useState(false);

  const fetchReservations = async () => {
    try {
      const reservations = await getUserReservations(id);
      setReservations(transformReservations(reservations));
    } catch (err) {
      setReservationsError(true);
    } finally {
      setReservationsLoaded(true);
    }
  };

  return {
    fetchReservations,
    reservations,
    reservationsLoaded,
    reservationsError,
  };
};

export default useReservations;
