import moment from "moment-timezone";
import { transformToDecimal } from "../../../../../../../shared/services/currencies";

export const getTimeMomentObject = (time) => {
  if (time) {
    const [hours, minutes] = time.split(":");
    return moment().set({ hours, minutes });
  }
};

export const createWeeklySchedule = ({ schedule, rateType, currency }) => {
  if (schedule.length === 0) {
    return { data: null, editData: null, editing: false };
  }

  const lot = schedule[0].lots[0];

  return {
    data: {
      startTime: formatTime(schedule[0].start, schedule[0].timezoneName),
      endTime: formatTime(schedule[0].end, schedule[0].timezoneName),
      maxRate: lot.rate.maxRate
        ? transformToDecimal(lot.rate.maxRate, currency)
        : "",
      rateType: lot.rate.type,
      [`rateType-${rateType}`]: lot.rate.type,
      rateValue: transformToDecimal(lot.rate.value, currency),
      spaces: lot.spaces[0].max,
      discount: {
        enabled: Boolean(lot.rate.discount),
        maxRate: lot.rate.discount
          ? transformToDecimal(lot.rate.discount.maxRate, currency)
          : undefined,
        arriveBefore: lot.rate.discount
          ? lot.rate.discount.arriveBefore.slice(0, 5)
          : "08:00",
      },
    },
    editData: null,
    editing: false,
  };
};

const formatTime = (data, timezone) => {
  return moment.tz(`${data}`, `${timezone}`).format("HH:mm");
};
