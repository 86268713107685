"use strict";

import React, { useMemo } from "react";
import { sum } from "lodash";
import moment from "moment-timezone";
import { Text } from "@citifyd/style";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import GuestBadge from "../../../../shared/react/components/GuestBadge";
import Table from "../../../../shared/react/components/Table";
import { getVehicleLicense } from "../../../../shared/utils/vehicles";
import Permissions from "../../../../shared/services/permissions";
import { formatCurrency } from "../../utils";
import { useTableColumns } from "./utils";
import styles from "./ReservedParkingPass.module.scss";
import { isSameDate } from "../../../../shared/services/helper";

const ReservedParkingPass = ({ reservedParkingPasses }) => {
  const t = useTranslator();

  const columns = useTableColumns();
  const { date } = useService("$stateParams");
  const $state = useService("$state");

  const userMayOpenReservedParkingPassPage =
    Permissions.userMayOpenReservedParkingPassPage();

  const formattedData = useMemo(() => {
    let reservedParkingList = reservedParkingPasses.map(
      (reservedParkingPass) => {
        const revenueAmount = reservedParkingPass.netRevenueAmount
          ? formatCurrency(reservedParkingPass.netRevenueAmount)
          : t("dailyRevenue.cancelled");

        reservedParkingPass.startTimeOnSelectedDate = isSameDate(
          reservedParkingPass.startTime,
          moment.tz(date, reservedParkingPass.lot.timezoneName),
          reservedParkingPass.lot.timezoneName
        );

        reservedParkingPass.endTimeOnSelectedDate = isSameDate(
          reservedParkingPass.endTime || reservedParkingPass.expirationTime,
          moment.tz(date, reservedParkingPass.lot.timezoneName),
          reservedParkingPass.lot.timezoneName
        );

        return {
          ...(userMayOpenReservedParkingPassPage && {
            onClick: () =>
              $state.go(
                "reserved-parking-pass",
                { id: reservedParkingPass.id },
                { enableBackLink: true }
              ),
          }),
          name: reservedParkingPass.user?.name ? (
            reservedParkingPass.user?.name
          ) : (
            <GuestBadge />
          ),
          license: getVehicleLicense(reservedParkingPass.vehicle),
          lotName: reservedParkingPass.lot?.name,
          startTime: t("defaultFormats.shortDatetime", {
            date: moment.tz(
              reservedParkingPass.startTime,
              reservedParkingPass.lot?.timezoneName
            ),
          }),
          endTime: t("defaultFormats.shortDatetime", {
            date: moment.tz(
              reservedParkingPass.endTime,
              reservedParkingPass.lot?.timezoneName
            ),
          }),
          revenueAmount: {
            value: revenueAmount,
            textAlign: "right",
            textColor:
              reservedParkingPass.netRevenueAmount < 0 ? "tertiary" : "default",
          },
        };
      }
    );

    reservedParkingList = [
      ...reservedParkingList,
      {
        name: <b>{t("dailyRevenue.total")}</b>,
        lotName: null,
        time: null,
        type: null,
        revenueAmount: {
          value: (
            <b>
              {formatCurrency(sum(reservedParkingPasses, "netRevenueAmount"))}
            </b>
          ),
          textAlign: "right",
        },
      },
    ];

    return reservedParkingList;
  }, [reservedParkingPasses]);

  return (
    <>
      <div className={styles.revenueContent}>
        <Text gutterBottom variant="h3">
          {t("dailyRevenue.reservedParkingParkers")}
        </Text>

        <Table
          className={styles.table}
          data={formattedData}
          columns={columns}
          borderBottom
          striped
          clickable
        />
      </div>
    </>
  );
};

export default ReservedParkingPass;
