import { Checkbox, Button, LoadingIndicator, Modal } from "@citifyd/style";
import { useState } from "react";
import { updateUser } from "../../api";
import { useAuthentication } from "../../react/contexts/authentication";
import { useTranslator } from "../../react/hooks";
import { showErrorAlert } from "../../services/helper";

const ReceiveDailyPropertyReportPDFViaEmailModal = ({ open, onClose }) => {
  const t = useTranslator();
  const { user: loggedUser, reloadUser } = useAuthentication();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [receivePDF, setReceivePdf] = useState(
    loggedUser.receiveDailyRevenueByPropertyReport
  );

  const save = async () => {
    try {
      setIsSubmitting(true);
      await updateUser(loggedUser.id, {
        receiveDailyRevenueByPropertyReport: receivePDF,
      });
      reloadUser();
      onClose({ userUpdated: true });
    } catch (response) {
      showErrorAlert(response.data);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <Modal.Title>{t("accountInfo.receiveDailyPropertyRevenue")}</Modal.Title>
      <Modal.Content>
        <Checkbox
          gutterBottom
          checked={receivePDF}
          onChange={(e) => setReceivePdf(e.target.checked)}
          label={t("accountInfo.checkThisBoxToReceiveThisPeportDaily")}
        />
      </Modal.Content>

      {isSubmitting && (
        <LoadingIndicator isLoading={isSubmitting} withOverlay />
      )}

      <Modal.Actions>
        <Button
          onMouseDown={onClose}
          type="button"
          textColor="white"
          appearance="secondary"
          fullWidth
          justifyContent="center"
          uppercase
        >
          {t("commonButtons.cancel")}
        </Button>
        <Button
          onMouseDown={() => save()}
          type="button"
          fullWidth
          justifyContent="center"
          uppercase
        >
          {t("commonButtons.continue")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ReceiveDailyPropertyReportPDFViaEmailModal;
