"use strict";

import React, { useMemo } from "react";
import { sum } from "lodash";
import { Text } from "@citifyd/style";
import { useTranslator, useService } from "../../../../shared/react/hooks";
import Table from "../../../../shared/react/components/Table";
import { formatCurrency } from "../../utils";
import styles from "./Summary.module.scss";

const Summary = ({ data }) => {
  const t = useTranslator();
  const $state = useService("$state");

  const formattedData = useMemo(() => {
    const currency = $state.params?.currency;

    if (!currency) return;

    const onDemandValue = sum(data.reservations, "netRevenueAmount");
    const reservedParkingValue = sum(
      data.reservedParkingPasses,
      "netRevenueAmount"
    );
    const eventsValue = sum(data.tickets, "netRevenueAmount");
    const bundlesValue = sum(data.bundlePurchases, "netRevenueAmount");
    const monthlyValue = sum(data.subscriptions, "netRevenueAmount");
    const total =
      onDemandValue +
      eventsValue +
      bundlesValue +
      monthlyValue +
      reservedParkingValue;

    return [
      {
        type: t("dailyRevenue.onDemandPasses"),
        revenue: {
          value: formatCurrency(onDemandValue, currency),
          textAlign: "right",
          textColor: onDemandValue < 0 ? "tertiary" : "default",
        },
      },
      {
        type: t("dailyRevenue.reservedParkingPasses"),
        revenue: {
          value: formatCurrency(reservedParkingValue, currency),
          textAlign: "right",
          textColor: eventsValue < 0 ? "tertiary" : "default",
        },
      },
      {
        type: t("dailyRevenue.eventPasses"),
        revenue: {
          value: formatCurrency(eventsValue, currency),
          textAlign: "right",
          textColor: eventsValue < 0 ? "tertiary" : "default",
        },
      },
      {
        type: t("dailyRevenue.bundles"),
        revenue: {
          value: formatCurrency(bundlesValue, currency),
          textAlign: "right",
          textColor: bundlesValue < 0 ? "tertiary" : "default",
        },
      },
      {
        type: t("dailyRevenue.monthlyParkingPasses"),
        revenue: {
          value: formatCurrency(monthlyValue, currency),
          textAlign: "right",
          textColor: monthlyValue < 0 ? "tertiary" : "default",
        },
      },
      {
        type: <b>{t("dailyRevenue.total")}</b>,
        revenue: {
          value: <b>{formatCurrency(total, currency)}</b>,
          textAlign: "right",
          textColor: total < 0 ? "tertiary" : "default",
        },
      },
    ];
  }, [data, $state.params]);

  const columns = [
    {
      value: t("dailyRevenue.type"),
      key: "type",
    },
    {
      value: t("dailyRevenue.revenue"),
      textAlign: "right",
      key: "revenue",
    },
  ];

  return (
    <>
      <div className={styles.revenueContent}>
        <Text gutterBottom variant="h3">
          {t("dailyRevenue.summary")}
        </Text>

        <Table
          className={styles.table}
          data={formattedData}
          columns={columns}
          striped
          borderBottom
        />
      </div>
    </>
  );
};

export default Summary;
