"use strict";

import React from "react";
import { useTranslator } from "../../hooks";
import { camelCase, kebabCase } from "lodash";
import { Badge } from "@citifyd/style";

export default function AdvancedRateDefinitionStatusBadge({ status }) {
  const t = useTranslator();

  const label = t("advancedRateDefinitions.statuses." + camelCase(status));

  const getBadgeProps = () => {
    let props;

    switch (kebabCase(status)) {
      case "published":
        props = { appearance: "success", textAppearance: "white" };
        break;
      case "draft":
      case "unsaved":
        props = { appearance: "gray", textAppearance: "gray_darker" };
        break;
      case "past-version":
        props = { appearance: "black", textAppearance: "white" };
        break;
      default:
    }

    return props;
  };

  return <Badge {...getBadgeProps()}>{label}</Badge>;
}
