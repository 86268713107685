"use strict";

import React from "react";
import { useTranslator } from "../../hooks";
import { Button, LoadingIndicator } from "@citifyd/style";

import "./style.scss";

function LoadingManager({
  isLoading,
  hasError,
  errorMessage,
  loadingMessage,
  onClickTryAgain,
}) {
  const t = useTranslator();

  if (hasError) {
    return (
      <div className="component-loading-manager component-loading-manager--error">
        <p className="component-loading-manager__message">
          {errorMessage || t("commonErrors.connectionProblem")}
        </p>

        {onClickTryAgain && (
          <Button
            justifyContent="center"
            letterSpacing
            shadow
            size="small"
            uppercase
            onClick={onClickTryAgain}
          >
            {t("commonButtons.tryAgain")}
          </Button>
        )}
      </div>
    );
  } else if (isLoading) {
    return (
      <div className="component-loading-manager component-loading-manager--loading">
        <LoadingIndicator />
        {loadingMessage && (
          <p className="component-loading-manager__message">{loadingMessage}</p>
        )}
      </div>
    );
  }

  return null;
}

export default LoadingManager;
