import { uniq } from "lodash";
import CurrencyFormatter from "@citifyd/currency-formatter";

import { getService } from "../../shared/react/utils";
import { getCurrentLanguage } from "../../shared/services/languages";

import { getAllProperties } from "../../shared/api";

export const fetchLots = (user) => {
  return getAllProperties({
    params: user.isAdmin ? { all: true } : {},
  });
};

export const formatCurrency = (value) => {
  const language = getCurrentLanguage();
  const { currency } = getService("$stateParams");

  return CurrencyFormatter.format(value, {
    currency: currency,
    language,
  });
};

export const getLotIds = (transactions) => {
  return uniq(
    [
      ...transactions.subscriptions.map(
        (subscription) => subscription?.plan?.lotId
      ),
      ...transactions.reservations.map((reservation) => reservation?.lot?.id),
      ...transactions.bundlePurchases.map(
        (bundlePurchase) => bundlePurchase?.bundle?.lot?.id
      ),
      ...transactions.reservedParkingPasses.map(
        (reservedParkingPass) => reservedParkingPass?.lot?.id
      ),
      ...transactions.tickets.map((ticket) => ticket?.lot?.id),
    ].filter((id) => id)
  );
};

export const filterTransactions = ({ transactions, lotId }) => {
  return {
    subscriptions: transactions.subscriptions.filter(
      (subscription) => subscription?.plan?.lotId === lotId
    ),
    reservations: transactions.reservations.filter(
      (reservation) => reservation?.lot?.id === lotId
    ),
    bundlePurchases: transactions.bundlePurchases.filter(
      (bundlePurchase) => bundlePurchase?.bundle?.lot?.id === lotId
    ),
    reservedParkingPasses: transactions.reservedParkingPasses.filter(
      (reservedParkingPass) => reservedParkingPass?.lot?.id === lotId
    ),
    tickets: transactions.tickets.filter((ticket) => ticket?.lot?.id === lotId),
  };
};
