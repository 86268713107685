"use strict";

import React, { useEffect, useState, useMemo, useRef } from "react";
import { Grid, Text } from "@citifyd/style";
import Table from "../../../../shared/react/components/Table";
import CSVDownload from "../../../../shared/react/components/CSVDownload";
import GenerateReportModal from "../../../../shared/modals/GenerateReportModal";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import {
  generateOrderForClickEvent,
  sort,
} from "../../../../shared/react/sorting";
import { getEventReportCsv } from "../../../../shared/api";
import SearchField from "./SearchField";
import StatusButtons from "./StatusButtons";
import StatusSelector from "./StatusSelector";
import PropertySelector from "./PropertySelector";
import {
  loadEventData,
  getData,
  getStats,
  selectDefaultFilters,
} from "./utils";

import styles from "./Purchases.module.scss";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";
import { useModal } from "../../../../shared/react/contexts/modal";

const Purchases = ({ event, ...rest }) => {
  const timer = useRef();

  const t = useTranslator();
  const { openModal } = useModal();
  const $stateParams = useService("$stateParams");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [lots, setLots] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [search, setSearch] = useState($stateParams?.search);
  const [propertyId, setPropertyId] = useState($stateParams?.lot || "");
  const [selectedStatus, setSelectedStatus] = useState($stateParams?.status);
  const [sortedTickets, setSortedTickets] = useState([]);
  const [stats, setStats] = useState({});
  const [tickets, setTickets] = useState([]);
  const [orders, setOrders] = useState([["createdAt", "desc"]]);
  const [lastHash, setLastHash] = useState();
  const { user: currentUser, permissions } = useAuthentication();

  const handleSort = (event, property) => {
    setOrders(
      generateOrderForClickEvent({
        event,
        currentOrder: orders,
        propertyName: property,
      })
    );
  };

  const { columns, data } = useMemo(
    () =>
      getData({
        tickets: sortedTickets,
        search,
        handleSort,
        orders,
        permissions,
      }),
    [sortedTickets, search, orders]
  );

  const generateCsvClicked = () => {
    const filterDescription = t("event.reportName", {
      filteredPassesDescription: t(
        `event.filterDescriptions.${selectedStatus}`
      ),
      eventName: event.name,
    });

    const reportCsvRequest = getEventReportCsv(event.id, {
      timezoneName: currentUser.timezoneName,
      lotId: $stateParams?.lot,
      category: selectedStatus,
    });

    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription,
      reportCsvRequest,
    });
  };

  const fetchEvents = async ({ lastHash }) => {
    const eventData = await loadEventData({ lastHash });
    setLots(eventData?.lots);
    setLastHash(eventData?.lastHash);
    setTickets(eventData?.rawTickets);
    setHasError(eventData?.error);
    refreshTimeout({ lastHash });
    setIsLoading(false);
  };

  const refreshTimeout = () => {
    clearTimeout(timer?.current);
    timer.current = setTimeout(() => {
      fetchEvents({
        lastHash,
      });
    }, 5000);
  };

  useEffect(
    () => setSortedTickets(sort({ items: filteredTickets, order: orders })),
    [filteredTickets, orders]
  );

  useEffect(() => {
    setStats(getStats({ tickets, lots, propertyId }));
  }, [tickets, lots, propertyId]);

  useEffect(() => {
    let filteredTickets;

    if (selectedStatus === "sold") {
      filteredTickets = tickets.filter(
        (item) => item.statusTag !== "cancelled"
      );
    } else {
      filteredTickets = tickets.filter(
        (item) => item.statusTag === selectedStatus
      );
    }

    if (propertyId) {
      filteredTickets = filteredTickets.filter(
        (item) => parseInt(item.lotId) === parseInt(propertyId)
      );
    }

    setFilteredTickets(filteredTickets);
  }, [selectedStatus, propertyId, tickets]);

  useEffect(() => {
    setSelectedStatus($stateParams.status);
  }, [$stateParams.status]);

  useEffect(() => {
    selectDefaultFilters();
    fetchEvents({ lastHash });

    return () => {
      clearTimeout(timer?.current);
    };
  }, []);

  if (isLoading || hasError) {
    return (
      <div {...rest}>
        <LoadingManager isLoading={isLoading} hasError={hasError} />
      </div>
    );
  }

  return (
    <div {...rest}>
      <Grid.Row justify="space-between">
        <Grid.Col sm={12} md={6} lg={4}>
          <PropertySelector
            disabled={isLoading}
            lots={lots}
            setPropertyId={setPropertyId}
            propertyId={propertyId}
          />
        </Grid.Col>
        <Grid.Col sm={12} md={5} lg={3}>
          <StatusSelector
            stats={stats}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            disabled={isLoading}
          />
          <div className={styles.searchAndCSV}>
            <SearchField setSearch={setSearch} search={search} />
            <CSVDownload
              disabled={isLoading}
              className={styles.csv}
              onClick={generateCsvClicked}
            />
          </div>
        </Grid.Col>
      </Grid.Row>
      <StatusButtons
        stats={stats}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        disabled={isLoading}
      />
      <Text variant="subtitle" weight="600" gutterBottom>
        {t(`event.filterDescriptions.${selectedStatus}`)}
      </Text>

      {data && data?.length === 0 && (
        <Text variant="subtitle" className={styles.noPasses}>
          {t(`event.noPasses`)}
        </Text>
      )}

      {data?.length > 0 && (
        <Table
          columns={columns}
          data={data}
          striped
          responsive
          sortable
          clickable
        />
      )}
    </div>
  );
};

export default Purchases;
