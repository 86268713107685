"use strict";

import React, { useMemo } from "react";
import CurrencyFormatter from "@citifyd/currency-formatter";
import _ from "lodash";
import { Text } from "@citifyd/style";

import BoxContent from "../BoxContent";
import Table from "../Table";
import TransactionExtraInformation from "../TransactionExtraInformation";

import { useTranslator } from "../../hooks";
import { getCurrentLanguage } from "../../../services/languages";

function CancellationBox({ reservedParkingPass }) {
  const t = useTranslator();
  const language = getCurrentLanguage();

  const refundAmount = useMemo(() => {
    if (reservedParkingPass.cancellation.refunded) {
      const amount = _(reservedParkingPass.cancellation.transactions)
        .filter({ type: "REFUND" })
        .sum("totalAmount");
      return -amount;
    } else {
      return null;
    }
  }, [reservedParkingPass]);

  const refundValue =
    refundAmount !== null
      ? CurrencyFormatter.format(refundAmount, {
          currency: reservedParkingPass.currency,
          language,
        })
      : t("transaction.inProcess");

  return (
    <BoxContent>
      <BoxContent.Header
        appearance="tertiary"
        title={t("transaction.cancellationInfo")}
      />
      <BoxContent.Content>
        <Table
          cleanMode
          showHeader={false}
          columns={[{ key: "title" }, { key: "amount" }]}
          data={[
            {
              borderBottom: true,
              title: <Text weight="600">{t("transaction.cancelledAt")}</Text>,
              amount: {
                value: t("defaultFormats.datetime", {
                  date: reservedParkingPass.cancellation.createdAt,
                }),
                textAlign: "right",
              },
            },
            {
              title: (
                <Text weight="600">{t("transaction.amountRefunded")}</Text>
              ),
              amount: {
                value: refundValue,
                textAlign: "right",
              },
            },
          ]}
        />
      </BoxContent.Content>

      {reservedParkingPass.cancellation.user.id !==
        reservedParkingPass.user.id && (
        <BoxContent.Footer>
          <Text bold gutterBottom>
            {t("transaction.fulfilledBy", {
              name: reservedParkingPass.cancellation.user.name,
            })}
          </Text>
          {reservedParkingPass.cancellation.reason && (
            <div>
              <Text>{t("transaction.ifPasses")}</Text>
              <Text>{reservedParkingPass.cancellation.reason}</Text>
              <Text italic>
                <TransactionExtraInformation
                  data={reservedParkingPass.cancellation}
                />
              </Text>
            </div>
          )}
        </BoxContent.Footer>
      )}
    </BoxContent>
  );
}

export default CancellationBox;
