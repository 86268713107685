"use strict";

import { isEmpty } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { Select, Grid, DatePicker } from "@citifyd/style";

import { useTranslator } from "../../../../../shared/react/hooks";
import {
  getFilterSelectionFromState,
  getLotsOptions,
  getLotsIdsObjectByParams,
  getLotsByParams,
  updateFilters,
  onDateChange,
  onPropertiesChange,
} from "../../../utils";

import { getFormattedDate } from "../../../../../shared/utils/date-time-formatting";

import CSVDownload from "../../../../../shared/react/components/CSVDownload";
import { getCurrentLanguage } from "../../../../../shared/services/languages";
import styles from "./Filter.module.scss";
import { useAuthentication } from "../../../../../shared/react/contexts/authentication";
import Permissions from "../../../../../shared/services/permissions";

const RatesFilter = ({
  lots,
  onClickGenerateCsv,
  disabled,
  organizationsOptions,
}) => {
  const t = useTranslator();
  const { user, permissions } = useAuthentication();
  const [selection, setSelection] = useState({});

  const [selectedLot, setSelectedLot] = useState();
  const [error, setError] = useState();
  const language = getCurrentLanguage();

  const lotsOptions = useMemo(
    () =>
      getLotsOptions({
        lots,
        t,
        showAllPropertiesOption: false,
        organizationId: selection.organizationId,
      }),
    [lots, selection.organizationId]
  );

  useEffect(() => {
    const params = getFilterSelectionFromState({ user });
    const options = getLotsOptions({
      lots,
      t,
      showAllPropertiesOption: false,
    });

    if (params.lots) {
      const lotIds = getLotsIdsObjectByParams(params.lots);

      if (lotIds?.length === 1) {
        setSelectedLot(getLotsByParams({ params, lots: options })[0]?.value);
      }
    }

    setSelection(params);
  }, []);

  useEffect(() => {
    setError(!selection?.ratesDate);
    if (!isEmpty(selection)) {
      updateFilters({ selection, lots, user });
    }
  }, [selection]);

  const generateCsvClicked = () => {
    if (!error && onClickGenerateCsv) {
      onClickGenerateCsv();
    }
  };

  return (
    <Grid.Row marginBottom>
      <Grid.Col md={11} className={styles.boxFilters}>
        <Grid.Row>
          {Permissions.userMaySeeParkingOperatorFilter(permissions) && (
            <Grid.Col md={3}>
              <Select
                fullWidth
                labelSize="small"
                label={t("reports.commonFilter.fields.parkingOperator")}
                options={organizationsOptions}
                value={selection.organizationId ?? "*"}
                onChange={(e) => {
                  setSelection({
                    ...selection,
                    organizationId: e.target.value,
                  });
                }}
              />
            </Grid.Col>
          )}

          <Grid.Col className={styles.column} sm={12} md={3}>
            <Select
              fullWidth
              name="lots"
              labelSize="small"
              label={t("reports.commonFilter.fields.property")}
              options={lotsOptions}
              searchable
              value={selectedLot || ""}
              onChange={(e) => {
                setSelection(
                  onPropertiesChange({ items: [e.target], selection })
                );
                setSelectedLot(e.target?.value);
              }}
            />
          </Grid.Col>
          <Grid.Col className={styles.column} sm={12} md={3}>
            <Grid.Row gutterWidth={4}>
              <Grid.Col sm={6}>
                <DatePicker
                  locale={language}
                  maxDate={new Date()}
                  label={t("reports.ratesFilter.fields.date")}
                  labelSize="small"
                  onChange={(date) =>
                    setSelection(
                      onDateChange({ field: "ratesDate", date, selection })
                    )
                  }
                  selectedDate={getFormattedDate(selection.ratesDate)}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
      <Grid.Col sm={12} md={1} className={styles.boxCSV}>
        <CSVDownload
          disabled={error || disabled}
          onClick={generateCsvClicked}
        />
      </Grid.Col>
    </Grid.Row>
  );
};

export default RatesFilter;
