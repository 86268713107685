"use strict";

import React from "react";
import { Grid } from "@citifyd/style";

import styles from "./NavBar.module.scss";

export default function NavBar({ children }) {
  return (
    <div className={`${styles.topNotificationBar} fade-animation`}>
      <Grid>
        <Grid.Row>
          <Grid.Col sm={12}>{children}</Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
}
