"use strict";

import React, { useState } from "react";
import classNames from "classnames";
import { Button, Text, Grid } from "@citifyd/style";
import { useTranslator } from "../../../../../shared/react/hooks";
import { getService } from "../../../../../shared/react/utils";
import { showStat } from "../utils";

import styles from "./StatusButtons.module.scss";

const StatusButton = ({ onChange, counter, label, isActive, disabled }) => {
  return (
    <Button
      onClick={onChange}
      appearance={isActive ? "primary" : "secondary"}
      uppercase
      disabled={disabled}
      size="small"
      fullWidth
      justifyContent="center"
      className={classNames(styles.statusButton, {
        [styles.active]: isActive,
      })}
    >
      <Text variant="h1" component="span" weight="700">
        {showStat(counter)}
      </Text>
      <br />
      <Text component="span" weight="600">
        {label}
      </Text>
    </Button>
  );
};

const StatusFilter = ({
  stats,
  disabled,
  selectedStatus,
  setSelectedStatus,
}) => {
  const t = useTranslator();
  const $state = getService("$state");

  const onChange = (status) => {
    setSelectedStatus(status);
    $state.transitionTo(
      $state.current.name,
      {
        ...$state.params,
        status: status?.toLowerCase(),
      },
      { location: "replace", notify: false, inherit: false }
    );
  };

  return (
    <div className={styles.container}>
      <hr />
      <Grid.Row gutterWidth={6}>
        <Grid.Col>
          <StatusButton
            disabled={disabled}
            counter={stats.sold}
            onChange={() => onChange("sold")}
            isActive={selectedStatus === "sold"}
            label={t("event.sold")}
          />
        </Grid.Col>
        <Grid.Col>
          <StatusButton
            disabled={disabled}
            counter={stats.parked}
            onChange={() => onChange("parked")}
            isActive={selectedStatus === "parked"}
            label={t("event.parked")}
          />
        </Grid.Col>
        <Grid.Col>
          <StatusButton
            disabled={disabled}
            counter={stats.transferred}
            onChange={() => onChange("transferred")}
            isActive={selectedStatus === "transferred"}
            label={t("event.transfers")}
          />
        </Grid.Col>
        <Grid.Col>
          <StatusButton
            disabled={disabled}
            counter={stats.cancelled}
            onChange={() => onChange("cancelled")}
            isActive={selectedStatus === "cancelled"}
            label={t("event.cancellations")}
          />
        </Grid.Col>
      </Grid.Row>
      <hr />
    </div>
  );
};

export default StatusFilter;
