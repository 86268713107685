"use strict";

import React, { Fragment } from "react";
import { Button, Text, Grid } from "@citifyd/style";

import ReservationRefundContextProvider, {
  useReservationRefundContext,
} from "./ReservationRefundContext.jsx";
import RefundDestination from "./ReservationRefundDestination.jsx";
import RefundSource from "./ReservationRefundSource.jsx";
import RefundReason from "./ReservationRefundReason.jsx";

import PageHeader from "../../../shared/react/components/PageHeader";
import LoadingManager from "../../../shared/react/components/LoadingManager";
import ReservationFullInformation from "../../../shared/react/components/ReservationFullInformation";
import StickyFooter from "../../../shared/react/components/StickyFooter";

import { getService } from "../../../shared/react/utils";
import { useTranslator } from "../../../shared/react/hooks";

import styles from "./ReservationRefund.module.scss";

function ReservationRefundPage() {
  const { id } = getService("$stateParams");

  return (
    <ReservationRefundContextProvider reservationId={id}>
      <ReservationRefundPageContent />
    </ReservationRefundContextProvider>
  );
}

function ReservationRefundPageContent() {
  const { reservation, refundOptions, isLoading, loadingMessage, hasError } =
    useReservationRefundContext();

  if (isLoading || hasError) {
    return (
      <LoadingManager
        loadingMessage={loadingMessage}
        isLoading={isLoading}
        hasError={hasError}
      />
    );
  }

  return (
    <Grid>
      <ReservationRefundPageTitle />

      {reservation && refundOptions && (
        <Fragment>
          <RefundDestination />
          <RefundSource />
          <RefundReason />
          <TransactionHistory />
          <Footer />
        </Fragment>
      )}
    </Grid>
  );
}

function ReservationRefundPageTitle() {
  const t = useTranslator();
  const { reservationId } = useReservationRefundContext();

  return (
    <PageHeader>
      <PageHeader.Title
        defaultState="reservation"
        defaultParams={{ id: reservationId }}
        title={t("reservationRefundPage.pageTitle")}
      />
    </PageHeader>
  );
}

function TransactionHistory() {
  const t = useTranslator();
  const { reservation } = useReservationRefundContext();

  return (
    <div>
      <Text
        variant="h5"
        gutterBottom
        className={styles.transactionHistoryTitle}
      >
        {t("refundPages.transactionHistory")}
      </Text>

      <ReservationFullInformation reservation={reservation} />
    </div>
  );
}

function Footer() {
  const t = useTranslator();
  const { isLoading, requestRefund } = useReservationRefundContext();

  return (
    <StickyFooter isBlocked={isLoading} justifyContent="flex-end">
      <Button uppercase onMouseDown={requestRefund} extraPadding>
        {t("ticketRefund.refundPass")}
      </Button>
    </StickyFooter>
  );
}

export default ReservationRefundPage;
