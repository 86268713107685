import gql from "graphql-tag";

export default gql`
  query ListPermitPasses($portalUserId: UUID) {
    admin_listPermitPasses(portalUserId: $portalUserId) {
      edges {
        node {
          id
          phoneNumber
          name
          startTime
          endTime
        }
      }
    }
  }
`;
