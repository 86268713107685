export const parseUsers = (users, t) => {
  return users.map((user) => {
    let formattedName = user.name;

    if (user.name && user.isGuest) {
      formattedName = t("users.userNameBillingName", {
        name: user.name,
      });
    }

    return { ...user, formattedName };
  });
};
