"use strict";

import React from "react";
import classNames from "classnames";
import { Button, Text, Grid } from "@citifyd/style";
import { useTranslator } from "../../../../../shared/react/hooks";
import { getService } from "../../../../../shared/react/utils";
import { showStat, getEventStatus } from "../utils";

import styles from "./StatusButtons.module.scss";

const StatusButton = ({ counter, label, status, disabled }) => {
  const $state = getService("$state");
  const currentStatus = getEventStatus($state.params?.status);
  const isActive = currentStatus === status;

  const onChange = () => {
    $state.transitionTo(
      $state.current.name,
      {
        ...$state.params,
        status: status?.toLowerCase(),
      },
      {
        location: "replace",
      }
    );
  };

  return (
    <Button
      onClick={() => onChange()}
      appearance={isActive ? "primary" : "secondary"}
      uppercase
      disabled={disabled}
      size="small"
      fullWidth
      justifyContent="center"
      className={classNames(styles.statusButton, {
        [styles.active]: isActive,
      })}
    >
      <Text variant="h1" component="span" weight="700">
        {showStat(counter)}
      </Text>
      <br />
      <Text component="span" weight="600">
        {label}
      </Text>
    </Button>
  );
};

const StatusFilter = ({ stats, disabled }) => {
  const t = useTranslator();

  return (
    <div className={styles.container}>
      <hr />
      <Grid.Row gutterWidth={6}>
        <Grid.Col>
          <StatusButton
            disabled={disabled}
            status="future"
            counter={stats.future}
            label={t("schedule.upcoming")}
          />
        </Grid.Col>
        <Grid.Col>
          <StatusButton
            disabled={disabled}
            status="today"
            counter={stats.today}
            label={t("schedule.today")}
          />
        </Grid.Col>
        <Grid.Col>
          <StatusButton
            disabled={disabled}
            status="week"
            counter={stats.week}
            label={t("schedule.week")}
          />
        </Grid.Col>
        <Grid.Col>
          <StatusButton
            disabled={disabled}
            status="month"
            counter={stats.month}
            label={t("schedule.month")}
          />
        </Grid.Col>
        <Grid.Col>
          <StatusButton
            disabled={disabled}
            status="past"
            counter={stats.past}
            label={t("schedule.past")}
          />
        </Grid.Col>
      </Grid.Row>
      <hr />
    </div>
  );
};

export default StatusFilter;
