import React, { useState, isValidElement } from "react";
import { Table, Icon } from "@citifyd/style";

const Row = ({
  item,
  noPadding,
  spacing,
  showIconRight,
  verticalAlignIconRight,
  columns,
}) => {
  if (!item) return null;

  const {
    onClick,
    rowId,
    expanded,
    clickable,
    borderBottom,
    borderBottomColor,
    appearance,
    showIcon = true,
    className,
    hover = true,
    ...rest
  } = item;
  const [openExpanded, setOpenExpanded] = useState(false);
  const hasExpanded = Boolean(expanded);

  const renderCells = (item) => {
    const items = columns.map((column) => item[column.key]);

    return items.map((cell, index) => {
      if (!cell) {
        return <Table.Cell key={`cell-${index}-${rowId}`}></Table.Cell>;
      }

      if (!cell?.hide) {
        return (
          <Table.Cell
            key={`cell-${index}-${rowId}`}
            textAlign={cell?.textAlign}
            textColor={cell?.textColor}
            verticalAlign={cell?.verticalAlign}
            appearance={cell?.appearance}
            border={cell?.border}
            colSpan={cell?.colSpan}
            rowSpan={cell?.rowSpan}
            noPadding={noPadding || cell?.noPadding}
            noVerticalPadding={cell?.noVerticalPadding}
            noHorizontalPadding={cell?.noHorizontalPadding}
            nowrap={cell?.nowrap}
            width={cell?.width}
          >
            {cell.value ||
              ((typeof cell === "string" || isValidElement(cell)) && cell)}
          </Table.Cell>
        );
      }
    });
  };

  const isClickable = onClick || (hasExpanded ? clickable : null);

  return (
    <>
      <Table.Row
        id={rowId}
        clickable={hover && isClickable}
        pointer={isClickable}
        appearance={appearance || (openExpanded && "primary")}
        borderBottom={borderBottom}
        borderBottomColor={borderBottomColor}
        spacing={spacing}
        onClick={
          onClick
            ? onClick
            : hasExpanded
            ? () => setOpenExpanded(!openExpanded)
            : null
        }
        className={className}
      >
        {renderCells(rest)}

        {showIconRight && (
          <Table.Cell verticalAlign={verticalAlignIconRight}>
            {showIcon && <Icon icon="chevron-right" pull="right" />}
          </Table.Cell>
        )}
      </Table.Row>
      {hasExpanded && openExpanded && (
        <Table.Row expanded>
          <Table.Cell colSpan="12">{expanded}</Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

export default Row;
