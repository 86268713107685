import _ from "lodash";

import { getTranslator } from "../../../../shared/services/languages";

const calculateCurrentRate = (ticket) => {
  return ticket.rate.value + ticket.rate.fee;
};

const calculateNewRate = (selectedLot) => {
  return selectedLot.rate.value + selectedLot.rate.fee;
};

const calculateRateDifference = (ticket, selectedLot) => {
  const currentRate = calculateCurrentRate(ticket);
  const newRate = calculateNewRate(selectedLot);
  const diff = newRate - currentRate;

  return Math.abs(diff) >= 50 ? diff : 0;
};

const getTitle = (ticket, selectedEvent, selectedLot) => {
  const t = getTranslator();
  let localeKey;

  if (ticket.event.id === selectedEvent.id) {
    localeKey = "ticketExchange.confirmLotExchange";
  } else {
    localeKey = "ticketExchange.confirmExchange";
  }

  const rateDifference = calculateRateDifference(ticket, selectedLot);

  if (rateDifference > 0) {
    localeKey += "AndPaymentMethod";
  } else if (rateDifference < 0) {
    localeKey += "AndRefundMethod";
  }

  return t(localeKey);
};

const getReasonLabel = (ticket, selectedEvent) => {
  const t = getTranslator();

  return ticket.event.id === selectedEvent.id
    ? t("ticketExchange.reasonLotChange")
    : t("ticketExchange.reasonExchange");
};

export const getData = (ticket, selectedEvent, selectedLot) => {
  const title = getTitle(ticket, selectedEvent, selectedLot);
  const reasonLabel = getReasonLabel(ticket, selectedEvent);
  const currentRate = calculateCurrentRate(ticket);
  const rateDifference = calculateRateDifference(ticket, selectedLot);
  const newRate = calculateNewRate(selectedLot);

  return {
    title,
    reasonLabel,
    rateDifference,
    newRate,
    currentRate,
  };
};

export const calculateRefundPreview = (operations) => {
  const transfersReversedAmount = _(operations)
    .filter((operation) =>
      _.includes(["transfer", "complimentary"], operation.transaction.type)
    )
    .sum((operation) => operation.amountToReverse);

  const refundDestinations = _(operations)
    .filter((operation) => {
      return (
        operation.transaction.type === "purchase" ||
        operation.transaction.type === "payment"
      );
    })
    .groupBy((operation) => operation.transaction.card.id)
    .map((operationsPerCard) => {
      return {
        card: operationsPerCard[0].transaction.card,
        amount: _.sum(operationsPerCard, "amountToReverse"),
      };
    })
    .value();

  const canSeeRefundCardExpiration = _.some(
    refundDestinations,
    (refundDestination) => refundDestination.card.expMonth
  );

  return {
    transfersReversedAmount,
    refundDestinations,
    canSeeRefundCardExpiration,
  };
};
