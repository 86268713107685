import { useLazyQuery } from "@apollo/client";
import SummaryTable from "./SummaryTable/SummaryTable";
import { AlertMessage, Link, Text } from "@citifyd/style";
import ApolloClient from "../../../../shared/services/apolloClient";
import ADMIN_REVENUE_BY_PROPERTY_REPORT from "../../../../graphql/reports/queries/AdminRevenueByPropertyReport";
import { isEmpty, isEqual } from "lodash";
import { useService, useTranslator } from "../../../../shared/react/hooks";
import { useEffect, useState } from "react";
import usePrevious from "../../../../shared/react/hooks/usePrevious";
import { getFilterSelectionFromState } from "../../utils";
import { useAuthentication } from "../../../../shared/react/contexts/authentication";
import LoadingManager from "../../../../shared/react/components/LoadingManager";
import Filter from "./Filter";

import Table from "../../../../shared/react/components/Table";

import styles from "./RevenueByPropertyReport.module.scss";
import RatesTable from "./RatesTable/RatesTable";
import moment from "moment";
import { useModal } from "../../../../shared/react/contexts/modal";
import GenerateReportModal from "../../../../shared/modals/GenerateReportModal";
import {
  getRevenueByPropertyCsv,
  getRevenueByPropertyPdf,
} from "../../../../shared/api";

const apolloClient = ApolloClient();

const RevenueByPropertyReport = ({ lots, organizationsOptions }) => {
  const { user } = useAuthentication();
  const t = useTranslator();
  const $state = useService("$state");
  const prevParams = usePrevious($state.params);
  const { openModal } = useModal();

  const [params, setParams] = useState($state.params);
  const [getRevenueByPropertyReport, { data, loading, error }] = useLazyQuery(
    ADMIN_REVENUE_BY_PROPERTY_REPORT,
    {
      client: apolloClient,
      fetchPolicy: "network-only",
    }
  );

  const getQueryOptionsFromFilterSelection = (filterSelection) => {
    return {
      startTime: moment(filterSelection.startDate).format(
        `YYYY-MM-DDT00:00:00Z`
      ),
      endTime: moment(filterSelection.endDate).format(`YYYY-MM-DDT23:59:59Z`),
      organizationId: filterSelection.organizationId
        ? Number(filterSelection.organizationId)
        : null,
      lotIds:
        filterSelection.lots?.split(",").map((item) => parseInt(item, 10)) ||
        [],
    };
  };

  const generateCsvClicked = () => {
    const filterSelection = getFilterSelectionFromState({ user });
    const options = getQueryOptionsFromFilterSelection(filterSelection);

    openModal(GenerateReportModal, {
      fileFormat: "csv",
      filterDescription: t("reports.propertyReport.csvReportName.message", {
        startTime: options.startTime,
        endTime: options.endTime,
      }),
      reportCsvRequest: getRevenueByPropertyCsv(options),
    });
  };

  const generatePdfClicked = () => {
    const filterSelection = getFilterSelectionFromState({ user });
    const options = getQueryOptionsFromFilterSelection(filterSelection);

    openModal(GenerateReportModal, {
      fileFormat: "pdf",
      filterDescription: t("reports.propertyReport.pdfReportName.message", {
        startTime: options.startTime,
        endTime: options.endTime,
      }),
      reportCsvRequest: getRevenueByPropertyPdf(options),
    });
  };

  useEffect(() => {
    if (!isEqual(prevParams, $state.params) && !isEmpty($state.params)) {
      setParams($state.params);
    }
  }, [$state.params]);

  useEffect(() => {
    if (params.dateRange) {
      const filterSelection = getFilterSelectionFromState({ user });
      const options = getQueryOptionsFromFilterSelection(filterSelection);

      getRevenueByPropertyReport({
        variables: {
          ...options,
        },
      });
    }
  }, [params]);

  const revenueByPropertyReportWithValues =
    data?.admin_revenueByPropertyReport.filter(
      (revenueByProperty) => revenueByProperty.totalTransactions > 0
    );

  const revenueByPropertyReportWithoutValues =
    data?.admin_revenueByPropertyReport.filter(
      (revenueByProperty) => revenueByProperty.totalTransactions === 0
    );

  return (
    <div>
      <>
        <AlertMessage className={styles.alertMessage}>
          <Text>
            {t("reports.propertyReport.receivePfdMessage")}

            <Link
              href="account?receivePDF=true"
              target="_blank"
              appearance="gray_darker"
            >
              {t("reports.propertyReport.goToAccount")}
            </Link>
          </Text>
        </AlertMessage>
      </>
      <Filter
        onClickGenerateCsv={generateCsvClicked}
        onClickGeneratePdf={generatePdfClicked}
        lots={lots}
        revenueByPropertyReport={data?.admin_revenueByPropertyReport}
        organizationsOptions={organizationsOptions}
      />
      {loading || error ? (
        <LoadingManager isLoading={loading} hasError={error} />
      ) : (
        <div>
          {revenueByPropertyReportWithValues?.map((revenueByProperty) => (
            <div key={revenueByProperty.lotId} className={styles.lotName}>
              <Text gutterBottom variant="h2" weight="300">
                {revenueByProperty.lotName}
              </Text>
              <div className={styles.summaryTable}>
                <SummaryTable summaryData={revenueByProperty} />
              </div>
              <RatesTable rates={revenueByProperty?.rates} />
              <div className={styles.divisor}></div>
            </div>
          ))}
          {revenueByPropertyReportWithoutValues?.length > 0 && (
            <>
              <Text gutterBottom variant="h3" weight="300">
                {t("reports.propertyReport.noTransactionSelectBelow")}
              </Text>

              <Table
                condensed
                striped
                data={revenueByPropertyReportWithoutValues}
                columns={[
                  {
                    value: "Property name",
                    key: "lotName",
                  },
                ]}
              />
            </>
          )}

          {data?.admin_revenueByPropertyReport.length === 0 && (
            <Text variant="subtitle">
              {t("reports.propertyReport.noTransactionSelect")}
            </Text>
          )}
        </div>
      )}
    </div>
  );
};

export default RevenueByPropertyReport;
