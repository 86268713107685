"use strict";

import React, { useMemo, useState } from "react";
import CurrencyFormatter from "@citifyd/currency-formatter";
import PaymentMethod from "../../../shared/react/components/PaymentMethod";
import moment from "moment-timezone";
import { get, sum, uniq } from "lodash";
import { AlertMessage, Button, LoadingIndicator, Text } from "@citifyd/style";

import { useTranslator } from "../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../shared/services/languages";
import Table from "../../../shared/react/components/Table";
import BoxContent from "../../../shared/react/components/BoxContent";
import Permissions from "../../../shared/services/permissions";
import styles from "../Subscription.module.scss";
import RouterLinkButton from "../../../shared/react/components/RouterLinkButton";
import { useAuthentication } from "../../../shared/react/contexts/authentication";
import ADMIN_CANCEL_SUBSCRIPTION from "../../../graphql/subscriptions/mutations/AdminCancelSubscription";
import { useMutation } from "@apollo/client";
import ApolloClient from "../../../shared/services/apolloClient";
import { showErrorAlert } from "../../../shared/services/helper";

const apolloClient = ApolloClient();

function PaymentHistoryBox({ subscription: baseSubscription, reload }) {
  const t = useTranslator();

  return (
    <BoxContent>
      <BoxContent.Header
        title={t("subscriptionPage.paymentHistoryBox.title")}
      />
      <BoxContent.Content noHorizontalPadding>
        <PaymentHistoryTable
          baseSubscription={baseSubscription}
          reload={reload}
        />
      </BoxContent.Content>
    </BoxContent>
  );
}

function PaymentHistoryTable({ baseSubscription, reload }) {
  const t = useTranslator();
  const { permissions } = useAuthentication();
  const [isCanceling, setIsCanceling] = useState(false);

  const userMayRefundSubscription = () =>
    Permissions.userMayRefundSubscription(permissions);

  const paymentHistoryItems = useMemo(() => {
    return baseSubscription.allSubscriptions.map((subscription) => {
      const purchaseTransactions = subscription.transactions.filter(
        (t) => t.type === "purchase"
      );
      const chargedAmount = sum(purchaseTransactions, (t) => t.totalAmount);
      const chargedAt =
        get(purchaseTransactions[0], "createdAt") || subscription.subscribedAt;
      const currency = get(purchaseTransactions[0], "currency");
      const card = get(purchaseTransactions[0], "card");
      return {
        subscription,
        chargedAt,
        purchaseTransactions,
        chargedAmount,
        card,
        currency,
      };
    });
  }, [baseSubscription]);

  const [adminCancelSubscription] = useMutation(ADMIN_CANCEL_SUBSCRIPTION, {
    client: apolloClient,
  });

  const isExpireSubscription = (subscription) => {
    const { expiresAt } = subscription;
    const currentDate = moment().tz(baseSubscription?.plan.lot.timezoneName);

    const isExpire = moment(expiresAt).isBefore(currentDate);

    return isExpire;
  };

  const cancelSubscription = async (subscription) => {
    if (
      !window.confirm(
        t("subscriptionPage.paymentHistoryBox.areYouSureToCancel")
      )
    ) {
      return;
    }
    try {
      setIsCanceling(true);
      await adminCancelSubscription({
        variables: { id: subscription.id },
      });

      reload();
    } catch (err) {
      console.log(err);
      showErrorAlert(err);
    } finally {
      setIsCanceling(false);
    }
  };

  const columns = [
    {
      value: "#",
      width: "1%",
      key: "sequence",
    },
    {
      value: t("subscriptionPage.paymentHistoryBox.fields.paymentDate"),
      key: "paymentDate",
    },
    {
      value: t("subscriptionPage.paymentHistoryBox.fields.parkingPeriod"),
      key: "parkingPeriod",
      width: "5%",
      nowrap: "nowrap",
    },
    {
      key: "separator",
      width: "1%",
    },
    {
      key: "expiresAt",
      width: "20%",
    },
    {
      value: t("subscriptionPage.paymentHistoryBox.fields.paymentMethod"),
      key: "paymentMethod",
      nowrap: "nowrap",
    },
    {
      value: t("subscriptionPage.paymentHistoryBox.fields.amountPaid"),
      textAlign: "right",
      key: "amountPaid",
      nowrap: "nowrap",
    },
    userMayRefundSubscription(permissions) && {
      value: t("commonButtons.cancel"),
      key: "cancel",
      textAlign: "right",
      nowrap: "nowrap",
    },
    userMayRefundSubscription(permissions) && {
      value: t("subscriptionPage.paymentHistoryBox.fields.refund"),
      textAlign: "right",
      key: "refund",
      nowrap: "nowrap",
    },
  ];

  const data = paymentHistoryItems.map((item) => {
    const formatDate = (date) =>
      t("subscriptionPage.paymentHistoryBox.dateFormat", {
        date: moment(date).tz(baseSubscription.plan.lot.timezoneName),
      });

    const isRefunded = item.subscription.transactions.some(
      (transaction) => transaction.type === "refund"
    );

    return {
      sequence: {
        value: item.subscription.sequence,
        verticalAlign: "middle",
      },
      paymentDate: {
        value: formatDate(item.chargedAt),
        nowrap: "nowrap",
        verticalAlign: "middle",
      },
      parkingPeriod: {
        value: formatDate(item.subscription.subscribedAt),
        nowrap: "nowrap",
        verticalAlign: "middle",
      },
      separator: {
        value: t(
          "subscriptionPage.paymentHistoryBox.fields.parkingPeriodSeparator"
        ),
        verticalAlign: "middle",
      },
      expiresAt: {
        value: formatDate(item.subscription.expiresAt),
        nowrap: "nowrap",
        verticalAlign: "middle",
      },
      paymentMethod: {
        value: <PaymentMethod card={item.card} />,
        nowrap: "nowrap",
        verticalAlign: "middle",
      },
      amountPaid: {
        value: (
          <TransactionAmount
            amount={item.chargedAmount}
            currency={item.currency}
          />
        ),
        textAlign: "right",
        verticalAlign: "middle",
      },

      cancel: {
        value:
          item.subscription.cancelledAt || isRefunded
            ? t("commonButtons.canceled")
            : !isExpireSubscription(item.subscription) && (
                <Button
                  key="cancel"
                  justifyContent="center"
                  onClick={() => cancelSubscription(item.subscription)}
                  letterSpacing
                  shadow
                  size="tiny"
                  uppercase
                >
                  {t("commonButtons.cancel")}
                </Button>
              ),
        textAlign: "right",
        verticalAlign: "middle",
      },
      refund: {
        value: (
          <RouterLinkButton
            key="refund"
            state="subscription-refund"
            params={{ id: item.subscription.id }}
            options={{ enableBackLink: true }}
            justifyContent="center"
            letterSpacing
            shadow
            disabled={isRefunded}
            size="tiny"
            uppercase
          >
            {isRefunded
              ? t("commonButtons.refunded")
              : t("commonButtons.refund")}
          </RouterLinkButton>
        ),
        textAlign: "right",
        verticalAlign: "middle",
      },
    };
  });

  const columnsFormatted = columns.map((column) => ({
    ...column,
    textColor: "gray_dark",
    uppercase: false,
  }));

  const currency = useMemo(() => {
    const currencies = uniq(paymentHistoryItems.map((item) => item.currency));
    return currencies.length === 1 ? currencies[0] : null;
  }, [paymentHistoryItems]);

  const totalAmount = useMemo(() => {
    if (currency) {
      return sum(paymentHistoryItems, (item) => item.chargedAmount);
    } else {
      return null;
    }
  }, [paymentHistoryItems, currency]);

  const dataFormatted = !totalAmount
    ? data
    : [
        ...data,
        {
          appearance: "white",
          sequence: {
            value: (
              <Text bold>
                {t("subscriptionPage.paymentHistoryBox.fields.total")}
              </Text>
            ),
            colSpan: 6,
          },
          paymentDate: {
            hide: true,
          },
          parkingPeriod: {
            hide: true,
          },
          separator: {
            hide: true,
          },
          expiresAt: {
            hide: true,
          },
          paymentMethod: {
            hide: true,
          },
          amountPaid: (
            <Text textAlign="right" bold>
              <TransactionAmount amount={totalAmount} currency={currency} />
            </Text>
          ),
        },
      ];

  return (
    <>
      {userMayRefundSubscription(permissions) && (
        <AlertMessage className={styles.refundMessage}>
          <Text>{t("subscriptionPage.refundedMessage")}</Text>
        </AlertMessage>
      )}

      {isCanceling && <LoadingIndicator isLoading={true} withOverlay />}

      <Table
        responsive
        spacing
        columns={columnsFormatted}
        data={dataFormatted}
        headerAppearance="white"
        className={styles.table}
      />
    </>
  );
}

function TransactionAmount({ amount, currency }) {
  const language = getCurrentLanguage();
  return CurrencyFormatter.format(amount, { currency, language });
}

export default PaymentHistoryBox;
