"use strict";

import React from "react";

import ReservedParkingTransactionBox from "./ReservedParkingTransactionBox.jsx";
import FeeBreakdownBox from "./FeeBreakdownBox.jsx";
import CancellationBox from "./CancellationBox.jsx";

export default function ReservedParkingPassFullInformation({
  reservedParkingPass,
}) {
  return (
    <div>
      <ReservedParkingTransactionBox
        reservedParkingPass={reservedParkingPass}
      />
      <FeeBreakdownBox />
      {reservedParkingPass?.cancellation && (
        <CancellationBox reservedParkingPass={reservedParkingPass} />
      )}
    </div>
  );
}
