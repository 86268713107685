import React from "react";
import { uniq, sum } from "lodash";
import CurrencyFormatter from "@citifyd/currency-formatter";
import moment from "moment-timezone";
import { Text } from "@citifyd/style";

import { calculateReservedParkingStatus } from "../../../../shared/utils/status-calculation";
import ReservedParkingStatusBadge from "../../../../shared/react/components/ReservedParkingStatusBadge";
import { getService } from "../../../../shared/react/utils";
import {
  getCurrentLanguage,
  getTranslator,
} from "../../../../shared/services/languages";

export const transformReservedParkingPass = (reservedParkingPasses) => {
  const language = getCurrentLanguage();

  return reservedParkingPasses.map(({ node }) => ({
    id: node.id,
    start: node.startTime,
    end: node.endTime,
    lotName: node.lot.name,
    timezoneName: node.lot.timezoneName,
    currency: node.currency,
    price: node.calculatedPrice.total,
    formattedAmount: CurrencyFormatter.format(node.calculatedPrice.total, {
      currency: node.currency,
      language,
    }),
    status: calculateReservedParkingStatus(node),
  }));
};

export const getFormattedTotal = (reservedParkingPasses) => {
  const language = getCurrentLanguage();

  const currencies = uniq(
    reservedParkingPasses,
    (reservedParkingPass) => reservedParkingPass.currency
  );

  if (currencies.length === 1) {
    const total = sum(
      reservedParkingPasses,
      (reservedParkingPass) => reservedParkingPass.price
    );

    return CurrencyFormatter.format(total, {
      currency: reservedParkingPasses[0].currency,
      language,
    });
  } else {
    return "";
  }
};

export const getData = ({
  reservedParkingPasses,
  handleSort,
  orders,
  formattedTotal,
}) => {
  const t = getTranslator();
  const $state = getService("$state");

  const columns = [
    {
      key: "start",
      value: t("user.reservedParking.table.start"),
      onClick: (e) => handleSort(e, "start"),
      sortableKey: "start",
    },
    {
      key: "end",
      value: t("user.reservedParking.table.end"),
      onClick: (e) => handleSort(e, "end"),
      sortableKey: "end",
    },
    {
      key: "lotName",
      value: t("user.reservedParking.table.lot"),
      onClick: (e) => handleSort(e, "lotName"),
      sortableKey: "lotName",
    },
    {
      key: "price",
      value: t("user.reservedParking.table.price"),
      onClick: (e) => handleSort(e, "price"),
      sortableKey: "price",
    },
    {
      key: "status",
      value: t("user.reservedParking.table.status"),
      onClick: (e) => handleSort(e, "status"),
      sortableKey: "status",
    },
  ];

  const columnsFormatted = columns.map((column) => ({
    ...column,
    sortable: true,
    orders,
  }));

  const handleClick = (id) => {
    $state.go("reserved-parking-pass", { id }, { enableBackLink: true });
  };

  const data = reservedParkingPasses.map((reservedParkingPass) => ({
    onClick: () => handleClick(reservedParkingPass.id),
    start: {
      value: t("defaultFormats.datetime", {
        date: moment.tz(
          reservedParkingPass.start,
          reservedParkingPass.timezoneName
        ),
      }),
      verticalAlign: "middle",
      nowrap: "nowrap",
    },
    end: {
      value: t("defaultFormats.datetime", {
        date: moment.tz(
          reservedParkingPass.end,
          reservedParkingPass.timezoneName
        ),
      }),
      verticalAlign: "middle",
      nowrap: "nowrap",
    },
    lotName: {
      value: reservedParkingPass.lotName,
      verticalAlign: "middle",
    },
    price: {
      value: reservedParkingPass.formattedAmount,
      verticalAlign: "middle",
    },
    status: {
      value: <ReservedParkingStatusBadge status={reservedParkingPass.status} />,
    },
  }));

  const dataWithTotal = [
    ...data,
    {
      appearance: "white",
      start: {
        value: <Text weight="700">{t("revenue.total")}</Text>,
        colSpan: 3,
      },
      end: {
        hide: true,
      },
      lotName: {
        hide: true,
      },
      price: {
        value: <Text weight="700">{formattedTotal}</Text>,
      },
      status: {
        hide: true,
      },
    },
  ];

  return {
    columns: columnsFormatted,
    data: dataWithTotal,
  };
};
