"use strict";

import React from "react";
import { Grid } from "@citifyd/style";
import classNames from "classnames";

import styles from "./StickyFooter.module.scss";

export default function StickyFooter({
  children,
  isBlocked,
  justifyContent = "space-between",
}) {
  return (
    <div className={styles.stickyFooter}>
      <Grid>
        <Grid.Row>
          <Grid.Col
            className={classNames(styles.column, styles[justifyContent])}
          >
            {isBlocked && <div className={styles.blockOverlay} />}
            {children}
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </div>
  );
}
