import { generatePath, matchPath } from "react-router-dom";
import { pick, omit, identity, isEmpty } from "lodash";
import { history } from "../../../routes";
import HistoryTracker from "../../services/history-tracker";

import {
  getRouteByName,
  getRouteObject,
  convertQueryStringToObject,
  getFormattedQueryStrings,
  getRouteByPathname,
} from "./utils";

const getPath = ({ url, queryStrings, params, options }) => {
  const currentRoute = getRouteByPathname(history.location?.pathname);

  const { inherit, enableBackLink } = options;
  let path = generatePath(url, params);
  let _origin = {};

  if (enableBackLink) {
    const currentQueryStrings = convertQueryStringToObject(
      history.location?.search?.substring(1)
    );

    const stateId = HistoryTracker.addState({
      name: currentRoute.name,
      params: currentQueryStrings
        ? { ...currentRoute.params, ...currentQueryStrings }
        : currentRoute.params,
    });

    _origin = { _origin: stateId };
  }

  // $state.go() or {inherit: true} inherits url parameters from current url.
  // It mounts the new route with query strings from current url.
  if (inherit && history.location?.search) {
    const currentQueryStrings = convertQueryStringToObject(
      history.location?.search?.substring(1)
    );

    const newQueryStrings = {
      ...currentQueryStrings,
      ...queryStrings,
      ..._origin,
    };

    return `${path}?${getFormattedQueryStrings(newQueryStrings)}`;
  } else if (!isEmpty(queryStrings)) {
    const newQueryStrings = { ...queryStrings, ..._origin };

    return `${path}?${getFormattedQueryStrings(newQueryStrings)}`;
  } else {
    const newQueryStrings = { ..._origin };
    return `${path}?${getFormattedQueryStrings(newQueryStrings)}`;
  }
};

const categorizeParams = ({ route, toParams }) => {
  const routeParams = route.url
    .split("/")
    .filter((path) => path.includes(":"))
    .map((item) => item.split(":")[1]);

  return {
    params: pick(toParams, routeParams),
    queryStrings: omit(toParams, routeParams),
  };
};

const routeTransition = ({ to, toParams, options }) => {
  const [pathname, pathparams] = to?.split("?");

  const routeParams = pathparams
    ? { ...toParams, ...convertQueryStringToObject(pathparams) }
    : toParams;

  const route = getRouteByName(pathname) || getRouteByPathname(pathname);

  if (isEmpty(route)) return;

  const { params, queryStrings } = categorizeParams({
    route,
    toParams: !isEmpty(routeParams)
      ? { ...routeParams, ...route?.params }
      : route.params,
  });

  const { location, reload } = options;

  const path = getPath({
    url: route.url,
    queryStrings,
    params,
    options,
  });

  // { reload } options: If true will force transition even if the state or params have not changed, aka a reload of the same state.
  // This conditional statament is checking if the route is the same and also if {reload} exists before reloading the page,
  // if the next route doesn't match and {reload} exists it won't force a reload because history.push will handle with that below.
  if (reload && matchPath(route.url, history.location.pathname)) {
    history.go(0);
  }

  // TODO: react-router onChange event needs to handle with { notify } state.
  // { notify } options: If true will broadcast $stateChangeStart and $stateChangeSuccess events.
  switch (location) {
    case "replace":
      return history.replace(path, { ...options });
    default:
      return history.push(path, { ...options });
  }
};

const getRoute = (pathname) => {
  const route = getRouteByName(pathname) || getRouteByPathname(pathname);

  return {
    auth: route.page?.auth,
    name: route.name,
    title: route.page?.title,
    url: route.url,
  };
};

export default {
  go(to, toParams, options = {}) {
    // $state.go calls $state.transitionTo internally but automatically sets options to
    // { location: true, inherit: true, relative: $state.$current, notify: true }
    routeTransition({
      to,
      toParams,
      options: { location: true, inherit: true, notify: true, ...options },
    });
  },
  transitionTo(to, toParams, options = {}) {
    routeTransition({ to, toParams, options });
  },
  get(pathname) {
    return getRoute(pathname);
  },
  get current() {
    return getRoute();
  },
  get params() {
    const route = getRouteByPathname();
    if (!route) return;

    const { params } = getRouteObject();
    const queryStrings = convertQueryStringToObject(
      history.location?.search?.substring(1)
    );

    return {
      ...queryStrings,
      ...params,
      ...route.params,
    };
  },
  href(to, toParams, options) {
    const route = getRouteByName(to);
    if (!route) return;

    const { params, queryStrings } = categorizeParams({ route, toParams });

    const path = getPath({
      url: route.url,
      queryStrings,
      params,
      options: { ...options, inherit: false },
    });

    return options?.absolute
      ? `${window.location.href?.slice(0, -1)}${path}`
      : path;
  },
};
