import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AdminGetPermitPortalQuery,
  useAdminGetPermitPortalLazyQuery,
} from "../../../graphql/generated/graphql";
import { getAllProperties } from "../../../shared/api";
import { useService } from "../../../shared/react/hooks";
import useLoadTracking from "../../../shared/react/hooks/useLoadTracking";
import ApolloClient from "../../../shared/services/apolloClient";
import { Lot } from "../types";
const apolloClient = ApolloClient();

export const DEFAULT_VALUE = {
  lots: [],
  portalData: null,
  isEditing: false,
  isLoading: false,
};

type ContextValues = {
  lots: Lot[];
  portalData: AdminGetPermitPortalQuery["admin_getPermitPortal"] | null;
  isEditing: boolean;
  isLoading: boolean;
};

const PermitPortalCtx = createContext<ContextValues>(DEFAULT_VALUE);

export const PermitPortalProvider = ({ children }: { children: ReactNode }) => {
  const $state = useService("$state");
  const isEditing = Boolean($state.params.id);

  const { isLoading, trackLoading, untrackLoading } = useLoadTracking({});
  const [lots, setLots] = useState<Lot[]>([]);

  const [getPortal, { data }] = useAdminGetPermitPortalLazyQuery({
    client: apolloClient,
    fetchPolicy: "network-only",
  });

  const getPortalSettings = async () => {
    if (isEditing) {
      trackLoading("getPortal");
      await getPortal({
        variables: { id: $state.params.id },
      });
      untrackLoading("getPortal");
    }
  };

  const getLots = async () => {
    trackLoading("lots");
    const response = await getAllProperties();
    setLots(response);
    untrackLoading("lots");
  };

  useEffect(() => {
    getLots();
    getPortalSettings();
  }, []);

  const portalData = data?.admin_getPermitPortal ?? null;

  return (
    <PermitPortalCtx.Provider
      value={{ portalData, lots, isLoading, isEditing }}
    >
      {children}
    </PermitPortalCtx.Provider>
  );
};

export const usePermitPortalCtx = () => useContext(PermitPortalCtx);
