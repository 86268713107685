"use strict";

import React, { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { Button, Text, Grid, Icon } from "@citifyd/style";
import { findWhere } from "lodash";
import CurrencyFormatter from "@citifyd/currency-formatter";
import FormikProvider from "../../../../../../../shared/react/components/FormikProvider";
import FormikField, {
  FIELD_TYPE,
} from "../../../../../../../shared/react/components/FormikField";
import { useFormik } from "formik";
import { useTranslator } from "../../../../../../../shared/react/hooks";
import { getCurrentLanguage } from "../../../../../../../shared/services/languages";
import { getTimeMomentObject } from "./utils";
import {
  getSelectedWeekDaysString,
  getSummaryTimeRange,
} from "../../../../utils";

import styles from "./Pricing.module.scss";
import { generateTimesArray } from "../../../../../../../shared/services/helper";
import { transformToInteger } from "../../../../../../../shared/services/currencies";

const Pricing = ({
  lot,
  type,
  currentPricingObject,
  onUpdatePricingSettings,
  onRemovePricingSettings,
  setIsEditingDailyPricing,
  range,
  period,
  selectedDays,
}) => {
  const t = useTranslator();
  const language = getCurrentLanguage();
  const currency = lot.country?.currency;
  const [pricing, setPricing] = useState(currentPricingObject[range]);
  const [discountTimes, setDiscountTimes] = useState([]);

  const times = useMemo(() => generateTimesArray(), []);

  const timeOptions = useMemo(() =>
    times.map(
      (time) => ({
        value: time.time,
        label: time.label,
      }),
      [times]
    )
  );

  const startTimeOptions = useMemo(() => {
    if (period === "day") return timeOptions;

    const daytimeKey = type === "weekday" ? "weekdays" : "weekends";

    const dailyTime = currentPricingObject[daytimeKey].data?.endTime?.replace(
      ":",
      ""
    );

    if (!dailyTime) {
      return timeOptions;
    }

    return times
      .filter((time) => time.number >= dailyTime)
      .map((time) => ({
        value: time.time,
        label: time.label,
      }));
  }, [currentPricingObject, times, timeOptions]);

  const setPlaceholder = (value) =>
    CurrencyFormatter.format(value, {
      currency,
      language,
    });

  const ratePlaceholder = setPlaceholder(200);
  const maxRatePlaceholder = setPlaceholder(1200);
  const discountMaxRatePlaceholder = setPlaceholder(1000);

  const discountTimeOptions = discountTimes?.map((time) => ({
    value: time.time,
    label: time.label,
  }));

  useEffect(() => {
    setIsEditingDailyPricing(pricing?.editing);
  }, [pricing]);

  const refreshDiscountTimes = () => {
    const discountTimes = generateTimesArray(values?.startTime);

    setDiscountTimes(discountTimes);

    if (discountTimes.length === 0) {
      setFieldValue("discount.enabled", false);
    } else {
      const hasValue = Boolean(
        findWhere(discountTimes, {
          time: values?.discount?.arriveBefore,
        })
      );

      if (!hasValue) {
        setFieldValue("discount.arriveBefore", discountTimes[0].time);
      }
    }
  };

  const onEdit = () => {
    setPricing((prevState) => ({
      ...prevState,
      editing: true,
    }));
  };

  const onRemove = () => {
    if (!window.confirm(t("commonConfirmations.areYouSure"))) {
      return;
    }

    onRemovePricingSettings();
    handleReset();

    setPricing((prevState) => ({
      ...prevState,
      editing: false,
      data: null,
    }));
  };

  const onSubmit = () => {
    const formattedData = {
      ...values,
      rateType: values[`rateType-${range}`],
    };

    onUpdatePricingSettings({ [range]: { data: formattedData } });

    setPricing((prevState) => ({
      ...prevState,
      editing: false,
      data: formattedData,
    }));
  };

  const onCancel = () => {
    onUpdatePricingSettings({ [range]: { data: currentPricingObject[range] } });
    handleReset();

    setPricing((prevState) => ({
      ...prevState,
      editing: false,
    }));
  };

  const formikbag = useFormik({
    initialValues: {
      spaces: pricing?.data?.spaces || lot.maxSpots,
      rateValue: pricing?.data?.rateValue,
      maxRate: pricing?.data?.maxRate,
      [`rateType-${range}`]: pricing?.data?.rateType || "hourly",
      startTime:
        pricing?.data?.startTime ?? (period === "day" ? "06:00" : "18:00"),
      endTime: pricing?.data?.endTime ?? (period === "day" ? "18:00" : "00:00"),
      discount: {
        enabled: pricing?.data?.discount?.enabled || false,
        maxRate: pricing?.data?.discount?.maxRate,
        arriveBefore: pricing?.data?.discount?.arriveBefore || "08:00",
      },
    },
    onSubmit,
    validationSchema: yup.object().shape({
      spaces: yup.number().required(),
      rateValue: yup.string().required(),
      discount: yup.object().shape({
        maxRate: yup.string().when("enabled", {
          is: true,
          then: yup.string().required(),
          otherwise: yup.string(),
        }),
      }),
    }),
  });

  const { handleReset, setFieldValue, handleSubmit, values } = formikbag;

  useEffect(() => {
    if (values?.startTime) {
      refreshDiscountTimes();
    }
  }, [values?.startTime]);

  const NewRateComponent = () => (
    <div>
      <Button
        rounded
        extraPadding
        size="small"
        appearance="white"
        onClick={() => onEdit()}
      >
        <Text weight="700" variant="small" component="span">
          {t(`addProperty.pricing.buttons.add.${type}.${period}`)}
        </Text>
      </Button>
      <Text textAlign="center" className={styles.dayInfo}>
        {period === "day" &&
          (type === "weekday"
            ? t("addProperty.dayInfo")
            : t("addProperty.dayWeekendInfo"))}
        {period === "night" &&
          (type === "weekday"
            ? t("addProperty.eveningInfo")
            : t("addProperty.eveningWeekendInfo"))}
      </Text>
    </div>
  );

  return (
    <FormikProvider value={formikbag}>
      {!pricing?.data && !pricing?.editing && <NewRateComponent />}

      {pricing?.editing && (
        <div className={styles.editableRateComponent}>
          <Grid.Row>
            <Grid.Col sm={type !== "weekend" ? 5 : 12}>
              <Grid.Row gutterWidth={0}>
                <Grid.Col>
                  <FormikField
                    name="rateValue"
                    as={FIELD_TYPE.CURRENCY}
                    currency={currency}
                    placeholder={ratePlaceholder}
                    className={styles.rateValueField}
                    gutterBottom
                  />
                </Grid.Col>
                <Grid.Col>
                  <div className={styles.rateType}>
                    <FormikField
                      as={FIELD_TYPE.RADIO}
                      name={`rateType-${range}`}
                      label={t("addProperty.perHour")}
                      value="hourly"
                      checked={values[`rateType-${range}`] === "hourly"}
                      gutterBottom
                    />
                    <FormikField
                      as={FIELD_TYPE.RADIO}
                      name={`rateType-${range}`}
                      label={t("addProperty.fixedRate")}
                      checked={values[`rateType-${range}`] === "fixed"}
                      value="fixed"
                    />
                  </div>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  {values[`rateType-${range}`] === "hourly" && (
                    <FormikField
                      name="maxRate"
                      as={FIELD_TYPE.CURRENCY}
                      currency={currency}
                      fullWidth={false}
                      placeholder={maxRatePlaceholder}
                      gutterBottom
                      inlineLabel={
                        period === "day"
                          ? t("addProperty.dailyMax")
                          : t("addProperty.nightMax")
                      }
                    />
                  )}
                  <FormikField
                    as={FIELD_TYPE.INPUT}
                    fullWidth={false}
                    placeholder={lot.maxSpots}
                    type="number"
                    inlineLabel={t("addProperty.passes")}
                    name="spaces"
                    gutterBottom
                  />
                  <FormikField
                    as={FIELD_TYPE.SELECT}
                    fullWidth={false}
                    options={startTimeOptions}
                    inlineLabel={t("addProperty.startTime")}
                    name="startTime"
                    gutterBottom
                  />
                  <FormikField
                    as={FIELD_TYPE.SELECT}
                    fullWidth={false}
                    options={timeOptions}
                    inlineLabel={t("addProperty.endTime")}
                    name="endTime"
                    gutterBottom
                  />
                </Grid.Col>
              </Grid.Row>

              <Grid.Row>
                <Grid.Col className={styles.editActions}>
                  <Icon
                    icon="check"
                    pull="none"
                    className={styles.editActionsButton}
                    onClick={handleSubmit}
                  />
                  <Icon
                    icon="times"
                    pull="none"
                    className={styles.editActionsButton}
                    onClick={() => onCancel()}
                  />
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            {type === "weekday" &&
              period === "day" &&
              discountTimes.length > 0 && (
                <Grid.Col sm={6} className={styles.earlyBirdColumn}>
                  <>
                    <FormikField
                      as={FIELD_TYPE.CHECKBOX}
                      name="discount.enabled"
                      gutterBottom
                      labelSize="default"
                      label={t("addProperty.earlybird")}
                    />
                    <Text gutterBottom>{t("addProperty.earlybirdInfo")}</Text>

                    {values.discount.enabled && (
                      <>
                        <FormikField
                          name="discount.maxRate"
                          as={FIELD_TYPE.CURRENCY}
                          currency={currency}
                          fullWidth={false}
                          placeholder={discountMaxRatePlaceholder}
                          gutterBottom
                          inlineLabel={t("addProperty.earlybirdMax")}
                        />
                        <FormikField
                          as={FIELD_TYPE.SELECT}
                          fullWidth={false}
                          options={discountTimeOptions}
                          inlineLabel={t("addProperty.arriveBefore")}
                          name="discount.arriveBefore"
                        />
                      </>
                    )}
                  </>
                </Grid.Col>
              )}
          </Grid.Row>
        </div>
      )}

      {pricing?.data && !pricing?.editing && (
        <div className={styles.summary}>
          <Text gutterBottom weight="700">
            {t(`addProperty.pricing.summary.title.${type}.${period}`)}
          </Text>
          <Text
            gutterBottom={
              !pricing.data.maxRate && !pricing.data.discount.enabled
            }
          >
            {t(
              `addProperty.pricing.summary.rate.${
                pricing.data[`rateType-${range}`]
              }`,
              {
                amount: transformToInteger(pricing.data.rateValue, currency),
                currency: currency,
              }
            )}
          </Text>
          {pricing.data.maxRate && values[`rateType-${range}`] === "hourly" && (
            <Text gutterBottom={!pricing.data.discount.enabled}>
              {t("addProperty.pricing.summary.maxRate", {
                amount: transformToInteger(pricing.data.maxRate, currency),
                currency: currency,
              })}
            </Text>
          )}
          {pricing.data.discount.enabled && (
            <Text gutterBottom>
              {t("addProperty.pricing.summary.earlyBird", {
                amount: transformToInteger(
                  pricing.data.discount.maxRate,
                  currency
                ),
                time: getTimeMomentObject(pricing.data.discount.arriveBefore),
                currency: currency,
              })}
            </Text>
          )}
          <Text gutterBottom>
            {t("addProperty.pricing.summary.passesAvailable", {
              amount: pricing.data.spaces,
            })}
          </Text>
          <Text>{getSummaryTimeRange(pricing.data)}</Text>
          <Text gutterBottom>
            {getSelectedWeekDaysString({ selectedDays, type })}
          </Text>
          <div>
            <Icon
              icon="pencil-alt"
              pull="none"
              className={styles.editActionsButton}
              onClick={() => onEdit()}
            />
            <Icon
              icon="trash"
              pull="none"
              className={styles.editActionsButton}
              onClick={() => onRemove()}
            />
          </div>
        </div>
      )}
    </FormikProvider>
  );
};

export default Pricing;
