"use strict";

import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { map } from "lodash";
import { useTranslator } from "../../../../../shared/react/hooks";
import FormikField, {
  FIELD_TYPE,
} from "../../../../../shared/react/components/FormikField";
import Table from "../../../../../shared/react/components/Table";
import styles from "./Gates.module.scss";

const GatesTable = ({ gates }) => {
  const t = useTranslator();
  const [formattedGates, setFormattedGates] = useState({});
  const { setFieldValue, values, errors } = useFormikContext();
  const { eventGates } = values;

  const formatGates = (gates) => {
    const data = map(gates, (gate, index) =>
      gate
        ? {
            ...gate,
            gateId: parseInt(gate?.gateId) || parseInt(index),
            type: gate?.type,
            selected: gate?.selected || false,
            beaconUids: gate?.beaconUids || "",
          }
        : undefined
    );

    return data.length > 0
      ? data.reduce((a, v) => ({ ...a, [v?.gateId]: v }), {})
      : {};
  };
  useEffect(() => {
    setFormattedGates(formatGates(eventGates));
  }, [eventGates]);

  const onGateSelection = (gate) => {
    const eventGates = formattedGates;
    const selectedGate = eventGates[gate.id];

    eventGates[gate.id] = {
      ...selectedGate,
      selected: !selectedGate?.selected,
      gateId: parseInt(gate.id),
      beaconUids: selectedGate?.selected ? "" : selectedGate?.beaconUids,
      type: selectedGate?.type,
    };

    setFieldValue("eventGates", eventGates);
  };

  const tableColumns = [
    {
      value: t("eventProperties.gates"),
      uppercase: false,
      width: "20%",
      key: "gate",
    },
    {
      value: t("eventProperties.beaconIds"),
      uppercase: false,
      width: "18%",
      key: "beaconUids",
    },
    {
      value: t("eventProperties.type"),
      uppercase: false,
      width: "13%",
      key: "type",
    },
    {
      value: t("eventProperties.armed"),
      uppercase: false,
      width: "1%",
      key: "armed",
    },
    {
      value: t("eventProperties.reports"),
      uppercase: false,
      width: "1%",
      key: "reportsStatus",
    },
    {
      value: t("eventProperties.barrier"),
      uppercase: false,
      width: "1%",
      key: "hasBarrier",
    },
    {
      value: t("eventProperties.automatic"),
      uppercase: false,
      width: "1%",
      key: "autoActivation",
    },
    {
      value: t("eventProperties.attendant"),
      uppercase: false,
      width: "1%",
      key: "hasAttendant",
    },
  ];

  const tableData = gates.map((gate, index) => ({
    borderBottom: index !== gates.length - 1,
    borderBottomColor: "gray",
    gate: {
      value: (
        <div className={styles.checkbox}>
          <FormikField
            name={`eventGates[${gate.id}].selected`}
            onChange={() => onGateSelection(gate)}
            as={FIELD_TYPE.CHECKBOX}
            label={gate.name}
          />
        </div>
      ),
    },
    beaconUids: (
      <FormikField
        name={`eventGates[${gate.id}].beaconUids`}
        options={gate.beaconUids}
        isClearable={false}
        as={FIELD_TYPE.CREATABLE_SELECT}
      />
    ),
    type: (
      <FormikField
        name={`eventGates[${gate.id}].type`}
        as={FIELD_TYPE.SELECT}
        options={[
          { label: t("eventProperties.ingress"), value: "ingress" },
          { label: t("eventProperties.egress"), value: "egress" },
        ]}
      />
    ),
    armed: {
      value: (
        <div className={styles.checkbox}>
          <FormikField
            name={`eventGates[${gate.id}].armedGate`}
            as={FIELD_TYPE.CHECKBOX}
          />
        </div>
      ),
      textAlign: "center",
    },
    reportsStatus: {
      value: (
        <div className={styles.checkbox}>
          <FormikField
            name={`eventGates[${gate.id}].reportsStatus`}
            as={FIELD_TYPE.CHECKBOX}
          />
        </div>
      ),
      textAlign: "center",
    },
    hasBarrier: {
      value: (
        <div className={styles.checkbox}>
          <FormikField
            name={`eventGates[${gate.id}].hasBarrier`}
            as={FIELD_TYPE.CHECKBOX}
          />
        </div>
      ),
      textAlign: "center",
    },
    autoActivation: {
      value: (
        <div className={styles.checkbox}>
          <FormikField
            name={`eventGates[${gate.id}].automaticActivation`}
            as={FIELD_TYPE.CHECKBOX}
          />
        </div>
      ),
      textAlign: "center",
    },
    hasAttendant: {
      value: (
        <div className={styles.checkbox}>
          <FormikField
            name={`eventGates[${gate.id}].hasAttendant`}
            as={FIELD_TYPE.CHECKBOX}
          />
        </div>
      ),
      textAlign: "center",
    },
  }));

  return (
    <Table
      condensed
      className={styles.table}
      headerAppearance="gray"
      columns={tableColumns}
      data={tableData}
    />
  );
};

export default GatesTable;
