"use strict";

import React, { useEffect, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import { Grid } from "@citifyd/style";
import { chunk } from "lodash";

import ADMIN_VENUES from "../../../graphql/venues/queries/AdminVenues";
import LoadingManager from "../../../shared/react/components/LoadingManager/LoadingManager.jsx";
import ApolloClient from "../../../shared/services/apolloClient";

import VenueItem from "./VenueItem.jsx";
import styles from "./VenueList.module.scss";

const apolloClient = ApolloClient();

function VenueList() {
  const [fetchData, { data: venueListData, loading: isLoadingVenueList }] =
    useLazyQuery(ADMIN_VENUES, {
      client: apolloClient,
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    fetchData();
  }, []);

  const venues =
    venueListData?.admin_venues?.edges?.map((item) => {
      return item.node;
    }) || [];

  const chunks = useMemo(() => chunk(venues, 2), [venues]);

  const renderChunks = () =>
    chunks?.map((venues) =>
      venues?.map((venue) => (
        <Grid.Col md={6} key={venue.id}>
          <VenueItem venue={venue} />
        </Grid.Col>
      ))
    );

  if (isLoadingVenueList) {
    return <LoadingManager isLoading={isLoadingVenueList} />;
  }

  return <Grid.Row className={styles.venueList}>{renderChunks()}</Grid.Row>;
}

export default VenueList;
