"use strict";

import React, { useRef } from "react";
import styles from "./InstantImageUpload.module.scss";
import { uploadImage, extractImageUrl } from "../../../services/helper";

import imageNotSelected from "../../../../images/file-button.png";

import classNames from "classnames";
import { Button, Icon, LoadingIndicator } from "@citifyd/style";
import axios from "axios";

const InstantImageUpload = ({
  state,
  errorImage,
  touchedImage,
  onChangeState,
  uploadPresetSuffix,
  defaultTransformation,
  small,
}) => {
  const formRef = useRef();
  const inputRef = useRef();
  const uploadControllerRef = useRef();

  const handleChange = async (e) => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }

    onChangeState({ uploading: true, image: null });
    uploadControllerRef.current = new AbortController();

    try {
      const result = await uploadImage(
        formRef.current,
        uploadPresetSuffix,
        uploadControllerRef.current
      );

      inputRef.current.value = null;

      const { cloudName, publicId, format } = result;

      onChangeState({
        uploading: false,
        image: {
          mode: "cloudinary",
          cloudinary: {
            cloudName,
            publicId,
            format,
            defaultTransformation,
          },
        },
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        throw err;
      }
    }
  };

  const cancel = () => {
    if (uploadControllerRef.current) {
      uploadControllerRef.current.abort();
      uploadControllerRef.current = null;
    }

    inputRef.current.value = null;

    onChangeState({ uploading: false, image: null });
  };

  return (
    <form ref={formRef}>
      <div
        className={classNames(
          styles.selectImage,
          { [styles.small]: small },
          errorImage && touchedImage ? styles.error : ""
        )}
      >
        {(state.image || state.uploading) && (
          <Button
            appearance="transparent"
            className={styles.icon}
            onClick={() => cancel()}
            justifyContent="flex-end"
          >
            <Icon icon="times" size="body" />
          </Button>
        )}
        {!state.image && !state.uploading && (
          <img className={styles.image} src={imageNotSelected} />
        )}
        {state.image && !state.uploading && (
          <img className={styles.image} src={extractImageUrl(state.image)} />
        )}
        {state.uploading && (
          <div className={small ? styles.smallLoading : styles.largeLoading}>
            <LoadingIndicator small={small ? true : false} noMargin />
          </div>
        )}

        <input
          type="file"
          name="file"
          ref={inputRef}
          className={styles.input}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </form>
  );
};

export default InstantImageUpload;
