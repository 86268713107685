"use strict";

import React from "react";
import { Icon, Label, Text, Tooltip } from "@citifyd/style";
import styles from "./LabelWithQuestionCircle.module.scss";

export const LabelWithQuestionCircle = ({
  id,
  label,
  required,
  info,
  error,
}) => {
  return (
    <div className={styles.container}>
      <Label required={required} error={error}>
        {label}
      </Label>
      <div className={styles.tooltip}>
        <Tooltip
          id={id}
          place="right"
          label={
            <Icon
              icon="question-circle"
              className={styles.icon}
              pull="none"
              size="h4"
            />
          }
        >
          <div className={styles.text}>
            <Text appearance="white">{info}</Text>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
