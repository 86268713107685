"use strict";

import React from "react";
import classNames from "classnames";
import { Text, Icon } from "@citifyd/style";
import styles from "./Pagination.module.scss";

const Pagination = ({ currentPage, goToPage, pages, amountOfPages }) => {
  return (
    <div className={styles.pagination}>
      <ul>
        {currentPage !== 1 && (
          <li>
            <span onClick={() => goToPage(currentPage - 1)}>
              <Icon icon="chevron-circle-left" />
            </span>
          </li>
        )}
        {pages?.map((item, index) => (
          <li key={index}>
            <Text
              className={classNames(styles.text, {
                [styles.active]: currentPage === item,
              })}
              bold={currentPage === item}
              onClick={() => goToPage(item)}
            >
              {item}
            </Text>
          </li>
        ))}
        {currentPage < amountOfPages && (
          <li>
            <span onClick={() => goToPage(currentPage + 1)}>
              <Icon icon="chevron-circle-right" />
            </span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Pagination;
