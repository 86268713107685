"use strict";

import React from "react";

import { Button, Icon } from "@citifyd/style";
import { useTranslator } from "../../../../../shared/react/hooks";

import StickyFooter from "../../../../../shared/react/components/StickyFooter";
import RouterLink from "../../../../../shared/react/components/RouterLink";
import styles from "./Footer.module.scss";

function Footer({ handleSubmit }) {
  const t = useTranslator();

  return (
    <StickyFooter>
      <RouterLink
        state="events"
        variant="h5"
        appearance="transparent"
        size="large"
        className={styles.routerLink}
      >
        <Icon
          className={styles.icon}
          appearance="transparent"
          icon="chevron-left"
          size="h5"
        />
        {t("commonButtons.goBackWithoutSaving")}
      </RouterLink>

      <Button
        type="button"
        uppercase
        className={styles.saveButton}
        justifyContent="center"
        onMouseDown={handleSubmit}
        extraPadding
      >
        {t("commonButtons.continue")}
      </Button>
    </StickyFooter>
  );
}

export default Footer;
