import { useEffect, useState } from "react";
import { getCountries, getCountry } from "../../../shared/api";
import { useAuthentication } from "../../../shared/react/contexts/authentication";
import { planifyCountries } from "../../../shared/services/helper";

export const useAddressSchema = ({
  setHasError,
  trackLoading,
  untrackLoading,
  alwaysShowCountryDropdown,
}) => {
  const [addressSchema, setAddressSchema] = useState(null);
  const [availableCountries, setAvailableCountries] = useState([]);
  const { user } = useAuthentication();

  const shouldShowCountryDropdown =
    alwaysShowCountryDropdown || !user?.organization;

  const loadCountryList = async () => {
    trackLoading("countryList");
    try {
      const response = await getCountries();
      const countries = planifyCountries(response).filter((c) => c.lotAllowed);

      setAvailableCountries(countries);
    } catch (err) {
      setHasError(true);
    } finally {
      untrackLoading("countryList");
    }
  };

  const loadAddressSchema = async (countryCode) => {
    trackLoading("addressSchema");
    try {
      const response = await getCountry(countryCode);
      setAddressSchema(response.schemas.address);
    } catch (err) {
      setHasError(true);
    }

    if (shouldShowCountryDropdown && !availableCountries.length) {
      loadCountryList();
    }

    untrackLoading("addressSchema");
  };

  return {
    loadAddressSchema,
    addressSchema,
    availableCountries,
    shouldShowCountryDropdown,
  };
};
