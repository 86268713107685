"use strict";

import React, { useContext, useEffect, useState, useRef } from "react";
import { Grid, Text, Icon } from "@citifyd/style";

import logo from "../../../../images/logo.jpg";
import styles from "./Header.module.scss";
import RouterLink from "../RouterLink";
import DropdownHeader from "../DropdownHeader";
import NavMenu from "../NavMenu";
import Permissions from "../../../services/permissions";
import { ReactRouterContext } from "../ReactRouter";
import { useAuthentication } from "../../contexts/authentication";

export default function Header() {
  const node = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { user: loggedUser, permissions } = useAuthentication();
  const { showMenu } = useContext(ReactRouterContext);

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown]);

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    setShowDropdown(false);
  };

  return (
    <div>
      <div className={styles.headerWrap}>
        <Grid className={styles.header}>
          <Grid.Row nogutter justify="between">
            <Grid.Col>
              <div className={styles.navLeft}>
                {Permissions.userMayAccessSnapshots(permissions) ? (
                  <RouterLink state="dashboard">
                    <Logo />
                  </RouterLink>
                ) : (
                  <Logo />
                )}

                {loggedUser && (
                  <div
                    className={styles.userDropdown}
                    onClick={(e) => {
                      setShowDropdown(!showDropdown);
                      e.stopPropagation();
                    }}
                    ref={node}
                  >
                    <div className={styles.userDropdownToggle}>
                      <Text weight="600" variant="subtitle" appearance="white">
                        {loggedUser?.name}
                        {showDropdown ? (
                          <Icon
                            icon="chevron-up"
                            pull="none"
                            appearance="white"
                          />
                        ) : (
                          <Icon
                            icon="chevron-down"
                            pull="none"
                            appearance="white"
                          />
                        )}
                      </Text>
                      <Text appearance="white">{loggedUser?.email}</Text>
                    </div>

                    {showDropdown && (
                      <DropdownHeader setShowDropdown={setShowDropdown} />
                    )}
                  </div>
                )}
              </div>

              {loggedUser && (
                <button
                  onClick={() => setOpenMenu(!openMenu)}
                  className={styles.menuToggle}
                  aria-controls="primary-menu"
                  aria-expanded={!openMenu}
                >
                  <Icon icon="bars" appearance="white" />
                </button>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </div>

      {showMenu && <NavMenu openMenu={openMenu} loggedUser={loggedUser} />}
    </div>
  );
}

const Logo = () => {
  return <img src={logo} alt="Citifyd" className={styles.logo} />;
};
