"use strict";

import React, { useMemo } from "react";
import { Label, Grid } from "@citifyd/style";

import AddressForm from "../../../../shared/react/components/AddressForm";
import Images from "./Images";

import ColorSelector from "../../../../shared/react/components/ColorSelector";
import FormikField, {
  FIELD_TYPE,
} from "../../../../shared/react/components/FormikField";

import SelectedDaysOfTheWeekTable from "./components/SelectedDaysOfTheWeekTable.jsx";
import { getAvailableColors, getLatitudeAngLongitude } from "./utils";
import { useTranslator } from "../../../../shared/react/hooks";

import { LabelWithQuestionCircle } from "./components/LabelWithQuestionCircle/LabelWithQuestionCircle.jsx";
import { Google } from "./components/Google.jsx";
import { useFormikContext } from "formik";
import { chunk } from "lodash";

const Form = ({
  addressSchema,
  amenities,
  availableCountries,
  countryCode,
  formikbag,
  loadAddressSchema,
  lot,
  mode,
  setCountryCode,
  shouldShowCountryDropdown,
}) => {
  const t = useTranslator();

  const { errors, touched, setFieldValue, values } = formikbag;

  const maxSpotsError = errors.maxSpots && touched.maxSpots;

  const handleDragEnd = ({ lat, lng }) => {
    if (!lot) return;

    const { latitude, longitude } = getLatitudeAngLongitude({ lat, lng, lot });

    setFieldValue("coordinates", {
      latitude: latitude || values.coordinates.latitude,
      longitude: longitude || values.coordinates.longitude,
    });
  };

  const isValidCoordinates = () => {
    return (
      typeof values.coordinates.latitude === "number" &&
      typeof values.coordinates.longitude === "number"
    );
  };

  const amenitiesGroups = useMemo(() => {
    if (!amenities) return;

    const columnSize = Math.ceil(amenities.length / 3);
    return chunk(amenities, columnSize);
  }, [amenities]);

  const validCoordinates = isValidCoordinates();

  return (
    <>
      <Grid.Row>
        <Grid.Col xs={12} sm={7}>
          <FieldContainer marginBottom>
            <FormikField
              required
              name="name"
              label={t("addProperty.labels.propertyName")}
              placeholder={t("addProperty.placeholders.propertyName")}
            />
          </FieldContainer>
          <FieldContainer marginBottom>
            <AddressForm
              addressSchema={addressSchema}
              availableCountries={availableCountries}
              countryCode={countryCode}
              label={t("addProperty.labels.propertyAddress")}
              loadAddressSchema={loadAddressSchema}
              setCountryCode={setCountryCode}
              shouldShowCountryDropdown={shouldShowCountryDropdown}
            />
          </FieldContainer>
          <FieldContainer marginBottom>
            <LabelWithQuestionCircle
              required={true}
              error={maxSpotsError}
              id="propertySpaces"
              label={t("addProperty.labels.propertySpaces")}
              info={t("addProperty.infos.propertySpaces")}
            />
            <FormikField
              type="number"
              name="maxSpots"
              placeholder={t("addProperty.placeholders.spaces")}
            />
          </FieldContainer>
          <FieldContainer marginBottom>
            <TextareaWithTooltipLabel
              id="aboutProperty"
              info={t("addProperty.infos.aboutTheProperty")}
              label={t("addProperty.labels.aboutTheProperty")}
              name="aboutProperty"
            />
          </FieldContainer>
          <FieldContainer marginBottom>
            <TextareaWithTooltipLabel
              id="importantNotes"
              info={t("addProperty.infos.importantNotes")}
              label={t("addProperty.labels.importantNotes")}
              name="importantNotes"
            />
          </FieldContainer>
          <FieldContainer marginBottom>
            <TextareaWithTooltipLabel
              id="directionsNotes"
              info={t("addProperty.infos.propertyDirectionNotes")}
              label={t("addProperty.labels.propertyDirectionNotes")}
              name="directionsNotes"
            />
          </FieldContainer>
          <FieldContainer>
            <Label>{t("addProperty.labels.amenities")}</Label>
            <Grid.Row marginBottom>
              {amenitiesGroups?.map((amenities) =>
                amenities.map((amenity, key) => (
                  <Grid.Col xs={4} key={key}>
                    <AmenityField amenity={amenity} />
                  </Grid.Col>
                ))
              )}
            </Grid.Row>
          </FieldContainer>

          {mode === "edit" && (
            <FieldContainer>
              <ColorSelector
                setColor={setFieldValue}
                selectedColor={values.color}
                availableColors={getAvailableColors(lot)}
              />
            </FieldContainer>
          )}
        </Grid.Col>
        <Grid.Col xs={12} sm={5}>
          {validCoordinates && (
            <FieldContainer marginBottom>
              <Google
                handleDragEnd={handleDragEnd}
                coordinates={values.coordinates}
              />
            </FieldContainer>
          )}
          <FieldContainer>
            <LabelWithQuestionCircle
              required={true}
              id="propertyPhotos"
              label={t("addProperty.labels.propertyPhotos")}
              info={t("addProperty.infos.propertyPhotos")}
            />
          </FieldContainer>
          <FieldContainer marginBottom>
            <Images />
          </FieldContainer>
          <FieldContainer>
            <FormikField
              as={FIELD_TYPE.DAYS_OF_WEEK}
              name="daysOfWeek"
              label="Opening hours"
              gutterBottom
            />
            <SelectedDaysOfTheWeekTable
              daysOfWeek={formikbag.values.daysOfWeek}
            />
          </FieldContainer>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

const AmenityField = ({ amenity }) => {
  const { setValues } = useFormikContext();

  const handleChange = (e) => {
    const enabled = e.target.checked;

    setValues((values) => {
      const amenities = {
        ...values.amenities,
        [amenity.code]: enabled,
      };

      if (enabled) {
        for (const disable of amenity.disables) {
          amenities[disable] = false;
        }
      }

      return { ...values, amenities };
    });
  };

  return (
    <div>
      <FormikField
        key={amenity.code}
        small
        as={FIELD_TYPE.CHECKBOX}
        name={`amenities.${amenity.code}`}
        gutterBottom
        label={amenity.label}
        onChange={handleChange}
      />
    </div>
  );
};

const FieldContainer = ({ children, marginBottom }) => {
  return (
    <Grid.Row marginBottom={marginBottom}>
      <Grid.Col>{children}</Grid.Col>
    </Grid.Row>
  );
};

const TextareaWithTooltipLabel = ({ id, label, info, name }) => {
  return (
    <>
      <LabelWithQuestionCircle id={id} label={label} info={info} />
      <FormikField as={FIELD_TYPE.TEXTAREA} name={name} rows="4" />
    </>
  );
};

export default Form;
