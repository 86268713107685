"use strict";

import React from "react";
import { Badge, Text, Tooltip } from "@citifyd/style";
import { useTranslator } from "../../../react/hooks";
import { DictionaryItem } from "../../../react/components/Dictionary";

import styles from "./Items.module.scss";

const Items = ({ label, tooltipLabel, items, verifyIfMatch }) => {
  const t = useTranslator();
  return (
    <DictionaryItem
      label={
        <>
          <Text>
            {label}
            <Tooltip
              id={tooltipLabel}
              icon="question-circle"
              iconProps={{ pull: null, className: styles.icon }}
            >
              {tooltipLabel}
            </Tooltip>
          </Text>
        </>
      }
    >
      {items?.length > 0
        ? items.map((item, i) => (
            <Badge key={i} appearance="gray_light" uppercase={false}>
              {item}
              {verifyIfMatch(item) ? (
                <Tooltip
                  id={`${i}-matched`}
                  icon="check"
                  iconProps={{ pull: null, className: styles.icon }}
                >
                  {t("modalUserMigration.matched")}
                </Tooltip>
              ) : (
                <Tooltip
                  icon="times"
                  id={`${i}-noMatch`}
                  iconProps={{ pull: null, className: styles.icon }}
                >
                  {t("modalUserMigration.noMatch")}
                </Tooltip>
              )}
            </Badge>
          ))
        : "—"}
    </DictionaryItem>
  );
};

export default Items;
