import gql from "graphql-tag";

export default gql`
  mutation AdminRefundSubscription(
    $id: Int!
    $feeRetentionMethod: FeeRetentionMethod!
    $refunder: Refunder!
    $stripeFeePayer: StripeFeePayer!
    $reason: String!
    $requestedByEmail: Boolean!
    $requestedByPhone: Boolean!
  ) {
    admin_refundSubscription(
      id: $id
      feeRetentionMethod: $feeRetentionMethod
      refunder: $refunder
      stripeFeePayer: $stripeFeePayer
      reason: $reason
      requestedByEmail: $requestedByEmail
      requestedByPhone: $requestedByPhone
    )
  }
`;
