"use strict";

import React, { useState, useEffect, useRef } from "react";
import { Icon, List, Input, Label } from "@citifyd/style";
import { filter, findWhere } from "lodash";
import classNames from "classnames";
import InputMask from "react-input-mask";
import { useField } from "formik";

import FormikField, { FIELD_TYPE } from "../FormikField";
import Flag from "../Flag";
import styles from "./PhoneField.module.scss";
import { planifyCountries } from "../../../services/helper";

const PhoneField = (
  {
    label,
    readOnly,
    sameContinentOnly,
    placeholder,
    gutterBottom,
    hideForContinent,
    countries,
    countryCodeDefault,
    disabled,
    phoneNumberFieldName = "phoneNumber",
    countryCodeFieldName = "countryCode",
  },
  ref
) => {
  const node = useRef();
  const [fieldCountryCode, , helpersCountryCode] =
    useField(countryCodeFieldName);
  const [_fieldPhoneNumber, metaPhoneNumber, helpersPhoneNumber] =
    useField(phoneNumberFieldName);
  const [showOptions, setShowOptions] = useState(false);
  const countryCode = fieldCountryCode?.value;
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [plainCountries, setPlainCountries] = useState([]);

  const selectCountry = (country) => {
    const { isoCode } = country;
    setSelectedCountry(findCountry(countries, isoCode));
    helpersCountryCode.setValue(isoCode);
    helpersPhoneNumber.setValue("");
    setShowOptions(false);
  };

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }

    setShowOptions(false);
  };

  const handleShowOptions = (e) => {
    if (readOnly) {
      return null;
    }

    setShowOptions(!showOptions);
  };

  const findCountry = (countries, isoCode) => {
    const planifyCountriesResponse = planifyCountries(countries);

    return findWhere(planifyCountriesResponse, { isoCode });
  };

  const getSelectedCountry = () => findCountry(countries, countryCode);

  const recalculatePlainCountries = () => {
    if (countries) {
      let plainCountries = planifyCountries(countries);

      if (sameContinentOnly) {
        let selectedCountry = getSelectedCountry();
        if (selectedCountry) {
          plainCountries = filter(plainCountries, {
            continent: selectedCountry.continent,
          });
        }
      }

      setPlainCountries(plainCountries);
      setSelectedCountry(getSelectedCountry());
    } else {
      setPlainCountries([]);
    }
  };

  useEffect(() => {
    if (showOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showOptions]);

  useEffect(() => {
    recalculatePlainCountries();
  }, [countries]);

  const shouldRenderSelectCountry = () => {
    if (!hideForContinent) {
      return true;
    }

    return hideForContinent && hideForContinent !== selectedCountry?.continent;
  };

  const renderMaskInput = (props) => {
    const { ref, ...maskProps } = props;

    return (
      <InputMask {...maskProps} mask="999 999 9999" maskChar={null}>
        {(inputProps) => (
          <Input disabled={props.disabled} {...inputProps} ref={ref} />
        )}
      </InputMask>
    );
  };

  const hasError = metaPhoneNumber.touched && metaPhoneNumber.error;

  return (
    <div className={styles.phoneNumber}>
      {label && (
        <Label
          className={classNames(styles.label, {
            [styles.error]: hasError,
          })}
        >
          {label}
        </Label>
      )}
      {shouldRenderSelectCountry() && (
        <div
          className={classNames(styles.phoneCountry, {
            [styles.disabled]: disabled,
          })}
          ref={node}
        >
          <div
            className={styles.selectedCountry}
            onClick={(e) => (disabled ? null : handleShowOptions(e))}
          >
            <Flag code={selectedCountry?.isoCode || countryCodeDefault} />
            <span>+{selectedCountry?.callingCode}</span>
            {showOptions ? (
              <Icon icon="chevron-up" pull="right" size="small" />
            ) : (
              <Icon icon="chevron-down" pull="right" size="small" />
            )}
          </div>

          {showOptions && (
            <List
              className={styles.countryOptions}
              onClick={(e) => e.stopPropagation()}
            >
              {plainCountries.map((country) => (
                <List.Item
                  key={country.isoCode}
                  textAppearance="gray_darker"
                  onClick={() => selectCountry(country)}
                  className={classNames({
                    [styles.selected]: countryCode === country.isoCode,
                  })}
                >
                  {country.name} (+{country.callingCode})
                </List.Item>
              ))}
            </List>
          )}
        </div>
      )}

      <div className={styles.phoneContainer}>
        {countryCode !== "us" && countryCode !== "ca" ? (
          <FormikField
            placeholder={placeholder}
            gutterBottom={gutterBottom}
            fullWidth
            name={phoneNumberFieldName}
            disabled={!!readOnly || disabled}
            ref={ref}
          />
        ) : (
          <FormikField
            placeholder={placeholder}
            errorMessage={hasError}
            gutterBottom={gutterBottom}
            fullWidth
            disabled={!!readOnly || disabled}
            name={phoneNumberFieldName}
            as={FIELD_TYPE.CUSTOM}
            customComponent={renderMaskInput}
            ref={ref}
          />
        )}
      </div>
    </div>
  );
};

export default React.forwardRef(PhoneField);
