"use strict";

import React from "react";
import CurrencyFormatter from "@citifyd/currency-formatter";
import { Badge, Text } from "@citifyd/style";

import Table from "../../../Table";
import { useService, useTranslator } from "../../../../hooks";
import { getCurrentLanguage } from "../../../../../services/languages";
import styles from "../RevenueTab/RevenueTab.module.scss";
import moment from "moment";

const RevenueDailyTable = ({ dates = [], currentFilter = {}, totals = {} }) => {
  const t = useTranslator();
  const language = getCurrentLanguage();
  const $state = useService("$state");

  const columns = [
    {
      value: t("revenue.date"),
      nowrap: "nowrap",
      width: "35%",
      key: "date",
    },
    {
      value: t("revenue.onDemand"),
      nowrap: "nowrap",
      key: "onDemand",
      textAlign: "right",
    },
    {
      value: t("revenue.reservedPasses"),
      nowrap: "nowrap",
      key: "reservedPasses",
      textAlign: "right",
    },
    {
      value: t("revenue.eventPasses"),
      nowrap: "nowrap",
      key: "eventPasses",
      textAlign: "right",
    },
    {
      value: t("revenue.monthly"),
      nowrap: "nowrap",
      key: "monthly",
      textAlign: "right",
    },
    {
      value: t("revenue.netRevenue"),
      nowrap: "nowrap",
      key: "revenue",
      textAlign: "right",
      width: "12%",
    },
  ];

  const handleClick = (date) => {
    $state.go(
      "reports",
      {
        reportType: "transactions",
        currency: currentFilter.currency,
        lots: currentFilter.lotId || undefined,
        dateRange: `${date.date}T00:00:00,${moment(date.date).add(1, "days")}`,
      },
      { enableBackLink: true }
    );
  };

  const data = dates.map((date) => {
    return {
      onClick: () => handleClick(date),
      date: {
        value: (
          <div className={styles.boxDate}>
            {t("defaultFormats.date", { date: date.date })}{" "}
            {date.hasReservationWithPendingPaymentProcessing && (
              <Badge appearance="gray">{t("dailyRevenue.estimated")}</Badge>
            )}
          </div>
        ),
        verticalAlign: "middle",
        noVerticalPadding: true,
      },
      onDemand: {
        value: date.reservationCount || "0",
        textAlign: "right",
        verticalAlign: "middle",
      },
      reservedPasses: {
        value: date.reservedParkingPassCount || "0",
        textAlign: "right",
        verticalAlign: "middle",
      },
      eventPasses: {
        value: date.ticketCount || "0",
        textAlign: "right",
        verticalAlign: "middle",
      },
      monthly: {
        value: date.subscriptionCount || "0",
        textAlign: "right",
        verticalAlign: "middle",
      },
      revenue: {
        value: totals
          ? CurrencyFormatter.format(date.netRevenueAmount, {
              currency: currentFilter.currency,
              language,
            })
          : 0,
        textAlign: "right",
        verticalAlign: "middle",
        textColor: date.netRevenueAmount < 0 ? "tertiary" : "default",
      },
    };
  });

  const renderCell = (value) => (
    <Text textAlign="right" weight="700" className={styles.total}>
      {value}
    </Text>
  );

  const dataWithTotal = [
    ...data,
    {
      showIcon: false,
      appearance: "white",
      date: (
        <Text weight="700" className={styles.total}>
          {t("dailyRevenue.total")}
        </Text>
      ),
      onDemand: renderCell(totals ? totals.reservationCount : 0),
      reservedPasses: renderCell(totals ? totals.reservedParkingPassCount : 0),
      eventPasses: renderCell(totals ? totals.ticketCount : 0),
      monthly: renderCell(totals ? totals.subscriptionCount : 0),
      revenue: {
        value: renderCell(
          totals
            ? CurrencyFormatter.format(totals.netRevenueAmount, {
                currency: currentFilter.currency,
                language,
              })
            : 0
        ),
        textColor: totals?.netRevenueAmount < 0 ? "tertiary" : "default",
      },
    },
  ];

  return (
    <Table
      striped
      columns={columns}
      data={dataWithTotal}
      responsive
      clickable
      showIconRight
      borderBottom
      headerGap
      verticalAlignIconRight="middle"
    />
  );
};

export default RevenueDailyTable;
