"use strict";

import React from "react";
import { trim, times, camelCase } from "lodash";
import moment from "moment-timezone";
import { getTranslator } from "../../../../shared/services/languages";
import HighlightText from "../../../../shared/react/components/HighlightText";
import { getVehicleLicense } from "../../../../shared/utils/vehicles";
import VehicleColorIndicator from "../../../../shared/react/components/VehicleColorIndicator";
import { getParkersSnapshot } from "../../../../shared/api";
import { formatDuration } from "../../../../shared/services/helper";

const PARKERS_PER_PAGE = 10;

const calculateTimeLeft = (reservation) => {
  const t = getTranslator();
  let timeLeft = null;

  if (reservation.expirationTime) {
    if (moment().isAfter(reservation.expirationTime)) {
      timeLeft = t("dashboard.expired");
    } else {
      const duration = moment.duration(
        moment(reservation.expirationTime).diff(moment())
      );
      timeLeft = formatDuration(duration);
    }
  }

  return timeLeft;
};

const adjustReservationType = (originalType) => camelCase(originalType); // 'on_demand' -> 'onDemand'

const adjustReservation = (reservation) => ({
  ...reservation,
  timeLeft: calculateTimeLeft(reservation),
  type: adjustReservationType(reservation.type),
});

export const loadParkers = async ({
  selectedProperty,
  search,
  currentPage,
  signal,
}) => {
  const options = {
    limit: PARKERS_PER_PAGE,
    offset: (currentPage - 1) * PARKERS_PER_PAGE,
  };

  if (selectedProperty) {
    options.lotId = selectedProperty;
  }

  if (trim(search) !== "") {
    options.search = search;
  }

  return getParkersSnapshot({ params: options, signal })
    .then((response) => {
      const amountOfPages = Math.max(
        Math.ceil(response?.count / PARKERS_PER_PAGE),
        1
      );

      return {
        reservations: response.reservations?.map(adjustReservation),
        amountOfPages: amountOfPages,
        pages: times(amountOfPages, (n) => n + 1),
      };
    })
    .catch((err) => ({ error: err }));
};

export const getData = ({ reservations, search }) => {
  if (!reservations || reservations?.length === 0) return {};

  const t = getTranslator();

  const columns = [
    {
      value: t("dashboard.plateNo"),
      key: "license",
    },
    {
      value: t("dashboard.makeAndColor"),
      key: "makeAndColor",
    },
    {
      value: t("dashboard.property"),
      key: "property",
    },
    {
      value: t("dashboard.passType"),
      key: "passType",
    },
    {
      value: t("dashboard.parkedSince"),
      key: "parkedSince",
    },
    {
      value: t("dashboard.timeLeft"),
      key: "timeLeft",
    },
  ];

  const data = reservations?.map((item) => ({
    license: {
      value: item.vehicle ? (
        <HighlightText
          flags="-i"
          text={getVehicleLicense(item.vehicle)}
          highlight={search}
        />
      ) : (
        <>&mdash;</>
      ),
      nowrap: "nowrap",
      verticalAlign: "middle",
    },
    makeAndColor: {
      value: item.vehicle ? (
        <span>
          <VehicleColorIndicator vehicle={item.vehicle} />
          {item.vehicle?.make || "—"}
        </span>
      ) : (
        <>&mdash;</>
      ),
      nowrap: "nowrap",
      verticalAlign: "middle",
    },
    property: {
      value: item.lot?.name,
      verticalAlign: "middle",
    },
    passType: {
      value: t("reservationTypes." + item.type),
      verticalAlign: "middle",
    },
    parkedSince: {
      value: t("defaultFormats.time", {
        date: moment.tz(item.parkedSince, item.lot?.timezoneName),
      }),
      verticalAlign: "middle",
    },
    timeLeft: {
      value: item?.timeLeft || <>&mdash;</>,
      verticalAlign: "middle",
    },
  }));

  return {
    columns,
    data,
  };
};
