"use strict";

import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null);

  const openModal = (component, props = {}) => {
    setModal({ component, props });
  };

  const onClose = (...args) => {
    setModal(null);
    modal.props.onClose && modal.props.onClose(...args);
  };

  const Component = modal?.component;

  return (
    <ModalContext.Provider value={{ openModal }}>
      {children}
      {Component && (
        <Component {...modal.props} onClose={onClose} open={true} />
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
