"use strict";

import { findKey, isEmpty, map } from "lodash";
import { matchPath } from "react-router-dom";
import { routes, history } from "../../../routes";

export const getFormattedQueryStrings = (queryStrings) =>
  Object.entries(queryStrings)
    .filter(([_key, value]) => value)
    .map(([key, value]) => {
      return encodeURIComponent(key) + "=" + encodeURIComponent(value);
    })
    .join("&");

export const convertQueryStringToObject = (queryString) => {
  if (!queryString) return;

  const urlParams = new URLSearchParams(queryString);
  return Object.fromEntries(urlParams);
};

export const getRouteByName = (path) => {
  const route = Object.keys(routes).filter((route) => route === path);

  if (isEmpty(route)) return;

  return { ...routes[route], name: path };
};

export const getRouteName = (item) =>
  findKey(routes, (route) => route.url === item);

export const getRouteByPathname = (path = history.location.pathname) => {
  const routeObj = map(routes, (route) => matchPath(route.url, path)).filter(
    Boolean
  )[0];

  if (!routeObj) return {};

  const routeName = getRouteName(routeObj?.pattern?.path);

  if (routeObj?.params) {
    const paramWithQueryStrings = Object.keys(routeObj.params).filter((param) =>
      routeObj?.params[param].includes("?")
    )[0];

    if (!isEmpty(paramWithQueryStrings)) {
      const paramObject = routeObj.params[paramWithQueryStrings];

      const params = {
        [paramWithQueryStrings]: paramObject?.split("?")[0],
        ...convertQueryStringToObject(paramObject?.split("?")[1]),
      };

      return { ...getRouteByName(routeName), params };
    } else {
      return { ...getRouteByName(routeName), params: { ...routeObj?.params } };
    }
  }

  return getRouteByName(routeName);
};

export const getRouteObject = (pathname = history.location.pathname) => {
  return (
    map(routes, (route) => matchPath(route.url, pathname)).filter(Boolean)[0] ||
    {}
  );
};
