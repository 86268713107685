import React, { useState } from "react";
import { Button, List, Grid, Text, Icon, AlertMessage } from "@citifyd/style";

import BoxContent from "../../../../shared/react/components/BoxContent";
import { DeviceQuery } from "../../../../graphql-pos/generated/graphql";
import { currencyFormatter } from "../../../../shared/utils/currencyFormtter";

import { useTranslator } from "../../../../shared/react/hooks";

import styles from "./DevicesCollapse.module.scss";
import { useDevicesActions } from "../../hooks/useDevicesActions";

export const ActiveCard = ({ device }: { device: DeviceQuery["device"] }) => {
  const t = useTranslator();
  const { onFinishButtonClick, onManageButtonClick } = useDevicesActions();
  const [successMessage, setSuccessMessage] = useState<string>("");

  const { tally, currentProvision, shortCode, name } = device;

  const assignedTo = device?.currentSession?.agent.name;

  return (
    <BoxContent className={styles.activeCard}>
      {successMessage === device?.id && (
        <AlertMessage className={styles.sucessMessage} appearance="success">
          <Text uppercase appearance="white" textAlign="center">
            {t("posRover.devices.updated")}{" "}
            <Icon icon="check" appearance="white" pull="none" />
          </Text>
        </AlertMessage>
      )}

      <BoxContent.Content>
        <Text weight="600">
          #{shortCode} {name ?? ""}
        </Text>

        <List>
          <List.Divisor />
        </List>

        <Grid.Row className={styles.payment}>
          <Grid.Col>
            <Text weight="600" textAlign="center">
              {currencyFormatter({
                value: tally?.card.money,
                currency:
                  currentProvision?.citifydLot.paymentDestination.currency ||
                  "usd",
              })}
            </Text>
            <Text textAlign="center">{t("posRover.devices.card")}</Text>
          </Grid.Col>
          <Grid.Col>
            <Text weight="600" textAlign="center">
              {currencyFormatter({
                value: tally?.cash.money,
                currency:
                  currentProvision?.citifydLot.paymentDestination.currency ||
                  "usd",
              })}
            </Text>

            <Text textAlign="center">{t("posRover.devices.cash")}</Text>
          </Grid.Col>
        </Grid.Row>

        <List>
          <List.Divisor />
        </List>
        <Grid.Row className={styles.assignedTo}>
          <Grid.Col>
            <Text gutterBottom textAlign="center">
              {t("posRover.devices.assignedTo", { name: assignedTo })}
            </Text>
            <Text weight="600" textAlign="center">
              {currentProvision && currentProvision.citifydLot.name}
            </Text>
          </Grid.Col>
        </Grid.Row>
      </BoxContent.Content>

      <BoxContent.Footer appearance="white" className={styles.footer}>
        <div className={styles.buttons}>
          <Button
            fullWidth
            justifyContent="center"
            size="small"
            appearance="secondary"
            uppercase
            onClick={() => onFinishButtonClick(device)}
          >
            {t("posRover.commons.finish")}
          </Button>
          <Button
            fullWidth
            justifyContent="center"
            size="small"
            uppercase
            onClick={() => onManageButtonClick({ device, setSuccessMessage })}
          >
            {t("posRover.commons.manage")}
          </Button>
        </div>
      </BoxContent.Footer>
    </BoxContent>
  );
};
