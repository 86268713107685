"use strict";
import React, { useState, useEffect } from "react";
import { Button, LoadingIndicator, Modal } from "@citifyd/style";
import FormikProvider from "../../react/components/FormikProvider";
import FormikField, { FIELD_TYPE } from "../../react/components/FormikField";
import { useTranslator } from "../../react/hooks";
import Mode from "../../utils/mode";
import useReferralCodeForm from "./hooks/useReferralCodeForm";
import { getReferralCode } from "../../api";

const ManageReferralCodeModal = ({ referralCodeId, open, onClose, mode }) => {
  const t = useTranslator();
  const [isSubmiting, setIsSubmiting] = useState(false);

  const formikbag = useReferralCodeForm({ mode, setIsSubmiting, onClose });

  const [isLoadingReferralCode, setIsLoadingReferralCode] = useState(true);
  const loadingReferralCode = referralCodeId
    ? isLoadingReferralCode
    : undefined;

  useEffect(() => {
    init();
  }, []);

  const loadReferralCode = async (referralCodeId) => {
    const response = await getReferralCode(referralCodeId);
    formikbag.setFieldValue("code", response.code);
    formikbag.setFieldValue(
      "incentiveAmount",
      String(response.incentiveAmount / 100)
    );
    formikbag.setFieldValue("maxReferrals", response.maxReferrals || null);
    setIsLoadingReferralCode(false);
  };

  const init = () => {
    if (mode === Mode.EDIT) {
      loadReferralCode(referralCodeId);
    }
  };

  const { handleSubmit } = formikbag;

  return (
    <Modal open={open} onClose={onClose} closeIcon>
      <FormikProvider value={formikbag}>
        <Modal.Title>
          {mode === Mode.ADD
            ? t("modalAddReferralCode.title")
            : t("modalAddReferralCode.titleEditCode", {
                code: formikbag.values.code,
              })}
        </Modal.Title>
        <Modal.Content>
          {mode === Mode.ADD && (
            <FormikField
              gutterBottom
              label={t("modalAddReferralCode.code")}
              name="code"
              as={FIELD_TYPE.INPUT}
            />
          )}
          <FormikField
            gutterBottom
            label={t("modalAddReferralCode.incentive")}
            name="incentiveAmount"
            as={FIELD_TYPE.CURRENCY}
            currency="usd"
          />
          <FormikField
            label={t("modalAddReferralCode.max")}
            name="maxReferrals"
            as={FIELD_TYPE.INPUT}
            type="number"
            min="0"
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            onMouseDown={onClose}
            type="button"
            textColor="white"
            appearance="secondary"
            fullWidth
            justifyContent="center"
            uppercase
          >
            {t("commonButtons.cancel")}
          </Button>
          <Button
            onMouseDown={handleSubmit}
            type="button"
            fullWidth
            justifyContent="center"
            uppercase
          >
            {t("commonButtons.save")}
          </Button>
        </Modal.Actions>
      </FormikProvider>
      {(isSubmiting || loadingReferralCode) && (
        <LoadingIndicator isLoading={isSubmiting} withOverlay />
      )}
    </Modal>
  );
};

export default ManageReferralCodeModal;
