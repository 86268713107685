"use strict";

const row1 = [{ field: "line1", width: "100%" }];

const row2 = [
  { field: "city", width: "60%" },
  { field: "state", width: "15%" },
  { field: "postalCode", width: "25%" },
];

export default {
  rows: [row1, row2],
};
