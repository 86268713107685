"use strict";

import React from "react";

import { Text } from "@citifyd/style";
import { useTranslator } from "../../../../shared/react/hooks";

import BoxContent from "../../../../shared/react/components/BoxContent";
import PropertyCardContent from "./PropertyCardContent";

const PropertyCard = ({ lot, measurementSystem, formikIndex, formikbag }) => {
  const t = useTranslator();

  return (
    <BoxContent smallRadius appearance="gray_lighter">
      <BoxContent.Header small title={lot.name}>
        <BoxContent.HeaderActions>
          <Text appearance="white">
            {measurementSystem === "imperial" &&
              t("eventProperties.distanceFromEventMiles", {
                distance: lot.distance.toFixed(2),
              })}
            {measurementSystem === "metric" &&
              t("eventProperties.distanceFromEventKilometers", {
                distance: lot.distance.toFixed(2),
              })}
          </Text>
        </BoxContent.HeaderActions>
      </BoxContent.Header>

      <PropertyCardContent
        formikIndex={formikIndex}
        lot={lot}
        formikbag={formikbag}
      />
    </BoxContent>
  );
};

export default PropertyCard;
