import { useEffect, useState } from "react";
import { getAllVenues, getMyVenues } from "../../../shared/api";
import { useAuthentication } from "../../../shared/react/contexts/authentication";
import Permissions from "../../../shared/services/permissions";

const useVenues = ({ trackLoading, untrackLoading }) => {
  const { permissions } = useAuthentication();
  const userMaySeeAllVenues = Permissions.userMaySeeAllVenues(permissions);

  const [venues, setVenues] = useState([]);

  const loadVenues = async () => {
    const promise = userMaySeeAllVenues ? getAllVenues() : getMyVenues();

    trackLoading("venues");
    const response = await promise;

    setVenues(response);
    untrackLoading("venues");
  };

  useEffect(() => {
    loadVenues();
  }, []);

  return { venues };
};

export default useVenues;
