"use strict";

import React, { useEffect, useState } from "react";
import { Text, Button } from "@citifyd/style";
import { useTranslator, useService } from "../../hooks";
import Permissions from "../../../services/permissions";
import styles from "./NoProperties.module.scss";
import { getCurrentLanguage } from "../../../services/languages";
import { useAuthentication } from "../../contexts/authentication";

const NoProperties = () => {
  const t = useTranslator();
  const { permissions } = useAuthentication();
  const [appleImage, setAppleImage] = useState();
  const [googleImage, setGoogleImage] = useState();
  const $state = useService("$state");
  const userMayCreateLot = Permissions.userMayCreateLot(permissions);
  const currentLanguage = getCurrentLanguage()?.split("-")[0];
  const storeImages = ["en", "ja", "pt"];

  const onCreatePropertyClick = () => {
    $state.transitionTo("add-property", {}, { location: "replace" });
  };

  useEffect(() => {
    const includedLanguage = storeImages.some((el) =>
      currentLanguage.includes(el)
    );
    const language = includedLanguage ? currentLanguage : "en";

    //this was causing a warning because the component did not exist, we are only mounting it when it exists
    let mounted = true;

    import(`../../../../images/app-store-badge_${language}.png`).then(
      (image) => {
        if (mounted) setAppleImage(image.default);
      }
    );

    import(`../../../../images/google-play-badge_${language}.png`).then(
      (image) => {
        if (mounted) setGoogleImage(image.default);
      }
    );

    return () => {
      mounted = false;
    };
  }, [currentLanguage]);

  return (
    <div className={styles.container}>
      <section>
        <Text variant="h2" textAlign="center">
          {t("noProperties.welcome")}
        </Text>
        <Text variant="subtitle" textAlign="center">
          {t("noProperties.getStarted")}
        </Text>
        {userMayCreateLot && (
          <Button
            className={styles.createPropertyButton}
            extraPadding
            shadow
            onClick={() => onCreatePropertyClick()}
          >
            {t("noProperties.create")}
          </Button>
        )}
      </section>

      <section className={styles.sectionParkingApp}>
        <Text variant="h2" textAlign="center">
          {t("noProperties.looking")}
        </Text>
        <Text variant="subtitle" textAlign="center">
          {t("noProperties.download")}
        </Text>

        <div className={styles.downloadButtons}>
          <a
            href="https://itunes.apple.com/us/app/citifyd/id984113093?ls=1&mt=8`"
            target="_blank"
          >
            <img src={appleImage} className="badge" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.citifyd.Citifyd"
            target="_blank"
          >
            <img src={googleImage} className="badge" />
          </a>
        </div>
      </section>
    </div>
  );
};

export default NoProperties;
