"use strict";

import moment from "moment-timezone";
import { getService } from "../../shared/react/utils";

import { getTranslator } from "../../shared/services/languages";

import {
  transformRateToInteger,
  transformRateToDecimal,
} from "../../shared/services/currencies";

import { findWhere, indexBy, get, cloneDeep, isEmpty, filter } from "lodash";
import {
  dateAndTimeToMomentObject,
  isNil,
  milesToKilometers,
  spacesArrayToObject,
  spacesObjectToArray,
} from "../../shared/services/helper";
import produce from "immer";

const adjustLotDistancesBasedOnMeasurementSystem = ({
  lots,
  measurementSystem,
}) => {
  // by default the back-end returns the distances as miles, so let's convert to km
  // if the measurement system selected is kilometers
  if (measurementSystem === "metric") {
    return lots.map((lot) => ({
      ...lot,
      distance: milesToKilometers(lot.distance),
    }));
  } else {
    return lots;
  }
};

export const getRemovedLotAvailabilities = (event) => {
  // event.lots contains the current available lots from this owner.
  // We check equivalent lot availabilities on event.nearbyLots[i] where
  // availability.selected = false to check which ones have been removed by the user.
  var removed = event.lots.filter((lot) => {
    var selected = _.find(event.nearbyLots, (nearbyLot) => {
      return (
        nearbyLot.id === lot.id &&
        nearbyLot.availability.selected &&
        nearbyLot.availability.id === lot.availability.id
      );
    });

    return !selected;
  });

  return removed.map((lot) => {
    return { lotId: lot.id, availabilityId: lot.availability.id };
  });
};

export const getAddedLotAvailabilities = ({
  event,
  selectedLots,
  posRates,
}) => {
  return selectedLots.map((lot) => {
    let rate;

    if (!lot.availability.disablePublicSale && lot.availability.rate.value) {
      rate = transformRateToInteger(
        {
          ...lot.availability.rate,
          value: parseFloat(lot.availability.rate?.value),
        },
        lot.country.currency
      );

      rate = convertRateToBackend(rate);
    } else {
      rate = { type: "fixed", value: 0 };
    }

    return {
      lotId: lot.id,
      availabilityId: lot.availability.id,
      allowReentry: lot.availability.allowReentry,
      gpsVerification: lot.availability.gpsVerification,
      vehicleRequired: lot.availability.vehicleRequired,
      barcodeActivation: lot.availability.barcodeActivation,
      serviceFeeDisabled: lot.availability.serviceFeeDisabled,
      publicSale: !lot.availability.disablePublicSale,
      saleCutoffByTime:
        lot.availability.advancedSalesEnabled &&
        lot.availability.saleCutoffByTimeEnabled
          ? lot.availability.saleCutoffByTime
          : null,
      saleCutoffByTransaction:
        lot.availability.advancedSalesEnabled &&
        lot.availability.saleCutoffByTransactionEnabled
          ? lot.availability.saleCutoffByTransaction
          : null,
      rate: rate,
      posRates: formatPosRatesToSubmit({ lot, posRates }),
      spaces: spacesObjectToArray(lot.availability.spaces),
      startsAt: formatDateAndTimeToMomentObject({
        date: lot.availability.startDate,
        time: lot.availability.startTime,
        timezoneName: event.timezoneName,
      }),
      endsAt: formatDateAndTimeToMomentObject({
        date: lot.availability.endDate,
        time: lot.availability.endTime,
        timezoneName: event.timezoneName,
      }),
    };
  });
};

const setLotAvailabilities = (event) => {
  const $stateParams = getService("$stateParams");
  const EMPTY_RATE = { type: "fixed", value: "" };

  const getDateObject = (momentObject) =>
    new Date(momentObject.year(), momentObject.month(), momentObject.date());

  const getLocalDate = ({ date, timezoneName }) => {
    var ymd = moment.tz(date, timezoneName).locale("en").format("YYYY-MM-DD");
    return moment(ymd).toDate();
  };

  return event.nearbyLots
    ?.map((lot) => {
      const availableLot = findWhere(event.lots, { id: lot.id });
      const availability = { id: get(availableLot, "availability.id", null) };

      if (availableLot) {
        availability.selected = true;
        availability.serviceFeeDisabled = availableLot.serviceFeeDisabled;
        availability.rateEditingDisabled = availableLot.rateEditingDisabled;
        availability.vehicleRequired = availableLot.vehicleRequired;
        availability.allowReentry = availableLot.allowReentry;
        availability.gpsVerification = availableLot.gpsVerification;
        availability.disablePublicSale =
          availableLot.disablePublicSale || !availableLot.publicSale;
        availability.barcodeActivation = availableLot.barcodeActivation;
        availability.rate = !availability.disablePublicSale
          ? transformRateToDecimal(availableLot.rate, lot.country.currency)
          : EMPTY_RATE;
        availability.spaces = spacesArrayToObject(availableLot.spaces);
        availability.startDate = getLocalDate({
          date: availableLot.availability.start,
          timezoneName: lot.timezoneName,
        });
        availability.startTime = moment
          .tz(availableLot.availability.start, lot.timezoneName)
          .locale("en")
          .format("HH:mm");
        availability.endDate = getLocalDate({
          date: availableLot.availability.end,
          timezoneName: lot.timezoneName,
        });
        availability.endTime = moment
          .tz(availableLot.availability.end, lot.timezoneName)
          .locale("en")
          .format("HH:mm");
        availability.advancedSalesEnabled = Boolean(
          availableLot.saleCutoffByTime || availableLot.saleCutoffByTransaction
        );
        availability.saleCutoffByTime = availableLot.saleCutoffByTime;
        availability.saleCutoffByTimeEnabled = Boolean(
          availableLot.saleCutoffByTime
        );
        availability.saleCutoffByTransaction =
          availableLot.saleCutoffByTransaction;

        availability.saleCutoffByTransactionEnabled = Boolean(
          availableLot.saleCutoffByTransaction
        );
      } else {
        availability.selected = false;
        availability.serviceFeeDisabled =
          lot.organization?.serviceFeeDisabledDefaultValue;
        availability.barcodeActivation = get(
          event,
          "extra.barcodeActivation",
          false
        );
        availability.rateEditingDisabled = false;
        availability.allowReentry = false;
        availability.vehicleRequired = true;
        availability.gpsVerification =
          lot.organization?.gpsVerificationDefaultValue;
        availability.disablePublicSale = false;
        availability.rate = cloneDeep(EMPTY_RATE);
        availability.spaces = { regular: lot.maxSpots };
        availability.saleCutoffByTime = null;
        availability.saleCutoffByTransaction = null;
        availability.advancedSalesEnabled = false;
        availability.saleCutoffByTimeEnabled = false;
        availability.saleCutoffByTransactionEnabled = false;

        var start =
          $stateParams.start ||
          event.availabilityDefaultStartTime ||
          event.lotsLatestOpeningTimeAllowed;
        var end =
          $stateParams.end ||
          event.availabilityDefaultEndTime ||
          event.lotsEarliestClosingTimeAllowed;

        availability.startDate = getDateObject(
          moment.tz(start, lot.timezoneName).startOf("day")
        );
        availability.startTime = moment
          .tz(start, lot.timezoneName)
          .locale("en")
          .format("HH:mm");
        availability.endDate = getDateObject(
          moment.tz(end, lot.timezoneName).startOf("day")
        );
        availability.endTime = moment
          .tz(end, lot.timezoneName)
          .locale("en")
          .format("HH:mm");
      }

      lot.availability = availability;

      return lot;
    })
    .sort((a, b) => a.distance - b.distance);
};

const formatPosRatesToSubmit = ({ lot, posRates }) => {
  if (!posRates[lot.id]) return null;

  return posRates[lot.id]
    .map((rate) => ({
      label: rate.label,
      name: rate.name || rate.label.replace(/\s+/g, "-").toLowerCase(),
      allowReentry: rate.allowReentry,
      rate: transformRateToInteger(
        {
          type: rate.rate.type,
          value: parseFloat(rate.rate.value),
          fee: parseFloat(rate.rate.fee),
        },
        lot.country.currency
      ),
    }))
    .filter((item) => item.name);
};

const buildSelectizeOptionsForGate = ({ gate, eventGate }) => {
  const selectizeOptions = [];

  gate.beaconUids.forEach((uid) => {
    selectizeOptions.push({ text: uid, value: uid });
  });

  // We need to add the UIDs associated with the event gate as options
  // otherwise Selectize won't show the values when the page loads
  if (eventGate) {
    eventGate.beaconUids.forEach((uid) => {
      selectizeOptions.push({ text: uid, value: uid });
    });
  }

  return selectizeOptions;
};

export const formatDateAndTimeToMomentObject = ({
  date,
  time,
  timezoneName,
}) => {
  return dateAndTimeToMomentObject(date, time, timezoneName)
    .locale("en")
    .format();
};

export const getPosRatesObject = (lots) => {
  const posRates = {};

  lots
    .filter((lot) => lot.posRates)
    .map((lot) => {
      posRates[lot.id] = lot.posRates.map((rate, index) => ({
        ...rate,
        id: index,
        rate: transformRateToDecimal(
          {
            type: rate.rate.type,
            value: rate.rate.value,
            fee: rate.rate.fee,
          },
          lot.country.currency
        ),
      }));
    });

  return posRates;
};

export const getEventGatesObject = ({ event, lots }) => {
  // System gates (e.g. GPS Entry) cannot be edited by users
  const filteredLots = lots
    .map((lot) =>
      lot.gates.filter((gate) => !gate.isSystemGate).length > 0
        ? { ...lot, gates: lot.gates.filter((gate) => !gate.isSystemGate) }
        : null
    )
    .filter((gate) => gate);

  if (isEmpty(filteredLots)) {
    return [];
  }

  const formattedGates = filteredLots
    .map((lot) =>
      lot.gates.map((gate) => {
        const eventGate = findWhere(event.eventGates, {
          gateId: gate.id,
        });

        const isLotSelected = lot.availability.selected;

        // The event gate is selected upon loading (check-box marked) if:
        // - There's an existing event gate (lot is already appended to event and there's an event gate set up)
        // - There's no existing event gate but the lot isn't selected for event. In this case we pre-select the event
        // gate so that, if this lot is made available for the event, the event gates will be all pre-selected.
        // They will be pre-selected even if the user doesn't have permission to edit the event gates (canConfigureGates is false).
        const eventGateSelected = Boolean(eventGate) || !isLotSelected;

        return {
          name: gate.name,
          id: get(eventGate, "id"),
          lotId: lot.id,
          gateId: gate.id,
          scannerCode: get(eventGate, "scannerCode", gate.scannerCode),
          beaconUids:
            get(eventGate, "beaconUids", gate.beaconUids).map((uid) => {
              if (uid.value || uid.label) {
                return uid;
              } else {
                return {
                  value: uid,
                  label: uid,
                };
              }
            }) || [],
          type: get(eventGate, "type", gate.type),
          armedGate: get(eventGate, "armedGate", gate.armedGate),
          reportsStatus: get(eventGate, "reportsStatus", gate.reportsStatus),
          hasBarrier: get(eventGate, "hasBarrier", gate.hasBarrier),
          automaticActivation: get(
            eventGate,
            "automaticActivation",
            gate.automaticActivation
          ),
          hasAttendant: get(eventGate, "hasAttendant", gate.hasAttendant),
          selected: eventGateSelected,
          selectizeOptions: buildSelectizeOptionsForGate({ gate, eventGate }),
        };
      })
    )
    ?.reduce((pre, cur) => pre.concat(cur));

  return indexBy(formattedGates, "gateId");
};

export const getFormattedNearbyLots = ({ event, measurementSystem }) => {
  let lots;

  lots = setLotAvailabilities(event);
  lots = adjustLotDistancesBasedOnMeasurementSystem({
    lots,
    measurementSystem,
  });

  // Remove system event gates from list so that they won't appear on UI
  lots = lots.map((lot) => ({
    ...lot,
    gates: lot.gates
      ?.filter((gate) => !gate.isSystemGate)
      .map((gate) => ({
        ...gate,
        beaconUids: gate.beaconUids.map((uid) => ({ value: uid, label: uid })),
      })),
  }));

  // If there's only one property nearby, let's select it by default.
  if (lots.length === 1) {
    lots[0].availability.selected = true;
  }

  return lots;
};

export const startDateValidation = ({ date, time, event }) => {
  const formattedDate = formatDateAndTimeToMomentObject({
    date: date,
    time: time,
    timezoneName: event.timezoneName,
  });

  return moment(formattedDate).isSameOrBefore(
    event.lotsLatestOpeningTimeAllowed
  );
};

export const endDateValidation = ({ date, time, event }) => {
  const formattedDate = formatDateAndTimeToMomentObject({
    date: date,
    time: time,
    timezoneName: event.timezoneName,
  });

  return moment(formattedDate).isAfter(event.lotsEarliestClosingTimeAllowed);
};

export const validateLotAvailabilities = ({
  event,
  selectedLots,
  posRates,
}) => {
  const t = getTranslator();

  const lotAvailabilities = getAddedLotAvailabilities({
    event,
    selectedLots,
    posRates,
  });

  let error = "";

  lotAvailabilities.forEach((lotAvailability) => {
    const name = findWhere(event.nearbyLots, {
      id: lotAvailability.lotId,
    }).name;

    if (
      lotAvailability.publicSale &&
      (isNil(lotAvailability.rate.value) || lotAvailability.rate.value === 0)
    ) {
      error = t("eventProperties.errors.rateRequired", {
        lotName: name,
      });
      return false;
    }

    if (
      isNaN(lotAvailability.spaces[0].max) ||
      lotAvailability.spaces[0].max < 0
    ) {
      error = t("eventProperties.errors.spacesRequired", {
        lotName: name,
      });
      return false;
    }

    if (
      moment(lotAvailability.startsAt).isAfter(
        event.lotsLatestOpeningTimeAllowed
      )
    ) {
      error = t("eventProperties.errors.invalidOpeningTime", {
        lotName: name,
        dateTime: moment.tz(
          event.lotsLatestOpeningTimeAllowed,
          event.timezoneName
        ),
      });
      return false;
    }

    if (
      moment(lotAvailability.endsAt).isBefore(
        event.lotsEarliestClosingTimeAllowed
      )
    ) {
      error = t("eventProperties.errors.invalidClosingTime", {
        lotName: name,
        dateTime: moment.tz(
          event.lotsEarliestClosingTimeAllowed,
          event.timezoneName
        ),
      });
      return false;
    }
  });

  if (error) {
    window.alert(error);
    return false;
  }

  return true;
};

export const transformToEventGatesArray = (editEventGates) =>
  filter(editEventGates, (obj) => {
    // Send to server only existent event gates (in case we want to edit or delete them)
    // OR new event gates
    return obj.id || obj.selected;
  }).map((eventGate) => {
    var obj = {
      gateId: eventGate.gateId,
      scannerCode: isEmpty(eventGate.scannerCode)
        ? null
        : eventGate.scannerCode,
      type: eventGate.type,
      armedGate: eventGate.armedGate,
      reportsStatus: eventGate.reportsStatus,
      hasBarrier: eventGate.hasBarrier,
      automaticActivation: eventGate.automaticActivation,
      hasAttendant: eventGate.hasAttendant,
    };

    obj.beaconUids = (eventGate.beaconUids || [])
      .map((gate) => gate.value?.trim())
      .filter((gate) => gate.value !== "");

    if (eventGate.id) {
      obj.id = eventGate.id;

      if (!eventGate.selected) {
        obj.destroy = true;
      }
    }

    return obj;
  });

export const convertLotFromBackend = (lot) => {
  const dynamicRate = lot.availability.rate.dynamic;
  if (dynamicRate) {
    dynamicRate.timeEnabled = dynamicRate.time?.length > 0;
    dynamicRate.transactionEnabled = dynamicRate.transaction?.length > 0;
  }

  return lot;
};

const convertRateToBackend = (rate) => {
  return produce(rate, (rate) => {
    if (rate.dynamic?.timeEnabled || rate.dynamic?.transactionEnabled) {
      rate.type = "dynamic";
    } else {
      rate.type = "fixed";
      delete rate.dynamic;
    }

    if (rate.dynamic) {
      if (!rate.dynamic.timeEnabled) delete rate.dynamic.time;
      if (!rate.dynamic.transactionEnabled) delete rate.dynamic.transaction;
      delete rate.dynamic.timeEnabled;
      delete rate.dynamic.transactionEnabled;
    }
  });
};
